import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { getLoggedInUser } from "app/stores/auth/authClient";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import styles from "./MyAccount.module.scss";
import { Button, ButtonType } from "@armtekfrance/armtek-react-design-system";
import { useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import {
  fetchUserNotificationPreferences,
  updateUserNotificationPreferences,
} from "app/services/userPreferences";
import NotificationPreferences from "components/NotificationParametrization/NotificationParametrization";

const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "fr",
    name: "Français",
  },
];

export default function MyAccount() {
  const { i18n, t } = useTranslation();
  const userDetails = getLoggedInUser();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };
  const [showChangePasswordModal, setShowChangePasswordModal] =
    useState<boolean>(false);

  return (
    <div className={styles.accountContainer} data-cy="MyAccount">
      <div className={styles.content}>
        <div className={styles.myAccountWrapper} data-cy="MyAccount_Wrapper">
          {userDetails && (
            <div className="d-flex flex-column">
              <div>
                <AvatarComponent
                  src={userDetails?.avatar?.url}
                  // size={54}
                  size={120}
                  firstName={userDetails?.first_name}
                  lastName={userDetails?.last_name}
                  tooltipPlacement={"bottomRight"}
                />
                <h5 className="my-3">
                  {userDetails?.first_name} {userDetails?.last_name}
                </h5>
              </div>
              <div className={styles.changePasswordButton}>
                <Button
                  type={ButtonType.default}
                  onClick={() => setShowChangePasswordModal(true)}
                  child={t("changePassword.changePassword")}
                />
                {showChangePasswordModal && (
                  <ChangePasswordModal
                    showChangePasswordModal={showChangePasswordModal}
                    setShowChangePasswordModal={setShowChangePasswordModal}
                  />
                )}
              </div>
            </div>
          )}
          <div
            className={styles.languageButton}
            data-cy="MyAccount_Wrapper_languages"
          >
            {languages.map(({ code, name }) => {
              return (
                <Button
                  aria-selected={i18n.language.split("-")[0] === code}
                  dataCy={`MyAccount_Wrapper_languages_button_${code}_${
                    i18n.language.split("-")[0] === code
                      ? "selected"
                      : "notSelected"
                  }`}
                  child={name}
                  onClick={() => changeLanguage(code)}
                  type={
                    i18n.language.split("-")[0] === code
                      ? ButtonType.primary
                      : ButtonType.default
                  }
                />
              );
            })}
          </div>
        </div>
        <div className={styles.notificationPreferences}>
          <h3>{t("notificationParametrization.preference")}</h3>
          <NotificationPreferences
            onSave={updateUserNotificationPreferences}
            fetchData={fetchUserNotificationPreferences}
          />
        </div>
      </div>
    </div>
  );
}
