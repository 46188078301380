import { Space } from "antd";
import { Link } from "react-router-dom";

export const documentRequestsColumns = [
  {
    title: "doc.plan.docRequest.documentType",
    dataIndex: "document_type",
    key: "document_type",
    render: (text: string) => <p>{text}</p>,
  },
  {
    title: "doc.plan.docRequest.subject",
    dataIndex: "subject",
    key: "subject",
    render: (subject: string) => (
      <p
        style={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        {subject}
      </p>
    ),
    width: 100,
  },
  {
    title: "doc.plan.docRequest.description",
    dataIndex: "description",
    key: "description",
    render: (text: string) => (
      <p
        style={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        {text}
      </p>
    ),
    width: "25%",
  },
  {
    title: "doc.plan.docRequest.created",
    dataIndex: "created_at",
    key: "createdAt",
    render: (createdAt: string) => (
      <>{new Intl.DateTimeFormat("en-GB").format(new Date(createdAt))}</>
    ),
  },
  {
    title: "doc.plan.docRequest.action",
    key: "action",
    dataIndex: "id",
    render: (id: number) => (
      <Space size="small">
        <Link to={`/account/plan/requests/view/${id}`}>View</Link>
      </Space>
    ),
  },
];
