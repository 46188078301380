import { MediaContent } from "Stores/media-models";
import { fetchBlobObjectTanstack } from "app/stores/content/contentClient";
import MediaAudioItemWrapper from "./MediaAudioItemWrapper";
import { GlobalStore } from "@armtekfrance/webgate/dist";

export default function MediaPickerModalAudioWrapper({
  audioItem,
  checked,
  onCheckedChange,
  convertToBase64 = true,
}: {
  audioItem: MediaContent;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  convertToBase64?: boolean;
}) {
  GlobalStore.media.setAudioSource(
    fetchBlobObjectTanstack(
      audioItem.media_content_uid,
      false,
      false,
      1000 * 60 * 60 * 24 * 7
    )
  );

  // TODO To be improved with audio metadata

  return (
    <>
      {GlobalStore.media.getAudioSource().status === "success" && (
        <MediaAudioItemWrapper
          fileName={audioItem.name}
          source={`data:${
            GlobalStore.media.getAudioSource().blobObject?.media.ContentType
          };${convertToBase64 ? "base64," : ""} ${
            GlobalStore.media.getAudioSource().blobObject?.media.file
          }`}
          checked={checked}
          onCheckedChange={onCheckedChange}
        />
      )}
    </>
  );
}
