import toRegexRange from "to-regex-range";

export const generateRegex = (start: number, end: number) => {
  if (start && end) {
    const source = toRegexRange(start, end);
    // const regex = new RegExp(`^${source}$`);
    return source;
  } else {
    return null;
  }
};

export const countDecimal = (checkNum: number) => {
  if (Math.floor(checkNum.valueOf()) === checkNum.valueOf()) return 0;
  //change this here to split by , or .
  return checkNum.toString().split(".")[1].length || 0;
};

export const calculateIntervalForMargin = (
  value: number,
  margin: number,
  calcValue: string
) => {
  if (calcValue.toUpperCase() === "PERCENTAGE") {
    const percentValue = margin / 100;
    return [value - margin * percentValue, value + margin * percentValue];
  } else {
    return [value - margin, value + margin];
  }
};

export const updateRegexForDecimal = (
  regexValue: string | null,
  decimalPlaces: number
) => {
  if (regexValue) {
    const regexToString = regexValue + "";
    let splitRegex = regexToString.split("|");
    for (let i = 0; i < splitRegex.length; i++) {
      let indexString = splitRegex[i];
      let newString = "";
      //This is for the first condition. This will have special characters at beginning of regex
      if (i === 0) {
        //This condition is if there is no range to match. It contains only numbers, so just set the decimal value
        if (indexString.charAt(indexString.length - 1) === "Ø") {
          newString =
            indexString.substring(0, indexString.length - 1 - decimalPlaces) +
            // eslint-disable-next-line
            "+\\." +
            indexString.substring(indexString.length - 1 - decimalPlaces);
          //The first condition usually contains only one range to match. So to check for that and to add the decimal point depending on the number of decimal places
        } else {
          newString =
            indexString.substring(0, indexString.length - 4 - decimalPlaces) +
            "+\\." +
            indexString.substring(indexString.length - 4 - decimalPlaces);
        }
        //This is for the last matching condition. This will contain special character at beginning to handle
      } else if (i === splitRegex.length - 1) {
        if (indexString.charAt(indexString.length - 2) === "0") {
          //This condition is if there is no range to match. It contains only numbers, so just set the decimal value
          newString =
            indexString.substring(0, indexString.length - 1 - decimalPlaces) +
            "+\\." +
            indexString.substring(
              indexString.length - 1 - decimalPlaces,
              indexString.length - 1
            ) +
            `{0,${decimalPlaces}})`;
        } else {
          //The last condition usually contains only one range to match. So to check for that and to add the decimal point depending on the number of decimal places
          newString =
            indexString.substring(0, indexString.length - 5 - decimalPlaces) +
            "+\\." +
            indexString.substring(
              indexString.length - 5 - decimalPlaces,
              indexString.length - 1
            ) +
            `{0,${decimalPlaces}})`;
        }
        //no special character to ignore. This is for all the other conditions in between
      } else {
        //This is to set for 1 decimal place
        if (decimalPlaces === 1) {
          if (indexString.charAt(indexString.length - 1) === "]") {
            newString =
              indexString.substring(0, indexString.length - 5) +
              "+\\." +
              indexString.substring(indexString.length - 5) +
              "{0,1}";
          }
          //This condition is to set for 2 decimal places
        } else if (decimalPlaces === 2) {
          if (indexString.charAt(indexString.length - 1) === "}") {
            if (indexString.charAt(indexString.length - 2) === "3") {
              newString =
                indexString.substring(0, indexString.length - 3) +
                "+\\.[0-9]" +
                "{0,2}";
            } else if (indexString.charAt(indexString.length - 2) === "2") {
              newString =
                indexString.substring(0, indexString.length - 8) +
                "+\\." +
                indexString.substring(
                  indexString.length - 8,
                  indexString.length - 3
                ) +
                "{0,2}";
            }
          } else if (indexString.charAt(indexString.length - 6) !== "]") {
            newString =
              indexString.substring(0, indexString.length - 4 - decimalPlaces) +
              "+\\." +
              indexString.substring(indexString.length - 4 - decimalPlaces) +
              "{0,2}";
          } else if (indexString.charAt(indexString.length - 6) === "]") {
            if (indexString.charAt(indexString.length - 9) === "[") {
              newString =
                indexString.substring(0, indexString.length - 9) +
                "+\\." +
                indexString.substring(indexString.length - 9) +
                "{0,2}";
            } else {
              newString =
                indexString.substring(0, indexString.length - 10) +
                "+\\." +
                indexString.substring(indexString.length - 10) +
                "{0,2}";
            }
          }
          //This condition is to set for 3 decimal places
        } else if (decimalPlaces === 3) {
          if (indexString.charAt(indexString.length - 1) === "}") {
            if (indexString.charAt(indexString.length - 2) === "2") {
              newString =
                indexString.substring(0, indexString.length - 13) +
                "+\\." +
                indexString.substring(
                  indexString.length - 13,
                  indexString.length - 3
                ) +
                "{0,3}";
            } else if (indexString.charAt(indexString.length - 2) === "3") {
              newString =
                indexString.substring(0, indexString.length - 8) +
                "+\\." +
                indexString.substring(
                  indexString.length - 8,
                  indexString.length - 3
                ) +
                "{0,3}";
            } else if (indexString.charAt(indexString.length - 2) === "4") {
              newString =
                indexString.substring(0, indexString.length - 3) +
                "+\\.[0-9]" +
                "{0,3}";
            }
          } else if (indexString.charAt(indexString.length - 6) === "]") {
            newString =
              indexString.substring(0, indexString.length - 11) +
              "+\\." +
              indexString.substring(indexString.length - 11) +
              "{0,3}";
          } else if (indexString.charAt(indexString.length - 6) !== "[") {
            newString =
              indexString.substring(0, indexString.length - 4 - decimalPlaces) +
              "+\\." +
              indexString.substring(indexString.length - 4 - decimalPlaces) +
              "{0,3}";
          }
        }
      }
      splitRegex[i] = newString;
    }
    let regexForDecimal = "";
    splitRegex.forEach((e, index) => {
      regexForDecimal += e; //Update this to add | in between
      if (index !== splitRegex.length - 1) {
        regexForDecimal += "|";
      }
    });
    return regexForDecimal;
  }
};
