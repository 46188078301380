import React from "react";
import styles from "./EditCompanyImages.module.scss";
import { CompanyLogo } from "@armtekfrance/webgate/dist";

export default function EditCompanyImages() {
  return (
    <div className={styles.editCompanyImagesWrapper}>
      <CompanyLogo />
    </div>
  );
}
