import { fetchUsersTanStack } from "app/stores/users/userClient";
import ObsoleteReleasesHeroSearch from "./ObsoleteReleasesHeroSearch/ObsoleteReleasesHeroSearch";
import { useObsoleteReleasesDispatch } from "../Provider/ObsoleteReleasesContext";
import { useEffect } from "react";
import ObsoleteReleasesHeroDropDownAuthor from "./ObsoleteReleasesHeroDropDownAuthor/ObsoleteReleasesHeroDropDownAuthor";
import ObsoleteReleasesHeroDropDownValidator from "./ObsoleteReleasesHeroDropDownValidator/ObsoleteReleasesHeroDropDownValidator";
import ObsoleteReleasesHeroDropDonwTimeExpired from "./ObsoleteReleasesHeroDropDownTimeExpired/ObsoleteReleasesHeroDropDownTimeExpired";
import styles from "./ObsoleteReleasesHero.module.scss";

export default function ObsoleteReleasesHero() {
  const dispatchObsoleteReleases = useObsoleteReleasesDispatch();
  const {
    status: statusUsers,
    error: errorUsers,
    users,
  } = fetchUsersTanStack();

  useEffect(() => {
    dispatchObsoleteReleases({
      type: "setUsersTanstack",
      setUsersTanstack: { statusUsers, errorUsers, users },
    });
  }, [statusUsers, errorUsers, users]);

  return (
    <div className={styles.obsoleteReleasesHeroWrapper}>
      <ObsoleteReleasesHeroSearch />
      <ObsoleteReleasesHeroDropDownAuthor />
      <ObsoleteReleasesHeroDropDownValidator />
      <ObsoleteReleasesHeroDropDonwTimeExpired />
    </div>
  );
}
