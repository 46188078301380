import { ReactElement } from "react";
import styles from "./InputCheckboxBlock.module.scss";
import { Checkbox } from "antd";
import { WarningOutlined } from "@ant-design/icons";

interface IInputCheckBox {
  title: string;
  options: {
    key: number;
    value: string;
    correct: boolean;
  }[];
  userInput: number[];
  alertOccured: boolean;
}

// TODO line 57 ans= ?

export default function InputQuizBlock({
  title,
  options,
  userInput,
  alertOccured,
}: IInputCheckBox): ReactElement {
  const keyIsSelected = (keyId: number) => {
    return userInput.includes(keyId - 1);
  };

  return (
    <div
      style={{
        border: alertOccured ? "3px red solid" : "",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}
      <h3>{title}</h3>
      <div className={styles.options}>
        {options.map((option) => {
          return (
            <div
              className={option.correct ? styles.optionGood : styles.optionBad}
            >
              <Checkbox
                className={styles.checkbox}
                checked={keyIsSelected(option.key)}
              ></Checkbox>
              <h4>{option.value}</h4>
              <h4>ans= {JSON.stringify(option.correct)}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
}
