import { createContext, Dispatch, useContext } from "react";
import {
  stateNotificationsProvider,
  TypeNotificationsContextReducerAction,
} from "./NotificationsReducer";

export const NotificationsContext = createContext<stateNotificationsProvider>({
  statusNotifications: "loading",
  errorNotifications: null,
  notifications: [],
  paginationNotifications: { page: 1, pageSize: 10, total: -1 },

  statusNotificationsCount: "loading",
  errorNotificationsCount: null,
  notificationsCount: -1,

  notificationsByDay: [],
  openedNotificationsByDay: [],
  closedNotificationsByDay: [],

  previewInfo: {
    typeOfPreview: "PROCEDURE",
    idOfPreview: -1,
    isOpen: false,
  },
});

export const NotificationsDispatchContext = createContext<
  Dispatch<TypeNotificationsContextReducerAction>
>(() => {
  return { type: "" };
});

export function useNotifications() {
  return useContext(NotificationsContext);
}

export function useNotificationsDispatch() {
  return useContext(NotificationsDispatchContext);
}
