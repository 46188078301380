import { Link } from "react-router-dom";
import { ReactComponent as GearIcon } from "./icons/GearIcon.svg";

export default function SettingGear({
  dataCy = "SettingGear",
}: {
  dataCy?: string;
}) {
  return (
    <Link
      to={"/account/settings/myaccount"}
      style={{
        display: "flex",
        width: "min-content",
        alignItems: "center",
        position: "relative",
      }}
      data-cy={dataCy}
    >
      <GearIcon />
    </Link>
  );
}
