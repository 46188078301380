import { useEffect, useRef } from "react";
import styles from "./Modal.module.scss";
export default function Modal({
  isOpen,
  autoSize = false,
  onModalClose = () => {
    return;
  },
  children,
}: {
  isOpen: boolean;
  autoSize?: boolean;
  onModalClose?: () => void;
  children: JSX.Element | JSX.Element[];
}) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (!dialogRef.current) return;
    dialogRef.current.addEventListener("close", onModalClose);
    return () => {
      dialogRef.current?.removeEventListener("close", onModalClose);
    };
  }, []);
  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    if (isOpen === true) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }, [dialogRef, isOpen]);
  return (
    <dialog
      data-auto-size={autoSize}
      className={styles.modalWrapper}
      ref={dialogRef}
    >
      {children}
    </dialog>
  );
}
