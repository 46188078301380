import Home from "../pages/Home";
import AuthLayout from "../components/AuthLayout/AuthLayout";
import TopHeaderMenuNavigationLayout from "../Layout/TopHeaderMenuNavigationLayout";
import Dashboard from "../pages/DashboardLayout/DashboardLayout";
import NotificationsOrAlertsRoot from "../pages/Notifications/NotificationsOrAlertsRoot";
import PlanIndex from "../pages/PlanLayout/PlanIndex";
import PlanLayout from "../pages/PlanLayout/PlanLayout";
import DocumentRequestLayout from "../pages/DocumentRequestsLayout/DocumentRequestLayout";
import ViewDocumentRequest from "../pages/ViewDocumentRequest";
import ModelIndex from "../pages/Model/ModelIndex";
import ModelLinkage from "../pages/ModelLinkageLayout/ModelLinkage";
import SystemIndex from "../pages/System/SystemIndex";
import ObsoleteReleasesRoot from "../pages/System/ObsoleteReleases/ObsoleteReleasesRoot";
import AssembleLayout from "../pages/AssembleLayout";
import AnalyticsIndex from "../pages/AnalyticsLayout";
import ResultsIndex from "../pages/Results/ResultsIndex";
import ExecutionFluxRoot from "../pages/Results/ExecutionFlux/ExecutionFluxRoot";
import AdvancedAnalysis from "../pages/AdvancedAnalysisLayout/AdvancedAnalysis";
import MyAccount from "../pages/MyAccount/MyAccount";
import CompanyUsersLayout from "../pages/CompanyUsersLayout/CompanyUsersLayout";
import TeamLayoutRoot from "../pages/TeamLayout/TeamLayoutRoot";
import CompanyDepartmentsLayout from "../pages/CompanyDepartmentsLayout/CompanyDepartmentsLayout";
import CompanyLayout from "../pages/CompanyLayout/CompanyLayout";
import Error403 from "../pages/Error403";
import TopHeaderLayout from "../Layout/TopHeaderLayout";
import ModelInfoPage from "../pages/ModelInfoPage/ModelInfoPage";
import ModelEditPage from "../pages/ModelEditPage/KnowledgeModelEditorPage";
import BuildInfo from "../pages/BuildInfo/BuildInfo";
import NotFound from "../pages/NotFound/NotFound";
import React, { FC } from "react";
import { Route, RouteObject } from "react-router-dom";
import { AppState } from "@armtekfrance/webgate/dist";
import { RouteConfig } from "./NavigationModels";
import { JSX } from "react/jsx-runtime";
import { StatusSwitch } from "@armtekfrance/webgate/dist";
import { Spinner } from "@armtekfrance/armtek-react-design-system";
import BoatPage from "../pages/BoatPage/boat-page";
import KnowledgeIndex from "../pages/Knowledge/KnowledgeIndex";
import HumanIndex from "../pages/Human/HumanIndex";
import DocumentsModelsProvider from "components/DocumentModels/Providers/DocumentModelsProvider";
import OperatePage from "pages/OperatePage/OperatePage";
import AssignPage from "pages/AssignPage/AssignPage";
import SystemReleaseIndex from "../pages/SystemRelease/SystemReleaseIndex";
import SystemConstructionPage from "pages/SystemConstructionPage/SystemConstructionPage";
import ValidationStatusProvider from "pages/System/ValidationStatus/Provider/ValidationStatusProvider";
import Validation from "pages/System/ValidationStatus/Validation";
import AssignIndex from "pages/AssignPage/AssignIndex";
import AdvancedAnalysisPage from "pages/AdvancedAnalysisPage/AdvancedAnalysisPage";
import ReleasePage from "pages/ReleasePage/ReleasePage";
import { PdfParamsHandler } from "components/Pdf/PdfParamsHandler";
import MigrationBatchPage from "pages/MigrationBatchPage/MigrationBatchPage";
import ErrorElement from "components/Error/errorComponent";
import { ApiDocumentFamilyEnum } from "@armtekfrance/corenexus-pn-sn";
import SystemModelEditorPage from "pages/System/ModelEditor/SystemModelEditorPage";
import { DocumentsModelsPage } from "pages/Model/DocumentsModelsPage";

const LoadingSection: FC<{ message?: string }> = ({ message }): JSX.Element => {
  return (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ textAlign: "center" }}>
        <Spinner />
        <br />
        {message}
      </p>
    </div>
  );
};

const DEFAULT_STATE: AppState = {
  READY: <NotFound />,
  INITIALIZING: (
    <LoadingSection message={"Loading additional data..."}></LoadingSection>
  ),
  AUTHENTICATED: (
    <LoadingSection
      message={"Authenticated ! fetching user..."}
    ></LoadingSection>
  ),
  AUTHENTICATING: (
    <LoadingSection message={"Authenticating..."}></LoadingSection>
  ),
  AUTHFAILED: <AuthLayout />,
  ONLINE: (
    <LoadingSection message={"Setting up everything..."}></LoadingSection>
  ),
  OFFLINE: (
    <LoadingSection message={"Looking for a connection..."}></LoadingSection>
  ),
};

const state = (custom_state: AppState): AppState => {
  return { ...DEFAULT_STATE, ...custom_state };
};

const generateChildRoutes = (
  children: Array<RouteConfig | JSX.Element> = []
) => {
  return children.map((child: RouteConfig | JSX.Element) => {
    if (React.isValidElement(child)) {
      return child;
    } else if ("state" in child || "element" in child) {
      return route(child);
    } else {
      // impossible
    }
  });
};
const route = (config: RouteConfig) => {
  if (config.index) {
    // is Index route
    return (
      <Route
        index
        element={<StatusSwitch stateDescriptor={state(config.state)} />}
      />
    );
  } else if ("state" in config) {
    // stateful route
    return (
      <Route
        path={config.path}
        element={<StatusSwitch stateDescriptor={state(config.state)} />}
      >
        {generateChildRoutes(config.children)}
      </Route>
    );
  } else if ("element" in config) {
    /// Element route
    return (
      <Route path={config.path} element={config.element}>
        {generateChildRoutes(config.children)}
      </Route>
    );
  } else {
    /// can't happen
  }
};
const elementState = (elementState: AppState): JSX.Element => {
  return <StatusSwitch stateDescriptor={state(elementState)} />;
};

export type paths =
  | "/"
  | ""
  | "login"
  | "account"
  | "account/dashboard"
  | "account/notifications"
  | "account/alerts"
  | "account/plan"
  | "account/plan/outline"
  | "account/plan/requests"
  | "account/plan/linkage"
  | "account/plan/requests/:view/:id"
  | "account/knowledge/models"
  | "account/knowledge/models/list"
  | "account/knowledge/models/linkage"
  | "account/knowledge/models/info/:id"
  | "account/knowledge/models/edit/:id"
  | "account/knowledge/models/edit/:id/:sectionId"
  | "account/knowledge"
  | "account/knowledge/releases"
  | "account/knowledge/validate"
  | "account/knowledge/obsoleteReleases"
  | "account/knowledge/assign"
  | "account/knowledge/results"
  | "account/knowledge/results/executionFlux"
  | "account/knowledge/results/executionFlux/exportpdf/:id"
  | "account/knowledge/results/executionFlux/:executionId"
  | "account/system/models/list"
  | "account/system/models/edit/:id"
  | "account/system/models/edit/diff/:id"
  | "account/system/validate"
  | "account/system/releases"
  | "account/system/assign/constructor/:id"
  | "account/system/assign"
  | "account/system/operate"
  | "account/human/models"
  | "account/human/validate"
  | "account/human/releases"
  | "account/human/assign"
  | "account/human/operate"
  | "account/assemble"
  | "account/automations"
  | "account/analytics"
  | "account/analytics/overview"
  | "account/analytics/know-how"
  | "account/analytics/advanced"
  | "account/settings"
  | "account/settings/myaccount"
  | "account/settings/users"
  | "account/settings/teams"
  | "account/settings/departments"
  | "account/settings/company"
  | "account/settings/analysis"
  | "account/error-403"
  | "buildInfo";

export const ROUTES: RouteObject[] = [
  {
    path: "/",
    element: elementState({ AUTHFAILED: <Home />, READY: <Home /> }),
  },
  {
    path: "login",
    element: <AuthLayout />,
  },
  {
    path: "account",
    element: elementState({ READY: <TopHeaderMenuNavigationLayout /> }),
    errorElement: <ErrorElement />,
    children: [
      { path: "dashboard", element: <Dashboard /> },
      {
        path: "notifications",
        element: (
          <NotificationsOrAlertsRoot typeOfNotidications="NOTIFICATIONS" />
        ),
      },
      {
        path: "alerts",
        element: <NotificationsOrAlertsRoot typeOfNotidications="ALERTS" />,
      },
      {
        path: "plan",
        element: <PlanIndex />,
        children: [
          { path: "outline", element: <PlanLayout /> },
          { path: "requests", element: <DocumentRequestLayout /> },
          { path: "linkage", element: <ModelLinkage /> },

          {
            path: "requests/:view/:id",
            element: <ViewDocumentRequest />,
          },
        ],
      },
      {
        path: "system",
        element: <SystemIndex />,
        children: [
          {
            path: "models",
            element: (
              <ModelIndex
                mainRoute="/account/system/models"
                relativeDefault="list"
              />
            ),
            children: [
              {
                path: "list",
                element: (
                  <DocumentsModelsPage
                    documentFamily={ApiDocumentFamilyEnum.SYSTEM}
                  />
                ),
              },
              {
                path: "edit/:id",
                element: <SystemModelEditorPage />,
              },
              {
                path: "edit/diff/:id",
                element: <MigrationBatchPage />,
              },
            ],
          },
          {
            path: "validate",
            element: (
              <ValidationStatusProvider>
                <Validation document_family="SYSTEM" />
              </ValidationStatusProvider>
            ),
          },
          {
            path: "releases",
            element: <SystemReleaseIndex />,
            children: [
              {
                path: "list",
                element: <ReleasePage type="SYSTEM" />,
              },
            ],
          },
          {
            path: "assign",
            // element: <BoatPage pageName={"Knowledge assign page"} />,
            element: <AssignIndex />,
            children: [
              {
                path: "list",
                element: <AssignPage />,
              },
              {
                path: "constructor/:baselineId/:constructorId/:objectId",
                element: <SystemConstructionPage />,
              },
            ],
          },
          {
            path: "operate",
            element: <OperatePage />,
          },
        ],
      },
      {
        path: "knowledge",
        element: <KnowledgeIndex />,
        children: [
          {
            path: "models",
            element: (
              <ModelIndex
                mainRoute="/account/knowledge/models"
                relativeDefault="list"
              />
            ),
            children: [
              {
                path: "list",
                element: (
                  <DocumentsModelsPage
                    documentFamily={ApiDocumentFamilyEnum.KNOWLEDGE}
                  />
                ),
              },
              { path: "linkage", element: <ModelLinkage /> },
              { path: "info/:id", element: <ModelInfoPage /> },
              { path: "edit/:id", element: <ModelEditPage /> },
              {
                path: "edit/:id/:sectionId",
                element: <ModelEditPage />,
              },
            ],
          },
          {
            path: "releases",
            element: <ReleasePage type="KNOWLEDGE" />,
          },
          {
            path: "validate",
            element: (
              <ValidationStatusProvider>
                <Validation document_family="KNOWLEDGE" />
              </ValidationStatusProvider>
            ),
          },
          {
            path: "obsoleteReleases",
            element: <ObsoleteReleasesRoot />,
          },
          {
            path: "assign",
            element: <AssignPage />,
          },
          {
            path: "results",
            element: <ResultsIndex />,
            children: [
              {
                path: "executionFlux",
                element: <ExecutionFluxRoot />,
              },
              {
                path: "executionFlux/:executionId",
                element: <ExecutionFluxRoot />,
              },
            ],
          },
        ],
      },
      {
        path: "human",
        element: <HumanIndex />,
        children: [
          {
            path: "models",
            element: <BoatPage pageName={"Human models page"} />,
          },
          {
            path: "validate",
            element: <BoatPage pageName={"Human validate page"} />,
          },
          {
            path: "releases",
            element: <BoatPage pageName={"Human releases page"} />,
          },
          {
            path: "assign",
            element: <BoatPage pageName={"Human assign page"} />,
          },
          {
            path: "operate",
            element: <OperatePage />,
          },
        ],
      },
      { path: "assemble", element: <AssembleLayout /> },
      {
        path: "analytics",
        element: <AnalyticsIndex />,
        children: [
          {
            path: "advanced",
            element: <AdvancedAnalysisPage />,
          },
        ],
      },
      {
        path: "settings",
        children: [
          { path: "myaccount", element: <MyAccount /> },
          { path: "users", element: <CompanyUsersLayout /> },
          { path: "teams", element: <TeamLayoutRoot /> },
          {
            path: "departments",
            element: <CompanyDepartmentsLayout />,
          },
          { path: "company", element: <CompanyLayout /> },
          {
            path: "analysis",
            element: <AdvancedAnalysis readOnly={false} />,
          },
        ],
      },
      { path: "error-403", element: <Error403 /> },
    ],
  },
  {
    path: "account",
    element: elementState({ READY: <TopHeaderLayout /> }),
    children: [
      {
        path: "system",
        element: <SystemIndex />,
        children: [
          {
            path: "models",
            element: (
              <DocumentsModelsProvider>
                <DocumentsModelsPage
                  documentFamily={ApiDocumentFamilyEnum.SYSTEM}
                />
              </DocumentsModelsProvider>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "buildInfo",
    element: <BuildInfo />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "pdfExport",
    element: <PdfParamsHandler />,
  },
];
