import { fetchBaselineValidationsTanStack } from "app/stores/documentBaselineValidation/documentsBaselineValidationClient";
import ObsoleteReleasesHero from "./Hero/ObsoleteReleasesHero";
import {
  useObsoleteReleases,
  useObsoleteReleasesDispatch,
} from "./Provider/ObsoleteReleasesContext";
import PaginationLayout from "components/PaginationLayout";
import { useEffect } from "react";
import ObsoleteReleasesTable from "./ObsoleteReleasesTable";

export default function ObsoleteReleases() {
  const obsoleteReleasesContext = useObsoleteReleases();
  const dispatchObsoleteReleases = useObsoleteReleasesDispatch();
  const {
    status: statusBaselineValidations,
    error: errorBaselineValidations,
    baselineValidations,
  } = fetchBaselineValidationsTanStack(
    obsoleteReleasesContext.paginationBaselineValidations.page,
    obsoleteReleasesContext.paginationBaselineValidations.pageSize,
    {
      authors: obsoleteReleasesContext.authors
        .filter((author) => author.selected)
        .map((author) => author.id),
      validators: obsoleteReleasesContext.validators
        .filter((validator) => validator.selected)
        .map((validator) => validator.id),
      name: { contains: obsoleteReleasesContext.queryNameTextFilter },
      expirationStartDate: obsoleteReleasesContext.qureyStartDate,
      expirationEndDate: obsoleteReleasesContext.queryEndDate,
      family: "organisation",
    }
  );

  useEffect(() => {
    dispatchObsoleteReleases({
      type: "setBaselineValidationsTanstackApi",
      setBaselineValidationsTanstackApi: {
        statusBaselineValidations,
        errorBaselineValidations,
      },
    });
    if (statusBaselineValidations === "success") {
      dispatchObsoleteReleases({
        type: "setBaselineValidationsTanstack",
        setBaselineValidationsTanstack: {
          baselineValidations: baselineValidations?.baselineValidations,
          paginationBaselineValidations: baselineValidations?.pagination ?? {
            page: 1,
            pageSize: 10,
            total: -1,
          },
        },
      });
    }
  }, [
    obsoleteReleasesContext.authors,
    obsoleteReleasesContext.validators,
    obsoleteReleasesContext.users,
    obsoleteReleasesContext.queryNameTextFilter,
    obsoleteReleasesContext.qureyStartDate,
    obsoleteReleasesContext.queryEndDate,
    statusBaselineValidations,
    errorBaselineValidations,
    baselineValidations,
  ]);

  function setPaginationPagePageSize(page: number, pageSize: number) {
    dispatchObsoleteReleases({
      type: "setPaginationBaselineValidations",
      paginationBaselineValidations: {
        page: page,
        pageSize: pageSize,
        total: baselineValidations!.pagination.total ?? -1,
      },
    });
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem",
        margin: "1rem 40px",
      }}
    >
      <ObsoleteReleasesHero />
      {obsoleteReleasesContext.statusBaselineValidations === "success" && (
        <>
          <ObsoleteReleasesTable
            procedures={obsoleteReleasesContext!.baselineValidations}
            loading={false}
          />
          <PaginationLayout
            page={obsoleteReleasesContext!.paginationBaselineValidations.page}
            pageSize={
              obsoleteReleasesContext!.paginationBaselineValidations.pageSize
            }
            total={obsoleteReleasesContext!.paginationBaselineValidations.total}
            onChangePagination={setPaginationPagePageSize}
          />
        </>
      )}
      {obsoleteReleasesContext.statusBaselineValidations === "loading" && (
        <>
          <ObsoleteReleasesTable procedures={[]} loading={true} />
          <PaginationLayout
            page={obsoleteReleasesContext!.paginationBaselineValidations.page}
            pageSize={
              obsoleteReleasesContext!.paginationBaselineValidations.pageSize
            }
            total={obsoleteReleasesContext!.paginationBaselineValidations.total}
            onChangePagination={setPaginationPagePageSize}
          />
        </>
      )}
    </div>
  );
}
