import Request, { pageFormat } from "../../../libs/requests";

// Company services
export const fetchLinkageNodes = (
  pageNum?: number
  // sort: any = { created_at: "desc" }
) => {
  // const sortParams = sortFormat(sort);

  // navigation.to("account/settings/departments");

  return Request()
    .get("/documents/linkage", {
      params: {
        ...pageFormat(pageNum || 1),
        // ...filterParams,
        // ...sortParams,
      },
    })
    .then((response) => response.data);
};

export const createLinkageNode = (nodeData: any) => {
  return Request()
    .post(`/documents/linkage`, nodeData)
    .then((response) => response.data);
};

export const editLinkageNode = (id: number, nodeData: any) => {
  return Request()
    .put(`/documents/linkage/${id}`, nodeData)
    .then((response) => response.data);
};

export const deleteLinkageNode = (id: number) => {
  return Request()
    .delete(`/documents/linkage/${id}`)
    .then((response) => response.data);
};
