import React from "react";
import { Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { Switch } from "@armtekfrance/armtek-react-design-system";

interface AdvancedAnalysisPopUpProps {
  isModalOpened: boolean;
  setIsModalOpened: (value: boolean) => void;
  createdDashboardName: string;
  setCreatedDashboardName: (value: string) => void;
  createdDashboardId: string;
  setCreatedDashboardId: (value: string) => void;
  graphPrivacy: string;
  setGraphPrivacy: (value: string) => void;
  graphMode: string;
  setGraphMode: (value: string) => void;
  isModalToCreate: boolean;
  setisModalToCreate: (value: boolean) => void;
  createDashboardMetabase: () => void;
  updateDashboardMetabase: () => void;
  setIsExecutionGraph: (value: boolean) => void;
  isExecutionGraph: boolean;
}

const AdvancedAnalysisPopUp = ({
  isModalOpened,
  setIsModalOpened,
  createdDashboardName,
  setCreatedDashboardName,
  createdDashboardId,
  setCreatedDashboardId,
  graphPrivacy,
  setGraphPrivacy,
  graphMode,
  setGraphMode,
  isModalToCreate,
  setisModalToCreate,
  createDashboardMetabase,
  updateDashboardMetabase,
  setIsExecutionGraph,
  isExecutionGraph,
}: AdvancedAnalysisPopUpProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isModalOpened}
      onCancel={() => {
        setIsModalOpened(false);
        setCreatedDashboardName("");
        setCreatedDashboardId("");
        setisModalToCreate(false);
        setIsExecutionGraph(false);
      }}
      title={
        isModalToCreate
          ? t("advancedAnalysis.createDashboard")
          : t("advancedAnalysis.updateDashboard")
      }
      onOk={() => {
        if (isModalToCreate) {
          createDashboardMetabase();
        } else {
          updateDashboardMetabase();
        }
        setIsModalOpened(false);
        setCreatedDashboardName("");
        setCreatedDashboardId("");
        setisModalToCreate(false);
        setIsExecutionGraph(false);
      }}
    >
      <div>
        <div style={{ marginBottom: "16px" }}>
          <TextArea
            value={createdDashboardName}
            placeholder={"Nom du dashboard"}
            onChange={(e: any) => {
              setCreatedDashboardName(e.target.value);
            }}
            autoSize
            maxLength={250}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <TextArea
            value={createdDashboardId}
            placeholder={"ID du dashboard"}
            onChange={(e: any) => {
              setCreatedDashboardId(e.target.value);
            }}
            autoSize
            maxLength={250}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Select privacy mode"}
            defaultValue={graphPrivacy ? graphPrivacy : "company"}
            onChange={(value: string) => {
              setGraphPrivacy(value);
            }}
          >
            <Select.Option value="company">Company</Select.Option>
            <Select.Option value="public">Public</Select.Option>
          </Select>
        </div>
        <div style={{ marginBottom: "16px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Select graph type"}
            defaultValue={graphMode ? graphMode : "dashboard"}
            onChange={(value: string) => {
              setGraphMode(value);
            }}
          >
            <Select.Option value="dashboard">Dashboard</Select.Option>
            <Select.Option value="question">Question</Select.Option>
          </Select>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            flexDirection: "row",
          }}
        >
          <div>Execution analysis</div>
          <Switch
            checked={isExecutionGraph}
            onChange={(value) => setIsExecutionGraph(value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AdvancedAnalysisPopUp;
