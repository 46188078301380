import Request, {
  filterFormat,
  pageFormat,
  pageSizeFormat,
  sortFormat,
} from "../../../libs/requests";

export const fetchBlockBaselines = (
  filters = {},
  sort = { position: "asc" },
  page?: number,
  pageSize?: number | "0"
) => {
  const sortParams = sortFormat(sort);
  const filterParams = filterFormat(filters);
  let reqParams = {
    ...filterParams,
    ...sortParams,
  };
  if (page) {
    const pageParams = pageFormat(page);
    reqParams = {
      ...reqParams,
      ...pageParams,
    };
  }
  if (pageSize) {
    const pageSizeParams = pageSizeFormat(pageSize);
    reqParams = {
      ...reqParams,
      ...pageSizeParams,
    };
  }
  return Request()
    .get("/block-baselines", {
      params: reqParams,
    })
    .then((response) => response.data);
};
