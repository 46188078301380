import { Button, Col, Input, message, Modal, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { fetchUsers } from "../../app/stores/users/userClient";
import { createDocumentBaselineValidation } from "../../app/stores/documentsBaseline/documentsBaselineClient";
import { publishDocument } from "../../app/stores/documents/documentsClient";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function PublishModalComponent({
  isPublishModalVisible,
  setIsPublishModalVisible,
  id,
}: any) {
  const { t } = useTranslation();

  const [userList, setUserList] = useState<any>([]);
  const [versionName, setVersionName] = useState("");
  const [selectedValidator, setSelectedValidators] = useState([]);

  const [publishingLoading, setPublishingLoading] = useState(false);

  const userListOptions = userList?.map((user: any) => {
    return { label: `${user.first_name} ${user.last_name}`, value: user.id };
  });

  const handleChange = (value: any) => {
    setSelectedValidators(value);
  };

  useEffect(() => {
    fetchUsers().then((response) => {
      setUserList(response);
    });
  }, []);

  const onTextChangeForVersion = (e: any) => {
    setVersionName(e.target.value);
  };

  const onPublishError = () => {
    setVersionName("");
    setSelectedValidators([]);
    setIsPublishModalVisible(true);
    setPublishingLoading(false);
    message.error(t("doc.models.procedure.somethingWentWrong"));
  };

  const publishDocumentOnClick = () => {
    if (id) {
      setPublishingLoading(true);
      publishDocument(id, { version_name: versionName })
        .then((response) => {
          if (
            selectedValidator &&
            selectedValidator.length > 0 &&
            response?.data?.id
          ) {
            createDocumentBaselineValidation(
              response?.data?.id,
              selectedValidator
            )
              .then((resp) => {
                if (resp.success === false) {
                  onPublishError();
                } else {
                  setVersionName("");
                  setSelectedValidators([]);
                  setIsPublishModalVisible(false);
                  setPublishingLoading(false);
                  message.success(
                    t("doc.models.procedure.documentPublishedValidation")
                  );
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (!response.data || !response.data.id) {
            onPublishError();
          } else {
            setVersionName("");
            setSelectedValidators([]);
            setIsPublishModalVisible(false);
            setPublishingLoading(false);
            message.success(t("doc.models.procedure.docPublished"));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Modal
      title={t("doc.models.procedure.publishDocument")}
      open={isPublishModalVisible}
      onOk={publishDocumentOnClick}
      onCancel={() => setIsPublishModalVisible(false)}
      footer={[
        !publishingLoading && (
          <Button key="back" onClick={() => setIsPublishModalVisible(false)}>
            {t("button.cancel")}
          </Button>
        ),
        !publishingLoading && (
          <Button
            key="submit"
            onClick={publishDocumentOnClick}
            data-cy="Models-PublishWindowOKButton"
          >
            OK
          </Button>
        ),
      ]}
    >
      {!publishingLoading ? (
        <>
          <Input
            placeholder={t("doc.models.procedure.versionName")}
            style={{ height: 40, marginBottom: "12px" }}
            id="version_name"
            onChange={onTextChangeForVersion}
          />
          <div data-cy="Models-Info-PublishWindow-Selector">
            <Select
              placeholder={t("doc.models.procedure.selectValidators")}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              onChange={handleChange}
              options={userListOptions}
              filterOption={(input, option) =>
                option && typeof option.label === "string"
                  ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
            />
          </div>
        </>
      ) : (
        <Row>
          <Col span={6}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
            />
          </Col>
          <Col span={18}>
            <p style={{ fontWeight: "600", fontSize: "16", margin: "0px" }}>
              {t("doc.models.procedure.modelPublishing")}{" "}
            </p>
            <p style={{ fontWeight: "600", fontSize: "16" }}>
              {t("doc.models.edit.generalInformation.doNotRefresh")}
            </p>
          </Col>
        </Row>
      )}
    </Modal>
  );
}
