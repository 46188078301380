import ListView from "../ListView";
import { useTranslation } from "react-i18next";
import ProcedurePreview from "../ProcedurePreview";
import { useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineQrcode } from "react-icons/ai";
import { Button, Popover, Spin, Tooltip } from "antd";
import { loadingCols, loadingRows } from "../ListView/util";
import { fetchDocumentsBaselineAsPdf } from "../../app/stores/documentsBaseline/documentsBaselineClient";
import { fetchDocumentsBaselineByID } from "../../app/stores/documentsBaseline/documentsBaselineClient";
import QrCodeComponent from "../QrCodeComponent/QrCodeComponent";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";

interface TableTagType {
  _id: string;
  name: string;
  color: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
}

interface TableType {
  baselines: any[];
  tags: { [key: string]: TableTagType };
  loading: boolean;
  isListView: boolean;
  paginationDetails: any;
  onChangePagination: (page: number, pageSize: number) => void;
  setSelectedTableKeys: (selectedKeys: any[]) => void;
  selectedTableKey?: any[];
  archiveBaseline?: (docId: number | string) => void;
  showCheckBox?: boolean;
}

export default function BaselineTable({
  baselines,
  loading,
  isListView,
  paginationDetails,
  onChangePagination,
  setSelectedTableKeys,
  selectedTableKey,
  archiveBaseline,
  showCheckBox = true,
}: TableType) {
  const { t } = useTranslation();

  const { page, pageSize, total } = paginationDetails;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewDocumentId, setViewDocumentId] = useState("");

  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [documentModelToArchive, setDocumentModelToarchive] = useState<
    string | number | null
  >(null);

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const showModal = (docId: string) => {
    setViewDocumentId(docId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const generatePDFDocument = async (id: string) => {
    setIsPdfLoading(true);
    const controller = abortControllerRef.current;
    await fetchDocumentsBaselineAsPdf(id, controller.signal).then(
      (response) => {
        fetchDocumentsBaselineByID(id).then((responseBaseline) => {
          let fileURL = window.URL.createObjectURL(response.data);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${
            responseBaseline.data.name.replace(/,/g, "-") +
            " " +
            responseBaseline.data.version
          }.pdf`;
          alink.click();
          window.open(fileURL);
          setIsPdfLoading(false);
        });
      }
    );
  };

  useEffect(() => {
    const controller = abortControllerRef.current;
    return () => {
      controller.abort();
    };
  }, []);

  const columns = [
    {
      title: "QR",
      dataIndex: "qrCode",
      key: "qrcode",
      render: (qrCode: any) => (
        <>
          {qrCode ? (
            <Popover
              trigger="click"
              content={<QrCodeComponent qrCode={qrCode} />}
            >
              <Button icon={<AiOutlineQrcode size={24} />}></Button>
            </Popover>
          ) : qrCode === undefined ? (
            <Popover
              trigger="click"
              content={
                <p style={{ color: "#C1C1C1" }}>
                  {t("doc.models.linkage.loading")}
                </p>
              }
            >
              <Button icon={<AiOutlineQrcode size={24} />}></Button>
            </Popover>
          ) : (
            <p style={{ fontSize: "12px", color: "#C1C1C1" }}>
              {t("qrCode.noQrAssignedSmall")}
            </p>
          )}
        </>
      ),
    },
    {
      title: "doc.models.procedure.name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <p
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: string) => <>{text}</>,
    },
    {
      title: "doc.models.procedure.version",
      dataIndex: "version",
      key: "version",
      render: (text: string) => <p style={{ fontWeight: "bold" }}>{text}</p>,
    },
    // {
    //   title: "doc.models.procedure.steps",
    //   dataIndex: "_count",
    //   key: "Steps",
    //   render: (_count: any) => (
    //     <p>{_count.document_baseline_has_section_baseline}</p>
    //   ),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (status: string) =>
    //     status.toLowerCase() === "published" ? (
    //       <Tag color="green">{status}</Tag>
    //     ) : status.toLowerCase() === "in progress" ? (
    //       <Tag color="blue">{status}</Tag>
    //     ) : status.toLowerCase() === "validation" ? (
    //       <Tag color="orange">{status}</Tag>
    //     ) : (
    //       <Tag color="volcano">{status}</Tag>
    //     ),
    // },
    // {
    //   title: "Tags",
    //   key: "tags",
    //   dataIndex: "tags",
    //   render: (tags: TableTagType[]) => {
    //     return (
    //       <>
    //         {tags.map((tag) => {
    //           const color = tag && tag.color ? tag.color : "default";
    //           return (
    //             <Tag color={color} className={style.tag}>
    //               {tag.name.toUpperCase()}
    //             </Tag>
    //           );
    //         })}
    //       </>
    //     );
    //   },
    // },
    {
      title: "doc.models.procedure.published",
      dataIndex: "published_at",
      key: "published_at",
      render: (published_at: string) => (
        <>{new Intl.DateTimeFormat("en-GB").format(new Date(published_at))}</>
      ),
    },
    {
      title: "doc.models.procedure.expired",
      dataIndex: "expired_at",
      key: "expired_at",
      render: (expired_at: string) => (
        <>
          {expired_at ? (
            new Intl.DateTimeFormat("en-GB").format(new Date(expired_at))
          ) : (
            <p style={{ textAlign: "center" }}>-</p>
          )}
        </>
      ),
    },
    {
      title: "doc.models.procedure.action",
      key: "action",
      dataIndex: "id",
      render: (id: string, data: any) => (
        <div style={{ float: "left", paddingTop: "4px" }}>
          <Button type="primary" size="small" onClick={() => showModal(id)}>
            {t("organisation.view")}
          </Button>
          <Button
            size="small"
            onClick={() => generatePDFDocument(id)}
            style={{ marginLeft: "8px" }}
          >
            {t("doc.models.edit.generalInformation.exportAsPdf")}
          </Button>
          {archiveBaseline && (
            <Tooltip title={t("doc.models.procedure.archiveBaseline")}>
              <Button
                size="small"
                icon={<AiOutlineDelete />}
                onClick={() => {
                  setIsArchiveModalVisible(true);
                  setDocumentModelToarchive(data.document_model_id);
                }}
                style={{ marginLeft: "8px" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  // Loading skeleton for the table
  const loadingColumns = loadingCols(columns);

  return (
    <>
      <Spin
        spinning={isPdfLoading}
        tip={t("doc.models.linkage.loading")}
        size="large"
      >
        <ListView
          data={loading ? loadingRows : baselines}
          cardData={baselines}
          columns={loading ? loadingColumns : columns}
          loading={false}
          loadingCardData={loading}
          isListView={isListView}
          page={page}
          pageSize={pageSize}
          total={total}
          cardLink={""}
          onChangePagination={onChangePagination}
          setSelectedTableKeys={setSelectedTableKeys}
          selectedKeys={selectedTableKey}
          showCheckBox={showCheckBox}
        />
        <ProcedurePreview
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          documentId={parseInt(viewDocumentId)}
          page={"baselines"}
        />
      </Spin>
      <ConfirmActionModal
        isVisible={isArchiveModalVisible}
        handleCancel={() => setIsArchiveModalVisible(false)}
        handleConfirm={() => {
          if (documentModelToArchive && archiveBaseline) {
            archiveBaseline(documentModelToArchive);
          }
          setDocumentModelToarchive(null);
          setIsArchiveModalVisible(false);
        }}
        confirmInsideText={t("doc.models.procedure.archiveBaseline")}
        confirmHeaderText={t("archived.title")}
      />
    </>
  );
}
