import { Table } from "antd";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface headerColumns {
  title: string;
  dataIndex: string;
  key: string;
  render: any;
}

interface TableType {
  tableData: any[];
  loading: boolean;
  headerColumns: headerColumns[];
  rowKey: string;
  selectedKeys?: number[] | undefined;
  setSelectedTableKeys: (selectedKeys: any[]) => void;
  showCheckBox?: boolean;
}

export default function TableLayout({
  tableData,
  loading,
  headerColumns,
  rowKey,
  setSelectedTableKeys,
  selectedKeys = undefined,
  showCheckBox = true,
}: TableType) {
  const [selectedTableKeys, setSelectedTableKeysState] = useState<any[]>([]);
  const { Column } = Table;
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedKeys) {
      setSelectedTableKeysState(selectedKeys);
    }
  }, []);

  const updateSelectedTableKeys = (selectedRowKeys: React.Key[]) => {
    let viewIds = tableData.map((item) => item.id);
    let newDatas = selectedTableKeys.map((id) => {
      if (!viewIds.includes(id)) {
        return id;
      }
    });
    newDatas = newDatas.filter((item) => item !== undefined);
    newDatas = [...newDatas, ...selectedRowKeys];
    return newDatas;
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      let newDatas = updateSelectedTableKeys(selectedRowKeys);
      setSelectedTableKeysState(newDatas);
      setSelectedTableKeys(newDatas);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
    selectedRowKeys: selectedKeys ?? [],
  };

  return (
    <div className={style.librayTable}>
      <Table
        rowKey={rowKey}
        rowSelection={
          showCheckBox
            ? {
                type: "checkbox",
                ...rowSelection,
              }
            : undefined
        }
        loading={loading}
        dataSource={tableData}
        pagination={false}
      >
        {headerColumns?.map((columnItem) => (
          <Column
            title={t(columnItem.title) as string}
            dataIndex={columnItem.dataIndex}
            key={columnItem.key}
            render={columnItem.render}
          />
        ))}
      </Table>
    </div>
  );
}
