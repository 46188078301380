import { Button, Modal, Input, message } from "antd";
import { fetchCompanyInfo, fetchSingleCompanyUser } from "app/services/company";
import { getLoggedInUser, loginUser } from "app/stores/auth/authClient";
import { updateUserPassword } from "app/stores/users/userClient";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLock } from "react-icons/ai";

export default function ChangePasswordModal({
  showChangePasswordModal,
  setShowChangePasswordModal,
}: {
  showChangePasswordModal: boolean;
  setShowChangePasswordModal: (showChangePasswordModal: boolean) => void;
}) {
  const { t } = useTranslation();
  const [confirmCurrentPassword, setConfirmCurrentPassword] =
    useState<boolean>(false);
  const [currentPasswordError, setCurrentPasswordError] =
    useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const loggedInUser = getLoggedInUser();

  const onCancelModal = () => {
    setConfirmCurrentPassword(false);
    setCurrentPasswordError(false);
    setCurrentPassword("");
    setShowChangePasswordModal(false);
  };

  const onConfirmPassword = async () => {
    const userData = await fetchSingleCompanyUser(loggedInUser.id);
    const passResp = await loginUser(userData.data.email, currentPassword);
    if (!passResp?.accessToken) {
      setCurrentPasswordError(true);
      setConfirmCurrentPassword(false);
      return;
    } else {
      setCurrentPasswordError(false);
      setConfirmCurrentPassword(true);
    }
  };

  const [companyName, setCompanyName] = useState<string>("");
  useEffect(() => {
    fetchCompanyInfo().then((companyResp) => {
      if (companyResp.success) {
        setCompanyName(companyResp.data.name);
      }
    });
  }, []);
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&'.,;])[A-Za-z\d@$!%*?&'.,;]{8,}$/g;

  const [newPasswordValue, setNewPasswordValue] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<boolean>(true);
  const [reEnterPasswordError, setReEnterPasswordError] =
    useState<boolean>(false);
  const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

  const onChangeNewPassword = (e: any) => {
    let value = e.target.value;
    if (
      passwordRegex.test(value) &&
      !value
        .toLowerCase()
        .includes(companyName.replace(/\s/g, "").toLowerCase())
    ) {
      setNewPasswordError(false);
      setReEnterPasswordError(true);
      setNewPasswordValue(value);
      setEnableSubmit(false);
    } else {
      setNewPasswordError(true);
      setReEnterPasswordError(false);
      setEnableSubmit(false);
    }
  };

  const onChangeReTypePassword = (e: any) => {
    if (newPasswordValue !== e.target.value) {
      setReEnterPasswordError(true);
      setEnableSubmit(false);
    } else {
      setReEnterPasswordError(false);
      setEnableSubmit(true);
    }
  };

  const onSubmitNewPassword = async () => {
    //set the password here
    const resp = await updateUserPassword(newPasswordValue);
    if (resp?.success) {
      message.success(t("changePassword.passwordChangeSuccessful"));
      onCancelModal();
    } else {
      message.error(t("doc.models.procedure.somethingWentWrong"));
    }
  };

  return (
    <Modal
      style={{
        top: 30,
      }}
      open={showChangePasswordModal}
      title={t("changePassword.changePassword")}
      onCancel={onCancelModal}
      // onOk={onAddPictogram}
      footer={[
        <Button key="back" onClick={onCancelModal}>
          {t("button.cancel")}
        </Button>,
        !confirmCurrentPassword && (
          <Button type="primary" onClick={() => onConfirmPassword()}>
            {t("button.confirm")}
          </Button>
        ),
        confirmCurrentPassword && (
          <Button
            key="submit"
            type="primary"
            disabled={!enableSubmit}
            onClick={() => onSubmitNewPassword()}
          >
            {t("button.update")}
          </Button>
        ),
      ]}
    >
      {!confirmCurrentPassword && (
        <>
          {t("changePassword.confirmPassword")}
          <Input.Password
            prefix={<AiOutlineLock className="site-form-item-icon" />}
            type="password"
            placeholder={t("loginPage.password")}
            size="large"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          {currentPasswordError && (
            <p style={{ margin: "0px", fontSize: "12px", color: "red" }}>
              {t("changePassword.invalidPassword")}
            </p>
          )}
        </>
      )}
      {confirmCurrentPassword && (
        <>
          <div style={{ marginBottom: "16px" }}>
            {t("changePassword.newPassword")}
            <Input.Password
              prefix={<AiOutlineLock className="site-form-item-icon" />}
              type="password"
              placeholder={t("loginPage.password")}
              size="large"
              onChange={onChangeNewPassword}
            />
            {newPasswordError && (
              <>
                <p style={{ margin: "0px", fontSize: "12px", color: "red" }}>
                  {t("companyUserInfo.passwordError")}
                </p>
                <p style={{ margin: "0px", fontSize: "12px", color: "red" }}>
                  {t("companyUserInfo.passwordCompanyNameError")}
                </p>
              </>
            )}
          </div>
          <div>
            {t("changePassword.reenterPassword")}
            <Input.Password
              disabled={newPasswordError}
              prefix={<AiOutlineLock className="site-form-item-icon" />}
              type="password"
              placeholder={t("loginPage.password")}
              size="large"
              onChange={onChangeReTypePassword}
            />
            {reEnterPasswordError && (
              <p style={{ margin: "0px", fontSize: "12px", color: "red" }}>
                {t("changePassword.reenterCorrectPassword")}
              </p>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
