import {
  NameFilter,
  // IntervalFilter,
  StoreFilterType,
  // DateTarget,
  DocumentTypeFilter,
  GlobalStore,
  UtilsFunction,
  DocumentModelCloudActions,
} from "@armtekfrance/webgate/dist";
import ReleaseComponent from "./ReleaseComponent";
import { useTranslation } from "react-i18next";
import {
  ApiDocumentFamilyEnum,
  ApiDocumentionValidationStatus,
  ApiDocumentSpecification,
} from "@armtekfrance/corenexus-pn-sn";
import { useEffect, useState } from "react";
import { Manager } from "@felixkletti/managementjs";

interface ReleaseComponentProps {
  type: "SYSTEM" | "KNOWLEDGE";
}

const ReleasePage = ({ type }: ReleaseComponentProps) => {
  let filterManager = GlobalStore.filter;
  const [t] = useTranslation();
  const [documentType, setDocumentType] = useState<ApiDocumentSpecification[]>(
    []
  );

  useEffect(() => {
    // Fix no document type in the dropdown when page is refreshed
    if (
      UtilsFunction.getDocumentTypesFromFamily(
        type === "KNOWLEDGE"
          ? ApiDocumentFamilyEnum.KNOWLEDGE
          : ApiDocumentFamilyEnum.SYSTEM
      )?.length > 0
    ) {
      setDocumentType(
        UtilsFunction.getDocumentTypesFromFamily(
          type === "KNOWLEDGE"
            ? ApiDocumentFamilyEnum.KNOWLEDGE
            : ApiDocumentFamilyEnum.SYSTEM
        )
      );
    } else {
      const subscriber = Manager.API.on(
        DocumentModelCloudActions.DOCUMENT_MODEL_TYPES
      ).subscribe(() => {
        setDocumentType(
          UtilsFunction.getDocumentTypesFromFamily(
            type === "KNOWLEDGE"
              ? ApiDocumentFamilyEnum.KNOWLEDGE
              : ApiDocumentFamilyEnum.SYSTEM
          )
        );
        subscriber.unsubscribe();
      });
    }
  }, []);

  let nameDocumentModelNameFilter = new NameFilter(
    t("filters.assign.modelName"),
    filterManager,
    StoreFilterType.DOCUMENT_BASELINE,
    t("filters.assign.modelNamePlaceholder")
  );

  let baseLineTypeFilter = new DocumentTypeFilter(
    "Model type",
    documentType,
    filterManager,
    StoreFilterType.DOCUMENT_BASELINE
  );

  let authorFilter = new NameFilter(
    t("filters.assign.authorName"),
    filterManager,
    StoreFilterType.USER,
    t("filters.assign.authorNamePlaceholder")
  );

  // const dateFilter = new IntervalFilter(
  //   filterManager,
  //   StoreFilterType.EXECUTION,
  //   t("filters.assign.creationDate"),
  //   DateTarget.CREATED_AT
  // );

  filterManager.clearFilters();
  filterManager.addFilter(nameDocumentModelNameFilter);
  filterManager.addFilter(authorFilter);
  //filterManager.addFilter(dateFilter);
  filterManager.addFilter(baseLineTypeFilter);

  if (type === "KNOWLEDGE") {
    filterManager.storeFilters.documentBaselineFilter.validation_status = [
      ApiDocumentionValidationStatus.VALIDATED,
    ];
    filterManager.storeFilters.documentBaselineFilter.family = [
      ApiDocumentFamilyEnum.KNOWLEDGE,
    ];
  } else if (type === "SYSTEM") {
    filterManager.storeFilters.documentBaselineFilter.family = [
      ApiDocumentFamilyEnum.SYSTEM,
    ];
  }

  return <ReleaseComponent filterManager={filterManager} type={type} />;
};
export default ReleasePage;
