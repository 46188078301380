import { Col, Row, Skeleton } from "antd";

export default function EditCompanyLoader() {
  return (
    <div>
      <Row gutter={12}>
        <Col span={24} className="mb-1">
          <Skeleton.Input active />
        </Col>
        <Col span={12} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={12} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24} className="mb-1">
          <Skeleton.Input active />
        </Col>
        <Col span={24} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24} className="mb-1">
          <Skeleton.Input active />
        </Col>
        <Col span={24} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24} className="mb-1">
          <Skeleton.Input active />
        </Col>
        <Col span={24} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24} className="mb-1">
          <Skeleton.Input active />
        </Col>
        <Col span={24} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
          </div>
        </Col>
      </Row>
    </div>
  );
}
