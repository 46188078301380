import { notificationType } from "app/stores/notifications/notificationsType";
import dayjs from "dayjs";
import("dayjs/locale/en");
import("dayjs/locale/fr");
import styles from "./NotificationListItem.module.scss";
import { useTranslation } from "react-i18next";
export default function NotificationListItem({
  notificationItem,
  onClick,
}: {
  notificationItem: notificationType;
  onClick: (item: notificationType) => void;
}) {
  const { i18n } = useTranslation();
  dayjs.locale(i18n.resolvedLanguage);

  return (
    <div
      className={`${styles.notificationListItemWrapper}  ${
        !notificationItem.opened && styles.notifIsOpen
      }`}
      onClick={() => onClick(notificationItem)}
    >
      <div dangerouslySetInnerHTML={{ __html: notificationItem.body }} />
      <p>{dayjs(notificationItem.createdAt).fromNow()}</p>
    </div>
  );
}
