import { useReducer } from "react";
import { commentsReducer } from "./CommentsReducer";
import { CommentsContext, CommentsDispatchContext } from "./CommentsContext";

export default function CommentsProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [comments, dispatchComments] = useReducer(commentsReducer, {
    statusComments: "loading",
    errorComments: null,
    comments: [],
    commentsWithReplies: null,
    paginationComments: { page: 1, pageSize: 10, total: -1 },
    sectionIdHovered: null,
  });

  return (
    <CommentsContext.Provider value={comments}>
      <CommentsDispatchContext.Provider value={dispatchComments}>
        {children}
      </CommentsDispatchContext.Provider>
    </CommentsContext.Provider>
  );
}
