import { BellOutlined } from "@ant-design/icons";
import styles from "./NotificationHero.module.scss";
import { useTranslation } from "react-i18next";

export default function NotificationHero() {
  const { t } = useTranslation();

  return (
    <div className={styles.notificationHeroWrapper}>
      <BellOutlined />
      <h3>{t("dashboard.notifications")}</h3>
    </div>
  );
}
