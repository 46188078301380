import { Input } from "antd";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { BlockTypeConst_NEW } from "../../../../app/stores/blocks/types";
import { useTranslation } from "react-i18next";

interface SmsBlockType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => void;
  disableEdit?: boolean;
  contentId: string | number;
  blockType: string;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function SmsBlock({
  data,
  updateContentInDb,
  disableEdit,
  contentId,
  blockType,
  setIsContentBeingSaved,
}: SmsBlockType) {
  const [phoneNumber, setPhoneNumber] = useState(
    blockType === BlockTypeConst_NEW.sms ? data.phone : data
  );
  const [smsText, setSmsText] = useState(
    blockType === BlockTypeConst_NEW.sms ? data.body : ""
  );

  useEffect(() => {
    blockType === BlockTypeConst_NEW.sms
      ? setPhoneNumber(data.phone)
      : setPhoneNumber(data);
    blockType === BlockTypeConst_NEW.sms
      ? setSmsText(data.body)
      : setSmsText("");
  }, [data]);
  const { t } = useTranslation();

  let phoneNumberTimeOut: any;
  const onChangePhoneNumber = (e: any) => {
    if (
      /^[0-9]/.test(e.nativeEvent.data) ||
      e.nativeEvent.data === "+" ||
      e.nativeEvent.data === null
    ) {
      setIsContentBeingSaved(true);
      setPhoneNumber(e.target.value);
      clearTimeout(timeoutId);
      clearTimeout(phoneNumberTimeOut);
      phoneNumberTimeOut = setTimeout(function () {
        // Runs 2 second (2000 ms) after the last change
        const dataToUpdate: any = {
          content: {
            content: null,
          },
        };
        if (blockType === BlockTypeConst_NEW.sms) {
          dataToUpdate["content"]["content"] = {
            phone: e.target.value,
            body: smsText,
          };
        } else {
          dataToUpdate["content"]["content"] = e.target.value;
        }
        updateContentInDb(contentId, dataToUpdate);
        setIsContentBeingSaved(false);
      }, 2000);
    }
  };

  let timeoutId: any;
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsContentBeingSaved(true);
    setSmsText(e.target.value);

    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate: any = {
        content: {
          content: null,
        },
      };
      if (blockType === BlockTypeConst_NEW.sms) {
        dataToUpdate["content"]["content"] = {
          phone: phoneNumber,
          body: e.target.value,
        };
      }
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  return (
    <div style={{ backgroundColor: "#F6F9FB", padding: "16px" }}>
      <Input
        placeholder={t("doc.models.edit.sections.blocks.enterPhoneNumber")}
        value={phoneNumber}
        onChange={onChangePhoneNumber}
        style={{ marginBottom: "8px", width: "60%" }}
        disabled={disableEdit}
      />
      {blockType === BlockTypeConst_NEW.sms ? (
        <TextArea
          placeholder={t(
            "doc.models.edit.sections.blocks.enterPredefinedMessage"
          )}
          rows={2}
          autoSize
          onChange={handleChange}
          value={smsText}
          style={{ width: "60%" }}
          disabled={disableEdit}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
