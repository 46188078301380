import { useTranslation } from "react-i18next";
import HeaderMenuTabItem from "./HeaderMenuTabItem";
import HeaderMenuTabResizeWrapper from "./HeaderMenuTabResizeWrapper";

export default function HeaderMenuTabResults() {
  const { t } = useTranslation();

  return (
    <HeaderMenuTabResizeWrapper>
      <HeaderMenuTabItem
        label={t("dashboard.executionFlux")}
        to={"/account/knowledge/results/executionFlux"}
      />
    </HeaderMenuTabResizeWrapper>
  );
}
