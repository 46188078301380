import ExecutionPreviewV2 from "components/ExecutionTable/ExecutionPreviewV2";
import ProcedurePreview from "components/ProcedurePreview";
import AlertedBlockPreview from "components/AlertedBlockPreview";

export default function NotificationModal({
  isModalVisible,
  typeOfPreview,
  documentId,
  onCancel,
}: {
  isModalVisible: boolean;
  typeOfPreview: "PROCEDURE" | "EXECUTION" | "SINGLE_BLOCK";
  documentId: any;
  onCancel: () => void;
}) {
  return (
    <>
      {typeOfPreview === "PROCEDURE" && (
        <ProcedurePreview
          isModalVisible={isModalVisible}
          handleCancel={onCancel}
          documentId={documentId}
          page={"baselines"}
        />
      )}
      {typeOfPreview === "EXECUTION" && (
        <ExecutionPreviewV2
          isModalVisible={isModalVisible}
          handleCancel={onCancel}
          documentId={documentId}
        />
      )}
      {typeOfPreview === "SINGLE_BLOCK" && (
        <AlertedBlockPreview
          isPreviewVisible={isModalVisible}
          handleCancel={onCancel}
          alert={{
            executed_block_id: documentId.targets[0].data.executed_block_id,
            requested_data: documentId.targets[0].data.requested_data,
            execution_id: documentId.targets[0].resource_id,
          }}
        />
      )}
    </>
  );
}
