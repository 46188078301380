import { useReducer } from "react";
import { magicReducer } from "./MagicReducer";
import { MagicContext, MagicDispatchContext } from "./MagicContext";

export default function MagicProvider({ children }: { children: JSX.Element }) {
  const [magic, dispatchMagic] = useReducer(magicReducer, {
    id: -1,
    status: "normal",
    isLoading: false,
    isOpen: false,
    isInError: false,
    isSelected: false,
    documentData: {
      id: -1,
      company_id: -1,
      user_id: -1,
      document_model_uid: "",
      name: "",
      type: "procedure",
      active: true,
      family: "",
      published: true,
      template: true,
      image_media_id: -1,
      auto_image_set: false,
      created_at: "",
      updated_at: "",
      never_expire: false,
      expire_at: "",
      status: "",
      first_published_at: "",
      last_published_at: "",
      subtitle: "",
      about: "",
      doc_category_id: -1,
      last_published_document_baseline_id: -1,
      sh__doc_category: null,
      local_code: "",
      user: { id: -1, first_name: "", last_name: "", avatar: {} },
      media_content: {
        id: -1,
        media_content_uid: "",
        location: "",
        type: "",
        name: "",
        metadata: null,
        company_id: -1,
      },
      qr_code: null,
      _count: null,
      version: "-1.-1.-1",
      count: { blocks: 0, sections: 0 },
      isSelected: false,
    },
  });

  return (
    <MagicContext.Provider value={magic}>
      <MagicDispatchContext.Provider value={dispatchMagic}>
        {children}
      </MagicDispatchContext.Provider>
    </MagicContext.Provider>
  );
}
