import { Pagination, PaginationProps } from "antd";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

interface PaginationType {
  onChangePagination: (page: number, pageSize: number) => void;
  page: number;
  pageSize: number;
  total: number;
}

export default function PaginationLayout({
  onChangePagination,
  page,
  pageSize,
  total,
}: PaginationType) {
  const { t } = useTranslation();
  const onChangePage = (page: number, pageSize: number) => {
    onChangePagination(page, pageSize);
  };

  const showTotal: PaginationProps["showTotal"] = (total) =>
    `${t("pagination.total")} ${total} ${t("pagination.items")}`;

  return (
    <div className={style.paginationStyle}>
      <Pagination
        size="small"
        showSizeChanger
        showTotal={showTotal}
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={(page, pageSize) => onChangePage(page, pageSize)}
      />
    </div>
  );
}
