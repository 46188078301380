import styles from "./MediaPickerUploadMediasGrid.module.scss";
import MediaAudioItemWrapper from "./MediaAudioItemWrapper";
import MediaPickerModalFileWrapper from "./MediaPickerModalFileWrapper";
import MediaImageItemWrapper from "components/TempDS/MediaImageItemWrapper/MediaImageItemWrapper";
import React from "react";
import MediaPickerVideoLocalFirstImageExtract from "./MediaPickerVideoLocalFirstImageExtract";
import { GlobalStore, MediaAction } from "@armtekfrance/webgate/dist";
import { ApiMediaSource } from "@armtekfrance/corenexus-pn-sn";
import { Manager } from "@felixkletti/managementjs";

export type DataUri = string;

export default function MediaPickerUploadMediasGrid({
  medias,
  uploadInProgress,
}: {
  medias: {
    file: File;
    dataUri: DataUri;
    selected: boolean;
    block_type: "image" | "video" | "audio" | "file";
  }[];
  uploadInProgress: boolean;
}) {
  return (
    <div
      className={styles.mediaPickerUploadMediasGridWrapper}
      data-uploading={uploadInProgress}
    >
      {medias
        .filter((media) => {
          return (
            media.block_type ===
            GlobalStore.media.getMediaPickerModalStatus().block_type
          );
        })
        .map((media, index) => {
          return (
            <React.Fragment key={index}>
              {GlobalStore.media.getMediaPickerModalStatus().block_type ===
                "image" && (
                <MediaImageItemWrapper
                  mediaName={media.file.name ?? ""}
                  alt={media.file.name ?? ""}
                  convertToBase64={true}
                  typeOfMedia="image"
                  imageDataUri={media.dataUri}
                  checked={media.selected}
                  onCheckedChange={() => {
                    GlobalStore.media.toggleMediaReadyToUploadByFileName(
                      media.file.name
                    );
                  }}
                  onPreviewClickOpening={() => {
                    GlobalStore.media.setFullScreenMediaPreview({
                      media_id: "",
                      block_type: "image",
                      localDataUri: media.dataUri,
                    });
                  }}
                  enablePreview
                  onDelete={() => {
                    GlobalStore.media.removeMediaReadyToUploadByFileName(
                      media?.file?.name ?? ""
                    );
                  }}
                  showMediaName={true}
                  showCheckbox={true}
                  status={"success"}
                  error={undefined}
                />
              )}
              {GlobalStore.media.getMediaPickerModalStatus().block_type ===
                "video" && (
                <MediaPickerVideoLocalFirstImageExtract
                  media={media}
                  onCheckedChange={() => {
                    GlobalStore.media.toggleMediaReadyToUploadByFileName(
                      media.file.name
                    );
                  }}
                  onPreviewClickOpening={() => {
                    /*    GlobalStore.media.setFullScreenMediaPreview({
                      media_id: "",
                      block_type: "video",
                      localDataUri: media.dataUri,
                    });*/
                    Manager.trigger(
                      MediaAction.OPEN_FULL_SCREEN_MEDIA_PREVIEW,
                      {
                        media_id: "",
                        block_type: "video",
                        open: true,
                        localDataUri: media.dataUri,
                      }
                    );
                  }}
                  onDelete={() => {
                    GlobalStore.media.removeMediaReadyToUploadByFileName(
                      media?.file?.name ?? ""
                    );
                  }}
                ></MediaPickerVideoLocalFirstImageExtract>
              )}
              {GlobalStore.media.getMediaPickerModalStatus().block_type ===
                "audio" && (
                <MediaAudioItemWrapper
                  source={media.dataUri}
                  fileName={media.file.name ?? ""}
                  checked={media.selected}
                  onCheckedChange={() => {
                    GlobalStore.media.toggleMediaReadyToUploadByFileName(
                      media.file.name
                    );
                  }}
                />
              )}

              {GlobalStore.media.getMediaPickerModalStatus().block_type ===
                "file" && (
                <MediaPickerModalFileWrapper
                  fileItem={{
                    company_id: 0,
                    execution_media: false,
                    id: 0,
                    location: "",
                    media_content_uid: "",
                    metadata: undefined,
                    name: media.file.name ?? "",
                    provider: "",
                    type: "file",
                    uuid: "",
                    created_at: undefined,
                    media_source: ApiMediaSource.EDITOR,
                    url: "",
                    url_preview: "",
                  }}
                  checked={media.selected}
                  onCheckedChange={() => {
                    GlobalStore.media.toggleMediaReadyToUploadByFileName(
                      media.file.name
                    );
                  }}
                  onDelete={() => {
                    GlobalStore.media.removeMediaReadyToUploadByFileName(
                      media?.file?.name ?? ""
                    );
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
}
