import DropDownList, {
  DropDownListItem,
} from "components/DropDown/DropDownList";
import { DropDownNew } from "components/DropDown/DropDownNew";
import { ReactComponent as DeleteIcon } from "../TempDS/assets/delete.svg";
import { ReactComponent as DownloadIcon } from "../TempDS/assets/download.svg";
import { ReactComponent as EditIcon } from "../TempDS/assets/edit.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Button from "components/TempDS/Button/Button";
export default function MediaSelectorDropDown({
  onDelete,
  onEdit,
  onClickDownload,
}: {
  onDelete?: () => void;
  onEdit?: () => void;
  onClickDownload?: () => void;
}) {
  const { t } = useTranslation();
  const [dropdownElemsList, setDropdownElemsList] = useState<
    DropDownListItem[]
  >([]);

  const executeActionOnDropdownItemClick = (event: DropDownListItem) => {
    if (event) {
      switch (event.key) {
        case "delete":
          if (onDelete) onDelete();
          break;
        case "edit":
          if (onEdit) onEdit();
          break;
        case "download":
          if (onClickDownload) onClickDownload();
          break;
      }
    }
  };

  useEffect(() => {
    const dropdownList: DropDownListItem[] = [];
    if (onDelete !== undefined) {
      dropdownList.push({
        key: "delete",
        label: t("mediaSelector.delete"),
        icon: <DeleteIcon />,
      });
    }
    if (onEdit !== undefined) {
      dropdownList.push({
        key: "edit",
        label: t("mediaSelector.edit"),
        icon: <EditIcon />,
      });
    }
    if (onClickDownload !== undefined) {
      dropdownList.push({
        key: "download",
        label: t("mediaSelector.download"),
        icon: <DownloadIcon />,
      });
    }
    setDropdownElemsList(dropdownList);
  }, []);

  return (
    <DropDownNew
      placement="bottom"
      dropdownContent={
        <DropDownList
          listItems={dropdownElemsList}
          onClickItem={(item) => executeActionOnDropdownItemClick(item)}
        />
      }
      definedWidth="auto"
      definedButtonBackground="white"
    >
      <Button>
        <>...</>
      </Button>
    </DropDownNew>
  );
}
