import {
  AudioOutlined,
  CameraOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  DatabaseOutlined,
  // CheckCircleOutlined,
  // CheckSquareOutlined,
  ExpandOutlined,
  FileOutlined,
  FontSizeOutlined,
  FormOutlined,
  // ImportOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  MessageOutlined,
  NumberOutlined,
  PhoneOutlined,
  PictureOutlined,
  PlaySquareOutlined,
  QrcodeOutlined,
  SafetyCertificateOutlined,
  SoundOutlined,
  // SplitCellsOutlined,
  TableOutlined,
  TrophyOutlined,
  UnorderedListOutlined,
  VideoCameraOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { AiOutlineQrcode, AiOutlineTags } from "react-icons/ai";
import {
  BlockTypeConst_NEW,
  BlockType_NEW,
} from "../../../../../../../app/stores/blocks/types";

import styles from "./MagicBlock.module.scss";
import { ButtonType, Icon } from "@armtekfrance/armtek-react-design-system";

export default function MagicBlock({
  blockType,
  inError,
}: {
  blockType: BlockType_NEW;
  inError: boolean;
}) {
  return (
    <div className={`${styles.magicBlockWrapper} ${inError && styles.error}`}>
      {/* {blockType === BlockTypeConst_NEW.import && <ImportOutlined />} */}
      {blockType === BlockTypeConst_NEW.text && <FontSizeOutlined />}
      {blockType === BlockTypeConst_NEW.bullet_point && (
        <UnorderedListOutlined />
      )}
      {blockType === BlockTypeConst_NEW.table && <TableOutlined />}
      {blockType === BlockTypeConst_NEW.warning && <WarningOutlined />}
      {blockType === BlockTypeConst_NEW.info && <InfoCircleOutlined />}
      {blockType === BlockTypeConst_NEW.external_link && <LinkOutlined />}
      {blockType === BlockTypeConst_NEW.image && <PictureOutlined />}
      {blockType === BlockTypeConst_NEW.video && <PlaySquareOutlined />}
      {blockType === BlockTypeConst_NEW.audio && <SoundOutlined />}
      {blockType === BlockTypeConst_NEW.file && <FileOutlined />}
      {blockType === BlockTypeConst_NEW.input_checkbox && (
        <CheckSquareOutlined />
      )}
      {blockType === BlockTypeConst_NEW.input_radio && <CheckCircleOutlined />}
      {blockType === BlockTypeConst_NEW.data && <DatabaseOutlined />}
      {blockType === BlockTypeConst_NEW.input_text && <FormOutlined />}
      {blockType === BlockTypeConst_NEW.input_data && <NumberOutlined />}
      {/* {blockType === BlockTypeConst_NEW.input_parameters && (
        <SplitCellsOutlined />
      )} */}
      {blockType === BlockTypeConst_NEW.input_image && <CameraOutlined />}
      {blockType === BlockTypeConst_NEW.input_video && <VideoCameraOutlined />}
      {blockType === BlockTypeConst_NEW.input_audio && <AudioOutlined />}
      {blockType === BlockTypeConst_NEW.signature && <ExpandOutlined />}
      {blockType === BlockTypeConst_NEW.qrcode && <QrcodeOutlined />}
      {blockType === BlockTypeConst_NEW.sms && <MessageOutlined />}
      {blockType === BlockTypeConst_NEW.phone && <PhoneOutlined />}
      {blockType === BlockTypeConst_NEW.quiz && <TrophyOutlined />}
      {blockType === BlockTypeConst_NEW.certification && (
        <SafetyCertificateOutlined />
      )}

      {blockType === BlockTypeConst_NEW.attribute && <AiOutlineTags />}
      {blockType === BlockTypeConst_NEW.select_item && <AiOutlineQrcode />}

      {blockType === BlockTypeConst_NEW.ContentInputAttributeStandard && (
        <Icon type={ButtonType.primary} iconData={"CodepenOutlined"} />
      )}
      {blockType === BlockTypeConst_NEW.ContentInputAttributeFixed && (
        <Icon iconData={"PicCenterOutlined"} />
      )}
      {blockType === BlockTypeConst_NEW.ContentInputAttributeVariable && (
        <Icon iconData={"SlidersOutlined"} />
      )}
      {blockType === BlockTypeConst_NEW.internal_link && (
        <Icon iconData={"BlockOutlined"} />
      )}
      {blockType === BlockTypeConst_NEW.ContentDisplayInternalLinkKnowledge && (
        <Icon iconData={"NodeIndexOutlined"} />
      )}
      {blockType === BlockTypeConst_NEW.ContentDisplayInternalLinkSystem && (
        <Icon iconData={"ApartmentOutlined"} />
      )}
    </div>
  );
}
