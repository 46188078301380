import { useTranslation } from "react-i18next";
import { useValidationStatusDispatch } from "../../Provider/ValidationStatusContext";
import InputSearch from "components/InputSearch/InputSearch";

export default function ValidationStatusHeroSearch() {
  const { t } = useTranslation();
  const dispatchValidationStatus = useValidationStatusDispatch();

  return (
    <InputSearch
      placeholder={t("menu.search")}
      onSearch={(e) => {
        dispatchValidationStatus({
          type: "setQueryNameTextFilter",
          queryNameTextFilter: e,
        });
      }}
    />
  );
}
