import Button from "components/TempDS/Button/Button";
import styles from "./MediaPickerModalMediasGridFooter.module.scss";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
export default function MediaPickerModalMediasGridFooter({
  onAdd,
  addDisabled,
  isLoading,
  onCancel,
}: {
  onAdd: () => void;
  addDisabled: boolean;
  isLoading: boolean;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.mediaPickerModalImagesGridFooterWrapper}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Button type="primary" disabled={addDisabled} onClick={onAdd}>
            <>{t("mediaPicker.add")}</>
          </Button>
          <Button type="default" onClick={onCancel}>
            <>{t("mediaPicker.cancel")}</>
          </Button>
        </>
      )}
    </div>
  );
}
