import { Col, Form, FormInstance, Input } from "antd";
import {
  useAddTeamForm,
  useAddTeamFormDispatch,
} from "components/AddTeamForm/Provider/AddTeamFormContext";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";

interface AddTeamFormtextFieldsIdentificationProps {
  formRef: RefObject<FormInstance<any>>;
  handleDisplay: () => void;
  handleHide: () => void;
}

export default function AddTeamFormTextFieldsIdentification({
  formRef,
  handleDisplay,
  handleHide,
}: AddTeamFormtextFieldsIdentificationProps) {
  const { t } = useTranslation();

  const addTeamFormContext = useAddTeamForm();
  const dispatchAddTeamForm = useAddTeamFormDispatch();

  const handleIdentificationDrowdownClick = () => {
    formRef.current?.setFieldValue(
      "identification",
      addTeamFormContext.suggestedTeamNickname
    );
    dispatchAddTeamForm({
      type: "resetSuggestedDropdown",
    });
  };

  return (
    <Col span={24}>
      <Form.Item
        style={{ marginBottom: "0px" }}
        name="identification"
        label={t("team.identification")}
        rules={[
          {
            required: true,
            message: t("team.teamIdentificationInput"),
          },
        ]}
      >
        <Input
          maxLength={42} // max length accepted by API for "identification"
          placeholder={t("team.teamIdentificationInput")}
          onFocus={handleDisplay}
          onBlur={handleHide}
        />
      </Form.Item>
      {addTeamFormContext.isPopOverVisibleNickname ? (
        <div
          style={{
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "5px",
            paddingRight: "5px",
            paddingLeft: "5px",
            marginBottom: "24px",
          }}
        >
          <p
            style={{ margin: "0px", cursor: "pointer" }}
            onClick={handleIdentificationDrowdownClick}
          >
            <span style={{ fontWeight: "700" }}> {t("team.suggested")} : </span>{" "}
            {addTeamFormContext.suggestedTeamNickname}
          </p>
        </div>
      ) : (
        <div style={{ marginBottom: "24px" }}></div>
      )}
    </Col>
  );
}
