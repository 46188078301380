import {
  CheckOutlined,
  CloseOutlined,
  HourglassOutlined,
} from "@ant-design/icons";
import { Space } from "antd";
import { IDocumentBaselineExecution } from "app/stores/executionFlux/types";
import translateDocumentType from "util/translateDocumentType";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import { useTranslation } from "react-i18next";

interface TreeDisplayCardProps {
  data: IDocumentBaselineExecution;
}

const TreeDisplayCard = ({ data }: TreeDisplayCardProps) => {
  const {
    document_type,
    user,
    id,
    baseline_name,
    baseline_version,
    exe_start_date,
    execution_status,
  } = data;

  const { t } = useTranslation();

  return (
    <div
      style={{ border: "1px solid #D9D9D9", padding: "2px 8px", width: "100%" }}
    >
      <Space direction="horizontal" style={{ width: "100%" }} size="large">
        {id}
        <AvatarComponent
          size={32}
          src={user?.avatar?.media_content_uid}
          firstName={user.first_name}
          lastName={user.last_name}
        />
        {translateDocumentType(document_type, t)}
        {baseline_name}
        {baseline_version}
        {execution_status &&
          (execution_status.toUpperCase() === "COMPLETED" ? (
            <CheckOutlined style={{ color: "#237804" }} />
          ) : execution_status.toUpperCase() === "EXECUTING" ? (
            <HourglassOutlined style={{ color: "#FFC685" }} />
          ) : (
            <CloseOutlined style={{ color: "#CF1322" }} />
          ))}
        {new Intl.DateTimeFormat("fr-FR").format(new Date(exe_start_date))}
      </Space>
    </div>
  );
};

export default TreeDisplayCard;
