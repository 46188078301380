import { GlobalStore, MediaAction } from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";
// import { MediaStore } from "Stores/media";
import { MediaContent } from "Stores/media-models";
import { fetchBlobObjectTanstack } from "app/stores/content/contentClient";
import MediaImageItemWrapper from "components/TempDS/MediaImageItemWrapper/MediaImageItemWrapper";

export default function MediaPickerModalVideoWrapper({
  videoItem,
  checked,
  onCheckedChange,
  convertToBase64 = false,
}: {
  videoItem: MediaContent;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  convertToBase64?: boolean;
}) {
  const thumbnailImage = fetchBlobObjectTanstack(
    videoItem.media_content_uid,
    true,
    true,
    1000 * 60 * 60 * 24 * 7
  );

  return (
    <MediaImageItemWrapper
      mediaName={videoItem.name}
      showMediaName={true}
      imageDataUri={`data:${thumbnailImage.blobObject?.media.ContentType};${
        convertToBase64 ? "base64," : ""
      } ${thumbnailImage.blobObject?.media.file}`}
      alt={videoItem.name}
      showCheckbox={true}
      checked={checked}
      enablePreview
      typeOfMedia="video"
      onCheckedChange={onCheckedChange}
      onPreviewClickOpening={() => {
        Manager.trigger(MediaAction.OPEN_FULL_SCREEN_MEDIA_PREVIEW, {
          media_id: videoItem.id.toString(),
          block_type: "image",
          open: true,
          localDataUri: null,
        });
        GlobalStore.media.setFullScreenMediaPreview({
          media_id: videoItem.id.toString(),
          block_type: "video",
          localDataUri: null,
        });
      }}
      status={thumbnailImage.status}
      error={thumbnailImage.error}
    />
  );
}
