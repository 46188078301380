import Request, {
  filterFormat,
  pageFormat,
  pageSizeFormat,
  sortFormat,
} from "../../../libs/requests";
import { SectionsType } from "./types";

export const createSection = async (createdSection: SectionsType) => {
  return Request()
    .post("/section-models", createdSection)
    .then((response) => response.data);
};

export const fetchSections = async (
  filters = {},
  sort = { position: "asc" },
  page?: number,
  pageSize?: number | "0"
) => {
  const sortParams = sortFormat(sort);
  const filterParams = filterFormat(filters);
  let reqParams = {
    ...filterParams,
    ...sortParams,
  };
  if (page) {
    const pageParams = pageFormat(page);
    reqParams = {
      ...reqParams,
      ...pageParams,
    };
  }
  if (pageSize) {
    const pageSizeParams = pageSizeFormat(pageSize);
    reqParams = {
      ...reqParams,
      ...pageSizeParams,
    };
  }
  return Request()
    .get("/section-models", {
      params: reqParams,
    })
    .then((response) => response.data);
};

export const duplicateSections = async (
  sectionId: string,
  documentId: string
) => {
  return Request()
    .post(`/section-models/${sectionId}/duplicateTo/${documentId}`)
    .then((response) => response.data);
};

export const deleteSection = async (sectionId: string, documentId: string) => {
  return Request()
    .delete(`/section-models/${sectionId}/${documentId}`)
    .then((response) => response.data);
};

export const fetchSectionById = async (sectionId: string) => {
  return Request()
    .get(`/section-models/${sectionId}`)
    .then((response) => response.data);
};

export const updateSectionById = async (sectionId: string, updateData: any) => {
  return Request()
    .patch(`/section-models/${sectionId}`, updateData)
    .then((response) => response.data);
};
