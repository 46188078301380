import { FormEvent, useEffect, useRef, useState } from "react";
import styles from "./TextArea.module.scss";
import { ReactComponent as InfoCircle } from "../assets/InfoCircle.svg";

export default function TextArea({
  placeholder,
  onChangeText,
  type = "default",
  rows = 1,
  maxLength,
  waitingForApi = false,
  defaultValue,
  fontSize = 16,
  isReadOnly = false,
}: {
  placeholder: string;
  onChangeText: (text: string) => void;
  type?: "default" | "comment";
  rows?: number;
  maxLength?: number;
  waitingForApi?: boolean;
  defaultValue?: string;
  fontSize?: number;
  isReadOnly?: boolean;
}) {
  const [textAreaText, setTextAreaText] = useState<string>(defaultValue ?? "");

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  async function onChange(e: FormEvent) {
    e.preventDefault();
    if (textAreaRef.current === null) return;

    setTextAreaText(textAreaRef.current.value);
  }

  useEffect(() => {
    if (textAreaRef.current !== null) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [textAreaText]);

  // Reset text area when waiting for api changes to false
  useEffect(() => {
    if (waitingForApi === false) {
      setTextAreaText("");
    }
  }, [waitingForApi]);

  useEffect(() => {
    setTextAreaText(defaultValue ?? "");
  }, [defaultValue]);

  return (
    <div className={`${type === "comment" ? styles.comment : styles.default}`}>
      <textarea
        className={styles.textArea}
        ref={textAreaRef}
        value={textAreaText}
        rows={rows}
        style={{ fontSize: `${fontSize}px` }}
        onChange={(e) => {
          if (!isReadOnly) {
            onChangeText(e.currentTarget.value);
            onChange(e);
          }
        }}
        onBlur={() => {
          setTextAreaText(textAreaText.trim());
        }}
        maxLength={maxLength}
        placeholder={placeholder}
        aria-label={placeholder}
      />
      {maxLength !== undefined && (
        <div
          className={`${
            textAreaText.length > maxLength - 1
              ? styles.warningMaxLenght
              : styles.noWarning
          }`}
        >
          <p>{`Max number of characters is ${maxLength}`}</p>
          <InfoCircle />
        </div>
      )}
    </div>
  );
}
