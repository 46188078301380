import Request from "../../../libs/requests";

export const fetchUserNotificationPreferences = () => {
  return Request()
    .get(`/users/events`)
    .then((response) => response.data);
};

export const updateUserNotificationPreferences = (preferenceData: any) => {
  return Request()
    .put(`/users/events`, preferenceData)
    .then((response) => response.data);
};
