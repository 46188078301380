import styles from "./MagicSectionItem.module.scss";
export default function MagicSectionInfoItem({
  documentUid,
  creationOrigin,
  subtitle,
  description,
}: {
  documentUid: string;
  creationOrigin: string;
  subtitle: string | null | undefined;
  description: string | null | undefined;
}) {
  return (
    <div className={`${styles.magicSectionItemWrapper} ${styles.info}`}>
      <div className={styles.infoDocument}>
        <h5>{documentUid}</h5>
        <p>{creationOrigin}</p>
      </div>

      <div className={styles.infoDocument}>
        <h5>{subtitle}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
}
