import styles from "./Button.module.scss";
import { ReactComponent as DownArrow } from "../assets/DownArrow.svg";

export default function Button({
  children,
  type = "default",
  size = "default",
  isOpen = false,
  dropdownButton = false,
  onClick = () => {
    return;
  },
  disabled = false,
  filter = false,
  dense = false,
  underline = false,
  loading = false,
}: {
  children?: JSX.Element;
  size?: "default" | "small" | "large";
  type?: "primary" | "default";
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isOpen?: boolean;
  disabled?: boolean;
  dropdownButton?: boolean;
  filter?: boolean;
  dense?: boolean;
  underline?: boolean;
  loading?: boolean;
}) {
  return (
    <button
      className={`${styles.buttonWrapper} ${styles[type]} ${
        isOpen ? styles.open : styles.close
      } ${filter ? styles.filter : ""}  ${
        dropdownButton ? styles.dropdownButton : ""
      }
      ${size === "small" ? styles.sizeSmall : ""}
      ${size === "large" ? styles.sizeLarge : ""}
      ${size === "default" ? styles.sizeDefault : ""}
      ${dense ? styles.dense : ""}
      ${disabled ? styles.disabled : ""}
      ${underline ? styles.underline : ""}
      ${loading ? styles.loading : ""}
      `}
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
    >
      <p>{children}</p>

      {dropdownButton && (
        <div
          className={` ${styles.downArrowWrapper} ${
            isOpen ? styles.open : styles.close
          } `}
        >
          <DownArrow />
        </div>
      )}
    </button>
  );
}
