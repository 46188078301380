import DropDownItemUser from "components/DropDown/DropDownItemUser";
import DropDownList from "components/DropDown/DropDownList";
import { DropDownNew } from "components/DropDown/DropDownNew";
import DropDownSelectOrUnselectAll from "components/DropDown/DropDownSelectOrUnselectAll";
import { useTranslation } from "react-i18next";
import {
  useObsoleteReleases,
  useObsoleteReleasesDispatch,
} from "../../Provider/ObsoleteReleasesContext";
import { DropdownButton } from "@armtekfrance/armtek-react-design-system";

export default function ObsoleteReleasesHeroDropDownValidator() {
  const { t } = useTranslation();
  const obsoleteReleases = useObsoleteReleases();
  const dispatchObsoleteReleases = useObsoleteReleasesDispatch();

  return (
    <DropDownNew
      dropdownContent={
        <>
          <DropDownSelectOrUnselectAll
            onSelectAll={() => {
              dispatchObsoleteReleases({ type: "selectAllValidators" });
            }}
            onClearAll={() => {
              dispatchObsoleteReleases({ type: "clearAllValidators" });
            }}
          />
          <DropDownList
            selectType="checkbox"
            onClickItem={(item) => {
              dispatchObsoleteReleases({
                type: "toogleSelectValidatorById",
                validatorId: parseInt(item.key) ?? 0,
              });
            }}
            listItems={obsoleteReleases.validators.map((validator) => {
              return {
                key: `${validator.id}`,
                label: (
                  <DropDownItemUser
                    first_name={validator.first_name}
                    last_name={validator.last_name}
                    avatar={validator.avatar}
                  />
                ),
                selected: validator.selected,
              };
            })}
          />
        </>
      }
    >
      <DropdownButton name={t("validationTable.validator")} />
    </DropDownNew>
  );
}
