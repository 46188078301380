import { DocumentTypeNew } from "app/stores/documents/typesDocNew";

export type MagicType = {
  id: number;
  status: "normal" | "waring" | "error";
  isLoading: boolean;
  isOpen: boolean;
  isInError: boolean;
  documentData: DocumentTypeNew & { isSelected: boolean };
  isSelected: boolean;
  documentDataSingle?: any;
};

type ObjectValues<T> = T[keyof T];

const MagicReducerActionType = {
  init: "init",
  initSingle: "initSingle",
  updateOneDocument: "updateOneDocument",
  toggleMagicSelected: "toggleMagicSelected",
} as const;

export type TypeMagicReducerAction = {
  type: ObjectValues<typeof MagicReducerActionType>;
} & {
  // magicState?: MagicType;
  // item?: MagicItemType;
  // itemId?: number;
  documentData?: any;
  documentDataSingle?: any;
};

export function magicReducer(
  magicData: MagicType,
  action: TypeMagicReducerAction
): MagicType {
  switch (action.type) {
    case "init": {
      if (action.documentData === undefined) {
        throw Error("No 'inputDropDownState' was provided " + action.type);
      } else {
        return {
          id: action.documentData.id,
          status: "normal",
          isLoading: true,
          isOpen: false,
          isInError: false,
          isSelected: false,
          documentData: action.documentData,
        };
      }
    }
    case "initSingle": {
      if (action.documentDataSingle === undefined) {
        throw Error("No 'documentDataSingle' was provided " + action.type);
      } else {
        const arrayOfNbOfBlock = action.documentDataSingle?.magic.map(
          (elem: any) => {
            return elem.blocks.length === 0;
          }
        );
        return {
          ...magicData,
          isLoading: false,
          isInError: arrayOfNbOfBlock?.some((el: any) => {
            return el === true;
          }),
          documentDataSingle: action.documentDataSingle,
        };
      }
    }
    case "toggleMagicSelected": {
      return { ...magicData, isSelected: !magicData.isSelected };
    }
    case "updateOneDocument": {
      if (action.documentData === undefined) {
        throw Error("No 'inputDropDownState' was provided " + action.type);
      } else {
        return { ...magicData };
      }
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
