import IndexRouter from "../../components/IndexRouter/IndexRouter";

export default function ResultsIndex() {
  return (
    <IndexRouter
      mainRoute="/account/knowledge/results"
      relativeDefault="executionFlux"
    />
  );
}
