import { memo } from "react";
import { useDrag } from "react-dnd";
import { CARD_VALUES } from "../../components/CategoryPlanCards";
import MagicTypeOfDocumentView from "../../components/MagicListItem/MagicBody/MagicArticle/MagicInfos/MagicInfosElements/MagicTypeOfDocumentView";
import style from "./style.module.css";

export const DraggableCard = memo(function DraggableCard({
  itemName,
  itemValue,
  type,
  textStyle,
  family,
}: any) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { itemName, type, itemValue, family },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [itemName, type, itemValue, family]
  );

  return (
    <div ref={drag} style={{ opacity, width: "100%", marginBottom: "4px" }}>
      {itemValue === CARD_VALUES.Category ||
      itemValue === CARD_VALUES.Subcategory ? (
        <div className={style.cardDesign}>
          <div style={textStyle}>{itemName}</div>
        </div>
      ) : (
        <div style={{ cursor: "grab" }}>
          <MagicTypeOfDocumentView type={itemValue} />
        </div>
      )}
    </div>
  );
});
