import { MediaContent } from "Stores/media-models";
import { Checkbox } from "antd";
import { ReactComponent as Delete } from "../TempDS/assets/delete.svg";
import styles from "./MediaPickerModalFileWrapper.module.scss";

export default function MediaPickerModalFileWrapper({
  fileItem,
  checked,
  onCheckedChange,
  onDelete,
}: {
  fileItem: MediaContent;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  onDelete?: () => any;
}) {
  return (
    <div
      className={styles.mediaPickerModalFileWrapperWrapper}
      aria-selected={checked}
    >
      <h2>{fileItem.name?.split(".")[fileItem.name.split(".").length - 1]}</h2>
      <p className={styles.fileName}>{fileItem.name}</p>
      <Checkbox
        className={styles.checkBox}
        aria-checked={checked}
        checked={checked}
        onChange={(e) => {
          onCheckedChange(e.target.checked);
        }}
      />
      {onDelete && <Delete onClick={onDelete} />}
    </div>
  );
}
