import styles from "./ImageDisplayComponentDS.module.scss";
import { Spin } from "antd";
import ImagePreview from "./ImagePreview";

export default function ImageDisplayComponentDS({
  // image,
  imageDataUri,
  status,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  externalLoading = false,
  alt,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error,
  onPreviewClickOpening,
  typeOfMedia,
  enablePreview = false,
}: {
  imageDataUri: string;
  status: "loading" | "success" | "error";
  error: unknown;
  alt: string;
  externalLoading?: boolean;
  onPreviewClickOpening: () => void;
  typeOfMedia: "image" | "video";
  enablePreview: boolean;
}) {
  return (
    <div className={styles.imageDisplayComponentPOCWrapper}>
      {!externalLoading && (
        <>
          {status === "loading" && (
            <div className={styles.spinnerWrapper}>
              <Spin size="large" />
            </div>
          )}
          {status === "success" && (
            <ImagePreview
              src={imageDataUri}
              alt={alt}
              typeOfMedia={typeOfMedia}
              enablePreview={enablePreview}
              onOpenPreviewClick={onPreviewClickOpening}
            />
          )}
        </>
      )}
    </div>
  );
}
