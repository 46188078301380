import { Col, Row, Skeleton } from "antd";

export default function DocLinkageLoader() {
  return (
    <div>
      {/* First row */}
      <Row gutter={12}>
        <Col span={2} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active size="small" />
          </div>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8} offset={1} className="mb-4">
          <div className="full-skeleton">
            <Skeleton round active />
          </div>
        </Col>
      </Row>

      {/* Second row */}
      <Row gutter={12}>
        <Col span={2} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active size="small" />
          </div>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8} offset={1} className="mb-4">
          <div className="full-skeleton">
            <Skeleton round active />
          </div>
        </Col>
      </Row>

      {/* Third row */}
      <Row gutter={12}>
        <Col span={2} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active size="small" />
          </div>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8} offset={1} className="mb-4">
          <div className="full-skeleton">
            <Skeleton round active />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function NodeLoader({ title }: { title: string }) {
  return (
    <Row gutter={12}>
      <Col span={24}>
        <div className="full-skeleton">
          <p style={{ position: "absolute", top: ".65rem", left: "2rem" }}>
            <strong> {title}</strong>{" "}
          </p>
          <Skeleton.Input active size="large" />
        </div>
      </Col>
    </Row>
  );
}
