import CompanyDepartment from "./CompanyDepartments/CompanyDepartments";
import styles from "./CompanyDepartmentsLayout.module.scss";

export default function CompanyDepartmentsLayout() {
  return (
    <div className={styles.companyDepartmentsLayoutWrapper}>
      <CompanyDepartment />
    </div>
  );
}
