import { Skeleton, message } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchDocumentRequestByID } from "../../app/stores/documentRequests/documentRequestsClient";
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import DocumentRequestDetail from "../../components/DocumentRequestDetails/DocumentRequestDetails";

export default function ViewDocumentRequest() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [entityId, setEntityId] = useState(id ? id : "");

  const [entityData, setEntityData] = useState<any>({});
  const [entityPending, setEntityPending] = useState(true);

  useEffect(() => {
    if (entityId) {
      fetchDocumentRequestByID(entityId).then((response) => {
        if (response.success) {
          setEntityData(response.data);
          setEntityPending(false);
        } else {
          message.error(t("error.fetchData"));
        }
      });
    } else {
      setEntityData({});
      setEntityPending(false);
    }
    // eslint-disable-next-line
  }, [dispatch, entityId]);

  return (
    <div className="create-procedure">
      <div className="content">
        <div>
          {/* <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={<Title level={4}>Document requests</Title>}
            subTitle={t("goBack")}
            style={{ backgroundColor: "white" }}
          /> */}
          <div
            className="content-wrapper bg-white pt-4"
            style={{ height: "100vh" }}
          >
            {!entityPending ? (
              <DocumentRequestDetail
                entityData={entityData}
                entityId={entityId}
                setEntityId={setEntityId}
              />
            ) : (
              <Skeleton active />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
