import { Tooltip } from "antd";
import { editContent } from "../../../../app/stores/content/contentClient";
import { ContentType } from "app/stores/content/type";
import { useDebounce } from "util/useDebounce";
import styles from "./MediaDetails.module.scss";
import MediaSelector from "components/MediaSelector/MediaSelector";
import { useTranslation } from "react-i18next";
import TextArea from "components/TempDS/TextArea/TextArea";

export default function MediaDetails({
  content,
  block_id,
  block_type,
  isPreview = false,
}: {
  content: ContentType;
  block_id: string;
  block_type: "image" | "video" | "audio" | "file";
  isPreview: boolean;
}) {
  const { t } = useTranslation();

  const _onTextChange = (textChanged: string, contentId: number | string) => {
    editContent(contentId, {
      content: {
        content: textChanged.trim(),
      },
    });
  };

  const onTextChange = useDebounce(_onTextChange);

  return (
    <div className={styles.mediaDetailsWrapper}>
      {content.media_content !== null && (
        <MediaSelector
          content={content}
          block_type={block_type}
          block_id={block_id}
          thumbnail={true}
          editable={!isPreview}
        />
      )}
      <footer>
        <Tooltip placement="bottom" title={content.media_content?.name}>
          <p className={styles.fileName}>{content.media_content?.name}</p>
        </Tooltip>
        <div className={styles.textAreaWrapper}>
          <TextArea
            type="default"
            defaultValue={content.content_json?.content ?? ""}
            onChangeText={(e) => {
              onTextChange(e, content.id!);
            }}
            isReadOnly={isPreview}
            placeholder={t("ops.edit.enterDoc")}
          />
        </div>
      </footer>
    </div>
  );
}
