import styles from "./MagicSectionDetails.module.scss";
import MagicSectionInfoItem from "./MagicSectionItems/MagicSectionInfoItem";
import MagicSectionItem from "./MagicSectionItems/MagicSectionItem";
import { useMagic } from "../../../MagicContext";
import { Link } from "react-router-dom";

export default function MagicSectionDetailsNew({
  commentSectionIdHovered,
}: {
  commentSectionIdHovered: number | null;
}) {
  const magic = useMagic();
  return (
    <>
      {magic.documentDataSingle !== undefined &&
        magic.documentDataSingle !== null &&
        !magic.isLoading && (
          <div className={styles.magicSectionDetailsWrapper}>
            <MagicSectionInfoItem
              documentUid={magic.documentDataSingle.document_model_uid}
              creationOrigin={""}
              subtitle={magic.documentDataSingle.subtitle}
              description={magic.documentDataSingle.about}
            />
            {magic.documentDataSingle &&
              magic.documentDataSingle?.magic.map(
                (section: any, sectionIndex: number) => {
                  return (
                    <Link
                      key={sectionIndex}
                      to={
                        magic.documentData.family === "SYSTEM"
                          ? `/account/system/models/edit/${magic.documentDataSingle.id}`
                          : `/account/knowledge/models/edit/${magic.documentDataSingle.id}/${section.section_model_id}`
                      }
                    >
                      <MagicSectionItem
                        key={sectionIndex}
                        position={section.position}
                        name={section.name}
                        blocks={section.blocks}
                        commentHovered={
                          commentSectionIdHovered === section.section_model_id
                        }
                      />
                    </Link>
                  );
                }
              )}
          </div>
        )}
    </>
  );
}
