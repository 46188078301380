// @ts-nocheck

import styles from "./BuildInfo.module.scss";

export default function BuildInfo() {
  return (
    <div className={styles.buildInfoWrapper}>
      <p>FRONT_BACKEND_URL: {JSON.stringify(window.FRONT_BACKEND_URL)}</p>
      <p>ARMTEK_PROD_VERSION: {JSON.stringify(window.ARMTEK_PROD_VERSION)}</p>
      <p>ARMTEK_PROD_COMMIT: {JSON.stringify(window.ARMTEK_PROD_COMMIT)}</p>
      <p>ARMTEK_PROD_BRANCH: {JSON.stringify(window.ARMTEK_PROD_BRANCH)}</p>
      <p>ARMTEK_PROD_DATE: {JSON.stringify(window.ARMTEK_PROD_DATE)}</p>
    </div>
  );
}
