import { DatePicker, Tooltip } from "antd";
import { queryClient } from "libs/requests";

import { useObsoleteReleasesDispatch } from "../../Provider/ObsoleteReleasesContext";
import { useTranslation } from "react-i18next";

export default function ObsoleteReleasesHeroDropDonwTimeExpired() {
  const { t } = useTranslation();
  const dispatchObsoleteReleases = useObsoleteReleasesDispatch();
  const { RangePicker } = DatePicker;
  return (
    <>
      <Tooltip placement="top" title={t("doc.models.procedure.expirationDate")}>
        <RangePicker
          placeholder={[t("operate.startDate"), t("operate.endDate")]}
          style={{
            borderRadius: "2px",
            border: "solid 1px #4f7fa3",
            boxSizing: "border-box",
            padding: "0.32rem 1rem",
          }}
          onChange={(date) => {
            dispatchObsoleteReleases({
              type: "setExpiredRangeDate",
              expiredRangeDate: {
                queryStartDate: (date?.[0]?.toISOString() ?? "") as string,
                queryEndDate: (date?.[1]?.toISOString() ?? "") as string,
              },
            });
            queryClient.invalidateQueries({
              queryKey: ["baselineValidations"],
            });
          }}
        />
      </Tooltip>
    </>
  );
}
