import Request, {
  filterFormat,
  pageFormat,
  pageSizeFormat,
  sortFormat,
} from "../../../libs/requests";
import { CreateBlockType } from "./types";

export const createBlock = (createdBlock: CreateBlockType) => {
  return Request()
    .post("/block-models/", createdBlock)
    .then((response) => response.data);
};

export const fetchBlocks = (
  filters = {},
  sort = { position: "asc" },
  page?: number,
  pageSize?: number | "0"
) => {
  const sortParams = sortFormat(sort);
  const filterParams = filterFormat(filters);
  let reqParams = {
    ...filterParams,
    ...sortParams,
  };
  if (page) {
    const pageParams = pageFormat(page);
    reqParams = {
      ...reqParams,
      ...pageParams,
    };
  }
  if (pageSize) {
    const pageSizeParams = pageSizeFormat(pageSize);
    reqParams = {
      ...reqParams,
      ...pageSizeParams,
    };
  }
  return Request()
    .get("/block-models", {
      params: reqParams,
    })
    .then((response) => response.data);
};

export const fetchSingleBlock = (blockId: number | string) => {
  return Request()
    .get(`/block-models/${blockId}`)
    .then((response) => response.data);
};

export const deleteBlockById = (
  blockId: number | string,
  sectionId: number | string
) => {
  return Request()
    .delete(`/block-models/${blockId}/${sectionId}`)
    .then((response) => response.data);
};

export const duplicateBlock = (blockId: string, sectionId: string) => {
  return Request()
    .post(`/block-models/${blockId}/duplicateTo/${sectionId}`)
    .then((response) => response.data);
};

export const updateBlockById = (blockId: string, updateData: any) => {
  return Request()
    .patch(`/block-models/${blockId}`, updateData)
    .then((response) => response.data);
};
