import React, { useEffect, useState } from "react";
// import MediaImageItemWrapper from "./MediaImageItemWrapper";
import MediaImageItemWrapper from "components/TempDS/MediaImageItemWrapper/MediaImageItemWrapper";

type MediaProps = {
  media: {
    file: File;
    selected: boolean;
    dataUri: string;
  };
  onCheckedChange: (selected: boolean) => void;
  onDelete: () => void;
  onPreviewClickOpening: () => void;
};

export default function MediaPickerVideoLocalFirstImageExtract({
  media,
  onCheckedChange,
  onPreviewClickOpening,
  onDelete,
}: MediaProps) {
  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  const extractImageAtTime = (videoDataUri: string, targetTime: number) => {
    const video = document.createElement("video");
    video.src = videoDataUri;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    video.addEventListener("loadeddata", () => {
      video.currentTime = targetTime;

      video.addEventListener("seeked", () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageDataUri = canvas.toDataURL("image/png");

        setThumbnail(imageDataUri);
        setThumbnailLoaded(true);
      });
    });
  };

  useEffect(() => {
    extractImageAtTime(media.dataUri, 0.1);
  }, [media.dataUri]);

  return (
    <MediaImageItemWrapper
      mediaName={media.file.name}
      showMediaName={true}
      imageDataUri={thumbnail}
      alt={media.file.name}
      showCheckbox={true}
      enablePreview
      convertToBase64={true}
      checked={media.selected}
      typeOfMedia="video"
      onCheckedChange={onCheckedChange}
      onPreviewClickOpening={onPreviewClickOpening}
      status={thumbnailLoaded ? "success" : "loading"}
      error={undefined}
      onDelete={onDelete}
    />
  );
}
