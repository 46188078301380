import NotificationsProvider from "./Provider/NotificationsProvider";
import NotificationsOrAlerts from "./NotificationsOrAlerts";

export default function NotificationsOrAlertsRoot({
  typeOfNotidications,
}: {
  typeOfNotidications: "NOTIFICATIONS" | "ALERTS";
}) {
  return (
    <NotificationsProvider>
      <NotificationsOrAlerts typeOfNotidications={typeOfNotidications} />
    </NotificationsProvider>
  );
}
