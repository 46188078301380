import { useReducer } from "react";
import { addTeamFormReducer } from "./AddTeamFormReducer";
import {
  AddTeamFormContext,
  AddTeamFormDispatchContext,
} from "./AddTeamFormContext";

export default function AddTeamFormProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [addTeamForm, dispatchAddTeamForm] = useReducer(addTeamFormReducer, {
    selectedUsers: [],
    defaultSelectedUsers: [],
    teamLoading: false,
    formLoading: false,
    activeUserId: null,
    viewOnly: false,
    openCreateUserDrawer: false,
    searchText: "",
    tableDataList: [],
    tableDataLoading: false,
    tableDataListPagination: {
      page: 1,
      pageSize: 15,
      total: 0,
    },
    page: 1,
    pageSize: 10,
    isPopOverVisibleNickname: false,
    suggestedTeamNickname: "",
    isAddDeptOpen: false,
    companyDeptList: [],
    deptListLoading: false,
  });

  return (
    <AddTeamFormContext.Provider value={addTeamForm}>
      <AddTeamFormDispatchContext.Provider value={dispatchAddTeamForm}>
        {children}
      </AddTeamFormDispatchContext.Provider>
    </AddTeamFormContext.Provider>
  );
}
