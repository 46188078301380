import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import MagicInfoNameView from "./MagicInfosElements/MagicInfoNameView";
import MagicQrCodeView from "./MagicInfosElements/MagicQrCodeView";
import MagicTypePublishView from "./MagicInfosElements/MagicTypePublishView";
import MagicVersionView from "./MagicInfosElements/MagicVersionView";
import MagicTypeOfDocumentView from "./MagicInfosElements/MagicTypeOfDocumentView";

import styles from "./MagicInfos.module.scss";
import { DocumentType } from "app/stores/documents/types";
import { PictureOutlined, PlayCircleOutlined } from "@ant-design/icons";
import ImageDisplayComponentPOC from "components/ImageDisplayComponent/ImageDisplayComponentPOC";
import { Switch } from "antd";
import { ApiMetabaseDashboard } from "app/stores/metabase/types";
import { BarChartOutlined } from "@ant-design/icons";

export default function MagicInfos({
  documentMagicData,
  metabaseDashboard,
  lite,
  type = "models",
  onOpenModal,
  readOnly = false,
}: {
  documentMagicData: DocumentType;
  metabaseDashboard?: ApiMetabaseDashboard;
  lite: boolean;
  type?: "models" | "analysis";
  readOnly?: boolean;

  onOpenModal?: (
    id: string | number,
    type: "openModal" | "deleteDashboard" | "changeActive" | "deleteModel"
  ) => void;
}) {
  return (
    <header className={styles.magicInfosWrapper}>
      {!lite && (
        <div>
          {type === "analysis" ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                marginRight: "1rem",
              }}
            >
              {!readOnly ? (
                <Switch
                  checked={metabaseDashboard?.active}
                  onChange={() => {
                    onOpenModal &&
                      onOpenModal(
                        metabaseDashboard
                          ? metabaseDashboard.dashboard_uuid
                          : "",
                        "changeActive"
                      );
                  }}
                />
              ) : (
                <BarChartOutlined
                  style={{
                    fontSize: "2rem",
                    color: "#030852",
                  }}
                />
              )}
            </div>
          ) : (
            <div className={styles.forceImageStyle}>
              {documentMagicData.media_content?.media_content_uid !==
              undefined ? (
                <div
                  className={styles.imageWrapper}
                  onClick={(e) => e.stopPropagation()}
                >
                  {documentMagicData.media_content?.type === "picture" ? (
                    <ImageDisplayComponentPOC
                      alt={""}
                      src={documentMagicData.media_content.media_content_uid}
                      style={{
                        height: "64px",
                        width: "64px",
                        objectFit: "cover",
                      }}
                      staleTime={10000}
                    />
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#e9eef1",
                      }}
                    >
                      <PlayCircleOutlined
                        style={{
                          color: "#d9d9d9",
                          scale: "2",
                          cursor: "not-allowed",
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#e9eef1",
                    width: "64px",
                  }}
                >
                  <PictureOutlined
                    style={{
                      color: "#d9d9d9",
                      scale: "2",
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {type !== "analysis" && (
        <MagicQrCodeView qr_code={documentMagicData.qr_code} />
      )}

      <MagicInfoNameView
        name={
          type === "analysis"
            ? metabaseDashboard?.graph_name || "No name"
            : documentMagicData.name
        }
        type={type}
        totalSections={
          documentMagicData ? documentMagicData.count?.sections : 0
        }
        totalBlocks={documentMagicData ? documentMagicData.count?.blocks : 0}
        lite={lite}
      />
      {type !== "analysis" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <MagicTypeOfDocumentView type={documentMagicData.type} />
          <div className={styles.rightInfoElem}>
            <MagicTypePublishView
              status={
                documentMagicData.status?.toLowerCase() === "work" &&
                documentMagicData.last_published_document_baseline_id
                  ? "published"
                  : documentMagicData.status
              }
            />
            <MagicVersionView version={documentMagicData.version} />
            <AvatarComponent
              lastName={documentMagicData.user?.last_name ?? "undefined"}
              firstName={documentMagicData.user?.first_name ?? "undefined"}
              src={documentMagicData.user?.avatar?.media_content_uid}
              style={{ border: "solid 0.5px black", borderRadius: "50%" }}
            />
          </div>
        </div>
      )}
    </header>
  );
}
