import { useTranslation } from "react-i18next";
import { Input } from "antd";
import {
  useAddLinkageNode,
  useAddLinkageNodeDispatch,
} from "../Provider/AddLinkageNodeContext";

const { Search } = Input;

export default function AddLinkageNodeHeroSearch() {
  const { t } = useTranslation();
  const addLinkageNode = useAddLinkageNode();
  const dispatchAddLinkageNode = useAddLinkageNodeDispatch();

  return (
    <Search
      placeholder={t("menu.search")}
      onSearch={(e) => {
        dispatchAddLinkageNode({
          type: "setQueryNameTextFilter",
          queryNameTextFilter: e,
        });
        dispatchAddLinkageNode({
          type: "setPagination",
          pagination: {
            page: 1,
            pageSize: addLinkageNode.pagination.pageSize,
            total: addLinkageNode.pagination.total,
          },
        });
      }}
      allowClear
      style={{ width: 300 }}
    />
  );
}
