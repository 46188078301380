import { Spin, message } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CsvExecution from "./CsvExecution";
import * as XLSX from "xlsx";
import CsvExecutionOfDocument from "./CsvExecutionOfDocument";
import AlertedBlockPreview from "../AlertedBlockPreview";
import ExecutionTreeModel from "./ExecutionTreeModel";
import {
  ButtonType,
  ExecutionMonitorCloudActions,
  ExpandableDocumentList,
  IconButton,
  ModalExportParamPanelWrapper,
  SizeType,
  ThreeDotMenu,
} from "@armtekfrance/webgate/dist";
import { useParams, useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";
import ExecutionPreviewV2 from "./ExecutionPreviewV2";
import { Manager } from "@felixkletti/managementjs";
import { ApiCsvData } from "@armtekfrance/corenexus-pn-sn";

interface TableType {
  emitFilter?: any;
  expandAll?: boolean;
}

export default function ExecutionTable({ emitFilter, expandAll }: TableType) {
  const { t } = useTranslation();

  const { executionId } = useParams();
  const [searchParams] = useSearchParams();
  const sectionIdParam = searchParams.get("sectionId");

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewId, setPreviewId] = useState(0);
  const [sectionId, setSectionId] = useState<number | undefined>();
  const [csvExecutionOfDocumentId, setcsvExecutionOfDocumentId] = useState(0);
  const [isCsvExecutionOfDocumentVisible, setIsCsvExecutionOfDocumentVisible] =
    useState(false);

  const [isExportPdfParamModalVisible, setIsExportPdfParamModalVisible] =
    useState(false);

  useEffect(() => {
    if (executionId) {
      handleVisiblePreview(
        parseInt(executionId),
        sectionIdParam ? parseInt(sectionIdParam) : undefined
      );
    }
  }, []);

  const handleCancelCSVModalOfDocument = () => {
    setIsCsvExecutionOfDocumentVisible(!isCsvExecutionOfDocumentVisible);
  };

  const handleVisiblePreviewOfDocument = (id: number) => {
    setIsCsvExecutionOfDocumentVisible(!isCsvExecutionOfDocumentVisible);
    setcsvExecutionOfDocumentId(id);
  };

  const handleVisiblePreview = (id: number, sectionId?: number) => {
    setIsPreviewVisible(true);
    setPreviewId(id);
    setSectionId(sectionId ? sectionId : undefined);
  };
  const handleCancel = () => {
    setIsPreviewVisible(false);
  };
  const handleCancelCSVModal = () => {
    setIsCsvModalVisible(!isCsvModalVisible);
  };

  const handleCancelAlertedBlocksPreview = () => {
    setIsShowAlertedBlocksPreviewVisible(!isShowAlertedBlocksPreviewVisible);
  };

  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isCsvModalVisible, setIsCsvModalVisible] = useState(false);
  const [execId, setExecId] = useState<number>(0);
  const [executionDataForFlux, setExecutionDataForFlux] = useState<any>();
  const [
    isShowAlertedBlocksPreviewVisible,
    setIsShowAlertedBlocksPreviewVisible,
  ] = useState(false);
  const [csvData, setCsvData] = useState<any>({});

  const [showExecutionTreeModel, setShowExecutionTreeModel] =
    useState<boolean>(false);
  const [parentExecutionId, setParentExecutionId] = useState<number | string>(
    ""
  );
  const [selectedId, setSelectedId] = useState<number | string | undefined>("");
  const [showTableForTree, setShowTableForTree] = useState<boolean>(false);
  const [exactPath, setExactPath] = useState(false);

  const onClickParentExecutionId = (
    parent_execution_id: number | string,
    showTable: boolean,
    selectedId?: number | string | undefined,
    exactPath?: boolean
  ) => {
    // setLoadingExecutionTreeModel(true);
    setShowTableForTree(showTable);
    setShowExecutionTreeModel(true);
    setParentExecutionId(parent_execution_id);
    setSelectedId(selectedId);
    setExactPath(exactPath ? exactPath : false);
  };
  const handleCancelExecutionTreeModel = () => {
    setShowExecutionTreeModel(false);
  };

  // const generatePDFExecution = (id: number) => {
  //   setIsPdfLoading(true);
  //   try {
  //     fetchBaselineExecutionByIdAsPdf(id.toString()).then((response) => {
  //       if (!response.data) {
  //         message.error(t("executionTable.ooopsPdf"));
  //         setIsPdfLoading(false);
  //       } else {
  //         fetchBaselineExecutionById(id).then((responseBaseline) => {
  //           let fileURL = window.URL.createObjectURL(response.data);
  //           let alink = document.createElement("a");
  //           alink.href = fileURL;
  //           alink.download = `${
  //             responseBaseline.data.baseline_name.replace(/,/g, "") +
  //             " " +
  //             responseBaseline.data.baseline_version
  //           }.pdf`;
  //           alink.click();
  //           window.open(fileURL);
  //           setIsPdfLoading(false);
  //         });
  //       }
  //     });
  //   } catch (error) {
  //     setIsPdfLoading(false);
  //     message.error(t("executionTable.ooopsPdf"));
  //   }
  // };

  const [rawCsv, setRawCsv] = useState<ApiCsvData>();
  const showCSV = async (id: number, executionData: any) => {
    setIsPdfLoading(true);
    setExecId(id);
    setExecutionDataForFlux(executionData);
    Manager.API.emit(ExecutionMonitorCloudActions.EXECUTION_MONITOR_RETRIEVES, {
      executions: [id],
      config: {
        extend: true,
        csvData: {
          extend: true,
        },
      },
    }).subscribe((response) => {
      const csvData = response.response.executions[0].data?.csvData;
      if (!csvData) {
        message.error("No CSV data found");
        setIsPdfLoading(false);
        return;
      }
      setRawCsv(csvData);
      setCsvData([csvData.json]);
      setIsCsvModalVisible(true);
      setIsPdfLoading(false);
    });

    // setIsCsvModalVisible(true);
  };

  const downloadXLSXFile = (isExcelColumn: boolean) => {
    let excelData: {}[] = [];
    if (isExcelColumn) {
      Object.keys(csvData[0]).map((c) => {
        let ret = csvData.map((r: any) => r[c]);
        ret.unshift(c);
        excelData.push(ret);
      });
    }
    let worksheet: any;
    if (isExcelColumn) {
      worksheet = XLSX.utils.json_to_sheet(excelData, { skipHeader: true });
    } else {
      worksheet = XLSX.utils.json_to_sheet(csvData);
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, csvData[0].baseline_name + "_execution.xlsx");
  };

  const downloadCSVFile = () => {
    const url = window.URL.createObjectURL(new Blob([rawCsv?.csv ?? ""]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", csvData[0].baseline_name + "_execution.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const [alertedBlocks] = useState<any>({});

  const downloadOnDropDownCSVFile = async (id: number) => {
    Manager.API.emit(ExecutionMonitorCloudActions.EXECUTION_MONITOR_RETRIEVES, {
      executions: [id],
      config: {
        extend: true,
        csvData: {
          extend: true,
        },
      },
    }).subscribe((response) => {
      const csvData = response.response.executions[0].data?.csvData;
      const url = window.URL.createObjectURL(new Blob([csvData?.csv ?? ""]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        csvData?.json.baseline_name + "_execution.csv"
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };
  const downloadOnDropDownXLSXFile = async (id: number) => {
    Manager.API.emit(ExecutionMonitorCloudActions.EXECUTION_MONITOR_RETRIEVES, {
      executions: [id],
      config: {
        extend: true,
        csvData: {
          extend: true,
        },
      },
    }).subscribe((response) => {
      const csvData = response.response.executions[0].data?.csvData;
      const worksheet = XLSX.utils.json_to_sheet([csvData?.json]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, csvData?.json.baseline_name + "_execution.xlsx");
    });
  };

  return (
    <div>
      <Spin
        spinning={isPdfLoading}
        tip={<p style={{ color: "black" }}>{t("pdfLoading.pleaseWait")}</p>}
        size="large"
      >
        <ExpandableDocumentList
          emitFilter={emitFilter}
          selectType="multiple"
          object={"ApiExecutionMonitor"}
          onCheck={(values) => {
            console.log(values);
          }}
          expandAll={expandAll}
          actionButtons={(currentDoc) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                type={ButtonType.underline}
                iconData="EyeOutlined"
                onClick={() => handleVisiblePreview(currentDoc.id)}
              />
              <IconButton
                type={ButtonType.underline}
                iconData="FilePdfOutlined"
                onClick={() => {
                  setExecId(currentDoc.id);
                  setIsExportPdfParamModalVisible(true);
                }}
              />
              <IconButton
                type={ButtonType.underline}
                iconData="TableOutlined"
                onClick={() => showCSV(currentDoc.id, currentDoc)}
              />

              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={styles.threeDotMenuWrapper}
              >
                <ThreeDotMenu
                  size={SizeType.small}
                  menuOptions={[
                    {
                      label: t("operate.downloadCsv"),
                      icon: "DownloadOutlined",
                      onClick: () => downloadOnDropDownCSVFile(currentDoc.id),
                    },
                    {
                      label: t("operate.downloadXlsx"),
                      icon: "DownloadOutlined",
                      onClick: () => downloadOnDropDownXLSXFile(currentDoc.id),
                    },
                    {
                      label: t("operate.groupedExport"),
                      icon: "DownloadOutlined",
                      onClick: () =>
                        handleVisiblePreviewOfDocument(currentDoc.id),
                    },
                    {
                      label: t("operate.groupedExecutionTree"),
                      icon: "DownloadOutlined",
                      onClick: () =>
                        onClickParentExecutionId(currentDoc.id, true),
                    },
                  ]}
                />
              </div>
            </div>
          )}
        />

        {isPreviewVisible ? (
          <ExecutionPreviewV2
            isModalVisible={isPreviewVisible}
            handleCancel={handleCancel}
            documentId={previewId}
            sectionId={sectionId}
          />
        ) : (
          <></>
        )}
        {isCsvModalVisible ? (
          <CsvExecution
            isCsvModalVisible={isCsvModalVisible}
            handleCancel={handleCancelCSVModal}
            csvData={csvData[0]}
            downloadCSVFile={downloadCSVFile}
            downloadXLSXFile={downloadXLSXFile}
            execId={execId}
            onPdfDownload={() => {
              setIsExportPdfParamModalVisible(true);
              setIsCsvModalVisible(false);
            }}
            executionData={executionDataForFlux}
          />
        ) : (
          <></>
        )}
        {isCsvExecutionOfDocumentVisible ? (
          <CsvExecutionOfDocument
            isModalVisible={isCsvExecutionOfDocumentVisible}
            handleCancel={handleCancelCSVModalOfDocument}
            documentId={csvExecutionOfDocumentId}
          />
        ) : (
          <></>
        )}
        {isShowAlertedBlocksPreviewVisible ? (
          <AlertedBlockPreview
            isPreviewVisible={isShowAlertedBlocksPreviewVisible}
            handleCancel={handleCancelAlertedBlocksPreview}
            alert={alertedBlocks}
          />
        ) : (
          <></>
        )}
        {showExecutionTreeModel && (
          <ExecutionTreeModel
            isModalVisible={showExecutionTreeModel}
            handleCancel={handleCancelExecutionTreeModel}
            parentExecutionId={parentExecutionId}
            selectedId={selectedId}
            showTable={showTableForTree}
            exactPath={exactPath}
          />
        )}
        {isExportPdfParamModalVisible && (
          <ModalExportParamPanelWrapper
            open={isExportPdfParamModalVisible}
            onCancel={() => setIsExportPdfParamModalVisible(false)}
            execution_monitor_data_id={execId}
          />
        )}
      </Spin>
    </div>
  );
}
