import { useTranslation } from "react-i18next";

export default function KnowledgeLayout() {
  const { t } = useTranslation();

  return (
    <main
      style={{
        alignItems: "center",
        alignContent: "center",
        textAlign: "center",
        marginTop: "13%",
      }}
    >
      <img
        src="/Sangeetha-guillaume-inass.gif"
        style={{ height: "248px" }}
        alt="We are working on it"
      />
      <p
        style={{
          color: "#3F6481",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
          fontWeight: "800px",
          fontSize: "18px",
          marginLeft: "72px",
          marginBottom: "4px",
        }}
      >
        {t("soon.workingOnFeatures")}
      </p>
      <p
        style={{
          color: "#3F6481",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
          fontWeight: "800px",
          fontSize: "18px",
          marginLeft: "72px",
        }}
      >
        {t("soon.comeBackLater")}
      </p>
    </main>
  );
}
