import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styles from "./NotificationHero.module.scss";
import { useTranslation } from "react-i18next";
import { WarningIcon } from "@armtekfrance/armtek-react-design-system/dist/assets";

export default function AlertsHeroFullPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.notificationHeroWrapper}>
      <WarningIcon aria-label="warring" />
      <h3>{t("dashboard.alerts")}</h3>
      <CloseOutlined onClick={() => navigate(-1)} />
    </div>
  );
}
