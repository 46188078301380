import { ReactComponent as Camera } from "../TempDS/assets/Camera.svg";
import { ReactComponent as AddAudio } from "../TempDS/assets/AddAudio.svg";
import { ReactComponent as AddImage } from "../TempDS/assets/AddImage.svg";
import { ReactComponent as Folder } from "../TempDS/assets/Folder.svg";
import styles from "./MediaSelectorAddNew.module.scss";

export default function MediaSelectorAddNew({
  block_type,
  onClickAddNew,
}: {
  block_type: "image" | "video" | "audio" | "file";
  onClickAddNew: () => void;
}) {
  return (
    <div className={styles.mediaSelectorAddNewWrapper} onClick={onClickAddNew}>
      {block_type === "image" && (
        <div>
          <AddImage />
        </div>
      )}
      {block_type === "video" && (
        <div>
          <Camera />
        </div>
      )}
      {block_type === "audio" && (
        <div>
          <AddAudio />
        </div>
      )}
      {block_type === "file" && (
        <div>
          <Folder />
        </div>
      )}
    </div>
  );
}
