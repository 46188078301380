import { updateDocumentBaselineValidationStatusTanStack } from "app/stores/documentBaselineValidation/documentsBaselineValidationClient";
import { Button, ButtonType } from "@armtekfrance/armtek-react-design-system";
import { useTranslation } from "react-i18next";

export default function ValidationStatusActionModal({
  document_baseline_id,
  closeModal,
  comment,
}: {
  document_baseline_id: number;
  closeModal: Function;
  comment: string;
}) {
  const { t } = useTranslation();
  const mutation = updateDocumentBaselineValidationStatusTanStack();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Button
        type={ButtonType.primary}
        onClick={() => {
          mutation.mutate({
            document_baseline_id,
            validationStatus: "VALIDATED",
            comment: comment,
          });
          closeModal(false);
        }}
        child={<p style={{ margin: 0 }}>{t("obsoleteDocsTable.validate")}</p>}
      />
      <Button
        type={ButtonType.primary}
        onClick={() => {
          mutation.mutate({
            document_baseline_id,
            validationStatus: "REFUSED",
            comment: comment,
          });
          closeModal(false);
        }}
        child={<p style={{ margin: 0 }}>{t("obsoleteDocsTable.askReview")}</p>}
      />
    </div>
  );
}
