import { useState, useEffect, useRef } from "react";
import {
  message,
  Col,
  Row,
  Form,
  Input,
  Space,
  List,
  Button,
  Typography,
  Skeleton,
  Tooltip,
} from "antd";
import { AiOutlinePlus, AiOutlineUser } from "react-icons/ai";
import {
  createCompanyDepartment,
  fetchCompanyDepartments,
  fetchSingleCompanyDepartment,
  updateCompanyDepartment,
  deleteCompanyDepartment,
} from "../../../app/services/company";
import EditCompanyDepartmentLoader, { loadingData } from "./Loader";
import { useTranslation, I18nContext } from "react-i18next";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";

const { Title, Text } = Typography;

export default function CompanyDepartment() {
  const { t, i18n } = useTranslation();
  const createFormRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [addDepartment, setAddDepartment] = useState(false);
  const [departmentsLoading, setDepartmentsLoading] = useState(true);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [activeId, setActiveId] = useState<number | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [archiveDepartmentId, setArchiveDepartmentId] = useState<number | null>(
    null
  );

  // Update the company departments list on page load
  useEffect(() => {
    fetchDepartments();
    // eslint-disable-next-line
  }, []);

  // Fetch company departments
  const fetchDepartments = async () => {
    try {
      setDepartmentsLoading(true);
      const CompanyDepartments = await fetchCompanyDepartments();
      if (CompanyDepartments.success) {
        setDepartments(CompanyDepartments.data);
      }
      setDepartmentsLoading(false);
    } catch (error) {
      console.error(error);
      setDepartmentsLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("departments.fetchError"),
      });
    }
  };

  // Fetch Single company department
  const fetchSingleDepartment = async (id: number) => {
    try {
      setDepartmentLoading(true);
      const CompanyDepartment = await fetchSingleCompanyDepartment(id);
      if (CompanyDepartment.success) {
        form.setFieldsValue(CompanyDepartment.data);
      }
      setDepartmentLoading(false);
    } catch (error) {
      console.error(error);
      setDepartmentLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("departments.fetchError"),
      });
    }
  };

  // On form submit [Create / Edit] department
  const onFinish = async (values: any) => {
    let response;
    const action = !activeId ? "created" : "updated";
    try {
      setFormLoading(true);
      if (activeId) {
        response = await updateCompanyDepartment(activeId, values);
      } else {
        response = await createCompanyDepartment(values);
      }
      if (response.success) {
        openDepartmentForm(false);
        fetchDepartments();
        form.resetFields();
        messageApi.open({
          type: "success",
          duration: 5,
          content:
            t("departments.hasBeen") +
            " " +
            t(`departments.${action}`) +
            " " +
            t("departments.successfully"),
        });
      }
      setFormLoading(false);
    } catch (error) {
      console.error(error);
      setFormLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("departments.couldNot") + " " + t(`departments.${action}`),
      });
    }
  };

  // On delete department submit
  const onDeleteSubmit = async (id: number) => {
    try {
      setDeleteLoading(true);
      const response = await deleteCompanyDepartment(id);
      if (response.success) {
        fetchDepartments();
        messageApi.open({
          type: "success",
          duration: 5,
          content: t(`departments.deleteSuccess`),
        });
      } else {
        messageApi.open({
          type: "error",
          duration: 5,
          content:
            t(`departments.deleteError`) + " : \n" + typeof response.message ===
            "string"
              ? response.message
              : i18n.language === "en"
              ? response?.message.en
              : response?.message.fr,
        });
      }
      setDeleteLoading(false);
    } catch (error) {
      console.error(error);
      setDeleteLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content: t(`departments.deleteError`),
      });
    }
  };

  // Scroll to the departMent form
  const executeScroll = () => {
    if (createFormRef.current) {
      createFormRef.current.scrollIntoView();
    }
  };

  // Alias used to oopen or close the departMent form
  const openDepartmentForm = async (
    open: boolean,
    departmentId?: number | null
  ) => {
    form.resetFields();
    setAddDepartment(open);
    if (departmentId) {
      await fetchSingleDepartment(departmentId);
    }
  };

  // On Department edit
  const editDepartment = (id: number) => {
    setActiveId(id);
    openDepartmentForm(true, id);
    executeScroll();
  };

  // On Department delete
  const deleteDepartment = (id: number) => {
    setActiveId(id);
    onDeleteSubmit(id);
  };

  // On Add Department
  const createDepartment = () => {
    setActiveId(null);
    openDepartmentForm(!addDepartment, null);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", margin: "1rem 6rem" }}
    >
      {contextHolder}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <Title level={4} className="mb-0">
            {t("company.companyDepartments")}
          </Title>
          <Text>{t("company.manageCompanyDept")}</Text>
        </div>
        <Button
          type={!addDepartment ? "primary" : "default"}
          icon={!addDepartment ? <AiOutlinePlus /> : null}
          onClick={() => createDepartment()}
        >
          &nbsp;{" "}
          {!addDepartment ? t("departments.add") : t("departments.hideForm")}
        </Button>
      </div>
      {addDepartment && (
        <div className="shadow p-3 rounded mb-5" ref={createFormRef}>
          <Title level={5}>
            {activeId ? (
              <>
                {t(`departments.editWithId`)} {`: #${activeId}`}
              </>
            ) : (
              t("departments.add")
            )}
          </Title>
          {departmentLoading ? (
            <EditCompanyDepartmentLoader />
          ) : (
            <Form
              name="companyDepartmentForm"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              requiredMark
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label={t("departments.name")}
                    rules={[
                      {
                        required: true,
                        message: t("departments.nameInput"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("departments.nameInput")}
                      maxLength={250}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label={t("departments.description")}
                    rules={[
                      {
                        required: true,
                        message: t("departments.descriptionInput"),
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={4}
                      maxLength={250}
                      placeholder={t("departments.descriptionInput")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="d-flex justify-content-end">
                    <Space>
                      <Button
                        type="primary"
                        loading={formLoading}
                        htmlType="submit"
                      >
                        {formLoading ? t("company.saving") : t("button.save")}
                      </Button>
                      <Button onClick={() => openDepartmentForm(false)}>
                        {t("button.cancel")}
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      )}
      <div className="my-3 mb-5">
        <Title level={5}>{t("companyTable.department")}</Title>
        <List
          itemLayout="horizontal"
          className="shadow rounded"
          dataSource={departmentsLoading ? loadingData : departments}
          bordered
          renderItem={(item) => (
            <List.Item
              actions={
                !departmentsLoading
                  ? [
                      <Tooltip title={t("company.editDepartment")}>
                        <Button
                          type="ghost"
                          onClick={() => editDepartment(item.id)}
                        >
                          {t("button.edit")}
                        </Button>
                      </Tooltip>,
                      <Tooltip title={t("departments.delete")}>
                        <Button
                          loading={deleteLoading && activeId === item.id}
                          danger
                          onClick={() => {
                            setIsArchiveModalVisible(true);
                            setArchiveDepartmentId(item.id);
                          }}
                        >
                          {t("button.delete")}
                        </Button>
                      </Tooltip>,
                    ]
                  : [<Skeleton.Button active />, <Skeleton.Button active />]
              }
            >
              <Skeleton loading={departmentsLoading} active>
                <List.Item.Meta
                  title={
                    <Title level={5}>
                      <div className="rounded-pill px-3 py-1 shadow d-inline-flex justify-content-center">
                        <div className="d-flex border-end pe-3">
                          <AiOutlineUser size={20} /> {item._count.user}
                        </div>
                        <div style={{ wordBreak: "break-word" }}>
                          &nbsp;&nbsp;{item.name}
                        </div>
                      </div>
                    </Title>
                  }
                  description={
                    <div style={{ wordBreak: "break-word" }}>
                      {item.description}
                    </div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
      <ConfirmActionModal
        isVisible={isArchiveModalVisible}
        handleCancel={() => setIsArchiveModalVisible(false)}
        handleConfirm={() => {
          if (archiveDepartmentId) {
            deleteDepartment(archiveDepartmentId);
          }
          setArchiveDepartmentId(null);
          setIsArchiveModalVisible(false);
        }}
        confirmInsideText={t("departments.permanentDelete")}
        confirmHeaderText={t("archived.title")}
      />
    </div>
  );
}
