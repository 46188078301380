import { useReducer } from "react";
import { teamLayoutReducer } from "./TeamLayoutReducer";
import {
  TeamLayoutContext,
  TeamLayoutDispatchContext,
} from "./TeamLayoutContext";

export default function TeamLayoutProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [teamLayout, dispatchTeamLayout] = useReducer(teamLayoutReducer, {
    statusTeams: "loading",
    errorTeams: null,
    teams: undefined,
    activeId: null,
    deleteLoading: false,
    newTeamCreated: false,
    isArchiveModalVisible: false,
    archiveTeamId: null,
  });

  return (
    <TeamLayoutContext.Provider value={teamLayout}>
      <TeamLayoutDispatchContext.Provider value={dispatchTeamLayout}>
        {children}
      </TeamLayoutDispatchContext.Provider>
    </TeamLayoutContext.Provider>
  );
}
