import i18next from "i18next";
import i18n from "translations/i18n";
export let validationStatusQueryStatusInitArray: {
  label: string;
  key: "PENDING" | "VALIDATED" | "REFUSED";
}[] = [];

function updateTranslations() {
  validationStatusQueryStatusInitArray = [
    {
      label: i18n.t("validationTable.pendingBig"),
      key: "PENDING",
    },
    {
      label: i18n.t("validationTable.validatedBig"),
      key: "VALIDATED",
    },
    {
      label: i18n.t("validationTable.refusedBig"),
      key: "REFUSED",
    },
  ];
}

if (i18n.isInitialized) {
  updateTranslations();
}

i18next.on("languageChanged", () => {
  updateTranslations();
});
