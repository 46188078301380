import React, { useEffect, useState } from "react";
import { Col, List, Row } from "antd";
import {
  fetchDocumentBaselines,
  fetchDocumentsBaselineByID,
} from "../../app/stores/documentsBaseline/documentsBaselineClient";
import ImageDisplayComponent from "../../components/ImageDisplayComponent/ImageDisplayComponent";
import { useTranslation } from "react-i18next";

export default function NewsLayout() {
  const { t } = useTranslation();
  const [list, setList] = useState<any>([]);
  //const [image, setImage] = useState<any>([]);
  useEffect(() => {
    let filters: any = {
      type: "communication",
    };
    let sort = { id: "desc" };
    fetchDocumentBaselines(1, 4, filters, sort).then(async (response) => {
      let baselines: any = [];
      for await (let baselineData of response.documentBaselines) {
        await baselineContent(baselineData.id).then((contentData) => {
          baselineData.firstContent = contentData;
          baselines.push(baselineData);
        });
      }
      setList(baselines);
    });
  }, []);

  const baselineContent = async (id: any) => {
    const docBaseline = await fetchDocumentsBaselineByID(id);
    for (const section of docBaseline.data
      .document_baseline_has_section_baseline) {
      for (const block of section.section_baseline
        .section_baseline_has_block_baseline) {
        for (const content of block.block_baseline.block_baseline_has_content) {
          if (
            content.content.content_json.content &&
            content.content.content_json.content.length > 0
          ) {
            return content.content.content_json.content;
          }
        }
      }
    }
  };
  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={list}
      renderItem={(props: any) => (
        <div>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              {props?.media_content &&
              (props?.media_content.type === "image" ||
                props?.media_content.type === "picture") &&
              props?.media_content?.media_content_uid ? (
                <div
                  style={{
                    height: "126px",
                    width: "183px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ImageDisplayComponent
                    src={props.media_content?.media_content_uid}
                    alt={""}
                    style={{
                      height: "126px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col
              style={{
                width: props.media_content ? "50%" : "100%",
                marginLeft: "15px",
              }}
            >
              <div
                style={{
                  paddingBottom: "4px",
                  color: "#676262",
                  fontFamily: "SF Pro Text",
                  fontStyle: "normal",
                  fontWeight: "400px",
                  fontSize: "12px",
                }}
              >
                {t("doc.models.published")}
                {"  "}
                {new Intl.DateTimeFormat("en-GB").format(
                  new Date(props.published_at)
                )}
              </div>{" "}
              <div
                style={{
                  fontWeight: "bold",
                  paddingBottom: "8px",
                  overflowWrap: "break-word",
                }}
              >
                {props.name}
              </div>
              <div
                style={{
                  paddingBottom: "30px",
                  fontSize: "14px",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  wordBreak: "break-all",
                }}
              >
                {props.firstContent?.replace(/<[^>]*>/g, "").substring(0, 240)}
                {props.firstContent?.length > 240 ? "..." : ""}
              </div>
            </Col>
          </Row>
        </div>
      )}
    />
  );
}
