import { matchPath, useLocation } from "react-router-dom";
import HeaderMenuTabDashborad from "./HeaderMenuTabDashborad";
import HeaderMenuTabPlan from "./HeaderMenuTabPlan";
import HeaderMenuTabResults from "./HeaderMenuTabResults";
import HeaderMenuTabSettings from "./HeaderMenuTabSettings";

const pathIsMatching = (pathToMatch: string, locationPath: string) => {
  return matchPath(`/account/${pathToMatch}/*`, locationPath) !== null;
};

export default function HeaderMenuTab() {
  const location = useLocation();

  if (pathIsMatching("dashboard", location.pathname))
    return <HeaderMenuTabDashborad />;

  if (pathIsMatching("plan", location.pathname)) return <HeaderMenuTabPlan />;

  if (pathIsMatching("models", location.pathname)) return <></>;

  if (pathIsMatching("releases", location.pathname)) return <></>;

  if (pathIsMatching("results", location.pathname))
    return <HeaderMenuTabResults />;

  if (pathIsMatching("settings", location.pathname))
    return <HeaderMenuTabSettings />;

  return <></>;
}
