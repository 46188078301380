import {
  countNotificationsTanStack,
  fetchNotificationsTanStack,
  openNotificationTanStack,
} from "app/stores/notifications/notificationsClient";
import NotificationHeroFullPage from "./Hero/NotificationHeroFullPage";
import { useEffect } from "react";
import {
  useNotifications,
  useNotificationsDispatch,
} from "./Provider/NotificationsContext";
import NotificationModal from "./Modal/NotificationModal";
import NotificationsFullList from "./NotificationsFullList";
import styles from "./Notifications.modules.scss";
import {
  checkIfItHasALink,
  extractDynamicLinkIdFromNotALink,
  isProcedureOrExecution,
} from "./NotificationsUtils";
import { useNavigate } from "react-router-dom";
import { notificationType } from "app/stores/notifications/notificationsType";
import AlertsHeroFullPage from "./Hero/AlertsHeroFullPage";

/*
 * Old redirection: web_redirection: "/account/models/info/content/" + document_model_id,
 * New redirection: web_redirection: /account/knowledge/models/info/4782
 */

/*
 * Old redirection: web_redirection: `/account/releases/validationStatus`,
 * New redirection: web_redirection: /account/knowledge/validate,
 */

/*
 * Old redirection: web_redirection: "/account/releases/releases",
 * New redirection: web_redirection: /account/knowledge/releases,
 */

export const reformattedWebRedirection = (webRedirection: string) => {
  if (webRedirection.includes("/account/models/info/content/")) {
    return webRedirection.replace(
      "/account/models/info/content/",
      "/account/knowledge/models/info/"
    );
  } else if (webRedirection.includes("/account/releases/validationStatus")) {
    return webRedirection.replace(
      "/account/releases/validationStatus",
      "/account/knowledge/validate"
    );
  } else if (webRedirection.includes("/account/releases/releases")) {
    return webRedirection.replace(
      "/account/releases/releases",
      "/account/knowledge/releases"
    );
  }
  return webRedirection;
};

export default function NotificationsOrAlerts({
  typeOfNotidications,
}: {
  typeOfNotidications: "NOTIFICATIONS" | "ALERTS";
}) {
  const notificationsContext = useNotifications();
  const dispatchNotifications = useNotificationsDispatch();
  const mutation = openNotificationTanStack();
  const navigate = useNavigate();

  const {
    status: statusNotifications,
    error: errorNotifications,
    notifications: notifications,
  } = fetchNotificationsTanStack(
    1,
    100,
    {
      alert: typeOfNotidications === "ALERTS",
    },
    { createdAt: "desc" }
  );

  useEffect(() => {
    dispatchNotifications({
      type: "setNotificationTanstackApi",
      setNotificationTanstackApi: {
        statusNotifications,
        errorNotifications,
      },
    });
    if (statusNotifications === "success") {
      dispatchNotifications({
        type: "setNotificationTanstack",
        setNotificationTanstack: {
          notifications: notifications?.notifications,
          paginationNotifications: notifications?.pagination ?? {
            page: 1,
            pageSize: 10,
            total: -1,
          },
        },
      });
    }
  }, [statusNotifications, errorNotifications, notifications]);

  const {
    status: statusNotificationsCount,
    error: errorNotificationsCount,
    notificationsCount,
  } = countNotificationsTanStack({
    opened: false,
    alert: typeOfNotidications === "ALERTS",
  });

  useEffect(() => {
    dispatchNotifications({
      type: "setNotificationCountTanstackApi",
      setNotificationCountTanstackApi: {
        statusNotificationsCount,
        errorNotificationsCount,
      },
    });
    if (statusNotificationsCount === "success") {
      dispatchNotifications({
        type: "setNotificationCountTanstack",
        setNotificationCountTanstack: {
          notificationsCount: notificationsCount! ?? -1,
        },
      });
    }
  }, [statusNotificationsCount, errorNotificationsCount, notificationsCount]);

  const handelNotificationOnClick = (notification: notificationType) => {
    mutation.mutate({
      id: notification.id,
      opened: true,
      receiverId: notification.receiver_id,
    });

    if (checkIfItHasALink(notification.web_redirection)) {
      navigate(reformattedWebRedirection(notification!.web_redirection!));
    } else {
      dispatchNotifications({
        type: "setPreview",
        previewInfo: {
          isOpen: true,
          idOfPreview:
            notification.targets && notification.targets.length > 0
              ? notification
              : extractDynamicLinkIdFromNotALink(
                  notification!.web_redirection!
                ),
          typeOfPreview: isProcedureOrExecution(notification),
        },
      });
    }
  };

  return (
    <div className={styles.notificationsWrapper}>
      {typeOfNotidications === "ALERTS" ? (
        <AlertsHeroFullPage />
      ) : (
        <NotificationHeroFullPage />
      )}
      {notificationsContext.statusNotifications === "success" && (
        <>
          <div className={styles.notificationsLists}>
            <NotificationsFullList
              notificationsByDay={notificationsContext.closedNotificationsByDay}
              alertOrNotification={typeOfNotidications}
              typeOfNotidications="UNREAD"
              notificationCount={
                notificationsContext.notificationsCount
                  ? notificationsContext.notificationsCount
                  : 0
              }
              onClick={(notification) => {
                handelNotificationOnClick(notification);
              }}
            />
            <NotificationsFullList
              notificationsByDay={notificationsContext.openedNotificationsByDay}
              alertOrNotification={typeOfNotidications}
              typeOfNotidications="READ"
              notificationCount={0}
              onClick={(notification) => {
                handelNotificationOnClick(notification);
              }}
            />
          </div>
          <NotificationModal
            isModalVisible={notificationsContext.previewInfo.isOpen}
            typeOfPreview={notificationsContext.previewInfo.typeOfPreview}
            documentId={notificationsContext.previewInfo.idOfPreview}
            onCancel={() => dispatchNotifications({ type: "togglePreview" })}
          />
        </>
      )}
    </div>
  );
}
