import { useEffect, useState } from "react";
import { message, Spin } from "antd";
import {
  editContent,
  fetchBlobObject,
  uploadAFile,
} from "app/stores/content/contentClient";
import { UIEvent, PhotoEditorSDKUI, SerialisationSchema } from "photoeditorsdk";
import { useTranslation } from "react-i18next";
import { readFromCookies } from "libs/cookies";
import styles from "./ArmtekImageEditor.module.scss";
import imageEditorConfigs from "./configs";
import { Manager } from "@felixkletti/managementjs";
import { ContentCloudActions, MediaAction } from "@armtekfrance/webgate/dist";
import {
  ApiContentSource,
  ApiContentType,
} from "@armtekfrance/corenexus-pn-sn";

interface ArmtekImageEditorPropsType {
  media?: any;
  media_content_uid?: string;
  contentId: string | number;
  onSave: () => void;
  closeModal?: () => void;
}

export default function ArmtekImageEditor({
  media,
  media_content_uid,
  contentId,
  onSave,
  closeModal,
}: ArmtekImageEditorPropsType) {
  const [loading, setLoading] = useState(true);
  const [activeLanguage] = useState(readFromCookies("i18next") || "fr");
  const { t } = useTranslation();

  /**
   * Convert base64 file to Javascript File Object
   * @param dataURI
   * @returns
   */
  const dataURItoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(",");
    if (!arr.length) return null;
    const mimeBlock = arr[0].match(/:(.*?);/),
      mime = mimeBlock ? mimeBlock[1] : "",
      bstr = atob(arr[arr.length - 1]);
    let n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  /**
   * Prepare the edited file and upload it with the modification state
   * @param editedImageObject
   * @param designState
   * @returns
   */
  // TODO : Refactor this function
  const saveEditedImage = async (
    editedImageBase64: string,
    designState: SerialisationSchema
  ) => {
    if (!contentId) return undefined;

    // Setup file metaData
    const derivedFrom = media.metadata?.derivedFrom
      ? media.metadata.derivedFrom
      : media.media_content_uid;
    const newMetadata = JSON.stringify({
      serialization: designState,
      derivedFrom,
    });
    const editedFile = dataURItoFile(
      editedImageBase64,
      "edited-" + derivedFrom + "." + "png"
    );

    // Setup File object for upload
    if (!editedFile) return undefined;
    const formData = new FormData();
    formData.append("file", editedFile);
    formData.append("metadata", JSON.stringify(newMetadata));
    const fileUploadResponse = await uploadAFile(formData);
    if (fileUploadResponse.success) {
      const contentUpdate = {
        contentFile: {
          media_content_id: fileUploadResponse.data[0].id,
        },
      };
      await editContent(contentId, contentUpdate);

      if (!fileUploadResponse.data[0].id) return undefined;

      Manager.trigger(MediaAction.ON_IMAGE_EDITOR_SAVE, {
        media_content_id: fileUploadResponse.data[0].id,
        content_id: contentId,
      });

      if (onSave) {
        onSave();
      }
    } else {
      if (JSON.stringify(fileUploadResponse).includes("value too long")) {
        message.error({
          content: t("imageEditor.bigFile"),
        });
      } else {
        message.error(t("imageEditor.error"));
      }
    }
  };

  // Initialize and configure the image editor
  const initEditor = async (img: string) => {
    const editor = await PhotoEditorSDKUI.init({
      image: img,
      language: activeLanguage.slice(0, 2),
      ...imageEditorConfigs,
    });

    // Load Image editing state
    let imageState;
    if (media.metadata) {
      if (typeof media.metadata === "string" && JSON.parse(media.metadata)) {
        imageState = JSON.parse(media.metadata);
      } else {
        imageState = media.metadata;
      }
      if (imageState.serialization) {
        if (
          typeof imageState.serialization === "string" &&
          JSON.parse(imageState.serialization)
        ) {
          imageState = {
            ...imageState,
            serialization: JSON.parse(imageState.serialization),
          };
        }
        editor.deserialize(imageState.serialization);
      }
    }

    // On Edited image save to the server
    editor.on(UIEvent.EXPORT, (editedImageBase64) => {
      editor
        .serialize({ image: false })
        .then(async (state) => {
          // Save the edited image and the state to the server
          setLoading(true);
          await saveEditedImage(editedImageBase64, state);
        })
        .catch((err) => {
          console.error("An error has occured ", err);
        })
        .finally(() => setLoading(false));
    });

    // On close of the image editor, close the parent modal
    editor.on(UIEvent.CLOSE, () => {
      closeModal && closeModal();
    });
  };

  // Fetch the base64 image using the mediaUID
  useEffect(() => {
    try {
      const baseMedia = media.metadata?.derivedFrom
        ? media.metadata.derivedFrom
        : media.media_content_uid;
      if (media || media_content_uid) {
        fetchBlobObject(baseMedia).then((response) => {
          let dataUrl = "";

          if (response && response.media != null) {
            dataUrl = `data:${response.media.ContentType};base64, ${response.media.file}`;
          } else if (response && response.fullMedia != null) {
            dataUrl = `data:${response.fullMedia.ContentType};base64, ${response.fullMedia.file}`;
          } else {
            setLoading(false);
            closeModal && closeModal();
            message.error("There was a problem loading the image");
          }
          setLoading(false);
          initEditor(dataUrl);
        });
      }
    } catch (error) {
      message.error("There was a problem loading the image");
      setLoading(false);
    }
  }, []);

  return (
    <div className="p-3" style={{ height: "100%", width: "100%" }}>
      <div id="editor"></div>
      {loading && (
        <div className={styles.imageEditorContainer}>
          <div className={styles.imageEditorOverlay}></div>
          <div className={styles.imageEditorWrapper}>
            <Spin size="large" />
          </div>
        </div>
      )}
    </div>
  );
}
