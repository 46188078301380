import { ContentType } from "app/stores/content/type";
import styles from "./MediaContentComponentNew.module.scss";
import { fetchContentsTanStack } from "app/stores/content/contentClient";
import { Spin } from "antd";
import MediaDetails from "./MediaDetails";
import MediaSelectorAddNew from "components/MediaSelector/MediaSelectorAddNew";
import React from "react";
import { GlobalStore, MediaAction } from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";

export default function MediaContentComponentNew({
  blockModel,
  isPreview = false,
}: {
  blockModel: {
    id: number;
    company_id: number;
    user_id: number;
    block_model_uid: string;
    name: string;
    active: boolean;
    published: boolean;
    type: "image" | "video" | "audio" | "file";
    template: boolean;
    created_at: string;
    updated_at: string;
    content: ContentType[];
    position: number;
  };
  isPreview?: boolean;
}) {
  const { status, contents: mediaContents } = fetchContentsTanStack(
    "0",
    { position: "asc" },
    { block_model_id: blockModel.id }
  );

  return (
    <>
      {status === "success" && (
        <div className={styles.mediaContentComponentNewWrapper}>
          {mediaContents?.contents.map((mediaContent, index) => {
            return (
              <React.Fragment key={index}>
                {mediaContent.media_content_id !== null && (
                  <MediaDetails
                    content={mediaContent}
                    block_id={blockModel?.id?.toString()}
                    block_type={blockModel?.type}
                    isPreview={isPreview}
                  />
                )}
              </React.Fragment>
            );
          })}
          {!isPreview && (
            <MediaSelectorAddNew
              block_type={blockModel?.type}
              onClickAddNew={() => {
                GlobalStore.media.setTypeOfMediaFilters(blockModel.type);
                GlobalStore.media.setMediaPickerModalStatus({
                  block_id: blockModel?.id?.toString(),
                  block_type: blockModel?.type,
                  isCoverImageSelection: false,
                });
                Manager.trigger(MediaAction.OPEN_MEDIA_PICKER, true);
              }}
            />
          )}
        </div>
      )}
      {status === "loading" && <Spin />}
      {status === "error" && <div>error</div>}
    </>
  );
}
