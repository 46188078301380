import { useTranslation } from "react-i18next";
import {
  useValidationStatus,
  useValidationStatusDispatch,
} from "../../Provider/ValidationStatusContext";
import { validationStatusQueryStatusInitArray } from "../../Provider/ValidationStatusQueryStatusArray";
import { Popover } from "antd";
import DropdownMultiSelection from "components/ExecutionTable/DropdownMultiSelection";
import { DropdownButton } from "@armtekfrance/armtek-react-design-system";

export default function ValidationStatusHeroDropDownStatus() {
  const { t } = useTranslation();
  const validationStatus = useValidationStatus();
  const dispatchValidationStatus = useValidationStatusDispatch();

  return (
    <div>
      <Popover
        placement="bottom"
        trigger="click"
        content={
          <DropdownMultiSelection
            allItems={validationStatusQueryStatusInitArray.map((item) => {
              return {
                label: item.label,
                value: item.key,
              };
            })}
            styleDropdown={{ width: "334px" }}
            selectedItems={validationStatus.queryBaselineValidationsStatus}
            onSelectAll={(items) => {
              const list = items.map((item) => {
                return item.value as "PENDING" | "VALIDATED" | "REFUSED";
              });

              dispatchValidationStatus({
                type: "setQueryBaselineValidationsStatus",
                queryBaselineValidationsStatus: list,
              });
            }}
            onClear={() => {
              dispatchValidationStatus({
                type: "setQueryBaselineValidationsStatus",
                queryBaselineValidationsStatus: [],
              });
            }}
            onSelectItem={(itemList) => {
              const list = itemList as ("PENDING" | "VALIDATED" | "REFUSED")[];

              dispatchValidationStatus({
                type: "setQueryBaselineValidationsStatus",
                queryBaselineValidationsStatus: list,
              });
            }}
            placeholder={t("dropdownMultiSelection.selectStatus")}
          />
        }
      >
        {/*
         * // TODO add parameter "validationStatus.queryBaselineValidationsStatus.length" as counter for dropdown
         */}
        <DropdownButton
          name={t("doc.models.procedure.status")}
          selectedItems={validationStatus.queryBaselineValidationsStatus.length}
        />
        {/* <Button dropdownButton filter>
          <div className={styles.validationStatusHeroDropDownStatusButton}>
            <div>{t("doc.models.procedure.status")}</div>
            {validationStatus.queryBaselineValidationsStatus.length > 0 && (
              <div
                className={styles.validationStatusHeroDropDownStatusButtonBadge}
              >
                <p>{validationStatus.queryBaselineValidationsStatus.length}</p>
              </div>
            )}
          </div>
        </Button> */}
      </Popover>
    </div>
  );
}
