import { BellOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styles from "./NotificationHero.module.scss";
import { useTranslation } from "react-i18next";

export default function NotificationHeroFullPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.notificationHeroWrapper}>
      <BellOutlined />
      <h3>{t("dashboard.notifications")}</h3>
      <CloseOutlined onClick={() => navigate(-1)} />
    </div>
  );
}
