import { InfoCircleOutlined } from "@ant-design/icons";

export default function DashboardMaintenance({
  maintenance,
}: {
  maintenance: string;
}) {
  return (
    <div
      style={{
        border: "1px solid #D6E4FF ",
        borderRadius: "10px",
        padding: "8px",
        marginBottom: "8px",
        textAlign: "center",
        fontWeight: "bold",
        background: "#D6E4FF",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <InfoCircleOutlined
        style={{ color: "black", marginLeft: "32%", padding: "8px" }}
      />{" "}
      {maintenance}
    </div>
  );
}
