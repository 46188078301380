import {
  Col,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Button,
  Switch,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import ConditionComponent from "./ConditionComponent";
import unitJson from "./unitsList.json";
import DefineAlertedUsers, {
  deleteTeamRelatedToUser,
} from "./DefineAlertedUsers";
import AvatarComponent from "../../../AvatarComponent/AvatarComponent";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./ListUsers.module.scss";
import { useTranslation } from "react-i18next";

interface DataBlockType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => Promise<void>;
  disableEdit?: boolean;
  contentId: string | number;
  setIsContentBeingSaved: (value: boolean) => void;
}

interface OptionGroupType {
  label: string;
  options: {
    label: string;
    value: string;
  }[];
}
// TODO We have 2 DataBlock components, one in src/components/SectionsEdit/Blocks/DataBlock/index.tsx and one in src/components/ExecutionFluxBlocks/dataBlock.tsx ?
export default function DataBlock({
  data,
  updateContentInDb,
  disableEdit,
  contentId,
  setIsContentBeingSaved,
}: DataBlockType) {
  const { t } = useTranslation();

  const [inputData, setInputData] = useState(
    data
      ? data
      : {
          name: "",
          instruction: "",
          type: "int",
          units: "",
          conditionType: "fixValue",
          conditionValue: "",
          inputConditionValues: [0, 0, 0],
          mandatory: false,
          alertedUsers: [],
          selectedTeams: [],
        }
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    data
      ? setInputData(data)
      : setInputData({
          name: "",
          instruction: "",
          type: "int",
          units: "",
          conditionType: "fixValue",
          conditionValue: "",
          inputConditionValues: [0, 0, 0],
          mandatory: false,
          alertedUsers: [],
          selectedTeams: [],
        });
  }, [data]);
  const [unitsOption, setUnitsOption] = useState([]);
  useEffect(() => {
    let addUnits: any = [];
    let k: keyof typeof unitJson;
    for (k in unitJson) {
      let optionGroup: OptionGroupType = {
        label: k,
        options: [],
      };
      unitJson[k].forEach((unit) => {
        optionGroup.options.push({ label: unit, value: unit });
      });
      addUnits.push(optionGroup);
    }
    setUnitsOption(addUnits);
  }, []);

  let timeoutId: any;

  const changeMandatoryField = (mandatoryData: any) => {
    let reqDataCopy = { ...inputData };
    reqDataCopy["mandatory"] = mandatoryData;
    clearTimeout(timeoutId);
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    setIsContentBeingSaved(true);
    updateContentInDb(contentId, dataToUpdate).then(() => {
      setIsContentBeingSaved(false);
    });
    setInputData(reqDataCopy);
  };

  const onChangeData = (e: any, field: string) => {
    let updateData: any = {};
    updateData[field] = e.target.value;
    const copyOfData = { ...inputData, ...updateData };
    setInputData(copyOfData);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 1 second (1000 ms) after the last change
      const dataToUpdate: any = {
        content: {
          content: copyOfData,
        },
      };
      setIsContentBeingSaved(true);
      updateContentInDb(contentId, dataToUpdate).then(() => {
        setIsContentBeingSaved(false);
      });
    }, 1000);
  };

  const onShowModal = () => {
    setIsModalOpen(true);
  };

  const onChangeType = (e: RadioChangeEvent) => {
    let updateData: any = {};
    updateData["type"] = e.target.value;
    const copyOfData = { ...inputData, ...updateData };
    setInputData(copyOfData);
    clearTimeout(timeoutId);
    const dataToUpdate: any = {
      content: {
        content: copyOfData,
      },
    };
    setIsContentBeingSaved(true);
    updateContentInDb(contentId, dataToUpdate).then(() => {
      setIsContentBeingSaved(false);
    });
  };

  const handleChangeUnits = (value: string) => {
    let updateData: any = {};
    updateData["units"] = value;
    const copyOfData = { ...inputData, ...updateData };
    setInputData(copyOfData);
    clearTimeout(timeoutId);
    const dataToUpdate: any = {
      content: {
        content: copyOfData,
      },
    };
    setIsContentBeingSaved(true);
    updateContentInDb(contentId, dataToUpdate).then(() => {
      setIsContentBeingSaved(false);
    });
  };

  const onDeleteAlertedUser = async (userId: string) => {
    let reqDataCopy = await deleteTeamRelatedToUser(+userId, inputData);

    reqDataCopy.alertedUsers = reqDataCopy.alertedUsers.filter(
      (user: any) => user.id !== userId
    );
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    setIsContentBeingSaved(true);
    updateContentInDb(contentId, dataToUpdate).then(() => {
      setIsContentBeingSaved(false);
    });
    setInputData(reqDataCopy);
  };

  return (
    <div style={{ backgroundColor: "#F6F9FB", padding: "16px" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", flexGrow: 1 }}>
          <p
            style={{
              color: "#030852",
              fontWeight: "600",
              marginLeft: "8px",
              marginRight: "8px",
            }}
          >
            {t("doc.models.edit.sections.blocks.mandatoryData")}
          </p>
          <Switch
            checked={inputData?.mandatory}
            disabled={disableEdit}
            onChange={changeMandatoryField}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            style={{
              marginBottom: "8px",
            }}
            disabled={disableEdit}
            onClick={onShowModal}
          >
            {t("doc.models.edit.sections.blocks.alertUsers")}
          </Button>
          {inputData.alertedUsers?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "200px",
              }}
            >
              {inputData.alertedUsers?.map((user: any, index: number) => (
                <div
                  key={index}
                  style={{
                    width: "32px",
                    height: "32px",
                    marginBottom: "8px",
                    marginRight: "8px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                    }}
                    className={styles.closeIcon}
                  >
                    <CloseOutlined
                      style={{
                        color: "white",
                      }}
                    ></CloseOutlined>
                  </div>
                  <div
                    className={styles.avatar}
                    style={{
                      // marginRight: "8px",
                      position: "relative",
                      bottom: "32px",
                      left: "0px",
                      marginTop: "0px",
                    }}
                    onClick={() => onDeleteAlertedUser(user.id)}
                  >
                    <AvatarComponent
                      src=""
                      key={user.id}
                      firstName={user.first_name}
                      lastName={user.last_name}
                      style={{
                        padding: "0px",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          flexGrow: 0.5,
        }}
      >
        <Space direction="vertical">
          <Input
            placeholder="Name"
            style={{ width: "35vw" }}
            value={inputData.name}
            onChange={(e) => onChangeData(e, "name")}
            disabled={disableEdit}
            maxLength={255}
          />
          <TextArea
            placeholder="Instruction"
            style={{ marginBottom: "8px", width: "35vw" }}
            value={inputData.instruction}
            onChange={(e) => onChangeData(e, "instruction")}
            disabled={disableEdit}
            maxLength={2550}
          />
        </Space>
        <Row
          style={{
            marginBottom: "8px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={2}>Type</Col>
          <Col span={22}>
            <Radio.Group
              onChange={onChangeType}
              value={inputData.type}
              disabled={disableEdit}
            >
              <Radio value={"int"}>Int</Radio>
              <Radio value={"decimal"}>Decimal</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: "8px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={2}>Units</Col>
          <Col span={22}>
            <Select
              value={inputData.units}
              style={{ width: 220 }}
              onChange={handleChangeUnits}
              options={unitsOption}
              disabled={disableEdit}
            />
          </Col>
        </Row>
        <ConditionComponent
          contentId={contentId}
          disableEdit={disableEdit}
          updateContentInDb={updateContentInDb}
          inputData={inputData}
          setInputData={setInputData}
          setIsContentBeingSaved={setIsContentBeingSaved}
        />

        {isModalOpen && (
          <DefineAlertedUsers
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setInputData={setInputData}
            inputData={inputData}
            updateContentInDb={updateContentInDb}
            contentId={contentId}
          />
        )}
      </div>
    </div>
  );
}
