import { Col } from "antd";
import CompanyUsersTable from "components/CompanyUsersTable/CompanyUsersTable";
import {
  useAddTeamForm,
  useAddTeamFormDispatch,
} from "../Provider/AddTeamFormContext";

export default function AddTeamFormCompanyUsersTable() {
  const addTeamFormContext = useAddTeamForm();
  const dispatchAddTeamForm = useAddTeamFormDispatch();

  // Manage pagination change
  const onChangePagination = (changedPage: number, changedPageSize: number) => {
    if (changedPage !== addTeamFormContext.page) {
      dispatchAddTeamForm({
        type: "setPage",
        page: changedPage,
      });
    } else if (changedPageSize !== addTeamFormContext.pageSize) {
      dispatchAddTeamForm({
        type: "setPageSize",
        pageSize: changedPageSize,
      });
    }
  };

  // Open a single user's modal (Details)
  const previewUser = (id: number) => {
    dispatchAddTeamForm({
      type: "setViewOnly",
      viewOnly: true,
    });
    dispatchAddTeamForm({
      type: "setActiveUserId",
      activeUserId: id,
    });
    dispatchAddTeamForm({
      type: "setOpenCreateUserDrawer",
      openCreateUserDrawer: true,
    });
  };

  const selectedTableKeys = (selectedRowKeys: any) => {
    dispatchAddTeamForm({
      type: "setSelectedUsers",
      selectedUsers: selectedRowKeys,
    });
  };

  return (
    <Col span={24}>
      <CompanyUsersTable
        selectedDefaults={addTeamFormContext.selectedUsers}
        users={addTeamFormContext.tableDataList}
        isListView={true}
        previewUser={previewUser}
        loading={addTeamFormContext.tableDataLoading}
        onChangePagination={onChangePagination}
        setSelectedTableKeys={selectedTableKeys}
        paginationDetails={addTeamFormContext.tableDataListPagination}
        displayedColumns={[
          "avatar",
          "first_name",
          "middle_name",
          "last_name",
          "details",
        ]}
        showCheckBox={true}
      />
    </Col>
  );
}
