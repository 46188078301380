import { Button, Popover } from "antd";
import { AiOutlineQrcode } from "react-icons/ai";
import { QRCodeCanvas } from "qrcode.react";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import ValidationStatus from "components/ValidationTable/ValidationStatus";
import { isAlreadyPassed, isInLessThanADurationFromNow } from "util/duration";
import ListViewNoPagination from "components/ListView/ListViewNoPagination";
import { useTranslation } from "react-i18next";

export default function ObsoleteReleasesTable({
  procedures,
  loading,
}: {
  procedures: any;
  loading: boolean;
}) {
  const { t } = useTranslation();

  const columns = [
    {
      title: "QR",
      dataIndex: "document_baseline_id",
      key: "qrcode",
      render: (document_baseline_id: string) => (
        <>
          <Popover
            trigger="click"
            content={<QRCodeCanvas value={document_baseline_id} />}
          >
            <Button icon={<AiOutlineQrcode size={24} />}></Button>
          </Popover>
        </>
      ),
    },
    {
      title: "edit.name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => <p style={{ margin: "0" }}>{name}</p>,
    },
    {
      title: "menu.release",
      dataIndex: "version",
      key: "release",
      render: (version: string) => <p style={{ margin: "0" }}>{version}</p>,
    },
    {
      title: "doc.models.procedure.authors",
      dataIndex: "author",
      key: "Steps",
      render: (authorData: any) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <AvatarComponent
            src={authorData.avatar?.media_content_uid}
            style={{ border: "2px #262626 solid" }}
            firstName={authorData.first_name}
            lastName={authorData.last_name}
          />
        </div>
      ),
    },
    {
      title: "doc.models.procedure.publishedOn",
      dataIndex: "published_at",
      key: "publishedOnDate",
      render: (publishedOn: string) => (
        <p
          style={{
            display: "flex",
            justifyContent: "flex-start",
            color: "#030852",
            margin: "0",
            padding: "0",
            fontWeight: "400",
            textDecoration: "underline",
          }}
        >
          {new Intl.DateTimeFormat("en-GB").format(new Date(publishedOn))}
        </p>
      ),
    },
    {
      title: "doc.models.procedure.status",
      dataIndex: "status",
      key: "status",
      render: (status: "PENDING" | "VALIDATED" | "REFUSED") => {
        return <ValidationStatus status={status} statusDate="fd" />;
      },
    },
    {
      title: "doc.models.procedure.expirationDate",
      dataIndex: "expired_at",
      key: "publishedOnDate",
      render: (expiredAt: string | null) => (
        <>
          {expiredAt === null && (
            <p
              style={{
                margin: "0",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                textDecorationLine: "underline",
                color: "#030852",
              }}
            >
              {t("obsoleteDocsTable.isValid")}
            </p>
          )}
          {expiredAt !== null && (
            <>
              {isAlreadyPassed(expiredAt) ? (
                <p style={{ margin: "0" }}>{"Expired"}</p>
              ) : (
                <>
                  {isInLessThanADurationFromNow(expiredAt, 3, "weeks") ? (
                    <>
                      <p
                        style={{ margin: "0" }}
                      >{`Will expire soon: ${new Intl.DateTimeFormat(
                        "en-GB"
                      ).format(new Date(expiredAt))}`}</p>
                    </>
                  ) : (
                    <p style={{ margin: "0" }}>{`${new Intl.DateTimeFormat(
                      "en-GB"
                    ).format(new Date(expiredAt))}`}</p>
                  )}
                </>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <ListViewNoPagination
      data={procedures}
      cardData={procedures}
      columns={columns}
      loading={loading}
      loadingCardData={loading}
      isListView={true}
      cardLink={"/account/knowledge/models/edit"}
      page={0}
      pageSize={0}
      total={0}
      setSelectedTableKeys={() => {
        return;
      }}
      showCheckBox={false}
    />
  );
}
