import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Input, Space } from "antd";
import { ReactNode, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { updateDocCategory } from "../../app/stores/documentCategories/documentCategoriesClient";
import styles from "./style.module.css";

const { Panel } = Collapse;

const style = {
  lineHeight: "normal",
};

export default function DroppableContainer({
  accept,
  containerCategory,
  onDrop,
  onClickCollapse,
  children,
  containerStyle,
  headingStyle,
  isCollapse,
  showConfirm,
}: {
  accept: string;
  containerCategory: any;
  onDrop: any;
  onClickCollapse: any;
  children?: ReactNode;
  containerStyle: any;
  headingStyle: any;
  isCollapse?: boolean;
  showConfirm: (category: any) => void;
}) {
  const [disableName, setDisableName] = useState(true);
  const inputRef = useRef<any>(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  let backgroundColor = "white";
  if (isActive) {
    backgroundColor = "#e6f7ff";
  } else if (canDrop) {
    backgroundColor = "#f3f3f3";
  }

  const onClickEdit = () => {
    setDisableName(false);
    inputRef.current!.focus();
  };

  let timeoutId: any;
  const onChangeText = (event: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        category: event.target.value,
      };
      updateDocCategory(dataToUpdate, containerCategory?.id);
    }, 2000);
  };

  // eslint-disable-next-line
  const onBlurFunction = (e: any) => {
    setDisableName(true);
  };

  return (
    <div
      ref={drop}
      style={{ ...style, backgroundColor, ...containerStyle }}
      data-testid="dustbin"
      key={containerCategory?.id + "div"}
    >
      {isCollapse ? (
        <div className="planOutline">
          <Collapse
            // bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            ghost
            onChange={(data) =>
              onClickCollapse(data, accept, containerCategory.parent_id)
            }
            key={containerCategory?.id + "collapse"}
          >
            <Panel
              header={
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={styles.editHover}
                >
                  <Input
                    style={{ ...headingStyle }}
                    maxLength={255}
                    bordered={false}
                    defaultValue={containerCategory?.category_name}
                    disabled={disableName}
                    ref={inputRef}
                    onChange={onChangeText}
                    onBlur={onBlurFunction}
                    className={!disableName ? styles.textAreaSelected : ""}
                  />
                </div>
              }
              key={containerCategory?.id}
              extra={[
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={styles.editHover}
                >
                  <Space>
                    <AiOutlineEdit
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={onClickEdit}
                      key={containerCategory?.id + "edit"}
                    />
                    {(accept === "document" &&
                      containerCategory._count.document_model === 0) ||
                    (accept === "Subcategory" &&
                      containerCategory._count.sub_categories === 0) ? (
                      <AiOutlineDelete
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => showConfirm(containerCategory)}
                        key={containerCategory?.id + "delete"}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Space>
                </div>,
              ]}
            >
              <div>{children}</div>
            </Panel>
            {isActive ? (
              <div
                style={{
                  border: "1px dotted #e3e3e3",
                  height: "30px",
                  backgroundColor: "white",
                  marginTop: "1rem",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                }}
                key="ghostSubCat"
              ></div>
            ) : (
              <div></div>
            )}
          </Collapse>
        </div>
      ) : (
        <div>
          <div style={{ ...headingStyle }}>
            {containerCategory?.doc_category}
          </div>
          <div>{children}</div>
          {isActive ? (
            <div
              style={{
                border: "1px dotted #e3e3e3",
                height: "30px",
                backgroundColor: "white",
                marginTop: "1rem",
              }}
              key="ghostCat"
            ></div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
}
