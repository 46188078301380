import { useReducer } from "react";
import { obsoleteReleasesReducer } from "./ObsoleteReleasesReducer";
import {
  ObsoleteReleasesContext,
  ObsoleteReleasesDispatchContext,
} from "./ObsoleteReleasesContext";

export default function ObsoleteReleasesProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [obsoleteReleases, dispatchObsoleteReleases] = useReducer(
    obsoleteReleasesReducer,
    {
      statusBaselineValidations: "loading",
      errorBaselineValidations: null,
      baselineValidations: [],
      paginationBaselineValidations: { page: 1, pageSize: 10, total: -1 },
      queryNameTextFilter: "",
      qureyStartDate: "",
      queryEndDate: "",
      statusUsers: "loading",
      errorUsers: null,
      users: [],
      authors: [],
      validators: [],
    }
  );

  return (
    <ObsoleteReleasesContext.Provider value={obsoleteReleases}>
      <ObsoleteReleasesDispatchContext.Provider
        value={dispatchObsoleteReleases}
      >
        {children}
      </ObsoleteReleasesDispatchContext.Provider>
    </ObsoleteReleasesContext.Provider>
  );
}
