import { Space } from "antd";
import { IDocumentBaselineExecution } from "app/stores/executionFlux/types";
import translateDocumentType from "util/translateDocumentType";
import { useTranslation } from "react-i18next";

interface TreeDisplayCardProps {
  data: IDocumentBaselineExecution;
}

const TreeDisplayCardOrganisation = ({ data }: TreeDisplayCardProps) => {
  const { t } = useTranslation();
  const { document_type, baseline_name } = data;

  return (
    <Space direction="horizontal" style={{ width: "100%" }} size="large">
      {translateDocumentType(document_type, t)}
      {baseline_name}
    </Space>
  );
};

export default TreeDisplayCardOrganisation;
