import { Button, Modal, Empty, Spin } from "antd";
import { useState, useEffect } from "react";
import { fetchBaselineExecutionById } from "../../app/stores/executionFlux/executionFluxClients";
import { fetchDocumentsBaselineByID } from "../../app/stores/documentsBaseline/documentsBaselineClient";
import { Parser } from "json2csv";
import DropdownMultiSelection from "./DropdownMultiSelection";
import {
  fetchExecutionsOfDocumentAsCsv,
  fetchDocumentVersions,
} from "../../app/stores/documents/documentsClient";

import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import WebDataRocksComponent from "../WebDataRocks/WebDataRocksComponent";
import type { MenuProps } from "antd";
import { Dropdown, Space, Popover } from "antd";
import { DownOutlined, WarningOutlined } from "@ant-design/icons";

interface CsvExecutionOfDocumentProps {
  isModalVisible: boolean;
  handleCancel: () => void;
  documentId: any;
}

const CsvExecutionOfDocument = ({
  isModalVisible,
  handleCancel,
  documentId,
}: CsvExecutionOfDocumentProps) => {
  const { t } = useTranslation();
  const [csvData, setCsvData] = useState<any>("");
  // const [fields, setFields] = useState<string[]>([]);
  const [allModelVersions, setAllModelVersions] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState<any>([]);
  const [selectedCsvData, setSelectedCsvData] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  const csvFieldList = [
    "execution_id",
    "organisation_serial_number",
    "baseline_name",
    "baseline_version",
    "parent_execution_id",
    "parent_execution_name",
    "operators",
    "date",
    "time_spend",
    "localisation",
  ];

  const translateObjectData = (
    obj: Record<string, any>
  ): Record<string, any> => {
    const translatedObject: Record<string, any> = {};

    Object.keys(obj).forEach((key) => {
      if (csvFieldList.includes(key)) {
        const translatedKey = t("csvdata." + key);
        translatedObject[translatedKey] = obj[key];
      } else {
        translatedObject[key] = obj[key];
      }
    });

    return translatedObject;
  };

  useEffect(() => {
    setIsLoading(true);
    fetchBaselineExecutionById(documentId).then((execution: any) => {
      fetchDocumentsBaselineByID(execution.data.document_baseline_id).then(
        (documentBaseline: any) => {
          fetchDocumentVersions(
            documentBaseline.data.document_model_id,
            1,
            100
          ).then((documentVersions: any) => {
            let allModelVersions: any = [];
            for (
              let i = 0;
              i < documentVersions.data.documentModelsVersions.length;
              i++
            ) {
              allModelVersions.push({
                label: documentVersions.data.documentModelsVersions[i].version,
                value: documentVersions.data.documentModelsVersions[i].version,
                id: documentVersions.data.documentModelsVersions[i].id,
              });
            }

            setAllModelVersions(allModelVersions.reverse());
          });
          fetchExecutionsOfDocumentAsCsv(
            documentBaseline.data.document_model_id,
            [execution.data.document_baseline_id],
            execution.data.id
          ).then((data: any) => {
            const resultsJSON = data.data.json;
            setCsvData(data.data.json);
            // setFields(Object.keys(results.data[0]));
            const defaultSelectedItems: any[] = [
              {
                label: documentBaseline.data.version,
                value: documentBaseline.data.version,
                id: documentBaseline.id,
              },
            ];
            setSelectedItems(defaultSelectedItems);
            const selectedData: any = [];
            defaultSelectedItems.forEach((selectedVersion: any) => {
              const selectedDataLocal = resultsJSON.map((row: any) => {
                if (
                  row.baseline_version.replaceAll('"', "") ===
                  selectedVersion.value
                ) {
                  return row;
                }
              });
              selectedData.push(...selectedDataLocal);
            });

            const translatedSelectedData = selectedData.map((elem: any) => {
              if (elem !== undefined) return translateObjectData(elem);
            });

            setSelectedVersions(
              translatedSelectedData.filter(function (element: any) {
                return element !== undefined;
              })
            );
            const parser = new Parser();
            const parsedCsvData = parser.parse(
              selectedData.filter(function (element: any) {
                return element !== undefined;
              })
            );
            setSelectedCsvData(parsedCsvData);
            setIsLoading(false);
          });
        }
      );
    });
  }, [documentId]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          style={{
            width: "100%",
          }}
          onClick={() => {
            downloadCSVFile();
          }}
        >
          {t("operate.downloadCsv")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            downloadXLSXFile();
          }}
        >
          {t("operate.downloadXlsx")}
        </div>
      ),
    },
  ];

  const downloadXLSXFile = () => {
    const worksheet = XLSX.utils.json_to_sheet(selectedVersions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, csvData[0].baseline_name + "_execution.xlsx");
  };

  const downloadCSVFile = () => {
    const url = window.URL.createObjectURL(new Blob([selectedCsvData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", csvData[0].baseline_name + "_execution.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const onSelectVersion = async (selectedArray: any[]) => {
    setIsLoading(true);
    setTimeout(() => {
      setSelectedItems(selectedArray);
      const selectedData: any = [];
      selectedArray.forEach((selectedVersion: any) => {
        selectedData.push(
          ...csvData.filter(
            (row: any) =>
              row.baseline_version.replaceAll('"', "") ===
              selectedVersion.replaceAll('"', "")
          )
        );
      });
      setSelectedVersions(selectedData);
      if (selectedData.length > 0) {
        const parser = new Parser();
        const parsedCsvData = parser.parse(selectedData);
        setSelectedCsvData(parsedCsvData);
        setIsLoading(false);
      } else {
        setSelectedCsvData("");
        setIsLoading(false);
      }
    }, 500);
  };

  const onSelectAll = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setSelectedItems(allModelVersions);
      setSelectedVersions(csvData);
      const parser = new Parser();
      const parsedCsvData = parser.parse(csvData);
      setSelectedCsvData(parsedCsvData);
      setSelectedCsvData(selectedCsvData);
      setIsLoading(false);
    }, 500);
  };

  const onClear = () => {
    setSelectedItems([]);
    setSelectedVersions([]);
    setSelectedCsvData("");
  };

  return (
    <div>
      <Modal
        title={t("doc.models.edit.tabPanel.preview")}
        open={isModalVisible}
        onCancel={handleCancel}
        width={"95vw"}
        style={{ top: 15 }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("operate.cancel")}
          </Button>,
        ]}
      >
        <div
          style={{
            height: "75vh",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            {t("operate.selectVersion")}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <Popover
                placement="topRight"
                title={
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    <WarningOutlined
                      style={{
                        fontSize: "20px",
                      }}
                    />
                    {t("csvExecution.resetWarning")}
                  </div>
                }
                open={isPopUpVisible}
              ></Popover>
              <DropdownMultiSelection
                allItems={allModelVersions}
                selectedItems={selectedItems}
                onSelectItem={onSelectVersion}
                onSelectAll={onSelectAll}
                onClear={onClear}
                styleDropdown={{ width: "50vh" }}
                setIsPopUpVisible={setIsPopUpVisible}
                placeholder={t("dropdownMultiSelection.selectVersion")}
              />
            </div>
            <div
              style={{
                marginTop: "3px",
              }}
            >
              <Dropdown menu={{ items }}>
                <Button type="primary">
                  <Space>
                    {t("csvExecution.rawData")}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "calc(90vh - 300px)",
              overflowY: "scroll",
              overflowX: "scroll",

              whiteSpace: "nowrap",
            }}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div>
                {selectedVersions.length > 0 ? (
                  <WebDataRocksComponent
                    csvJSONData={selectedVersions}
                    rawCsv={csvData}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Empty />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CsvExecutionOfDocument;
