import { ReactElement, useState } from "react";
import styles from "./InputCheckboxBlock.module.scss";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

interface IInputCheckBox {
  title: string;
  userInput: any;
  alertOccured: boolean;
}

export default function InputTextBlock({
  title,
  userInput,
  alertOccured,
}: IInputCheckBox): ReactElement {
  const { t } = useTranslation();

  const [requestDataHistory, setRequestDataHistory] = useState("");

  return (
    <div
      style={{
        border: alertOccured ? "3px red solid" : "",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <h3
          style={{
            flexGrow: 1,
          }}
        >
          {t("operate.textSent")}
        </h3>
        {userInput.relatedAlerts && userInput.relatedAlerts.length > 1 && (
          <div
            style={{
              marginRight: "10px",
            }}
          >
            <Select
              defaultValue={
                userInput.relatedAlerts[0].targets[0].data.requested_data
              }
              onChange={(value: any) => {
                setRequestDataHistory(value);
              }}
              style={{ width: 120 }}
            >
              {userInput.relatedAlerts.map((alert: any) => (
                <Select.Option value={alert.targets[0].data.requested_data}>
                  {alert.targets[0].data.requested_data}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </div>
      <h4>{title}</h4>

      <div className={styles.toggled}>
        <div className={styles.options}>
          <p>
            {requestDataHistory ? requestDataHistory : userInput.requested_data}
          </p>
        </div>
      </div>
    </div>
  );
}
