import Request, {
  // SortFormatType,
  filterFormat,
  // optionFormat,
  pageFormat,
  pageSizeFormat,
  // queryClient,
  // sortFormat,
} from "../../../libs/requests";
import { PaginationType } from "../pagination/types";

import {
  ApiMetabaseDashboard,
  // ApiGraphDisplayType,
  // ApiGraphPrivacy,
  ApiFetchMetabaseDashboardsFilters,
} from "./types";

export const fetchMetabaseDashboards = async (
  filters: ApiFetchMetabaseDashboardsFilters = {},
  page: number,
  pageSize: number
) => {
  return Request()
    .get("/metabase/", {
      params: {
        ...filterFormat(filters),
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
      },
    })
    .then((response) => {
      return {
        metabase: response.data.data.metabase as ApiMetabaseDashboard[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const updateMetabaseDashboard = (dashboardId: string, values: any) => {
  return Request()
    .patch(`/metabase/${dashboardId}`, {
      ...values,
    })
    .then((response) => response.data);
};

export const createMetabaseDashboard = (values: any) => {
  return Request()
    .post(`/metabase/`, {
      ...values,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteMetabaseDashboard = (dashboardId: string) => {
  return Request()
    .delete(`/metabase/${dashboardId}`)
    .then((response) => response.data);
};
