import {
  SizeType,
  Icon,
  Dialog,
  ButtonType,
  Button,
} from "@armtekfrance/armtek-react-design-system";
import { AuthAction, webGate } from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";
import { getLoggedInUser } from "app/stores/auth/authClient";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import { DropDownNew } from "components/DropDown/DropDownNew";
import MyUserDropDown from "components/MyUser/MyUserDropDown";
import { removeCookies } from "libs/cookies";
import { queryClient } from "libs/requests";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { tap } from "rxjs";
import styles from "./AvatarComponentOfLoggedUser.module.scss";

export default function AvatarComponentOfLoggedUser({
  dataCy = "AvatarComponentOfLoggedUser",
}: {
  dataCy?: string;
}) {
  const { t } = useTranslation();

  {
    const userDetails = getLoggedInUser();

    const [confirmLogoutOpen, setConfirmLogoutOpen] = useState<boolean>(false);

    useEffect(() => {
      const subscription = Manager.on(AuthAction.ON_LOGOUT)
        .pipe(
          tap(() => {
            // Reset all Tanstack cashed data
            queryClient.clear();
          })
        )
        .subscribe();
      return () => subscription.unsubscribe(); // Nettoyer l’abonnement
    }, []);

    return (
      <div
        className={styles.avatarComponentOfLoggedUserWrapper}
        data-cy={dataCy}
      >
        <Dialog
          dataCy="Dialog_Logout"
          noHeader
          size={SizeType.small}
          child={
            <div className={styles.modalContent}>
              <Icon iconData="WarningOutlined" type={ButtonType.warning} />
              <p className={styles.logoutText}>{t("button.logout")}</p>
            </div>
          }
          open={confirmLogoutOpen}
          onHide={(e) => {
            e.stopImmediatePropagation();
            if (confirmLogoutOpen) setConfirmLogoutOpen(false);
          }}
          onSlRequestClose={() => {
            setConfirmLogoutOpen(false);
          }}
          footer={
            <div className={styles.rowFlex}>
              <Button
                type={ButtonType.default}
                child={t("button.cancel")}
                onClick={() => setConfirmLogoutOpen(false)}
                size={SizeType.large}
                dataCy={dataCy + "_Button_CancelLogout"}
              />
              <Button
                type={ButtonType.primary}
                child={"OK"}
                onClick={() => {
                  removeCookies("accessToken");
                  removeCookies("refreshToken");
                  webGate.authManager.logout();
                }}
                size={SizeType.large}
                dataCy={dataCy + "_Button_OkLogout"}
              />
            </div>
          }
        />
        <DropDownNew
          placement="bottomRight"
          dropdownContent={
            <MyUserDropDown onClickLogout={() => setConfirmLogoutOpen(true)} />
          }
        >
          {userDetails && (
            <div data-cy="Header-Avatar">
              <AvatarComponent
                src={userDetails?.avatar?.url}
                size={27}
                firstName={userDetails?.first_name}
                lastName={userDetails?.last_name}
                tooltipPlacement={"bottomRight"}
              />
            </div>
          )}
        </DropDownNew>
      </div>
    );
  }
}
