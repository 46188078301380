import ObsoleteReleasesProvider from "./Provider/ObsoleteReleasesProvider";
import ObsoleteReleases from "./ObsoleteReleases";

export default function ObsoleteReleasesRoot() {
  return (
    <ObsoleteReleasesProvider>
      <ObsoleteReleases />
    </ObsoleteReleasesProvider>
  );
}
