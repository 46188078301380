import { GlobalStore } from "@armtekfrance/webgate/dist";
import styles from "./MediaSelectorVideo.module.scss";
import { MediaContent } from "Stores/media-models";
import { fetchBlobObjectTanstack } from "app/stores/content/contentClient";
import MediaImageItemWrapper from "components/MediaPicker/MediaImageItemWrapper";

export default function MediaSelectorVideo({
  videoItem,
  dropDownComponent,
  convertToBase64 = false,
}: {
  videoItem: MediaContent;
  dropDownComponent: JSX.Element;
  convertToBase64?: boolean;
}) {
  const thumbnailImage = fetchBlobObjectTanstack(
    videoItem.media_content_uid,
    true,
    true,
    1000 * 60 * 60 * 24 * 7
  );
  return (
    <div className={styles.mediaSelectorVideoWrapper}>
      {/* // TODO Refactor image and video to use the same component? */}
      <MediaImageItemWrapper
        mediaName={videoItem.name}
        showMediaName={false}
        imageDataUri={`data:${thumbnailImage.blobObject?.media.ContentType};${
          convertToBase64 ? "base64," : ""
        } ${thumbnailImage.blobObject?.media.file}`}
        alt={videoItem.name}
        showCheckbox={false}
        checked={false}
        typeOfMedia="video"
        enablePreview={true}
        onCheckedChange={() => {
          // TODO: reduce to avoid that
          return;
        }}
        onPreviewClickOpening={() => {
          GlobalStore.media.setFullScreenMediaPreview({
            media_id: videoItem.id.toString(),
            block_type: "video",
            localDataUri: null,
          });
        }}
        status={thumbnailImage.status}
        error={thumbnailImage.error}
      />
      {dropDownComponent}
    </div>
  );
}
