import DropDownItemUser from "components/DropDown/DropDownItemUser";
import DropDownList from "components/DropDown/DropDownList";
import { DropDownNew } from "components/DropDown/DropDownNew";
import DropDownSelectOrUnselectAll from "components/DropDown/DropDownSelectOrUnselectAll";

import { useTranslation } from "react-i18next";
import {
  useObsoleteReleases,
  useObsoleteReleasesDispatch,
} from "../../Provider/ObsoleteReleasesContext";
import { DropdownButton } from "@armtekfrance/armtek-react-design-system";

export default function ObsoleteReleasesHeroDropDownAuthor() {
  const { t } = useTranslation();

  const obsoleteReleases = useObsoleteReleases();
  const dispatchObsoleteReleases = useObsoleteReleasesDispatch();

  return (
    <DropDownNew
      dropdownContent={
        <>
          <DropDownSelectOrUnselectAll
            onSelectAll={() => {
              dispatchObsoleteReleases({ type: "selectAllAuthors" });
            }}
            onClearAll={() => {
              dispatchObsoleteReleases({ type: "clearAllAuthors" });
            }}
          />
          <DropDownList
            selectType="checkbox"
            onClickItem={(item) => {
              dispatchObsoleteReleases({
                type: "toogleSelectAuthorById",
                authorId: parseInt(item.key) ?? 0,
              });
            }}
            listItems={obsoleteReleases.authors.map((author) => {
              return {
                key: `${author.id}`,
                label: (
                  <DropDownItemUser
                    first_name={author.first_name}
                    last_name={author.last_name}
                    avatar={author.avatar}
                  />
                ),
                selected: author.selected,
              };
            })}
          />
        </>
      }
    >
      <DropdownButton name={t("validationTable.author")} />
    </DropDownNew>
  );
}
