import styles from "./MediaPickerUploadArticle.module.scss";
import { uploadMediaContentTanstack } from "app/stores/content/contentClient";
import MediaPickerUploadMediasGrid from "./MediaPickerUploadMediasGrid";
import MediaPickerUploadFooter from "./MediaPickerUploadFooter";
import UploadFilesHero from "./UploadFilesHero";
import { GlobalStore, useStoreState } from "@armtekfrance/webgate/dist";

export default function MediaPickerUploadArticle() {
  const mutation = uploadMediaContentTanstack();
  useStoreState(GlobalStore.media.getMediaReadyToUpload());
  useStoreState(GlobalStore.media.getMediaPickerModalStatus());

  return (
    <div className={styles.mediaPickerUploadArticleWrapper}>
      <UploadFilesHero
        uploadInProgress={mutation.isLoading}
        onMediasLoaded={(medias) => {
          GlobalStore.media.setMediaReadyToUpload([
            ...GlobalStore.media.getMediaReadyToUpload(),
            ...medias,
          ]);
        }}
        mediaType={GlobalStore.media.getMediaPickerModalStatus().block_type}
      />
      <MediaPickerUploadMediasGrid
        uploadInProgress={mutation.isLoading}
        medias={GlobalStore.media.getMediaReadyToUpload()}
      />
      <MediaPickerUploadFooter
        uploadInProgress={mutation.isLoading}
        uploadVisible={
          GlobalStore.media
            .getMediaReadyToUpload()
            .filter((media) => {
              return (
                media.block_type ===
                GlobalStore.media.getMediaPickerModalStatus().block_type
              );
            })
            .filter((media) => media.selected).length !== 0
        }
        onUpload={() => {
          mutation.mutate({
            uploadFileList: GlobalStore.media
              .getMediaReadyToUpload()
              .filter((media) => {
                return (
                  media.block_type ===
                  GlobalStore.media.getMediaPickerModalStatus().block_type
                );
              })
              .filter((media) => media.selected)
              .map((file) => file.file),
          });
        }}
      />
    </div>
  );
}
