import { ImageFormat, ExportFormat, Configuration, Tool } from "photoeditorsdk";

export default {
  container: "#editor",
  license:
    '{"api_token":"oIHVQyQ_X66sMgJBtjW1P0StJWBbzosTZB5GhKhj7iAllepf5sidFtc4uxrVU2Iv","app_identifiers":["armtekapp.com","staging.armtek.fr","localhost","poc-efficiens.intradef.gouv.fr","fabtek.armtek.fr","infratek.armtek.fr","dev.armtek.fr","alpha.armtek.fr","beta.armtek.fr","prod.armtek.fr","env-prod-web-dev.azurewebsites.net","env-prod-web-alpha.azurewebsites.net","env-prod-web-staging.azurewebsites.net","env-prod-web-beta.azurewebsites.net","env-prod-web.azurewebsites.net"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","brush","sticker","text","transform"],"issued_at":1698948403,"minimum_sdk_version":"1.0","owner":"ARMTEK","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"IvNCT6MZzrGorm3SvfVAjrDAs4nZ0wQqqQKp9vn6FjLlzv6Exg/hJkmAY0BvBAG7Lucbq3x+VmKoBWDphydYN7unSRW37/93rEAwQe/fDTaKhAxyn5n1/bJjqP9KBBIt9XGGV3zny6f2FztFLge4VLctQ0EFI1Z98fwcfGjqas9NU8FS70WZkUgl24t3COsptAdie0SN/Ao0+CuRziuzXXEO6Md7WMZuleJPDvAAK24KGBneK0ZfaIVBzIq87U/6pXnyGZP4UDxZcuCmkH8+gvBCEqJaAy7+begL0iRtqQA8fmyanskQX8mRrYYgvbN2tUcK86yDRD6w6N48M9jsgPp1BIwaLkB9cYXUCk/VvMSJVS/wgwLdRfsoZq7DXZKOSips94CDC0U7Z7uaiyFODEyCfiaD+1qKXrv7DgBvv27ZnLc+pdTZanOf6RDw9TZ7KuGzq1qF0/kdZvlLGpq0LKKXG7FVIsrmSu6tK72P7gvCkZszOpZPdAfpDiC4sI6uqzyou61AWtHbqJaqEm1lR6R+lnn94DdAFYf2mpHbdFIx3hPbsdvRPzk6LUR1JKzA2ozLTGB0l3mISWYsHcV3LDE4qGdxrc7+yMFKlvm5KoUvdEPB4ZuSQdt4hu7yDfn3H75pzHa4oWuyapqro+KnYfksddxQ3Ogu8KPCjdJPKCM="}',
  theme: "dark",
  layout: "basic",
  assetBaseUrl: "/assets",
  enableZoom: true,
  scaleImageToFit: false,
  displaySerializationModal: false,
  restrictZoomOutToDefault: true,
  displayResizeWarning: false,
  displayCloseWarning: true,
  displayDiscardChangesWarning: true,
  displayToolControlBarTitle: true,
  tools: [Tool.TRANSFORM, Tool.STICKER, Tool.TEXT],
  export: {
    filename: "photoeditorsdk-export",
    image: {
      exportType: ExportFormat.DATA_URL,
      format: ImageFormat.PNG,
      quality: 1,
      enableDownload: false,
      transparent: true,
    },
  },
  transform: {
    enableRotation: true,
    enableFlip: true,
    flattenCategories: true,
  },
  text: {
    fonts: [
      {
        identifier: "google-roboto",
        fontFamily: "Roboto",
        fontWeight: 400,
        provider: "google",
        textMetrics: {
          unitsPerEm: 2048,
          ascender: 1900,
          descender: -500,
        },
      },
    ],
  },
  sticker: {
    enableCustomUpload: false,
    categories: [
      // ARROW STICKERS
      {
        identifier: "imgly_sticker_arrow",
        thumbnailURI: "shapes/Arrow/Small_arrow.png",
        name: "Arrow",
        items: [
          {
            identifier: "Small_arrow",
            name: "Small_arrow",
            thumbnailURI: "shapes/Arrow/Small_arrow.png",
            stickerURI: "shapes/Arrow/Small_arrow.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_arrow",
            name: "Medium_arrow",
            thumbnailURI: "shapes/Arrow/Medium_arrow.png",
            stickerURI: "shapes/Arrow/Medium_arrow.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_arrow",
            name: "Semi-large_arrow",
            thumbnailURI: "shapes/Arrow/Semi-large_arrow.png",
            stickerURI: "shapes/Arrow/Semi-large_arrow.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_arrow",
            name: "Large_arrow",
            thumbnailURI: "shapes/Arrow/Large_arrow.png",
            stickerURI: "shapes/Arrow/Large_arrow.png",
            tintMode: "colorized",
          },
        ],
      },
      // CIRCLE STICKERS
      {
        identifier: "imgly_sticker_circle",
        thumbnailURI: "shapes/Circle/Small_circle.png",
        name: "Cricle",
        items: [
          {
            identifier: "Small_circle",
            name: "Small_circle",
            thumbnailURI: "shapes/Circle/Small_circle.png",
            stickerURI: "shapes/Circle/Small_circle.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_circle",
            name: "Medium_circle",
            thumbnailURI: "shapes/Circle/Medium_circle.png",
            stickerURI: "shapes/Circle/Medium_circle.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_circle",
            name: "Semi-large_circle",
            thumbnailURI: "shapes/Circle/Semi-large_circle.png",
            stickerURI: "shapes/Circle/Semi-large_circle.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_circle",
            name: "Large_circle",
            thumbnailURI: "shapes/Circle/Large_circle.png",
            stickerURI: "shapes/Circle/Large_circle.png",
            tintMode: "colorized",
          },
        ],
      },
      // SQUARE STICKERS
      {
        identifier: "imgly_sticker_square",
        thumbnailURI: "shapes/Square/Small_square.png",
        name: "Square",
        items: [
          {
            identifier: "Small_square",
            name: "Small_square",
            thumbnailURI: "shapes/Square/Small_square.png",
            stickerURI: "shapes/Square/Small_square.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_square",
            name: "Medium_square",
            thumbnailURI: "shapes/Square/Medium_square.png",
            stickerURI: "shapes/Square/Medium_square.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_square",
            name: "Semi-large_square",
            thumbnailURI: "shapes/Square/Semi-large_square.png",
            stickerURI: "shapes/Square/Semi-large_square.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_square",
            name: "Large_square",
            thumbnailURI: "shapes/Square/Large_square.png",
            stickerURI: "shapes/Square/Large_square.png",
            tintMode: "colorized",
          },
        ],
      },
      // NUMBER STICKERS
      // Small
      {
        identifier: "Small_numbers",
        name: "Small_numbers",
        thumbnailURI: "numbers/Small/Small_number_01.png",
        items: [
          {
            identifier: "Small_01",
            name: "Small_01",
            thumbnailURI: "numbers/Small/Small_number_01.png",
            stickerURI: "numbers/Small/Small_number_01.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_02",
            name: "Small_02",
            thumbnailURI: "numbers/Small/Small_number_02.png",
            stickerURI: "numbers/Small/Small_number_02.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_03",
            name: "Small_03",
            thumbnailURI: "numbers/Small/Small_number_03.png",
            stickerURI: "numbers/Small/Small_number_03.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_04",
            name: "Small_04",
            thumbnailURI: "numbers/Small/Small_number_04.png",
            stickerURI: "numbers/Small/Small_number_04.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_05",
            name: "Small_05",
            thumbnailURI: "numbers/Small/Small_number_05.png",
            stickerURI: "numbers/Small/Small_number_05.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_06",
            name: "Small_06",
            thumbnailURI: "numbers/Small/Small_number_06.png",
            stickerURI: "numbers/Small/Small_number_06.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_07",
            name: "Small_07",
            thumbnailURI: "numbers/Small/Small_number_07.png",
            stickerURI: "numbers/Small/Small_number_07.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_08",
            name: "Small_08",
            thumbnailURI: "numbers/Small/Small_number_08.png",
            stickerURI: "numbers/Small/Small_number_08.png",
            tintMode: "colorized",
          },
          {
            identifier: "Small_09",
            name: "Small_09",
            thumbnailURI: "numbers/Small/Small_number_09.png",
            stickerURI: "numbers/Small/Small_number_09.png",
            tintMode: "colorized",
          },
        ],
      },
      // Medium
      {
        identifier: "Medium_numbers",
        thumbnailURI: "numbers/Medium/Medium_number_01.png",
        name: "Medium_numbers",
        items: [
          {
            identifier: "Medium_01",
            name: "Medium_01",
            thumbnailURI: "numbers/Medium/Medium_number_01.png",
            stickerURI: "numbers/Medium/Medium_number_01.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_02",
            name: "Medium_02",
            thumbnailURI: "numbers/Medium/Medium_number_02.png",
            stickerURI: "numbers/Medium/Medium_number_02.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_03",
            name: "Medium_03",
            thumbnailURI: "numbers/Medium/Medium_number_03.png",
            stickerURI: "numbers/Medium/Medium_number_03.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_04",
            name: "Medium_04",
            thumbnailURI: "numbers/Medium/Medium_number_04.png",
            stickerURI: "numbers/Medium/Medium_number_04.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_05",
            name: "Medium_05",
            thumbnailURI: "numbers/Medium/Medium_number_05.png",
            stickerURI: "numbers/Medium/Medium_number_05.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_06",
            name: "Medium_06",
            thumbnailURI: "numbers/Medium/Medium_number_06.png",
            stickerURI: "numbers/Medium/Medium_number_06.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_07",
            name: "Medium_07",
            thumbnailURI: "numbers/Medium/Medium_number_07.png",
            stickerURI: "numbers/Medium/Medium_number_07.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_08",
            name: "Medium_08",
            thumbnailURI: "numbers/Medium/Medium_number_08.png",
            stickerURI: "numbers/Medium/Medium_number_08.png",
            tintMode: "colorized",
          },
          {
            identifier: "Medium_09",
            name: "Medium_09",
            thumbnailURI: "numbers/Medium/Medium_number_09.png",
            stickerURI: "numbers/Medium/Medium_number_09.png",
            tintMode: "colorized",
          },
        ],
      },
      // Semi-large
      {
        identifier: "Semi-large_numbers",
        thumbnailURI: "numbers/Semi-large/Semi-large_number_01.png",
        name: "Semi-large_numbers",
        items: [
          {
            identifier: "Semi-large_01",
            name: "Semi-large_01",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_01.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_01.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_02",
            name: "Semi-large_02",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_02.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_02.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_03",
            name: "Semi-large_03",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_03.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_03.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_04",
            name: "Semi-large_04",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_04.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_04.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_05",
            name: "Semi-large_05",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_05.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_05.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_06",
            name: "Semi-large_06",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_06.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_06.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_07",
            name: "Semi-large_07",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_07.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_07.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_08",
            name: "Semi-large_08",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_08.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_08.png",
            tintMode: "colorized",
          },
          {
            identifier: "Semi-large_09",
            name: "Semi-large_09",
            thumbnailURI: "numbers/Semi-large/Semi-large_number_09.png",
            stickerURI: "numbers/Semi-large/Semi-large_number_09.png",
            tintMode: "colorized",
          },
        ],
      },
      // Large
      {
        identifier: "large_numbers",
        thumbnailURI: "numbers/Large/Large_number_01.png",
        name: "large_numbers",
        items: [
          {
            identifier: "Large_01",
            name: "Large_01",
            thumbnailURI: "numbers/Large/Large_number_01.png",
            stickerURI: "numbers/Large/Large_number_01.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_02",
            name: "Large_02",
            thumbnailURI: "numbers/Large/Large_number_02.png",
            stickerURI: "numbers/Large/Large_number_02.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_03",
            name: "Large_03",
            thumbnailURI: "numbers/Large/Large_number_03.png",
            stickerURI: "numbers/Large/Large_number_03.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_04",
            name: "Large_04",
            thumbnailURI: "numbers/Large/Large_number_04.png",
            stickerURI: "numbers/Large/Large_number_04.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_05",
            name: "Large_05",
            thumbnailURI: "numbers/Large/Large_number_05.png",
            stickerURI: "numbers/Large/Large_number_05.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_06",
            name: "Large_06",
            thumbnailURI: "numbers/Large/Large_number_06.png",
            stickerURI: "numbers/Large/Large_number_06.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_07",
            name: "Large_07",
            thumbnailURI: "numbers/Large/Large_number_07.png",
            stickerURI: "numbers/Large/Large_number_07.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_08",
            name: "Large_08",
            thumbnailURI: "numbers/Large/Large_number_08.png",
            stickerURI: "numbers/Large/Large_number_08.png",
            tintMode: "colorized",
          },
          {
            identifier: "Large_09",
            name: "Large_09",
            thumbnailURI: "numbers/Large/Large_number_09.png",
            stickerURI: "numbers/Large/Large_number_09.png",
            tintMode: "colorized",
          },
        ],
      },
    ],
  },
} as Omit<Configuration, "image">;
