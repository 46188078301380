import { createContext, Dispatch, useContext } from "react";
import {
  stateValidationStatusProvider,
  TypeValidationStatusContextReducerAction,
} from "./ValidationStatusReducer";

export const ValidationStatusContext =
  createContext<stateValidationStatusProvider>({
    statusBaselineValidations: "loading",
    statusUsers: "loading",
    errorBaselineValidations: null,
    errorUsers: null,
    baselineValidations: [],
    queryBaselineValidationsStatus: [],
    queryNameTextFilter: "",
    users: [],
    authors: [],
    validators: [],
    paginationBaselineValidations: { page: 1, pageSize: 10, total: -1 },
  });

export const ValidationStatusDispatchContext = createContext<
  Dispatch<TypeValidationStatusContextReducerAction>
>(() => {
  return { type: "" };
});

export function useValidationStatus() {
  return useContext(ValidationStatusContext);
}

export function useValidationStatusDispatch() {
  return useContext(ValidationStatusDispatchContext);
}
