import { Button, Col, Form, Input, message, Modal, Row, Space } from "antd";
import { createCompanyDepartment } from "app/services/company";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function AddDeptModel({
  isAddDeptOpen,
  handleAddDeptCancel,
  handleAddDeptSave,
}: {
  isAddDeptOpen: boolean;
  handleAddDeptCancel: () => void;
  handleAddDeptSave: () => void;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSavingDept, setIsSavingDept] = useState<boolean>(false);

  // On form submit create department
  const onFinish = async (values: any) => {
    let response;
    try {
      setIsSavingDept(true);
      response = await createCompanyDepartment(values);
      if (response.success) {
        handleAddDeptSave();
        form.resetFields();
        message.open({
          type: "success",
          duration: 5,
          content:
            t("departments.hasBeen") +
            " " +
            t(`departments.created`) +
            " " +
            t("departments.successfully"),
        });
      }
      setIsSavingDept(false);
    } catch (error) {
      console.error(error);
      setIsSavingDept(false);
      message.open({
        type: "error",
        duration: 5,
        content: t("departments.couldNot") + " " + t(`departments.created`),
      });
    }
  };

  return (
    <Modal
      open={isAddDeptOpen}
      onCancel={handleAddDeptCancel}
      title={t("departments.add")}
      footer={null}
    >
      <Form
        name="companyDepartmentForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        requiredMark
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: t("departments.nameInput"),
                },
              ]}
            >
              <Input placeholder={t("departments.nameInput")} maxLength={250} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: t("departments.descriptionInput"),
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                maxLength={250}
                placeholder={t("departments.descriptionInput")}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="d-flex justify-content-end">
              <Space>
                <Button type="primary" loading={isSavingDept} htmlType="submit">
                  {isSavingDept ? t("company.saving") : t("button.save")}
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    handleAddDeptCancel();
                  }}
                >
                  {t("button.cancel")}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
