import { ReactElement } from "react";
import styles from "./InputCheckboxBlock.module.scss";
import VideoPlayerComponent from "../VideoPlayerComponent";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface IInputVideoBlock {
  userInput: string;
  alertOccured: boolean;
}

export default function InputAudioBlock({
  userInput,
  alertOccured,
}: IInputVideoBlock): ReactElement {
  const { t } = useTranslation();
  return (
    <div
      style={{
        border: alertOccured ? "3px red solid" : "",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}
      <h3>{t("operate.audioSent")}</h3>

      <div className={styles.toggled}>
        <div className={styles.options}>
          <VideoPlayerComponent
            playVideo={{
              media_content: {
                media_content_uid: userInput,
              },
            }}
          ></VideoPlayerComponent>
        </div>
      </div>
    </div>
  );
}
