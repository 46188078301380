import MenuTabItem from "components/TempDS/MenuTabItem";
import styles from "./MediaPickerHeaderTabs.module.scss";
import { useTranslation } from "react-i18next";
import { GlobalStore, useStoreState } from "@armtekfrance/webgate/dist";

export default function MediaPickerHeaderTabs() {
  const { t } = useTranslation();
  useStoreState(GlobalStore.media.getCurrentTab());

  return (
    <header className={styles.mediaPickerHeaderTabsWrapper}>
      <MenuTabItem
        label={t("doc.models.edit.sections.blocks.mediaLibrary")}
        selected={GlobalStore.media.getCurrentTab().currentTab === "media"}
        onClick={() => {
          GlobalStore.media.setCurrentTab({ currentTab: "media" });
        }}
      />
      <MenuTabItem
        label={t("doc.models.edit.sections.blocks.uploadFiles")}
        selected={GlobalStore.media.getCurrentTab().currentTab === "upload"}
        onClick={() => {
          GlobalStore.media.setCurrentTab({ currentTab: "upload" });
        }}
      />
    </header>
  );
}
