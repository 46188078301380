import { useEffect } from "react";
import {
  countNotificationsTanStack,
  fetchNotificationsTanStack,
} from "app/stores/notifications/notificationsClient";
import { useHeader, useHeaderDispatch } from "../Provider/HeaderContext";
import NotificationModal from "pages/Notifications/Modal/NotificationModal";
import { DropDownNew } from "components/DropDown/DropDownNew";
import NotificationsOrAlertsDropDown from "./DropDowns/NotificationsOrAlertsDropDown";
import BadgeOnItem from "../../../components/BadgeOnItem/BadgeOnItem";
import styles from "./NotificationBell.module.scss";
import { BellClassic } from "@armtekfrance/armtek-react-design-system/dist/assets";

export default function NotificationBell({
  dataCy = "NotificationBell",
}: {
  dataCy?: string;
}) {
  const headerContext = useHeader();
  const dispatchHeader = useHeaderDispatch();

  const {
    status: statusNotifications,
    error: errorNotifications,
    notifications,
  } = fetchNotificationsTanStack(
    1,
    100,
    {
      alert: false,
    },
    { createdAt: "desc" }
  );

  useEffect(() => {
    dispatchHeader({
      type: "setNotificationTanstackApi",
      setNotificationTanstackApi: {
        statusNotifications,
        errorNotifications,
      },
    });
    if (statusNotifications === "success") {
      dispatchHeader({
        type: "setNotificationTanstack",
        setNotificationTanstack: {
          notifications: notifications?.notifications,
          paginationNotifications: notifications?.pagination ?? {
            page: 1,
            pageSize: 10,
            total: -1,
          },
        },
      });
    }
  }, [statusNotifications, errorNotifications, notifications]);

  const {
    status: statusNotificationsCount,
    error: errorNotificationsCount,
    notificationsCount,
  } = countNotificationsTanStack({
    opened: false,
    alert: false,
  });

  useEffect(() => {
    dispatchHeader({
      type: "setNotificationCountTanstackApi",
      setNotificationCountTanstackApi: {
        statusNotificationsCount,
        errorNotificationsCount,
      },
    });
    if (statusNotificationsCount === "success") {
      dispatchHeader({
        type: "setNotificationCountTanstack",
        setNotificationCountTanstack: {
          notificationsCount: notificationsCount! ?? -1,
        },
      });
    }
  }, [statusNotificationsCount, errorNotificationsCount, notificationsCount]);

  return (
    <div className={styles.notificationBellWrapper} data-cy={dataCy}>
      <DropDownNew
        placement="bottomRight"
        dropdownContent={
          <NotificationsOrAlertsDropDown typeOfNotidications="NOTIFICATIONS" />
        }
      >
        {headerContext.notificationsCount ? (
          <BadgeOnItem numberValue={headerContext.notificationsCount}>
            <BellClassic />
          </BadgeOnItem>
        ) : (
          <BellClassic />
        )}
      </DropDownNew>
      <NotificationModal
        isModalVisible={headerContext.previewInfo.isOpen}
        typeOfPreview={headerContext.previewInfo.typeOfPreview}
        documentId={headerContext.previewInfo.idOfPreview}
        onCancel={() => {
          dispatchHeader({ type: "togglePreview" });
        }}
      />
    </div>
  );
}
