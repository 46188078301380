import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, Popover, Select, Tag } from "antd";
import Modal from "antd/lib/modal/Modal";
import QrCodeComponent from "../QrCodeComponent/QrCodeComponent";
import { useEffect, useState } from "react";
import { AiOutlineQrcode } from "react-icons/ai";
import { fetchDocumentCategoryByID } from "../../app/stores/documentCategories/documentCategoriesClient";
import { fetchDocumentVersions } from "../../app/stores/documents/documentsClient";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import DocumentVersions from "../DocumentVersions";
import ProcedurePreview from "../ProcedurePreview";
import style from "./style.module.css";
import { onChangeDocumentData } from "../../pages/ModelInfoPage/ModelInfoPage";
import { useTranslation } from "react-i18next";
import { getAccessRightForAResource } from "app/services/accessRights";
import AccessRightDetails from "components/ModelEditSectionDetails/AccessRightDetails";
import { AccessRight } from "app/services/accessRights/types";

interface ModelInfoCardType {
  currentDocument: any;
  qrData: any;
}

export default function ModelInfoCard({
  currentDocument,
  qrData,
}: ModelInfoCardType) {
  const { t } = useTranslation();
  const [docCategory, setDocCategory] = useState<any>({});
  const [docSubCategory, setDocSubCategory] = useState<any>({});
  const [docLatestVersion, setDocLatestVersion] = useState([]);
  const [displayAccessRight, setDisplayAccessRight] = useState<
    "internal" | "private"
  >("private");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewDocumentId, setViewDocumentId] = useState("");
  const showModal = (docId: any) => {
    setViewDocumentId(docId);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isVersionModalVisible, setIsVersionModalVisibleVisible] =
    useState(false);
  const handleVersionModalCancel = () => {
    setIsVersionModalVisibleVisible(false);
  };

  useEffect(() => {
    fetchDocumentCategoryByID(currentDocument.doc_category_id).then(
      (response) => {
        setDocSubCategory(response.data);
        fetchDocumentCategoryByID(response.data.parent_id).then(
          (parentResponse) => {
            setDocCategory(parentResponse.data);
          }
        );
      }
    );
  }, [currentDocument.doc_category_id]);

  useEffect(() => {
    fetchDocumentVersions(currentDocument.id, 1, 1, {}).then((response) => {
      if (response?.data?.documentModelsVersions?.length > 0) {
        let versionToDisplay: any = [];
        versionToDisplay.push(
          response.data.documentModelsVersions[
            response.data.documentModelsVersions.length - 1
          ]
        );
        setDocLatestVersion(versionToDisplay);
      }
    });
    getAccessRightForAResource(currentDocument.id, "document_model").then(
      (response) => {
        if (response.success) {
          // setAccessRight(response.data);
          if (response?.data?.length > 0) {
            setDisplayAccessRight("private");
          } else {
            setDisplayAccessRight("internal");
          }
        }
      }
    );
  }, [currentDocument]);

  const handleLocalCodeChange = (value: string) => {
    onChangeDocumentData({ local_code: value }, currentDocument);
  };

  const [accessRight, setAccessRight] = useState<AccessRight[]>([]);

  useEffect(() => {
    try {
      getAccessRightForAResource(currentDocument.id, "document_model").then(
        (response) => {
          console.log("response", response);
          if (response.success) {
            setAccessRight(response.data);
            if (response?.data?.length > 0) {
              setDisplayAccessRight("private");
            } else {
              setDisplayAccessRight("internal");
            }
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }, [currentDocument]);

  useEffect(() => {
    console.log("accessRight", accessRight);
  }, accessRight);

  return (
    <>
      <Modal
        style={{
          top: 25,
        }}
        width={"80vw"}
        title={t("doc.models.edit.tabPanel.versions")}
        open={isVersionModalVisible}
        onCancel={handleVersionModalCancel}
        footer={[
          <Button key="back" onClick={handleVersionModalCancel}>
            {t("button.cancel")}
          </Button>,
        ]}
      >
        <DocumentVersions documentId={currentDocument.id} />
      </Modal>
      <Card
        style={{
          border: "0.5px solid #030852",
          color: "#030852",
        }}
        size="small"
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "1.5rem",
              rowGap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <AvatarComponent
              src={currentDocument.user?.avatar?.media_content_uid}
              firstName={currentDocument.user.first_name}
              lastName={currentDocument.user.last_name}
              showToolTip={false}
              style={{ border: "solid 1px #262626" }}
            />
            <p
              style={{ margin: "0 auto 0 0", color: "#030852" }}
            >{`${currentDocument.user.first_name} ${currentDocument.user.last_name}`}</p>
            <p style={{ margin: "0", color: "#030852" }}>
              {new Intl.DateTimeFormat("en-GB").format(
                new Date(currentDocument.updated_at)
              )}
            </p>
          </div>
        }
      >
        {docLatestVersion.length === 0 ? (
          <>{t("modelInfoCard.noPublishedVersion")}</>
        ) : (
          <>
            <ProcedurePreview
              isModalVisible={isModalVisible}
              handleCancel={handleCancel}
              documentId={parseInt(viewDocumentId)}
              page={"baselines"}
            />
            {docLatestVersion.map((version: any) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  columnGap: "1.75rem",
                  rowGap: ".4rem",
                  margin: ".2rem 0 .8rem 0",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: "0" }}>
                  {`${t("doc.models.procedure.version")} ${version.version}`}
                </p>
                <p style={{ margin: "0", marginRight: "auto" }}>
                  {new Intl.DateTimeFormat("en-GB").format(
                    new Date(version.published_at)
                  )}
                </p>
                <Tag
                  color="#ABC09F"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  <p style={{ margin: "0" }}>
                    {t("doc.models.published")} &nbsp;
                  </p>
                  <EyeOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => showModal(version.id)}
                  />
                </Tag>
              </div>
            ))}
            <div
              style={{
                cursor: "pointer",
                color: "#030852",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
              onClick={() => setIsVersionModalVisibleVisible(true)}
            >
              {t("modelInfoCard.versionHistory")}
            </div>
          </>
        )}
        <div className={style.sidebarMenu}></div>
        {t("modelInfoCard.category")} {docCategory?.category_name}
        <div className={style.sidebarMenu}></div>
        {t("modelInfoCard.subcategory")} {docSubCategory?.category_name}
        <div className={style.sidebarMenu}></div>
        {t("modelInfoCard.modelLanguage")}
        <Select
          defaultValue={currentDocument.local_code}
          onChange={handleLocalCodeChange}
          style={{
            width: 150,
            color: "#030852",
          }}
          bordered={false}
          options={[
            {
              value: "en_US",
              label: "English",
            },
            {
              value: "fr_FR",
              label: "Français",
            },
          ]}
        />
        <div className={style.sidebarMenu}></div>
        <Popover
          content={
            qrData ? (
              <QrCodeComponent qrCode={qrData} />
            ) : (
              <p>{t("qrCode.noQrAssigned")}</p>
            )
          }
          trigger="click"
        >
          <AiOutlineQrcode
            size={24}
            style={{ cursor: "pointer", color: "black" }}
          />
        </Popover>
        {"  "}
        {currentDocument.document_model_uid}
        <div className={style.sidebarMenu}></div>
        <AccessRightDetails
          currentDocument={currentDocument}
          displayAccessRight={displayAccessRight}
          setDisplayAccessRight={setDisplayAccessRight}
          accessRight={accessRight}
          setAccessRight={setAccessRight}
        />
      </Card>
    </>
  );
}
