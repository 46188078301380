import dayjs, { ManipulateType } from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);

export const humanizeHowLongAgo = (dateTime: string) => {
  return dayjs(dateTime).fromNow();
};

export const isInLessThanADurationFromNow = (
  date: string,
  durationValue: number,
  durationUnit: ManipulateType
): boolean | undefined => {
  if (dayjs(date).isValid()) {
    const now = dayjs();
    const dateDayjs = dayjs(date).subtract(durationValue, durationUnit);
    return now.diff(dateDayjs) > 0;
  }
};

export const isAlreadyPassed = (date: string): boolean | undefined => {
  if (dayjs(date).isValid()) {
    if (dayjs(date).diff(dayjs()) < 0) {
      return true;
    }
    return false;
  }
};

export const convertSecondsToTime = (durationInSeconds: number) => {
  const duration = dayjs.duration(durationInSeconds, "seconds");
  const hours = duration.hours().toString().padStart(2, "0");
  const minutes = duration.minutes().toString().padStart(2, "0");
  const seconds = duration.seconds().toString().padStart(2, "0");

  if (duration.hours() > 0) return `${hours}:${minutes}:${seconds}`;
  return `${minutes}:${seconds}`;
};
