import { useReducer } from "react";
import {
  DocumentsModelsContext,
  DocumentsModelsDispatchContext,
} from "./DocumentModelsContext";
import { documentsModelsReducer } from "./DocumentModelsReducer";

export default function DocumentsModelsProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [documentsModels, dispatchDocumentsModels] = useReducer(
    documentsModelsReducer,
    {
      status: "loading",
      error: null,
      slimView: false,
      documentModels: [],
      selectedItems: [],
      nameTextFilter: "",
      typeFilter: [],
      usersFilter: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: -1,
      },
    }
  );

  return (
    <DocumentsModelsContext.Provider value={documentsModels}>
      <DocumentsModelsDispatchContext.Provider value={dispatchDocumentsModels}>
        {children}
      </DocumentsModelsDispatchContext.Provider>
    </DocumentsModelsContext.Provider>
  );
}
