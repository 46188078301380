import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { navigation } from "../../Router/NavigationService";

export default function Home() {
  const { t } = useTranslation();
  useEffect(() => {
    navigation.toDefault();
  }, []);
  return <main>{t("dashboard.redirecting")}</main>;
}
