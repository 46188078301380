import { fetchBaselineValidationsTanStack } from "app/stores/documentBaselineValidation/documentsBaselineValidationClient";

import PaginationLayout from "components/PaginationLayout";
import ValidationTable from "components/ValidationTable/ValidationTable";
import { useEffect } from "react";
import ValidationStatusHero from "./Hero/ValidationStatusHero";
import {
  useValidationStatus,
  useValidationStatusDispatch,
} from "./Provider/ValidationStatusContext";

export default function Validation({
  document_family,
}: {
  document_family: "KNOWLEDGE" | "SYSTEM";
}) {
  const validationStatusContext = useValidationStatus();
  const dispatchValidationStatus = useValidationStatusDispatch();

  const {
    status: statusBaselineValidations,
    error: errorBaselineValidations,
    baselineValidations,
  } = fetchBaselineValidationsTanStack(
    validationStatusContext.paginationBaselineValidations.page,
    validationStatusContext.paginationBaselineValidations.pageSize,
    {
      authors: validationStatusContext.authors,
      validators: validationStatusContext.validators.length
        ? validationStatusContext.validators
        : [],
      status: validationStatusContext.queryBaselineValidationsStatus.length
        ? validationStatusContext.queryBaselineValidationsStatus
        : ["PENDING", "VALIDATED", "REFUSED"],
      name: { contains: validationStatusContext.queryNameTextFilter },
      family: "organisation",
      document_family,
    }
  );

  useEffect(() => {
    dispatchValidationStatus({
      type: "setBaselineValidationsTanstackApi",
      setBaselineValidationsTanstackApi: {
        statusBaselineValidations,
        errorBaselineValidations,
      },
    });
    if (statusBaselineValidations === "success") {
      dispatchValidationStatus({
        type: "setBaselineValidationsTanstack",
        setBaselineValidationsTanstack: {
          baselineValidations: baselineValidations?.baselineValidations,
          paginationBaselineValidations: baselineValidations?.pagination ?? {
            page: 1,
            pageSize: 10,
            total: -1,
          },
        },
      });
    }
  }, [
    validationStatusContext.authors,
    validationStatusContext.users,
    validationStatusContext.queryNameTextFilter,
    statusBaselineValidations,
    errorBaselineValidations,
    baselineValidations,
  ]);

  function setPaginationPagePageSize(page: number, pageSize: number) {
    dispatchValidationStatus({
      type: "setPaginationBaselineValidations",
      paginationBaselineValidations: {
        page: page,
        pageSize: pageSize,
        total: baselineValidations!.pagination.total ?? -1,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem",
        margin: "1rem 40px",
      }}
    >
      <ValidationStatusHero />
      {validationStatusContext.statusBaselineValidations === "success" && (
        <>
          <ValidationTable
            procedures={validationStatusContext!.baselineValidations}
            loading={false}
          />
          <PaginationLayout
            page={validationStatusContext!.paginationBaselineValidations.page}
            pageSize={
              validationStatusContext!.paginationBaselineValidations.pageSize
            }
            total={validationStatusContext!.paginationBaselineValidations.total}
            onChangePagination={setPaginationPagePageSize}
          />
        </>
      )}
      {validationStatusContext.statusBaselineValidations === "loading" && (
        <>
          <ValidationTable procedures={[]} loading={true} />
          <PaginationLayout
            page={validationStatusContext!.paginationBaselineValidations.page}
            pageSize={
              validationStatusContext!.paginationBaselineValidations.pageSize
            }
            total={validationStatusContext!.paginationBaselineValidations.total}
            onChangePagination={setPaginationPagePageSize}
          />
        </>
      )}
    </div>
  );
}
