import { createContext, Dispatch, useContext } from "react";
import {
  stateHeaderProvider,
  TypeHeaderContextReducerAction,
} from "./HeaderReducer";

export const HeaderContext = createContext<stateHeaderProvider>({
  statusNotifications: "loading",
  errorNotifications: null,
  notifications: [],
  paginationNotifications: { page: 1, pageSize: 10, total: -1 },
  notificationsByDay: [],
  statusNotificationsCount: "loading",
  errorNotificationsCount: null,
  notificationsCount: undefined,
  openedNotificationsByDay: [],
  closedNotificationsByDay: [],
  previewInfo: {
    typeOfPreview: "PROCEDURE",
    idOfPreview: -1,
    isOpen: false,
  },
  statusAlerts: "loading",
  errorAlerts: null,
  alerts: [],
  paginationAlerts: { page: 1, pageSize: 10, total: -1 },
  statusAlertsCount: "loading",
  errorAlertsCount: null,
  alertsCount: undefined,
  alertsByDay: [],
  openedAlertsByDay: [],
  closedAlertsByDay: [],
  rightMenuTab: 0,
  leftMenuActions: 0,
});

export const HeaderDispatchContext = createContext<
  Dispatch<TypeHeaderContextReducerAction>
>(() => {
  return { type: "" };
});

export function useHeader() {
  return useContext(HeaderContext);
}

export function useHeaderDispatch() {
  return useContext(HeaderDispatchContext);
}
