import { QueryStatus } from "@tanstack/react-query";
import { notificationType } from "app/stores/notifications/notificationsType";
import { PaginationType } from "app/stores/pagination/types";
import dayjs from "dayjs";

export type stateHeaderProvider = {
  statusNotifications: QueryStatus;
  errorNotifications: unknown;
  notifications: notificationType[] | undefined;
  paginationNotifications: PaginationType;
  statusNotificationsCount: QueryStatus;
  errorNotificationsCount: unknown;
  notificationsCount: number | undefined;
  notificationsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  openedNotificationsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  closedNotificationsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  previewInfo: {
    typeOfPreview: "PROCEDURE" | "EXECUTION" | "SINGLE_BLOCK";
    idOfPreview: any;
    isOpen: boolean;
  };
  statusAlerts: QueryStatus;
  errorAlerts: unknown;
  alerts: notificationType[] | undefined;
  paginationAlerts: PaginationType;
  statusAlertsCount: QueryStatus;
  errorAlertsCount: unknown;
  alertsCount: number | undefined;
  alertsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  openedAlertsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  closedAlertsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  rightMenuTab: number;
  leftMenuActions: number;
};

const notificationsDayjsDateFormat = "DD/MM/YYYY";

function createListOfPossibleNotificationDay(
  notifications: notificationType[] | undefined
) {
  const listOfNotificationDays: string[] = [];
  notifications?.map((notification) => {
    if (
      !listOfNotificationDays.includes(
        dayjs(notification.createdAt).format(notificationsDayjsDateFormat)
      )
    ) {
      listOfNotificationDays.push(
        dayjs(notification.createdAt).format(notificationsDayjsDateFormat)
      );
    }
  });
  return listOfNotificationDays;
}

export function makeArrayByCreatedAtDay(
  notifications: notificationType[] | undefined
) {
  const daysOfNotifs = createListOfPossibleNotificationDay(notifications);

  return daysOfNotifs.map((stringOfNotifDay) => {
    return {
      createdAt: stringOfNotifDay,
      notifications: notifications?.filter((notification) => {
        return (
          dayjs(notification.createdAt).format(notificationsDayjsDateFormat) ===
          stringOfNotifDay
        );
      }),
    };
  });
}

export type TypeHeaderContextReducerAction =
  | {
      type: "setRightMenuTab";
      setRightMenuTab: stateHeaderProvider["rightMenuTab"];
    }
  | {
      type: "setLeftMenuActions";
      setLeftMenuActions: stateHeaderProvider["leftMenuActions"];
    }
  | {
      type: "setAlertsTanstackApi";
      setAlertsTanstackApi: {
        statusAlerts: stateHeaderProvider["statusAlerts"];
        errorAlerts: stateHeaderProvider["errorAlerts"];
      };
    }
  | {
      type: "setNotificationTanstackApi";
      setNotificationTanstackApi: {
        statusNotifications: stateHeaderProvider["statusNotifications"];
        errorNotifications: stateHeaderProvider["errorNotifications"];
      };
    }
  | {
      type: "setNotificationCountTanstackApi";
      setNotificationCountTanstackApi: {
        statusNotificationsCount: stateHeaderProvider["statusNotificationsCount"];
        errorNotificationsCount: stateHeaderProvider["errorNotificationsCount"];
      };
    }
  | {
      type: "togglePreview";
    }
  | {
      type: "setPreview";
      previewInfo: {
        typeOfPreview: stateHeaderProvider["previewInfo"]["typeOfPreview"];
        idOfPreview: stateHeaderProvider["previewInfo"]["idOfPreview"];
        isOpen: stateHeaderProvider["previewInfo"]["isOpen"];
      };
    }
  | {
      type: "setNotificationTanstack";
      setNotificationTanstack: {
        notifications: stateHeaderProvider["notifications"];
        paginationNotifications: stateHeaderProvider["paginationNotifications"];
      };
    }
  | {
      type: "setAlertsTanstack";
      setAlertsTanstack: {
        alerts: stateHeaderProvider["alerts"];
        paginationAlerts: stateHeaderProvider["paginationAlerts"];
      };
    }
  | {
      type: "setAlertCountTanstack";
      setAlertCountTanstack: {
        alertsCount: stateHeaderProvider["alertsCount"];
      };
    }
  | {
      type: "setAlertCountTanstackApi";
      setAlertCountTanstackApi: {
        statusAlertsCount: stateHeaderProvider["statusAlertsCount"];
        errorAlertsCount: stateHeaderProvider["errorAlertsCount"];
      };
    }
  | {
      type: "setNotificationCountTanstack";
      setNotificationCountTanstack: {
        notificationsCount: stateHeaderProvider["notificationsCount"];
      };
    };

export function headerReducer(
  stateHeader: stateHeaderProvider,
  action: TypeHeaderContextReducerAction
): stateHeaderProvider {
  switch (action.type) {
    case "setLeftMenuActions": {
      return {
        ...stateHeader,
        leftMenuActions: action.setLeftMenuActions,
      };
    }
    case "setRightMenuTab": {
      return {
        ...stateHeader,
        rightMenuTab: action.setRightMenuTab,
      };
    }
    case "setNotificationTanstackApi": {
      return {
        ...stateHeader,
        statusNotifications:
          action.setNotificationTanstackApi.statusNotifications,
        errorNotifications:
          action.setNotificationTanstackApi.errorNotifications,
      };
    }
    case "setAlertsTanstackApi": {
      return {
        ...stateHeader,
        statusAlerts: action.setAlertsTanstackApi.statusAlerts,
        errorAlerts: action.setAlertsTanstackApi.errorAlerts,
      };
    }
    case "setNotificationTanstack": {
      return {
        ...stateHeader,
        notifications: action.setNotificationTanstack.notifications,
        paginationNotifications:
          action.setNotificationTanstack.paginationNotifications,
        notificationsByDay: makeArrayByCreatedAtDay(
          action.setNotificationTanstack.notifications
        ),
        openedNotificationsByDay: makeArrayByCreatedAtDay(
          action.setNotificationTanstack.notifications?.filter(
            (notification) => notification.opened
          )
        ),
        closedNotificationsByDay: makeArrayByCreatedAtDay(
          action.setNotificationTanstack.notifications?.filter(
            (notification) => !notification.opened
          )
        ),
      };
    }
    case "setAlertsTanstack": {
      return {
        ...stateHeader,
        alerts: action.setAlertsTanstack.alerts,
        paginationNotifications: action.setAlertsTanstack.paginationAlerts,
        alertsByDay: makeArrayByCreatedAtDay(action.setAlertsTanstack.alerts),
        openedAlertsByDay: makeArrayByCreatedAtDay(
          action.setAlertsTanstack.alerts?.filter(
            (notification) => notification.opened
          )
        ),
        closedAlertsByDay: makeArrayByCreatedAtDay(
          action.setAlertsTanstack.alerts?.filter(
            (notification) => !notification.opened
          )
        ),
      };
    }
    case "setPreview": {
      return {
        ...stateHeader,
        previewInfo: {
          typeOfPreview: action.previewInfo.typeOfPreview,
          idOfPreview: action.previewInfo.idOfPreview,
          isOpen: action.previewInfo.isOpen,
        },
      };
    }
    case "setNotificationCountTanstackApi": {
      return {
        ...stateHeader,
        statusNotificationsCount:
          action.setNotificationCountTanstackApi.statusNotificationsCount,
        errorNotificationsCount:
          action.setNotificationCountTanstackApi.errorNotificationsCount,
      };
    }
    case "setAlertCountTanstackApi": {
      return {
        ...stateHeader,
        statusAlertsCount: action.setAlertCountTanstackApi.statusAlertsCount,
        errorAlertsCount: action.setAlertCountTanstackApi.errorAlertsCount,
      };
    }
    case "setNotificationCountTanstack": {
      return {
        ...stateHeader,
        notificationsCount:
          action.setNotificationCountTanstack.notificationsCount,
      };
    }
    case "setAlertCountTanstack": {
      return {
        ...stateHeader,
        alertsCount: action.setAlertCountTanstack.alertsCount,
      };
    }
    case "togglePreview": {
      return {
        ...stateHeader,
        previewInfo: {
          ...stateHeader.previewInfo,
          isOpen: !stateHeader.previewInfo.isOpen,
        },
      };
    }
    default: {
      return { ...stateHeader };
    }
  }
}
