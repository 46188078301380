import {
  DocumentModelCloudActions,
  GlobalStore,
  Loader,
  useIsOngoing,
  useStoreState,
} from "@armtekfrance/webgate/dist";
import { DraggableCard } from "../../pages/PlanLayout/DraggableCard";
import { useTranslation } from "react-i18next";
import { ApiDocumentFamilySpecification } from "@armtekfrance/corenexus-pn-sn";

export enum CARD_VALUES {
  Category = "Category",
  Subcategory = "Subcategory",
}

export default function CategoryPlanCards() {
  const { t } = useTranslation();
  useStoreState(GlobalStore.modelEditor.getDocumentTypes());
  const isLoading = useIsOngoing(
    DocumentModelCloudActions.DOCUMENT_MODEL_TYPES
  );
  return (
    <div
      style={{ border: "1px solid black", padding: "8px", borderRadius: "8px" }}
    >
      <DraggableCard
        itemName={t("doc.plan.outline.category")}
        itemValue={CARD_VALUES.Category}
        type={"Category"}
        textStyle={{ fontSize: "18px", fontWeight: "600" }}
      />
      <DraggableCard
        itemName={t("doc.plan.outline.subcategory")}
        itemValue={CARD_VALUES.Subcategory}
        type={"Subcategory"}
        textStyle={{ fontSize: "16px", fontWeight: "600" }}
      />
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <>
          {GlobalStore?.modelEditor
            ?.getDocumentTypes()
            .map(
              (
                documentFamily: ApiDocumentFamilySpecification,
                index: number
              ) => (
                <>
                  <div
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    key={index}
                  >
                    {documentFamily?.family === "SYSTEM"
                      ? t("systemPage")
                      : t("menu.knowledge")}
                  </div>
                  <>
                    {documentFamily?.types?.map((type, index) => (
                      <DraggableCard
                        key={index}
                        itemValue={type?.type}
                        type={"document"}
                        family={documentFamily?.family}
                      />
                    ))}
                  </>
                </>
              )
            )}
        </>
      )}
    </div>
  );
}
