import { Button, Tag, Skeleton, Tooltip, Space } from "antd";
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ListView from "../ListView";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import { useEffect, useState } from "react";
import { getLoggedInUser } from "../../app/stores/auth/authClient";
import { useTranslation } from "react-i18next";
import DisplayUserTeamsByUserId from "./DisplayUserTeamsByUserId";
import { IUser } from "app/stores/users/types";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";

interface TableType {
  users: IUser[];
  loading: boolean;
  isListView: boolean;
  paginationDetails: any;
  onChangePagination: (page: number, pageSize: number) => void;
  setSelectedTableKeys?: (selectedKeys: any[]) => void;
  previewUser: (id: number) => void;
  editUser?: (id: number) => void;
  archiveUser?: (id: number) => Promise<void>;
  displayedColumns?: string[] | undefined;
  selectedDefaults?: number[] | undefined;
  showCheckBox?: boolean;
}

export default function CompanyUsersTable({
  users,
  loading,
  editUser = () => {
    return;
  },
  archiveUser = async () => {
    return;
  },
  previewUser,
  paginationDetails,
  onChangePagination,
  setSelectedTableKeys = () => {
    return;
  },
  displayedColumns = undefined,
  selectedDefaults = undefined,
  showCheckBox,
}: TableType) {
  const { t } = useTranslation();
  const { page, pageSize, total } = paginationDetails;
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [archiveUserId, setArchiveUserId] = useState<number | null>(null);
  const [numRows, setNumRows] = useState<number>(10);
  const [activeUser, setActiveUser] = useState<number | null>(null);

  const user = getLoggedInUser();

  // const [userTeams, setUserTeams] = useState<string[]>([]);

  // Skeleton loader small animation
  useEffect(() => {
    if (loading) {
      let increasing = 1;
      const intervalId = setInterval(() => {
        let increment = () => (Math.random() < 0.5 ? 1 : 2);
        setNumRows((prev) => {
          if (prev >= 10) {
            increasing = -increment();
          }
          if (prev <= 3) {
            increasing = +increment();
          }
          return prev + increasing;
        });
      }, 3000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [loading]);

  const loadingRows = ((numRows: number) => {
    let numList = [];
    for (let i = 1; i <= numRows; i++) {
      numList.push(i);
    }
    return numList;
  })(numRows);

  // On Archive user
  const onArchiveUser = async (id: number) => {
    setActiveUser(id);
    setArchiveLoading(true);
    await archiveUser(id);
    setArchiveLoading(false);
  };

  const isUserSuperAdmin = (id: number) => {
    const user = users.find((user) => user.id === id);

    if (!user) {
      return false;
    }
    return user.permissions === 127;
  };

  const permissionList = [
    {
      name: t("userPermissionData.guest.name"),
      permission: 64,
    },
    {
      name: t("userPermissionData.operator.name"),
      permission: 96,
    },
    {
      name: t("userPermissionData.editor.name"),
      permission: 112,
    },
    {
      name: t("userPermissionData.manager.name"),
      permission: 124,
    },
    {
      name: t("userPermissionData.admin.name"),
      permission: 126,
    },
    {
      name: t("userPermissionData.superAdmin.name"),
      permission: 127,
    },
  ];

  const isLoggedUserAdmin = () => {
    if (!user) {
      return false;
    }

    return user.permissions === 127 || user.permissions === 126;
  };

  let columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      render: (avatar: any, record: any) => (
        <AvatarComponent
          src={avatar?.media_content_uid || ""}
          firstName={record.first_name}
          lastName={record.last_name}
        />
      ),
    },
    {
      title: t("companyTable.firstName"),
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {first_name}
        </span>
      ),
    },
    {
      title: t("companyTable.middleName"),
      dataIndex: "middle_name",
      key: "middle_name",
      render: (middle_name: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {middle_name}
        </span>
      ),
    },
    {
      title: t("companyTable.lastName"),
      dataIndex: "last_name",
      key: "last_name",
      render: (last_name: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {last_name}
        </span>
      ),
    },
    {
      title: t("companyTable.email"),
      dataIndex: "email",
      key: "email",
      render: (email: string) => <span>{email}</span>,
    },
    {
      title: t("companyTable.title"),
      dataIndex: "title",
      key: "title",
      render: (title: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {title}
        </span>
      ),
    },
    {
      title: t("companyTable.role"),
      dataIndex: "permissions",
      key: "permissions",
      render: (permissions: number) =>
        permissionList.find((perm) => perm.permission === permissions)?.name,
    },
    {
      title: t("companyTable.team"),
      dataIndex: "id",
      key: "id",
      render: (id: number) => {
        return <DisplayUserTeamsByUserId id={id} />;
      },
    },
    {
      title: "doc.models.procedure.status",
      dataIndex: "active",
      key: "active",
      render: (active: boolean) => {
        return active ? (
          <Tag color="#87d068">{t("companyTable.active")}</Tag>
        ) : (
          <Tag color="#f50">{t("companyTable.inactive")}</Tag>
        );
      },
    },
    {
      title: t("companyTable.joined"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: string) => (
        <p
          style={{
            display: "flex",
            justifyContent: "flex-start",
            color: "#030852",
            margin: "0",
            padding: "0",
            fontWeight: "400",
            textDecoration: "underline",
          }}
        >
          {new Intl.DateTimeFormat("en-GB").format(new Date(created_at))}
        </p>
      ),
    },
    {
      title: t("companyTable.details"),
      key: "details",
      dataIndex: "id",
      render: (id: number) => (
        <Tooltip title={t("companyTable.viewUserDetails")}>
          <Button
            icon={<AiOutlineEye />}
            type="primary"
            onClick={() => {
              previewUser(id);
            }}
          ></Button>
        </Tooltip>
      ),
    },
    {
      title: t("doc.models.procedure.action"),
      key: "action",
      dataIndex: "id",
      render: (id: number) => (
        <Space>
          {!isLoggedUserAdmin() || isUserSuperAdmin(id) ? null : (
            <Tooltip title={t("companyTable.editUser")}>
              <Button
                icon={<AiOutlineEdit />}
                type="ghost"
                onClick={() => editUser(id)}
              ></Button>
            </Tooltip>
          )}
          {!isLoggedUserAdmin() || isUserSuperAdmin(id) ? null : (
            <Tooltip title={t("companyTable.archiveUser.archive")}>
              <Button
                icon={<AiOutlineDelete />}
                danger
                loading={archiveLoading && id === activeUser}
                onClick={() => {
                  setIsArchiveModalVisible(true);
                  setArchiveUserId(id);
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  if (displayedColumns) {
    columns = columns.filter((column) =>
      displayedColumns.includes(column.key.toLowerCase())
    );
  }

  // Loading skeleton for the table
  const loadingColumns = columns.map((column) => ({
    ...column,
    render: () => {
      if (column.key === "avatar") {
        return <Skeleton.Avatar active />;
      } else if (
        column.key === "active" ||
        column.key === "created_at" ||
        column.key === "permissions"
      ) {
        return <Skeleton.Button active size="small" />;
      } else if (column.key === "id") {
        return <Skeleton.Avatar active size="small" shape="square" />;
      } else if (column.key === "action") {
        return (
          <Space>
            <Skeleton.Avatar active size="small" shape="square" />
            <Skeleton.Avatar active size="small" shape="square" />
            <Skeleton.Avatar active size="small" shape="square" />
          </Space>
        );
      }
      return <Skeleton.Input active size="small" />;
    },
  }));

  return (
    <>
      <div style={{ zIndex: "-1" }}>
        <ListView
          selectedKeys={selectedDefaults}
          data={loading ? loadingRows : users}
          cardData={users}
          columns={loading ? loadingColumns : columns}
          loading={false}
          loadingCardData={loading}
          isListView={true}
          page={page}
          pageSize={pageSize}
          total={total}
          cardLink={""}
          onChangePagination={onChangePagination}
          setSelectedTableKeys={setSelectedTableKeys}
          showCheckBox={showCheckBox}
        />
      </div>
      <ConfirmActionModal
        isVisible={isArchiveModalVisible}
        handleCancel={() => setIsArchiveModalVisible(false)}
        handleConfirm={() => {
          if (archiveUserId) {
            onArchiveUser(archiveUserId);
          }
          setArchiveUserId(null);
          setIsArchiveModalVisible(false);
        }}
        confirmInsideText={t("companyTable.archiveUser.title")}
        confirmHeaderText={t("archived.title")}
      />
    </>
  );
}
