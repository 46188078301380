import { IUser } from "app/stores/users/types";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import styles from "./DropDownItemUser.module.scss";

export default function DropDownItemUser({
  first_name,
  last_name,
  avatar,
}: {
  first_name: IUser["first_name"];
  last_name: IUser["last_name"];
  avatar?: IUser["avatar"];
}) {
  return (
    <form className={styles.dropDownItemUserWrapper}>
      <AvatarComponent
        src={avatar?.media_content_uid ?? ""}
        firstName={first_name}
        lastName={last_name}
        size={16}
        showToolTip={false}
      />
      <p>{`${first_name} ${last_name}`}</p>
    </form>
  );
}
