import { useParams } from "react-router-dom";
import {
  DocumentModelCloudActions,
  MigrationBatch,
  UtilsFunction,
} from "@armtekfrance/webgate/dist";
import { useEffect, useState } from "react";
import {
  ApiDocumentBaselineExpandable,
  ApiLanguageEnum,
} from "@armtekfrance/corenexus-pn-sn";
import { Manager } from "@felixkletti/managementjs";
import AfterPublishModal from "pages/System/ModelEditor/SystemAfterPublishModal";
import i18n from "translations/i18n";
import { useTranslation } from "react-i18next";

const MigrationBatchPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const [baselineIdPublished, setBaselineIdPublished] =
    useState<ApiDocumentBaselineExpandable>();

  Manager.API.on(
    DocumentModelCloudActions.DOCUMENT_MODEL_START_PUBLISH
  ).subscribe((next) => {
    if (next.success && next.response.document_baseline) {
      setBaselineIdPublished(next.response.document_baseline);
    }
  });

  useEffect(() => {
    if (baselineIdPublished) {
      setOpenDialog(true);
    }
  }, [baselineIdPublished]);

  return id && parseInt(id) ? (
    <>
      <MigrationBatch documentModelId={parseInt(id)} />
      {openDialog && (
        <AfterPublishModal
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          baselineIdPublished={baselineIdPublished?.id}
          typeOfBaselineLabel={
            UtilsFunction.interpretLabel(
              i18n.language as ApiLanguageEnum,
              baselineIdPublished?.data?.specification.label
            ) ?? ""
          }
        />
      )}
    </>
  ) : (
    <div style={{ textAlign: "center" }}>
      {t("migrationBatch.problemWithLoadingData")}
    </div>
  );
};

export default MigrationBatchPage;
