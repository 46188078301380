import { QueryStatus } from "@tanstack/react-query";
import { DocumentBaseline } from "app/stores/documentsBaseline/types";
import { PaginationType } from "app/stores/pagination/types";
import { IUser } from "app/stores/users/types";

export type stateObsoleteReleasesProvider = {
  statusBaselineValidations: QueryStatus;
  errorBaselineValidations: unknown;
  baselineValidations:
    | (DocumentBaseline & { isSelected: boolean })[]
    | undefined;
  paginationBaselineValidations: PaginationType;
  queryNameTextFilter: string;
  qureyStartDate: string;
  queryEndDate: string;
  statusUsers: QueryStatus;
  errorUsers: unknown;
  users: IUser[] | undefined;
  authors: (IUser & { selected: boolean })[];
  validators: (IUser & { selected: boolean })[];
};

export type TypeObsoleteReleasesContextReducerAction =
  | {
      type: "setPaginationBaselineValidations";
      paginationBaselineValidations: stateObsoleteReleasesProvider["paginationBaselineValidations"];
    }
  | {
      type: "setBaselineValidations";
      baselineValidations: stateObsoleteReleasesProvider["baselineValidations"];
    }
  | {
      type: "setErrorBaselineValidations";
      errorBaselineValidations: stateObsoleteReleasesProvider["errorBaselineValidations"];
    }
  | {
      type: "setStatusBaselineValidations";
      statusBaselineValidations: stateObsoleteReleasesProvider["statusBaselineValidations"];
    }
  | {
      type: "toogleSelectAuthorById";
      authorId: number;
    }
  | {
      type: "toogleSelectValidatorById";
      validatorId: number;
    }
  | {
      type: "setBaselineValidationsTanstack";
      setBaselineValidationsTanstack: {
        baselineValidations: stateObsoleteReleasesProvider["baselineValidations"];
        paginationBaselineValidations: stateObsoleteReleasesProvider["paginationBaselineValidations"];
      };
    }
  | {
      type: "setBaselineValidationsTanstackApi";
      setBaselineValidationsTanstackApi: {
        statusBaselineValidations: stateObsoleteReleasesProvider["statusBaselineValidations"];
        errorBaselineValidations: stateObsoleteReleasesProvider["errorBaselineValidations"];
      };
    }
  | {
      type: "setExpiredRangeDate";
      expiredRangeDate: {
        queryStartDate: stateObsoleteReleasesProvider["qureyStartDate"];
        queryEndDate: stateObsoleteReleasesProvider["queryEndDate"];
      };
    }
  | {
      type: "setUsersTanstack";
      setUsersTanstack: {
        statusUsers: stateObsoleteReleasesProvider["statusUsers"];
        errorUsers: stateObsoleteReleasesProvider["errorUsers"];
        users: stateObsoleteReleasesProvider["users"];
      };
    }
  | {
      type: "selectAllAuthors";
    }
  | {
      type: "clearAllAuthors";
    }
  | {
      type: "selectAllValidators";
    }
  | {
      type: "clearAllValidators";
    }
  | {
      type: "setQueryNameTextFilter";
      queryNameTextFilter: stateObsoleteReleasesProvider["queryNameTextFilter"];
    };

export function obsoleteReleasesReducer(
  stateObsoleteReleases: stateObsoleteReleasesProvider,
  action: TypeObsoleteReleasesContextReducerAction
): stateObsoleteReleasesProvider {
  switch (action.type) {
    case "setExpiredRangeDate": {
      return {
        ...stateObsoleteReleases,
        qureyStartDate: action.expiredRangeDate.queryStartDate,
        queryEndDate: action.expiredRangeDate.queryEndDate,
      };
    }
    case "setStatusBaselineValidations": {
      return {
        ...stateObsoleteReleases,
        statusBaselineValidations: action.statusBaselineValidations,
      };
    }
    case "setErrorBaselineValidations": {
      return {
        ...stateObsoleteReleases,
        errorBaselineValidations: action.errorBaselineValidations,
      };
    }
    case "setBaselineValidations": {
      return {
        ...stateObsoleteReleases,
        baselineValidations: action.baselineValidations,
      };
    }
    case "setBaselineValidationsTanstackApi": {
      return {
        ...stateObsoleteReleases,
        statusBaselineValidations:
          action.setBaselineValidationsTanstackApi.statusBaselineValidations,
        errorBaselineValidations:
          action.setBaselineValidationsTanstackApi.errorBaselineValidations,
      };
    }
    case "setBaselineValidationsTanstack": {
      return {
        ...stateObsoleteReleases,
        baselineValidations:
          action.setBaselineValidationsTanstack.baselineValidations,
        paginationBaselineValidations:
          action.setBaselineValidationsTanstack.paginationBaselineValidations,
      };
    }
    case "setPaginationBaselineValidations": {
      return {
        ...stateObsoleteReleases,
        paginationBaselineValidations: action.paginationBaselineValidations,
      };
    }
    case "setUsersTanstack": {
      if (action.setUsersTanstack.statusUsers === "success") {
        return {
          ...stateObsoleteReleases,
          statusUsers: action.setUsersTanstack.statusUsers,
          errorUsers: action.setUsersTanstack.errorUsers,
          users: action.setUsersTanstack.users,
          authors:
            action.setUsersTanstack.users!.map((user) => {
              return { ...user, selected: false };
            }) ?? [],
          validators:
            action.setUsersTanstack.users!.map((user) => {
              return { ...user, selected: false };
            }) ?? [],
        };
      }
      return {
        ...stateObsoleteReleases,
        statusUsers: action.setUsersTanstack.statusUsers,
        errorUsers: action.setUsersTanstack.errorUsers,
      };
    }
    case "setQueryNameTextFilter": {
      return {
        ...stateObsoleteReleases,
        paginationBaselineValidations: {
          ...stateObsoleteReleases.paginationBaselineValidations,
          page: 1,
        },
        queryNameTextFilter: action.queryNameTextFilter,
      };
    }
    case "selectAllAuthors": {
      return {
        ...stateObsoleteReleases,
        authors: stateObsoleteReleases.authors.map((author) => {
          author.selected = true;
          return author;
        }),
      };
    }
    case "clearAllAuthors": {
      return {
        ...stateObsoleteReleases,
        authors: stateObsoleteReleases.authors.map((author) => {
          author.selected = false;
          return author;
        }),
      };
    }
    case "selectAllValidators": {
      return {
        ...stateObsoleteReleases,
        validators: stateObsoleteReleases.validators.map((validator) => {
          validator.selected = true;
          return validator;
        }),
      };
    }
    case "clearAllValidators": {
      return {
        ...stateObsoleteReleases,
        validators: stateObsoleteReleases.validators.map((validator) => {
          validator.selected = false;
          return validator;
        }),
      };
    }
    case "toogleSelectAuthorById": {
      return {
        ...stateObsoleteReleases,
        paginationBaselineValidations: {
          ...stateObsoleteReleases.paginationBaselineValidations,
          page: 1,
        },
        authors: stateObsoleteReleases.authors.map((author) => {
          return author.id === action.authorId
            ? { ...author, selected: !author.selected }
            : author;
        }),
      };
    }
    case "toogleSelectValidatorById": {
      return {
        ...stateObsoleteReleases,
        paginationBaselineValidations: {
          ...stateObsoleteReleases.paginationBaselineValidations,
          page: 1,
        },
        validators: stateObsoleteReleases.validators.map((validator) => {
          return validator.id === action.validatorId
            ? { ...validator, selected: !validator.selected }
            : validator;
        }),
      };
    }
    default: {
      return { ...stateObsoleteReleases };
    }
  }
}
