import { Spin } from "antd";
import styles from "./CommentsList.module.scss";
import { Comment } from "app/stores/documentComments/types";
import { useState } from "react";
import {
  fetchBaselineExecutionById,
  fetchBaselineExecutionByIdAsCSV,
} from "app/stores/executionFlux/executionFluxClients";
import CsvExecution from "components/ExecutionTable/CsvExecution";
import * as XLSX from "xlsx";
import CommentItem from "./CommentItem";
import { MutationStatus } from "@tanstack/react-query";
import { ModalExportParamPanelWrapper } from "@armtekfrance/webgate/dist";

export default function CommentsList({
  listOfComments,
  onReply,
  apiStatus,
  sectionNameInfos,
}: {
  listOfComments: (Comment & { replies: Comment[] })[] | null;
  onReply: (replyText: string, comment_id: number) => void;
  apiStatus: MutationStatus;
  sectionNameInfos:
    | { sectionId: number; sectionName: string; sectionPosition: number }[]
    | null;
}) {
  const [execId, setExecId] = useState<number>(0);
  const [executionDataForFlux, setExecutionDataForFlux] = useState<any>();
  const [rawCsv, setRawCsv] = useState<any>("");
  const [csvData, setCsvData] = useState<any>({});
  const [isCsvModalVisible, setIsCsvModalVisible] = useState(false);
  const [isPdfLoading] = useState(false);
  const [executionContextLoading, setExecutionContextLoading] = useState(false);
  const [isExportPdfParamModalVisible, setIsExportPdfParamModalVisible] =
    useState(false);

  const showCSV = async (id: number) => {
    setExecutionContextLoading(true);
    setExecId(id);
    const executionData = await fetchBaselineExecutionById(id.toString());
    setExecutionDataForFlux(executionData.data);
    const response = await fetchBaselineExecutionByIdAsCSV(id.toString());
    setRawCsv(response);
    setCsvData([response.data.json]);
    setIsCsvModalVisible(true);
    setExecutionContextLoading(false);
  };

  const handleCancelCSVModal = () => {
    setIsCsvModalVisible(!isCsvModalVisible);
  };

  const downloadCSVFile = () => {
    const url = window.URL.createObjectURL(new Blob([rawCsv.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", csvData[0].baseline_name + "_execution.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const downloadXLSXFile = () => {
    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, csvData[0].baseline_name + "_execution.xlsx");
  };

  // const generatePDFExecution = async (id: number) => {
  //   setIsPdfLoading(true);
  //   try {
  //     fetchBaselineExecutionByIdAsPdf(id.toString()).then((response) => {
  //       if (!response.data) {
  //         message.error(t("executionTable.ooopsPdf"));
  //         setIsPdfLoading(false);
  //       } else {
  //         fetchBaselineExecutionById(id).then((responseBaseline) => {
  //           let fileURL = window.URL.createObjectURL(response.data);
  //           let alink = document.createElement("a");
  //           alink.href = fileURL;
  //           alink.download = `${
  //             responseBaseline.data.baseline_name.replace(/,/g, "") +
  //             " " +
  //             responseBaseline.data.baseline_version
  //           }.pdf`;
  //           alink.click();
  //           window.open(fileURL);
  //           setIsPdfLoading(false);
  //         });
  //       }
  //     });
  //   } catch (error) {
  //     setIsPdfLoading(false);
  //     message.error(t("executionTable.ooopsPdf"));
  //   }
  // };

  return (
    <div className={styles.commentsListWrapper}>
      {listOfComments?.map((comment, index) => {
        return (
          <CommentItem
            key={index}
            comment={comment}
            showExecutionMonitorId={(execution_monitor_id: number) => {
              showCSV(execution_monitor_id);
            }}
            onReply={(replyText, comment_id) => {
              onReply(replyText, comment_id);
            }}
            apiStatus={apiStatus}
            executionContextLoading={executionContextLoading}
            sectionNameInfo={sectionNameInfos?.find((sectionNameInfo) => {
              return sectionNameInfo.sectionId === comment.section_model_id;
            })}
          />
        );
      })}
      {isCsvModalVisible ? (
        <>
          {isPdfLoading ? (
            <Spin />
          ) : (
            <CsvExecution
              isCsvModalVisible={isCsvModalVisible}
              handleCancel={handleCancelCSVModal}
              csvData={csvData[0]}
              downloadCSVFile={downloadCSVFile}
              downloadXLSXFile={downloadXLSXFile}
              execId={execId}
              executionData={executionDataForFlux}
              onPdfDownload={() => {
                setIsExportPdfParamModalVisible(true);
              }}
            />
          )}
        </>
      ) : (
        <></>
      )}

      {isExportPdfParamModalVisible && (
        <ModalExportParamPanelWrapper
          open={isExportPdfParamModalVisible}
          onCancel={() => setIsExportPdfParamModalVisible(false)}
          execution_monitor_data_id={execId}
        />
      )}
    </div>
  );
}
