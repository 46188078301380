import { Button, Checkbox, List, Modal, Input, Skeleton } from "antd";
import { useState, useEffect } from "react";
import styles from "./ListUsers.module.scss";
import { fetchCompanyUsers } from "../../../../app/services/company";
import UsersTable from "./UsersTable";
import { fetchTeams } from "../../../../app/stores/teams/teamsClient";
import DropdownMultiSelection from "../../../../components/ExecutionTable/DropdownMultiSelection";
import { useTranslation } from "react-i18next";
import { ITeamType, IUser } from "app/stores/users/types";
import { PaginationType } from "app/stores/pagination/types";

export const deleteTeamRelatedToUser = async (
  userId: number,
  dataOfBlock: any
) => {
  let reqDataCopy = { ...dataOfBlock };
  if (reqDataCopy.selectedTeams) {
    for (let i = 0; i < reqDataCopy.selectedTeams.length; i++) {
      const { users } = await fetchCompanyUsers(1, 100, {
        team_id: reqDataCopy.selectedTeams[i],
      });
      if (users.filter((user) => user.id === userId).length > 0) {
        reqDataCopy = {
          ...reqDataCopy,
          selectedTeams: await reqDataCopy.selectedTeams.filter(
            (team: any) => team !== reqDataCopy.selectedTeams[i]
          ),
        };
      }
    }
    return reqDataCopy;
  } else {
    return reqDataCopy;
  }
};

export default function DefineAlertedUsers({
  isModalOpen,
  setIsModalOpen,
  setInputData,
  inputData,
  updateContentInDb,
  contentId,
}: {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  setInputData: Function;
  inputData: any;
  updateContentInDb: (id: string | number, data: any) => void;
  contentId: string | number;
}) {
  const { t } = useTranslation();
  const { Search } = Input;

  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [AllUsers, setAllUsers] = useState<IUser[]>([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [selectedTableKey, setSelectedTableKeys] = useState<any[]>([]);
  const [tableDataListPagination, setTableDataListPagination] =
    useState<PaginationType>({
      page: 1,
      pageSize: 10,
      total: 0,
    });
  const [paginatedUsers, setPaginatedUsers] = useState<IUser[]>([]);
  const [companyTeams, setCompanyTeams] = useState<ITeamType[]>([]);

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    const filters = searchText
      ? {
          first_name: { contains: searchText },
          middle_name: { contains: searchText },
          last_name: { contains: searchText },
        }
      : {};
    fetchCompanyUsers(page, pageSize, filters).then(({ users, pagination }) => {
      setIsFetchingUsers(true);
      setTableDataListPagination(pagination);
      setPaginatedUsers(users);
      setIsFetchingUsers(false);
    });
    // Fetch All Users
    fetchCompanyUsers(1, 100, filters).then(({ users }) => {
      setAllUsers(users);
    });

    fetchTeams().then((teams) => {
      let customTeamArray: any[] = [];
      teams.data.teams.forEach((team: any) => {
        customTeamArray.push({
          id: team.id,
          label: team.name,
          value: team.id,
          description: team.description,
          location: team.location,
          isTeamSelected: false,
          created_at: team.created_at,
          company_id: team.company_id,
          upated_at: team.upated_at,
          user_id: team.user_id,
        });
      });
      setCompanyTeams(customTeamArray);
    });
  }, [page, pageSize, searchText]);

  useEffect(() => {
    if (inputData.alertedUsers && inputData.alertedUsers.length > 0) {
      setSelectedTableKeys(
        inputData.alertedUsers.map((user: IUser) => user.id) || []
      );
    }
  }, [isModalOpen]);

  const onChangePagination = (changedPage: number, changedPageSize: number) => {
    if (changedPage !== page) {
      setPage(changedPage);
    } else if (changedPageSize !== pageSize) {
      setPageSize(changedPageSize);
    }
  };

  const onSubmit = () => {
    let alertedUsers: IUser[] = [];

    selectedTableKey.forEach((indicator: number) => {
      alertedUsers.push(AllUsers.filter((user) => user.id === indicator)[0]);
    });

    let reqDataCopy = { ...inputData };
    alertedUsers.forEach((user) => {
      if (user) {
        if (
          reqDataCopy.alertedUsers?.filter((u: IUser) => u.id === user.id)
            .length === 0
        ) {
          reqDataCopy.alertedUsers.push(user);
        } else if (!reqDataCopy.alertedUsers) {
          reqDataCopy["alertedUsers"] = [];
          reqDataCopy["alertedUsers"].push(user);
        }
      }
    });
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    updateContentInDb(contentId, dataToUpdate);
    setInputData({
      ...inputData,
      alertedUsers: reqDataCopy.alertedUsers,
    });
    setIsModalOpen(false);
  };

  const onSelectTeam = () => (selectedTeams: any) => {
    let reqDataCopy = { ...inputData };
    const NewAddedTeam = selectedTeams.filter(
      (team: any) => reqDataCopy.selectedTeams.includes(team) === false
    );

    if (NewAddedTeam.length > 0) {
      fetchCompanyUsers(1, 100, { team_id: NewAddedTeam[0] }).then(
        ({ users }) => {
          reqDataCopy.selectedTeams = selectedTeams;
          for (let i = 0; i < users.length; i++) {
            if (
              reqDataCopy.alertedUsers?.filter((u: any) => u.id === users[i].id)
                .length === 0
            ) {
              reqDataCopy.alertedUsers.push(users[i]);
            } else if (!reqDataCopy.alertedUsers) {
              reqDataCopy["alertedUsers"] = [];
              reqDataCopy["alertedUsers"].push(users[i]);
            }
          }

          setInputData({
            ...inputData,
            selectedTeams: reqDataCopy.selectedTeams,
            alertedUsers: reqDataCopy.alertedUsers,
          });
          const dataToUpdate = {
            content: {
              content: reqDataCopy,
            },
          };
          updateContentInDb(contentId, dataToUpdate);
        }
      );
    } else {
      const removedTeams = reqDataCopy.selectedTeams.filter(
        (team: any) => selectedTeams.includes(team) === false
      );
      for (let i = 0; i < removedTeams.length; i++) {
        fetchCompanyUsers(1, 100, {
          team_id: removedTeams[i],
        }).then(({ users }) => {
          for (let j = 0; j < users.length; j++) {
            reqDataCopy.alertedUsers = reqDataCopy.alertedUsers.filter(
              (user: any) => user.id !== users[j].id
            );
          }

          reqDataCopy.selectedTeams = selectedTeams;
          setInputData({
            ...inputData,
            selectedTeams: reqDataCopy.selectedTeams,
            alertedUsers: reqDataCopy.alertedUsers,
          });
          const dataToUpdate = {
            content: {
              content: reqDataCopy,
            },
          };
          updateContentInDb(contentId, dataToUpdate);
        });
      }
    }
  };

  return (
    <>
      {inputData ? (
        <Modal
          width={1000}
          open={isModalOpen}
          // onOk={() => {
          //   setIsModalOpen(false);
          // }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          footer={[
            <Button
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              {t("button.cancel")}
            </Button>,
          ]}
        >
          <h2>{t("defineAlertedUsers.assignedUsers")}</h2>
          <List
            style={{ margin: "0", padding: "0" }}
            className={styles.listDocument}
            header={
              <div className={styles.listHeaderOrganization}>
                <h4 className={styles.listHeaderOrganizationText}>
                  {t("defineAlertedUsers.usersList")}
                </h4>
              </div>
            }
            dataSource={inputData.alertedUsers}
            renderItem={(item: any) => (
              <List.Item className={styles.listItemOrganization}>
                <Checkbox checked={true}></Checkbox>
                <p style={{ wordBreak: "break-word" }}>
                  {item.first_name + " " + item.last_name}{" "}
                </p>
              </List.Item>
            )}
          />
          <div
            style={{
              marginTop: "2rem",
            }}
          >
            <h2> {t("doc.models.edit.sections.blocks.teamSelection")}</h2>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <DropdownMultiSelection // TODO replace by teams dropdown component
                allItems={companyTeams}
                selectedItems={inputData.selectedTeams}
                onSelectItem={onSelectTeam()}
                onSelectAll={(items) => {
                  inputData.selectedTeams = items;
                  setInputData(inputData);
                  setTableDataListPagination({
                    page: 1,
                    pageSize: 10,
                    total: 0,
                  });
                }}
                onClear={() => {
                  inputData.selectedTeams = [];
                  inputData.alertedUsers = [];
                  setInputData(inputData);
                  setTableDataListPagination({
                    page: 1,
                    pageSize: 10,
                    total: 0,
                  });
                }}
                isSelectAllButtonVisible={true}
                styleDropdown={{ minWidth: "50vh" }}
                placeholder={t("dropdownMultiSelection.selectTeam")}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "2rem",
              borderTop: "1px solid #e8e8e8",
              paddingTop: "1rem",
            }}
          >
            <h2> {t("doc.models.edit.sections.blocks.userSelection")}</h2>
            <Search
              style={{ maxWidth: "30%" }}
              placeholder={t("menu.search")}
              onSearch={onSearch}
            />
            <UsersTable
              loading={isFetchingUsers}
              AllUsers={paginatedUsers}
              paginationDetails={tableDataListPagination}
              onChangePagination={onChangePagination}
              setSelectedTableKeys={setSelectedTableKeys}
              selectedKeys={selectedTableKey}
            />
            <div
              style={{
                display: "flex",
                margin: "1.5rem",
                justifyContent: "center",
              }}
            >
              {selectedTableKey.length > 0 && (
                <Button onClick={onSubmit} type="primary">
                  {t("organisation.assign")}
                </Button>
              )}
            </div>
          </div>
        </Modal>
      ) : (
        <Skeleton active />
      )}
    </>
  );
}
