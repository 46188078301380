import { ReactElement } from "react";
import AvatarComponent from "../AvatarComponent/AvatarComponent";

interface IValidator {
  user: {
    id: number;
    first_name: string;
    last_name: string;
    avatar: {
      media_content_uid: string;
      location: string;
      type: string;
      name: string;
      metadata: string;
      company_id: number;
      company_bu_id: number;
    };
  };
  validated_on: string;
  status: "PENDING" | "VALIDATED" | "REFUSED";
}

interface IValidators {
  validators: IValidator[];
}

export default function ValidatorsAvatar({
  validators,
}: IValidators): ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {validators.map((validator, index) => {
        if (validator.status === "PENDING") {
          return (
            <AvatarComponent
              src={validator?.user?.avatar?.media_content_uid}
              style={{ border: "#BFBFBF 2px solid", margin: "0 1rem" }}
              firstName={validator.user.first_name}
              lastName={validator.user.last_name}
              key={index}
            />
          );
        }
        if (validator.status === "VALIDATED") {
          return (
            <AvatarComponent
              src={validator?.user?.avatar?.media_content_uid}
              style={{ border: "#96D970 2px solid", margin: "0 1rem" }}
              firstName={validator.user.first_name}
              lastName={validator.user.last_name}
              key={index}
            />
          );
        }
        if (validator.status === "REFUSED") {
          return (
            <AvatarComponent
              src={validator?.user?.avatar?.media_content_uid}
              style={{ border: "#BE0613 2px solid", margin: "0 1rem" }}
              firstName={validator.user.first_name}
              lastName={validator.user.last_name}
              key={index}
            />
          );
        }
        return <></>;
      })}
    </div>
  );
}
