import { useTranslation } from "react-i18next";
import HeaderMenuTabItem from "./HeaderMenuTabItem";
import HeaderMenuTabResizeWrapper from "./HeaderMenuTabResizeWrapper";

export default function HeaderMenuTabSettings() {
  const { t } = useTranslation();
  return (
    <HeaderMenuTabResizeWrapper>
      <HeaderMenuTabItem
        label={t("topHeader.myaccount")}
        to={"/account/settings/myaccount"}
      />
      <HeaderMenuTabItem
        label={t("company.companyProfile")}
        to={"/account/settings/company"}
      />
      <HeaderMenuTabItem
        label={t("company.companyDepartments")}
        to={"/account/settings/departments"}
      />
      <HeaderMenuTabItem
        label={t("team.teamsProfile")}
        to={"/account/settings/teams"}
      />
      <HeaderMenuTabItem
        label={t("company.users")}
        to={"/account/settings/users"}
      />
      <HeaderMenuTabItem
        label={t("menu.advancedAnalysis")}
        to={"/account/settings/analysis"}
      />
      {/* <HeaderMenuTabItem
        label={t("settings.configuration")}
        to={"/account/settings/configuration"}
      /> */}
    </HeaderMenuTabResizeWrapper>
  );
}
