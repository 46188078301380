import styles from "./MagicListItem.module.scss";
import { useEffect, useState } from "react";
import { useMagicDispatch } from "./MagicContext";
import { fetchSingleDocumentModelTanstack } from "../../app/stores/documents/documentsClient";
import MagicListItemSideUtils from "./MagicListItemSideUtils";
import { DocumentType } from "app/stores/documents/types";
import MagicDocumentModelListItemBody from "./MagicBody/MagicDocumentModelListItemBody";

import { matchPath, useLocation } from "react-router-dom";
import { useDocumentModels } from "components/DocumentModels/Providers/DocumentModelsContext";
import { useAddLinkageNode } from "pages/ModelLinkageLayout/AddLinkageNode/Provider/AddLinkageNodeContext";
import { ApiMetabaseDashboard } from "app/stores/metabase/types";

export default function MagicDocumentModelListItem({
  documentModel,
  metabaseDashboard,
  isLoading = false,
  type = "models",
  isCurrentUserSuperAdmin = true,
  readOnly = false,
  onOpenModal,
  displayActionSide = true,
}: {
  documentModel?: DocumentType;
  metabaseDashboard?: ApiMetabaseDashboard;
  isLoading?: boolean;
  type?: "models" | "analysis";
  isCurrentUserSuperAdmin?: boolean;
  readOnly?: boolean;
  onOpenModal?: (
    id: string | number,
    type: "openModal" | "deleteDashboard" | "changeActive" | "deleteModel"
  ) => void;
  displayActionSide?: boolean;
}) {
  const [displayDetails, setDisplayDetails] = useState<boolean>(false);
  const dispatchMagic = useMagicDispatch();
  const location = useLocation();
  const [hasCheckbox, setHasCheckbox] = useState<boolean>(false);
  const documentModels = useDocumentModels();
  const addLinkageNode = useAddLinkageNode();

  const { documents } =
    type === "models" && documentModel !== undefined
      ? fetchSingleDocumentModelTanstack(documentModel.id, {
          magic: true,
        })
      : { documents: undefined };

  useEffect(() => {
    dispatchMagic({ type: "init", documentData: documentModel });
    if (documents !== undefined)
      dispatchMagic({ type: "initSingle", documentDataSingle: documents });
  }, [documentModel, dispatchMagic, documents]);

  const [animationState, setAnimationState] = useState<
    "Init" | "dispalyView" | "displayHide"
  >("Init");

  useEffect(() => {
    if (matchPath(`/account/plan/linkage`, location.pathname) !== null) {
      addLinkageNode.documentModels.length <= 0
        ? setHasCheckbox(false)
        : setHasCheckbox(true);
    } else if (
      matchPath(`/account/knowledge/models/content`, location.pathname) !==
        null ||
      matchPath(`/account/knowledge/models/organisation`, location.pathname) !==
        null ||
      matchPath(`/account/knowledge/models/sets`, location.pathname) !== null
    ) {
      setHasCheckbox(true);
    }
  }, [location.pathname]);
  // The double div nested is needed for the tricks to animate to height auto
  return (
    <div
      className={[
        styles.magicListItemWrapper,
        animationState === "Init" && styles.init,
        animationState === "dispalyView" && styles.displayView,
        animationState === "displayHide" && styles.displayHide,
        documentModels.slimView && styles.magicListItemLiteVersion,
      ]
        .filter(Boolean)
        .join(" ")}
    >
      <div
        className={[
          styles.magicListItemNested,
          displayDetails ? styles.full : styles.short,
          animationState === "Init" && styles.init,
          animationState === "dispalyView" && styles.displayView,
          animationState === "displayHide" && styles.displayHide,
          documentModels.slimView && styles.magicListItemLiteVersion,
        ]
          .filter(Boolean)
          .join(" ")}
      >
        <MagicListItemSideUtils
          lite={documentModels.slimView}
          isLoading={isLoading}
          checkbox={hasCheckbox}
        />
        <MagicDocumentModelListItemBody
          displayDetails={displayDetails}
          setDisplayDetails={setDisplayDetails}
          setAnimationState={setAnimationState}
          documentModel={documentModel}
          metabaseDashboard={metabaseDashboard}
          isLoading={isLoading}
          lite={documentModels.slimView}
          isCurrentUserSuperAdmin={isCurrentUserSuperAdmin}
          type={type}
          readOnly={readOnly}
          onOpenModal={onOpenModal}
          displayActionSide={displayActionSide}
        />
      </div>
    </div>
  );
}
