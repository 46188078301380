import styles from "./MediaSelector.module.scss";
import MediaSelectorImage from "./MediaSelectorImage";
import MediaSelectorAudio from "./MediaSelectorAudio";
import MediaSelectorVideo from "./MediaSelectorVideo";
import MediaSelectorFile from "./MediaSelectorFile";
import MediaSelectorDropDown from "./MediaSelectorDropDown";
import {
  deleteSingleContentTanstack,
  fetchBlobObject,
} from "app/stores/content/contentClient";
import { ContentType } from "app/stores/content/type";
import { Spin } from "antd";
import { GlobalStore } from "@armtekfrance/webgate/dist";

export default function MediaSelector({
  content,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  thumbnail,
  block_id,
  block_type,
  downloadable,
  editable,
  onDelete,
}: {
  content: ContentType;
  thumbnail: boolean;
  block_id: string;
  block_type: "image" | "video" | "audio" | "file";
  downloadable?: boolean;
  editable?: boolean;
  onDelete?: () => void;
}) {
  const mutation = deleteSingleContentTanstack();

  const handleDeleteSingleContent = () => {
    if (onDelete) {
      onDelete();
    } else {
      mutation.mutate({
        contentId: content.id.toString() ?? "",
        blockId: block_id,
      });
    }
  };

  // TODO Refactor
  const downloadContent = async () => {
    try {
      if (content.media_content?.media_content_uid) {
        const imageToDownload = await fetchBlobObject(
          content.media_content?.media_content_uid,
          false
        );
        const contentType = imageToDownload.media.ContentType;
        const byteCharacters = atob(imageToDownload.media.file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const data = new Blob([byteArray], { type: contentType });
        const imageUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = `${content.media_content?.name ?? ""}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading content:", error);
    }
  };

  if (content.media_content === null) return null;

  return (
    <div className={styles.mediaSelectorWrapper}>
      {mutation.isLoading ||
      GlobalStore.media.getLinkContentToBlockTanstack()?.isLoading ? (
        <div className={styles.spinnerWrapper}>
          <Spin />
        </div>
      ) : (
        <>
          {block_type === "image" && (
            <MediaSelectorImage
              imageItem={content.media_content}
              convertToBase64={true}
              dropDownComponent={
                <MediaSelectorDropDown
                  onEdit={
                    editable
                      ? () => {
                          GlobalStore.media.setImageEditorModalStatus({
                            isOpen: true,
                            media: content.media_content,
                            media_content_uid:
                              content.media_content?.media_content_uid ?? "",
                            contentId: content.id,
                          });
                        }
                      : undefined
                  }
                  onClickDownload={downloadable ? downloadContent : undefined}
                  onDelete={handleDeleteSingleContent}
                />
              }
            />
          )}
          {block_type === "audio" && (
            <MediaSelectorAudio
              mediaItem={content.media_content}
              dropDownComponent={
                <MediaSelectorDropDown
                  onClickDownload={downloadContent}
                  onDelete={handleDeleteSingleContent}
                />
              }
            />
          )}
          {block_type === "video" && (
            <MediaSelectorVideo
              videoItem={content.media_content}
              convertToBase64={true}
              dropDownComponent={
                <MediaSelectorDropDown
                  onClickDownload={downloadContent}
                  onDelete={handleDeleteSingleContent}
                />
              }
            />
          )}
          {block_type === "file" && (
            <MediaSelectorFile
              fileItem={content.media_content}
              dropDownComponent={
                <MediaSelectorDropDown
                  onClickDownload={downloadContent}
                  onDelete={handleDeleteSingleContent}
                />
              }
            />
          )}
        </>
      )}
    </div>
  );
}
