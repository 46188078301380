import { Col, InputNumber, Radio, RadioChangeEvent, Row, Space } from "antd";

import {
  calculateIntervalForMargin,
  countDecimal,
  generateRegex,
  updateRegexForDecimal,
} from "./regexGenerationUtil";
import { useTranslation } from "react-i18next";

interface ConditionComponentType {
  updateContentInDb: (id: string | number, data: any) => Promise<void>;
  disableEdit?: boolean;
  contentId: string | number;
  inputData: any;
  setInputData: (inputData: any) => void;
  setIsContentBeingSaved: (value: boolean) => void;
}

export default function ConditionComponent({
  updateContentInDb,
  disableEdit,
  contentId,
  inputData,
  setInputData,
  setIsContentBeingSaved,
}: ConditionComponentType) {
  const { t } = useTranslation();

  let timeoutId: any;

  // Keeping this function just in case we need to escape regex characters in the future
  const escapeRegex = function (s: string) {
    // Commenting this cause it was causing issues with the regex
    // return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

    return s;
  };

  const onChangeCondition = (e: RadioChangeEvent) => {
    let updateData: any = {};
    updateData["conditionType"] = e.target.value;
    const copyOfData = { ...inputData, ...updateData };
    setInputData(copyOfData);
    clearTimeout(timeoutId);
    const dataToUpdate: any = {
      content: {
        content: copyOfData,
      },
    };
    setIsContentBeingSaved(true);
    updateContentInDb(contentId, dataToUpdate).then(() => {
      setIsContentBeingSaved(false);
    });
  };

  const onChangeValue = (e: any, conditionType: any, index: number) => {
    if (conditionType === "fixValue") {
      let updateData: any = {};
      updateData["conditionValue"] = e;
      updateData["inputConditionValues"] = inputData.inputConditionValues;
      updateData["inputConditionValues"][index] = e;
      let decimalPlace = countDecimal(e);
      if (inputData["type"] === "int" && decimalPlace > 0)
        updateData["type"] = "decimal";
      const copyOfData = { ...inputData, ...updateData };
      setInputData(copyOfData);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        // Runs 1 second (1000 ms) after the last change
        const dataToUpdate: any = {
          content: {
            content: copyOfData,
          },
        };
        setIsContentBeingSaved(true);
        updateContentInDb(contentId, dataToUpdate).then(() => {
          setIsContentBeingSaved(false);
        });
      }, 1000);
    } else if (conditionType === "interval") {
      let updateData: any = {};
      updateData["inputConditionValues"] = inputData.inputConditionValues;
      updateData["inputConditionValues"][index] = e;
      let decimalPlace = countDecimal(e);
      if (inputData["type"] === "int" && decimalPlace > 0)
        updateData["type"] = "decimal";

      //check decimal here and update the regex for decimal
      const decimalIndex0 = countDecimal(updateData.inputConditionValues[0]);
      const decimalIndex1 = countDecimal(updateData.inputConditionValues[1]);

      let regexGenerated: string | null;
      if (decimalIndex0 > 0 || decimalIndex1 > 0) {
        const regexInputValues =
          decimalIndex0 > decimalIndex1
            ? [
                updateData.inputConditionValues[0] *
                  Math.pow(10, decimalIndex0),
                updateData.inputConditionValues[1] *
                  Math.pow(10, decimalIndex0),
              ]
            : [
                updateData.inputConditionValues[0] *
                  Math.pow(10, decimalIndex1),
                updateData.inputConditionValues[1] *
                  Math.pow(10, decimalIndex1),
              ];
        regexGenerated = generateRegex(
          regexInputValues[0],
          regexInputValues[1]
        );
      } else {
        regexGenerated = generateRegex(
          updateData.inputConditionValues[0],
          updateData.inputConditionValues[1]
        );
      }

      let finalRegex: any;
      if (decimalIndex0 > 0 || decimalIndex1 > 0) {
        let decimalRegex = updateRegexForDecimal(
          regexGenerated,
          decimalIndex0 > decimalIndex1 ? decimalIndex0 : decimalIndex1
        );
        finalRegex = new RegExp(`^${escapeRegex(decimalRegex ?? "")}$`);
      } else {
        finalRegex = new RegExp(`^${escapeRegex(regexGenerated ?? "")}$`);
      }

      updateData["conditionValue"] = finalRegex + "";
      const copyOfData = { ...inputData, ...updateData };
      setInputData(copyOfData);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        // Runs 1 second (1000 ms) after the last change
        const dataToUpdate: any = {
          content: {
            content: copyOfData,
          },
        };
        setIsContentBeingSaved(true);
        updateContentInDb(contentId, dataToUpdate).then(() => {
          setIsContentBeingSaved(false);
        });
      }, 1000);
    } else if (conditionType === "margin") {
      let updateData: any = {};
      updateData["inputConditionValues"] = inputData.inputConditionValues;
      updateData["inputConditionValues"][index] = e;
      if (index === 1) updateData["inputConditionValues"][2] = 0;
      else if (index === 2) updateData["inputConditionValues"][1] = 0;
      const copyOfData = { ...inputData, ...updateData };
      setInputData(copyOfData);
      if (
        updateData["inputConditionValues"][0] !== 0 ||
        updateData["inputConditionValues"][1] !== 0 ||
        updateData["inputConditionValues"][2] !== 0
      ) {
        let intervalValues = [0, 0];
        if (index === 0) {
          let dataIndex = updateData["inputConditionValues"][2] !== 0 ? 2 : 1;
          intervalValues = calculateIntervalForMargin(
            updateData["inputConditionValues"][0],
            updateData["inputConditionValues"][dataIndex],
            dataIndex === 2 ? "PERCENTAGE" : "PLUSMINUS"
          );
        } else {
          intervalValues = calculateIntervalForMargin(
            updateData["inputConditionValues"][0],
            updateData["inputConditionValues"][index],
            index === 2 ? "PERCENTAGE" : "PLUSMINUS"
          );
        }
        intervalValues[0] = parseFloat(
          (Math.round(intervalValues[0] * 1000) / 1000).toFixed(3)
        );
        intervalValues[1] = parseFloat(
          (Math.round(intervalValues[1] * 1000) / 1000).toFixed(3)
        );

        //check decimal here and update the regex for decimal
        const decimalIndex0 = countDecimal(intervalValues[0]);
        const decimalIndex1 = countDecimal(intervalValues[1]);

        let regexGenerated: string | null;
        if (decimalIndex0 > 0 || decimalIndex1 > 0) {
          const regexInputValues =
            decimalIndex0 > decimalIndex1
              ? [
                  Math.floor(intervalValues[0] * Math.pow(10, decimalIndex0)),
                  Math.floor(intervalValues[1] * Math.pow(10, decimalIndex0)),
                ]
              : [
                  Math.floor(intervalValues[0] * Math.pow(10, decimalIndex1)),
                  Math.floor(intervalValues[1] * Math.pow(10, decimalIndex1)),
                ];
          regexGenerated = generateRegex(
            regexInputValues[0],
            regexInputValues[1]
          );
        } else {
          regexGenerated = generateRegex(intervalValues[0], intervalValues[1]);
        }

        let finalRegex: any;
        if (decimalIndex0 > 0 || decimalIndex1 > 0) {
          let decimalRegex = updateRegexForDecimal(
            regexGenerated,
            decimalIndex0 > decimalIndex1 ? decimalIndex0 : decimalIndex1
          );
          finalRegex = new RegExp(`^${escapeRegex(decimalRegex ?? "")}$`);
        } else {
          finalRegex = new RegExp(`^${escapeRegex(regexGenerated ?? "")}$`);
        }

        updateData["conditionValue"] = finalRegex + "";
        const copyOfData = { ...inputData, ...updateData };
        setInputData(copyOfData);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () {
          // Runs 1 second (1000 ms) after the last change
          const dataToUpdate: any = {
            content: {
              content: copyOfData,
            },
          };
          setIsContentBeingSaved(true);
          updateContentInDb(contentId, dataToUpdate).then(() => {
            setIsContentBeingSaved(false);
          });
        }, 1000);
      }
    }
  };

  return (
    <Row>
      <Col span={2}>Condition</Col>
      <Col span={22}>
        <Radio.Group
          style={{ width: "100%" }}
          onChange={onChangeCondition}
          value={inputData.conditionType}
          disabled={disableEdit}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="horizontal" style={{ width: "100%" }}>
              <Radio value={"interval"}>
                <Space size="large">
                  {t("dataBlock.interval")}
                  <InputNumber
                    placeholder="Min"
                    style={{ width: "80px" }}
                    step={inputData.type === "decimal" ? 0.5 : 1}
                    disabled={
                      inputData.conditionType !== "interval" || disableEdit
                    }
                    value={
                      inputData.conditionType === "interval"
                        ? inputData.inputConditionValues[0]
                        : ""
                    }
                    onChange={(e) => onChangeValue(e, "interval", 0)}
                    precision={3}
                  />
                  <InputNumber
                    placeholder="Max"
                    style={{ width: "80px" }}
                    step={inputData.type === "decimal" ? 0.5 : 1}
                    disabled={
                      inputData.conditionType !== "interval" || disableEdit
                    }
                    value={
                      inputData.conditionType === "interval"
                        ? inputData.inputConditionValues[1]
                        : ""
                    }
                    onChange={(e) => onChangeValue(e, "interval", 1)}
                    precision={3}
                  />
                </Space>
              </Radio>
            </Space>
            <Radio value={"margin"}>
              <Space size="large">
                {t("dataBlock.margin")}
                <InputNumber
                  placeholder="Value"
                  style={{ width: "80px" }}
                  step={inputData.type === "decimal" ? 0.5 : 1}
                  disabled={inputData.conditionType !== "margin" || disableEdit}
                  value={
                    inputData.conditionType === "margin"
                      ? inputData.inputConditionValues[0]
                      : ""
                  }
                  onChange={(e) => onChangeValue(e, "margin", 0)}
                  precision={3}
                />
                <InputNumber
                  addonBefore="+/-"
                  style={{ width: "120px" }}
                  step={inputData.type === "decimal" ? 0.5 : 1}
                  disabled={inputData.conditionType !== "margin" || disableEdit}
                  value={
                    inputData.conditionType === "margin"
                      ? inputData.inputConditionValues[1]
                      : ""
                  }
                  onChange={(e) => onChangeValue(e, "margin", 1)}
                  precision={3}
                />
                <InputNumber
                  addonBefore="%"
                  style={{ width: "120px" }}
                  step={inputData.type === "decimal" ? 0.5 : 1}
                  disabled={inputData.conditionType !== "margin" || disableEdit}
                  value={
                    inputData.conditionType === "margin"
                      ? inputData.inputConditionValues[2]
                      : " "
                  }
                  onChange={(e) => onChangeValue(e, "margin", 2)}
                  precision={3}
                />
              </Space>
            </Radio>
            <Radio value={"fixValue"}>
              <Space size="large">
                {t("dataBlock.fixValue")}
                <InputNumber
                  placeholder="Value"
                  style={{ width: "80px" }}
                  step={inputData.type === "decimal" ? 0.5 : 1}
                  disabled={
                    inputData.conditionType !== "fixValue" || disableEdit
                  }
                  value={
                    inputData.conditionType === "fixValue"
                      ? inputData.inputConditionValues[0]
                      : ""
                  }
                  onChange={(e) => onChangeValue(e, "fixValue", 0)}
                  precision={3}
                />
              </Space>
            </Radio>
          </Space>
        </Radio.Group>
      </Col>
    </Row>
  );
}
