import React, { useEffect } from "react";
import { useState } from "react";
import MagicDocumentModelListItem from "components/MagicListItem/MagicDocumentModelListItem";
import { message } from "antd";
import PaginationLayout from "components/PaginationLayout";

import {
  createMetabaseDashboard,
  deleteMetabaseDashboard,
  fetchMetabaseDashboards,
  updateMetabaseDashboard,
} from "app/stores/metabase/metabaseClient";
import {
  ApiGraphDisplayType,
  ApiGraphPrivacy,
  ApiMetabaseDashboard,
} from "app/stores/metabase/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AdvancedAnalysisPopUp from "components/AdvancedAnalysis/AdvancedAnalysisPopUp";
import { fetchUserInfo } from "../../app/stores/users/userClient";
import { IUser } from "../../app/stores/users/types";
import { Button } from "@armtekfrance/armtek-react-design-system";
import InputSearch from "components/InputSearch/InputSearch";
import { PaginationType } from "app/stores/pagination/types";

export default function AdvancedAnalysis({
  readOnly = false,
}: {
  readOnly: boolean;
}) {
  const { t } = useTranslation();

  const [isLoading, setisLoading] = useState(false);

  const [metabaseDashboards, setmetabaseDashboards] = useState<
    ApiMetabaseDashboard[]
  >([]);

  fetchUserInfo;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [dashboardUid, setDashboardUid] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [isModalToCreate, setisModalToCreate] = useState(false);
  const [isCurrentUserSuperAdmin, setIsCurrentUserSuperAdmin] = useState(false);
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const setPaginationPagePageSize = (page: number, pageSize: number) => {
    setPagination({
      page: page,
      pageSize: pageSize,
      total: pagination.total,
    });
  };
  useEffect(() => {
    setisLoading(true);
    fetchUserInfo().then((user: IUser) => {
      setIsCurrentUserSuperAdmin(user.permissions === 127 ? true : false);
    });

    fetchMetabaseDashboards(
      {
        ...(!readOnly && { activeAndInactive: true }),
        searchText: { contains: searchText },
      },
      pagination.page,
      pagination.pageSize
    ).then((res) => {
      setmetabaseDashboards(res.metabase.reverse());
      setPagination(res.pagination);
      setisLoading(false);
    });
  }, [searchText, pagination.page, pagination.pageSize]);
  const onOpenModal = (
    id: string | number,
    type: "openModal" | "deleteDashboard" | "changeActive" | "deleteModel"
  ) => {
    // Find the concerned dashboard
    const dashboard = metabaseDashboards.find(
      (dashboard) => dashboard.dashboard_uuid === id
    );

    if (dashboard === undefined) {
      return;
    }

    setDashboardUid(id.toString());
    if (type === "deleteDashboard") {
      setmetabaseDashboards(
        metabaseDashboards.filter(
          (SingleDashboard) =>
            SingleDashboard.dashboard_uuid !== dashboard.dashboard_uuid
        )
      );

      deleteMetabaseDashboard(dashboard.dashboard_uuid).then(() => {
        //
      });
    } else if (type === "openModal") {
      setisModalToCreate(false);
      setIsModalOpened(true);
      setCreatedDashboardName(dashboard.graph_name);
      setCreatedDashboardId(dashboard.graph_id.toString());
      setGraphPrivacy(dashboard.graph_privacy);
      setGraphMode(dashboard.graph_type);
      setIsExecutionGraph(dashboard.is_execution_dashboard);
    } else if (type === "changeActive") {
      setmetabaseDashboards(
        metabaseDashboards.map((SingleDashboard) => {
          if (
            SingleDashboard.dashboard_uuid === dashboard.dashboard_uuid &&
            SingleDashboard.active !== undefined
          ) {
            SingleDashboard.active = !SingleDashboard.active;
          }
          return SingleDashboard;
        })
      );
      updateMetabaseDashboard(dashboard.dashboard_uuid.toString(), {
        dashboard_uuid: dashboard.dashboard_uuid,
        graph_name: dashboard.graph_name,
        graph_id: dashboard.graph_id,
        graph_type: dashboard.graph_type,
        active: dashboard.active,
        is_execution_dashboard: dashboard.is_execution_dashboard,
      }).then(() => {
        //
      });
    }
  };

  const createDashboardMetabase = () => {
    createMetabaseDashboard({
      graph_name: createdDashboardName,
      graph_id: parseInt(createdDashboardId),
      graph_type: graphMode as ApiGraphDisplayType,
      graph_privacy: graphPrivacy as ApiGraphPrivacy,
      active: true,
      is_execution_dashboard: isExecutionGraph,
    }).then((resp) => {
      if (resp.success === false && resp.errorCode === 409) {
        message.error(t("advancedAnalysis.errorDashboardAlreadyExist"));
        return;
      }

      setisLoading(true);
      fetchMetabaseDashboards(
        {
          ...(!readOnly && { activeAndInactive: true }),
        },
        pagination.page,
        pagination.pageSize
      ).then((res) => {
        setmetabaseDashboards(res.metabase.reverse());
        setPagination(res.pagination);
        setisLoading(false);
      });
    });
  };

  const updateDashboardMetabase = () => {
    // Replace the dashboard with the new one

    let currentDashboard;
    for (let i = 0; i < metabaseDashboards.length; i++) {
      if (metabaseDashboards[i].dashboard_uuid === dashboardUid) {
        metabaseDashboards[i].graph_name = createdDashboardName;
        metabaseDashboards[i].graph_id = parseInt(createdDashboardId);
        metabaseDashboards[i].graph_type = graphMode as ApiGraphDisplayType;
        metabaseDashboards[i].graph_privacy = graphPrivacy as ApiGraphPrivacy;
        metabaseDashboards[i].is_execution_dashboard = isExecutionGraph;

        currentDashboard = metabaseDashboards[i];
      }
    }
    updateMetabaseDashboard(dashboardUid, {
      graph_name: createdDashboardName,
      graph_id: parseInt(createdDashboardId),
      graph_type: graphMode as ApiGraphDisplayType,
      graph_privacy: graphPrivacy as ApiGraphPrivacy,
      active: currentDashboard ? currentDashboard?.active : true,
      is_execution_dashboard: isExecutionGraph,
    }).then(() => {
      //
    });
  };

  const [createdDashboardName, setCreatedDashboardName] = useState("");
  const [graphPrivacy, setGraphPrivacy] = useState<string>("company");
  const [graphMode, setGraphMode] = useState<string>("dashboard");
  const [isExecutionGraph, setIsExecutionGraph] = useState(false);

  const [createdDashboardId, setCreatedDashboardId] = useState<string>("");

  const onSearchText = (text: string) => {
    setSearchText(text);
  };
  const defaultLoadingList = Array.from(Array(10).keys());

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "16px",
        }}
      >
        <div
          style={{
            flexGrow: 0.95,
            marginLeft: "32px",
          }}
        >
          <InputSearch
            placeholder={t("menu.search")}
            onSearch={(e) => {
              onSearchText(e);
            }}
          />
        </div>
        {!readOnly && (
          <div>
            {isCurrentUserSuperAdmin && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Link
                  style={{
                    marginRight: "16px",
                    textDecoration: "underline",
                  }}
                  target="_blank"
                  to="https://armtek-metabase.azurewebsites.net/"
                >
                  {t("advancedAnalysis.openMetabase")}
                </Link>

                <Button
                  onClick={() => {
                    setIsModalOpened(true);
                    setisModalToCreate(true);
                  }}
                  child={t("advancedAnalysis.createDashboard")}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {isLoading ? (
        <div>
          {defaultLoadingList.map((dashboard: {}) => (
            <div
              style={{
                margin: "16px",
              }}
            >
              <MagicDocumentModelListItem
                metabaseDashboard={dashboard as ApiMetabaseDashboard}
                isLoading={isLoading}
                type="analysis"
                isCurrentUserSuperAdmin={isCurrentUserSuperAdmin}
                readOnly={readOnly}
                onOpenModal={onOpenModal}
              />
            </div>
          ))}
        </div>
      ) : (
        <div>
          {metabaseDashboards.map((dashboard) => (
            <div
              style={{
                margin: "16px",
              }}
            >
              <MagicDocumentModelListItem
                metabaseDashboard={dashboard}
                isLoading={isLoading}
                isCurrentUserSuperAdmin={isCurrentUserSuperAdmin}
                type="analysis"
                readOnly={readOnly}
                displayActionSide={readOnly ? false : true}
                onOpenModal={onOpenModal}
              />
            </div>
          ))}
          <PaginationLayout
            page={pagination.page}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChangePagination={setPaginationPagePageSize}
          />
        </div>
      )}
      {isModalOpened && (
        <AdvancedAnalysisPopUp
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          createdDashboardName={createdDashboardName}
          setCreatedDashboardName={setCreatedDashboardName}
          createdDashboardId={createdDashboardId}
          setCreatedDashboardId={setCreatedDashboardId}
          graphPrivacy={graphPrivacy}
          setGraphPrivacy={setGraphPrivacy}
          graphMode={graphMode}
          setGraphMode={setGraphMode}
          isModalToCreate={isModalToCreate}
          setisModalToCreate={setisModalToCreate}
          createDashboardMetabase={createDashboardMetabase}
          updateDashboardMetabase={updateDashboardMetabase}
          setIsExecutionGraph={setIsExecutionGraph}
          isExecutionGraph={isExecutionGraph}
        />
      )}
    </>
  );
}
