import Request from "../../../libs/requests";

export const fetchCompanyNotificationPreferences = () => {
  return Request()
    .get(`/companies/events`)
    .then((response) => response.data);
};

export const updateCompanyNotificationPreferences = (preferenceData: any) => {
  return Request()
    .put(`/companies/events`, preferenceData)
    .then((response) => response.data);
};
