import { useTranslation } from "react-i18next";
import { useValidationStatusDispatch } from "../../Provider/ValidationStatusContext";
import UsersTeamsDropDown from "components/UsersTeamsDropDown/UsersTeamsDropDown";

export default function ValidationHeroDropDownAuthor() {
  const { t } = useTranslation();

  const dispatchValidationStatus = useValidationStatusDispatch();

  return (
    <UsersTeamsDropDown
      placeholder={t("validationTable.author")}
      onSelectedChange={(_items, itemsIds) => {
        dispatchValidationStatus({
          type: "setAuthors",
          authors: itemsIds,
        });
        return;
      }}
    />
  );
}
