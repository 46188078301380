import { Form, FormInstance, Row, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as TeamApi from "../../app/services/team/teamClient";
import EditCompanyTeamLoader from "pages/CompanyDepartmentsLayout/CompanyDepartments/Loader";
import AddTeamFormParameters from "./AddTeamFormParameters";
import {
  useAddTeamForm,
  useAddTeamFormDispatch,
} from "./Provider/AddTeamFormContext";
import AddteamFormTextFields from "./AddTeamFormTextFields/AddTeamFormTextFields";
import AddTeamFormUserSelectField from "./AddTeamFormUserSelectField/AddTeamFormUserSelectField";
import AddTeamFormButtons from "./AddTeamFormButtons/AddTeamFormButtons";

const { Title } = Typography;

export default function AddTeamForm({
  activeId,
  createFormRef,
  onOpenClose,
  messageApi,
  onCancel,
  onSave,
  displayUserSelect,
}: AddTeamFormParameters) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const addTeamFormContext = useAddTeamForm();
  const dispatchAddTeamForm = useAddTeamFormDispatch();

  const formRef = useRef<FormInstance>(null);

  useEffect(() => {
    openTeamForm(true, activeId);
  }, [activeId]);

  const openTeamForm = async (open: boolean, teamId?: number | null) => {
    dispatchAddTeamForm({
      type: "setAllSelectedUsers",
      value: [],
    });
    form.resetFields();
    if (teamId) {
      await fetchSingleTeam(teamId);
    }
    if (onOpenClose) {
      onOpenClose(open);
    }
    dispatchAddTeamForm({
      type: "resetSuggestedDropdown",
    });
  };

  // Fetch Single team
  const fetchSingleTeam = async (id: number) => {
    try {
      dispatchAddTeamForm({
        type: "setTeamLoading",
        teamLoading: true,
      });
      const Team = await TeamApi.fetchSingleTeam(id);
      if (Team.success) {
        form.setFieldsValue(Team.data.team);
      }
      dispatchAddTeamForm({
        type: "setTeamLoading",
        teamLoading: false,
      });

      const selectedUsers = Team.data.team.team_has_user.map(
        (user: any) => user
      );

      dispatchAddTeamForm({
        type: "setAllSelectedUsers",
        value: selectedUsers,
      });
    } catch (error) {
      console.error(error);
      dispatchAddTeamForm({
        type: "setTeamLoading",
        teamLoading: false,
      });
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("team.teamsNotFetched"),
      });
    }
  };

  // On form submit [Create / Edit] team
  const onFinish = async (values: any) => {
    let response;
    const action = !activeId ? "created" : "updated";

    try {
      dispatchAddTeamForm({
        type: "setFormLoading",
        formLoading: true,
      });
      if (values.hasOwnProperty.call(values, "Member")) {
        delete values["Member"];
      }
      if (values.hasOwnProperty.call(values, "Membres")) {
        delete values["Membres"];
      }
      if (activeId) {
        response = await TeamApi.updateTeam(activeId, values);
      } else {
        response = await TeamApi.createTeam(values);
      }
      if (response.success) {
        const teamId = action === "created" ? response.data : activeId;

        openTeamForm(false);
        form.resetFields();

        const previousSelectedUsers = addTeamFormContext.defaultSelectedUsers;
        const currentSelectedUsers = addTeamFormContext.selectedUsers;

        if (currentSelectedUsers.length > previousSelectedUsers.length) {
          await TeamApi.addUsersToTeam(teamId, currentSelectedUsers, "ADD");
        } else if (currentSelectedUsers.length < previousSelectedUsers.length) {
          const usersToRemove = previousSelectedUsers.filter(
            (user) => !currentSelectedUsers.includes(user)
          );
          await TeamApi.addUsersToTeam(teamId, usersToRemove, "REMOVE");
        }

        const sortPrevious = [...previousSelectedUsers].sort(
          (a, b) => a.valueOf() - b.valueOf()
        );
        const sortCurrent = [...currentSelectedUsers].sort(
          (a, b) => a.valueOf() - b.valueOf()
        );

        const differences: Number[] = [];

        const isSame = sortPrevious.every((el, i) => {
          if (
            sortCurrent[i] != undefined &&
            el.valueOf() !== sortCurrent[i].valueOf()
          ) {
            differences.push(sortPrevious[i]);
            differences.push(sortCurrent[i]);
            return false;
          }
          return true;
        });

        if (!isSame) {
          await TeamApi.addUsersToTeam(teamId, differences, "REMOVE");
          await TeamApi.addUsersToTeam(teamId, currentSelectedUsers, "ADD");
        }

        if (onSave) {
          onSave();
        }

        messageApi.open({
          type: "success",
          duration: 5,
          content: `${t("team.hasBeen")} ${t(`departments.${action}`)} ${t(
            "departments.successfully"
          )}`,
        });
      }
      dispatchAddTeamForm({
        type: "setFormLoading",
        formLoading: false,
      });
    } catch (error) {
      console.error(error);
      dispatchAddTeamForm({
        type: "setFormLoading",
        formLoading: false,
      });
      messageApi.open({
        type: "error",
        duration: 5,
        content: `${t("team.teamError")} ${t(`departments.${action}`)} !`,
      });
    }
  };

  return (
    <>
      <div className="shadow p-3 rounded mb-5" ref={createFormRef}>
        <Title level={5}>
          {activeId
            ? `${t("team.editTeamWithId")} : #${activeId}`
            : t("team.addTeam")}
        </Title>
        {addTeamFormContext.teamLoading ? (
          <EditCompanyTeamLoader />
        ) : (
          <Form
            ref={formRef}
            name="teamForm"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            requiredMark
          >
            <Row gutter={16}>
              <AddteamFormTextFields
                formRef={formRef}
                messageApi={messageApi}
              />
              {displayUserSelect && (
                <AddTeamFormUserSelectField messageApi={messageApi} />
              )}
              <AddTeamFormButtons
                openTeamForm={openTeamForm}
                onCancel={onCancel}
                formRef={formRef}
              />
            </Row>
          </Form>
        )}
      </div>
    </>
  );
}
