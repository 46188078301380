import Comments from "./Comments";
import CommentsProvider from "./Provider/CommentsProvider";

export default function CommentsRoot({
  document_model_id,
  sectionIdHovered,
  sectionNameInfos,
}: {
  document_model_id: number;
  sectionIdHovered: (sectionId: number | null) => void;
  sectionNameInfos:
    | { sectionId: number; sectionName: string; sectionPosition: number }[]
    | null;
}) {
  return (
    <CommentsProvider>
      <Comments
        document_model_id={document_model_id}
        sectionIdHovered={sectionIdHovered}
        sectionNameInfos={sectionNameInfos}
      />
    </CommentsProvider>
  );
}
