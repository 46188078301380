export function checkIfWinningBuffer(
  buffer: Int32Array,
  lastIndexEntered: number
) {
  let checkPoint1Index = 0;
  let validCheckPoint1 = false;

  for (let i = 0; i < lastIndexEntered; i = i + 2) {
    if (checkPoint1(buffer[i], buffer[i + 1])) {
      validCheckPoint1 = true;
      checkPoint1Index = i;
      break;
    }
  }
  let validCheckPoint2 = false;
  let checkPoint2Index = 0;

  if (validCheckPoint1) {
    for (let j = checkPoint1Index; j < lastIndexEntered; j = j + 2) {
      if (checkPoint2(buffer[j], buffer[j + 1])) {
        validCheckPoint2 = true;
        checkPoint2Index = j;
        break;
      }
    }
  }

  let validCheckPoint3 = false;

  if (validCheckPoint2 === true) {
    for (let k = checkPoint2Index + 2; k < lastIndexEntered; k = k + 2) {
      if (checkPoint3(buffer[k], buffer[k + 1])) {
        validCheckPoint3 = true;
        break;
      }
    }
  }

  if (
    validCheckPoint1 === true &&
    validCheckPoint2 === true &&
    validCheckPoint3 === true
  ) {
    return true;
  }
  return false;
}

function checkPoint1(x: number, y: number) {
  return y > 166 && y < 174 && x > 0 && x < 35;
}

function checkPoint2(x: number, y: number) {
  return y < 104 && y > 96 && x > 0 && x < 70;
}

function checkPoint3(x: number, y: number) {
  return y > 166 && y < 174 && x < 200 && x > 170;
}
