import { QueryStatus } from "@tanstack/react-query";
import { PaginationType } from "app/stores/pagination/types";
import { Comment } from "app/stores/documentComments/types";

export type stateCommentsProvider = {
  statusComments: QueryStatus;
  errorComments: unknown;
  comments: Comment[] | undefined;
  commentsWithReplies: (Comment & { replies: Comment[] })[] | null;
  paginationComments: PaginationType | undefined;
  sectionIdHovered: number | null;
};

export type TypeCommentsContextReducerAction =
  | {
      type: "setCommentsTanstackApi";
      setCommentsTanstackApi: {
        statusComments: stateCommentsProvider["statusComments"];
        errorComments: stateCommentsProvider["errorComments"];
      };
    }
  | {
      type: "setCommentsTanstack";
      setCommentsTanstack: {
        comments: stateCommentsProvider["comments"];
        paginationComments: stateCommentsProvider["paginationComments"];
      };
    }
  | {
      type: "setSectionIdHovered";
      sectionIdHovered: stateCommentsProvider["sectionIdHovered"];
    };

export function commentsReducer(
  stateComments: stateCommentsProvider,
  action: TypeCommentsContextReducerAction
): stateCommentsProvider {
  switch (action.type) {
    case "setCommentsTanstackApi": {
      return {
        ...stateComments,
        statusComments: action.setCommentsTanstackApi.statusComments,
        errorComments: action.setCommentsTanstackApi.errorComments,
      };
    }
    case "setCommentsTanstack": {
      const arrayOfParentComments: (Comment & { replies: Comment[] })[] = [];

      if (!action.setCommentsTanstack.comments) return stateComments;
      for (let i = 0; i < action.setCommentsTanstack.comments.length; i++) {
        if (action.setCommentsTanstack.comments[i].parent_id === null) {
          arrayOfParentComments.push({
            ...action.setCommentsTanstack.comments[i],
            replies: [],
          });
        }
      }

      for (let i = 0; i < action.setCommentsTanstack.comments.length; i++) {
        if (
          action.setCommentsTanstack !== null &&
          action.setCommentsTanstack.comments[i].parent_id !== null
        ) {
          let parent = arrayOfParentComments.find((parentComment) => {
            if (action.setCommentsTanstack.comments === undefined) return false;
            return (
              parentComment.id ===
              action.setCommentsTanstack.comments[i].parent_id
            );
          });
          parent?.replies.push(action.setCommentsTanstack.comments[i]);
        }
      }

      return {
        ...stateComments,
        comments: action.setCommentsTanstack.comments,
        paginationComments: action.setCommentsTanstack.paginationComments,
        commentsWithReplies: arrayOfParentComments,
      };
    }
    case "setSectionIdHovered": {
      return {
        ...stateComments,
        sectionIdHovered: action.sectionIdHovered,
      };
    }
    default: {
      return { ...stateComments };
    }
  }
}
