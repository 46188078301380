import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { PlusCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Card from "antd/lib/card/Card";
import CheckboxLayout from "./CheckboxLayout";
import { Switch, Row, Col } from "antd";

interface QuizType {
  data: ListType;
  disableEdit?: boolean;
  contentId: number | string;
  updateContentInDb: (id: string | number, data: any) => void;
  deleteContentInDb: (contentId: string | number) => void;
  setIsContentBeingSaved: (data: boolean) => void;
}

interface ListType {
  question: string;
  options: OptionType[];
  mandatory: boolean;
  success_message: string;
  fail_message: string;
}

interface OptionType {
  key: number;
  value: string;
  correct: boolean;
}

export default function Quiz({
  data,
  disableEdit,
  contentId,
  updateContentInDb,
  setIsContentBeingSaved,
}: QuizType) {
  const { t } = useTranslation();
  const [quizData, setQuizData] = useState<ListType>(
    data
      ? data
      : {
          question: "",
          options: [{ key: 1, value: "", correct: false }],
          mandatory: false,
          success_message: "",
          fail_message: "",
        }
  );
  const [checklistDataPending, setChecklistDataPending] = useState(false);
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);

  useEffect(() => {
    setQuizData(
      data
        ? data
        : {
            question: "",
            options: [{ key: 1, value: "", correct: false }],
            mandatory: false,
            success_message: "",
            fail_message: "",
          }
    );
  }, [data]);

  const addChoice = () => {
    setChecklistDataPending(true);
    let listCopy = { ...quizData };
    const dataToAdd = {
      key: quizData.options ? quizData.options.length + 1 : 1,
      value: "",
      correct: false,
    };
    if (listCopy.options) {
      listCopy.options.push(dataToAdd);
    } else {
      listCopy["options"] = [];
      listCopy.options.push(dataToAdd);
    }
    setQuizData(listCopy);
    setChecklistDataPending(false);
    onDataChange(listCopy);
  };

  const removeChoice = (index: number) => {
    const listCopy = { ...quizData };
    listCopy.options.splice(index, 1);
    const updatedKeys = listCopy.options.map((item, index) => {
      return { key: index + 1, value: item.value, correct: item.correct };
    });
    listCopy.options = updatedKeys;
    setQuizData(listCopy);
    onDataChange(listCopy);
  };

  const onDataChange = (updateValue: any) => {
    const dataToUpdate = {
      content: { content: updateValue },
    };
    updateContentInDb(contentId, dataToUpdate);
  };

  let timeoutTitleId: any;
  const onChangeTitle = (e: any) => {
    setIsContentBeingSaved(true);
    const listCopy = { ...quizData };
    if (listCopy.question) {
      listCopy.question = e.target.value;
    } else {
      listCopy["question"] = e.target.value;
    }
    clearTimeout(timeoutTitleId);
    timeoutTitleId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: listCopy,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
    setQuizData(listCopy);
  };

  const onChangeSuccess = (e: any) => {
    setIsContentBeingSaved(true);
    const listCopy = { ...quizData };
    if (listCopy.success_message) {
      listCopy.success_message = e.target.value;
    } else {
      listCopy["success_message"] = e.target.value;
    }
    clearTimeout(timeoutTitleId);
    timeoutTitleId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: listCopy,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
    setQuizData(listCopy);
  };
  const onChangeFail = (e: any) => {
    setIsContentBeingSaved(true);
    const listCopy = { ...quizData };
    if (listCopy.fail_message) {
      listCopy.fail_message = e.target.value;
    } else {
      listCopy["fail_message"] = e.target.value;
    }
    clearTimeout(timeoutTitleId);
    timeoutTitleId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: listCopy,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
    setQuizData(listCopy);
  };

  let timeoutOption: any;
  const onChangeOptionValue = (index: number, e: any) => {
    setIsContentBeingSaved(true);
    const listCopy = { ...quizData };
    listCopy.options[index].value = e.target.value;
    setQuizData(listCopy);
    clearTimeout(timeoutOption);
    timeoutOption = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: listCopy,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const correctAnswer = (fieldData: any, index: any) => {
    let listCopy = { ...quizData };
    listCopy.options[index].correct = fieldData.target.checked;
    const dataToUpdate = {
      content: {
        content: listCopy,
      },
    };
    setQuizData(listCopy);
    updateContentInDb(contentId, dataToUpdate);
    let trueOptions = quizData.options?.filter((item: any) => item.correct);
    if (trueOptions?.length > 0) {
      setIsPopOverVisible(false);
    } else {
      setIsPopOverVisible(true);
    }
  };

  const changeMandatoryField = (fieldData: any) => {
    const dataToUpdate = {
      content: { content: { ...quizData, mandatory: fieldData } },
    };
    updateContentInDb(contentId, dataToUpdate);
    setQuizData((oldData: any) => {
      return {
        ...oldData,
        mandatory: fieldData,
      };
    });
  };

  useEffect(() => {
    let trueOptions = quizData.options?.filter((item: any) => item.correct);
    if (trueOptions?.length > 0) {
      setIsPopOverVisible(false);
    } else {
      setIsPopOverVisible(true);
    }
  }, [quizData.options]);

  return (
    <Card
      style={{
        backgroundColor: "#F6F9FB",
      }}
      bodyStyle={{ padding: "8px" }}
    >
      <Row>
        <Col flex="auto">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <p
              style={{
                color: "#030852",
                fontWeight: "600",
                marginLeft: "8px",
              }}
            >
              {t("doc.models.edit.sections.blocks.mandatoryQuiz")}
            </p>
            <Switch
              style={{ marginLeft: "8px" }}
              checked={quizData?.mandatory}
              disabled={disableEdit}
              onChange={changeMandatoryField}
            />
          </div>
        </Col>
        <Col style={{ display: "flex", flexWrap: "wrap" }}>
          {isPopOverVisible ? (
            <div
              style={{
                height: "40px",
                padding: "0px",
                backgroundColor: "white",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
            >
              <p style={{ margin: "0px" }}>
                <span style={{ fontWeight: "700" }}>
                  {t("quiz.information")}
                </span>{" "}
                {t("doc.models.edit.sections.blocks.quizHelp")}
              </p>
            </div>
          ) : (
            <></>
          )}
          <InfoCircleOutlined
            onClick={() => setIsPopOverVisible(!isPopOverVisible)}
            style={{
              fontSize: "30px",
              color: "#030852",
              marginLeft: "10px",
              marginTop: "5px",
            }}
          />
        </Col>
      </Row>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "8  px" }}>
        <div style={{ width: "50%", minWidth: "516px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginLeft: "10px",
            }}
          >
            <div style={{ fontWeight: "600" }}>Question :</div>
            <TextArea
              placeholder={t("doc.models.edit.sections.blocks.quizInput")}
              name="question"
              autoSize
              bordered={false}
              style={{
                fontWeight: "bold",
                width: "35vw",
                paddingLeft: "4px",
                marginTop: "-4px",
                marginBottom: "10px",
              }}
              value={quizData.question}
              onChange={onChangeTitle}
              disabled={disableEdit}
            />
          </div>
          {checklistDataPending ? (
            <div></div>
          ) : (
            <CheckboxLayout
              removeChoice={removeChoice}
              readOnly={disableEdit}
              data={quizData}
              onChangeOptionValue={onChangeOptionValue}
              correctAnswer={correctAnswer}
            />
          )}

          {!disableEdit ? (
            <PlusCircleOutlined
              style={{
                marginLeft: "4px",
                cursor: "pointer",
                color: "#030852",
                fontSize: "24px",
              }}
              onClick={() => addChoice()}
            />
          ) : (
            <></>
          )}
        </div>

        <div style={{ width: "30%", minWidth: "390px" }}>
          <p style={{ fontWeight: "bold" }}>
            {t("doc.models.edit.sections.blocks.messageAfterTheAnswer")} :
          </p>
          <TextArea
            placeholder={t("doc.models.edit.sections.blocks.successMessage")}
            name="successMessage"
            autoSize
            bordered={false}
            onChange={onChangeSuccess}
            value={quizData.success_message}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          />

          <TextArea
            placeholder={t("doc.models.edit.sections.blocks.failMessage")}
            name="failMessage"
            autoSize
            bordered={false}
            onChange={onChangeFail}
            value={quizData.fail_message}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    </Card>
  );
}
