import { useTranslation } from "react-i18next";
import { Card } from "antd";
import styles from "./UpdateCard.module.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { featureListTabEnglish, featureListTabFrench } from "./featureListTab";

function LoadedFeature({ feature }: { feature: string }): JSX.Element {
  if (feature) {
    return (
      <li>
        <p>{feature}</p>
      </li>
    );
  }
  return <></>;
}

function FeatureList() {
  const { t } = useTranslation();
  const featureTab =
    t("updateContent.features") === "french"
      ? featureListTabFrench
      : featureListTabEnglish;

  return (
    <ul className={styles.featureList}>
      {featureTab.map((feature, index) => (
        <LoadedFeature key={index} feature={feature} />
      ))}
    </ul>
  );
}

export default function UpdateCard() {
  const { t } = useTranslation();

  return (
    <Card className={styles.updateCardWrapper}>
      <div className={styles.iconBox}>
        <div className={styles.iconImageBox}>
          <ExclamationCircleFilled className={styles.iconImage} />
        </div>
        <div style={{ color: "#030852" }}>{t("updateContent.title")}</div>
      </div>
      <FeatureList />
    </Card>
  );
}
