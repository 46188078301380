import { useEffect, useRef } from "react";
import styles from "./HeaderMenuTab.module.scss";
import { useHeaderDispatch } from "../Provider/HeaderContext";

export default function HeaderMenuTabResizeWrapper({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const divRef = useRef<HTMLDivElement>(null);
  const dispatchHeader = useHeaderDispatch();

  useEffect(() => {
    if (divRef.current !== null) {
      dispatchHeader({
        type: "setRightMenuTab",
        setRightMenuTab: divRef.current.getBoundingClientRect().right,
      });
    }

    const handleResize = () => {
      if (divRef.current !== null) {
        dispatchHeader({
          type: "setRightMenuTab",
          setRightMenuTab: divRef.current.getBoundingClientRect().right,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={divRef} className={styles.headerMenuTabWrapper}>
      {children}
    </div>
  );
}
