import {
  ApiDocumentFamilyEnum,
  ApiExecutionType,
  ApiObjectStateEnum,
} from "@armtekfrance/corenexus-pn-sn";
import {
  DocumentTypeFilter,
  FilterMaster,
  GlobalStore,
  NameFilter,
  ObjectStateFilter,
  StoreFilterType,
  UtilsFunction,
} from "@armtekfrance/webgate/dist";
import { useTranslation } from "react-i18next";

export default function OperateFilters() {
  const { t } = useTranslation();

  const filterManager = GlobalStore.filter;
  const pubStatusFilter = new ObjectStateFilter(
    t("filters.operate.objectState"),
    filterManager
  );

  const serialNumberNameFilter = new NameFilter(
    t("filters.operate.serialNumberName"),
    filterManager,
    StoreFilterType.OBJECT,
    t("filters.operate.serialNumberPlaceholder")
  );

  const nameDocumentModelNameFilter = new NameFilter(
    t("filters.operate.modelName"),
    filterManager,
    StoreFilterType.DOCUMENT_MODEL,
    t("filters.operate.modelNamePlaceholder")
  );
  const modelTypeFilter = new DocumentTypeFilter(
    t("filters.operate.modelType"),
    UtilsFunction.getDocumentTypesFromFamily(ApiDocumentFamilyEnum.SYSTEM),
    filterManager,
    StoreFilterType.DOCUMENT_MODEL
  );
  filterManager.clearFilters();
  filterManager.addFilter(serialNumberNameFilter);
  filterManager.addFilter(nameDocumentModelNameFilter);
  filterManager.addFilter(pubStatusFilter);
  filterManager.addFilter(modelTypeFilter);

  filterManager.storeFilters.objectFilter.states = [
    ApiObjectStateEnum.INSTANTIATE,
    ApiObjectStateEnum.IN_SERVICE,
    ApiObjectStateEnum.BUILD,
  ];
  filterManager.storeFilters.executionFilter.types = [
    ApiExecutionType.CONSTRUCTOR,
  ];

  return (
    <FilterMaster
      filterManager={filterManager}
      fetchData={() => {
        //
      }}
    />
  );
}
