import {
  ButtonType,
  IconButton,
  SizeType,
} from "@armtekfrance/armtek-react-design-system";
import {
  ApiDocumentBaseline,
  ApiDocumentModel,
  ApiExecutionMonitor,
  ApiExecutionStatus,
  ApiExecutionType,
  ApiObject,
  ApiObjectSearchRequest,
  ApiObjectStateEnum,
} from "@armtekfrance/corenexus-pn-sn";
import {
  DeleteButton,
  ExpandableDocumentList,
  GlobalStore,
  useStoreState,
} from "@armtekfrance/webgate/dist";
import { useNavigate } from "react-router-dom";
import styles from "./OperateBody.module.scss";

export default function OperateBody() {
  const navigate = useNavigate();
  const [filterExecution] = useStoreState(
    GlobalStore.filter.storeFilters.executionFilter
  );
  const [filterDocument] = useStoreState(
    GlobalStore.filter.storeFilters.documentModelFilter
  );

  const [filterObject] = useStoreState(
    GlobalStore.filter.storeFilters.objectFilter
  );

  const filter: ApiObjectSearchRequest = {
    documents: filterDocument,
    execution: {
      ...filterExecution,
    },
    object: {
      ...filterObject,
      states:
        (filterObject?.states ?? []).length > 0
          ? filterObject?.states
          : [
              ApiObjectStateEnum.BUILD,
              ApiObjectStateEnum.IN_SERVICE,
              ApiObjectStateEnum.INSTANTIATE,
            ],
    },
    config: {
      extend: true,
      user: {
        extend: true,
      },
    },
  };
  return (
    <>
      <ExpandableDocumentList
        object="ApiObject"
        emitFilter={filter}
        onCheck={() => console.log("")}
        disabled={false}
        size={SizeType.small}
        slim={true}
        actionButtons={(
          prop:
            | ApiExecutionMonitor
            | ApiDocumentModel
            | ApiDocumentBaseline
            | ApiObject
        ) => {
          const typedProp = prop as ApiObject;
          return (
            <div className={styles.actionsButtonsSide}>
              <IconButton
                iconData="FormOutlined"
                type={ButtonType.underline}
                onClick={() => {
                  navigate(
                    `/account/system/assign/constructor/${typedProp.blue_print.id}/${typedProp.object_constructor?.id}/${typedProp.id}`
                  );
                }}
              />
              <DeleteButton
                object={typedProp}
                onDelete={() => {
                  // const previous =
                }}
              />
            </div>
          );
        }}
      />
    </>
  );
}
