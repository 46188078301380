import { Button, Col, Empty, message, Modal, Row, Skeleton, Steps } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { fetchBlocks } from "../../app/stores/blocks/blocksClient";
// import { fetchAllContents } from "../../app/stores/content/contentClient";
import { fetchSections } from "../../app/stores/sections/sectionsClient";
import style from "./style.module.css";
import ProcedureBlocks from "../ProcedureBlocks";
import { BlockTypeConst_NEW } from "../../app/stores/blocks/types";
import { fetchSectionBaselines } from "../../app/stores/sectionBaselines/sectionBaselinesClient";
import { fetchBlockBaselines } from "../../app/stores/blockBaselines/blockBaselinesClient";
import { useTranslation } from "react-i18next";
import MediaContentComponentNew from "components/SectionsEdit/Blocks/MediaContent/MediaContentComponentNew";

const { Step } = Steps;
interface ProcedurePreviewType {
  isModalVisible: boolean;
  handleCancel: () => void;
  documentId: number;
  page?: "baselines" | "models";
}

export default function ProcedurePreview({
  isModalVisible,
  handleCancel,
  documentId,
  page,
}: ProcedurePreviewType) {
  const { t } = useTranslation();
  const pageType = page === "baselines" ? "baselines" : "models";
  const [sectionsList, setSectionList] = useState<any[]>([]);
  const [sectionListPending, setSectionListPending] = useState(true);
  const [currentSectionId, setCurrentSectionId] = useState();
  const [currentSection, setCurrentSection] = useState<any>({});
  const [currentSectionPending, setCurrentSectionPending] = useState(true);
  useEffect(() => {
    setSectionListPending(true);
    if (!Number.isNaN(documentId)) {
      fetchSectionsByPage().then((response) => {
        if (response?.success) {
          if (response.data.sections.length > 0) {
            fetchBlocksForSection(response.data.sections[0].id).then(
              (contentResponse) => {
                let copyOfSections = [...response.data.sections];
                copyOfSections[0]["blocks"] = contentResponse;
                setSectionList(copyOfSections);
                setSectionListPending(false);
                setCurrentSection(copyOfSections[0]);
                setCurrentSectionPending(false);
                setCurrentSectionId(copyOfSections[0].id);
              }
            );
          } else {
            setSectionList([]);
            setSectionListPending(false);
            setCurrentSectionPending(false);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [documentId, isModalVisible]);

  const fetchSectionsByPage = async () => {
    try {
      if (pageType === "baselines") {
        const baselineSection = await fetchSectionBaselines(
          { document_baseline_id: documentId },
          { position: "asc" },
          1,
          "0"
        );

        return baselineSection;
      } else {
        const sections = await fetchSections(
          { document_model_id: documentId },
          { position: "asc" },
          1,
          "0"
        );
        return sections;
      }
    } catch (error) {
      console.error("Error in procedure preview fetch sections", error);
    }
  };

  useEffect(() => {
    if (currentSectionId && !currentSection.blocks) {
      setCurrentSectionPending(true);
      fetchBlocksForSection(currentSectionId).then((contentResponse) => {
        let copyOfSections = [...sectionsList];
        const indexOfSection = copyOfSections
          .map((object) => object.id)
          .indexOf(currentSection.id);
        copyOfSections[indexOfSection]["blocks"] = contentResponse;
        setSectionList(copyOfSections);
        setSectionListPending(false);
        setCurrentSection(copyOfSections[indexOfSection]);
        setCurrentSectionPending(false);
      });
    }
    // eslint-disable-next-line
  }, [currentSectionId]);

  const fetchBlocksForSection = async (sectionId: any) => {
    let blocksResponse;
    if (pageType === "baselines") {
      blocksResponse = await fetchBlockBaselines(
        {
          section_baseline_id: sectionId,
        },
        { position: "asc" },
        1,
        "0"
      );
    } else {
      blocksResponse = await fetchBlocks(
        { section_model_id: sectionId },
        { position: "asc" },
        1,
        "0"
      );
    }
    if (blocksResponse.success) {
      // const blocksWithContent = await fetchBlockContentInLoop(
      //   blocksResponse.data.blocks
      // );
      return blocksResponse.data.blocks;
    } else {
      message.error(t("procedurePreview.errorFetching"));
      return [];
    }
  };
  // eslint-disable-next-line
  const [isContentBeingSaved, setIsContentBeingSaved] = useState(false);

  const goToStepOnClick = (index: any) => {
    const selectedSection = sectionsList[index];
    if (!selectedSection.blocks) {
      setCurrentSectionPending(true);
    }
    setCurrentSection(selectedSection);
    setCurrentSectionId(selectedSection.id);
  };

  const onClickNextButton = (currentPosition: any) => {
    goToStepOnClick(parseInt(currentPosition));
  };

  const onClickPrevButton = (currentPosition: any) => {
    goToStepOnClick(parseInt(currentPosition) - 2);
  };

  return (
    <Modal
      title={t("doc.models.edit.tabPanel.preview")}
      open={isModalVisible}
      onCancel={handleCancel}
      style={{
        top: 15,
      }}
      width={"95vw"}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          data-cy="Dashboard-Preview-Cancel"
        >
          {t("button.cancel")}
        </Button>,
      ]}
    >
      {sectionListPending ? (
        <>
          <Skeleton active />
        </>
      ) : (
        <div>
          {sectionsList.length > 0 ? (
            <>
              <Steps
                current={parseInt(currentSection.position) - 1}
                onChange={goToStepOnClick}
              >
                {sectionsList.map((item, index) => (
                  <Step
                    key={item.id}
                    disabled={false}
                    icon={index + 1}
                    // status={
                    //   parseInt(currentSection.position) - 1 === index
                    //     ? "current"
                    //     : "wait"
                    // }
                  />
                ))}
              </Steps>
              {currentSectionPending ? (
                <div className={style.stepsContent}>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </div>
              ) : (
                <div className={style.stepsContent}>
                  <h3>
                    &nbsp;&nbsp;{currentSection.position} |{" "}
                    {currentSection.name}
                  </h3>
                  <div
                    className="scrollable-container"
                    style={{
                      alignContent: "center",
                      marginLeft: "70px",
                      marginRight: "70px",
                    }}
                  >
                    <div
                      style={{
                        height: "50vh",
                        overflow: "auto",
                        textAlign: "justify",
                      }}
                    >
                      {currentSection.blocks ? (
                        currentSection.blocks?.map(
                          (singleBlock: any, index: number) => {
                            return singleBlock.type ===
                              BlockTypeConst_NEW.image ||
                              singleBlock.type === BlockTypeConst_NEW.video ||
                              singleBlock.type === BlockTypeConst_NEW.audio ||
                              singleBlock.type === BlockTypeConst_NEW.file ? (
                              <MediaContentComponentNew
                                key={index}
                                blockModel={singleBlock}
                                isPreview={true}
                              />
                            ) : (
                              <ProcedureBlocks
                                key={index}
                                readOnly={true}
                                blockType={singleBlock.type}
                                setSectionList={setSectionList}
                                blockId={singleBlock.id}
                                sectionId={currentSection.id}
                                setIsContentBeingSaved={setIsContentBeingSaved}
                                page={page}
                              />
                            );
                          }
                        )
                      ) : (
                        <Empty />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <Row style={{ marginTop: "24px", height: "40px" }}>
                <Col span={11}>
                  <Button
                    type="primary"
                    icon={<AiOutlineLeft />}
                    key="prevStep"
                    style={{ width: "100%" }}
                    disabled={parseInt(currentSection.position) === 1}
                    onClick={() => onClickPrevButton(currentSection.position)}
                  >
                    &nbsp; {t("executionPreview.previousStep")}
                  </Button>
                </Col>
                <Col span={2} style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    {currentSection.position} / {sectionsList.length}
                  </p>
                </Col>
                <Col span={11}>
                  <Button
                    type="primary"
                    key="nextStep"
                    style={{ width: "100%" }}
                    disabled={
                      parseInt(currentSection.position) === sectionsList.length
                    }
                    onClick={() => onClickNextButton(currentSection.position)}
                  >
                    {t("executionPreview.nextStep")} &nbsp; <AiOutlineRight />
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Empty />
          )}
        </div>
      )}
    </Modal>
  );
}
