import { Col, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useAddTeamFormDispatch } from "../Provider/AddTeamFormContext";
import AddTeamFormPreviewUserWrapper from "./AddTeamFormPreviewUserWrapper";
import { MessageInstance } from "antd/lib/message";
import AddTeamFormCompanyUsersTable from "./AddTeamFormCompanyUsersTable";

interface AddTeamFormUserSelectFieldProps {
  messageApi: MessageInstance;
}

export default function AddTeamFormUserSelectField({
  messageApi,
}: AddTeamFormUserSelectFieldProps) {
  const { t } = useTranslation();

  const dispatchAddTeamForm = useAddTeamFormDispatch();

  // Manage search
  const onSearch = (value: string) => {
    // Prevent search if only spaces are entered
    if (value.length > 0 && value.trim().length === 0) return;
    dispatchAddTeamForm({
      type: "setSearchValues",
      page: 1,
      pageSize: 10,
      searchText: value,
    });
  };

  const onChangeMemberField = (e: any) => {
    e.preventDefault();

    onSearch(e.target.value);
  };

  return (
    <>
      <Col span={24}>
        <Form.Item
          name={t("team.member")}
          label={t("team.member")}
          rules={[
            {
              required: false,
              message: t("team.teamMemberInput"),
            },
          ]}
        >
          <Input
            onChange={onChangeMemberField}
            placeholder={t("team.teamMemberInput")}
          />
        </Form.Item>
      </Col>
      <AddTeamFormCompanyUsersTable />
      <AddTeamFormPreviewUserWrapper messageApi={messageApi} />
    </>
  );
}
