import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import ImageDisplayComponent from "../ImageDisplayComponent/ImageDisplayComponent";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

const { Meta } = Card;

interface CardType {
  data: any[];
  loading: boolean;
  cardLink: string;
}

export default function CardLayout({ data, loading, cardLink }: CardType) {
  const { t } = useTranslation();
  return (
    <div className={style.LibraryCardView}>
      {loading ? (
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={6}>
            <Card
              loading={true}
              cover={<div className={style.loadingCardImage}></div>}
            >
              <Meta
                title={t("cardLayout.cardTitle")}
                description={t("cardLayout.thisIsDescription")}
              />
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card
              loading={true}
              cover={<div className={style.loadingCardImage}></div>}
            >
              <Meta
                title={t("cardLayout.cardTitle")}
                description={t("cardLayout.thisIsDescription")}
              />
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card
              loading={true}
              cover={<div className={style.loadingCardImage}></div>}
            >
              <Meta
                title={t("cardLayout.cardTitle")}
                description={t("cardLayout.thisIsDescription")}
              />
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card
              loading={true}
              cover={<div className={style.loadingCardImage}></div>}
            >
              <Meta
                title={t("cardLayout.cardTitle")}
                description={t("cardLayout.thisIsDescription")}
              />
            </Card>
          </Col>
        </Row>
      ) : (
        <div>
          <div>
            <Row gutter={[16, 16]}>
              {data.map((entity) => (
                <Col className="gutter-row">
                  <Link to={cardLink + entity.id}>
                    <Card
                      style={{ width: "192px", height: "224px" }}
                      cover={
                        entity.media_content ? (
                          <div style={{ width: "192px", height: "154px" }}>
                            <ImageDisplayComponent
                              src={entity?.media_content?.media_content_uid}
                              alt={entity.name}
                              thumbnail={true}
                              style={{ width: "192px", height: "154px" }}
                              enablePreview={false}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "192px",
                              height: "154px",
                              background: "#f2f2f2",
                              alignContent: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            {t("cardLayout.noImage")}
                          </div>
                        )
                      }
                    >
                      <Meta
                        title={
                          <p style={{ fontSize: "14px" }}>{entity.name}</p>
                        }
                      />
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}
