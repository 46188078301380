import { createContext, Dispatch, useContext } from "react";
import {
  stateTeamLayoutProvider,
  TypeTeamLayoutContextReducerAction,
} from "./TeamLayoutReducer";

export const TeamLayoutContext = createContext<stateTeamLayoutProvider>({
  statusTeams: "loading",
  errorTeams: null,
  teams: undefined,
  activeId: null,
  deleteLoading: false,
  newTeamCreated: false,
  isArchiveModalVisible: false,
  archiveTeamId: null,
});

export const TeamLayoutDispatchContext = createContext<
  Dispatch<TypeTeamLayoutContextReducerAction>
>(() => {
  return { type: "" };
});

export function useTeamLayout() {
  return useContext(TeamLayoutContext);
}

export function useTeamLayoutDispatch() {
  return useContext(TeamLayoutDispatchContext);
}
