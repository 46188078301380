import { QueryStatus } from "@tanstack/react-query";
import { PaginationType } from "app/stores/pagination/types";
import { DocumentType } from "app/stores/documents/types";

export type stateAddLinkageNodeProvider = {
  pagination: PaginationType;
  status: QueryStatus;
  error: unknown;
  documentModels: (DocumentType & { isSelected: boolean })[];
  selectedItems: number[];
  typeFilter: string;
  queryNameTextFilter: string;
};

export type TypeAddLinkageNodeContextReducerAction =
  | {
      type: "setPagination";
      pagination: stateAddLinkageNodeProvider["pagination"];
    }
  | {
      type: "setDocumentModelsTanstackApi";
      status: stateAddLinkageNodeProvider["status"];
      error: stateAddLinkageNodeProvider["error"];
    }
  | {
      type: "setDocumentModelsTanstack";
      documentModels: stateAddLinkageNodeProvider["documentModels"];
      pagination: stateAddLinkageNodeProvider["pagination"];
    }
  | {
      type: "setTypeFilter";
      typeFilter: stateAddLinkageNodeProvider["typeFilter"];
    }
  | {
      type: "setQueryNameTextFilter";
      queryNameTextFilter: stateAddLinkageNodeProvider["queryNameTextFilter"];
    }
  | {
      type: "toggleDocumentsSelected";
      documentId: number;
    }
  | {
      type: "resetSelectedItems";
    }
  | {
      type: "updateSelectedItems";
    };

export function addLinkageNodeReducer(
  stateAddLinkageNode: stateAddLinkageNodeProvider,
  action: TypeAddLinkageNodeContextReducerAction
): stateAddLinkageNodeProvider {
  const updatedModels = (itemArray: number[]) => {
    return stateAddLinkageNode.documentModels.map((documentModel) => {
      return itemArray.includes(documentModel.id)
        ? { ...documentModel, isSelected: true }
        : { ...documentModel, isSelected: false };
    });
  };

  switch (action.type) {
    case "setPagination": {
      return {
        ...stateAddLinkageNode,
        pagination: action.pagination,
      };
    }
    case "setDocumentModelsTanstackApi": {
      return {
        ...stateAddLinkageNode,
        status: action.status,
        error: action.error,
      };
    }
    case "setDocumentModelsTanstack": {
      return {
        ...stateAddLinkageNode,
        documentModels: action.documentModels.map((documentModel) => {
          return {
            ...documentModel,
            isSelected: stateAddLinkageNode.selectedItems.includes(
              documentModel.id
            ),
          };
        }),
        pagination: action.pagination,
      };
    }
    case "setTypeFilter": {
      return { ...stateAddLinkageNode, typeFilter: action.typeFilter };
    }
    case "setQueryNameTextFilter": {
      return {
        ...stateAddLinkageNode,
        queryNameTextFilter: action.queryNameTextFilter,
      };
    }
    case "toggleDocumentsSelected": {
      const localSelectedItem = stateAddLinkageNode.selectedItems.includes(
        action.documentId
      )
        ? stateAddLinkageNode.selectedItems.filter(
            (itemId) => itemId !== action.documentId
          )
        : [...stateAddLinkageNode.selectedItems, action.documentId];

      return {
        ...stateAddLinkageNode,
        documentModels: updatedModels(localSelectedItem),
        selectedItems: localSelectedItem,
      };
    }
    case "resetSelectedItems": {
      return {
        ...stateAddLinkageNode,
        selectedItems: [],
      };
    }
    case "updateSelectedItems": {
      return {
        ...stateAddLinkageNode,
        documentModels: updatedModels(stateAddLinkageNode.selectedItems),
      };
    }
    default: {
      return { ...stateAddLinkageNode };
    }
  }
}
