import {
  ApiContentType,
  ApiDocumentBaselineExpandable,
  ApiDocumentFamilyEnum,
  ApiObjectExpandable,
} from "@armtekfrance/corenexus-pn-sn";
import {
  ModelHeader,
  ObjectCloudActions,
  ObjectManager,
  Loader,
  ExpandableLinkageConstructorSection,
  CompleteButton,
  useModelStore,
} from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./SystemConstructionPage.module.scss";
import { navigation } from "Router/NavigationService";
import { paths } from "Router/ROUTES";
import { PreviewFullScreen } from "@armtekfrance/webgate/dist";

export default function SystemConstructionPage() {
  const { constructorId, baselineId, objectId } = useParams();

  const retrieveDocumentAndExecution = (
    constructorId: number,
    baselineId: number,
    objectId: number
  ) => {
    Manager.API.emit(ObjectCloudActions.OBJECT_RETRIEVES, {
      objects: [objectId],
      config: {
        extend: true,
        blue_print: {
          extend: true,
          cover: {
            extend: true,
          },
          qr_code: {
            extend: true,
          },
          sections: {
            extend: true,
            blocks: {
              extend: true,
              content: {
                extend: true,
                execution_monitor_id: constructorId,
              },
            },
          },
        },
        object_childs: {
          extend: true,
          qr_code: {
            extend: true,
          },
          blue_print: {
            extend: true,
            cover: {
              extend: true,
            },
          },
        },
        documents: { extend: true },
      },
    });
  };

  useEffect(() => {
    if (constructorId && baselineId && objectId) {
      retrieveDocumentAndExecution(
        parseInt(constructorId),
        parseInt(baselineId),
        parseInt(objectId)
      );
    }
  }, [constructorId, baselineId, constructorId]);

  const objectData: ApiObjectExpandable | undefined =
    ObjectManager.useObjectStateFromObjectRetrieveResponse(parseInt(objectId!));

  const afterCreate = () => {
    setTimeout(() => {
      navigate(`/account/system/operate`);
    }, 1000);
  };

  const onNavigateObject = (
    objectId: number,
    baselineId: number,
    constructorId: number
  ) => {
    if (objectId && baselineId && constructorId) {
      navigation.to(
        `account/system/assign/constructor/${baselineId}/${constructorId}/${objectId}` as paths
      );
    }
  };
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    return () => {
      // Set the store to initial state
      useModelStore.getState().setCurrentModel(null);
    };
  }, [location]);

  return (
    <div>
      {objectData && objectData.data ? (
        <>
          <PreviewFullScreen />
          <ModelHeader
            document={
              objectData?.data?.blue_print as ApiDocumentBaselineExpandable
            }
            additionalFirstLineButtons={[
              <CompleteButton
                object={objectData.data!}
                onComplete={afterCreate}
              />,
            ]}
            documentFamily={ApiDocumentFamilyEnum.SYSTEM}
            objectId={parseInt(objectId!)}
            constructorId={parseInt(constructorId!)}
            additionalContent={
              <div className={styles.additionalContent}>
                {objectData?.data?.documents?.length > 0 && (
                  <ExpandableLinkageConstructorSection
                    dataCy={
                      "ConstructorPage_WriteExecutedValueExpandableSection_KnowledgeLinkage"
                    }
                    editable={false}
                    isExpandedInitially={true}
                    objectId={parseInt(objectId!)}
                    onNavigateObject={onNavigateObject}
                    sectionId={
                      "documentLinkage" + objectData.data?.blue_print.id
                    }
                    linkageType={
                      ApiContentType.ContentDisplayInternalLinkKnowledge
                    }
                  />
                )}
                {(objectData?.data?.object_childs?.length > 0 ||
                  (objectData?.data?.object_childs_missing?.length ?? 0) >
                    0) && (
                  <ExpandableLinkageConstructorSection
                    dataCy={
                      "ConstructorPage_WriteExecutedValueExpandableSection_SystemLinkage"
                    }
                    editable={false}
                    isExpandedInitially={true}
                    objectId={parseInt(objectId!)}
                    onNavigateObject={onNavigateObject}
                    sectionId={"systemLinkage" + objectData.data?.blue_print.id}
                    linkageType={
                      ApiContentType.ContentDisplayInternalLinkSystem
                    }
                  />
                )}
              </div>
            }
          />
        </>
      ) : (
        <div className={styles.loaderContainer}>
          <Loader></Loader>
        </div>
      )}
    </div>
  );
}
