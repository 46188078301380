import { Button, Card, Popover, Switch } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { fetchDocumentsBaselineByID } from "../../app/stores/documentsBaseline/documentsBaselineClient";
import QrCodeComponent from "../QrCodeComponent/QrCodeComponent";
import { AiOutlineQrcode } from "react-icons/ai";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import { fetchQRCodes } from "../../app/stores/qrCode/qrCodeClient";

interface SelectItemBlockType {
  data: any;
  userInput?: number | string | undefined;
  alertOccured: boolean;
}

export default function SelectItemBlock({
  data,
  userInput,
  alertOccured,
}: SelectItemBlockType) {
  const { t } = useTranslation();

  const [userInputItem, setUserInputItem] = useState<any>();

  useEffect(() => {
    if (userInput) {
      fetchDocumentsBaselineByID(userInput + "").then((res) => {
        if (res.success && res.data) {
          let qrFilter = {
            document_model_id: userInput + "",
          };
          fetchQRCodes(1, 1, qrFilter).then((qrRes) => {
            setUserInputItem({
              name: res.data.name,
              id: res.data.id,
              version: res.data.version,
              type: res.data.type,
              qrcode: qrRes ? qrRes.qrCodes : null,
              user: {
                avatar: res.data.user?.avatar?.media_content_uid,
                first_name: res.data.user.first_name,
                last_name: res.data.user.last_name,
              },
            });
          });
        } else {
          setUserInputItem(null);
        }
      });
    }
    // eslint-disable-next-line
  }, [userInput]);
  return (
    <Card
      style={{
        backgroundColor: "#F6F9FB",
        border: alertOccured ? "3px red solid" : "",
      }}
      bodyStyle={{ padding: "8px" }}
    >
      <div style={{ marginBottom: "20px" }}>
        <Switch
          checkedChildren={t("doc.models.edit.sections.blocks.mandatory")}
          unCheckedChildren={t("doc.models.edit.sections.blocks.notMandatory")}
          defaultChecked={data?.mandatory}
          disabled={true}
        />
      </div>
      <div>
        <TextArea
          placeholder={t("selectItemBlock.instructionForSelect")}
          bordered={false}
          autoSize
          defaultValue={data?.instruction}
          disabled={true}
          style={{
            marginTop: "4px",
            paddingLeft: "0px",
            fontSize: "14px",
            margin: "0px",
          }}
        />
      </div>
      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
        <p style={{ fontWeight: "600" }}>
          {t("selectItemBlock.userSelectedItem")}
        </p>
        {userInputItem && (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "white",
                padding: "8px",
                width: "70%",
                justifyContent: "space-between",
                minWidth: "566px",
              }}
            >
              {userInputItem?.qrcode ? (
                <Popover
                  trigger="click"
                  content={<QrCodeComponent qrCode={userInputItem?.qrcode} />}
                >
                  <Button icon={<AiOutlineQrcode size={24} />}></Button>
                </Popover>
              ) : (
                <p style={{ color: "#b3b3b3" }}>
                  {t("qrCode.noQrAssignedSmall")}
                </p>
              )}

              <div>{userInputItem.name}</div>
              <div>{userInputItem.type}</div>
              <AvatarComponent
                src={userInputItem.user?.avatar}
                firstName={userInputItem.user.first_name}
                lastName={userInputItem.user.last_name}
              />
              <div style={{ marginRight: "10%" }}>
                {t("doc.models.procedure.version")} {userInputItem.version}
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
