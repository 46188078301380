import { Avatar, Space, Popover } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const bulletColors = [
  "#D65249",
  "#5499C7",
  "black",
  "red",
  "green",
  "#e91e63",
  "#ff5722",
  "#ff9800",
  "#ffc107",
  "#009688",
  "#8bc34a",
  "#2196f3",
  "#03a9f4",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#00bcd4",
  "#4caf50",
  "#cddc39",
  "#ffeb3b",
  "#795548",
  "#607d8b",
];

interface BulletPointType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => void;
  readOnly?: boolean;
  contentId: number | string;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function BulletPoints({
  data,
  updateContentInDb,
  readOnly,
  contentId,
  setIsContentBeingSaved,
}: BulletPointType) {
  const [bullets, setBullets] = useState(
    data?.points?.length > 0 ? data.points : [{ value: "", color: "black" }]
  );
  const [bulletsLoading, setBulletsLoading] = useState(false);
  const [bulletRefs, setBulletRefs] = useState<any>([]);
  const [activeBullet, setActiveBullet] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    setBulletsLoading(true);
    setBullets(
      data?.points?.length > 0 ? data.points : [{ value: "", color: "black" }]
    );
    setBulletsLoading(false);
  }, [data]);

  useEffect(() => {
    if (bulletRefs.length === bullets.length && activeBullet !== null) {
      bulletRefs[activeBullet]?.current?.focus();
    }
    // eslint-disable-next-line
  }, [bulletRefs.length]);

  useEffect(() => {
    setBulletRefs((elRefs: any) =>
      Array(bullets.length)
        .fill(null)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [bullets.length]);

  const onkeydown = (key: any, e: any) => {
    if (e.code === "Enter") {
      addBullet(key, e.target.selectionStart);
      setActiveBullet(key + 1);
    }
    if (e.code === "Backspace" && e.target.selectionStart === 0 && key !== 0) {
      e.preventDefault();
      deleteBullet(key);
      setActiveBullet(key - 1);
    }
    if (e.code === "ArrowUp") {
      setActiveBullet(key - 1);
      bulletRefs[key - 1].current.focus();
    }
    if (e.code === "ArrowDown") {
      setActiveBullet(key + 1);
      bulletRefs[key + 1].current.focus();
    }
  };

  const addBullet = (key: number, cursorIndex: number) => {
    setBulletsLoading(true);
    let cp_bullets = [...bullets];
    let valueWithoutNewline = cp_bullets[key].value.replace(/\n/g, "");
    const newBulletText = valueWithoutNewline.slice(cursorIndex);
    const newText = valueWithoutNewline.slice(0, cursorIndex);
    let newBullet = {
      color: "black",
      value: "",
    };
    newBullet.value = newBulletText;
    cp_bullets[key].value = newText;
    cp_bullets.splice(key + 1, 0, newBullet);
    setBullets(cp_bullets);
    setBulletsLoading(false);
    updateData(cp_bullets);
  };

  const deleteBullet = (key: number) => {
    setBulletsLoading(true);
    let copyBullets = [...bullets];
    copyBullets[key].value = copyBullets[key].value.replace(/\n/g, "");
    copyBullets[key - 1].value =
      copyBullets[key - 1].value + copyBullets[key].value;
    copyBullets.splice(key, 1);

    setBullets(copyBullets);
    setBulletsLoading(false);
    updateData(copyBullets);
  };

  const updateBulletColor = (key: number, e: string) => {
    let copyBullets = [...bullets];
    copyBullets[key].color = e;
    setBullets(copyBullets);
    updateData(copyBullets);
  };

  let timeoutId: any;
  const setBulletValue = (key: number, event: any) => {
    setIsContentBeingSaved(true);
    //need to check here for enter and backspace
    let copyBullets = [...bullets];
    copyBullets[key].value = event.target.value;
    setBullets(copyBullets);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change

      const dataToUpdate = {
        content: { content: { points: copyBullets } },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const updateData = (updateData: any) => {
    const dataToUpdate = {
      content: {
        content: { points: updateData },
      },
    };

    clearTimeout(timeoutId);
    updateContentInDb(contentId, dataToUpdate);
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F9FB",
        padding: "8px 0px 8px 4px",
      }}
    >
      {!bulletsLoading ? (
        bullets.map((onePoint: any, index: number) => (
          <>
            <Space
              align="center"
              style={{ paddingBottom: "8px" }}
              id={"space" + index}
            >
              {readOnly ? (
                <Avatar
                  style={{ backgroundColor: onePoint.color }}
                  size={12}
                ></Avatar>
              ) : (
                <Popover
                  trigger="click"
                  placement="topLeft"
                  content={
                    <Space>
                      {bulletColors.map((color: string) => (
                        <Avatar
                          onClick={() => updateBulletColor(index, color)}
                          style={{ backgroundColor: color, cursor: "pointer" }}
                          size={12}
                        ></Avatar>
                      ))}
                    </Space>
                  }
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: onePoint.color,
                        cursor: "pointer",
                      }}
                      size={12}
                    ></Avatar>
                  </div>
                </Popover>
              )}
              <TextArea
                autoSize
                style={{ width: "80vw" }}
                onKeyDown={(e) => onkeydown(index, e)}
                disabled={readOnly}
                bordered={false}
                ref={bulletRefs[index]}
                value={bullets[index].value.replace(/(?:\r\n|\r|\n)/g, "")}
                onChange={(event) => setBulletValue(index, event)}
                placeholder={t("doc.models.edit.sections.blocks.insertContent")}
                id={"bullet" + index}
              />
            </Space>
          </>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
}
