import { Empty } from "antd";
import style from "./style.module.css";
import TableLayout from "../TableLayout";
import PaginationLayout from "../PaginationLayout";
import CardLayout from "../CardLayout";
import { useTranslation } from "react-i18next";

interface ListViewType {
  data: any[];
  cardData: any[];
  columns: any[];
  loading: boolean;
  loadingCardData: boolean;
  isListView: boolean;
  page: number;
  pageSize: number;
  total: number;
  cardLink: string;
  onChangePagination: (page: number, pageSize: number) => void;
  setSelectedTableKeys: (selectedKeys: any[]) => void;
  selectedKeys?: number[] | undefined;
  showCheckBox?: boolean;
}

export default function ListView({
  data,
  cardData,
  columns,
  loading,
  loadingCardData,
  isListView,
  page,
  pageSize,
  total,
  cardLink,
  onChangePagination,
  setSelectedTableKeys,
  selectedKeys,
  showCheckBox = false,
}: ListViewType) {
  const { t } = useTranslation();
  return (
    <div>
      {data && data.length !== 0 ? (
        <div>
          {isListView ? (
            <div className={style.libraryTable}>
              <TableLayout
                tableData={data}
                loading={loading}
                headerColumns={columns}
                rowKey="id"
                setSelectedTableKeys={setSelectedTableKeys}
                selectedKeys={selectedKeys}
                showCheckBox={showCheckBox}
              />
            </div>
          ) : (
            <div className={style.libraryTable}>
              <CardLayout
                data={cardData}
                loading={loading || loadingCardData}
                cardLink={cardLink}
              />
            </div>
          )}
          {total > 0 && (
            <PaginationLayout
              onChangePagination={onChangePagination}
              page={page}
              pageSize={pageSize}
              total={total}
            />
          )}
        </div>
      ) : (
        <div className={style.libraryTable}>
          <Empty description={t("empty.noData")} />
        </div>
      )}
    </div>
  );
}
