import { ObjectValues } from "../../../util/typesUtils";

export interface CreateBlockType {
  section_model_id: number;
  name: string;
}

export const BlockTypeConst_NEW = {
  text: "text",
  image: "image",
  video: "video",
  file: "file",
  audio: "audio",
  warning: "warning",
  info: "info",
  input_text: "input_text",
  input_checkbox: "input_checkbox",
  input_radio: "input_radio",
  bullet_point: "bullet_point",
  signature: "signature",
  input_image: "input_image",
  input_video: "input_video",
  input_audio: "input_audio",
  quiz: "quiz",
  certification: "certification",
  input_data: "input_data",
  phone: "phone",
  sms: "sms",
  data: "data",
  internal_link: "internal_link",
  external_link: "external_link",
  qrcode: "qrcode",
  table: "table",
  attribute: "attribute",
  select_item: "select_item",
  radioList: "radioList",
  ContentInputAttributeStandard: "ContentInputAttributeStandard",
  ContentInputAttributeFixed: "ContentInputAttributeFixed",
  ContentInputAttributeVariable: "ContentInputAttributeVariable",
  ContentDisplayInternalLinkKnowledge: "ContentDisplayInternalLinkKnowledge",
  ContentDisplayInternalLinkSystem: "ContentDisplayInternalLinkSystem",
} as const;

export type BlockType_NEW = ObjectValues<typeof BlockTypeConst_NEW>;
