import { MediaContent } from "Stores/media-models";
import styles from "./MediaSelectorFile.module.scss";
import { ReactComponent as Folder } from "../TempDS/assets/Folder.svg";

export default function MediaSelectorFile({
  fileItem,
  dropDownComponent,
}: {
  fileItem: MediaContent;
  dropDownComponent: JSX.Element;
}) {
  return (
    <div className={styles.mediaSelectorFileWrapper}>
      <article>
        <Folder />
        <p>{fileItem.name?.split(".")[fileItem.name.split(".").length - 1]}</p>
      </article>
      {dropDownComponent}
    </div>
  );
}
