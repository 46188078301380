import React, { useRef } from "react";
import { Menu, Button, Modal, Row, Col, Alert } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentTypeConst } from "../../../../app/stores/documents/types";
import { fetchDocumentBaselines } from "../../../../app/stores/documentsBaseline/documentsBaselineClient";
import FilterDropdownComponent from "../../../../components/FilterDropdownComponent";
import { fetchQRCodes } from "../../../../app/stores/qrCode/qrCodeClient";
import Search from "antd/lib/input/Search";
import BaselineTable from "components/BaselineTable";

interface internalLinkModalProps {
  IsModelAssignVisible: boolean;
  handleCancel: () => void;
  selectedTableKey: any[];
  modalDataPending: boolean;
  setModalDataPending: (data: boolean) => void;

  handleSubmit: (data: any) => void;
  tableDataList: any[];
  setTableDataList: (data: any) => void;
  setSelectedTableKeys: (data: any) => void;
}
const InternalLinkModal = ({
  IsModelAssignVisible,
  handleCancel,
  selectedTableKey,
  modalDataPending,
  setModalDataPending,

  handleSubmit,
  tableDataList,
  setTableDataList,
  setSelectedTableKeys,
}: internalLinkModalProps) => {
  const { t } = useTranslation();
  const [tableDataListPagination, setTableDataListPagination] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [docTypeFilter, setDocTypeFilter] = useState<string>("");

  const [searchText, setSearchText] = useState("");
  const onSearch = (value: string) => {
    setPage(1);
    setPageSize(10);
    setSearchText(value);
  };

  const onChangePagination = (changedPage: number, changedPageSize: number) => {
    if (changedPage !== page) {
      setPage(changedPage);
    } else if (changedPageSize !== pageSize) {
      setPageSize(changedPageSize);
    }
  };

  useEffect(() => {
    setModalDataPending(true);
    let filters: any = {};
    if (docTypeFilter.trim() !== "") {
      filters["type"] = docTypeFilter;
    }
    if (searchText) {
      filters["name"] = { contains: searchText };
    }
    fetchBaselineWithQr(page, pageSize, filters).then((response) => {
      setTableDataList(response.data);
      setTableDataListPagination(response.pagination);
    });

    // eslint-disable-next-line
  }, [page, pageSize, searchText, docTypeFilter]);

  const onClickTypeFilter = (event: any) => {
    setPage(1);
    setPageSize(10);
    if (event.key === "all") {
      setDocTypeFilter("");
    } else {
      setDocTypeFilter(event.key);
    }
  };

  const typeFilterMenu = (
    <Menu
      selectable
      defaultSelectedKeys={["all"]}
      onClick={onClickTypeFilter}
      items={[
        {
          key: "all",
          label: t("doc.models.allType"),
        },
        {
          key: DocumentTypeConst.procedure,
          label: t("doc.models.procedure.title"),
        },
        {
          key: DocumentTypeConst.report,
          label: t("doc.models.report.title"),
        },
        {
          key: DocumentTypeConst.form,
          label: t("doc.models.form.title"),
        },
        {
          key: DocumentTypeConst.specification,
          label: t("doc.models.specification.title"),
        },
        {
          key: DocumentTypeConst.communication,
          label: t("doc.models.communication.title"),
        },
        {
          key: DocumentTypeConst.mixed,
          label: t("doc.models.mixed.title"),
        },
        {
          key: DocumentTypeConst.manual,
          label: t("doc.models.manual.title"),
        },
        {
          key: DocumentTypeConst.requirements,
          label: t("doc.models.requirements.title"),
        },
        {
          key: DocumentTypeConst.guide,
          label: t("doc.models.guide.title"),
        },
        {
          key: DocumentTypeConst.group_sets,
          label: t("doc.models.group_setss.title"),
        },
        {
          key: DocumentTypeConst.organisation_asset,
          label: t("doc.models.organisation_asset.title"),
        },
        {
          key: DocumentTypeConst.organisation_project,
          label: t("doc.models.organisation_project.title"),
        },
        {
          key: DocumentTypeConst.organisation_product,
          label: t("doc.models.organisation_product.title"),
        },
        {
          key: DocumentTypeConst.organisation_workstation,
          label: t("doc.models.organisation_workstation.title"),
        },
      ]}
    />
  );
  const abortControllerRef = useRef<AbortController>(new AbortController());

  useEffect(() => {
    const controller = abortControllerRef.current;
    return () => {
      controller.abort();
    };
  }, []);

  const fetchBaselineWithQr = async (
    page: number,
    pageSize: number,
    filter: any
  ) => {
    let respData: { data: any[]; pagination: any } = {
      data: [],
      pagination: { page: 1, pageSize: 10, total: 0 },
    };
    let sort = {
      id: "desc",
    };
    const controller = abortControllerRef.current;
    const documentData = await fetchDocumentBaselines(
      page,
      pageSize,
      filter,
      sort,
      controller.signal
    );
    respData = {
      data: documentData.documentBaselines,
      pagination: documentData.pagination,
    };
    const qrResp = await fetchQRCode(respData.data);
    if (qrResp) {
      respData.data = qrResp;
    }
    setModalDataPending(false);
    return respData;
  };

  const fetchQRCode = async (listData: any) => {
    if (listData.length > 0) {
      let tableDataListCopy = [];
      for await (const oneData of listData) {
        if (!oneData.qrCode) {
          let qrFilter = {
            document_model_id: oneData.document_model_id,
          };
          const qrCode = await fetchQRCodes(1, 1, qrFilter);
          if (qrCode.qrCodes) {
            oneData["qrCode"] = qrCode.qrCodes;
          } else {
            oneData["qrCode"] = null;
          }
        }
        tableDataListCopy.push(oneData);
      }
      return tableDataListCopy;
    } else {
      return null;
    }
  };

  return (
    <div>
      <Modal
        title={t("doc.models.edit.sections.blocks.listOfReleasedModels")}
        open={IsModelAssignVisible}
        onCancel={handleCancel}
        style={{
          top: 30,
        }}
        width={"95vw"}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("button.cancel")}
          </Button>,
        ]}
      >
        <Search
          placeholder={t(
            "doc.models.edit.sections.blocks.searchReleasedModels"
          )}
          allowClear
          onSearch={onSearch}
          style={{ width: 300 }}
        />
        {selectedTableKey.length > 1 ? (
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Alert
              style={{
                width: "373px",
                minWidth: "373px",
              }}
              message={
                <div>
                  <span style={{ color: "red" }}>{t("modelLink.error")}</span>{" "}
                  {t("doc.models.edit.sections.blocks.modelLinkError")}
                </div>
              }
              type="error"
            />
          </div>
        ) : null}
        <div className="content-wrapper bg-white pt-4">
          <div style={{ marginTop: "28px" }}>
            <FilterDropdownComponent
              menuList={typeFilterMenu}
              buttonName={
                docTypeFilter
                  ? typeFilterMenu.props.items[
                      typeFilterMenu.props.items.findIndex(
                        (filterItem: any) => filterItem.key === docTypeFilter
                      )
                    ].label
                  : "All"
              }
              buttonStyle={{ borderColor: "#4F7FA3", color: "#4F7FA3" }}
            />
          </div>
          <BaselineTable
            loading={modalDataPending}
            tags={{}}
            baselines={tableDataList}
            isListView={true}
            paginationDetails={tableDataListPagination}
            onChangePagination={onChangePagination}
            setSelectedTableKeys={setSelectedTableKeys}
            selectedTableKey={selectedTableKey}
            showCheckBox={true}
          />
          <Row>
            <Col span={22}></Col>
            <Col span={2}>
              <Button
                key="submit"
                disabled={selectedTableKey.length === 1 ? false : true}
                style={{ backgroundColor: "#4F7FA3", color: "white" }}
                onClick={handleSubmit}
              >
                {t("doc.models.edit.sections.blocks.link")}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default InternalLinkModal;
