import CreateUser from "pages/CompanyUsersLayout/UserForm";
// import { useTranslation } from "react-i18next";
import {
  useAddTeamForm,
  useAddTeamFormDispatch,
} from "../Provider/AddTeamFormContext";
import { fetchCompanyUsers } from "app/services/company";
import { useEffect } from "react";
import { MessageInstance } from "antd/lib/message";
import { useTranslation } from "react-i18next";

interface AddTeamFormPreviewUserWrapperProps {
  messageApi: MessageInstance;
}

export default function AddTeamFormPreviewUserWrapper({
  messageApi,
}: AddTeamFormPreviewUserWrapperProps) {
  const { t } = useTranslation();

  const addTeamFormContext = useAddTeamForm();
  const dispatchAddTeamForm = useAddTeamFormDispatch();

  useEffect(() => {
    fetchUsers();
  }, [
    addTeamFormContext.page,
    addTeamFormContext.pageSize,
    addTeamFormContext.searchText,
  ]);

  // Fetch all users
  const fetchUsers = async () => {
    dispatchAddTeamForm({
      type: "setTableDataLoading",
      tableDataLoading: true,
    });
    fetchCompanyUsers(
      addTeamFormContext.page,
      addTeamFormContext.pageSize,
      addTeamFormContext.searchText
        ? {
            first_name: { contains: addTeamFormContext.searchText },
            middle_name: { contains: addTeamFormContext.searchText },
            last_name: { contains: addTeamFormContext.searchText },
          }
        : {}
    )
      .then(({ users, pagination }) => {
        dispatchAddTeamForm({
          type: "setTableDataList",
          tableDataList: users,
        });
        dispatchAddTeamForm({
          type: "setTableDataListPagination",
          tableDataListPagination: pagination,
        });
        dispatchAddTeamForm({
          type: "setTableDataLoading",
          tableDataLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatchAddTeamForm({
          type: "setTableDataLoading",
          tableDataLoading: false,
        });
        messageApi.open({
          type: "error",
          duration: 5,
          content: t("team.teamsNotFetched"),
        });
      });
  };

  return (
    <CreateUser
      userId={addTeamFormContext.activeUserId}
      onComplete={fetchUsers}
      open={addTeamFormContext.openCreateUserDrawer}
      viewOnly={addTeamFormContext.viewOnly}
      setOpen={(isOpen) => {
        dispatchAddTeamForm({
          type: "setOpenCreateUserDrawer",
          openCreateUserDrawer: isOpen,
        });
        if (!isOpen) {
          dispatchAddTeamForm({
            type: "setActiveUserId",
            activeUserId: null,
          });
        }
      }}
    />
  );
}
