import { Comment } from "app/stores/documentComments/types";
import styles from "./CommentItem.module.scss";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import ReplyOnComment from "./ReplyOnComment";
import RepliesOfAComment from "./RepliesOfAComment";
import { MutationStatus } from "@tanstack/react-query";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import { useCommentsDispatch } from "./Provider/CommentsContext";
import { Spin } from "antd";
import { InfoCircle } from "@armtekfrance/armtek-react-design-system/dist/assets";

import("dayjs/locale/en");
import("dayjs/locale/fr");

export default function CommentItem({
  comment,
  showExecutionMonitorId,
  onReply,
  apiStatus,
  executionContextLoading,
  sectionNameInfo,
}: {
  comment: Comment & { replies: Comment[] };
  showExecutionMonitorId: (execution_monitor_id: number) => void;
  onReply: (replyText: string, comment_id: number) => void;
  apiStatus: MutationStatus;
  executionContextLoading: boolean;
  sectionNameInfo:
    | {
        sectionId: number;
        sectionName: string;
        sectionPosition: number;
      }
    | undefined;
}) {
  const { t, i18n } = useTranslation();
  const dispatchComments = useCommentsDispatch();

  dayjs.locale(i18n.resolvedLanguage);

  return (
    <div
      className={`${styles.commentItemWrapper} ${
        comment.section_model_id !== null && styles.commentItemWrapperHover
      }`}
      onMouseOver={() => {
        dispatchComments({
          type: "setSectionIdHovered",
          sectionIdHovered:
            comment.section_model_id !== undefined
              ? comment.section_model_id
              : null,
        });
      }}
      onMouseLeave={() => {
        dispatchComments({
          type: "setSectionIdHovered",
          sectionIdHovered: null,
        });
      }}
    >
      <AvatarComponent
        size={22}
        src={comment?.user?.avatar?.media_content_uid}
        firstName={comment?.user?.first_name}
        lastName={comment?.user?.last_name}
      />
      <div className={styles.commentTextAndActions}>
        <div>
          <p>{`${comment.user.first_name} ${comment.user.last_name}`}</p>
          <p>{dayjs(comment.updated_at).fromNow()}</p>
        </div>
        <div className={styles.executionContext}>
          {sectionNameInfo?.sectionName !== undefined && (
            <p>{sectionNameInfo?.sectionName}</p>
          )}
          {comment.execution_monitor_id !== undefined &&
            comment.execution_monitor_id !== null && (
              <div>
                <InfoCircle />
                <p
                  onClick={() => {
                    if (
                      comment.execution_monitor_id !== undefined &&
                      comment.execution_monitor_id !== null
                    ) {
                      showExecutionMonitorId(comment.execution_monitor_id);
                    }
                  }}
                >
                  {t("doc.models.edit.comment.executionContext")}
                </p>
                <Spin
                  size="small"
                  style={{
                    opacity: `${executionContextLoading ? "1" : "0"}`,
                  }}
                />
              </div>
            )}
        </div>
        <div>
          <p>{comment.content}</p>
        </div>
        <RepliesOfAComment replies={comment.replies} />
        <ReplyOnComment
          onReply={(replyText) => {
            onReply(replyText, comment.id);
          }}
          apiStatus={apiStatus}
        />
      </div>
    </div>
  );
}
