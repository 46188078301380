import { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import styles from "./DropDownNew.module.scss";
import React from "react";

export function DropDownNew({
  children,
  dropdownContent,
  placement = "bottom",
  definedWidth,
  definedButtonBackground,
}: {
  children: JSX.Element;
  dropdownContent: JSX.Element;
  placement?: "bottom" | "bottomLeft" | "bottomRight";
  definedWidth?: string;
  definedButtonBackground?: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (isOpen) setIsOpen(false);
    },
  });

  return (
    <div ref={ref} className={styles.dropDownNewWrapper}>
      <div
        className={styles.dropdownButton}
        style={
          definedButtonBackground
            ? {
                background: definedButtonBackground,
                borderRadius: "10px",
                backgroundColor: definedButtonBackground,
              }
            : undefined
        }
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {React.cloneElement(<>{children}</>, {
          isOpen,
        })}
      </div>
      {isOpen && (
        <div
          className={`${styles.dropdownContent} ${styles[placement]}`}
          style={{ width: definedWidth }}
        >
          {dropdownContent}
        </div>
      )}
    </div>
  );
}
