import { Space } from "antd";
import { useEffect, useState } from "react";
import style from "./style.module.scss";
import { fetchBaselineExecutions } from "../../app/stores/executionFlux/executionFluxClients";
import { getGTEDateOfWeek, getLTEDateOfWeek } from "./GetDateOfWeek";
import ProcedurePreview from "../../components/ProcedurePreview";
import ExecutionPreviewV2 from "../../components/ExecutionTable/ExecutionPreviewV2";
// import DocOpsSlider from "../DashboardLayout/DocOpsSlider";
import { fetchLastWeekDocuments, fetchLastWeekValidations } from "./Util";
import { fetchBanner } from "../../app/stores/banner/bannerClient";
import AlertedBlockPreview from "../../components/AlertedBlockPreview";
import UpdateCard from "../../components/UpdateCard/UpdateCard";
import DocumentsLink from "./Links/DocumentsLink";
import ExecutionLink from "./Links/ExecutionLink";
import PendingValidationLink from "./Links/PendingValidationLink";
import DashboardMaintenance from "components/DashboardMaintenance/DashboardMaintenance";
import ExecutionFluxCard from "components/ExecutionFluxCard/ExecutionFluxCard";
import NewsCard from "components/NewsCard/NewsCard";
import AlertCard from "components/AlertsCard/AlertsCard";
import NotificationsCard from "components/NotificationsCard/NotificationsCard";

export default function Dashboard() {
  const [executionThisWeek, setExecutionThisWeek] = useState<any>([]);
  const [docThisWeek, setdocThisWeek] = useState(0);
  const [validationsThisWeek, setValidationsThisWeek] = useState(0);
  // const [list, setList] = useState<any>([]);
  const [maintenance, setMaintenance] = useState<string>("");
  const [executionPreviewID, setExecutionPreviewID] = useState(0);
  const [isPreviewVisible, setisPreviewVisible] = useState(false);
  const [procedurePreviewId, setProcedurePreviewId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAlertedBlockPreviewVisible, setIsAlertedBlockPreviewVisible] =
    useState(false);

  const handlePreviewCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setisPreviewVisible(false);
  };
  const handleAlertedBlockPreviewCancel = () => {
    setIsAlertedBlockPreviewVisible(false);
  };

  const [alertData, setAlertData] = useState<any>({});

  const onOpenNotificationNotifWithoutLink = (
    web_redirection: string,
    body: string,
    execution_id?: number,
    executed_block_id?: number,
    requested_data?: any
  ) => {
    let n = web_redirection.lastIndexOf("/");
    let dynamic_linkID = web_redirection.substring(n + 1);
    if (
      body.includes("publié") ||
      body.includes("dislike") ||
      body.includes("aimé")
    ) {
      setProcedurePreviewId(dynamic_linkID);
      setIsModalVisible(true);
    } else if (
      body.includes("executé") ||
      body.includes("exécuté") ||
      body.includes("exécution") ||
      body.includes("execution")
    ) {
      if (!execution_id) {
        setExecutionPreviewID(parseInt(dynamic_linkID));
        setisPreviewVisible(true);
      } else {
        setAlertData({
          execution_id: execution_id,
          executed_block_id: executed_block_id,
          requested_data: requested_data,
        });
        setIsAlertedBlockPreviewVisible(true);
      }
    }
  };

  let ThisWeekFilter: any = {
    date: {
      gte: getGTEDateOfWeek(new Date()),
      lte: getLTEDateOfWeek(new Date()),
    },
  };

  useEffect(() => {
    fetchBaselineExecutions(1, 1, ThisWeekFilter).then((response) => {
      setExecutionThisWeek(response.pagination.total);
    });
    fetchLastWeekDocuments().then((response) =>
      setdocThisWeek(response.length)
    );
    fetchLastWeekValidations().then((response) => {
      setValidationsThisWeek(response.length);
    });
    fetchBanner().then((response) => {
      setMaintenance(response);
    });
  }, []);

  return (
    <div className="dashboard">
      <div className="content">
        <div className="content-wrapper bg-white pt-4">
          {maintenance?.length > 1 && (
            <DashboardMaintenance maintenance={maintenance} />
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginLeft: "0%",
              columnGap: "2rem",
            }}
          >
            <DocumentsLink docThisWeek={docThisWeek} />
            <ExecutionLink executionThisWeek={executionThisWeek} />
            <PendingValidationLink validationsThisWeek={validationsThisWeek} />
            <Space size="middle" className={style.tableActions}></Space>
          </div>
          <div // BIG DIV WRAP
            style={{
              marginTop: "24px",
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div // DIV EXECUTION NOTIF ALERT
              style={{
                width: "40%",
                marginRight: " 25px",
                minWidth: "348px",
                marginBottom: "25px",
              }}
            >
              <ExecutionFluxCard />
              <NewsCard />
            </div>
            <div
              style={{ width: "55%", minWidth: "400px" }} // DIV ALERTS NOTIF
            >
              <AlertCard
                onOpenNotificationNotifWithoutLink={
                  onOpenNotificationNotifWithoutLink
                }
              />
              <NotificationsCard
                onOpenNotificationNotifWithoutLink={
                  onOpenNotificationNotifWithoutLink
                }
              />
              <UpdateCard />
            </div>
          </div>
          <br />
        </div>
      </div>
      {isPreviewVisible && (
        <ExecutionPreviewV2
          isModalVisible={isPreviewVisible}
          handleCancel={handleCancel}
          documentId={executionPreviewID}
        />
      )}
      {isModalVisible && (
        <ProcedurePreview
          isModalVisible={isModalVisible}
          handleCancel={handlePreviewCancel}
          documentId={parseInt(procedurePreviewId)}
          page={"baselines"}
        />
      )}
      {isAlertedBlockPreviewVisible && alertData && (
        <AlertedBlockPreview
          isPreviewVisible={isAlertedBlockPreviewVisible}
          handleCancel={handleAlertedBlockPreviewCancel}
          alert={alertData}
        />
      )}
    </div>
  );
}
