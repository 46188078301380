import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  message,
  Typography,
  Result,
  Switch,
  Button as AntButton,
} from "antd";
import EditUserLoader from "./Loader";
import {
  editCompanyInfo,
  fetchCompanyInfo,
} from "../../../app/services/company";
import { useTranslation } from "react-i18next";
import {
  fetchCompanyNotificationPreferences,
  updateCompanyNotificationPreferences,
} from "app/services/companyPreferences";
import NotificationPreferences from "components/NotificationParametrization/NotificationParametrization";
import { getLoggedInUser } from "app/stores/auth/authClient";
import { basePermissions } from "pages/CompanyUsersLayout/permissionData";

const { Title } = Typography;

export default function EditCompany() {
  const [form] = Form.useForm();
  const [dataLoading, setDataLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [dataLoadingError, setDataLoadingError] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<any>({});
  const { t } = useTranslation();
  const userData = getLoggedInUser();

  useEffect(() => {
    form.resetFields();
    (async () => {
      try {
        setDataLoading(true);
        const response = await fetchCompanyInfo();
        if (response.success) {
          const data = response.data;
          form.setFieldsValue({
            ...data,
          });
          setCompanyInfo(data);
          setDataLoadingError(false);
        }
        setDataLoading(false);
      } catch (error) {
        console.error(error);
        setDataLoading(false);
        setDataLoadingError(true);
      }
    })();
  }, []);

  // On Submit company info form
  const onSubmit = async (values: any) => {
    const { ...otherValues } = values;
    type inputsType = typeof otherValues;
    const validValues: inputsType = Object.entries(otherValues).reduce(
      (acc, [key, value]) => {
        acc[key] = value ? value : "";
        return acc;
      },
      {} as inputsType
    );
    try {
      setFormLoading(true);
      const response = await editCompanyInfo(validValues);
      if (response.success) {
        messageApi.open({
          type: "success",
          duration: 5,
          content: t("companyEdit.updatedSuccessfully"),
        });
      }
      setFormLoading(false);
    } catch (error) {
      console.error(error);
      setFormLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("companyEdit.errorNoUpdate"),
      });
    }
  };

  const onChangeEnableScreenshots = () => {
    setCompanyInfo({
      ...companyInfo,
      company_configurations: {
        ...companyInfo.company_configurations,
        enable_screenshots: companyInfo.company_configurations
          ?.enable_screenshots
          ? !companyInfo.company_configurations?.enable_screenshots
          : true,
      },
    });
    editCompanyInfo({
      company_configurations: {
        enable_screenshots: companyInfo.company_configurations
          ?.enable_screenshots
          ? !companyInfo.company_configurations?.enable_screenshots
          : true,
      },
    });
  };

  return (
    <div className="create-user-container p-3">
      {contextHolder}
      <div>
        <div className="create-user-segment">
          <Title level={4}>{t("companyEdit.companyInformation")}</Title>
          {dataLoading ? (
            <EditUserLoader />
          ) : dataLoadingError ? (
            <Result status="error" title={t("companyEdit.errorLoading")} />
          ) : (
            <Form
              name="companyForm"
              form={form}
              layout="vertical"
              onFinish={onSubmit}
              requiredMark
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="abrev" label={t("companyEdit.abbreviation")}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="company_uid"
                    label={t("companyEdit.company_uid")}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label={t("companyEdit.name")}
                    rules={[
                      {
                        required: true,
                        message: t("companyEdit.companyNameError"),
                      },
                    ]}
                  >
                    <Input placeholder={t("companyEdit.companyName")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="website"
                    label={t("companyEdit.website")}
                    rules={[
                      {
                        type: "url",
                        message: t("companyEdit.enterValidUrl"),
                      },
                    ]}
                  >
                    <Input placeholder={t("companyEdit.enterUrl")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="region" label={t("companyEdit.region")}>
                    <Input placeholder={t("companyEdit.companyRegion")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label={t("companyEdit.description")}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder={t("companyEdit.companyDescription")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="EnableScreenshots" label={""}>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("companyEdit.enableScreenshots")}
                    </span>
                    <Switch
                      style={{
                        marginLeft: "10px",
                      }}
                      checked={
                        companyInfo.company_configurations?.enable_screenshots
                          ? true
                          : false
                      }
                      onChange={onChangeEnableScreenshots}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="d-flex justify-content-end">
                    <AntButton
                      htmlType="submit"
                      type="primary"
                      loading={formLoading}
                    >
                      {formLoading ? t("company.saving") : t("button.save")}
                    </AntButton>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </div>
      {(userData.permissions & basePermissions["MANAGE_COMPANY_RIGHT"]) !=
        0 && (
        <div>
          <h3 className="mt-5">
            {t("notificationParametrization.preference")}
          </h3>
          <NotificationPreferences
            onSave={updateCompanyNotificationPreferences}
            fetchData={fetchCompanyNotificationPreferences}
            includePreferenceEditor
          />
        </div>
      )}
    </div>
  );
}
