import {
  ButtonType,
  IconButton,
  SizeType,
} from "@armtekfrance/armtek-react-design-system";
import {
  ApiDocumentBaseline,
  ApiDocumentFamilyEnum,
  ApiDocumentModel,
  ApiDocumentModelSearchRequest,
  ApiExecutionMonitor,
  ApiObject,
} from "@armtekfrance/corenexus-pn-sn";
import {
  ExpandableDocumentList,
  GlobalStore,
  useStoreState,
} from "@armtekfrance/webgate/dist";
import styles from "./DocumentsModels.module.scss";
import { useNavigate } from "react-router-dom";

export default function DocumentsModelsBody() {
  const [filterDocument] = useStoreState(
    GlobalStore.filter.storeFilters.documentModelFilter
  );
  const navigate = useNavigate();

  const filter: ApiDocumentModelSearchRequest = {
    documents: filterDocument!,
    config: {
      extend: true,
      user: {
        extend: true,
      },
    },
  };

  return (
    <ExpandableDocumentList
      object="ApiDocumentModel"
      emitFilter={filter}
      onCheck={() => console.log("")}
      disabled={false}
      size={SizeType.small}
      slim={true}
      actionButtons={(
        prop:
          | ApiExecutionMonitor
          | ApiDocumentModel
          | ApiDocumentBaseline
          | ApiObject
      ) => {
        const typedProp = prop as ApiDocumentModel;
        return (
          <div
            className={styles.actionButtonsContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton
              type={ButtonType.underline}
              size={SizeType.medium}
              dataCy="EditButton"
              iconData="FormOutlined"
              onClick={() =>
                navigate(
                  `/account/${
                    typedProp?.specification?.family ===
                    ApiDocumentFamilyEnum.SYSTEM
                      ? "system"
                      : "knowledge"
                  }/models/edit/${typedProp.id}`
                )
              }
            />
            {/* <DeleteButton
                object={typedProp}
                onDelete={() => {
                  // const previous =
                }}
              /> */}
          </div>
        );
      }}
    />
  );
}
