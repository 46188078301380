import { Button, Col, Input, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineHolder,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  updateDocument,
  updateManyDocuments,
} from "../../app/stores/documents/documentsClient";
import styles from "./style.module.css";
import { useDrag } from "react-dnd";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";

export default function DocDisplayContainer({
  doc,
  setCategoryTree,
  subCategory,
}: any) {
  const { t } = useTranslation();

  const [disableName, setDisableName] = useState(true);
  const inputRef = useRef<any>(null);

  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);

  const [docName, setDocName] = useState(doc.name);
  useEffect(() => {
    setDocName(doc.name);
  }, [doc]);
  const onClickEdit = () => {
    setDisableName(false);
    inputRef.current!.focus();
  };

  // const type = doc.type.startsWith("organisation")
  //   ? "organisation"
  //   : doc.type.startsWith("group")
  //   ? "sets"
  //   : "content";

  let timeoutId: any;
  const onChangeText = (event: any) => {
    setDocName(event.target.value);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        name: event.target.value,
        doc_category_id: doc.doc_category_id,
      };
      updateDocument(doc.id, dataToUpdate);
    }, 2000);
  };

  // eslint-disable-next-line
  const onBlurFunction = (e: any) => {
    setDisableName(true);
  };

  const onArchiveDoc = async () => {
    await updateManyDocuments([doc.id], false);
    setCategoryTree((oldData: any) => {
      let dataSource = [...oldData];
      const indexOfParentCategory = dataSource
        .map((object: any) => parseInt(object.id))
        .indexOf(parseInt(subCategory.parent_id));
      const indexOfSubCategory = dataSource[indexOfParentCategory].subCategories
        .map((object: any) => parseInt(object.id))
        .indexOf(parseInt(subCategory.id));

      const filteredDocs = dataSource[indexOfParentCategory].subCategories[
        indexOfSubCategory
      ].documents.filter((item: any) => parseInt(item.id) !== parseInt(doc.id));
      dataSource[indexOfParentCategory].subCategories[
        indexOfSubCategory
      ].documents = filteredDocs;
      dataSource[indexOfParentCategory].subCategories[
        indexOfSubCategory
      ]._count.document_model -= 1;
      return dataSource;
    });
  };

  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: "document",
      item: {
        type: "document",
        action: "update",
        docId: doc.id,
        currentSubCategory: subCategory,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    []
  );

  return (
    <>
      <div ref={preview} style={{ opacity }}>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "400",
            paddingLeft: "32px",
            paddingRight: "24px",
          }}
          className={styles.editHover}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }} ref={drag}>
              <AiOutlineHolder style={{ cursor: "grab" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1rem",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <p style={{ margin: "0", lineHeight: "0" }}>
                {t(`doc.models.${doc.type}.title`)} -
              </p>
              <Input
                bordered={false}
                maxLength={255}
                value={docName}
                style={{
                  width: "30vw",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginRight: "auto",
                }}
                disabled={disableName}
                ref={inputRef}
                onChange={onChangeText}
                onBlur={onBlurFunction}
              />
            </div>
            <Col>
              <Space>
                <AiOutlineEdit
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={onClickEdit}
                />
                <AiOutlineDelete
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsArchiveModalVisible(true);
                  }}
                />
                <Link
                  to={`/account/${
                    doc.family === "SYSTEM" ? "system" : "knowledge"
                  }/models/edit/${doc.id}`}
                >
                  <Button size="small" type="primary">
                    {t("analytics.model")}
                  </Button>
                </Link>
              </Space>
            </Col>
          </div>
        </div>
      </div>

      <ConfirmActionModal
        isVisible={isArchiveModalVisible}
        handleCancel={() => setIsArchiveModalVisible(false)}
        handleConfirm={() => {
          onArchiveDoc();
          setIsArchiveModalVisible(false);
        }}
        confirmInsideText={t("docDisplayContainer.archiveDocument")}
        confirmHeaderText={t("archived.title")}
      />
    </>
  );
}
