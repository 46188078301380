import ListView from "../ListView";

interface TableType {
  documentRequests: any[];
  loading: boolean;
  paginationDetails: any;
  onChangePagination: (page: number, pageSize: number) => void;
  columns: any[];
  setSelectedTableKeys: (selectedKeys: any[]) => void;
  selectedTableKey: any[];
}

export default function DocumentRequestsTable({
  documentRequests,
  loading,
  paginationDetails,
  onChangePagination,
  columns,
  setSelectedTableKeys,
  selectedTableKey,
}: TableType) {
  const { page, pageSize, total } = paginationDetails;
  return (
    <ListView
      data={documentRequests}
      cardData={documentRequests}
      columns={columns}
      loading={loading}
      loadingCardData={loading}
      isListView={true}
      page={page}
      pageSize={pageSize}
      total={total}
      cardLink={"/account/assets/view/"}
      onChangePagination={onChangePagination}
      setSelectedTableKeys={setSelectedTableKeys}
      selectedKeys={selectedTableKey}
      showCheckBox={false}
    />
  );
}
