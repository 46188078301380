import { useTranslation } from "react-i18next";
import styles from "./MagicInfoNameView.module.scss";

export default function MagicInfoNameView({
  name,
  totalSections,
  totalBlocks,
  type,
  lite,
}: {
  name: string;
  totalSections?: number;
  totalBlocks?: number;
  type?: "models" | "analysis";
  lite: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div
      className={styles.magicInfoNameViewWrapper}
      data-cy="MagicInfoNameView"
    >
      <h3>{`${name}`}</h3>
      {!lite && type !== "analysis" && (
        <p>{`${totalSections} ${t("magic.sections")} / ${totalBlocks} ${t(
          "magic.blocks"
        )}`}</p>
      )}
    </div>
  );
}
