import React from "react";
import ImageDisplayComponent from "../ImageDisplayComponent/ImageDisplayComponent";
import { useTranslation } from "react-i18next";
import VideoPlayerComponent from "../VideoPlayerComponent";

interface CsvPreviewRowProps {
  csvData: any;

  fields: string[];
  mediasOfRequestedDatas: (reqDatas: any) => Array<string>;
}

const csvPreviewRow = ({
  fields,
  mediasOfRequestedDatas,
  csvData,
}: CsvPreviewRowProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          width: "100%",
          overflowY: "scroll",

          whiteSpace: "nowrap",
        }}
      >
        <table>
          <thead
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {fields.map((field) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      paddingLeft: "12px",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {field}
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      width: "100%",
                      height: "100%",
                      paddingLeft: "10px",
                    }}
                  >
                    {field.includes("input_image") ||
                    field.includes("signature") ? (
                      mediasOfRequestedDatas(csvData[field]).map((media) => (
                        <div
                          style={{
                            width: "300px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {media && (
                            <ImageDisplayComponent
                              src={`${media}`}
                              alt={t("operate.imageSent")}
                              thumbnail={true}
                            ></ImageDisplayComponent>
                          )}
                        </div>
                      ))
                    ) : field.includes("input_video") ||
                      field.includes("input_audio") ? (
                      mediasOfRequestedDatas(csvData[field]).map((media) => (
                        <div
                          style={{
                            width: "300px",
                          }}
                        >
                          {media && (
                            <VideoPlayerComponent
                              playVideo={{
                                media_content: {
                                  media_content_uid: media,
                                },
                              }}
                            ></VideoPlayerComponent>
                          )}
                        </div>
                      ))
                    ) : (
                      <p
                        style={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          wordBreak: "break-all",
                          margin: "5px",
                        }}
                      >
                        {csvData[field]}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default csvPreviewRow;
