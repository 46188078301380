import { Col, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

export default function AddTeamFormtextFieldsDescription() {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: t("team.teamDescriptionInput"),
          },
        ]}
      >
        <Input.TextArea
          rows={4}
          maxLength={250}
          placeholder={t("team.teamDescriptionInput")}
        />
      </Form.Item>
    </Col>
  );
}
