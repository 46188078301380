import { Card, Switch, Button } from "antd";
import { BlockTypeConst_NEW } from "../../../../app/stores/blocks/types";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import DefineAlertedUsers, {
  deleteTeamRelatedToUser,
} from "../DataBlock/DefineAlertedUsers";
import AvatarComponent from "../../../AvatarComponent/AvatarComponent";
import { CloseOutlined } from "@ant-design/icons";
import styles from "../ChecklistContent/style.module.css";

interface SignatureInputType {
  data: any;
  disableEdit?: boolean;
  contentId: string | number;
  blockType: string;
  updateContentInDb: (id: string | number, data: any) => void;
  deleteContentInDb: (contentId: string | number) => void;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function SignatureInput({
  data,
  disableEdit,
  contentId,
  blockType,
  updateContentInDb,
  setIsContentBeingSaved,
}: SignatureInputType) {
  const { t } = useTranslation();
  const [reqData, setReqData] = useState(
    data
      ? data
      : {
          mandatory: false,
          instruction: "",
          alertedUsers: [],
          selectedTeams: [],
        }
  );
  let timeoutId: any;
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setReqData(
      data
        ? data
        : {
            mandatory: false,
            instruction: "",
            alertedUsers: [],
            selectedTeams: [],
          }
    );
  }, [data]);

  const changeMandatoryField = (mandatoryData: any) => {
    let reqDataCopy = { ...reqData };
    reqDataCopy["mandatory"] = mandatoryData;
    setReqData(reqDataCopy);
    clearTimeout(timeoutId);
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    updateContentInDb(contentId, dataToUpdate);
  };

  const onDataChange = (e: any) => {
    setIsContentBeingSaved(true);
    let reqDataCopy = { ...reqData };
    reqDataCopy["instruction"] = e.target.value;
    setReqData(reqDataCopy);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: { content: reqDataCopy },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const displayText =
    blockType === BlockTypeConst_NEW.input_image
      ? "image"
      : blockType === BlockTypeConst_NEW.input_video
      ? "video"
      : blockType === BlockTypeConst_NEW.input_audio
      ? "audio"
      : "signature";

  const onShowModal = () => {
    setIsModalOpen(true);
  };

  const onDeleteAlertedUser = async (userId: string) => {
    let reqDataCopy = await deleteTeamRelatedToUser(+userId, reqData);
    reqDataCopy.alertedUsers = reqDataCopy.alertedUsers.filter(
      (user: any) => user.id !== userId
    );
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    updateContentInDb(contentId, dataToUpdate);
    setReqData(reqDataCopy);
  };

  return (
    <Card
      style={{
        backgroundColor: "#F6F9FB",
      }}
      bodyStyle={{ padding: "8px" }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div style={{ marginBottom: "20px", flexGrow: 1 }}>
          <Switch
            checkedChildren={
              blockType === BlockTypeConst_NEW.input_image
                ? t("doc.models.edit.sections.blocks.mandatoryImageRequest")
                : blockType === BlockTypeConst_NEW.input_video
                ? t("doc.models.edit.sections.blocks.mandatoryVideoRequest")
                : blockType === BlockTypeConst_NEW.input_audio
                ? t("doc.models.edit.sections.blocks.mandatoryAudioRequest")
                : t("doc.models.edit.sections.blocks.mandatorySignatureRequest")
            }
            unCheckedChildren={
              blockType === BlockTypeConst_NEW.input_image
                ? t("doc.models.edit.sections.blocks.notMandatoryImageRequest")
                : blockType === BlockTypeConst_NEW.input_video
                ? t("doc.models.edit.sections.blocks.notMandatoryVideoRequest")
                : blockType === BlockTypeConst_NEW.input_audio
                ? t("doc.models.edit.sections.blocks.notMandatoryAudioRequest")
                : t(
                    "doc.models.edit.sections.blocks.notMandatorySignatureRequest"
                  )
            }
            checked={reqData?.mandatory}
            disabled={disableEdit}
            onChange={changeMandatoryField}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            style={{
              marginBottom: "8px",
            }}
            disabled={disableEdit}
            onClick={onShowModal}
          >
            {t("doc.models.edit.sections.blocks.alertUsers")}
          </Button>
          {reqData.alertedUsers && reqData.alertedUsers.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "200px",
              }}
            >
              {reqData.alertedUsers.map((user: any) => (
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    marginBottom: "8px",
                    marginRight: "8px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                    }}
                    className={styles.closeIcon}
                  >
                    <CloseOutlined
                      style={{
                        color: "white",
                      }}
                    ></CloseOutlined>
                  </div>
                  <div
                    className={styles.avatar}
                    style={{
                      // marginRight: "8px",
                      position: "relative",
                      bottom: "32px",
                      left: "0px",
                      marginTop: "0px",
                    }}
                    onClick={() => onDeleteAlertedUser(user.id)}
                  >
                    <AvatarComponent
                      src=""
                      key={user.id}
                      firstName={user.first_name}
                      lastName={user.last_name}
                      style={{
                        padding: "0px",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <TextArea
          placeholder={`${t("inputTextContent.instruction")} ${displayText}`}
          bordered={false}
          autoSize
          value={reqData?.instruction}
          onChange={(e) => onDataChange(e)}
          disabled={disableEdit}
          style={{
            marginTop: "4px",
            paddingLeft: "0px",
            fontSize: "14px",
            margin: "0px",
          }}
        />
      </div>
      {isModalOpen && (
        <DefineAlertedUsers
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          contentId={contentId}
          updateContentInDb={updateContentInDb}
          inputData={reqData}
          setInputData={setReqData}
        />
      )}
    </Card>
  );
}
