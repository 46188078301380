import InputSearch from "components/TempDS/InputSearch/InputSearch";
import styles from "./MediaPickerSearch.module.scss";
import { useTranslation } from "react-i18next";
// import { MediaStore } from "Stores/media";
import { GlobalStore } from "@armtekfrance/webgate/dist";

export default function MediaPickerModalSearch() {
  const { t } = useTranslation();
  return (
    <div className={styles.mediaPickerModalSearchWrapper}>
      <InputSearch
        placeholder={t("menu.search")}
        onSearch={(value: string) => {
          GlobalStore.media.setSearchText(value);
        }}
      />
    </div>
  );
}
