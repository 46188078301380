import { ApiObjectUpsert } from "@armtekfrance/corenexus-pn-sn";
import {
  ButtonType,
  IconButton,
  ObjectCloudActions,
} from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";
import { Button } from "antd";
import Popover from "antd/lib/popover";
import { useNavigate } from "react-router-dom";
import { tap } from "rxjs";

export default function MagicBaselineConstructor({
  baselineId,
}: {
  baselineId: number;
}) {
  const navigate = useNavigate();

  const onClickItem = () => {
    if (baselineId) {
      const objectRequest: ApiObjectUpsert = {
        baseline_id: baselineId,
      };
      Manager.API.emit(ObjectCloudActions.OBJECT_UPSERT, {
        objects: [objectRequest],
        config: {
          extend: true,
          object_constructor: {
            extend: true,
          },
        },
      })
        .pipe(
          tap((data) => {
            if (data.response?.objects?.length > 0) {
              const constructorId =
                data.response.objects[0].data?.object_constructor?.id;
              if (constructorId) {
                navigateToConstructor(
                  constructorId,
                  data.response.objects[0].id
                );
              }
            }
          })
        )
        .subscribe();
    }
  };

  const navigateToConstructor = (constructorId: number, objectId: number) => {
    // TODO : Replace with navigation service later
    navigate(
      `/account/system/assign/constructor/${baselineId}/${constructorId}/${objectId}`
    );
  };

  return (
    <Popover
      content={
        <Button onClick={() => onClickItem()}>Single serial number</Button>
      }
      placement="bottomLeft"
      showArrow={false}
      trigger="click"
    >
      <IconButton type={ButtonType.underline} iconData="PlusCircleOutlined" />
    </Popover>
  );
}
