import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import styles from "./ModelInfoPage.module.scss";
import {
  fetchDocumentByIDAsync,
  getCurrentDocument,
  getDocumentCurrentPending,
} from "../../app/stores/documents/documentsSlice";
import { updateDocument } from "../../app/stores/documents/documentsClient";
import { fetchSections } from "../../app/stores/sections/sectionsClient";
import ModelInfoHeader from "../../components/ModelInfoHeader/ModelInfoHeader";
import ModelInfoCard from "../../components/ModelInfoCard/ModelInfoCard";
import ModelStepsAndAttach from "../../components/ModelStepsAndAttach";
import { fetchQRCodes } from "../../app/stores/qrCode/qrCodeClient";
import CommentsRoot from "components/Comments/CommentsRoot";

let timeoutId: any;
export const onChangeDocumentData = (data: any, currentDocument: any) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(function () {
    // Runs 2 second (2000 ms) after the last change
    saveToDB(data, currentDocument);
  }, 2000);
};

export function saveToDB(data: any, currentDocument: any) {
  let docToUpdate = { ...currentDocument, ...data };
  const docId = docToUpdate["id"];
  delete docToUpdate["id"];
  updateDocument(docId, docToUpdate);
}

export default function ModelInfoPage() {
  const [isContentBeingSaved, setIsContentBeingSaved] = useState<any>(false);

  const onChangeDocumentDataProps = (data: any) => {
    setIsContentBeingSaved(true);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      saveToDB(data, currentDocument);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [sectionsList, setSectionList] = useState<any[]>([]);
  const currentDocument: any = useAppSelector(getCurrentDocument);
  const currentDocumentPending: boolean = useAppSelector(
    getDocumentCurrentPending
  );

  const [qrData, setQrData] = useState<any>({});
  const [qrDataPending, setQrDataPending] = useState(true);

  useEffect(() => {
    if (id) {
      dispatch(fetchDocumentByIDAsync(id));
      const filters = { document_model_id: id };
      const sort = { position: "asc" };
      const page = 1;
      const pageSize = "0";
      fetchSections(filters, sort, page, pageSize).then((response) => {
        if (response.success) {
          setSectionList(response.data.sections);
        }
      });
      fetchQRCode(id).then((response) => {
        setQrData(response);
        setQrDataPending(false);
      });
    }
  }, [dispatch, id]);

  const fetchQRCode = async (docId: any) => {
    let qrFilter = {
      document_model_id: parseInt(docId),
    };
    const qrCode = await fetchQRCodes(1, 1, qrFilter);
    if (qrCode.qrCodes) {
      return qrCode.qrCodes;
    } else {
      return null;
    }
  };

  const [sectionIdHovered, setSectionIdHovered] = useState<number | null>(null);

  return (
    <div>
      {!currentDocumentPending && !qrDataPending ? (
        <div className={styles.modelInfoPageWrapper}>
          <ModelInfoHeader
            page="info"
            id={id}
            currentDocument={currentDocument}
            generatePDFDocument={() => {
              return;
            }}
            qrData={qrData}
            onChangeDocumentDataProps={onChangeDocumentDataProps}
            isContentBeingSaved={isContentBeingSaved}
          />
          <ModelInfoCard currentDocument={currentDocument} qrData={qrData} />
          <ModelStepsAndAttach
            sectionsList={sectionsList}
            documentId={currentDocument.id}
            commentSectionIdHovered={sectionIdHovered}
          />
          <CommentsRoot
            document_model_id={currentDocument.id}
            sectionIdHovered={(sectionId) => {
              setSectionIdHovered(sectionId);
            }}
            sectionNameInfos={null}
          />
        </div>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
}
