import { ReactElement } from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import styles from "./ValidationStatus.module.scss";

interface IStatus {
  status: "PENDING" | "VALIDATED" | "REFUSED";
  statusDate: string;
}

export default function ValidationStatus({ status }: IStatus): ReactElement {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "2px 0",
      }}
    >
      <div>
        {status === "PENDING" && (
          <AiOutlineCheckCircle
            className={styles.pending}
            color="#BFBFBF"
            size={32}
          />
        )}
        {status === "VALIDATED" && (
          <AiOutlineCheckCircle color="#96D970" size={32} />
        )}
        {status === "REFUSED" && (
          <AiOutlineCloseCircle color="#BE0613" size={32} />
        )}
      </div>
    </div>
  );
}
