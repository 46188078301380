import { useEffect, useState } from "react";
import { Modal, Button, notification } from "antd";
import { createLinkageNode } from "../../../app/services/documentLinkage";
import MagicDocumentModelListItem from "../../../components/MagicListItem/MagicDocumentModelListItem";
import { useTranslation } from "react-i18next";
import AddLinkageNodeHero from "./Hero/AddLinkageNodeHero";
import { AddLinkageNodeList } from "./AddLinkageNodeList";
import PaginationLayout from "components/PaginationLayout";
import {
  useAddLinkageNode,
  useAddLinkageNodeDispatch,
} from "./Provider/AddLinkageNodeContext";
import { fetchDocumentsModelsTanStack } from "app/stores/documents/documentsClient";
import styles from "./AddLinkageNode.module.scss";
import { DocumentType } from "app/stores/documents/types";

export interface AddNodeProps {
  open: boolean;
  parentNodeId: number | null;
  onComplete: () => void;
  setOpen: (isOpen: boolean) => void;
}

export default function AddLinkageNode({
  open,
  setOpen,
  onComplete,
  parentNodeId,
}: AddNodeProps) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [notificationApi] = notification.useNotification();
  const addLinkageNodeContext = useAddLinkageNode();
  const dispatchAddLinkageNode = useAddLinkageNodeDispatch();

  const { status, error, documentsModels } = fetchDocumentsModelsTanStack(
    addLinkageNodeContext.pagination.page,
    addLinkageNodeContext.pagination.pageSize,
    {
      ...(addLinkageNodeContext.typeFilter !== "" && {
        type: addLinkageNodeContext.typeFilter,
      }),
      name: { contains: addLinkageNodeContext.queryNameTextFilter },
      count: true,
      qr_code: true,
    }
  );

  // Add the selected document as nodes to parentId
  const saveSelectedDocuments = async () => {
    setSaving(true);
    try {
      const result = await createLinkageNode({
        parent_id: parentNodeId,
        document_model_ids: addLinkageNodeContext.selectedItems,
      });
      if (result.success) {
        dispatchAddLinkageNode({ type: "resetSelectedItems" });
        dispatchAddLinkageNode({ type: "updateSelectedItems" });
        onComplete();
      } else {
        notificationApi.error({
          message: `${t("doc.models.linkage.errorOccured")}`,
          description: `${t("doc.models.linkage.errorWhileSaving")}`,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error(error);
      notificationApi.error({
        message: `${t("doc.models.linkage.errorOccured")}`,
        description: `${t("doc.models.linkage.errorWhileSaving")}`,
        placement: "topRight",
      });
    }
    setSaving(false);
  };

  useEffect(() => {
    dispatchAddLinkageNode({
      type: "setDocumentModelsTanstackApi",
      status: status,
      error: error,
    });
    if (status === "success") {
      dispatchAddLinkageNode({
        type: "setDocumentModelsTanstack",
        documentModels: documentsModels!.documentsModels.map(
          (documentModel) => {
            return { ...documentModel, isSelected: false };
          }
        ),
        pagination: documentsModels?.pagination ?? {
          page: 1,
          pageSize: 10,
          total: -1,
        },
      });
      dispatchAddLinkageNode({ type: "updateSelectedItems" });
    }
  }, [status, error, documentsModels]);

  function setPaginationPageSize(page: number, pageSize: number) {
    dispatchAddLinkageNode({
      type: "setPagination",
      pagination: {
        page: page,
        pageSize: pageSize,
        total: documentsModels!.pagination.total ?? -1,
      },
    });
  }

  return (
    <>
      <Modal
        className={styles.addLinkageNodeWrapper}
        title={t("doc.models.linkage.selectModelAdd")}
        onCancel={() => {
          setOpen(false);
        }}
        open={open}
        width={1500}
        footer={null}
        // closable={false}
        bodyStyle={{ padding: 0, paddingRight: "1rem" }}
        style={{ top: 20 }}
      >
        <AddLinkageNodeHero />
        {status === "success" && (
          <>
            <AddLinkageNodeList />
            <PaginationLayout
              page={addLinkageNodeContext!.pagination.page}
              pageSize={addLinkageNodeContext!.pagination.pageSize}
              total={addLinkageNodeContext!.pagination.total}
              onChangePagination={setPaginationPageSize}
            />
            <div className={styles.bottomButtons}>
              <Button
                type="primary"
                disabled={saving}
                onClick={() => {
                  dispatchAddLinkageNode({ type: "resetSelectedItems" });
                  dispatchAddLinkageNode({ type: "updateSelectedItems" });
                  setOpen(false);
                }}
              >
                {t("doc.models.linkage.cancel")}
              </Button>
              <Button
                type="primary"
                disabled={!addLinkageNodeContext.selectedItems.length}
                onClick={saveSelectedDocuments}
                loading={saving}
              >
                {t("doc.models.linkage.createLinkage")}
              </Button>
            </div>
          </>
        )}
        {status === "loading" && (
          <div className={styles.loading}>
            {Array.from(
              Array(documentsModels?.pagination?.pageSize ?? 10).keys()
            ).map(() => {
              return (
                <MagicDocumentModelListItem
                  isLoading={true}
                  documentModel={{} as DocumentType & { isSelected: boolean }}
                />
              );
            })}
          </div>
        )}
      </Modal>
    </>
  );
}
