import { Link } from "react-router-dom";
import StepCard from "./StepCard";
import { useTranslation } from "react-i18next";
import styles from "./ModelInfoSteps.module.scss";

export default function ModelInfoSteps({
  sectionsList,
  documentId,
  commentSectionIdHovered,
}: {
  sectionsList: any[];
  documentId: string | number;
  commentSectionIdHovered: number | null;
}) {
  const { t } = useTranslation();
  // const { type } = useParams();

  return (
    <div className={styles.modelInfoStepsWrapper}>
      {sectionsList?.length > 0 ? (
        <>
          {sectionsList.map((section: any) => (
            <Link
              to={`/account/knowledge/models/edit/${documentId}/${section.id}`}
            >
              <div className={styles.stepCardWrapper}>
                <StepCard
                  section={section}
                  stepStyle={{
                    margin: "0",
                    paddingBottom: `${
                      commentSectionIdHovered === section.id ? "0" : "2px"
                    }`,
                    border: `${
                      commentSectionIdHovered === section.id
                        ? "dashed #5d87d8 2px"
                        : "#030852 solid 1px"
                    }`,
                  }}
                />
              </div>
            </Link>
          ))}
        </>
      ) : (
        <p>{t("modelInfoSteps.noStepsAdded")}</p>
      )}
    </div>
  );
}
