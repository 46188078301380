import { Button, InputNumber, message, Popover } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TableBlockType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => void;
  disableEdit?: boolean;
  contentId: string | number;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function TableBlock({
  data,
  updateContentInDb,
  disableEdit,
  contentId,
  setIsContentBeingSaved,
}: TableBlockType) {
  const [tableData, setTableData] = useState<any>(
    data
      ? data
      : {
          title: "",
          datas: [],
          columns: [],
          rows: [],
        }
  );

  useEffect(() => {
    setTableData(
      data
        ? data
        : {
            title: "",
            datas: [],
            columns: [],
            rows: [],
          }
    );
  }, [data]);

  const [vertical, setVertical] = useState<number | null | undefined>(
    data && data.datas ? data.datas.length : 0
  );
  const [horizontal, setHorizontal] = useState<number | null | undefined>(
    data && data.columns ? data.columns.length : 0
  );

  const [oldVertical, setOldVertical] = useState(
    data && data.datas ? data.datas.length : 0
  );
  const [oldHorizontal, setOldHorizontal] = useState(
    data && data.columns ? data.columns?.length : 0
  );

  const { t } = useTranslation();

  const createTable = () => {
    if (
      vertical &&
      !isNaN(vertical) &&
      vertical > 1 &&
      horizontal &&
      !isNaN(horizontal) &&
      horizontal > 1
    ) {
      if (horizontal === oldHorizontal && vertical === oldVertical) {
        message.error(t("tableBlock.changeRowCol"));
      } else {
        setIsContentBeingSaved(true);
        let ColTest: any = [];
        let RowTest: any = [];
        let dataTest: any = [];

        if (!tableData.columns || tableData.columns.length === 0) {
          const colArray = new Array(horizontal).fill(null);
          const rowArray = new Array(vertical).fill(null);
          let dataArray = [];

          for (let i = 0; i < vertical; i++) {
            let newArrayToFill = new Array(horizontal).fill(null);
            dataArray.push(newArrayToFill);
          }

          setTableData({
            title: tableData.title,
            datas: dataArray,
            columns: colArray,
            rows: rowArray,
          });
          ColTest = colArray;
          RowTest = rowArray;
          dataTest = dataArray;

          const dataToUpdate = {
            title: tableData.title,
            datas: dataTest,
            columns: ColTest,
            rows: RowTest,
          };

          const dbData = {
            content: {
              content: dataToUpdate,
            },
          };

          updateContentInDb(contentId, dbData);
          setIsContentBeingSaved(false);
          setTableData(dataToUpdate);
        } else if (tableData.columns.length > 0 && tableData.rows.length > 0) {
          setIsContentBeingSaved(true);
          let colArray = [];
          let rowArray = [];
          let dataArray = [];
          if (vertical < tableData.rows.length) {
            rowArray = tableData.rows.slice(0, vertical);
          } else if (vertical > tableData.rows.length) {
            let additionalArray = new Array(
              vertical - tableData.rows.length
            ).fill(null);
            rowArray = tableData.rows.concat(additionalArray);
          } else {
            rowArray = tableData.rows;
          }

          if (horizontal < tableData.columns.length) {
            colArray = tableData.columns.slice(0, horizontal);
            for (let i = 0; i < vertical; i++) {
              if (tableData.datas[i]) {
                dataArray.push(tableData.datas[i].slice(0, horizontal));
              } else {
                dataArray.push(new Array(horizontal).fill(null));
              }
            }
          } else if (horizontal > tableData.columns.length) {
            let additionalArray = new Array(
              horizontal - tableData.columns.length
            ).fill(null);
            colArray = tableData.columns.concat(additionalArray);
            for (let i = 0; i < vertical; i++) {
              if (tableData.datas[i]) {
                let arrayData = tableData.datas[i];
                let extraData = new Array(
                  horizontal - tableData.columns.length
                ).fill(null);
                arrayData = tableData.datas[i].concat(extraData);
                dataArray.push(arrayData);
              } else {
                dataArray.push(new Array(horizontal).fill(null));
              }
            }
          } else {
            colArray = tableData.columns;
            if (vertical < tableData.rows.length) {
              dataArray = tableData.datas.slice(0, vertical);
            } else if (vertical > tableData.rows.length) {
              dataArray = tableData.datas;
              for (let i = 0; i < vertical - tableData.rows.length; i++) {
                dataArray.push(new Array(horizontal).fill(null));
              }
            }
          }
          setTableData({
            title: tableData.title,
            rows: rowArray,
            columns: colArray,
            datas: dataArray,
          });
          ColTest = colArray;
          RowTest = rowArray;
          dataTest = dataArray;

          const dataToUpdate = {
            title: tableData.title,
            datas: dataTest,
            columns: ColTest,
            rows: RowTest,
          };

          const dbData = {
            content: {
              content: dataToUpdate,
            },
          };
          updateContentInDb(contentId, dbData);
          setIsContentBeingSaved(false);
          setTableData(dataToUpdate);
          setOldHorizontal(horizontal);
          setOldVertical(vertical);
        }
      }
    }
  };

  let timeoutId: any;
  const onColumnsChange = (data: any, key: number) => {
    setIsContentBeingSaved(true);
    let copyTable = { ...tableData };
    copyTable.columns[key] = data.target.value;
    setTableData(copyTable);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: copyTable,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const onRowsChange = (data: any, key: number) => {
    setIsContentBeingSaved(true);
    let copyTable = { ...tableData };
    copyTable.rows[key] = data.target.value;
    setTableData(copyTable);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: copyTable,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const onDataRowChange = (data: any, key: number, key1: number) => {
    setIsContentBeingSaved(true);
    let copyTable = { ...tableData };
    copyTable.datas[key][key1] = data.target.value;
    setTableData(copyTable);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: copyTable,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const onTitleChange = (data: any) => {
    setIsContentBeingSaved(true);
    let copyTable = { ...tableData };
    copyTable.title = data.target.value;
    setTableData(copyTable);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: copyTable,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  return (
    <div style={{ backgroundColor: "#F6F9FB", padding: "16px" }}>
      <TextArea
        value={tableData.title}
        onChange={onTitleChange}
        placeholder="Enter title"
        style={{ marginBottom: "16px", fontWeight: "bold" }}
        autoSize
        bordered={false}
        disabled={disableEdit}
      />
      {!disableEdit && (
        <Popover
          trigger="click"
          content={
            <div>
              <h6>{t("tableBlock.tableSize")}</h6>
              <InputNumber
                addonBefore="V"
                value={horizontal}
                style={{ width: "104px" }}
                onChange={(e) => setHorizontal(e)}
                min={2}
                disabled={disableEdit}
              />
              <InputNumber
                addonBefore="H"
                value={vertical}
                style={{ marginLeft: "16px", width: "104px" }}
                onChange={(e) => setVertical(e)}
                max={100}
                min={2}
                disabled={disableEdit}
              />
              <div style={{ marginTop: "8px" }}>
                <Button
                  disabled={disableEdit}
                  type="primary"
                  onClick={createTable}
                >
                  OK
                </Button>
              </div>
            </div>
          }
        >
          <Button>{t("tableBlock.showSettings")}</Button>
        </Popover>
      )}

      {tableData.datas?.length > 0 ? (
        <table
          style={{
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <tr
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <tr style={{ display: "flex", flexDirection: "column" }}>
                <tr>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        borderRight: "solid 0.2px black",
                        backgroundColor: "#FAFAFA",
                      }}
                    >
                      <th style={{ width: "100%" }}>
                        <TextArea disabled={true} bordered={false} autoSize />
                      </th>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {tableData?.columns?.map((col: any, index: number) => (
                        <div
                          style={{
                            backgroundColor: "#FAFAFA",

                            borderRadius: "0px",
                          }}
                        >
                          <th>
                            <TextArea
                              disabled={disableEdit}
                              value={tableData.columns[index]}
                              onChange={(data) => onColumnsChange(data, index)}
                              placeholder={t(
                                "doc.models.edit.sections.blocks.tableTitle"
                              )}
                              bordered={false}
                              autoSize
                            ></TextArea>
                          </th>
                        </div>
                      ))}
                    </div>
                  </div>
                  <tr>
                    {tableData?.datas?.map((data: any, index: number) => (
                      <tr
                        style={{
                          borderTop: "solid 0.2px black",
                        }}
                      >
                        <td
                          style={{
                            borderRight: "solid 0.2px black",
                          }}
                        >
                          <TextArea
                            disabled={disableEdit}
                            onChange={(data) => onRowsChange(data, index)}
                            value={tableData?.rows ? tableData.rows[index] : ""}
                            placeholder={t(
                              "doc.models.edit.sections.blocks.rowTitle"
                            )}
                            bordered={false}
                            autoSize
                          />
                        </td>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {data &&
                            Array.isArray(data) &&
                            data?.map((rowData: any, index1: number) => (
                              <tr
                                style={{
                                  borderRadius: "0px",
                                }}
                              >
                                <th>
                                  <TextArea
                                    disabled={disableEdit}
                                    value={tableData.datas[index][index1]}
                                    onChange={(data) =>
                                      onDataRowChange(data, index, index1)
                                    }
                                    placeholder={t(
                                      "doc.models.edit.sections.blocks.tableData"
                                    )}
                                    bordered={false}
                                    autoSize
                                  ></TextArea>
                                </th>
                              </tr>
                            ))}
                        </div>
                      </tr>
                    ))}
                  </tr>
                </tr>
              </tr>
            </tr>
          </div>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
}
