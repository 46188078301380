import AddTeam from "./AddTeam";
import styles from "./TeamLayout.module.scss";

export default function TeamLayout() {
  return (
    <div className={styles.teamLayoutWrapper}>
      <div>
        <AddTeam />
      </div>
    </div>
  );
}
