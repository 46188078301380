import { Button, Card, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefineAlertedUsers, {
  deleteTeamRelatedToUser,
} from "../DataBlock/DefineAlertedUsers";
import AvatarComponent from "../../../AvatarComponent/AvatarComponent";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./style.module.css";

interface InputTextType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => void;
  disableEdit?: boolean;
  contentId: number | string;
  deleteContentInDb: (contentId: string | number) => void;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function InputTextContent({
  data,
  updateContentInDb,
  disableEdit,
  contentId,
  setIsContentBeingSaved,
}: InputTextType) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputTextData, setInputTextData] = useState(
    data
      ? data
      : {
          title: "",
          value: "",
          mandatory: false,
          alertedUsers: [],
          selectedTeams: [],
        }
  );

  useEffect(() => {
    setInputTextData(
      data
        ? data
        : {
            title: "",
            value: "",
            mandatory: false,
            alertedUsers: [],
            selectedTeams: [],
          }
    );
  }, [data]);

  let timeoutId: any;
  const onDataChange = (e: any) => {
    setIsContentBeingSaved(true);
    let reqDataCopy = { ...inputTextData };
    reqDataCopy["title"] = e.target.value;
    setInputTextData(reqDataCopy);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: { content: reqDataCopy },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const changeMandatoryField = (fieldData: any) => {
    const dataToUpdate = {
      content: { content: { ...inputTextData, mandatory: fieldData } },
    };
    updateContentInDb(contentId, dataToUpdate);
    setInputTextData((oldData: any) => {
      return {
        ...oldData,
        mandatory: fieldData,
      };
    });
  };

  const onShowModal = () => {
    setIsModalOpen(true);
  };

  const onDeleteAlertedUser = async (userId: string) => {
    let reqDataCopy = await deleteTeamRelatedToUser(+userId, inputTextData);
    reqDataCopy.alertedUsers = reqDataCopy.alertedUsers.filter(
      (user: any) => user.id !== userId
    );
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    updateContentInDb(contentId, dataToUpdate);
    setInputTextData(reqDataCopy);
  };

  return (
    <Card
      style={{
        backgroundColor: "#F6F9FB",
      }}
      bodyStyle={{ padding: "8px" }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div style={{ marginBottom: "20px", flexGrow: 1 }}>
          <Switch
            checkedChildren={t(
              "doc.models.edit.sections.blocks.mandatoryTextRequest"
            )}
            unCheckedChildren={t(
              "doc.models.edit.sections.blocks.notMandatoryTextRequest"
            )}
            checked={inputTextData?.mandatory}
            disabled={disableEdit}
            onChange={changeMandatoryField}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            style={{
              marginBottom: "8px",
            }}
            disabled={disableEdit}
            onClick={onShowModal}
          >
            {t("doc.models.edit.sections.blocks.alertUsers")}
          </Button>
          {inputTextData.alertedUsers &&
          inputTextData.alertedUsers.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "200px",
              }}
            >
              {inputTextData.alertedUsers.map((user: any) => (
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    marginBottom: "8px",
                    marginRight: "8px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                    }}
                    className={styles.closeIcon}
                  >
                    <CloseOutlined
                      style={{
                        color: "white",
                      }}
                    ></CloseOutlined>
                  </div>
                  <div
                    className={styles.avatar}
                    style={{
                      // marginRight: "8px",
                      position: "relative",
                      bottom: "32px",
                      left: "0px",
                      marginTop: "0px",
                    }}
                    onClick={() => onDeleteAlertedUser(user.id)}
                  >
                    <AvatarComponent
                      src=""
                      key={user.id}
                      firstName={user.first_name}
                      lastName={user.last_name}
                      style={{
                        padding: "0px",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <TextArea
          placeholder={t("inputTextContent.instructionDescription")}
          bordered={false}
          autoSize
          value={inputTextData?.title}
          onChange={(e) => onDataChange(e)}
          disabled={disableEdit}
          style={{
            marginTop: "4px",
            paddingLeft: "0px",
            fontSize: "14px",
            margin: "0px",
          }}
        />
      </div>
      {isModalOpen && (
        <DefineAlertedUsers
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          contentId={contentId}
          updateContentInDb={updateContentInDb}
          inputData={inputTextData}
          setInputData={setInputTextData}
        />
      )}
    </Card>
  );
}
