import { PaginationType } from "app/stores/pagination/types";
import { IUser } from "app/stores/users/types";

export type stateAddTeamFormProvider = {
  selectedUsers: number[];
  defaultSelectedUsers: number[];
  teamLoading: boolean;
  formLoading: boolean;
  activeUserId: number | null;
  viewOnly: boolean;
  openCreateUserDrawer: boolean;
  searchText: string;
  tableDataList: IUser[];
  tableDataLoading: boolean;
  tableDataListPagination: PaginationType;
  page: number;
  pageSize: number;
  isPopOverVisibleNickname: boolean;
  suggestedTeamNickname: string;
  isAddDeptOpen: boolean;
  companyDeptList: any;
  deptListLoading: boolean;
};

export type TypeAddTeamFormContextReducerAction =
  | {
      type: "setSelectedUsers";
      selectedUsers: stateAddTeamFormProvider["selectedUsers"];
    }
  | {
      type: "setDefaultSelectedUsers";
      defaultSelectedUsers: stateAddTeamFormProvider["defaultSelectedUsers"];
    }
  | {
      type: "setAllSelectedUsers";
      value: [];
    }
  | {
      type: "setTeamLoading";
      teamLoading: stateAddTeamFormProvider["teamLoading"];
    }
  | {
      type: "setFormLoading";
      formLoading: stateAddTeamFormProvider["formLoading"];
    }
  | {
      type: "setActiveUserId";
      activeUserId: stateAddTeamFormProvider["activeUserId"];
    }
  | {
      type: "setViewOnly";
      viewOnly: stateAddTeamFormProvider["viewOnly"];
    }
  | {
      type: "setOpenCreateUserDrawer";
      openCreateUserDrawer: stateAddTeamFormProvider["openCreateUserDrawer"];
    }
  | {
      type: "setSearchText";
      searchText: stateAddTeamFormProvider["searchText"];
    }
  | {
      type: "setTableDataList";
      tableDataList: stateAddTeamFormProvider["tableDataList"];
    }
  | {
      type: "setTableDataLoading";
      tableDataLoading: stateAddTeamFormProvider["tableDataLoading"];
    }
  | {
      type: "setTableDataListPagination";
      tableDataListPagination: stateAddTeamFormProvider["tableDataListPagination"];
    }
  | {
      type: "setPage";
      page: stateAddTeamFormProvider["page"];
    }
  | {
      type: "setPageSize";
      pageSize: stateAddTeamFormProvider["pageSize"];
    }
  | {
      type: "setIsPopOverVisibleNickname";
      isPopOverVisibleNickname: stateAddTeamFormProvider["isPopOverVisibleNickname"];
    }
  | {
      type: "setSuggestedTeamNickname";
      suggestedTeamNickname: stateAddTeamFormProvider["suggestedTeamNickname"];
    }
  | {
      type: "setIsAddDeptOpen";
      isAddDeptOpen: stateAddTeamFormProvider["isAddDeptOpen"];
    }
  | {
      type: "setCompanyDeptList";
      companyDeptList: stateAddTeamFormProvider["companyDeptList"];
    }
  | {
      type: "setDeptListLoading";
      deptListLoading: stateAddTeamFormProvider["deptListLoading"];
    }
  | {
      type: "setSearchValues";
      page: stateAddTeamFormProvider["page"];
      pageSize: stateAddTeamFormProvider["pageSize"];
      searchText: stateAddTeamFormProvider["searchText"];
    }
  | {
      type: "resetSuggestedDropdown";
    }
  | {
      type: "setSuggestedDropdownValues";
      suggestedTeamNickname: stateAddTeamFormProvider["suggestedTeamNickname"];
      isPopOverVisibleNickname: stateAddTeamFormProvider["isPopOverVisibleNickname"];
    };

export function addTeamFormReducer(
  stateAddTeamForm: stateAddTeamFormProvider,
  action: TypeAddTeamFormContextReducerAction
): stateAddTeamFormProvider {
  switch (action.type) {
    case "setSelectedUsers": {
      return {
        ...stateAddTeamForm,
        selectedUsers: action.selectedUsers,
      };
    }
    case "setDefaultSelectedUsers": {
      return {
        ...stateAddTeamForm,
        defaultSelectedUsers: action.defaultSelectedUsers,
      };
    }
    case "setAllSelectedUsers": {
      return {
        ...stateAddTeamForm,
        selectedUsers: action.value,
        defaultSelectedUsers: action.value,
      };
    }
    case "setTeamLoading": {
      return {
        ...stateAddTeamForm,
        teamLoading: action.teamLoading,
      };
    }
    case "setFormLoading": {
      return {
        ...stateAddTeamForm,
        formLoading: action.formLoading,
      };
    }
    case "setActiveUserId": {
      return {
        ...stateAddTeamForm,
        activeUserId: action.activeUserId,
      };
    }
    case "setViewOnly": {
      return {
        ...stateAddTeamForm,
        viewOnly: action.viewOnly,
      };
    }
    case "setOpenCreateUserDrawer": {
      return {
        ...stateAddTeamForm,
        openCreateUserDrawer: action.openCreateUserDrawer,
      };
    }
    case "setSearchText": {
      return {
        ...stateAddTeamForm,
        searchText: action.searchText,
      };
    }
    case "setTableDataList": {
      return {
        ...stateAddTeamForm,
        tableDataList: action.tableDataList,
      };
    }
    case "setTableDataLoading": {
      return {
        ...stateAddTeamForm,
        tableDataLoading: action.tableDataLoading,
      };
    }
    case "setTableDataListPagination": {
      return {
        ...stateAddTeamForm,
        tableDataListPagination: action.tableDataListPagination,
      };
    }
    case "setPage": {
      return {
        ...stateAddTeamForm,
        page: action.page,
      };
    }
    case "setPageSize": {
      return {
        ...stateAddTeamForm,
        pageSize: action.pageSize,
      };
    }
    case "setIsPopOverVisibleNickname": {
      return {
        ...stateAddTeamForm,
        isPopOverVisibleNickname: action.isPopOverVisibleNickname,
      };
    }
    case "setSuggestedTeamNickname": {
      return {
        ...stateAddTeamForm,
        suggestedTeamNickname: action.suggestedTeamNickname,
      };
    }
    case "setIsAddDeptOpen": {
      return {
        ...stateAddTeamForm,
        isAddDeptOpen: action.isAddDeptOpen,
      };
    }
    case "setCompanyDeptList": {
      return {
        ...stateAddTeamForm,
        companyDeptList: action.companyDeptList,
      };
    }
    case "setDeptListLoading": {
      return {
        ...stateAddTeamForm,
        deptListLoading: action.deptListLoading,
      };
    }
    case "setSearchValues": {
      return {
        ...stateAddTeamForm,
        page: action.page,
        pageSize: action.pageSize,
        searchText: action.searchText,
      };
    }
    case "resetSuggestedDropdown": {
      return {
        ...stateAddTeamForm,
        suggestedTeamNickname: "",
        isPopOverVisibleNickname: false,
      };
    }
    case "setSuggestedDropdownValues": {
      return {
        ...stateAddTeamForm,
        suggestedTeamNickname: action.suggestedTeamNickname,
        isPopOverVisibleNickname: action.isPopOverVisibleNickname,
      };
    }
    default: {
      return { ...stateAddTeamForm };
    }
  }
}
