import { ReactElement } from "react";
import styles from "./InputCheckboxBlock.module.scss";
import { Radio } from "antd";
import { WarningOutlined } from "@ant-design/icons";

interface IInputCheckBox {
  title: string;
  options: {
    key: number;
    value: string;
  }[];
  userInput: number[];
  alertOccured: boolean;
}

export default function InputRadioboxBlock({
  title,
  options,
  userInput,
  alertOccured,
}: IInputCheckBox): ReactElement {
  const keyIsSelected = (keyId: number) => {
    return userInput?.includes(keyId - 1);
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F9FB",
        width: "70%",
        border: alertOccured ? "3px red solid" : "",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}
      <div className={styles.toggled}>
        <h3 style={{ padding: "10px" }}>{title}</h3>
        <div style={{ marginLeft: "5px" }}>
          {options ? (
            options.map((field: any, index: number) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
                className={styles.checkboxLine}
              >
                <div
                  key={index}
                  style={{
                    marginBottom: "8px",
                    marginLeft: "4px",
                    width: "95%",
                    backgroundColor: "white",
                    paddingLeft: "8px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  <Radio
                    style={{ marginRight: "8px" }}
                    checked={keyIsSelected(field.key)}
                  ></Radio>
                  <h5
                    style={{
                      maxWidth: "90%",
                      overflowWrap: "break-word",
                    }}
                  >
                    {field.value}
                  </h5>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
