import { useReducer } from "react";
import { notificationsReducer } from "./NotificationsReducer";
import {
  NotificationsContext,
  NotificationsDispatchContext,
} from "./NotificationsContext";

export default function NotificationsProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [notifications, dispatchNotifications] = useReducer(
    notificationsReducer,
    {
      statusNotifications: "loading",
      errorNotifications: null,
      notifications: [],
      paginationNotifications: { page: 1, pageSize: 10, total: -1 },

      statusNotificationsCount: "loading",
      errorNotificationsCount: null,
      notificationsCount: -1,
      notificationsByDay: [],
      openedNotificationsByDay: [],
      closedNotificationsByDay: [],

      previewInfo: {
        typeOfPreview: "PROCEDURE",
        idOfPreview: -1,
        isOpen: false,
      },
    }
  );

  return (
    <NotificationsContext.Provider value={notifications}>
      <NotificationsDispatchContext.Provider value={dispatchNotifications}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsContext.Provider>
  );
}
