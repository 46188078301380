import { Col, Row } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withScrolling from "react-dnd-scrolling";
import CategoryPlanCards from "../../components/CategoryPlanCards";
import PlanOutline from "../../components/PlanOutline/PlanOutline";
import styles from "./PlanLayout.module.scss";

const ScrollingComponent = withScrolling("div");

export default function PlanLayout() {
  return (
    <div className={styles.planLayoutWrapper}>
      <div style={{ minHeight: "100vh", background: "white" }}>
        <DndProvider backend={HTML5Backend}>
          <ScrollingComponent style={{ padding: "0px" }}>
            <Row
              style={{ minHeight: "100vh", background: "white", width: "100%" }}
            >
              <Col
                span={4}
                style={{
                  paddingTop: "56px",
                  paddingLeft: "8px",
                }}
              >
                <CategoryPlanCards />
              </Col>
              <Col
                span={20}
                style={{
                  paddingTop: "32px",
                  paddingLeft: "32px",
                  paddingRight: "32px",
                }}
              >
                <PlanOutline />
              </Col>
            </Row>
          </ScrollingComponent>
        </DndProvider>
      </div>
    </div>
  );
}
