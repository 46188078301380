import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function IndexRouter({
  mainRoute,
  relativeDefault,
}: {
  mainRoute: string;
  relativeDefault: string;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === mainRoute) {
      navigate(relativeDefault);
    }
  }, [location.pathname, mainRoute, navigate, relativeDefault]);

  return <Outlet />;
}
