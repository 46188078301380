import { Card, List } from "antd";
import styles from "./AlertsCard.module.scss";
import { Link } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { fetchNotifications } from "app/stores/notifications/notificationsClient";
import { notificationType } from "app/stores/notifications/notificationsType";
import { useTranslation } from "react-i18next";
import { reformattedWebRedirection } from "pages/Notifications/NotificationsOrAlerts";

interface AlertCardProps {
  onOpenNotificationNotifWithoutLink: (
    web_redirection: string,
    body: string,
    execution_id?: number,
    executed_block_id?: number,
    requested_data?: any
  ) => void;
}

export default function AlertCard({
  onOpenNotificationNotifWithoutLink,
}: AlertCardProps) {
  const { t } = useTranslation();

  const [alertList, setAlertList] = useState<notificationType[]>([]);

  useEffect(() => {
    const notificationSort = {
      createdAt: "desc",
    };
    const AlertFilter = {
      alert: true,
    };
    fetchNotifications(1, 5, AlertFilter, notificationSort).then((data) => {
      setAlertList(data.notifications);
    });
  }, []);

  return (
    <Card
      className={styles.cardBody}
      style={{
        minHeight: "192px",
        width: "100%",
        marginBottom: 15,
      }}
    >
      <Link
        to="/account/alerts"
        style={{
          textDecoration: "none",
          color: "#030852",
          fontSize: "14px",
          fontVariant: "tabular-nums",
          lineHeight: "1.5715",
          listStyle: "none",
          fontFeatureSettings: "tnum",
        }}
        data-cy="Dashboard-AlertRedirectionButton"
      >
        <div className={styles.iconBox}>
          <div className={styles.iconImageBox}>
            <WarningOutlined style={{ fontSize: "25px", color: "white" }} />
          </div>
          <div style={{ paddingTop: "5px" }} className={styles.clickableHead}>
            {t("dashboard.alerts")}
          </div>
        </div>
      </Link>
      <List
        className="demo-loadmore-list "
        dataSource={alertList}
        itemLayout="horizontal"
        renderItem={(props: any) => (
          <div>
            {props.web_redirection &&
            !props.web_redirection.includes("null") ? (
              <Link to={reformattedWebRedirection(props.web_redirection)}>
                <List.Item
                  className={styles.listItemTest}
                  style={{ paddingLeft: "10px", paddingBottom: 0 }}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <p
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {props.title}{" "}
                          <span
                            style={{
                              color: "black",
                              fontWeight: "normal",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: props.body }}
                            />
                          </span>
                        </p>
                      </div>
                    }
                  />
                </List.Item>
              </Link>
            ) : (
              <List.Item
                data-cy="Dashboard-Button-Alert-Preview"
                className={styles.listItemTest}
                style={{ paddingLeft: "10px", paddingBottom: 0 }}
                onClick={() =>
                  onOpenNotificationNotifWithoutLink(
                    props.web_redirection,
                    props.body,
                    props.targets && props.targets[0]
                      ? props.targets[0].resource_id
                      : null,
                    props.targets && props.targets[0]
                      ? props.targets[0].data.executed_block_id
                      : null,
                    props.targets && props.targets[0]
                      ? props.targets[0].data.requested_data
                      : null
                  )
                }
              >
                <List.Item.Meta
                  title={
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {props.title}{" "}
                        <span
                          style={{
                            color: "black",
                            fontWeight: "normal",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: props.body }}
                          />
                        </span>
                      </p>
                    </div>
                  }
                />
              </List.Item>
            )}
          </div>
        )}
      />
    </Card>
  );
}
