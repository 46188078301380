import { useReducer } from "react";
import { headerReducer } from "./HeaderReducer";
import { HeaderContext, HeaderDispatchContext } from "./HeaderContext";

export default function HeaderProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [header, dispatchHeader] = useReducer(headerReducer, {
    statusNotifications: "loading",
    errorNotifications: null,
    paginationNotifications: { page: 1, pageSize: 10, total: -1 },
    notifications: [],
    notificationsByDay: [],
    openedNotificationsByDay: [],
    closedNotificationsByDay: [],
    statusNotificationsCount: "loading",
    errorNotificationsCount: null,
    notificationsCount: undefined,
    previewInfo: {
      typeOfPreview: "PROCEDURE",
      idOfPreview: -1,
      isOpen: false,
    },
    statusAlerts: "loading",
    errorAlerts: null,
    alerts: [],
    paginationAlerts: { page: 1, pageSize: 10, total: -1 },
    statusAlertsCount: "loading",
    errorAlertsCount: null,
    alertsCount: undefined,
    alertsByDay: [],
    openedAlertsByDay: [],
    closedAlertsByDay: [],
    rightMenuTab: 0,
    leftMenuActions: 0,
  });

  return (
    <HeaderContext.Provider value={header}>
      <HeaderDispatchContext.Provider value={dispatchHeader}>
        {children}
      </HeaderDispatchContext.Provider>
    </HeaderContext.Provider>
  );
}
