// import { useState } from "react";
import { message } from "antd";
import { fetchDocumentCategories } from "app/stores/documentCategories/documentCategoriesClient";
import { useNavigate, useParams } from "react-router-dom";
import { currentDateAndTime } from "util/currentDateUtil";
import { createDocument } from "app/stores/documents/documentsClient";
import { createSection } from "app/stores/sections/sectionsClient";
import { getLoggedInUser } from "app/stores/auth/authClient";
import { DropDownNew } from "components/DropDown/DropDownNew";
import DropDownList from "components/DropDown/DropDownList";
import styles from "./DocumentsModelsHeroCreateWrapper.module.scss";
import { Button } from "@armtekfrance/armtek-react-design-system";
import { translateNewDocumentType } from "util/translateDocumentType";
import { showToast } from "libs/requests";
import { useTranslation } from "react-i18next";
import { Manager } from "@felixkletti/managementjs";
import { UtilityActions } from "@armtekfrance/webgate/dist";

export default function DocumentModelsHeroCreateWrapper({
  typeMenuItems,
  dataCy = "DocumentsModelsHeroCreateWrapper",
}: {
  typeMenuItems: { key: string; label: string }[];
  dataCy?: string;
}) {
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();
  const userDetails = getLoggedInUser();
  // const [loading, setLoading] = useState(false);

  const addDocument = async (event: any) => {
    Manager.trigger(UtilityActions.IS_MODEL_CREATING, true);
    const docCategories = await fetchDocumentCategories({}, 1, "0");
    if (docCategories.success) {
      let subCategory: any;
      for await (const cat of docCategories.data.docCategories) {
        let subCats = await fetchDocumentCategories({
          parent_id: parseInt(cat.id),
        });
        if (subCats?.data?.docCategories.length > 0) {
          subCategory = subCats.data.docCategories[0];
          break;
        }
      }
      if (!subCategory) {
        showToast("warning", t("doc.models.warning"), "warning");
        Manager.trigger(UtilityActions.IS_MODEL_CREATING, false);
        return;
      }
      if (subCategory?.id) {
        let typeCreate = "procedure";
        if (type === "organisation") {
          typeCreate = "organisation_asset";
        } else if (type === "sets") {
          typeCreate = "group_sets";
        } else if (type === "content") {
          typeCreate = "procedure";
        }

        const currDate = currentDateAndTime();

        const displayedNewType = translateNewDocumentType(
          event.key ? event.key : typeCreate,
          t
        );

        const docToCreate = {
          name: `${displayedNewType} ${userDetails?.first_name} ${currDate}`,
          type: event?.key ? event.key : typeCreate,
          category_id: subCategory?.id,
        };

        const response = await createDocument(docToCreate);

        // setLoading(false);
        if (response.success) {
          await createSection({
            name: `${t("modelEditSectionList.step")} 1`,
            document_model_id: response.data.id,
          });

          navigate(`/account/knowledge/models/edit/${response.data.id}`);
        } else {
          // TODO: Translate
          message.error("Could not create the document. Please try again");
        }
      }
    } else {
      // setLoading(false);
    }
  };

  return (
    <div
      className={styles.documentsModelsHeroCreateWrapperWrapper}
      data-cy={dataCy}
    >
      <DropDownNew
        placement="bottomRight"
        dropdownContent={
          <DropDownList
            dataCy={`${dataCy}_list`}
            listItems={typeMenuItems}
            onClickItem={(item) => addDocument(item)}
          />
        }
      >
        <Button child={t("doc.models.create")} dataCy={`${dataCy}_button`} />
      </DropDownNew>
    </div>
  );
}
