import { Popover } from "antd";
import { AiOutlineQrcode } from "react-icons/ai";
import { QrCodeType } from "../../../../../../app/stores/qrCode/types";
import QrCodeComponent from "../../../../../QrCodeComponent/QrCodeComponent";
import styles from "./MagicQrCodeView.module.scss";

export default function MagicQrCodeView({
  qr_code,
}: {
  qr_code: QrCodeType | null | undefined;
}) {
  return (
    <div
      className={styles.magicQrCodeViewWrapper}
      onClick={(e) => e.stopPropagation()}
    >
      {qr_code ? (
        <Popover trigger="click" content={<QrCodeComponent qrCode={qr_code} />}>
          <AiOutlineQrcode className={styles.qrCodeOk} size={32} />
        </Popover>
      ) : (
        <AiOutlineQrcode className={styles.qrCodeNotOk} size={32} />
      )}
    </div>
  );
}
