import NotificationListDayItem from "./NotificationListDayItem";
import { useTranslation } from "react-i18next";
import { notificationType } from "app/stores/notifications/notificationsType";
import styles from "./NotificationsFullList.module.scss";
import { openAllNotificationsTanStack } from "app/stores/notifications/notificationsClient";
import { getLoggedInUser } from "app/stores/auth/authClient";

export default function NotificationsFullList({
  notificationsByDay,
  typeOfNotidications,
  notificationCount,
  alertOrNotification,
  onClick,
}: {
  notificationsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  typeOfNotidications: "READ" | "UNREAD";
  alertOrNotification: "ALERTS" | "NOTIFICATIONS";

  notificationCount: number;
  onClick: (item: notificationType) => void;
}) {
  const { t } = useTranslation();
  const mutation = openAllNotificationsTanStack();
  const user = getLoggedInUser();
  return (
    <div className={styles.notificationsFullListWrapper}>
      <p className={styles.headerText}>
        <p className={styles.unreadText}>
          {typeOfNotidications === "READ"
            ? t("dashboard.read")
            : t("dashboard.unread") + ` (${notificationCount})`}
        </p>

        {typeOfNotidications === "UNREAD" && (
          <p
            className={styles.markAllText}
            onClick={() => {
              mutation.mutate({
                opened: true,
                receiverId: user.id,
                alert: alertOrNotification === "ALERTS" ? true : false,
              });
            }}
          >
            {t("button.markAllRead")}
          </p>
        )}
      </p>
      {notificationsByDay.map((day) => {
        return (
          <NotificationListDayItem
            onClick={(e) => {
              onClick(e);
            }}
            dayItem={day}
          />
        );
      })}
    </div>
  );
}
