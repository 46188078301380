import { useReducer } from "react";
import { validationStatusReducer } from "./ValidationStatusReducer";
import {
  ValidationStatusContext,
  ValidationStatusDispatchContext,
} from "./ValidationStatusContext";

export default function ValidationStatusProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [validationStatus, dispatchValidationStatus] = useReducer(
    validationStatusReducer,
    {
      statusBaselineValidations: "loading",
      statusUsers: "loading",
      errorBaselineValidations: null,
      errorUsers: null,
      baselineValidations: [],
      queryBaselineValidationsStatus: [],
      queryNameTextFilter: "",
      users: [],
      authors: [],
      validators: [],
      paginationBaselineValidations: {
        page: 1,
        pageSize: 10,
        total: -1,
      },
    }
  );

  return (
    <ValidationStatusContext.Provider value={validationStatus}>
      <ValidationStatusDispatchContext.Provider
        value={dispatchValidationStatus}
      >
        {children}
      </ValidationStatusDispatchContext.Provider>
    </ValidationStatusContext.Provider>
  );
}
