import { useTranslation } from "react-i18next";
import styles from "./boat-page.module.scss";

export default function BoatPage({ pageName }: { pageName?: string }) {
  const { t } = useTranslation();

  // const audio = new Audio("https://lasonotheque.org/UPLOAD/wav/0261.wav");
  // const [continuePlaying, setContinuePlaying] = useState(true);

  // const playAudio = () => {
  //   audio.play();
  // };

  // const loopAudio = async () => {
  //   while (continuePlaying) {
  //     const min = 20;
  //     const max = 30;
  //     const rand = Math.floor(Math.random() * (max - min + 1) + min);

  //     await delay(rand * 1000);

  //     playAudio();
  //   }
  // };

  // const delay = (ms: number) => {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // };

  // const stopAudioLoop = useCallback(() => {
  //   audio.pause();
  //   setContinuePlaying(false);
  // }, [audio, setContinuePlaying]);

  // loopAudio();

  // useEffect(() => {
  //   const unloadCallback = (event: BeforeUnloadEvent) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => {
  //     window.addEventListener("popstate", stopAudioLoop);
  //     window.removeEventListener("beforeunload", unloadCallback);
  //   };
  // }, []);

  return (
    <main style={{ position: "relative" }}>
      <div className={styles.animationWrapper}>
        <div className={styles.water}>
          <ul className={styles.waves}>
            <li
              className={styles.waveOne}
              style={{
                backgroundImage:
                  "url('https://i.postimg.cc/7LtCC11Y/wave1.png')",
              }}
            ></li>
            <li
              className={styles.waveTwo}
              style={{
                backgroundImage:
                  "url('https://i.postimg.cc/P5hv05rh/wave2.png')",
              }}
            ></li>
            <li
              className={styles.waveThree}
              style={{
                backgroundImage:
                  "url('https://i.postimg.cc/63Dyc91k/wave3.png')",
              }}
            ></li>
            <li
              className={styles.waveFour}
              style={{
                backgroundImage:
                  "url('https://i.postimg.cc/1tg8DgM0/wave4.png')",
              }}
            ></li>
          </ul>
        </div>
        <div
          className={styles.boat}
          style={{
            backgroundImage: "url('https://i.postimg.cc/GmQTRnHD/boat2.png')",
          }}
        ></div>
        <div
          className={styles.cloud}
          style={{
            backgroundImage:
              "url('https://i.postimg.cc/VNkrLZCV/cloud-md.png')",
          }}
        ></div>
        <div
          className={styles.cloud2}
          style={{
            backgroundImage:
              "url('https://i.postimg.cc/VNkrLZCV/cloud-md.png')",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "#3F6481",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
              fontWeight: "800px",
              fontSize: "18px",
              marginLeft: "72px",
              marginBottom: "4px",
            }}
          >
            {t("soon.workingOnFeatures")}
          </p>
          <p
            style={{
              color: "#3F6481",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
              fontWeight: "800px",
              fontSize: "18px",
              marginLeft: "72px",
            }}
          >
            {t("soon.comeBackLater")}
          </p>
          {pageName && (
            <p
              style={{
                color: "#3F6481",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
                fontWeight: "800px",
                fontSize: "18px",
                marginLeft: "72px",
              }}
            >
              {pageName}
            </p>
          )}
        </div>
      </div>
    </main>
  );
}
