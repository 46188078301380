import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchDocumentVersionsAsync,
  getDocumentVersion,
  getDocumentVersionPending,
} from "../../app/stores/documents/documentsSlice";
import ListView from "../ListView";
import { useTranslation } from "react-i18next";
import ProcedurePreview from "../ProcedurePreview";

interface DocumentVersionsType {
  documentId: any;
}

export default function DocumentVersions({ documentId }: DocumentVersionsType) {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // eslint-disable-next-line
  const [selectedTableKey, setSelectedTableKeys] = useState<any[]>([]);

  const isFetchingBaseline = useAppSelector(getDocumentVersionPending);
  const baselines = useAppSelector(getDocumentVersion);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewDocumentId, setViewDocumentId] = useState("");

  const showModal = (docId: any) => {
    setViewDocumentId(docId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    let filter: any = {};
    const id = documentId;
    const page = 1;
    const pageSize = 100;
    dispatch(fetchDocumentVersionsAsync({ id, page, pageSize, filter }));
  }, [dispatch, page, pageSize, documentId]);

  const onChangePagination = (changedPage: number, changedPageSize: number) => {
    if (changedPage !== page) {
      setPage(changedPage);
    }
    if (changedPageSize !== pageSize) {
      setPageSize(changedPageSize);
    }
  };

  const { t } = useTranslation();

  const baselineColumns = [
    // {
    //   title: "QR",
    //   dataIndex: "qr_code",
    //   key: "qr_code",
    //   render: (qr_code: string) => (
    //     <>
    //       {qr_code ? (
    //         <Popover trigger="click" content={<QrCodeComponent qrCode={qr_code} />}>
    //           <Button icon={<AiOutlineQrcode size={24} />}></Button>
    //         </Popover>
    //       ) : (
    //         <div>
    //           <p style={{ color: "#c2bdbd" }}>{t("qrCode.labelNotAssigned")}</p>
    //         </div>
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "doc.models.edit.versions.baselineName",
      dataIndex: "version_name",
      key: "version_name",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "doc.models.edit.versions.version",
      dataIndex: "version",
      key: "version",
      render: (text: string) => <p style={{ fontWeight: "bold" }}>{text}</p>,
    },
    // {
    //   title: "Steps",
    //   dataIndex: "_count",
    //   key: "Steps",
    //   render: (_count: any) => (
    //     <p>{_count.document_baseline_has_section_baseline}</p>
    //   ),
    // },
    {
      title: "doc.models.edit.versions.publishedAt",
      dataIndex: "published_at",
      key: "published_at",
      render: (published_at: string) => (
        <>{new Intl.DateTimeFormat("en-GB").format(new Date(published_at))}</>
      ),
    },
    {
      title: "doc.models.edit.versions.action",
      key: "action",
      dataIndex: "id",
      render: (id: number) => (
        <div
          onClick={() => showModal(id)}
          style={{ cursor: "pointer", color: "#030852" }}
        >
          {t("organisation.view")}
        </div>
      ),
    },
  ];

  return (
    <>
      <ListView
        data={baselines.documentModelsVersions}
        cardData={baselines.documentModelsVersions}
        columns={baselineColumns}
        loading={isFetchingBaseline}
        loadingCardData={true}
        isListView={true}
        page={page}
        pageSize={pageSize}
        total={baselines?.pagination?.total}
        cardLink={""}
        onChangePagination={onChangePagination}
        setSelectedTableKeys={setSelectedTableKeys}
      />
      <ProcedurePreview
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        documentId={parseInt(viewDocumentId)}
        page={"baselines"}
      />
    </>
  );
}
