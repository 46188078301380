import styles from "./MagicListItemArticle.module.scss";
import MagicInfos from "./MagicInfos/MagicInfos";
import MagicSectionDetailsNew from "./MagicSectionDetails/MagicSectionDetailsNew";
import { DocumentType } from "app/stores/documents/types";
import { Skeleton } from "antd";
import { ApiMetabaseDashboard } from "app/stores/metabase/types";

export default function MagicListItemArticle({
  documentMagicData,
  metabaseDashboard,
  lite,
  isLoading = false,
  commentSectionIdHovered,
  type = "models",
  onOpenModal,
  readOnly = false,
}: {
  documentMagicData?: DocumentType;
  metabaseDashboard?: ApiMetabaseDashboard;
  lite: boolean;
  isLoading?: boolean;
  commentSectionIdHovered: number | null;
  type?: "models" | "analysis";
  readOnly?: boolean;
  onOpenModal?: (
    id: string | number,
    type: "openModal" | "deleteDashboard" | "changeActive" | "deleteModel"
  ) => void;
}) {
  return (
    <article
      className={`${styles.magicListItemArticleWrapper} ${
        lite && styles.magicListItemArticleWrapperLiteVersion
      }`}
    >
      {!isLoading ? (
        <>
          <MagicInfos
            documentMagicData={documentMagicData as DocumentType}
            metabaseDashboard={metabaseDashboard}
            lite={lite}
            type={type}
            readOnly={readOnly}
            onOpenModal={onOpenModal}
          ></MagicInfos>
          {!lite && type === "analysis" && (
            <div
              style={{
                padding: "16px",
              }}
            >
              <iframe
                style={{
                  width: "100%",
                  height: "600px",
                }}
                src={metabaseDashboard?.iframe_url || ""}
              ></iframe>
            </div>
          )}
          {!lite && type === "models" && (
            <>
              <MagicSectionDetailsNew
                commentSectionIdHovered={commentSectionIdHovered}
                // document_model_id={documentMagicData.id}
                // about={documentMagicData.about}
                // subtitle={documentMagicData.subtitle}
                // sections={documentMagicData.sections}
                // magicItemInError={magicItemInError}
                // setMagicListItemInError={setMagicListItemInError}
              />
            </>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: "1rem 2rem",
              scale: "1 3",
              translate: "0 18px",
              width: "100%",
            }}
          >
            <Skeleton paragraph={false} active />
          </div>
        </div>
      )}
    </article>
  );
}
