import Button from "components/TempDS/Button/Button";
import styles from "./MediaPickerUploadFooter.module.scss";
import { useTranslation } from "react-i18next";
import { GlobalStore } from "@armtekfrance/webgate/dist";

export default function MediaPickerUploadFooter({
  uploadVisible,
  onUpload,
  uploadInProgress,
}: {
  uploadVisible: boolean;
  onUpload: () => void;
  uploadInProgress: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.mediaPickerUploadFooterWrapper} ${
        uploadVisible ? styles.show : styles.hide
      }`}
    >
      {!uploadInProgress ? (
        <Button type="primary" onClick={onUpload}>
          <>
            {`${t("mediaPicker.upload")} ${
              GlobalStore.media
                .getMediaReadyToUpload()
                .filter((media) => {
                  return (
                    media.block_type ===
                    GlobalStore.media.getMediaPickerModalStatus().block_type
                  );
                })
                .filter((media) => media.selected).length
            } ${t("doc.models.edit.sections.blocks.files")}`}
          </>
        </Button>
      ) : (
        <Button type="primary" disabled={true}>
          <p>{t("mediaPicker.uploading")}</p>
        </Button>
      )}
    </div>
  );
}
