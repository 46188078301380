import { MediaContent } from "Stores/media-models";
import { fetchBlobObjectTanstack } from "app/stores/content/contentClient";
import styles from "./MediaSelectorImage.module.scss";
import MediaImageItemWrapper from "components/MediaPicker/MediaImageItemWrapper";
import { GlobalStore } from "@armtekfrance/webgate/dist";

export default function MediaSelectorImage({
  imageItem,
  dropDownComponent,
  convertToBase64 = false,
}: {
  imageItem: MediaContent;
  dropDownComponent: JSX.Element;
  convertToBase64?: boolean;
}) {
  const thumbnailImage = fetchBlobObjectTanstack(
    imageItem.media_content_uid,
    true,
    false,
    1000 * 60 * 60 * 24 * 7
  );

  return (
    <div className={styles.mediaSelectorImageWrapper}>
      <MediaImageItemWrapper
        enablePreview
        mediaName={imageItem.name}
        showMediaName={false}
        imageDataUri={`data:${thumbnailImage.blobObject?.media.ContentType};${
          convertToBase64 ? "base64," : ""
        } ${thumbnailImage.blobObject?.media.file}`}
        alt={imageItem.name}
        showCheckbox={false}
        checked={false}
        typeOfMedia="image"
        onCheckedChange={() => {
          // TODO: reduce to avoid that
          return;
        }}
        onPreviewClickOpening={() => {
          GlobalStore.media.setFullScreenMediaPreview({
            media_id: imageItem.id.toString(),
            block_type: "image",
            localDataUri: null,
          });
        }}
        status={thumbnailImage.status}
        error={thumbnailImage.error}
      />
      {dropDownComponent}
    </div>
  );
}
