import IndexRouter from "../../components/IndexRouter/IndexRouter";

export default function AnalyticsIndex() {
  return (
    <IndexRouter
      mainRoute={"/account/analytics"}
      relativeDefault={"advanced"}
    />
  );
}
