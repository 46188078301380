import { CheckBox, RadioBox } from "@armtekfrance/armtek-react-design-system";
import styles from "./DropDownItemTextSelect.module.scss";

export default function DropDownItemTextSelect({
  item,
  selectType,
  dataCy = "DropDownItemTextSelect",
}: {
  item: { key: string; label: string | JSX.Element; selected?: boolean };
  selectType?: "radio" | "checkbox";
  dataCy?: string;
}) {
  return (
    <form className={styles.dropDownItemTextSelectWrapper} data-cy={dataCy}>
      {/* TODO Replace with Input type="radio" | "checkbox" later */}
      {selectType === "checkbox" && item.selected !== undefined && (
        <CheckBox
          checked={item.selected}
          onChange={() => {
            return;
          }}
        />
      )}
      {selectType === "radio" && item.selected !== undefined && (
        // <></>
        <RadioBox
          checked={item.selected}
          onChange={() => {
            return;
          }}
        />
      )}
      <p>{item.label}</p>
    </form>
  );
}
