import { documentTypeMenuItemsAll } from "components/DocumentModels/Hero/documentsModelsTypesDropDown/EnumTypesContent";
import AddLinkageNodeHeroTypeFilterWrapper from "./AddLinkageNodeHeroTypeFilterWrapper";

export default function AddLinkageNodeHeroTypeFilter() {
  return (
    <AddLinkageNodeHeroTypeFilterWrapper
      typeMenuItems={documentTypeMenuItemsAll}
    />
  );
}
