import IndexRouter from "../../components/IndexRouter/IndexRouter";

interface ModelIndexProps {
  /**
   * mainRoute is the main route to redirect from.
   */
  mainRoute: string;
  /**
   * relativeDefault is the default route to redirect to when the main route is accessed.
   */
  relativeDefault: string;
}

export default function ModelIndex({
  mainRoute,
  relativeDefault,
}: ModelIndexProps) {
  return (
    <IndexRouter mainRoute={mainRoute} relativeDefault={relativeDefault} />
  );
}
