import DocumentsModelsProvider from "components/DocumentModels/Providers/DocumentModelsProvider";
import ModelLinkageLayout from "./ModelLinkageLayout";

export default function ModelLinkage() {
  return (
    <DocumentsModelsProvider>
      <ModelLinkageLayout />
    </DocumentsModelsProvider>
  );
}
