import { Layout, LayoutType } from "@armtekfrance/webgate/dist";
import OperateBody from "./OperateBody";
// import OperateFilters from "./OperateFilters";
// import { ApiObjectExpandable } from "@armtekfrance/corenexus-pn-sn";
import OperateFilters from "./OperateFilters";
// import { useIsOngoing } from "libs/useIsOngoing";

const OperatePage = () => {
  return (
    <Layout
      layoutType={LayoutType.NO_SIDE}
      header={<OperateFilters />}
      content={<OperateBody />}
    />
  );
};
export default OperatePage;
