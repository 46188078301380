import { Outlet } from "react-router-dom";
import styles from "./TopHeaderMenuLayout.module.scss";
import HeaderMenuRoot from "components/Header/HeaderMenuRoot";

export default function TopHeaderMenuLayout() {
  return (
    <div className={styles.topHeaderMenuLayoutWrapper}>
      <HeaderMenuRoot></HeaderMenuRoot>
      <Outlet />
    </div>
  );
}
