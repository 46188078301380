import { Col, Divider, Row, Skeleton } from "antd";

export default function EditUserLoader() {
  return (
    <div>
      <Row gutter={12}>
        <Col span={24} style={{ marginBottom: "1rem" }}>
          <Skeleton.Input active />
        </Col>
        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
      </Row>
      <Divider />
      <Row gutter={12}>
        <Col span={24} style={{ marginBottom: "1rem" }}>
          <Skeleton.Input active />
        </Col>
        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
      </Row>
      <Divider />

      <Row gutter={12}>
        <Col span={24} style={{ marginBottom: "1rem" }}>
          <Skeleton.Input active />
        </Col>
        <Col span={24} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
      </Row>
      <Divider />
      <Row gutter={12}>
        <Col span={24} style={{ marginBottom: "1rem" }}>
          <Skeleton.Input active />
        </Col>

        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={12} style={{ marginBottom: "1rem" }}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={6}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={18}>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
      </Row>
    </div>
  );
}
