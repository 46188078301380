import { Card, Tabs } from "antd";
import ModelInfoSteps from "../ModelInfoSteps/ModelInfoSteps";
import { useTranslation } from "react-i18next";

interface ModelStepsAndAttachType {
  sectionsList: any[];
  documentId: number;
  commentSectionIdHovered: number | null;
}

export default function ModelStepsAndAttach({
  sectionsList,
  documentId,
  commentSectionIdHovered,
}: ModelStepsAndAttachType) {
  const { t } = useTranslation();

  return (
    <Card style={{ border: "0.5px solid #030852" }} size="small">
      <Tabs defaultActiveKey="1" className="modelInfoTab">
        <Tabs.TabPane
          className="modelInfoStepsTab"
          tab={
            <div
              style={{
                paddingLeft: "32px",
                paddingRight: "32px",
                color: "#030852",
              }}
            >
              {t("doc.models.procedure.steps")}
            </div>
          }
          key="1"
        >
          <ModelInfoSteps
            sectionsList={sectionsList}
            documentId={documentId}
            commentSectionIdHovered={commentSectionIdHovered}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}
