import React, { useState, useEffect } from "react";
import { Switch, Table, Space, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "@armtekfrance/armtek-react-design-system";
import { message } from "antd";
import { UserOutlined, SettingOutlined } from "@ant-design/icons";
import NotificationPreferencesLoader from "./Loader";

const { Option } = Select;

interface NotificationPreferencesProps {
  active?: boolean;
  includePreferenceEditor?: boolean;
  onSave: (data: { preferences: EventPreference[] }) => Promise<void>;
  fetchData: () => Promise<{
    data: EventPreference[];
  }>;
}
interface EventPreference {
  id: number;
  event_type: string;
  preference: "USER" | "COMPANY";
  target: "ALL" | "ONLY_EMAIL" | "ONLY_PUSH" | "NONE";
  company_id: number;
  inherited?: boolean;
}

const NotificationPreferences: React.FC<NotificationPreferencesProps> = ({
  active = true,
  includePreferenceEditor = false,
  onSave,
  fetchData,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<EventPreference[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  // Fetch for model event preferences
  const fetchPreferenceData = async () => {
    try {
      const response = await fetchData();
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPreferenceData();
  }, []);

  // Handle switch change for email and push preferences
  const handleSwitchChange = (
    eventType: string,
    type: "email" | "push",
    checked: boolean
  ) => {
    setData((prevData) =>
      prevData.map((record) =>
        record.event_type === eventType
          ? {
              ...record,
              target: updateTarget(record.target, type, checked),
            }
          : record
      )
    );
  };

  // Handle preference change (USER or COMPANY)
  const handlePreferenceChange = (
    eventType: string,
    value: "USER" | "COMPANY"
  ) => {
    setData((prevData) =>
      prevData.map((record) =>
        record.event_type === eventType
          ? { ...record, preference: value }
          : record
      )
    );
  };

  // Update the target based on email and push preferences
  const updateTarget = (
    currentTarget: "ALL" | "ONLY_EMAIL" | "ONLY_PUSH" | "NONE",
    type: "email" | "push",
    checked: boolean
  ) => {
    const emailChecked =
      type === "email"
        ? checked
        : currentTarget === "ALL" || currentTarget === "ONLY_EMAIL";
    const pushChecked =
      type === "push"
        ? checked
        : currentTarget === "ALL" || currentTarget === "ONLY_PUSH";

    if (emailChecked && pushChecked) {
      return "ALL";
    } else if (emailChecked) {
      return "ONLY_EMAIL";
    } else if (pushChecked) {
      return "ONLY_PUSH";
    } else {
      return "NONE";
    }
  };

  // Handle saving preferences
  const handleSavePreferences = async () => {
    try {
      setSaving(true);
      await onSave({ preferences: data });
      message.success(t("notificationParametrization.savingSuccessful"));
      await fetchPreferenceData();
    } catch (error) {
      console.error("Error saving preferences:", error);
      message.error(t("notificationParametrization.savingError"));
    } finally {
      setSaving(false);
    }
  };

  // Table columns configuration
  const columns = [
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
      width: "40%",
      render: (_: any, record: EventPreference) => (
        <div>
          <>
            <div
              style={{ width: "30px", height: "30px" }}
              className={
                "my-2 d-inline-flex justify-content-center align-items-center rounded-circle " +
                (record.inherited ? "bg-info-subtle" : "bg-dark-subtle")
              }
            >
              {record.inherited ? (
                <UserOutlined size={20} />
              ) : (
                <SettingOutlined size={20} />
              )}
            </div>
            &nbsp; &nbsp;
          </>
          <span>{record.event_type.replaceAll("_", " ")}</span>
        </div>
      ),
    },
    ...(includePreferenceEditor
      ? [
          {
            title: (
              <strong className="d-inline-block w-100 text-center">
                Preference Editor
              </strong>
            ),
            dataIndex: "preference",
            key: "preference",
            render: (_: any, record: EventPreference) => (
              <div style={{ textAlign: "center" }}>
                <Select
                  value={record.preference}
                  onChange={(value) =>
                    handlePreferenceChange(record.event_type, value)
                  }
                  disabled={saving || !active}
                  style={{ width: 120 }}
                >
                  <Option value="USER">User</Option>
                  <Option value="COMPANY">Company</Option>
                </Select>
              </div>
            ),
            width: "20%",
          },
        ]
      : []),
    {
      title: (
        <strong className="d-inline-block w-100 text-center">
          Email preferences
        </strong>
      ),
      dataIndex: "target",
      key: "email",
      render: (_: any, record: EventPreference) => (
        <div style={{ textAlign: "center" }}>
          <Switch
            checked={record.target === "ALL" || record.target === "ONLY_EMAIL"}
            onChange={(checked) =>
              handleSwitchChange(record.event_type, "email", checked)
            }
            disabled={
              saving ||
              (record.preference === "COMPANY" && !includePreferenceEditor) ||
              !active
            }
          />
        </div>
      ),
      width: "20%",
    },
    {
      title: (
        <strong className="d-inline-block w-100 text-center">
          Push preferences
        </strong>
      ),
      dataIndex: "target",
      key: "push",
      render: (_: any, record: EventPreference) => (
        <div style={{ textAlign: "center" }}>
          <Switch
            checked={record.target === "ALL" || record.target === "ONLY_PUSH"}
            onChange={(checked) =>
              handleSwitchChange(record.event_type, "push", checked)
            }
            disabled={
              saving ||
              (record.preference === "COMPANY" && !includePreferenceEditor) ||
              !active
            }
          />
        </div>
      ),
      width: "20%",
    },
  ];

  return (
    <div className="notification-preferences-container">
      <div className="my-3">
        {loading ? (
          <NotificationPreferencesLoader
            includePreferenceEditor={includePreferenceEditor}
          />
        ) : (
          <>
            <Table
              dataSource={data}
              columns={columns}
              rowKey="event_type"
              pagination={false}
              size="small"
              bordered
            />
            <Space className="d-flex justify-content-end mt-5">
              <Button
                onClick={handleSavePreferences}
                child={t("notificationParametrization.savePreferences")}
                type={ButtonType.primary}
                loading={saving}
                disabled={saving || !active}
              />
            </Space>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationPreferences;
