import HeaderMenu from "./HeaderMenu";
import HeaderProvider from "./Provider/HeaderProvider";

export default function HeaderMenuRoot() {
  return (
    <HeaderProvider>
      <HeaderMenu />
    </HeaderProvider>
  );
}
