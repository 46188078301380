import { useReducer } from "react";
import { addLinkageNodeReducer } from "./AddLinkageNodeReducer";
import {
  AddLinkageNodeContext,
  AddLinkageNodeDispatchContext,
} from "./AddLinkageNodeContext";

export default function AddLinkageNodeProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [addLinkageNode, dispatchAddLinkageNode] = useReducer(
    addLinkageNodeReducer,
    {
      pagination: { page: 1, pageSize: 10, total: -1 },
      status: "loading",
      error: null,
      documentModels: [],
      selectedItems: [],
      typeFilter: "",
      queryNameTextFilter: "",
    }
  );

  return (
    <AddLinkageNodeContext.Provider value={addLinkageNode}>
      <AddLinkageNodeDispatchContext.Provider value={dispatchAddLinkageNode}>
        {children}
      </AddLinkageNodeDispatchContext.Provider>
    </AddLinkageNodeContext.Provider>
  );
}
