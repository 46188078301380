import React from "react";
import { useRouteError } from "react-router-dom";
import "./errorComponent.module.scss";

const ErrorComponent: React.FC = () => {
  const error = useRouteError();

  return (
    <div className="error-container">
      <div className="error-card">
        <div className="error-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            height="72"
            fill="currentColor"
            className="bi bi-exclamation-triangle"
            viewBox="0 0 16 16"
          >
            <path d="M7.938 2.016a.13.13 0 0 1 .125 0l6.857 3.945a.565.565 0 0 1 .244.731l-6.858 11.89a.565.565 0 0 1-.976 0L.27 6.692a.565.565 0 0 1 .244-.731L7.938 2.016zM8 12a.905.905 0 1 0 0 1.81A.905.905 0 0 0 8 12zm.93-7.388a.905.905 0 1 0-1.857 0l-.384 4.59a.905.905 0 0 0 1.803 0l.438-4.59z" />
          </svg>
        </div>
        <h1>Something Went Wrong</h1>
        <p>
          {error instanceof Error
            ? error.message
            : "An unexpected error occurred."}
        </p>
        <button
          onClick={() => (window.location.href = "/")}
          className="error-button"
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default ErrorComponent;
