import styles from "./UploadFilesHero.module.scss";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";
import { DataUri } from "./MediaPickerUploadMediasGrid";
import { message } from "antd";
import { GlobalStore } from "@armtekfrance/webgate/dist";

export default function UploadFilesHero({
  onMediasLoaded,
  mediaType,
  uploadInProgress,
}: {
  onMediasLoaded: (
    images: {
      file: File;
      dataUri: DataUri;
      selected: boolean;
      block_type: "image" | "video" | "audio" | "file";
    }[]
  ) => void;
  mediaType: "image" | "video" | "audio" | "file";
  uploadInProgress: boolean;
}) {
  const { t } = useTranslation();

  const typeOfMediaAccepted = () => {
    switch (mediaType) {
      case "image":
        return "image/png, image/jpeg, image/gif, image/webm";
      case "video":
        return "video/*";
      case "audio":
        return "audio/mp3, audio/wav, audio/mpeg";
      case "file":
        return "*";
    }
  };

  const handleFileChosen = async (file: File) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    });
  };

  const readAllFiles = async (allFiles: File[]) => {
    const filesList: {
      file: File;
      dataUri: DataUri;
      selected: boolean;
      block_type: "image" | "video" | "audio" | "file";
    }[] = [];
    const results = await Promise.all(
      allFiles.map(async (file) => {
        return await handleFileChosen(file);
      })
    );
    for (let i = 0; i < allFiles.length; i++) {
      filesList.push({
        file: allFiles[i],
        dataUri: results[i] as string,
        block_type: mediaType,
        selected: true,
      });
    }
    onMediasLoaded(filesList);
  };

  const getFileExtension = (fileName: string) => {
    const parts = fileName.split(".");
    if (parts.length === 1) {
      console.error(`${t("mediaPicker.fileNotSupported")} ${fileName}`);
      message.error(`${t("mediaPicker.fileNotSupported")} ${fileName}`);
      return "";
    }
    return parts[parts.length - 1];
  };

  const isImageExtension = (extension: string) => {
    return ["jpg", "jpeg", "png", "gif", "webm"].includes(extension);
  };

  const isVideoExtension = (extension: string) => {
    return ["mp4", "webm", "ogg", "avi", "mov", "m4v", "mkv"].includes(
      extension
    );
  };

  const isAudioExtension = (extension: string) => {
    return ["mp3", "wav", "mpeg"].includes(extension);
  };

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (uploadInProgress) message.error(t("mediaPicker.waitForUpload"));
    const medias: File[] = [];
    if (e.target.files === null) return;
    for (const file of e.target.files) {
      medias.push(file);
    }
    readAllFiles(medias);
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    if (uploadInProgress) return;
    if (e.dataTransfer === null) return;
    if (e.dataTransfer.files === null) return;
    const medias: File[] = [];
    for (const file of e.dataTransfer.files) {
      if (
        GlobalStore.media.getMediaPickerModalStatus().block_type === "image" &&
        isImageExtension(getFileExtension(file.name))
      ) {
        medias.push(file);
      } else if (
        GlobalStore.media.getMediaPickerModalStatus().block_type === "video" &&
        isVideoExtension(getFileExtension(file.name))
      ) {
        medias.push(file);
      } else if (
        GlobalStore.media.getMediaPickerModalStatus().block_type === "audio" &&
        isAudioExtension(getFileExtension(file.name))
      ) {
        medias.push(file);
      } else if (
        GlobalStore.media.getMediaPickerModalStatus().block_type === "file"
      ) {
        medias.push(file);
      } else {
        console.error(`${t("mediaPicker.fileNotSupported")} ${file.name}`);
        message.error(`${t("mediaPicker.fileNotSupported")} ${file.name}`);
      }
    }
    readAllFiles(medias);
  };

  return (
    <form
      className={styles.uploadFilesHeroWrapper}
      data-upload-in-progress={uploadInProgress}
      encType="multipart/form-data"
    >
      <label className={styles.label}>
        <h3>{t("mediaPicker.clickOrDrag")}</h3>
      </label>
      <input
        type="file"
        className={styles.defaultFileInput}
        accept={typeOfMediaAccepted()}
        multiple={true}
        onChange={inputChange}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleDrop(e);
        }}
        disabled={uploadInProgress}
      />
    </form>
  );
}
