import { PaginationType } from "../pagination/types";
import { ObjectValues } from "../../../util/typesUtils";

export const DocumentTypeConst = {
  procedure: "procedure",
  report: "report",
  form: "form",
  communication: "communication",
  specification: "specification",
  mixed: "mixed",
  manual: "manual",
  requirements: "requirements",
  guide: "guide",
  group_sets: "group_sets",
  organisation_asset: "organisation_asset",
  organisation_product: "organisation_product",
  organisation_project: "organisation_project",
  organisation_workstation: "organisation_workstation",
} as const;

export type DocumentModelType = ObjectValues<typeof DocumentTypeConst>;

export interface DocumentType {
  about?: null | any;
  active?: boolean;
  auto_image_set?: boolean;
  company_id?: number;
  created_at: string;
  doc_category_id?: number;
  document_model_uid?: string;
  expire_at?: null | string;
  first_published_at?: string;
  id: number;
  image_media_id?: number | null;
  last_published_at?: string;
  last_published_document_baseline_id?: number;
  media_content?: null | any;
  name: string;
  never_expire?: boolean;
  published?: boolean;
  qr_code?: null | any;
  sh__doc_category?: null | any;
  status?: string;
  subtitle?: null | any;
  template?: boolean;
  type: DocumentModelType;
  updated_at?: string;
  user?: any;
  user_id?: number;
  _count?: {
    document_model_has_section_model: number;
  };
  count?: {
    sections: number;
    blocks: number;
  };
  version: string;
}

export interface DocumentState {
  current: DocumentType | {};
  documentList: { documentsModels: DocumentType[]; pagination: PaginationType };
  listPending: boolean;
  listError: string | undefined;
  currentPending: boolean;
  currentError: string | undefined;
  listUpdatePending: boolean;
  versionList: any;
  versionListPending: boolean;
}
