import { ReactElement } from "react";
import VideoPlayerComponent from "../VideoPlayerComponent";
import { WarningOutlined } from "@ant-design/icons";

interface IInputVideoBlock {
  userInput: string;
  alertOccured: boolean;
}

export default function InputVideoBlock({
  userInput,
  alertOccured,
}: IInputVideoBlock): ReactElement {
  return (
    <div
      style={{
        border: alertOccured ? "3px red solid" : "",
        borderRadius: "5px",
        width: "25%",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}

      <VideoPlayerComponent
        playVideo={{
          media_content: {
            media_content_uid: userInput,
          },
        }}
      ></VideoPlayerComponent>
    </div>
  );
}
