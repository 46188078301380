import { useEffect, useState } from "react";
import {
  EditorState,
  Modifier,
  SelectionState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor, SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDebounce } from "../../../../util/useDebounce";

interface TextType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => Promise<void>;
  disableEdit?: boolean;
  contentId: string | number;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function TextContent({
  data,
  updateContentInDb,
  disableEdit,
  contentId,
  setIsContentBeingSaved,
}: TextType) {
  const [draftDataPending, setDraftDataPending] = useState(true);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // load blocks on data change
  useEffect(() => {
    setDraftDataPending(true);

    if (data?.blocks) {
      const contentState = convertFromRaw({
        blocks: data.blocks,
        entityMap: {},
      });
      const newEditorState = EditorState.createWithContent(contentState);

      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
    setDraftDataPending(false);
  }, [data, data === ""]);

  // handle save
  const _onEditorStateChange = async (state: EditorState) => {
    setIsContentBeingSaved(true);

    const dataToUpdate = {
      content: {
        content: convertToRaw(state.getCurrentContent()),
      },
    };

    await updateContentInDb(contentId, dataToUpdate);

    setIsContentBeingSaved(false);
    // ! content issue coming from the late save
    // ! -> if we switch two blocks it will try to rerender the blocks
    // ! -> and the content will be the saved one, so not the latest one if it was not saved yet
  };

  const onEditorStateChange = useDebounce(_onEditorStateChange);

  // handle tab and reverse tab
  const handleTab = (e: SyntheticKeyboardEvent) => {
    if (e.shiftKey) {
      e.preventDefault();

      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const anchorKey = selection.getAnchorKey();
      const currentBlock = currentContent.getBlockForKey(anchorKey);
      const startOffset = 0;
      const endOffset = 4;
      const textAtStartOfLine = currentBlock
        .getText()
        .substring(startOffset, endOffset);

      if (textAtStartOfLine === "    ") {
        const spacesToRemove = endOffset;
        const newContent = Modifier.replaceText(
          currentContent,
          SelectionState.createEmpty(anchorKey).merge({
            anchorOffset: startOffset,
            focusOffset: endOffset,
          }),
          ""
        );
        const newSelection = selection.merge({
          anchorOffset: selection.getAnchorOffset() - spacesToRemove,
          focusOffset: selection.getFocusOffset() - spacesToRemove,
        });
        const newEditorState = EditorState.push(
          editorState,
          newContent,
          "remove-range"
        );

        setEditorState(
          EditorState.forceSelection(newEditorState, newSelection)
        );
        onEditorStateChange(newEditorState);
      }
    } else {
      e.preventDefault();
      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const newContent = Modifier.replaceText(
        currentContent,
        selection,
        "    "
      );
      const newEditorState = EditorState.push(
        editorState,
        newContent,
        "insert-characters"
      );

      setEditorState(newEditorState);
      onEditorStateChange(newEditorState);
    }
  };

  return (
    <div
      style={{ backgroundColor: "#F6F9FB", paddingTop: "20px" }}
      onClick={(e) => e.stopPropagation()}
    >
      {draftDataPending ? (
        <div></div>
      ) : (
        <Editor
          editorState={editorState}
          onEditorStateChange={(state) => {
            setEditorState(state);
            onEditorStateChange(state);
          }}
          onTab={handleTab}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          handlePastedText={() => false}
          toolbarHidden={disableEdit}
          readOnly={disableEdit}
          editorStyle={{ marginLeft: "20px" }}
          toolbar={{
            textAlign: { inDropdown: true },
            inline: { inDropdown: true },
            list: { inDropdown: true },
            options: [
              "inline",
              "list",
              "emoji",
              "remove",
              "blockType",
              "fontSize",
              "fontFamily",
              "textAlign",
              "colorPicker",
            ],
          }}
          toolbarStyle={{
            width: "580px",
            minWidth: "580px",
            marginLeft: "20px",
            border: "solid 0.3px #bbbbbb",
            marginBottom: "15px",
          }}
        />
      )}
    </div>
  );
}
