import { fetchDocuments } from "../../app/stores/documents/documentsClient";
import { fetchBaselineValidations } from "../../app/stores/documentsBaseline/documentsBaselineClient";
import { getGTEDateOfWeek } from "./GetDateOfWeek";

export const fetchLastWeekDocuments = async () => {
  const documents = await fetchDocuments(1, 100, {}, { created_at: "desc" });
  return documents.documentsModels.filter(
    (doc) => new Date(doc.created_at) > new Date(getGTEDateOfWeek(new Date()))
  );
};

export const fetchLastWeekValidations = async () => {
  const documents = await fetchBaselineValidations(1, 100, "", {
    published_at: "desc",
  });
  const result = documents.data.baselineValidations;

  return result.filter(
    (doc: any) =>
      new Date(doc.published_at) > new Date(getGTEDateOfWeek(new Date()))
  );
};
