import { Col, Row } from "antd";
import style from "./style.module.css";

interface StepCardType {
  section: any;
  stepStyle?: any;
}

export default function StepCard({ section, stepStyle }: StepCardType) {
  return (
    <div className={style.cardDesign} style={stepStyle ? stepStyle : {}}>
      <Row justify="space-around" align="middle" style={{ minHeight: "40px" }}>
        <Col span={2}>
          <div style={{ paddingLeft: "8px" }}>{section.position}</div>
        </Col>
        <Col span={22}>
          <div className="stepName">{section.name}</div>
        </Col>
      </Row>
    </div>
  );
}
