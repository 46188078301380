import { ApiDocumentFamilyEnum } from "@armtekfrance/corenexus-pn-sn";
import {
  DocumentPublicationStatusFilter,
  DocumentTypeFilter,
  FilterMaster,
  GlobalStore,
  NameFilter,
  StoreFilterType,
  UtilsFunction,
} from "@armtekfrance/webgate/dist";
import DocumentModelsHeroCreate from "components/DocumentModels/Hero/Create/DocumentsModelsHeroCreate";
import { t } from "i18next";
import styles from "./DocumentsModelsFilters.module.scss";

export default function DocumentsModelsFilters({
  documentFamily,
  dataCy = "DocumentsModelsFilters",
}: {
  documentFamily: ApiDocumentFamilyEnum;
  dataCy?: string;
}) {
  const filterManager = GlobalStore.filter;
  const pubStatusFilter = new DocumentPublicationStatusFilter(
    t("doc.models.procedure.status"),
    filterManager,
    StoreFilterType.DOCUMENT_MODEL
  );

  const nameDocumentModelNameFilter = new NameFilter(
    t("filters.operate.modelName"),
    filterManager,
    StoreFilterType.DOCUMENT_MODEL,
    t("edit.name")
  );
  const modelTypeFilter = new DocumentTypeFilter(
    t("filters.operate.modelType"),
    UtilsFunction.getDocumentTypesFromFamily(documentFamily),
    filterManager,
    StoreFilterType.DOCUMENT_MODEL
  );
  filterManager.clearFilters();
  filterManager.addFilter(nameDocumentModelNameFilter);
  filterManager.addFilter(pubStatusFilter);
  filterManager.addFilter(modelTypeFilter);

  filterManager.storeFilters.documentModelFilter.family = [documentFamily];

  return (
    <div className={styles.flexEnd} data-cy={dataCy}>
      <FilterMaster
        dataCy={`${dataCy}_FilterMaster`}
        filterManager={filterManager}
        fetchData={() => {
          return;
        }}
      />
      <DocumentModelsHeroCreate
        dataCy={`${dataCy}_DocumentModelsHeroCreate`}
        documentFamily={documentFamily}
      />
    </div>
  );
}
