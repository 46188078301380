import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./LinkStyles.module.scss";
import { Button } from "@armtekfrance/armtek-react-design-system";

export default function PendingValidationLink({
  validationsThisWeek,
}: {
  validationsThisWeek: number;
}) {
  const { t } = useTranslation();

  const displayedCount = validationsThisWeek > 99 ? "99+" : validationsThisWeek;

  return (
    <div className={styles.linkWrapper}>
      <Link to="/account/knowledge/validate">
        <Button
          child={
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonStyle}>{displayedCount}</div>
              <span>{t("dashboard.pendingValidationThisWeek")}</span>
            </div>
          }
        />
      </Link>
    </div>
  );
}
