import { ToolFilled } from "@ant-design/icons";
import { Card, List } from "antd";
import { fetchBaselineExecutions } from "app/stores/executionFlux/executionFluxClients";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ExecutionFluxCard.module.scss";
import { useTranslation } from "react-i18next";
import translateDocumentType from "util/translateDocumentType";

export default function ExecutionFluxCard() {
  const [list, setList] = useState<any>([]);
  const { t } = useTranslation();

  let exeFilter: any = { family: ["document", "group"] };
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 7);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  exeFilter["date"] = {
    gte:
      yesterday.getFullYear() +
      "-" +
      (yesterday.getMonth() + 1) +
      "-" +
      yesterday.getDate(),
    lte:
      tomorrow.getFullYear() +
      "-" +
      (tomorrow.getMonth() + 1) +
      "-" +
      tomorrow.getDate(),
  };

  useEffect(() => {
    fetchBaselineExecutions(1, 5, exeFilter).then((response) => {
      setList(response.baselineExecutions);
    });
  }, []);

  return (
    <Link to="/account/knowledge/results">
      <Card // CARD EXECUTION FLUX
        className={styles.cardBody}
        style={{
          marginRight: "40px",
          width: "100%",
          minWidth: "350px",
          marginBottom: "10px",
        }}
      >
        <div className={styles.iconBox}>
          <div className={styles.iconImageBox}>
            <ToolFilled style={{ fontSize: "25px", color: "white" }} />
          </div>
          <div
            style={{ paddingTop: "5px", color: "#030852" }}
            className={styles.clickableHead}
          >
            {t("dashboard.executionFlux")}
          </div>
        </div>
        <List
          className="demo-loadmore-list "
          dataSource={list}
          itemLayout="horizontal"
          renderItem={(props: any) => (
            <Link to={"/account/knowledge/results"}>
              <List.Item
                className={styles.listItemTest}
                style={{ paddingLeft: "10px" }}
              >
                <List.Item.Meta
                  avatar={
                    <AvatarComponent
                      src={props.user?.avatar?.media_content_uid}
                      firstName={props.user.first_name}
                      lastName={props.user.last_name}
                      showToolTip={false}
                    />
                  }
                  title={
                    <p>{props.user.first_name + " " + props.user.last_name}</p>
                  }
                />

                <List.Item.Meta
                  description={
                    <p>
                      {translateDocumentType(props.document_type, t) + " "}
                      <span
                        style={{
                          color: "black",
                          marginLeft: "15px",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {props.baseline_name}
                      </span>{" "}
                    </p>
                  }
                />
              </List.Item>
            </Link>
          )}
        />
      </Card>
    </Link>
  );
}
