import { TFunction } from "react-i18next";
import { DocumentTypeConst } from "../app/stores/documents/types";

export default function translateDocumentType(
  type: string,
  t: TFunction<"translation", undefined>
) {
  switch (type) {
    case DocumentTypeConst.procedure:
      return t("doc.models.procedure.title");
    case DocumentTypeConst.report:
      return t("doc.models.report.title");
    case DocumentTypeConst.form:
      return t("doc.models.form.title");
    case DocumentTypeConst.specification:
      return t("doc.models.specification.title");
    case DocumentTypeConst.communication:
      return t("doc.models.communication.title");
    case DocumentTypeConst.mixed:
      return t("doc.models.mixed.title");
    case DocumentTypeConst.manual:
      return t("doc.models.manual.title");
    case DocumentTypeConst.requirements:
      return t("doc.models.requirements.title");
    case DocumentTypeConst.guide:
      return t("doc.models.guide.title");
    case DocumentTypeConst.group_sets:
      return t("doc.models.group_setss.title");
    case DocumentTypeConst.organisation_asset:
      return t("doc.models.organisation_asset.title");
    case DocumentTypeConst.organisation_project:
      return t("doc.models.organisation_project.title");
    case DocumentTypeConst.organisation_product:
      return t("doc.models.organisation_product.title");
    case DocumentTypeConst.organisation_workstation:
      return t("doc.models.organisation_workstation.title");
  }
}

export function translateNewDocumentType(
  type: string,
  t: TFunction<"translation", undefined>
) {
  switch (type) {
    case DocumentTypeConst.procedure:
      return t("doc.models.procedure.new");
    case DocumentTypeConst.report:
      return t("doc.models.report.new");
    case DocumentTypeConst.form:
      return t("doc.models.form.new");
    case DocumentTypeConst.specification:
      return t("doc.models.specification.new");
    case DocumentTypeConst.communication:
      return t("doc.models.communication.new");
    case DocumentTypeConst.mixed:
      return t("doc.models.mixed.new");
    case DocumentTypeConst.manual:
      return t("doc.models.manual.new");
    case DocumentTypeConst.requirements:
      return t("doc.models.requirements.new");
    case DocumentTypeConst.guide:
      return t("doc.models.guide.new");
    case DocumentTypeConst.group_sets:
      return t("doc.models.group_setss.new");
    case DocumentTypeConst.organisation_asset:
      return t("doc.models.organisation_asset.new");
    case DocumentTypeConst.organisation_project:
      return t("doc.models.organisation_project.new");
    case DocumentTypeConst.organisation_product:
      return t("doc.models.organisation_product.new");
    case DocumentTypeConst.organisation_workstation:
      return t("doc.models.organisation_workstation.new");
  }
}
