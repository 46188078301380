import { useEffect, useState } from "react";
import { fetchBaselineExecutionById } from "../../app/stores/executionFlux/executionFluxClients";
import { Button, Modal, Skeleton } from "antd";
import { fetchContents } from "../../app/stores/content/contentClient";
import ExecutionFluxBlocks from "../ExecutionFluxBlocks";
import { useTranslation } from "react-i18next";
interface ExecutionProps {
  isPreviewVisible: boolean;
  handleCancel: () => void;
  alert: any;
}
const alertedBlockPreview = ({
  isPreviewVisible,
  handleCancel,
  alert,
}: ExecutionProps) => {
  const { t } = useTranslation();
  const [executedBlock, setexecutedBlock] = useState<any>({});
  const [isExecutedBlockLoading, setIsExecutedBlockLoading] = useState(false);
  useEffect(() => {
    setIsExecutedBlockLoading(true);

    if (Array.isArray(alert.executed_block_id)) {
      let finalData: any[] = [];
      fetchBaselineExecutionById(alert.execution_id).then(
        async (response: any) => {
          for (let index = 0; index < alert.executed_block_id.length; index++) {
            const executedBlock = await response.data.executed_block.filter(
              (block: any) => block.id === alert.executed_block_id[index]
            )[0];
            const AllContent = await fetchContents(
              "0",
              { position: "asc" },
              {
                block_baseline_id: executedBlock.block_baseline_id,
              }
            );
            const contentData = [{ content: AllContent.contents[0] }];
            const block_baseline = {
              block_baseline: {
                type: executedBlock.block_baseline.type,
                block_baseline_has_content: contentData,
              },
              executed: [executedBlock],
            };

            finalData.push(block_baseline);
          }

          setexecutedBlock(finalData);
          setIsExecutedBlockLoading(false);
        }
      );
    } else {
      fetchBaselineExecutionById(alert.execution_id).then(async (response) => {
        const executedBlock = await response.data.executed_block.filter(
          (block: any) => block.id === alert.executed_block_id
        )[0];
        const AllContent = await fetchContents(
          "0",
          { position: "asc" },
          {
            block_baseline_id: executedBlock.block_baseline_id,
          }
        );
        if (Array.isArray(alert.requested_data)) {
          if (
            alert.requested_data.filter((data: any) => data !== null).length > 0
          ) {
            executedBlock.requested_data = alert.requested_data;
          }
        } else {
          executedBlock.requested_data = alert.requested_data;
        }
        const contentData = [{ content: AllContent.contents[0] }];
        const block_baseline = {
          block_baseline: {
            type: executedBlock.block_baseline.type,
            block_baseline_has_content: contentData,
          },
          executed: [executedBlock],
        };
        setexecutedBlock([block_baseline]);
        setIsExecutedBlockLoading(false);
      });
    }
  }, [alert]);

  return (
    <div>
      <Modal
        title="Preview"
        open={isPreviewVisible}
        onCancel={() => {
          handleCancel();
          setexecutedBlock({});
        }}
        style={{
          top: 15,
        }}
        width={"95vw"}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCancel();
              setexecutedBlock({});
            }}
          >
            {`${t("button.cancel")}`}
          </Button>,
        ]}
      >
        {!isExecutedBlockLoading ? (
          <div>
            {executedBlock.length > 0 && (
              <div>
                {executedBlock.map((block: any) => {
                  return (
                    <div>
                      <ExecutionFluxBlocks block_baseline={block} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <Skeleton active />
        )}
      </Modal>
    </div>
  );
};

export default alertedBlockPreview;
