import {
  SizeType,
  ButtonType,
  IconButton,
} from "@armtekfrance/armtek-react-design-system";
import {
  ApiDocumentBaseline,
  ApiDocumentModel,
  ApiExecutionMonitor,
  ApiExecutionMonitorSearchRequest,
  ApiExecutionStatus,
  ApiExecutionType,
  ApiObject,
  ApiObjectStateEnum,
} from "@armtekfrance/corenexus-pn-sn";
import {
  FilterMaster,
  ExpandableDocumentList,
  FilterManager,
  useStoreState,
  GlobalStore,
  DeleteButton,
  Layout,
  LayoutType,
} from "@armtekfrance/webgate/dist";
import { useNavigate } from "react-router-dom";
import styles from "./AssignPage.module.scss";

interface AssignComponentProps {
  filterManager: FilterManager;
}

export default function AssignComponent({
  filterManager,
}: AssignComponentProps) {
  const navigate = useNavigate();

  const [filterExecution] = useStoreState(
    GlobalStore.filter.storeFilters.executionFilter
  );
  const [filterDocument] = useStoreState(
    GlobalStore.filter.storeFilters.documentBaselineFilter
  );
  const [filterUser] = useStoreState(
    GlobalStore.filter.storeFilters.userFilter
  );

  const filter: ApiExecutionMonitorSearchRequest = {
    execution: {
      ...filterExecution,
      status: [ApiExecutionStatus.EXECUTING],
      types: [ApiExecutionType.CONSTRUCTOR],
    },
    document: filterDocument,
    operator: filterUser,
    object: {
      states: [ApiObjectStateEnum.NOT_INSTANTIATED],
    },
    config: {
      extend: true,
      user: {
        extend: true,
      },
      baseline: {
        extend: true,
        qr_code: {
          extend: true,
        },
      },
      object_constructor: {
        extend: true,
      },
    },
  };

  return (
    <Layout
      layoutType={LayoutType.NO_SIDE}
      header={
        <div className={styles.filterMasterContainer}>
          <FilterMaster
            filterManager={filterManager}
            fetchData={() => {
              //console.log("debug");
            }}
          />
        </div>
      }
      content={
        <div className={styles.assignWrapper}>
          <div className={styles.expandableDocumentListContainer}>
            <ExpandableDocumentList
              object="ApiExecutionMonitor"
              emitFilter={filter}
              onCheck={() => console.log("onCheck")}
              disabled={false}
              size={SizeType.small}
              slim={true}
              actionButtons={(
                prop:
                  | ApiExecutionMonitor
                  | ApiDocumentModel
                  | ApiDocumentBaseline
                  | ApiObject
              ) => {
                const typedProp = prop as ApiExecutionMonitor;
                return (
                  <div className={styles.actionButtonsContainer}>
                    <div onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        type={ButtonType.underline}
                        size={SizeType.medium}
                        iconData="FormOutlined"
                        onClick={() =>
                          navigate(
                            `/account/system/assign/constructor/${typedProp.baseline.id}/${typedProp.id}/${typedProp.object_constructor?.id}`
                          )
                        }
                        // onClick={() => console.log("edit", Clickedindex)}
                      />
                    </div>
                    <DeleteButton
                      object={typedProp}
                      onDelete={() => {
                        // const previous =
                      }}
                    />
                    {/* <div onClick={(e) => e.stopPropagation()}>
                <IconButton
                  type={ButtonType.underline}
                  size={SizeType.medium}
                  iconData="DeleteOutlined"
                  onClick={() => console.log("edit", Clickedindex)}
                />
              </div>

              <div
                style={{
                  position: "relative",
                }}
              >
                <ThreeDotMenu menuOptions={[]} />
              </div> */}
                  </div>
                );
              }}
            />
          </div>
        </div>
      }
    />
  );
}
