import { Tooltip } from "antd";
import styles from "./MagicTypePublishView.module.scss";
import { useTranslation } from "react-i18next";

export default function MagicTypePublishView({
  status,
}: {
  status: string | undefined;
}) {
  const { t } = useTranslation();

  let statusClass = "";
  let statusText = "";
  let tooltipTitle = "";

  switch (status) {
    case "published":
    case "VALIDATED":
    case "published_and_inwork":
      statusClass = styles.published;
      statusText = "P";
      tooltipTitle = t("magicTypePublishView.published");
      break;
    case "PENDING":
      statusClass = styles.pending;
      statusText = "W";
      tooltipTitle = t("magicTypePublishView.waitingForValidation");
      break;
    case "REFUSED":
      statusClass = styles.refused;
      statusText = "R";
      tooltipTitle = t("magicTypePublishView.rejected");
      break;
    default:
      statusClass = styles.notPublished;
      statusText = "U";
      tooltipTitle = t("magicTypePublishView.unPublished");
  }

  return (
    <Tooltip title={tooltipTitle}>
      <div className={styles.magicTypePublishViewWrapper}>
        <div className={statusClass}>
          <p>{statusText}</p>
        </div>
      </div>
    </Tooltip>
  );
}
