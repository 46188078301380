import { FormInstance } from "antd";
import {
  useAddTeamForm,
  useAddTeamFormDispatch,
} from "../Provider/AddTeamFormContext";
import { RefObject } from "react";
import AddTeamFormTextFieldsTeamName from "./Fields/AddTeamFormTextFieldsTeamName";
import AddTeamFormTextFieldsIdentification from "./Fields/AddTeamFormTextFieldsIdentification";
import AddTeamFormTextFieldsCompanyDepartments from "./Fields/AddTeamFormTextFieldsCompanyDepartment";
import AddTeamFormtextFieldsDescription from "./Fields/AddTeamFormTextFieldsDescription";
import AddTeamFormtextFieldsLocation from "./Fields/AddTeamFormTextFieldsLocation";
import { MessageInstance } from "antd/lib/message";

interface AddTeamFormFieldsProps {
  formRef: RefObject<FormInstance<any>>;
  messageApi: MessageInstance;
}

export default function AddteamFormTextFields({
  formRef,
  messageApi,
}: AddTeamFormFieldsProps) {
  const addTeamFormContext = useAddTeamForm();
  const dispatchAddTeamForm = useAddTeamFormDispatch();

  // identification dropdown functions
  const handleDisplayIdentificationDropdown = () => {
    if (addTeamFormContext.suggestedTeamNickname.length > 0) {
      // setTimeout here can create unexpected situations, be careful
      setTimeout(() => {
        dispatchAddTeamForm({
          type: "setIsPopOverVisibleNickname",
          isPopOverVisibleNickname: true,
        });
      }, 300);
    }
  };

  const handleHideIdentificationDropdown = () => {
    // setTimeout here can create unexpected situations, be careful
    setTimeout(() => {
      dispatchAddTeamForm({
        type: "setIsPopOverVisibleNickname",
        isPopOverVisibleNickname: false,
      });
    }, 200);
  };

  return (
    <>
      <AddTeamFormTextFieldsTeamName
        handleDisplay={handleDisplayIdentificationDropdown}
        handleHide={handleHideIdentificationDropdown}
      />
      <AddTeamFormTextFieldsIdentification
        formRef={formRef}
        handleDisplay={handleDisplayIdentificationDropdown}
        handleHide={handleHideIdentificationDropdown}
      />
      <AddTeamFormTextFieldsCompanyDepartments messageApi={messageApi} />
      <AddTeamFormtextFieldsDescription />
      <AddTeamFormtextFieldsLocation />
    </>
  );
}
