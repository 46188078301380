import { FormOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MagicEditForm.module.scss";
import { useMagic } from "components/MagicListItem/MagicContext";

export default function MagicEditForm({
  documentId,
  type = "models",
  onOpenModal,
}: // legacyParamType = "content",
{
  documentId: number | string;
  type?: "models" | "analysis";
  onOpenModal?: (
    id: string | number,
    type: "openModal" | "deleteDashboard" | "changeActive" | "deleteModel"
  ) => void;
  // legacyParamType: "content" | "organisation" | "sets";
}) {
  const navigate = useNavigate();
  const magic = useMagic();

  const [clickAnimation, setClickAnimation] = useState<boolean>(false);
  const goToEdit = () => {
    requestAnimationFrame(() =>
      setTimeout(() => {
        const navigationPath =
          magic.documentData?.family === "SYSTEM"
            ? "/account/system/models/edit/"
            : "/account/knowledge/models/edit/";
        navigate(`${navigationPath}${documentId}`);
        // navigate(`/account/system/models/edit/${documentId}`);
      }, 300)
    );
  };
  return (
    <div className={styles.magicEditFromWrapper} data-cy="MagicEditForm">
      <FormOutlined
        onClick={(e) => {
          if (type === "analysis") {
            onOpenModal && onOpenModal(documentId as string, "openModal");
            return e.stopPropagation();
          }
          setClickAnimation(!clickAnimation);
          goToEdit();
          return e.stopPropagation();
        }}
        className={`${clickAnimation && styles.writingAnimation}`}
      />
    </div>
  );
}
