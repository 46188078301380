import { useState, useEffect } from "react";
import { fetchBlobObjectTanstack } from "../../app/stores/content/contentClient";
import { Avatar, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

interface ImageResponsiveProp {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

interface ImageProps {
  src: string | null;
  size?: number | "large" | "small" | "default" | ImageResponsiveProp;
  style?: any;
  firstName: string;
  lastName: string;
  className?: string;
  showToolTip?: boolean;
  tooltipPlacement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
}

export default function AvatarComponent({
  src,
  size,
  style,
  firstName,
  lastName,
  showToolTip = true,
  tooltipPlacement,
  className,
}: ImageProps) {
  const [userAvatar, setUserAvatar] = useState<any>(null);

  // Segment an avatar color based on the firstName first character
  const avatarColor = (() => {
    if (/^[a-fA-F]{1}$/.test(firstName?.charAt(0))) {
      return "bg-primary";
    } else if (/^[g-lG-L]{1}$/.test(firstName?.charAt(0))) {
      return "bg-success";
    } else if (/^[m-rM-R]{1}$/.test(firstName?.charAt(0))) {
      return "bg-dark";
    } else if (/^[s-vS-V]{1}$/.test(firstName?.charAt(0))) {
      return "bg-warning text-dark";
    }
    return "bg-danger";
  })();

  const { status, error, blobObject } = fetchBlobObjectTanstack(
    src,
    true,
    false,
    1000 * 60 * 60 * 24
  );

  useEffect(() => {
    if (status === "success") {
      try {
        if (blobObject?.media !== null) {
          setUserAvatar(
            `data:${blobObject.media.ContentType};base64, ${blobObject.media.file}`
          );
        } else if (blobObject?.fullMedia !== null) {
          setUserAvatar(
            `data:${blobObject.fullMedia.ContentType};base64, ${blobObject.fullMedia.file}`
          );
        }
      } catch (err) {
        console.error(error);
        setUserAvatar(null);
      }
    }
    if (!src) {
      setUserAvatar(null);
    }
  }, [src, status === "success"]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {showToolTip ? (
          <Tooltip
            title={firstName + " " + lastName}
            placement={tooltipPlacement ? tooltipPlacement : "top"}
          >
            {userAvatar ? (
              <Avatar
                className={avatarColor + " " + className}
                src={userAvatar}
                style={style}
                size={size ? size : "default"}
              />
            ) : firstName && lastName ? (
              <Avatar
                className={avatarColor + " " + className}
                style={style}
                size={size ? size : "default"}
              >
                {firstName.charAt(0).toUpperCase() +
                  lastName.charAt(0).toUpperCase()}
              </Avatar>
            ) : (
              <Avatar
                className={avatarColor + " " + className}
                style={style}
                size={size ? size : "default"}
                icon={<UserOutlined />}
              />
            )}
          </Tooltip>
        ) : (
          <>
            {userAvatar ? (
              <Avatar
                className={avatarColor + " " + className}
                src={userAvatar}
                style={style}
                size={size ? size : "default"}
              />
            ) : firstName && lastName ? (
              <Avatar
                className={avatarColor + " " + className}
                style={style}
                size={size ? size : "default"}
              >
                {firstName.charAt(0).toUpperCase() +
                  lastName.charAt(0).toUpperCase()}
              </Avatar>
            ) : (
              <Avatar
                className={avatarColor + " " + className}
                style={style}
                size={size ? size : "default"}
                icon={<UserOutlined />}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
