import styles from "./MediaPickerModalContent.module.scss";
import MediaPickerModalHeader from "./MediaPickerModalHeader";
import MediaPickerMediasArticle from "./MediaPickerMediasArticle";
import MediaPickerUploadArticle from "./MediaPickerUploadArticle";
import { GlobalStore, useStoreState } from "@armtekfrance/webgate/dist";

export default function MediaPickerModalContent() {
  useStoreState(GlobalStore.media.getCurrentTab());
  return (
    <div className={styles.mediaPickerModalContentWrapper}>
      <MediaPickerModalHeader />
      {GlobalStore.media.getCurrentTab().currentTab === "media" && (
        <MediaPickerMediasArticle />
      )}
      {GlobalStore.media.getCurrentTab().currentTab === "upload" && (
        <MediaPickerUploadArticle />
      )}
    </div>
  );
}
