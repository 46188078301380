import { QueryStatus } from "@tanstack/react-query";
import { notificationType } from "app/stores/notifications/notificationsType";
import { PaginationType } from "app/stores/pagination/types";
import { makeArrayByCreatedAtDay } from "components/Header/Provider/HeaderReducer";

export type stateNotificationsProvider = {
  statusNotifications: QueryStatus;
  errorNotifications: unknown;
  notifications: notificationType[] | undefined;
  paginationNotifications: PaginationType;

  statusNotificationsCount: QueryStatus;
  errorNotificationsCount: unknown;
  notificationsCount: number | undefined;

  notificationsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  openedNotificationsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  closedNotificationsByDay: {
    createdAt: string;
    notifications: notificationType[] | undefined;
  }[];
  previewInfo: {
    typeOfPreview: "PROCEDURE" | "EXECUTION" | "SINGLE_BLOCK";
    idOfPreview: any;
    isOpen: boolean;
  };
};

export type TypeNotificationsContextReducerAction =
  | {
      type: "setNotificationTanstackApi";
      setNotificationTanstackApi: {
        statusNotifications: stateNotificationsProvider["statusNotifications"];
        errorNotifications: stateNotificationsProvider["errorNotifications"];
      };
    }
  | {
      type: "setNotificationTanstack";
      setNotificationTanstack: {
        notifications: stateNotificationsProvider["notifications"];
        paginationNotifications: stateNotificationsProvider["paginationNotifications"];
      };
    }
  | {
      type: "setNotificationCountTanstackApi";
      setNotificationCountTanstackApi: {
        statusNotificationsCount: stateNotificationsProvider["statusNotificationsCount"];
        errorNotificationsCount: stateNotificationsProvider["errorNotificationsCount"];
      };
    }
  | {
      type: "setNotificationCountTanstack";
      setNotificationCountTanstack: {
        notificationsCount: stateNotificationsProvider["notificationsCount"];
      };
    }
  | {
      type: "togglePreview";
    }
  | {
      type: "setPreview";
      previewInfo: {
        typeOfPreview: stateNotificationsProvider["previewInfo"]["typeOfPreview"];
        idOfPreview: stateNotificationsProvider["previewInfo"]["idOfPreview"];
        isOpen: stateNotificationsProvider["previewInfo"]["isOpen"];
      };
    };

export function notificationsReducer(
  stateNotifications: stateNotificationsProvider,
  action: TypeNotificationsContextReducerAction
): stateNotificationsProvider {
  switch (action.type) {
    case "setNotificationTanstackApi": {
      return {
        ...stateNotifications,
        statusNotifications:
          action.setNotificationTanstackApi.statusNotifications,
        errorNotifications:
          action.setNotificationTanstackApi.errorNotifications,
      };
    }
    case "setNotificationTanstack": {
      return {
        ...stateNotifications,
        notifications: action.setNotificationTanstack.notifications,
        paginationNotifications:
          action.setNotificationTanstack.paginationNotifications,
        notificationsByDay: makeArrayByCreatedAtDay(
          action.setNotificationTanstack.notifications
        ),
        openedNotificationsByDay: makeArrayByCreatedAtDay(
          action.setNotificationTanstack.notifications?.filter(
            (notification) => notification.opened
          )
        ),
        closedNotificationsByDay: makeArrayByCreatedAtDay(
          action.setNotificationTanstack.notifications?.filter(
            (notification) => !notification.opened
          )
        ),
      };
    }
    case "setNotificationCountTanstackApi": {
      return {
        ...stateNotifications,
        statusNotificationsCount:
          action.setNotificationCountTanstackApi.statusNotificationsCount,
        errorNotificationsCount:
          action.setNotificationCountTanstackApi.errorNotificationsCount,
      };
    }
    case "setNotificationCountTanstack": {
      return {
        ...stateNotifications,
        notificationsCount:
          action.setNotificationCountTanstack.notificationsCount,
      };
    }
    case "setPreview": {
      return {
        ...stateNotifications,
        previewInfo: {
          typeOfPreview: action.previewInfo.typeOfPreview,
          idOfPreview: action.previewInfo.idOfPreview,
          isOpen: action.previewInfo.isOpen,
        },
      };
    }

    case "togglePreview": {
      return {
        ...stateNotifications,
        previewInfo: {
          ...stateNotifications.previewInfo,
          isOpen: !stateNotifications.previewInfo.isOpen,
        },
      };
    }
    default: {
      return { ...stateNotifications };
    }
  }
}
