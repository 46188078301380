import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "libs/requests";
import { RouterProvider } from "react-router-dom";
import { navigation } from "../Router/NavigationService";
import ManagementJSProvider from "./ManagementJSProvider";

export default function TanstackQueriesClient() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ManagementJSProvider />
      <RouterProvider router={navigation.router} />
    </QueryClientProvider>
  );
}
