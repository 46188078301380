import React from "react";
import { Col, Row } from "antd";
import ImageDisplayComponent from "../ImageDisplayComponent/ImageDisplayComponent";
import { useTranslation } from "react-i18next";
import VideoPlayerComponent from "../VideoPlayerComponent";

interface CsvPreviewRowProps {
  csvData: any;

  fields: string[];
  mediasOfRequestedDatas: (reqDatas: any) => Array<string>;
}

const csvPreviewRow = ({
  fields,
  mediasOfRequestedDatas,
  csvData,
}: CsvPreviewRowProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col span={16}>
          <table
            style={{
              width: "100%",
            }}
          >
            <thead style={{}}>
              {fields.map((field) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <tr
                    style={{
                      border: "1px solid black",
                      width: "30%",
                      paddingLeft: "12px",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {field}
                  </tr>

                  <tr
                    style={{
                      border: "1px solid black",
                      width: "70%",
                      paddingLeft: "10px",
                    }}
                  >
                    {field.includes("input_image") ||
                    field.includes("signature") ? (
                      mediasOfRequestedDatas(csvData[field]).map((media) => (
                        <div
                          style={{
                            width: "20%",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {media && (
                            <ImageDisplayComponent
                              src={`${media}`}
                              alt={t("operate.imageSent")}
                              thumbnail={true}
                            ></ImageDisplayComponent>
                          )}
                        </div>
                      ))
                    ) : field.includes("input_video") ||
                      field.includes("input_audio") ? (
                      mediasOfRequestedDatas(csvData[field]).map((media) => (
                        <div
                          style={{
                            width: "40%",
                          }}
                        >
                          {media && (
                            <VideoPlayerComponent
                              playVideo={{
                                media_content: {
                                  media_content_uid: media,
                                },
                              }}
                            ></VideoPlayerComponent>
                          )}
                        </div>
                      ))
                    ) : (
                      <p
                        style={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {csvData[field]}
                      </p>
                    )}
                  </tr>
                </div>
              ))}
            </thead>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default csvPreviewRow;
