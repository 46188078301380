import { Link, matchPath, useLocation } from "react-router-dom";
import styles from "./HeaderMenuTabItem.module.scss";

export default function HeaderMenuTabItem({
  label,
  to,
}: {
  label: string;
  to: string;
}) {
  const location = useLocation();

  return (
    <Link
      className={styles.headerMenuTabItemWrapper}
      to={to}
      aria-selected={matchPath(to, location.pathname) !== null}
    >
      {label}
    </Link>
  );
}
