import styles from "./InputSearch.module.scss";
import { ReactComponent as MagnifyingGlass } from "../assets/MagnifyingGlass.svg";
import { FormEvent, useRef } from "react";

export default function InputSearch({
  placeholder,
  onSearch,
  allowClear = false,
}: {
  placeholder: string;
  onSearch: (value: string) => void;
  allowClear?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (inputRef.current === null) return;

    onSearch(inputRef.current.value);
  }
  return (
    <form className={styles.inputSearchWrapper} onSubmit={onSubmit}>
      <input ref={inputRef} type="text" placeholder={placeholder} />
      {allowClear && <></>}
      <button>
        <MagnifyingGlass />
      </button>
    </form>
  );
}
