import { Icon } from "@armtekfrance/armtek-react-design-system";
import styles from "./InputSearch.module.scss";
import { FormEvent, useRef } from "react";

export default function InputSearch({
  dataCy = "InputSearch",
  placeholder,
  onSearch,
}: {
  dataCy?: string;
  placeholder: string;
  onSearch: (value: string) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (inputRef.current === null) return;
    onSearch(inputRef.current.value);
  }
  return (
    <form
      className={styles.inputSearchWrapper}
      onSubmit={onSubmit}
      data-cy={`${dataCy}-form`}
    >
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        data-cy={`${dataCy}-input`}
      />
      <button data-cy={`${dataCy}-button`}>
        {/* <img
          src={
            undefined
            // NOT FOUND TODO : Fix
            // used to be : assets.MagnifyInglass
          }
          alt="MagnifyingGlass"
        /> */}
        <Icon iconData="SearchOutlined" />
      </button>
    </form>
  );
}
