import { ElementRef, useEffect, useRef } from "react";

export function MatrixRain() {
  const divRef = useRef<ElementRef<"div">>(null);
  const canvasRef = useRef<ElementRef<"canvas">>(null);

  // Setting up the letters
  let letters: any = "ARMTEK";
  letters = letters.split("");

  function draw(
    ctx: CanvasRenderingContext2D,
    drops: number[],
    fontSize: number
  ) {
    ctx.fillStyle = "rgba(0, 0, 0, .1)";
    ctx.font = "17px Verdana";
    ctx.fillRect(0, 0, canvasRef!.current!.width, canvasRef!.current!.height);
    for (let i = 0; i < drops.length; i++) {
      let text = letters[Math.floor(Math.random() * letters.length)];
      ctx.fillStyle = "#0f0";
      ctx.fillText(text, i * fontSize, drops[i] * fontSize);
      drops[i]++;
      if (
        drops[i] * fontSize > canvasRef!.current!.height &&
        Math.random() > 0.97
      ) {
        drops[i] = 0;
      }
    }
  }

  useEffect(() => {
    // Setting up the columns
    let fontSize = 14,
      columns = canvasRef!.current!.getBoundingClientRect().width / fontSize;

    // Setting up the drops
    let drops: number[] = [];
    for (let i = 0; i < columns; i++) {
      drops[i] = 1;
    }

    if (canvasRef.current) {
      let ctxreact = canvasRef.current.getContext("2d");

      // do something here with the canvas
      canvasRef.current.width = divRef!.current!.getBoundingClientRect().width;
      canvasRef.current.height =
        divRef!.current!.getBoundingClientRect().height;

      const interval = setInterval(() => {
        draw(ctxreact!, drops, fontSize);
      }, 38);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div
      ref={divRef}
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        zIndex: "10",
      }}
    >
      <canvas ref={canvasRef}></canvas>
    </div>
  );
}
