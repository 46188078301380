import Request from "../../../libs/requests";

export const fetchTeams = (filters = {}) => {
  return Request()
    .get("/teams", {
      params: {
        ...filters,
      },
    })
    .then((response) => response.data);
};
