import React, { useState } from "react";
import styles from "./RepliesOfAComment.module.scss";
import { Comment } from "app/stores/documentComments/types";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";

import("dayjs/locale/en");
import("dayjs/locale/fr");

export default function RepliesOfAComment({ replies }: { replies: Comment[] }) {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.resolvedLanguage);

  const [repliesVisible, setRepliesVisible] = useState<boolean>(false);

  return (
    <>
      {replies.length > 0 && (
        <>
          <div
            className={styles.repliesOfACommentHeader}
            onClick={() => {
              setRepliesVisible(!repliesVisible);
            }}
          >
            <div
              className={` ${styles.downArrowWrapper} ${
                repliesVisible ? styles.open : styles.close
              } `}
            >
              {/* TODO Does not exist anymore. What should it be now ????
                  <DownArrow />
              */}
            </div>
            {replies.length > 1 ? (
              <p>
                {`${replies.length} ${t("doc.models.edit.comment.replies")}`}
              </p>
            ) : (
              <p>{`${replies.length} ${t("doc.models.edit.comment.reply")}`}</p>
            )}
          </div>
          {repliesVisible && (
            <ol className={styles.repliesOfACommentWrapper}>
              {replies.map((reply, index) => {
                return (
                  <li key={index}>
                    <div className={styles.infoUserReply}>
                      <AvatarComponent
                        size={22}
                        src={reply.user?.avatar?.media_content_uid}
                        firstName={reply.user.first_name}
                        lastName={reply.user.last_name}
                      />

                      <p>{`${reply.user.first_name} ${reply.user.last_name}`}</p>
                      <p>{dayjs(reply.updated_at).fromNow()}</p>
                    </div>
                    <p>{reply.content}</p>
                  </li>
                );
              })}
            </ol>
          )}
        </>
      )}
    </>
  );
}
