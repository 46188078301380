import ImageDisplayComponentDS from "components/TempDS/ImageDisplayComponentDS";
import styles from "./MediaImageItemWrapper.module.scss";
import { Checkbox } from "antd";

export default function MediaImageItemWrapper({
  mediaName,
  showMediaName = true,
  imageDataUri,
  status,
  error,
  externalLoading = false,
  alt,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  convertToBase64 = true,
  showCheckbox = false,
  checked,
  onCheckedChange,
  onPreviewClickOpening,
  typeOfMedia,
  optionComponent = <></>,
  enablePreview = false,
}: {
  mediaName: string;
  showMediaName: boolean;
  imageDataUri: string;
  status: "loading" | "success" | "error";
  error: unknown;
  alt: string;
  externalLoading?: boolean;
  enablePreview?: boolean;
  convertToBase64?: boolean;
  showCheckbox: boolean;
  checked: boolean;
  onCheckedChange: (selected: boolean) => void;
  onPreviewClickOpening: () => void;
  typeOfMedia: "image" | "video";
  optionComponent?: JSX.Element;
}) {
  return (
    <div
      className={styles.mediaImageItemWrapperWrapper}
      aria-selected={checked}
    >
      <ImageDisplayComponentDS
        imageDataUri={imageDataUri}
        error={error}
        status={status}
        alt={alt}
        typeOfMedia={typeOfMedia}
        externalLoading={externalLoading}
        onPreviewClickOpening={onPreviewClickOpening}
        enablePreview={enablePreview}
      />
      {showMediaName && (
        <label
          aria-label={mediaName}
          onClick={() => {
            onCheckedChange(!checked);
          }}
        >
          {mediaName}
        </label>
      )}
      {showCheckbox && (
        <Checkbox
          className={styles.checkBox}
          aria-checked={checked}
          checked={checked}
          onChange={(e) => {
            onCheckedChange(e.target.checked);
          }}
        />
      )}
      <div className={styles.optionComponent}>{optionComponent}</div>
    </div>
  );
}
