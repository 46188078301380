import { useEffect, useState } from "react";
import styles from "./FooterReplyComment.module.scss";
import { useTranslation } from "react-i18next";
import TextArea from "../../components/TextArea/TextArea";
import { MutationStatus } from "@tanstack/react-query";
export default function FooterReplyComment({
  onPublish,
  apiStatus,
}: {
  onPublish: (replyText: string) => void;
  apiStatus: MutationStatus;
}) {
  const { t } = useTranslation();

  const [inputValueTextReply, setInputValueTextReply] = useState<string>("");
  const [waitingForApi, setWaitingForApi] = useState<boolean>(false);

  // Change waitingForApi to false when apiStatus is success or idle to clear the TextArea text
  useEffect(() => {
    if (apiStatus === "success") {
      setWaitingForApi(false);
    }
  }, [apiStatus]);

  // TODO put the comments back (when textArea will work)
  return (
    <div className={styles.footerReplyCommentWrapper}>
      <TextArea
        type="default"
        placeholder={`${t("doc.models.edit.comment.addAReply")}..`}
        onChangeText={(text: string) => {
          setInputValueTextReply(text);
        }}
        maxLength={250}
        rows={1}
        waitingForApi={waitingForApi}
      />
      <button
        className={`${apiStatus === "loading" && styles.loading}`}
        aria-disabled={inputValueTextReply.trim() === ""}
        onClick={() => {
          if (apiStatus === "idle" || apiStatus === "success") {
            setWaitingForApi(true);
          }
          if (inputValueTextReply.trim() !== "") onPublish(inputValueTextReply);
        }}
      >
        <p>{t("doc.models.edit.comment.publish")}</p>
      </button>
    </div>
  );
}
