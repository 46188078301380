import {
  NameFilter,
  // IntervalFilter,
  StoreFilterType,
  // DateTarget,
  DocumentTypeFilter,
  GlobalStore,
  UtilsFunction,
} from "@armtekfrance/webgate/dist";
import AssignComponent from "./AssignComponent";
import { useTranslation } from "react-i18next";
import {
  ApiDocumentFamilyEnum,
  ApiExecutionStatus,
} from "@armtekfrance/corenexus-pn-sn";

const AssignPage = () => {
  let filterManager = GlobalStore.filter;
  const [t] = useTranslation();

  let nameDocumentModelNameFilter = new NameFilter(
    t("filters.assign.modelName"),
    filterManager,
    StoreFilterType.DOCUMENT_BASELINE,
    t("filters.assign.modelNamePlaceholder")
  );

  let baseLineTypeFilter = new DocumentTypeFilter(
    "Model type",
    UtilsFunction.getDocumentTypesFromFamily(ApiDocumentFamilyEnum.SYSTEM),
    filterManager,
    StoreFilterType.DOCUMENT_BASELINE
  );

  let authorFilter = new NameFilter(
    t("filters.assign.authorName"),
    filterManager,
    StoreFilterType.USER,
    t("filters.assign.authorNamePlaceholder")
  );

  // const dateFilter = new IntervalFilter(
  //   filterManager,
  //   StoreFilterType.EXECUTION,
  //   t("filters.assign.creationDate"),
  //   DateTarget.CREATED_AT
  // );

  filterManager.clearFilters();
  filterManager.addFilter(nameDocumentModelNameFilter);
  filterManager.addFilter(authorFilter);
  //filterManager.addFilter(dateFilter);
  filterManager.addFilter(baseLineTypeFilter);

  filterManager.storeFilters.executionFilter.status = [
    ApiExecutionStatus.EXECUTING,
  ];
  filterManager.storeFilters.documentBaselineFilter.family = [
    ApiDocumentFamilyEnum.SYSTEM,
  ];

  return (
    <div>
      <AssignComponent filterManager={filterManager} />
    </div>
  );
};
export default AssignPage;
