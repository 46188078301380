import React, { useRef } from "react";
import { Button, message, Popover } from "antd";
import { AiOutlineQrcode, AiOutlineMinusCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QrCodeComponent from "../../../QrCodeComponent/QrCodeComponent";
import AvatarComponent from "../../../AvatarComponent/AvatarComponent";

import { fetchDocumentVersions } from "../../../../app/stores/documents/documentsClient";
import { fetchDocumentsBaselineByID } from "../../../../app/stores/documentsBaseline/documentsBaselineClient";
import InternalLinkModal from "./internalLinkModal";

interface ModelLinkType {
  data: any;
  disableEdit?: boolean;
  contentId: string | number;
  updateContentInDb: (id: string | number, data: any) => void;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function ModelLink({
  data,
  disableEdit,
  contentId,
  updateContentInDb,
  setIsContentBeingSaved,
}: ModelLinkType) {
  const { t } = useTranslation();

  const [IsModelAssignVisible, setIsModelAssignVisible] = useState(false);
  const [modalDataPending, setModalDataPending] = useState(true);

  const [selectedTableKey, setSelectedTableKeys] = useState<any[]>([]);
  const [tableDataList, setTableDataList] = useState<any>([]);

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const [singleBaselineData, setSingleBaselineData] = useState({
    id: "",
    name: "",
    version: "",
    type: "",
    qrcode: "",
    user: {
      avatar: "",
      first_name: "",
      last_name: "",
    },
  });

  useEffect(() => {
    const controller = abortControllerRef.current;
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (data?.resource?.resource_id) {
      fetchDocumentVersions(data.resource.resource_id, 1, 1, {}, sort).then(
        (res) => {
          res.data?.documentModelsVersions.map((item: any) => {
            return fetchDocumentsBaselineByID(item.id).then((res) => {
              setSingleBaselineData({
                name: res.data.name,
                id: res.data.id,
                version: res.data.version,
                type: res.data.type,
                qrcode: data.qrcode,
                user: {
                  avatar: res.data?.user?.avatar?.media_content_uid,
                  first_name: res.data?.user?.first_name,
                  last_name: res.data?.user?.last_name,
                },
              });
            });
          });
        }
      );
    }
    // eslint-disable-next-line
  }, [data]);

  const showAssignModal = () => {
    setIsModelAssignVisible(true);
  };

  const handleCancel = () => {
    setIsModelAssignVisible(false);
  };

  const sort = {
    id: "desc",
  };

  const handleSubmit = () => {
    setIsContentBeingSaved(true);
    if (selectedTableKey.length > 1) {
      message.error(t("doc.models.edit.sections.blocks.modelLinkError"));
      return;
    } else {
      let baseline = tableDataList.filter(
        (baseline: any) => baseline.id === selectedTableKey[0]
      )[0];

      const dataForUpdate: any = {
        link: baseline?.qrCode ? baseline.qrCode.dynamic_link : null,
        description: baseline.subtitle,
        name: baseline.name,
        qrcode: baseline?.qrCode ? baseline.qrCode.uuid : null,
        resource: {
          resource_type: "model",
          resource_id: baseline.document_model_id,
        },
      };

      fetchDocumentVersions(
        dataForUpdate.resource.resource_id,
        1,
        1,
        {},
        sort
      ).then((res) => {
        res.data?.documentModelsVersions.map((item: any) => {
          return fetchDocumentsBaselineByID(item.id).then((res) => {
            setSingleBaselineData({
              name: res.data.name,
              id: res.data.id,
              version: res.data.version,
              type: res.data.type,
              qrcode: dataForUpdate.qrcode,
              user: {
                avatar: res.data?.user?.avatar?.media_content_uid,
                first_name: res.data?.user?.first_name,
                last_name: res.data?.user?.last_name,
              },
            });
          });
        });
      });
      const dataToUpdate: any = {
        content: {
          content: dataForUpdate,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsModelAssignVisible(false);
      setIsContentBeingSaved(false);
    }
  };
  const handleRemove = () => {
    setIsContentBeingSaved(true);
    setSingleBaselineData({
      name: "",
      id: "",
      version: "",
      type: "",
      qrcode: "",
      user: {
        avatar: "",
        first_name: "",
        last_name: "",
      },
    });
    const dataToUpdate: any = {
      content: {
        content: null,
      },
    };
    updateContentInDb(contentId, dataToUpdate);
    setSelectedTableKeys([]);
    setIsContentBeingSaved(false);
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F9FB",
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      {!disableEdit ? (
        <Button
          onClick={showAssignModal}
          style={{
            backgroundColor: "#030852",
            color: "white",
            marginLeft: "50px",
          }}
        >
          {t("doc.models.edit.sections.blocks.listOfReleasedModels")}
        </Button>
      ) : (
        <></>
      )}
      <>
        {singleBaselineData.id ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "white",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: "10px",
                width: "70%",
                marginTop: "8px",
                marginLeft: "50px",
                justifyContent: "space-between",
                minWidth: "566px",
              }}
            >
              {singleBaselineData.qrcode ? (
                <Popover
                  trigger="click"
                  content={
                    <QrCodeComponent
                      qrCode={{ qr_data: singleBaselineData.qrcode }}
                    />
                  }
                >
                  <Button icon={<AiOutlineQrcode size={24} />}></Button>
                </Popover>
              ) : (
                <p style={{ fontSize: "12px", color: "#C1C1C1" }}>
                  {t("qrCode.noQrAssignedSmall")}
                </p>
              )}
              <div>{singleBaselineData.name}</div>
              <div>{singleBaselineData.type}</div>
              <AvatarComponent
                src={singleBaselineData.user.avatar}
                firstName={singleBaselineData.user.first_name}
                lastName={singleBaselineData.user.last_name}
              />
              <div style={{ marginRight: "10%" }}>
                {t("doc.models.procedure.version")} {singleBaselineData.version}
              </div>
            </div>
            {!disableEdit ? (
              <div>
                <Button
                  type="link"
                  onClick={handleRemove}
                  block
                  icon={<AiOutlineMinusCircle size={24} />}
                  style={{
                    marginTop: "16px",
                    marginLeft: "10px",
                    color: "#030852",
                  }}
                ></Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div style={{ marginLeft: "50px", marginTop: "8px" }}>
            {t("doc.models.edit.sections.blocks.noModelLinked")}
          </div>
        )}
      </>
      {IsModelAssignVisible && (
        <InternalLinkModal
          IsModelAssignVisible={IsModelAssignVisible}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          tableDataList={tableDataList}
          selectedTableKey={selectedTableKey}
          setSelectedTableKeys={setSelectedTableKeys}
          modalDataPending={modalDataPending}
          setModalDataPending={setModalDataPending}
          setTableDataList={setTableDataList}
        />
      )}
    </div>
  );
}
