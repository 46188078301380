import { useQuery } from "@tanstack/react-query";
import Request, {
  SortFormatType,
  filterFormat,
  pageFormat,
  pageSizeFormat,
  sortFormat,
} from "../../../libs/requests";
import { DocumentBaselines } from "./types";
import { PaginationType } from "../pagination/types";

export const fetchDocumentBaselines = async (
  page: number,
  pageSize: number,
  filters = {},
  sort: SortFormatType = { published_at: "desc" },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signal?: AbortController["signal"] | undefined
) => {
  return Request()
    .get("/document-baselines/", {
      params: {
        ...filterFormat(filters),
        ...sortFormat(sort),
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
      },
    })
    .then((response) => {
      return {
        documentBaselines: response.data.data
          .documentBaselines as DocumentBaselines[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const fetchDocumentBaselinesTanStack = (
  page: number,
  pageSize: number,
  filters: any = {},
  sort: SortFormatType = { published_at: "desc" }
) => {
  const {
    status,
    error,
    data: documentBaselines,
  } = useQuery({
    queryKey: ["documentBaselines", page, pageSize, filters, sort],
    queryFn: () => fetchDocumentBaselines(page, pageSize, filters, sort),
  });
  return { status, error, documentBaselines };
};

export const fetchDocumentsBaselineByID = (id: string) => {
  return Request()
    .get(`/document-baselines/${id}`)
    .then((response) => response.data);
};

export const fetchDocumentsBaselineAsPdf = (
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signal?: AbortController["signal"] | undefined
) => {
  return Request()
    .get(`/document-baselines/pdf/${id}`, {
      responseType: "blob",
    })
    .then((response) => response);
};

export const fetchBaselineValidations = (
  page: number,
  pageSize: number,
  filters = {},
  sort = {}
) => {
  const sortParams = sortFormat(sort);
  const pageParams = pageFormat(page);
  const pageSizeParams = pageSizeFormat(pageSize);
  const filterParams = filterFormat(filters);

  return Request()
    .get("/document-baselines/validations", {
      params: {
        ...filterParams,
        ...sortParams,
        ...pageParams,
        ...pageSizeParams,
      },
    })
    .then((response) => response.data);
};

export const createDocumentBaselineValidation = (
  baselineId: string,
  validators: any[]
) => {
  return Request()
    .post("/document-baselines/validations/", {
      document_baseline_id: parseInt(baselineId),
      validators: validators,
    })
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data;
    });
};
