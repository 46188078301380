import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./LinkStyles.module.scss";
import { Button } from "@armtekfrance/armtek-react-design-system";

export default function ExecutionLink({
  executionThisWeek,
}: {
  executionThisWeek: number;
}) {
  const { t } = useTranslation();

  const displayedCount = executionThisWeek > 99 ? "99+" : executionThisWeek;

  return (
    <div className={styles.linkWrapper}>
      <Link to="/account/knowledge/results">
        <Button
          child={
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonStyle}>{displayedCount}</div>
              <span>{t("dashboard.executionThisWeek")}</span>
            </div>
          }
        />
      </Link>
    </div>
  );
}
