import {
  addOrUpdateAccessRightForAResource,
  deleteAccessRightForAResource,
} from "app/services/accessRights";
import { useEffect, useState } from "react";
import { AccessRight } from "app/services/accessRights/types";
import { fetchTeams } from "app/services/team/teamClient";
import { Row, Tooltip } from "antd";
import { DropDownNew } from "components/DropDown/DropDownNew";
import DropDownList from "components/DropDown/DropDownList";
import { useTranslation } from "react-i18next";
import styles from "./AccessRightDetails.module.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import UsersTeamsDropDown from "components/UsersTeamsDropDown/UsersTeamsDropDown";
import { DropdownButton } from "@armtekfrance/armtek-react-design-system";
import { ContentType } from "app/stores/content/type";

interface AccessRightDetailsData {
  currentDocument: ContentType;
  displayAccessRight: "internal" | "private";
  setDisplayAccessRight: (data: "internal" | "private") => any;
  accessRight: AccessRight[];
  setAccessRight: (data: AccessRight[]) => any;
}

export default function AccessRightDetails({
  currentDocument,
  displayAccessRight,
  setDisplayAccessRight,
  accessRight,
  setAccessRight,
}: AccessRightDetailsData) {
  const { t } = useTranslation();
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);
  const [popOverContent, setPopOverContent] = useState<string>("");

  const [selectedTeams, setSelectedTeams] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  useEffect(() => {
    try {
      if (accessRight.length > 0) {
        setDisplayAccessRight("private");
        let teamsFromResponse: number[] = [];
        let usersFromResponse: number[] = [];
        accessRight.forEach((item: any) => {
          if (item.target_type === "team") {
            teamsFromResponse.push(item.target_id);
          }
          if (item.target_type === "user") {
            usersFromResponse.push(item.target_id);
          }
          setSelectedTeams(teamsFromResponse);
          setSelectedUsers(usersFromResponse);
        });
      } else {
        setDisplayAccessRight("internal");
      }
      fetchTeams().then((teams: any) => {
        teams?.forEach((team: any) => {
          (teams.value = team.id), (teams.label = team.name);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }, [currentDocument, accessRight]);

  const onSelectItemFromDropdown = async (
    item: number,
    action: "select" | "deselect",
    type: "user" | "team"
  ) => {
    try {
      const dataToBeSent = {
        resource_type: "document_model",
        resource_id: currentDocument.id,
        target_type: type,
        target_id: item,
      };
      if (action === "select") {
        const accessRightData = {
          read_access: true,
          execute_access: true,
          edit_access: true,
          archive_access: true,
        };
        const responseData = await addOrUpdateAccessRightForAResource({
          ...dataToBeSent,
          ...accessRightData,
        });
        if (responseData.success) {
          if (type === "user") {
            setSelectedUsers([...selectedUsers, item]);
          } else if (type === "team") {
            setSelectedTeams([...selectedTeams, item]);
          }
          if (displayAccessRight === "internal" && accessRight.length === 0) {
            setPopOverContent(t("accessRights.documentIsPrivate"));
            setIsPopOverVisible(true);
          } else {
            // setPopOverContent("");
            setIsPopOverVisible(false);
          }
        }
      } else if (action === "deselect") {
        const responseData = await deleteAccessRightForAResource(dataToBeSent);
        if (responseData.success) {
          if (type === "user") {
            setSelectedUsers(selectedUsers.filter((user) => user !== item));
          } else if (type === "team") {
            setSelectedTeams(selectedTeams.filter((team) => team !== item));
          }
          if (displayAccessRight === "private" && accessRight.length === 1) {
            setPopOverContent(t("accessRights.documentIsInternal"));
            setIsPopOverVisible(true);
          } else {
            setIsPopOverVisible(false);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const checkChangeOfAccessRight = async (value: string) => {
    try {
      if (value === "internal" && accessRight.length > 0) {
        const cp_accessRight = [...accessRight];
        for await (const item of cp_accessRight) {
          const dataToBeSent = {
            resource_type: "document_model",
            resource_id: currentDocument.id,
            target_type: item.target_type,
            target_id: item.target_id,
          };
          const responseData = await deleteAccessRightForAResource(
            dataToBeSent
          );
          if (!responseData.success) {
            return;
          }
        }
        setSelectedUsers([]);
        setSelectedTeams([]);
        setIsPopOverVisible(false);
      } else if (value === "private" && accessRight.length <= 0) {
        setIsPopOverVisible(true);
        setPopOverContent(t("accessRights.cannotSetPrivate"));
      } else {
        setIsPopOverVisible(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div className={styles.accessWriteDropdownWrapper}>
        <DropDownNew
          placement="bottomLeft"
          dropdownContent={
            <DropDownList
              listItems={[
                { key: "internal", label: t("accessRights.internal") },
                { key: "private", label: t("accessRights.private") },
              ]}
              onClickItem={(item) => {
                if (item.key === "internal") setDisplayAccessRight("internal");
                if (item.key === "private") setDisplayAccessRight("private");
                checkChangeOfAccessRight(item.key);
              }}
            ></DropDownList>
          }
        >
          <DropdownButton name={t(`accessRights.${displayAccessRight}`)} />
        </DropDownNew>
      </div>

      <div>
        <UsersTeamsDropDown
          hasPopover={false}
          canSelectAll={false}
          dropDownStyle={{ width: "30vw", marginLeft: "20px" }}
          forceUserList={{
            selectedUserList: selectedUsers,
            setSelectedUserList: (userList: number[]) => {
              setSelectedUsers(userList);
            },
          }}
          onSelectedChange={(_items, itemsIds) => {
            if (itemsIds.length > selectedUsers.length) {
              onSelectItemFromDropdown(
                itemsIds[itemsIds.length - 1],
                "select",
                "user"
              );
            } else {
              // Find the deleted user
              let deletedUser: any = undefined;
              for (let i = 0; i < selectedUsers.length; i++) {
                if (
                  !itemsIds
                    .map((item) => {
                      return item;
                    })
                    .includes(selectedUsers[i])
                ) {
                  deletedUser = selectedUsers[i];
                }
              }
              if (deletedUser !== undefined) {
                onSelectItemFromDropdown(deletedUser, "deselect", "user");
              }
            }
          }}
        />
      </div>
      {isPopOverVisible ? (
        <div style={{ marginLeft: "auto", marginRight: "0" }}>
          <Tooltip
            title={`Information: ${popOverContent}`}
            open
            placement="topRight"
          >
            <InfoCircleOutlined
              style={{
                fontSize: "30px",
                color: "#030852",
              }}
            />
          </Tooltip>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
