import { useMutation, useQuery } from "@tanstack/react-query";
import Request, {
  SortFormatType,
  filterFormat,
  pageFormat,
  pageSizeFormat,
  queryClient,
  sortFormat,
} from "../../../libs/requests";
import { PaginationType } from "../pagination/types";

export const fetchBaselineValidationsTanStack = (
  page: number,
  pageSize: number,
  filters: {
    authors?: number[];
    validators?: number[];
    name?: { contains: string };
    status?: ("PENDING" | "VALIDATED" | "REFUSED")[];
    expirationStartDate?: string;
    expirationEndDate?: string;
    family?: "organisation";
    document_family?: "KNOWLEDGE" | "SYSTEM";
  },
  sort: SortFormatType = { published_at: "desc" }
) => {
  const {
    status,
    error,
    data: baselineValidations,
  } = useQuery({
    queryKey: ["baselineValidations", page, pageSize, filters, sort],
    queryFn: () => fetchBaselineValidations(page, pageSize, filters, sort),
  });
  return { status, error, baselineValidations };
};

// TODO Improve Typing of baselineValidations
const fetchBaselineValidations = async (
  page: number,
  pageSize: number,
  filters: {
    authors?: number[];
    validators?: number[];
    name?: { contains: string };
    status?: ("PENDING" | "VALIDATED" | "REFUSED")[];
    expirationStartDate?: string;
    expirationEndDate?: string;
    family?: "organisation";
    document_family?: "KNOWLEDGE" | "SYSTEM";
  } = {},
  sort: SortFormatType = { published_at: "desc" }
) => {
  return Request()
    .get("/document-baselines/validations", {
      params: {
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
        ...filterFormat(filters),
        ...sortFormat(sort),
      },
    })
    .then((response) => {
      // TODO any is not a good return type here
      return {
        baselineValidations: response.data.data.baselineValidations as any,
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const updateDocumentBaselineValidationStatusTanStack = () => {
  return useMutation({
    mutationFn: ({
      document_baseline_id,
      validationStatus,
      comment,
    }: {
      document_baseline_id: number;
      validationStatus: "VALIDATED" | "REFUSED";
      comment: string;
    }) => {
      return updateDocumentBaselineValidationStatus(
        document_baseline_id,
        validationStatus,
        comment
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["baselineValidations"] });
    },
  });
};

const updateDocumentBaselineValidationStatus = async (
  validation_id: string | number,
  validationStatus: "VALIDATED" | "REFUSED",
  comment: string
) => {
  return Request()
    .patch(`/document-baselines/validations/${validation_id}`, {
      validation_status: validationStatus,
      comment: comment,
    })
    .then((response) => response.data);
};
