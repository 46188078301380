import styles from "./MediaPickerMediasArticleHeader.module.scss";
import MediaPickerModalSearch from "./MediaPickerSearch";
import { useTranslation } from "react-i18next";
import Button from "components/TempDS/Button/Button";
import { GlobalStore, useStoreState } from "@armtekfrance/webgate/dist";

export default function MediaPickerMediasArticleHeader() {
  const { t } = useTranslation();
  useStoreState(GlobalStore.media.getFilters());

  return (
    <div className={styles.mediaPickerMediasArticleHeaderWrapper}>
      <div className={styles.firstRow}>
        <MediaPickerModalSearch />
        <Button
          filter
          onClick={() => {
            GlobalStore.media.setIsContentMedia();
          }}
          type={
            GlobalStore.media.getFilters().execution_media === "0"
              ? "primary"
              : "default"
          }
        >
          <>{t("mediaPicker.mediaSection.contentMedia")}</>
        </Button>
        <Button
          filter
          onClick={() => {
            GlobalStore.media.setIsExecutionMedia();
          }}
          type={
            GlobalStore.media.getFilters().execution_media === "1"
              ? "primary"
              : "default"
          }
        >
          <>{t("mediaPicker.mediaSection.executionMedia")}</>
        </Button>
      </div>
      {/* <div className={styles.secondRow}>
        <UsersTeamsDropDown
          onSelectedChange={() => {
            return;
          }}
        />
        <RangeDatePicker />
      </div> */}
      <div className={styles.thirdRow}>
        <p>
          {`${
            GlobalStore.media.getSelectedMedias().filter((media) => {
              return (
                media.type ===
                GlobalStore.media.getMediaPickerModalStatus().block_type
              );
            }).length
          } ${t("mediaPicker.filesSelected")}`}
        </p>
        {GlobalStore.media.getSelectedMedias().filter((media) => {
          return (
            media.type ===
            GlobalStore.media.getMediaPickerModalStatus().block_type
          );
        }).length > 0 && (
          <Button
            underline
            onClick={() => {
              GlobalStore.media.setSelectedMedias([
                ...GlobalStore.media.getSelectedMedias().filter((media) => {
                  return (
                    media.type !==
                    GlobalStore.media.getMediaPickerModalStatus().block_type
                  );
                }),
              ]);
            }}
          >
            <>{t("mediaPicker.clearAll")}</>
          </Button>
        )}
      </div>
    </div>
  );
}
