import { Skeleton } from "antd";
import TextContent from "../SectionsEdit/Blocks/TextContent";
import {
  deleteSingleContent,
  editContent,
  fetchContents,
} from "../../app/stores/content/contentClient";
import ChecklistContent from "../SectionsEdit/Blocks/ChecklistContent";
import InputTextContent from "../SectionsEdit/Blocks/InputTextContent";
import {
  BlockTypeConst_NEW,
  BlockType_NEW,
} from "../../app/stores/blocks/types";
import VigilanceContent from "../SectionsEdit/Blocks/VigilanceContent";
import BulletPoints from "../SectionsEdit/Blocks/BulletPoints";
import SignatureInput from "../SectionsEdit/Blocks/SignatureInput";
import SmsBlock from "../SectionsEdit/Blocks/SmsBlock";
import ExternalLink from "../SectionsEdit/Blocks/ExternalLink";
import ModelLink from "../SectionsEdit/Blocks/ModelLink";
import Quiz from "../SectionsEdit/Blocks/Quiz";
import TableBlock from "../SectionsEdit/Blocks/TableBlock";
import DataBlock from "../SectionsEdit/Blocks/DataBlock";
// import Attribute from "../SectionsEdit/Blocks/Attributes";
import SelectItem from "../SectionsEdit/Blocks/SelectItem";
import { useEffect, useState } from "react";
import { ContentType } from "app/stores/content/type";

interface ProcedureBlockData {
  contentData?: any;
  blockType: BlockType_NEW;
  readOnly?: boolean;
  blockId: any;
  setSectionList: (sectionsList: any) => void;
  sectionId: any;
  setIsContentBeingSaved: (data: boolean) => void;
  page?: "baselines" | "models";
}

export default function ProcedureBlocks({
  setIsContentBeingSaved,
  blockType,
  readOnly,
  blockId,
  setSectionList,
  sectionId,
  page = "models",
}: ProcedureBlockData) {
  const [finalContent, setFinalContent] = useState<ContentType[]>([]);

  useEffect(() => {
    getContentData();
  }, [blockId, sectionId]);

  const getContentData = async () => {
    let contentFilter;
    if (page === "baselines") {
      contentFilter = {
        block_baseline_id: blockId,
      };
    } else {
      contentFilter = {
        block_model_id: blockId,
      };
    }
    await fetchContents("0", { position: "asc" }, contentFilter).then(
      (resp) => {
        setFinalContent(resp.contents);
      }
    );
  };

  const updateContentInDb = async (contentId: any, data: any) => {
    await editContent(contentId, data);
  };

  const deleteContentInDb = (contentId: any) => {
    deleteSingleContent(contentId, blockId).then((response) => {
      if (response.success) {
        setSectionList((oldData: any[]) => {
          const dataSource = [...oldData];
          const indexOfSection = dataSource
            .map((object) => object.id)
            .indexOf(sectionId);
          const indexOfBlock = dataSource[indexOfSection].blocks
            .map((obj: any) => obj.id)
            .indexOf(blockId);
          let filteredContents = dataSource[indexOfSection].blocks[
            indexOfBlock
          ]["content"].filter((obj: any) => obj.id !== contentId);
          dataSource[indexOfSection].blocks[indexOfBlock]["content"] =
            filteredContents;
          return dataSource;
        });
      }
    });
  };

  return (
    <>
      {!finalContent ? (
        <div>
          <Skeleton active />
        </div>
      ) : (
        <>
          {finalContent?.map((eachContent: any) => (
            <div key={eachContent.id}>
              {eachContent && (
                <div>
                  {blockType === BlockTypeConst_NEW.text ? (
                    <TextContent
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      disableEdit={readOnly}
                      contentId={eachContent.id}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.warning ||
                    blockType === BlockTypeConst_NEW.info ? (
                    <VigilanceContent
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      disableEdit={readOnly}
                      contentId={eachContent.id}
                      vigilanceType={blockType}
                      deleteContentInDb={deleteContentInDb}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.input_text ? (
                    <InputTextContent
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      contentId={eachContent.id}
                      disableEdit={readOnly}
                      deleteContentInDb={deleteContentInDb}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.input_checkbox ||
                    blockType === BlockTypeConst_NEW.input_radio ? (
                    <ChecklistContent
                      checkListType={blockType}
                      readOnly={readOnly}
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      contentId={eachContent.id}
                      deleteContentInDb={deleteContentInDb}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.bullet_point ? (
                    <BulletPoints
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      readOnly={readOnly}
                      contentId={eachContent.id}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.signature ||
                    blockType === BlockTypeConst_NEW.input_image ||
                    blockType === BlockTypeConst_NEW.input_video ||
                    blockType === BlockTypeConst_NEW.input_audio ? (
                    <SignatureInput
                      data={eachContent.content_json.content}
                      contentId={eachContent.id}
                      blockType={blockType}
                      disableEdit={readOnly}
                      updateContentInDb={updateContentInDb}
                      deleteContentInDb={deleteContentInDb}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.sms ||
                    blockType === BlockTypeConst_NEW.phone ? (
                    <SmsBlock
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      disableEdit={readOnly}
                      contentId={eachContent.id}
                      blockType={blockType}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.external_link ? (
                    <ExternalLink
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      disableEdit={readOnly}
                      contentId={eachContent.id}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.internal_link ||
                    blockType ===
                      BlockTypeConst_NEW.ContentDisplayInternalLinkKnowledge ||
                    blockType ===
                      BlockTypeConst_NEW.ContentDisplayInternalLinkSystem ? (
                    <ModelLink
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      disableEdit={readOnly}
                      contentId={eachContent.id}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.table ? (
                    <TableBlock
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      disableEdit={readOnly}
                      contentId={eachContent.id}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : blockType === BlockTypeConst_NEW.quiz ? (
                    <Quiz
                      data={eachContent.content_json.content}
                      updateContentInDb={updateContentInDb}
                      deleteContentInDb={deleteContentInDb}
                      disableEdit={readOnly}
                      contentId={eachContent.id}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : // ) : [
                  //     BlockTypeConst_NEW.data,
                  //     BlockTypeConst_NEW.input_data,
                  //   ].includes(blockType) ? (
                  [
                      BlockTypeConst_NEW.data as BlockType_NEW,
                      BlockTypeConst_NEW.input_data as BlockType_NEW,
                    ].includes(blockType as BlockType_NEW) ? (
                    <DataBlock
                      data={eachContent.content_json.content}
                      contentId={eachContent.id}
                      disableEdit={readOnly}
                      updateContentInDb={updateContentInDb}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : // ) : blockType === BlockTypeConst_NEW.attribute ? (
                  //   <Attribute
                  //     data={eachContent.content_json.content}
                  //     contentId={eachContent.id}
                  //     blockType={blockType}
                  //     disableEdit={readOnly}
                  //     updateContentInDb={updateContentInDb}
                  //     deleteContentInDb={deleteContentInDb}
                  //     setIsContentBeingSaved={setIsContentBeingSaved}
                  //   />
                  blockType === BlockTypeConst_NEW.select_item ? (
                    <SelectItem
                      data={eachContent.content_json.content}
                      contentId={eachContent.id}
                      blockType={blockType}
                      disableEdit={readOnly}
                      updateContentInDb={updateContentInDb}
                      deleteContentInDb={deleteContentInDb}
                      setIsContentBeingSaved={setIsContentBeingSaved}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
}
