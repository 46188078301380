import { useState, useEffect, useRef, useCallback } from "react";
import { Tree, Button, Divider, notification } from "antd";
import {
  deleteLinkageNode,
  editLinkageNode,
  fetchLinkageNodes,
} from "../../app/services/documentLinkage";
import DocLinkageLoader, { NodeLoader } from "./Loader";
import type { DataNode, TreeProps } from "antd/es/tree";
import MagicDocumentsModelListItem from "../../components/MagicListItem/MagicDocumentModelListItem";
import { useTranslation } from "react-i18next";
import { useDocumentModelsDispatch } from "components/DocumentModels/Providers/DocumentModelsContext";
import AddLinkageNodeRoot from "./AddLinkageNode/AddLinkageNodeRoot";
import styles from "./ModelLinkageLayout.module.scss";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";

export default function ModelLinkageLayout() {
  const { t } = useTranslation();

  const dispatchDocumentsModels = useDocumentModelsDispatch();
  dispatchDocumentsModels({ type: "setSlimView", slimView: true });
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line
  const [nodeParentId, setNodeParentId] = useState<number | null>(null);
  const [rootNodesLeft, setRootNodesLeft] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const bottomScroll = useRef<HTMLDivElement>(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [nodeIdToDelete, setNodeIdToDelete] = useState("");

  // Remove the provided node, using the key
  function removeNodeWithKey(tree: DataNode, keyToRemove: string): DataNode {
    if (!tree.children) return tree;
    if (tree.children.map((c) => c.key).includes(keyToRemove)) {
      return {
        ...tree,
        children: tree.children
          .filter((c) => c.key !== keyToRemove)
          .map((c) => ({ ...c, disabled: false })),
      };
    }
    return {
      ...tree,
      children: tree.children.map((c) => removeNodeWithKey(c, keyToRemove)),
    };
  }

  // Loading node
  function loadingNode(currentNode: DataNode) {
    return {
      title: <NodeLoader title={t("doc.models.linkage.deleteChildNode")} />,
      key: currentNode.key,
      children: [
        {
          title: <NodeLoader title={t("doc.models.linkage.deleteChildNode")} />,
          key: currentNode.key + "-add-child-1",
        },
      ],
    };
  }

  // Recursively replace nodes with loading nodes
  function applyLoadingTree(currentNode: DataNode): DataNode {
    if (!currentNode.children || !currentNode.children.length)
      return loadingNode(currentNode);

    return {
      ...loadingNode(currentNode),
      children: [...currentNode.children.map((ch) => applyLoadingTree(ch))],
    };
  }

  // Set's the node to loading state
  function setNodeLoading(tree: DataNode, key: string): DataNode {
    if (!tree.children) return tree;
    if (tree.key === key) {
      return {
        ...tree,
        title: <NodeLoader title={t("doc.models.linkage.deleteNode")} />,
        children: applyLoadingTree(tree).children,
        disabled: false,
      };
    }
    if (tree.children.map((c) => c.key).includes(key)) {
      return {
        ...tree,
        children: tree.children.map((c) =>
          c.key === key
            ? {
                ...c,
                title: (
                  <NodeLoader title={t("doc.models.linkage.deleteNode")} />
                ),
                children: applyLoadingTree(c).children,
                disabled: false,
              }
            : { ...c, disabled: true }
        ),
      };
    }
    return {
      ...tree,
      children: tree.children.map((c) => setNodeLoading(c, key)),
    };
  }

  // Delete a node
  const deleteNode = async (key: string) => {
    setTreeData((prevTree) =>
      prevTree.map((rootNode) => setNodeLoading(rootNode, key))
    );

    try {
      const nodeId = +key.split("-")[1];
      const result = await deleteLinkageNode(nodeId);
      if (result.success) {
        notificationApi.success({
          message: t("doc.models.linkage.deleteDocumentNode"),
          description: t("doc.models.linkage.sucessDeleteDocumentNode"),
          placement: "topRight",
        });
        setTreeData((prevTree) =>
          prevTree
            .filter((rootNode) => rootNode.key !== key)
            .map((rootNode) => removeNodeWithKey(rootNode, key))
        );
      } else {
        notificationApi.error({
          message: t("doc.models.linkage.errorOccured"),
          description: t("doc.models.linkage.errorDeleteDocumentNode"),
          placement: "topRight",
        });
      }
    } catch (error) {
      notificationApi.error({
        message: t("doc.models.linkage.errorOccured"),
        description: t("doc.models.linkage.errorDeleteDocumentNode"),
        placement: "topRight",
      });
    }
  };

  // Build node tree
  const buildTreeData = useCallback(
    (parentId: number | null, list: any[]): DataNode[] => {
      if (!list.length)
        return [
          {
            title: <></>,
            key: "addTo-" + parentId,
            disabled: true,
            selectable: false,
          },
        ];

      return [
        ...list.map((node) => {
          const nodeKey = node.parentId + "-" + node.id;
          if (node.children.length || !parentId) {
            setExpandedKeys((prev) => [...prev, nodeKey]);
          }
          return {
            key: nodeKey,
            title: (
              <div className="magic-node">
                <MagicDocumentsModelListItem
                  documentModel={node.document_model}
                />
              </div>
            ),
            children: buildTreeData(node.id, node.children),
          };
        }),
        ...(parentId
          ? [
              {
                title: <></>,
                key: "addTo-" + parentId,
                disabled: true,
                selectable: false,
              },
            ]
          : []),
      ];
    },
    // eslint-disable-next-line
    []
  );

  // Load more root nodes
  const loadNodes = useCallback(
    async (newPage?: number) => {
      setLoading(true);
      try {
        const result = await fetchLinkageNodes(newPage ? newPage : page);
        if (result.success) {
          const { page: nodePage, pageSize, total } = result.data.pagination;
          setTreeData((origin) => [
            ...origin,
            ...buildTreeData(null, result.data.nodes),
          ]);
          setRootNodesLeft(total - pageSize * nodePage);
          setPage(nodePage + 1);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [page, buildTreeData]
  );

  // Load the root nodes
  useEffect(() => {
    loadNodes();
    // eslint-disable-next-line
  }, []);

  const loadMoreNodes = async () => {
    await loadNodes();
    bottomScroll.current?.scrollIntoView({ behavior: "smooth" });
  };

  // After new nodes are added
  const onDocumentNodesSaved = () => {
    notificationApi.success({
      message: `${t("doc.models.linkage.savedDocumentNode")}`,
      description: `${t("doc.models.linkage.allDocumentSuccess")}`,
      placement: "topRight",
    });
    setTreeData([]);
    setPage((page) => page - 1);
    setOpen(false);
    loadNodes(page - 1);
  };

  // On change node position
  const onDrop: TreeProps["onDrop"] = async (info) => {
    const dropKey = info.node.key + "";
    const dragKey = info.dragNode.key + "";
    const dragPos = info.dragNode.pos.split("-");
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };
    const data = [...treeData];

    // Find dragObject
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setTreeData(data);

    // Save changes to server
    const [dragParentId, dragId] = dragKey.split("-");
    const [dropParentId, dropId] = dropKey.split("-");

    const dragToFirst = dropParentId === "null" && dropId === dragParentId;
    const dragToSmallerPosition =
      !dragToFirst &&
      Number(dropPos[dropPos.length - 1]) < Number(dragPos[dragPos.length - 1]);

    // eslint-disable-next-line
    const calculatedPosition = dragToFirst
      ? 1
      : dragToSmallerPosition
      ? info.dropPosition + 1
      : info.dropPosition;

    if (dragParentId === dropParentId || dragToFirst) {
      try {
        await editLinkageNode(+dragId, {
          new_position: dragToFirst ? 1 : info.dropPosition,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className={`${styles.modelLinkageLayoutWrapper}`}>
        {contextHolder}
        {loading && treeData.length < 1 ? (
          <DocLinkageLoader />
        ) : (
          <div className={styles.targetToDisable}>
            {/* <div className={styles.hereToStopsTheClicks}></div> */}
            <Tree
              draggable={false}
              blockNode
              defaultExpandedKeys={expandedKeys}
              onDrop={onDrop}
              selectable={false}
              treeData={treeData}
              switcherIcon={
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100%",
                    minWidth: "100%",
                    height: "auto",
                    cursor: "default",
                  }}
                ></div>
              }
            />
          </div>
        )}
        {rootNodesLeft > 0 && treeData.length > 0 && (
          <Divider>
            <Button
              size="large"
              shape="round"
              type="primary"
              loading={loading}
              onClick={loadMoreNodes}
            >
              {loading
                ? `${t("doc.models.linkage.savedDocumentNode")}`
                : `${t("doc.models.linkage.loadMore")} ${rootNodesLeft} ${t(
                    "doc.models.linkage.nodeLeft"
                  )}`}
            </Button>
          </Divider>
        )}
        <AddLinkageNodeRoot
          open={open}
          setOpen={setOpen}
          onComplete={onDocumentNodesSaved}
          parentNodeId={nodeParentId}
        />
        <div style={{ float: "left", clear: "both" }} ref={bottomScroll}></div>
      </div>
      <ConfirmActionModal
        isVisible={isArchiveModalVisible}
        handleCancel={() => setIsArchiveModalVisible(false)}
        handleConfirm={() => {
          if (nodeIdToDelete.length) {
            deleteNode(nodeIdToDelete);
          }
          setNodeIdToDelete("");
          setIsArchiveModalVisible(false);
        }}
        confirmInsideText={t("doc.models.linkage.deleteAllNode")}
        confirmHeaderText={t("archived.titleDeleteVersion")}
      />
    </>
  );
}
