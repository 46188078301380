import { Skeleton, Space } from "antd";

export const loadingCols = (cols: any) => {
  return cols.map((column: any) => ({
    ...column,
    render: () => {
      if (column.key === "avatar") {
        return <Skeleton.Avatar active />;
      } else if (
        column.key === "active" ||
        column.key === "created_at" ||
        column.key === "permissions"
      ) {
        return <Skeleton.Button active size="small" />;
      } else if (column.key === "id") {
        return <Skeleton.Avatar active size="small" shape="square" />;
      } else if (column.key === "action") {
        return (
          <Space>
            <Skeleton.Avatar active size="small" shape="square" />
            <Skeleton.Avatar active size="small" shape="square" />
            <Skeleton.Avatar active size="small" shape="square" />
          </Space>
        );
      }
      return <Skeleton.Input active size="small" />;
    },
  }));
};

export const loadingRows = ((numRows: number) => {
  let numList = [];
  for (let i = 1; i <= numRows; i++) {
    numList.push(i);
  }
  return numList;
})(10);
