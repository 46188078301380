import { Menu } from "antd";
import FilterDropdownComponent from "components/FilterDropdownComponent";
import { t } from "i18next";
import {
  useAddLinkageNode,
  useAddLinkageNodeDispatch,
} from "../../Provider/AddLinkageNodeContext";

export default function AddLinkageNodeHeroTypeFilterWrapper({
  typeMenuItems,
}: {
  typeMenuItems: { key: string; label: string }[];
}) {
  const addLinkageNode = useAddLinkageNode();
  const dispatchAddLinkageNode = useAddLinkageNodeDispatch();

  const typeFilterMenu = (
    <Menu
      selectable
      onClick={(e) => {
        dispatchAddLinkageNode({
          type: "setPagination",
          pagination: {
            page: 1,
            pageSize: addLinkageNode.pagination.pageSize,
            total: addLinkageNode.pagination.total,
          },
        });
        dispatchAddLinkageNode({ type: "setTypeFilter", typeFilter: e.key });
      }}
      items={typeMenuItems}
    />
  );
  return (
    <FilterDropdownComponent
      menuList={typeFilterMenu}
      buttonName={
        typeMenuItems.find(
          (content) => content.key === addLinkageNode.typeFilter
        )?.label ?? t("doc.models.allType")
      }
      buttonStyle={{
        borderColor: "#D9D9D9",
        color: "#262626",
        borderRadius: "8px",
      }}
    />
  );
}
