import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import CsvPreviewRow from "./csvPreviewRow";
import CsvPreviewColumn from "./csvPreviewColumn";
import {
  InsertRowLeftOutlined,
  InsertRowAboveOutlined,
} from "@ant-design/icons";

interface CsvExecutionProps {
  csvData: any;
  isCsvModalVisible: boolean;
  handleCancel: () => void;
  downloadXLSXFile: (isExcelColumn: boolean) => void;
  downloadCSVFile: () => void;
  execId: number;
  executionData: any;
  onPdfDownload: () => void;
}

// Extract medias from a given field
// Returns Array<String>
// robust for old datas where csvData[field] = MediaId : String
// robust for new datas where csvData[field] = [MediaId1, ...] : Array<String>
export function mediasOfRequestedDatas(reqDatas: any): Array<string> {
  // if Requested Data is a string then try to parse it cause it might be an Array
  if (reqDatas && reqDatas === reqDatas.toString()) {
    try {
      return Array.from(JSON.parse(reqDatas));
    } catch (error) {
      // if the parsing fails then it isn't an array,
      // thus there is only 1 media being [reqDatas]
      return [reqDatas];
    }
  } else if (reqDatas === "") {
    try {
      return Array.from(JSON.parse(reqDatas));
    } catch (error) {
      // if the parsing fails then it isn't an array,
      // thus there is only 1 media being [reqDatas]
      return [reqDatas];
    }
  }
  // if Requested Data isn't a string then it is already an array of IDs
  return reqDatas;
}

const CsvExecution = ({
  csvData,
  isCsvModalVisible,
  handleCancel,
  downloadXLSXFile,
  downloadCSVFile,
  onPdfDownload,
}: CsvExecutionProps) => {
  const { t } = useTranslation();
  const fields = Object.keys(csvData);

  const [isCSVDownloadLoading, setIsCSVDownloadLoading] = useState(false);
  const [isXLSXDownloadLoading, setIsXLSXDownloadLoading] = useState(false);
  const [isPDFDownloadLoading] = useState(false);
  const [isPreviewColumn, setIsPreviewColumn] = useState(true);
  return (
    <div>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flexGrow: 1,
              }}
            >
              {`${t("doc.models.edit.tabPanel.preview")} "${
                csvData.baseline_name
              }"`}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                flexDirection: "row",
                height: "100%",
                marginRight: "20px",
              }}
            >
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  setIsCSVDownloadLoading(true);
                  downloadCSVFile();
                  setIsCSVDownloadLoading(false);
                }}
                style={{
                  borderRadius: "8px",
                  margin: "5px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                loading={isCSVDownloadLoading}
              >
                {t("operate.downloadCsv")}
              </Button>
              <Button
                size="large"
                onClick={() => {
                  setIsXLSXDownloadLoading(true);
                  downloadXLSXFile(isPreviewColumn);
                  setIsXLSXDownloadLoading(false);
                }}
                style={{
                  borderRadius: "8px",
                  margin: "5px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                loading={isXLSXDownloadLoading}
              >
                {t("operate.downloadXlsx")}
              </Button>
              <Button
                size="large"
                onClick={() => {
                  onPdfDownload();
                }}
                style={{
                  borderRadius: "8px",
                  margin: "5px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                // disabled={
                //   executionData.executed_block &&
                //   executionData.executed_block.length === 0
                //     ? false
                //     : true
                // }
                loading={isPDFDownloadLoading}
              >
                {t("operate.downloadPdf")}
              </Button>
              <Button
                size="large"
                onClick={() => setIsPreviewColumn(!isPreviewColumn)}
                style={{
                  borderRadius: "8px",
                  margin: "5px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    marginRight: "5px",
                  }}
                >
                  {isPreviewColumn ? (
                    <InsertRowLeftOutlined
                      style={{
                        fontSize: "25px",
                      }}
                      onClick={() => setIsPreviewColumn(!isPreviewColumn)}
                    />
                  ) : (
                    <InsertRowAboveOutlined
                      style={{
                        fontSize: "25px",
                      }}
                      onClick={() => setIsPreviewColumn(!isPreviewColumn)}
                    />
                  )}
                </div>
                <div>{t("operate.transpose")}</div>
              </Button>
            </div>
          </div>
        }
        open={isCsvModalVisible}
        onCancel={handleCancel}
        style={{
          top: 15,
        }}
        width={"95vw"}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("operate.cancel")}
          </Button>,
        ]}
      >
        {isPreviewColumn ? (
          <CsvPreviewColumn
            fields={fields}
            mediasOfRequestedDatas={mediasOfRequestedDatas}
            csvData={csvData}
          />
        ) : (
          <CsvPreviewRow
            fields={fields}
            mediasOfRequestedDatas={mediasOfRequestedDatas}
            csvData={csvData}
          />
        )}
      </Modal>
    </div>
  );
};

export default CsvExecution;
