import { useEffect, useState } from "react";
import styles from "./ReplyOnComment.module.scss";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "@armtekfrance/armtek-react-design-system";
import { getLoggedInUser } from "app/stores/auth/authClient";
import { MutationStatus } from "@tanstack/react-query";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";
import TextArea from "../../components/TextArea/TextArea";

export default function ReplyOnComment({
  onReply,
  apiStatus,
}: {
  onReply: (replyText: string) => void;
  apiStatus: MutationStatus;
}) {
  const { t } = useTranslation();
  const [inputOpened, setInputOpened] = useState<boolean>(false);
  const [answerCanBeSend, setAnswerCanBeSend] = useState<boolean>(false);
  const userDetails = getLoggedInUser();
  const [inputValueTextReply, setInputValueTextReply] = useState<string>("");
  const [waitingForApi, setWaitingForApi] = useState<boolean>(false);

  // Change waitingForApi to false when apiStatus is success or idle to clear the TextArea text
  useEffect(() => {
    if (apiStatus === "success") {
      setWaitingForApi(false);
    }
  }, [apiStatus]);

  // TODO put the comments back (when textArea will work)
  return (
    <div className={styles.replyOnCommentWrapper}>
      {inputOpened ? (
        <div className={styles.openComment}>
          <AvatarComponent
            size={22}
            src={userDetails?.avatar?.media_content_uid}
            firstName={userDetails.first_name}
            lastName={userDetails.last_name}
          />
          <div>
            <TextArea
              type="comment"
              placeholder={`${t("doc.models.edit.comment.addAReply")}..`}
              onChangeText={(text: string) => {
                setInputValueTextReply(text);
                setAnswerCanBeSend(text.length > 0);
              }}
              maxLength={250}
              rows={1}
              waitingForApi={waitingForApi}
            />
            <div className={styles.footerWithButtons}>
              <Button
                child={t("operate.cancel")}
                // dense
                // size="small"
                onClick={() => {
                  setInputOpened(false);
                }}
              />
              <Button
                child={t("doc.models.edit.comment.replyToComment")}
                // dense
                // size="small"
                type={ButtonType.primary}
                disabled={!answerCanBeSend}
                onClick={() => {
                  if (apiStatus === "idle" || apiStatus === "success") {
                    setWaitingForApi(true);
                  }
                  if (inputValueTextReply.trim() !== "")
                    onReply(inputValueTextReply);
                }}
                loading={apiStatus === "loading"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.replyButton}
          onClick={() => setInputOpened(!inputOpened)}
        >
          <p>{t("doc.models.edit.comment.replyToComment")}</p>
        </div>
      )}
    </div>
  );
}
