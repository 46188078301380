import { NotificationOutlined } from "@ant-design/icons";
import { Card } from "antd";
import NewsLayout from "pages/DashboardLayout/NewsLayout";
import styles from "./NewsCard.module.scss";
import { useTranslation } from "react-i18next";

export default function NewsCard() {
  const { t } = useTranslation();

  return (
    <Card className={styles.cardBody} style={{ width: "100%" }}>
      <div className={styles.iconBox}>
        <div className={styles.iconImageBox}>
          <NotificationOutlined style={{ fontSize: "25px", color: "white" }} />
        </div>
        <div style={{ paddingTop: "5px" }}>{t("dashboard.news")}</div>
      </div>
      <NewsLayout />
    </Card>
  );
}
