import { useQuery } from "@tanstack/react-query";
import Request, {
  sortFormat,
  pageFormat,
  pageSizeFormat,
  filterFormat,
} from "../../../libs/requests";
import { PaginationType } from "../pagination/types";

type DocumentRequest = {
  active: boolean;
  company_id: number;
  created_at: string;
  description: string;
  id: number;
  document_type: string;
  subject: string;
  updated_at: string;
  user: {
    avatar: string;
    first_name: string;
    last_name: string;
  };
  user_id: number;
};

export const fetchDocumentRequestsTanStack = (
  page: number,
  pageSize: number,
  filters = {},
  sort = { created_at: "desc" }
) => {
  const {
    status,
    error,
    data: documentRequests,
  } = useQuery({
    queryKey: ["documentRequests", page, pageSize, filters, sort],
    queryFn: () => fetchDocumentRequests(page, pageSize, filters, sort),
  });
  return { status, error, documentRequests };
};

const fetchDocumentRequests = async (
  page: number,
  pageSize: number,
  filters = {},
  sort = { created_at: "desc" }
) => {
  return Request()
    .get("/document-requests", {
      params: {
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
        ...filterFormat(filters),
        ...sortFormat(sort),
      },
    })
    .then((response) => {
      return {
        documentRequests: response.data.data.docRequests as DocumentRequest[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const fetchDocumentRequestByID = async (id: number | string) => {
  return Request()
    .get(`/document-requests/${id}`)
    .then((response) => response.data);
};

export const archiveDocumentRequest = async (id: number | string) => {
  return Request()
    .patch(`/document-requests`, {
      id: id,
      active: false,
    })
    .then((response) => response.data);
};
