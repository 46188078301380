import { Button } from "@armtekfrance/armtek-react-design-system";
import { exportQrcode } from "../../app/stores/qrCode/qrCodeClient";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";
import { QrCodeType } from "app/stores/qrCode/types";

export default function QrCodeComponent({ qrCode }: { qrCode: QrCodeType }) {
  const { t } = useTranslation();

  const downloadQRCode = async (uuid: any) => {
    exportQrcode(uuid).then((response) => {
      const pngUrl = window.URL.createObjectURL(response.data);
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  return (
    <>
      {qrCode ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "0.5rem",
            width: "max-content",
          }}
        >
          <QRCodeCanvas
            value={qrCode.qr_data}
            width={256}
            height={256}
            style={{
              aspectRatio: "1",
            }}
          />
          <Button
            onClick={() => downloadQRCode(qrCode.qr_code_uuid)}
            child={<>{t("qrCode.downloadQR")}</>}
          />
        </div>
      ) : (
        <p style={{ fontSize: "12px", color: "#C1C1C1" }}>
          {t("qrCode.noQrAssignedSmall")}
        </p>
      )}
    </>
  );
}
