import styles from "./HeaderMenu.module.scss";
import HeaderMenuActions from "./HeaderMenuActions";
import HeaderMenuActionsDropDown from "./HeaderMenuActionsDropDown";
import HeaderMenuTab from "./HeaderMenuTab/HeaderMenuTab";

import { useHeader } from "./Provider/HeaderContext";

export default function HeaderMenu() {
  const headerContext = useHeader();

  return (
    <div className={styles.headerMenuWrapper}>
      <div className={styles.stakingContext}>
        <header>
          <HeaderMenuActions />
        </header>
        <footer>
          <HeaderMenuTab />
        </footer>
      </div>
    </div>
  );
}
