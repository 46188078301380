import { useState, useEffect } from "react";
import { fetchBlobObject } from "../../app/stores/content/contentClient";
import { Image, message, Spin } from "antd";
import { useTranslation } from "react-i18next";

interface ImageProps {
  src: string;
  alt: string;
  thumbnail?: boolean;
  style?: any;
  enablePreview?: boolean;
  videoThumbnail?: boolean;
}

export default function ImageDisplayComponent({
  src,
  alt,
  thumbnail,
  style, // ...otherProps
  enablePreview = true,
  videoThumbnail = false,
}: ImageProps) {
  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      if (thumbnail) {
        fetchBlobObject(src, thumbnail, videoThumbnail)
          .then((resp) => {
            if (resp?.media != null) {
              setThumbnailImage(
                `data:${resp.media.ContentType};base64, ${resp.media.file}`
              );
            } else if (resp?.fullMedia != null) {
              setThumbnailImage(
                `data:${resp.fullMedia.ContentType};base64, ${resp.fullMedia.file}`
              );
            }
            setLoading(false);
          })
          .catch();
      } else {
        fetchBlobObject(src)
          .then((response) => {
            if (response.media != null) {
              setImage(
                `data:${response.media.ContentType};base64, ${response.media.file}`
              );
            } else if (response.fullMedia != null) {
              setImage(
                `data:${response.fullMedia.ContentType};base64, ${response.fullMedia.file}`
              );
            }
            setLoading(false);
          })
          .catch();
      }
    } catch (error) {
      message.error(t("avatarComponent.problemLoading"));
      setLoading(false);
    }
  }, [src, thumbnail]);

  // eslint-disable-next-line
  const onVisibleChange = (visible: any, prevVisible: any) => {
    fetchBlobObject(src).then((response) => {
      if (response.media != null) {
        setImage(
          `data:${response.media.ContentType};base64, ${response.media.file}`
        );
      } else if (response.fullMedia != null) {
        setImage(
          `data:${response.fullMedia.ContentType};base64, ${response.fullMedia.file}`
        );
      }
    });
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {thumbnail ? (
            <Image
              src={thumbnailImage}
              alt={alt}
              style={style}
              preview={enablePreview ? { onVisibleChange, src: image } : false}
            />
          ) : (
            <>
              <Image
                src={image}
                alt={alt}
                style={style}
                preview={enablePreview}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
