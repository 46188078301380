import { useQuery } from "@tanstack/react-query";
import Request, {
  sortFormat,
  pageFormat,
  pageSizeFormat,
  filterFormat,
  SortFormatType,
} from "../../../libs/requests";
import { PaginationType } from "../pagination/types";

export const fetchBaselineExecutions = async (
  page: number,
  pageSize: number,
  // TODO {} is not a good type here
  filters = {},
  sort: SortFormatType = { id: "desc" }
) => {
  return Request()
    .get("/document-baselines/executions", {
      params: {
        ...filterFormat(filters),
        ...sortFormat(sort),
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
      },
    })
    .then((response) => {
      // TODO any is not a good return type here
      return {
        baselineExecutions: response.data.data.baselineExecutions as any,
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const fetchBaselineExecutionsTanStack = (
  page: number,
  pageSize: number,
  // TODO {} is not a good type here
  filters = {},
  sort: SortFormatType = { id: "desc" }
) => {
  const {
    status,
    error,
    data: baselineExecutions,
  } = useQuery({
    queryKey: ["baselineExecutions", page, pageSize, filters, sort],
    queryFn: () => fetchBaselineExecutions(page, pageSize, filters, sort),
  });
  return { status, error, baselineExecutions };
};

export const fetchBaselineExecutionById = (id: number | string) => {
  return Request()
    .get(`/document-baselines/executions/${id}`)
    .then((response) => response.data);
};

export const fetchBaselineExecutionByIdAsPdf = (id: string) => {
  return Request()
    .get(`/document-baselines/executions/pdf/${id}`, {
      responseType: "blob",
    })
    .then((response) => response)
    .catch((error) => {
      return error.response.data;
    });
};

export const fetchBaselineExecutionByIdAsCSV = (
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signal?: AbortController["signal"] | undefined
) => {
  return Request()
    .get(`/document-baselines/executions/csv/${id}`)
    .then((response) => response)
    .catch((error) => {
      return error.response.data;
    });
};

export const getExecutionTree = (ids: any[]) => {
  const filters = {
    parent_execution_ids: ids,
  };
  const filterParams = filterFormat(filters);
  return Request()
    .get("/document-baselines/executions/context-tree", {
      params: {
        ...filterParams,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getExecutionTreeExactPath = (ids: any[]) => {
  const filters = {
    parent_execution_ids: ids,
  };
  const filterParams = filterFormat(filters);
  return Request()
    .get("/document-baselines/executions/context-tree/exact-path", {
      params: {
        ...filterParams,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getExecutionTreeCSV = (ids: any[]) => {
  const filters = {
    parent_execution_ids: ids,
  };
  const filterParams = filterFormat(filters);
  return Request()
    .get("/document-baselines/executions/context-tree/csv", {
      params: {
        ...filterParams,
      },
    })
    .then((response) => response)
    .catch((error) => {
      return error.response.data;
    });
};

export const getExecutionTreePdf = (ids: any[]) => {
  const filters = {
    parent_execution_ids: ids,
  };
  const filterParams = filterFormat(filters);
  return Request()
    .get("/document-baselines/executions/context-tree/pdf", {
      params: {
        ...filterParams,
      },
      responseType: "blob",
    })
    .then((response) => response)
    .catch((error) => {
      return error.response.data;
    });
};
