import {
  fetchCommentsTanstack,
  mutationReplyToAComment,
} from "app/stores/documentComments/docCommentsClient";
import CommentsList from "./CommentsList";
import FooterReplyComment from "./FooterReplyComment";
import HeaderCommentWithCount from "./HeaderCommentWithCount";
import styles from "./Comments.module.scss";
import { useEffect } from "react";
import { useComments, useCommentsDispatch } from "./Provider/CommentsContext";

export default function Comments({
  document_model_id,
  sectionIdHovered,
  sectionNameInfos,
}: {
  document_model_id: number;
  sectionIdHovered: (sectionId: number | null) => void;
  sectionNameInfos:
    | { sectionId: number; sectionName: string; sectionPosition: number }[]
    | null;
}) {
  const commentsContext = useComments();
  const dispatchComments = useCommentsDispatch();

  const { mutate: mutationComments, status: statusMutation } =
    mutationReplyToAComment();
  const {
    status: statusComments,
    error: errorComments,
    comments,
  } = fetchCommentsTanstack(
    1,
    1000,
    { document_model_id: document_model_id },
    {}
  );

  useEffect(() => {
    sectionIdHovered(commentsContext.sectionIdHovered);
  }, [commentsContext.sectionIdHovered]);

  useEffect(() => {
    if (statusComments === "success") {
      dispatchComments({
        type: "setCommentsTanstack",
        setCommentsTanstack: {
          comments: comments?.comments,
          paginationComments: comments?.pagination,
        },
      });
    }
    dispatchComments({
      type: "setCommentsTanstackApi",
      setCommentsTanstackApi: {
        statusComments,
        errorComments,
      },
    });
  }, [statusComments, errorComments, comments, dispatchComments]);

  return (
    <div className={styles.commentsWrapper}>
      <HeaderCommentWithCount
        apiStatus={statusMutation}
        numberOfComments={commentsContext.paginationComments?.total}
      />
      <CommentsList
        listOfComments={commentsContext.commentsWithReplies}
        apiStatus={statusMutation}
        onReply={(replyText, comment_id) => {
          mutationComments({
            content: replyText,
            document_model_id,
            parent_id: comment_id,
          });
        }}
        sectionNameInfos={sectionNameInfos}
      />
      <FooterReplyComment
        onPublish={(commentText) => {
          mutationComments({
            content: commentText,
            document_model_id,
          });
        }}
        apiStatus={statusMutation}
      />
    </div>
  );
}
