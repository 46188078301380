import styles from "./MagicVersionView.module.scss";

export default function MagicVersionView({
  version,
}: {
  version?: string | null;
}) {
  return (
    <div className={styles.magicVersionViewWrapper}>
      {version ? <p>{`v ${version}`}</p> : <span> </span>}
    </div>
  );
}
