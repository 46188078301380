import styles from "../../pages/Notifications/NotificationListItem.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLoggedInUser } from "app/stores/auth/authClient";
import AvatarComponent from "components/AvatarComponent/AvatarComponent";

export default function MyUserDropDown({
  onClickLogout,
}: {
  onClickLogout: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userDetails = getLoggedInUser();

  return (
    <div className={styles.notificationDropDownWrapper}>
      {/* // TODO Refacto styles */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
          padding: "1rem 1rem 1rem 1rem",
          height: "auto",
          position: "relative",
          zIndex: "5",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            cursor: "pointer",
            paddingBottom: "1rem",
            borderBottom: "1px #cccccc solid",
          }}
          onClick={() => navigate("/account/settings/myaccount")}
        >
          {userDetails && (
            <>
              <AvatarComponent
                src={userDetails?.avatar?.url}
                size={54}
                firstName={userDetails?.first_name}
                lastName={userDetails?.last_name}
                tooltipPlacement={"bottomRight"}
              />
            </>
          )}
          <p style={{ margin: 0, fontSize: "20px", fontWeight: "600" }}>
            {userDetails?.first_name} {userDetails?.last_name}
          </p>
        </div>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "600",
            cursor: "pointer",
            margin: 0,
          }}
          onClick={onClickLogout}
          data-cy="Header-Logout-Button"
        >
          {t("menu.logout")}
        </p>
      </div>
    </div>
  );
}
