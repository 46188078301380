import { PaginationType } from "app/stores/pagination/types";
import { DocumentType } from "app/stores/documents/types";
import { QueryStatus } from "@tanstack/react-query";

export type stateDocumentsModelsProvider = {
  status: QueryStatus;
  error: unknown;
  documentModels: (DocumentType & { isSelected: boolean })[];
  selectedItems: number[];
  slimView: boolean;
  nameTextFilter: string;
  typeFilter: string[];
  usersFilter: number[];
  pagination: PaginationType;
};

export type TypeDocumentsModelsContextReducerAction =
  | {
      type: "setDocumentModels";
      documentModels: (DocumentType & { isSelected: boolean })[];
    }
  | {
      type: "setStatus";
      status: "error" | "success" | "loading";
    }
  | {
      type: "setError";
      error: unknown;
    }
  | {
      type: "setPagination";
      pagination: PaginationType;
    }
  | {
      type: "setNameTextFilter";
      nameTextFilter: string;
    }
  | {
      // TODO
      // Improve typing of typeFilter
      type: "setTypeFilter";
      typeFilter: string[];
    }
  | {
      type: "toggleDocumentsSelected";
      documentId: number;
    }
  | {
      type: "setSlimView";
      slimView: boolean;
    }
  | {
      type: "toggleSlimView";
    }
  | {
      type: "setUsersFilter";
      usersFilter: number[];
    };

export function documentsModelsReducer(
  stateDocumentsModels: stateDocumentsModelsProvider,
  action: TypeDocumentsModelsContextReducerAction
): stateDocumentsModelsProvider {
  switch (action.type) {
    case "setPagination": {
      return { ...stateDocumentsModels, pagination: action.pagination };
    }
    case "setNameTextFilter": {
      return {
        ...stateDocumentsModels,
        nameTextFilter: action.nameTextFilter,
      };
    }
    case "setTypeFilter": {
      return { ...stateDocumentsModels, typeFilter: action.typeFilter };
    }
    case "setUsersFilter": {
      return { ...stateDocumentsModels, usersFilter: action.usersFilter };
    }
    case "setStatus": {
      return { ...stateDocumentsModels, status: action.status };
    }
    case "setError": {
      return { ...stateDocumentsModels, error: action.error };
    }
    case "setDocumentModels": {
      return {
        ...stateDocumentsModels,
        documentModels: action.documentModels.map((documentModel) => {
          return {
            ...documentModel,
            isSelected: stateDocumentsModels.selectedItems.includes(
              documentModel.id
            ),
          };
        }),
      };
    }
    case "toggleDocumentsSelected": {
      const localSelectedItem = stateDocumentsModels.selectedItems.includes(
        action.documentId
      )
        ? stateDocumentsModels.selectedItems.filter(
            (itemId) => itemId !== action.documentId
          )
        : [...stateDocumentsModels.selectedItems, action.documentId];

      const localUpdatedModels = stateDocumentsModels.documentModels.map(
        (documentModel) => {
          return localSelectedItem.includes(documentModel.id)
            ? { ...documentModel, isSelected: true }
            : { ...documentModel, isSelected: false };
        }
      );

      return {
        ...stateDocumentsModels,
        documentModels: localUpdatedModels,
        selectedItems: localSelectedItem,
      };
    }
    case "toggleSlimView": {
      return {
        ...stateDocumentsModels,
        slimView: !stateDocumentsModels.slimView,
      };
    }
    case "setSlimView": {
      return {
        ...stateDocumentsModels,
        slimView: action.slimView,
      };
    }
    default: {
      return { ...stateDocumentsModels };
    }
  }
}
