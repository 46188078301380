import {
  Button,
  Dropdown,
  Empty,
  MenuProps,
  Modal,
  Skeleton,
  Space,
  Spin,
  Tree,
  message,
} from "antd";
import {
  getExecutionTree,
  getExecutionTreeCSV,
  fetchBaselineExecutionById,
  getExecutionTreePdf,
  getExecutionTreeExactPath,
} from "app/stores/executionFlux/executionFluxClients";
import Papa from "papaparse";
import { Parser } from "json2csv";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TreeDisplayCard from "./TreeDisplayCard";
import TreeDisplayCardOrganisation from "./TreeDisplayCardOrganisation";
import * as XLSX from "xlsx";
import { DownOutlined } from "@ant-design/icons";
import WebDataRocksComponent from "../WebDataRocks/WebDataRocksComponent";
import { IDocumentBaselineExecution } from "app/stores/executionFlux/types";

interface ExecutionTreeModelProps {
  isModalVisible: boolean;
  handleCancel: () => void;
  parentExecutionId: number | string;
  selectedId?: number | string;
  showTable?: boolean;
  exactPath?: boolean;
}

const ExecutionTreeModel = ({
  isModalVisible,
  handleCancel,
  parentExecutionId,
  selectedId,
  showTable = false,
  exactPath = false,
}: ExecutionTreeModelProps) => {
  const { t } = useTranslation();

  const [loadingExecutionTreeModel, setLoadingExecutionTreeModel] =
    useState<boolean>(true);
  const [loadingTreeTable, setLoadingTreeTable] = useState<boolean>(true);
  const [treeData, setTreeData] = useState<any>("");
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);

  const [csvData, setCsvData] = useState<any>();
  // const [rawCsv, setRawCsv] = useState<any>();
  const [csvDataForFile, setCsvDataForFile] = useState<any>();
  // const [fields, setFields] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [executionIds, setExecutionIds] = useState([parentExecutionId]);
  const [selectedIdFormated, setSelectedIdFormated] = useState<
    number | undefined
  >(undefined);
  const [parentExecutionIdFormated, setParentExecutionIdFormated] =
    useState<number>(0);

  const csvFieldList = [
    "execution_id",
    "baseline_name",
    "baseline_version",
    "parent_execution_id",
    "operators",
    "date",
    "time_spend",
    "localisation",
  ];

  const translateObjectData = (
    obj: Record<string, any>
  ): Record<string, any> => {
    const translatedObject: Record<string, any> = {};

    Object.keys(obj).forEach((key) => {
      if (csvFieldList.includes(key)) {
        const translatedKey = t("csvdata." + key);
        translatedObject[translatedKey] = obj[key];
      } else {
        translatedObject[key] = obj[key];
      }
    });

    return translatedObject;
  };

  useEffect(() => {
    setSelectedIdFormated(
      selectedId
        ? typeof selectedId === "number"
          ? selectedId
          : parseInt(selectedId)
        : undefined
    );
  }, [selectedId]);

  useEffect(() => {
    setParentExecutionIdFormated(
      typeof parentExecutionId === "number"
        ? parentExecutionId
        : parseInt(parentExecutionId)
    );
  }, [parentExecutionId]);

  useEffect(() => {
    if (!parentExecutionIdFormated) return;
    setLoadingExecutionTreeModel(true);
    let idArray: any[] = [];

    let arrayOfIds: number[] = [];
    const getId = (data: any) => {
      for (let i = 0; i < data.length; i++) {
        arrayOfIds.push(data[i].id);
        if (data[i].children.length) {
          getId(data[i].children);
        } else {
          return arrayOfIds;
        }
      }
      return arrayOfIds;
    };
    try {
      if (exactPath) {
        idArray.push(selectedId);
        getExecutionTreeExactPath(idArray).then((response) => {
          if (response.success) {
            setTreeData(getTreeData(response.data.tree.executions));
            setExecutionIds(getId(response.data.tree.executions));
            setLoadingExecutionTreeModel(false);
          }
        });
      } else {
        idArray.push(parentExecutionId);
        getExecutionTree(idArray).then((response) => {
          if (response.success) {
            setTreeData(getTreeData(response.data.executions));
            setExecutionIds(getId(response.data.executions));
            setLoadingExecutionTreeModel(false);
          }
        });
      }
    } catch (error) {
      setLoadingExecutionTreeModel(false);
    }
  }, [parentExecutionIdFormated]);

  useEffect(() => {
    if (!parentExecutionIdFormated) return;
    let idArray: (string | number)[] = [];
    idArray.push(parentExecutionIdFormated);
    getExecutionTreeCSV(idArray).then((csvresponse) => {
      Papa.parse(csvresponse.data, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results: any) {
          setCsvData(results.data);
          // setFields(Object.keys(results.data[0]));
          const selectedData: any = [];
          if (selectedIdFormated) {
            selectedData.push(
              ...results.data.filter((each: any) => {
                return (
                  expandedKeys.includes(JSON.stringify(each.execution_id)) ||
                  expandedKeys.includes(parseInt(each.execution_id))
                );
              })
            );
          } else {
            selectedData.push(...results.data);
          }
          if (!selectedData.length) return;

          const translatedSelectedData = selectedData.map((elem: any) => {
            return translateObjectData(elem);
          });

          setTableData(translatedSelectedData);
          const parser = new Parser();
          const parsedCsvData = parser.parse(translatedSelectedData);
          setCsvDataForFile(parsedCsvData);
          setLoadingTreeTable(false);
        },
      });
    });
  }, [expandedKeys]);

  const downloadXLSXFile = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, csvData[0].baseline_name + "_execution.xlsx");
  };

  const downloadCSVFile = () => {
    const url = window.URL.createObjectURL(new Blob([csvDataForFile]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", csvData[0].baseline_name + "_execution.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const selectDisplayCardVersion = (data: IDocumentBaselineExecution) => {
    return data.document_type.includes("organisation") ? (
      <TreeDisplayCardOrganisation data={data} />
    ) : (
      <TreeDisplayCard data={data} />
    );
  };

  const getTreeData = (treeData: any[]) => {
    setExpandedKeys((prev) => [...prev, treeData[0].id]);
    return [
      {
        key: treeData[0].id,
        title: <div style={{}}>{selectDisplayCardVersion(treeData[0])}</div>,
        children: buildTree(treeData[0].children),
      },
    ];
  };

  const buildTree = (data: IDocumentBaselineExecution[]): any => {
    if (data?.length === 0) {
      return;
    } else {
      return [
        ...data.map((oneExec: any) => {
          const nodeKey = oneExec.id;
          setExpandedKeys((prev) => [...prev, nodeKey]);
          return {
            key: nodeKey,
            title: selectDisplayCardVersion(oneExec),
            children: buildTree(oneExec.children),
          };
        }),
      ];
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          style={{
            width: "100%",
          }}
          onClick={() => {
            downloadCSVFile();
          }}
        >
          {t("operate.downloadCsv")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            downloadXLSXFile();
          }}
        >
          {t("operate.downloadXlsx")}
        </div>
      ),
    },
  ];

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const generatePDFExecution = async () => {
    setIsPdfLoading(true);
    try {
      getExecutionTreePdf(executionIds).then((response) => {
        if (!response.data) {
          message.error(t("executionTable.ooopsPdf"));
          setIsPdfLoading(false);
        } else {
          fetchBaselineExecutionById(executionIds[0]).then(
            (responseBaseline) => {
              let fileURL = window.URL.createObjectURL(response.data);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = `${
                "Tree" +
                " " +
                responseBaseline.data.baseline_name.replace(/,/g, "") +
                " " +
                responseBaseline.data.baseline_version
              }.pdf`;
              alink.click();
              window.open(fileURL);
              setIsPdfLoading(false);
            }
          );
        }
      });
    } catch (error) {
      setIsPdfLoading(false);
      message.error(t("executionTable.ooopsPdf"));
    }
  };

  return (
    <div>
      <Modal
        title={t("executionTree.preview")}
        open={isModalVisible}
        onCancel={handleCancel}
        width={"95vw"}
        style={{ top: 15 }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("operate.cancel")}
          </Button>,
        ]}
      >
        <Spin size="large" spinning={isPdfLoading}>
          {loadingExecutionTreeModel ? (
            <Skeleton />
          ) : (
            <>
              <div>
                <Tree
                  showLine
                  draggable={false}
                  treeData={treeData}
                  defaultExpandedKeys={expandedKeys}
                  selectable={true}
                  style={{ marginBottom: "12px" }}
                />
              </div>

              {!loadingTreeTable && (
                <div>
                  <Dropdown menu={{ items }}>
                    <Button
                      style={{
                        marginRight: "8px",
                      }}
                      type="primary"
                    >
                      <Space>
                        {t("csvExecution.rawData")}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                  <Button type="primary" onClick={generatePDFExecution}>
                    {t("doc.models.edit.generalInformation.exportAsPDF")}
                  </Button>
                </div>
              )}
              {showTable && !loadingTreeTable && (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: "calc(90vh - 300px)",
                      overflowY: "scroll",
                      overflowX: "scroll",
                      whiteSpace: "nowrap",
                      marginTop: "8px",
                    }}
                  >
                    {csvDataForFile?.length > 0 ? (
                      <>
                        <WebDataRocksComponent
                          csvJSONData={tableData}
                          rawCsv={csvDataForFile}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Empty />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </Spin>
      </Modal>
    </div>
  );
};

export default ExecutionTreeModel;
