import { Switch, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { BlockTypeConst_NEW } from "../../../../app/stores/blocks/types";
import CheckboxLayout from "./CheckboxLayout";
import RadioButtonLayout from "./RadioButtonLayout";
import { useTranslation } from "react-i18next";
import Card from "antd/lib/card/Card";
import DefineAlertedUsers, {
  deleteTeamRelatedToUser,
} from "../DataBlock/DefineAlertedUsers";
import AvatarComponent from "../../../AvatarComponent/AvatarComponent";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./style.module.css";

interface CheckListType {
  data: ListType;
  updateContentInDb: (id: string | number, data: any) => void;
  checkListType: string;
  readOnly?: boolean;
  contentId: number | string;
  deleteContentInDb: (contentId: string | number) => void;
  setIsContentBeingSaved: (data: boolean) => void;
}

interface ListType {
  title: string;
  options: OptionType[];
  mandatory: boolean;
  alertedUsers: any[];
  selectedTeams: any[];
}

interface OptionType {
  key: number;
  value: string;
  sendAlert: boolean;
}

export default function ChecklistContent({
  data,
  updateContentInDb,
  checkListType,
  readOnly,
  contentId,
  setIsContentBeingSaved,
}: CheckListType) {
  const { t } = useTranslation();
  const [checklistData, setChecklistData] = useState<ListType>(
    data
      ? data
      : {
          title: "",
          options: [],
          mandatory: false,
          alertedUsers: [],
          selectedTeams: [],
        }
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checklistDataPending, setChecklistDataPending] = useState(false);

  useEffect(() => {
    setChecklistData(
      data
        ? data
        : {
            title: "",
            options: [],
            mandatory: false,
            alertedUsers: [],
            selectedTeams: [],
          }
    );
  }, [data]);

  const onChangeSendAlert = (index: number) => {
    let listCopy = { ...checklistData };
    listCopy.options[index].sendAlert = !listCopy.options[index].sendAlert;
    setChecklistData(listCopy);
    onDataChange();
  };

  const addChoice = () => {
    setChecklistDataPending(true);
    let listCopy = { ...checklistData };
    const dataToAdd = {
      key: checklistData.options ? checklistData.options.length + 1 : 1,
      value: "",
      sendAlert: false,
    };
    if (listCopy.options) {
      listCopy.options.push(dataToAdd);
    } else {
      listCopy["options"] = [];
      listCopy.options.push(dataToAdd);
    }
    setChecklistData(listCopy);
    setChecklistDataPending(false);
    onDataChange();
  };

  const removeChoice = (index: number) => {
    let listCopy = { ...checklistData };
    listCopy.options.splice(index, 1);
    const updatedKeys = listCopy.options.map((item, index) => {
      return { key: index + 1, value: item.value, sendAlert: item.sendAlert };
    });
    listCopy.options = updatedKeys;
    setChecklistData(listCopy);
    onDataChange();
  };

  let timeoutTitleId: any;
  const onChangeTitle = (e: any) => {
    setIsContentBeingSaved(true);
    let listCopy = { ...checklistData };
    if (listCopy.title) {
      listCopy.title = e.target.value;
    } else {
      listCopy["title"] = e.target.value;
    }
    clearTimeout(timeoutTitleId);
    setChecklistData(listCopy);
    timeoutTitleId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: listCopy,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  let timeoutOption: any;
  const onChangeOptionValue = (index: number, e: any) => {
    setIsContentBeingSaved(true);
    let listCopy = { ...checklistData };
    listCopy.options[index].value = e.target.value;
    setChecklistData(listCopy);
    clearTimeout(timeoutOption);
    timeoutOption = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: listCopy,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  let timeoutId: any;
  const onDataChange = () => {
    setIsContentBeingSaved(true);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: { content: checklistData },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const changeMandatoryField = (fieldData: any) => {
    const dataToUpdate = {
      content: { content: { ...checklistData, mandatory: fieldData } },
    };
    updateContentInDb(contentId, dataToUpdate);
    setChecklistData((oldData: any) => {
      return {
        ...oldData,
        mandatory: fieldData,
      };
    });
  };

  const onShowModal = () => {
    setIsModalOpen(true);
  };

  const onDeleteAlertedUser = async (userId: string) => {
    let reqDataCopy = await deleteTeamRelatedToUser(+userId, checklistData);
    reqDataCopy.alertedUsers = reqDataCopy.alertedUsers.filter(
      (user: any) => user.id !== userId
    );
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    updateContentInDb(contentId, dataToUpdate);
    setChecklistData(reqDataCopy);
  };

  return (
    <Card
      style={{
        backgroundColor: "#F6F9FB",
      }}
      bodyStyle={{ padding: "8px" }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div style={{ marginBottom: "8px", flexGrow: "1" }}>
          {checkListType === BlockTypeConst_NEW.input_radio ? (
            <Switch
              checkedChildren={t(
                "doc.models.edit.sections.blocks.mandatoryRadio"
              )}
              unCheckedChildren={t(
                "doc.models.edit.sections.blocks.notMandatoryRadio"
              )}
              checked={checklistData.mandatory}
              disabled={readOnly}
              onChange={changeMandatoryField}
            />
          ) : (
            <Switch
              checkedChildren={t(
                "doc.models.edit.sections.blocks.mandatoryCheck"
              )}
              unCheckedChildren={t(
                "doc.models.edit.sections.blocks.notMandatoryCheck"
              )}
              checked={checklistData.mandatory}
              disabled={readOnly}
              onChange={changeMandatoryField}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            style={{
              marginBottom: "8px",
            }}
            disabled={readOnly}
            onClick={onShowModal}
          >
            {t("doc.models.edit.sections.blocks.alertUsers")}
          </Button>
          {checklistData.alertedUsers &&
          checklistData.alertedUsers.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "200px",
              }}
            >
              {checklistData.alertedUsers?.map((user: any) => (
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    marginBottom: "8px",
                    marginRight: "8px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                    }}
                    className={styles.closeIcon}
                  >
                    <CloseOutlined
                      style={{
                        color: "white",
                      }}
                    ></CloseOutlined>
                  </div>
                  <div
                    className={styles.avatar}
                    style={{
                      // marginRight: "8px",
                      position: "relative",
                      bottom: "32px",
                      left: "0px",
                      marginTop: "0px",
                    }}
                    onClick={() => onDeleteAlertedUser(user.id)}
                  >
                    <AvatarComponent
                      src=""
                      key={user.id}
                      firstName={user.first_name}
                      lastName={user.last_name}
                      style={{
                        padding: "0px",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <TextArea
        placeholder={t("checklistContent.enterInstructions")}
        name="title"
        autoSize
        bordered={false}
        style={{ fontWeight: "bold", width: "50vw", paddingLeft: "4px" }}
        value={checklistData.title}
        onChange={onChangeTitle}
        disabled={readOnly}
      />
      {checklistDataPending ? (
        <div></div>
      ) : (
        <>
          {checkListType === BlockTypeConst_NEW.input_radio ? (
            <RadioButtonLayout
              checklistData={checklistData}
              removeChoice={removeChoice}
              readOnly={readOnly}
              data={checklistData}
              onChangeOptionValue={onChangeOptionValue}
              onChangeSendAlert={onChangeSendAlert}
            />
          ) : (
            <CheckboxLayout
              checklistData={checklistData}
              removeChoice={removeChoice}
              readOnly={readOnly}
              data={checklistData}
              onChangeOptionValue={onChangeOptionValue}
              onChangeSendAlert={onChangeSendAlert}
            />
          )}
        </>
      )}
      {!readOnly ? (
        <PlusCircleOutlined
          style={{
            marginLeft: "4px",
            cursor: "pointer",
            color: "#030852",
            fontSize: "24px",
          }}
          onClick={() => addChoice()}
        />
      ) : (
        <></>
      )}

      {isModalOpen && (
        <DefineAlertedUsers
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          contentId={contentId}
          inputData={checklistData}
          setInputData={setChecklistData}
          updateContentInDb={updateContentInDb}
        />
      )}
    </Card>
  );
}
