import MagicProvider from "components/MagicListItem/MagicProvider";
import styles from "./AddLinkageNodeList.module.scss";
import { useAddLinkageNode } from "./Provider/AddLinkageNodeContext";
import MagicDocumentModelListItem from "components/MagicListItem/MagicDocumentModelListItem";

export function AddLinkageNodeList() {
  const addLinkageNodeContext = useAddLinkageNode();

  return (
    <div className={styles.addLinkageNodeListWrapper}>
      {addLinkageNodeContext.documentModels.map((item, index) => (
        <MagicProvider key={index}>
          <MagicDocumentModelListItem
            documentModel={item}
          ></MagicDocumentModelListItem>
        </MagicProvider>
      ))}
    </div>
  );
}
