import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Select } from "antd";
import { MessageInstance } from "antd/lib/message";
import { fetchCompanyDepartments } from "app/services/company";
import {
  useAddTeamForm,
  useAddTeamFormDispatch,
} from "components/AddTeamForm/Provider/AddTeamFormContext";
import AddDeptModel from "pages/TeamLayout/AddDeptModal";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function AddTeamFormTextFieldsCompanyDepartments({
  messageApi,
}: {
  messageApi: MessageInstance;
}) {
  const { t } = useTranslation();

  const addTeamFormContext = useAddTeamForm();
  const dispatchAddTeamForm = useAddTeamFormDispatch();

  // Fetch company departments
  const fetchCompanyDept = async () => {
    try {
      dispatchAddTeamForm({
        type: "setDeptListLoading",
        deptListLoading: true,
      });
      const companyDepts = await fetchCompanyDepartments();
      if (companyDepts.success) {
        let companyDeptSelectList = companyDepts.data.map((dept: any) => {
          return { value: dept.id, label: dept.name };
        });
        dispatchAddTeamForm({
          type: "setCompanyDeptList",
          companyDeptList: companyDeptSelectList,
        });
        dispatchAddTeamForm({
          type: "setDeptListLoading",
          deptListLoading: false,
        });
      }
    } catch (err) {
      dispatchAddTeamForm({
        type: "setDeptListLoading",
        deptListLoading: false,
      });
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("team.teamsNotFetched"),
      });
    }
  };

  // Fetch departments on component loading
  useEffect(() => {
    fetchCompanyDept();
  }, []);

  const handleAddDeptCancel = () => {
    dispatchAddTeamForm({
      type: "setIsAddDeptOpen",
      isAddDeptOpen: false,
    });
  };

  const handleAddDeptSave = () => {
    dispatchAddTeamForm({
      type: "setIsAddDeptOpen",
      isAddDeptOpen: false,
    });
    fetchCompanyDept();
  };

  return (
    <>
      <Col span={24}>
        <Form.Item
          name="company_bu_id"
          label={t("team.companyDepartment")}
          rules={[
            {
              required: true,
              message: t("team.teamCompanyDepartmentInput"),
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            options={addTeamFormContext.companyDeptList}
            loading={addTeamFormContext.deptListLoading}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    dispatchAddTeamForm({
                      type: "setIsAddDeptOpen",
                      isAddDeptOpen: true,
                    })
                  }
                >
                  {t("departments.add")}
                </Button>
              </>
            )}
          />
        </Form.Item>
      </Col>
      <AddDeptModel
        isAddDeptOpen={addTeamFormContext.isAddDeptOpen}
        handleAddDeptCancel={handleAddDeptCancel}
        handleAddDeptSave={handleAddDeptSave}
      />
    </>
  );
}
