import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import Request, {
  SortFormatType,
  filterFormat,
  pageFormat,
  pageSizeFormat,
  queryClient,
  showToast,
  sortFormat,
} from "libs/requests";
import { ContentType, CreateContentType } from "./type";
import { MediaContent, MediaFilters } from "../../../Stores/media-models";
import { PaginationType } from "../pagination/types";
import { message } from "antd";

import i18n from "translations/i18n";
import type { AxiosRequestConfig } from "axios";
import { GlobalStore } from "@armtekfrance/webgate/dist";

export const fetchContents = async (
  pageSize = "0",
  sort: SortFormatType = { position: "asc" },
  filters = {},
  page?: number
) => {
  let reqParams = {
    ...filterFormat(filters),
    ...sortFormat(sort),
    ...pageSizeFormat(pageSize),
  };
  if (page) {
    reqParams = {
      ...reqParams,
      ...pageFormat(page),
    };
  }
  return Request()
    .get("/contents", {
      params: reqParams,
    })
    .then((response) => {
      return {
        contents: response.data.data.contents as ContentType[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const fetchContentsTanStack = (
  pageSize = "0",
  sort: SortFormatType = { position: "asc" },
  filters = {},
  page?: number
) => {
  const {
    status,
    error,
    data: contents,
  } = useQuery({
    queryKey: ["contents", pageSize, filters, sort, page],
    queryFn: () => fetchContents(pageSize, sort, filters, page),
  });
  return { status, error, contents };
};

const fetchMediaContent = async (
  page: number,
  pageSize: number,
  filters: MediaFilters = {},
  sort: SortFormatType = {
    updated_at: "asc",
  }
) => {
  // TODO "image" or "picture"
  const localFilter: any = filters;
  if (filters.type === "image") {
    localFilter.type = "picture";
  }
  return Request()
    .get("/contents/media", {
      params: {
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
        ...filterFormat(localFilter),
        ...sortFormat(sort),
      },
    })
    .then((response) => {
      return {
        // TODO It's not about making the right choice.
        // It's about making a choice and making it right.
        mediaContents: response.data.data.mediaContents.map((media: any) => {
          if (media.type === "picture") {
            return { ...media, type: "image" };
          }
          return media;
        }) as MediaContent[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export function fetchMediaContentTanStackInfinite(
  pageSize: number,
  filters: MediaFilters = {},
  sort: SortFormatType = {
    updated_at: "desc",
  }
) {
  const {
    data: mediaContents,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = 1 }) => {
      return fetchMediaContent(pageParam, pageSize, filters, sort);
    },
    queryKey: ["mediaContents", pageSize, filters, sort],
    getNextPageParam: (lastPage) => {
      if (
        pageSize * (lastPage.pagination.page + 1) <
        lastPage.pagination.total
      ) {
        return lastPage.pagination.page + 1;
      } else {
        return undefined;
      }
    },
  });
  return {
    mediaContents,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  };
}

// fetch a media from the content, company or user route
export const fetchBlobObject = async (
  mediaId: string,
  thumbnail?: boolean,
  videoThumbnail?: boolean,
  onProgressHandle?: AxiosRequestConfig<any>["onDownloadProgress"],
  size?: "midium" | "small" | "large"
) => {
  let reqUrl = `/contents/media/${mediaId}`;
  if (thumbnail) {
    reqUrl = reqUrl + "?type=thumbnail";
  }
  if (size) {
    reqUrl = reqUrl + "?size=" + size;
  }
  if (videoThumbnail) {
    reqUrl = reqUrl + "&quality=preview";
  }
  const requestInstance = Request();

  if (onProgressHandle !== undefined) {
    requestInstance.interceptors.request.use(
      (config) => {
        config.onDownloadProgress = onProgressHandle;
        return config;
      },
      (error) => {
        console.error(error);
        return Promise.reject(error);
      }
    );
  }

  return requestInstance
    .get(reqUrl)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

/**
 * @param mediaId id of the media to fetch
 * @param thumbnail boolean for thumbnail
 * @param staleTime stale time in ms (default: 1000ms)
 * @returns tanstack status, error and blob object
 */
export const fetchBlobObjectTanstack = (
  mediaId: string | null,
  thumbnail: boolean,
  videoThumbnail: boolean,
  staleTime?: number,
  onProgressHandle?: AxiosRequestConfig<any>["onDownloadProgress"]
) => {
  const {
    status,
    error,
    data: blobObject,
  } = useQuery({
    queryKey: [
      "blobObject",
      mediaId,
      thumbnail,
      videoThumbnail,
      onProgressHandle,
    ],
    queryFn: () =>
      fetchBlobObject(
        mediaId ?? "",
        thumbnail,
        videoThumbnail,
        onProgressHandle
      ),
    enabled: !!mediaId,
    staleTime: staleTime ?? 1000 * 1,
  });
  return { status, error, blobObject };
};

export const addContentToBlock = async (data: CreateContentType) => {
  const response = await Request().post("/contents", data);
  return response.data;
};

export const editContent = (contentId: number | string, data: any) => {
  return Request()
    .patch(`contents/${contentId}`, data)
    .then((response) => response.data)
    .catch((error: any) => {
      if (error?.response?.data?.message) {
        showToast("Error", error.response.data.message, "error");
      } else {
        showToast("Error", i18n.t("errorContentUpdate"), "error");
      }
    });
};

export const deleteSingleContent = async (
  contentId: string,
  blockId: string
) => {
  return Request()
    .delete(`contents/${contentId}/${blockId}`)
    .then((response) => {
      return response.data;
    });
};

const linkContentToBlock = async (
  block_model_id: number,
  content: {
    content: string;
    style: {};
  },
  contentFile: {
    media_content_id: number;
  },
  type: "image" | "video" | "audio" | "file"
) => {
  return Request()
    .post("contents", { block_model_id, content, contentFile, type })
    .then((response) => {
      return response.data;
    });
};

export const linkContentToBlockTanstack = () => {
  return useMutation({
    mutationFn: ({
      block_model_id,
      content,
      contentFile,
      type,
    }: {
      block_model_id: number;
      content: {
        content: string;
        style: {};
      };
      contentFile: {
        media_content_id: number;
      };
      type: "image" | "video" | "audio" | "file";
    }) => {
      return linkContentToBlock(block_model_id, content, contentFile, type);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contents"] });
      GlobalStore.media.setSelectedMedias([]);
    },
  });
};

export const deleteSingleContentTanstack = () => {
  return useMutation({
    mutationFn: ({
      contentId,
      blockId,
    }: {
      contentId: string;
      blockId: string;
    }) => {
      return deleteSingleContent(contentId, blockId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contents"] });
    },
  });
};

export const uploadMediaContentTanstack = () => {
  return useMutation({
    mutationFn: ({ uploadFileList }: { uploadFileList: File[] }) => {
      return uploadMediaContent(uploadFileList);
    },
    onError: (err) => {
      console.error(err);
      message.error(`${i18n.t("mediaPicker.uploadError")}`);
    },
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["mediaContents"] });
      GlobalStore.media.setMediaReadyToUpload([
        ...GlobalStore.media.getMediaReadyToUpload().filter((media) => {
          return (
            media.block_type ===
              GlobalStore.media.getMediaPickerModalStatus().block_type &&
            !media.selected
          );
        }),
        ...GlobalStore.media.getMediaReadyToUpload().filter((media) => {
          return (
            media.block_type !==
            GlobalStore.media.getMediaPickerModalStatus().block_type
          );
        }),
      ]);
      if (
        GlobalStore.media.getMediaReadyToUpload().filter((media) => {
          return (
            media.block_type ===
            GlobalStore.media.getMediaPickerModalStatus().block_type
          );
        }).length === 0
      ) {
        GlobalStore.media.setCurrentTab({ currentTab: "media" });
      }
      message.success(`${i18n.t("mediaPicker.uploadSuccess")}`);
    },
  });
};

const uploadMediaContent = async (uploadFileList: File[]) => {
  const formData = new FormData();
  uploadFileList.forEach((file) => {
    formData.append("file", file);
  });
  return Request()
    .post("/contents/media", formData)
    .then((response) => {
      return response.data.data as MediaContent[];
    })
    .catch((error) => {
      message.error(error);
    });
};

export const uploadAFile = async (formData: any) => {
  return Request()
    .post("/contents/media", formData)
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data;
    });
};
