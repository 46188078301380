import { useQuery } from "@tanstack/react-query";
import Request from "../../../libs/requests";
import { Team } from "./types";

export const createTeam = async (teamData: any) => {
  return Request()
    .post(`/teams`, teamData)
    .then((response) => response.data);
};

export const fetchTeams = async () => {
  return Request()
    .get(`/teams`)
    .then((response) => response.data.data.teams as Team[]);
};

export const fetchTeamsTanStack = () => {
  const {
    status,
    error,
    data: teams,
  } = useQuery({
    queryKey: ["teams"],
    queryFn: () => fetchTeams(),
  });
  return { status, error, teams };
};

export const fetchUserTeams = async (id: number) => {
  return Request()
    .get(`/teams/user/${id}`)
    .then((response) => {
      return response.data.data.teams as any[];
    });
};

export const fetchSingleTeam = async (id: number) => {
  return Request()
    .get(`/teams/${id}`)
    .then((response) => response.data);
};

export const updateTeam = async (id: number, teamData: any) => {
  return Request()
    .put(`/teams/${id}`, {
      ...teamData,
    })
    .then((response) => response.data);
};

export const addUsersToTeam = async (
  id: number,
  users: any,
  action = "ADD"
) => {
  return Request()
    .patch(`/teams/`, {
      team_id: id,
      users_id: users,
      action: action,
    })
    .then((response) => response.data);
};

export const deleteTeam = async (id: number) => {
  return Request()
    .delete(`/teams/${id}`)
    .then((response) => response.data);
};
