import { InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import ProcedurePreview from "../../../../ProcedurePreview";
import styles from "./MagicViewEye.module.scss";

export default function MagicViewEye({ documentId }: { documentId: number }) {
  const navigate = useNavigate();

  const [clickAnimation, setClickAnimation] = useState<boolean>(false);

  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const handlePreviewCancel = () => {
  //   setIsModalVisible(false);
  // };

  const goToView = () => {
    requestAnimationFrame(() =>
      setTimeout(() => {
        navigate(`/account/knowledge/models/info/${documentId}`);
      }, 300)
    );
  };

  return (
    <>
      <div
        className={styles.magicViewEyeWrapper}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <InfoCircleOutlined
          onClick={(e) => {
            setClickAnimation(!clickAnimation);
            goToView();
            e.nativeEvent.stopImmediatePropagation();
            return e.stopPropagation();
          }}
          className={`${clickAnimation && styles.eyeBlink}`}
        />
      </div>
    </>
  );
}
