import { PreviewModalExecution } from "@armtekfrance/webgate/dist";

interface ExecutionPreviewType {
  isModalVisible: boolean;
  handleCancel: () => void;
  documentId: number;
  page?: string;
  sectionId?: number;
}

export default function ExecutionPreviewV2({
  isModalVisible,
  handleCancel,
  documentId,
  sectionId,
}: ExecutionPreviewType) {
  return (
    <PreviewModalExecution
      open={isModalVisible}
      onClose={handleCancel}
      executionId={documentId}
      sectionId={sectionId}
    />
  );
}
