import ArmtekImageEditor from "components/ArmtekImageEditor/ArmtekImageEditor";
import Modal from "components/Modal/Modal";
import { queryClient } from "libs/requests";
import {
  GlobalStore,
  MediaAction,
  useStoreState,
} from "@armtekfrance/webgate/dist";
import { PreviewFullScreen } from "@armtekfrance/webgate/dist";
import { useEffect, useState } from "react";
import { Manager } from "@felixkletti/managementjs";

export default function MediaPreviewWithEditor() {
  useStoreState(GlobalStore.media.getImageEditorModalStatus());

  const [isMediaEditorOpen, setIsMediaEditorOpen] = useState(false);

  useEffect(() => {
    Manager.on(MediaAction.OPEN_MEDIA_EDITOR, (value, done) => {
      if (value) {
        setIsMediaEditorOpen(value.isOpen);
      }
    });
  }, [GlobalStore.media.getImageEditorModalStatus().contentId]);

  return (
    <>
      <PreviewFullScreen />
      <Modal
        isOpen={isMediaEditorOpen}
        onModalClose={() => {
          Manager.trigger(MediaAction.OPEN_MEDIA_EDITOR, {
            media: undefined,
            media_content_uid: "",
            contentId: "",
            isOpen: false,
          });

          GlobalStore.media.setImageEditorModalStatus({
            media: null,
            media_content_uid: "",
            contentId: "",
            isOpen: false,
          });
        }}
        autoSize={true}
      >
        <>
          {isMediaEditorOpen && (
            <ArmtekImageEditor
              media={{ ...GlobalStore.media.getImageEditorModalStatus().media }}
              media_content_uid={
                GlobalStore.media.getImageEditorModalStatus().media_content_uid
              }
              onSave={() => {
                queryClient.invalidateQueries({ queryKey: ["contents"] });

                Manager.trigger(MediaAction.OPEN_MEDIA_EDITOR, {
                  media: undefined,
                  media_content_uid: "",
                  contentId: "",
                  isOpen: false,
                });

                GlobalStore.media.setImageEditorModalStatus({
                  ...GlobalStore.media.getImageEditorModalStatus(),
                  isOpen: false,
                });
              }}
              contentId={
                GlobalStore.media.getImageEditorModalStatus().contentId
              }
              closeModal={() => {
                Manager.trigger(MediaAction.OPEN_MEDIA_EDITOR, {
                  media: undefined,
                  media_content_uid: "",
                  contentId: "",
                  isOpen: false,
                });

                GlobalStore.media.setImageEditorModalStatus({
                  ...GlobalStore.media.getImageEditorModalStatus(),
                  isOpen: false,
                });
              }}
            />
          )}
        </>
      </Modal>
    </>
  );
}
