import { Skeleton } from "antd";
import MagicEditForm from "./MagicActionsButtons/MagicEditForm";
// import MagicExtraDropdown from "./MagicActionsButtons/MagicExtraDropdown";
// import MagicFavoriteStar from "./MagicActionsButtons/MagicFavoriteStar";
// import MagicLock from "./MagicActionsButtons/MagicLock";
import MagicViewEye from "./MagicActionsButtons/MagicViewEye";
import styles from "./MagicListItemActionsAside.module.scss";
import CommentsRoot from "components/Comments/CommentsRoot";
import { useMagic } from "components/MagicListItem/MagicContext";
import { ApiDashboard, ApiDocumentModel } from "@armtekfrance/corenexus-pn-sn";
import { DeleteButton, SizeType } from "@armtekfrance/webgate/dist";

export default function MagicListItemActionsAside({
  documentId,
  lite,
  isLoading = false,
  sectionIdHovered,
  type = "models",
  isCurrentUserSuperAdmin = true,
  readOnly = false,
  onOpenModal,
}: {
  documentId: number | string;
  isLoading?: boolean;
  type?: "models" | "analysis";
  lite: boolean;
  sectionIdHovered: (sectionId: number | null) => void;
  readOnly?: boolean;
  isCurrentUserSuperAdmin?: boolean;
  onOpenModal?: (
    id: string | number,
    type: "openModal" | "deleteDashboard" | "changeActive" | "deleteModel"
  ) => void;
}) {
  const magic = useMagic();
  const magicSectionInfoComments = () => {
    return magic.documentDataSingle?.magic?.map((section: any) => {
      return {
        sectionId: section.section_model_id,
        sectionName: section.name,
        sectionPosition: section.position,
      };
    });
  };
  return (
    <aside
      data-cy="MagicListItemActionsAside"
      className={`${styles.magicListItemActionsAsideWrapper} ${
        lite && styles.magicListItemActionsAsideWrapperLiteVersion
      }`}
    >
      {!isLoading ? (
        <>
          <div className={styles.magicAsideActions}>
            {!readOnly && (
              <div>
                {isCurrentUserSuperAdmin && (
                  <MagicEditForm
                    type={type}
                    onOpenModal={onOpenModal}
                    documentId={documentId}
                  />
                )}
              </div>
            )}
            {type !== "analysis" ? (
              <>
                {!magic.documentData?.family.includes("SYSTEM") && (
                  <MagicViewEye documentId={documentId as number} />
                )}
                {isCurrentUserSuperAdmin && (
                  <DeleteButton
                    object={
                      {
                        object: "ApiDocumentModel",
                        id: documentId,
                      } as ApiDocumentModel
                    }
                    buttonSize={SizeType.large}
                    onDelete={() => {
                      onOpenModal && onOpenModal(documentId, "deleteModel");
                    }}
                  />
                )}
              </>
            ) : !readOnly ? (
              <div>
                {isCurrentUserSuperAdmin && (
                  <DeleteButton
                    object={
                      { object: "ApiDashboard", id: documentId } as ApiDashboard
                    }
                    buttonSize={SizeType.large}
                    onDelete={() => {
                      onOpenModal && onOpenModal(documentId, "deleteDashboard");
                    }}
                  />
                )}
              </div>
            ) : (
              <div></div>
            )}
            {/* <MagicFavoriteStar />
        <MagicLock />
      <MagicExtraDropdown documentId={documentId} /> */}
          </div>
          {/* {!lite && comment && <MagicAsideDetailsBody comment={comment} />} */}
          {type !== "analysis" ? (
            <div>
              {!lite && (
                <div
                  className={styles.commentsRootWrapper}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <CommentsRoot
                    document_model_id={documentId as number}
                    sectionIdHovered={sectionIdHovered}
                    sectionNameInfos={magicSectionInfoComments()}
                  />
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: "1rem 2rem",
              scale: "1 3",
              translate: "0 18px",
              width: "100%",
            }}
          >
            <Skeleton paragraph={false} active />
          </div>
        </div>
      )}
    </aside>
  );
}
