import { Button, Card, Col, Row, Image, Space } from "antd";
import {
  InfoCircleOutlined,
  PlusCircleFilled,
  WarningOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { BlockTypeConst_NEW } from "../../../../app/stores/blocks/types";
import { useEffect, useState } from "react";
import PictogramModal from "./PictogramModal";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

interface VigilanceContentType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => void;
  disableEdit?: boolean;
  contentId: string | number;
  vigilanceType: string;
  deleteContentInDb: (contentId: string | number) => void;
  setIsContentBeingSaved: (value: boolean) => void;
}

export default function VigilanceContent({
  data,
  updateContentInDb,
  disableEdit,
  contentId,
  vigilanceType,
  setIsContentBeingSaved,
}: VigilanceContentType) {
  const [vigilanceContent, setVigilanceContent] = useState(
    data
      ? data
      : {
          title: "",
          pictograms: [],
        }
  );
  const [showPictogramModal, setShowPictogramModal] = useState(false);

  useEffect(() => {
    setVigilanceContent(
      data
        ? data
        : {
            title: "",
            pictograms: [],
          }
    );
  }, [data]);

  const { t } = useTranslation();

  let cardHeaderStyle: any = {
    borderRadius: "5px 5px 0px 0px",
    // position: "relative",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  };

  let cardStyle: any = {
    border: "solid 0.3px",
    borderRadius: "5px 5px 0px 0px",
    minWidth: "221px",
    backgroundColor: "white",
    display: "inline-block",
  };

  switch (vigilanceType) {
    case BlockTypeConst_NEW.warning:
      cardHeaderStyle["backgroundColor"] = "#D90429AD";
      cardHeaderStyle["borderColor"] = "#D90429AD";
      cardStyle["borderColor"] = "#D90429AD";
      break;
    case BlockTypeConst_NEW.info:
      cardHeaderStyle["backgroundColor"] = "#030852CC";
      cardHeaderStyle["borderColor"] = "#030852CC";
      cardStyle["borderColor"] = "#030852CC";
      break;
    default:
      break;
  }

  let timeoutId: any;
  const onTextChange = (e: any) => {
    clearTimeout(timeoutId);
    setIsContentBeingSaved(true);
    setVigilanceContent({ ...vigilanceContent, title: e.target.value });
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: {
          content: { ...vigilanceContent, title: e.target.value },
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  const savePictogram = (pictogramList: string[]) => {
    const dataToUpdate = {
      content: {
        content: { ...vigilanceContent, pictograms: pictogramList },
      },
    };
    updateContentInDb(contentId, dataToUpdate);
    setVigilanceContent({ ...vigilanceContent, pictograms: pictogramList });
  };

  return (
    <Card
      style={{
        backgroundColor: "#F6F9FB",
        minWidth: "230px",
        display: "inline-block",
      }}
      bodyStyle={{ padding: "8px" }}
    >
      {/* {disableEdit ? (
        <></>
      ) : (
        <Row style={{ height: "20px" }} justify="space-around" align="middle">
          <Col span={23}></Col>
          <Col span={1}>
            <CloseCircleFilled
              style={{
                fontSize: "12px",
                cursor: "pointer",
                color: "#030852",
                float: "right",
              }}
              onClick={() => deleteContentInDb(contentId)}
            />
          </Col>
        </Row>
      )} */}

      <Card
        title={
          vigilanceType === BlockTypeConst_NEW.info ? (
            // <div style={{ margin: "auto", justifyContent: "center" }}>
            <InfoCircleOutlined
              style={{ fontSize: "35px", color: "#FFFFFF" }}
              twoToneColor="#096dd9"
            />
          ) : (
            // </div>
            // <div style={{ margin: "auto", justifyContent: "center" }}>
            <WarningOutlined style={{ fontSize: "35px", color: "#FFFFFF" }} />
            // </div>
          )
        }
        // bordered={false}
        headStyle={cardHeaderStyle}
        bodyStyle={{ padding: "12px" }}
        style={cardStyle}
      >
        <>
          <Space
            direction="vertical"
            style={{
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            {disableEdit ? (
              <></>
            ) : (
              <>
                <Button
                  type="link"
                  onClick={() => setShowPictogramModal(true)}
                  icon={<PlusCircleFilled color="#030852" />}
                  style={{ color: "#030852" }}
                >
                  {t("doc.models.edit.sections.blocks.addPictograms")}
                </Button>
                <PictogramModal
                  showPictogramModal={showPictogramModal}
                  setShowPictogramModal={setShowPictogramModal}
                  data={vigilanceContent}
                  savePictogram={savePictogram}
                ></PictogramModal>
              </>
            )}

            {vigilanceContent.pictograms ? (
              <Row gutter={[16, 16]} style={{ margin: "4px" }}>
                {vigilanceContent.pictograms?.map(
                  (file: string, description: string) => {
                    return (
                      <Col style={{ height: "60px" }}>
                        <SelectedPictogramPreview
                          file={file}
                          description={description}
                        />
                      </Col>
                    );
                  }
                )}
              </Row>
            ) : (
              <></>
            )}

            <TextArea
              placeholder={
                BlockTypeConst_NEW.info === vigilanceType
                  ? t("doc.models.edit.sections.blocks.infoDescription")
                  : t("doc.models.edit.sections.blocks.warningDescription")
              }
              bordered={false}
              autoSize
              value={vigilanceContent.title}
              onChange={onTextChange}
              disabled={disableEdit}
              style={{ color: "#030852" }}
            />
          </Space>
        </>
      </Card>
    </Card>
  );
}

export function SelectedPictogramPreview({ file }: any) {
  return (
    <div className={style.pictogramPreview}>
      <Image
        className="logo"
        src={`/pictos/${file}`}
        alt="Pictogram"
        style={{
          height: "56px",
          width: "56px",
        }}
      />
    </div>
  );
}
