import styles from "./ImagePreview.module.scss";
import { ReactComponent as Play } from "../TempDS/assets/PlayButton.svg";

import { useTranslation } from "react-i18next";
import { EyeOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export default function ImagePreview({
  src,
  alt,
  enablePreview = false,
  onOpenPreviewClick,
  typeOfMedia,
}: {
  src: string;
  alt: string;
  typeOfMedia?: "image" | "video";
  enablePreview?: boolean;
  onOpenPreviewClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.imagePreviewWrapper}>
      <img className={styles.image} src={src} alt={alt}></img>
      {enablePreview && (
        <div
          className={styles.previewText}
          onClick={onOpenPreviewClick}
          data-block-type={typeOfMedia}
        >
          {typeOfMedia === "image" && (
            <Tooltip
              title={
                <p style={{ margin: "0" }}>{t("mediaPicker.previewImage")}</p>
              }
            >
              <EyeOutlined style={{ scale: "3" }} />
            </Tooltip>
          )}
          {typeOfMedia === "video" && (
            <Tooltip
              title={
                <p style={{ margin: "0" }}>{t("mediaPicker.previewVideo")}</p>
              }
            >
              <Play />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
}
