import { useTranslation } from "react-i18next";
import styles from "./HeaderCommentWithCount.module.scss";
import { MutationStatus } from "@tanstack/react-query";

export default function HeaderCommentWithCount({
  numberOfComments,
  apiStatus,
}: {
  numberOfComments: number | undefined;
  apiStatus: MutationStatus;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.headerCommentWithCountWrapper}>
      <p>{t("doc.models.edit.comment.comments")}</p>
      {numberOfComments !== undefined && (
        <div
          className={`${
            numberOfComments > 10 ? styles.numberRect : styles.numberSquare
          } ${apiStatus === "loading" ? styles.loading : ""}`}
        >
          <p>{numberOfComments}</p>
        </div>
      )}
    </div>
  );
}
