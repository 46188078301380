import { message, Modal } from "antd";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AddTeamModal.module.scss";
import AddTeamFormRoot from "components/AddTeamForm/AddTeamFormRoot";

export default function AddTeamModal({
  isAddTeamOpen,
  handleAddTeamCancel,
  handleAddTeamSave,
}: {
  isAddTeamOpen: boolean;
  handleAddTeamCancel: () => void;
  handleAddTeamSave: () => void;
}) {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const createFormRef = useRef<any>(null);

  return (
    <div>
      {contextHolder}
      <Modal
        className={styles.ModalWrapper}
        open={isAddTeamOpen}
        onCancel={handleAddTeamCancel}
        title={t("team.addTeam")}
        footer={null}
      >
        <AddTeamFormRoot
          activeId={null}
          createFormRef={createFormRef}
          onOpenClose={null}
          messageApi={messageApi}
          onCancel={handleAddTeamCancel}
          onSave={handleAddTeamSave}
          displayUserSelect={false}
        />
      </Modal>
    </div>
  );
}
