import DocumentModelsHeroCreateWrapper from "./DocumentsModelsHeroCreateWrapper";
import { documentTypeMenuItems } from "../documentsModelsTypesDropDown/EnumTypesContent";
import DocumentModelsSystemCreateWrapper from "./DocumentModelsSystemCreateWrapper";
import { ApiDocumentFamilyEnum } from "@armtekfrance/corenexus-pn-sn";

export default function DocumentModelsHeroCreate({
  documentFamily,
  dataCy = "DocumentModelsHeroCreate",
}: {
  documentFamily: ApiDocumentFamilyEnum;
  dataCy?: string;
}) {
  if (documentFamily === ApiDocumentFamilyEnum.KNOWLEDGE) {
    return (
      <DocumentModelsHeroCreateWrapper
        typeMenuItems={documentTypeMenuItems}
        dataCy={`${dataCy}_DocumentModelsHeroCreateWrapper`}
      />
    );
  }

  if (documentFamily === ApiDocumentFamilyEnum.SYSTEM) {
    return (
      <DocumentModelsSystemCreateWrapper
        dataCy={`${dataCy}_DocumentModelsSystemCreateWrapper`}
      />
    );
  }

  return <p data-cy={`${dataCy}_error`}>Error</p>;
}
