import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  fetchDocuments,
  fetchDocumentByID,
  updateManyDocuments,
  fetchDocumentVersions,
} from "./documentsClient";
import { DocumentState } from "./types";

const getInitialState = (): DocumentState => {
  return {
    current: {},
    documentList: {
      documentsModels: [],
      pagination: { page: 0, pageSize: 0, total: 0 },
    },
    listPending: false,
    listError: "",
    currentPending: false,
    currentError: "",
    listUpdatePending: false,
    versionList: {},
    versionListPending: false,
  };
};

const fetchDocumentsAsync = createAsyncThunk(
  "documents/fetch",
  async ({ page, pageSize, filters, sort }: any = {}) =>
    await fetchDocuments(page, pageSize, filters, sort)
);

export const fetchDocumentByIDAsync = createAsyncThunk(
  "document/id/fetch",
  async (id: string) => {
    return await fetchDocumentByID(id);
  }
);

export const updateManyDocumentsAsync = createAsyncThunk(
  "documents/updateMany",
  async ({ ids, activeFlag }: any) => {
    return await updateManyDocuments(ids, activeFlag);
  }
);

export const fetchDocumentVersionsAsync = createAsyncThunk(
  "documents/versions",
  async ({ id, page, pageSize, filter }: any) => {
    return await fetchDocumentVersions(id, page, pageSize, filter);
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentsAsync.pending, (state) => {
        state.listPending = true;
        state.listError = "";
      })
      .addCase(fetchDocumentsAsync.fulfilled, (state, action) => {
        state.listPending = false;
        state.documentList = action.payload;
      })
      .addCase(fetchDocumentsAsync.rejected, (state) => {
        state.listPending = false;
        state.listError = "Error!";
      })
      .addCase(fetchDocumentByIDAsync.pending, (state) => {
        state.currentPending = true;
        state.currentError = "";
        state.current = {};
      })
      .addCase(fetchDocumentByIDAsync.fulfilled, (state, action) => {
        state.currentPending = false;
        state.current = action.payload.data;
      })
      .addCase(fetchDocumentByIDAsync.rejected, (state, action) => {
        state.currentPending = false;
        state.currentError = action.error.message;
      })

      .addCase(fetchDocumentVersionsAsync.pending, (state) => {
        state.versionListPending = true;
        state.versionList = {};
      })
      .addCase(fetchDocumentVersionsAsync.fulfilled, (state, action) => {
        state.versionListPending = false;
        state.versionList = action.payload.data;
      })
      .addCase(fetchDocumentVersionsAsync.rejected, (state) => {
        state.versionListPending = false;
        state.versionList = {};
      });
  },
});

export const getCurrentDocument = (state: RootState) => state.documents.current;
export const getDocumentCurrentPending = (state: RootState) =>
  state.documents.currentPending;
export const getDocumentVersion = (state: RootState) =>
  state.documents.versionList;
export const getDocumentVersionPending = (state: RootState) =>
  state.documents.versionListPending;

export default documentsSlice.reducer;
