import { createContext, Dispatch, useContext } from "react";
import { MagicType, TypeMagicReducerAction } from "./MagicReducer";

export const MagicContext = createContext<MagicType>({
  id: -1,
  status: "normal",
  isOpen: false,
  isInError: false,
  isLoading: true,
  isSelected: false,
  documentData: {
    id: -1,
    company_id: -1,
    user_id: -1,
    document_model_uid: "",
    name: "",
    type: "procedure",
    active: true,
    published: true,
    template: true,
    image_media_id: -1,
    auto_image_set: false,
    created_at: "",
    updated_at: "",
    never_expire: false,
    expire_at: "",
    status: "",
    first_published_at: "",
    last_published_at: "",
    subtitle: "",
    about: "",
    family: "",
    doc_category_id: -1,
    last_published_document_baseline_id: -1,
    sh__doc_category: null,
    local_code: "",
    user: { id: -1, first_name: "", last_name: "", avatar: {} },
    media_content: {
      id: -1,
      media_content_uid: "",
      location: "",
      type: "",
      name: "",
      metadata: null,
      company_id: -1,
    },
    qr_code: null,
    _count: null,
    version: "-1.-1.-1",
    isSelected: false,
  },
});

export const MagicDispatchContext = createContext<
  Dispatch<TypeMagicReducerAction>
>(() => {
  return { type: "" };
});

export function useMagic() {
  return useContext(MagicContext);
}

export function useMagicDispatch() {
  return useContext(MagicDispatchContext);
}
