import NotificationBell from "./HeaderMenuAction/NotificationBell";
import SettingGear from "./HeaderMenuAction/SettingGear";
// import ChatWithSupport from "./HeaderMenuAction/ChatWithSupport";
import WarringAlert from "./HeaderMenuAction/WarningAlert";
import AvatarComponentOfLoggedUser from "./HeaderMenuAction/AvatarComponentOfLoggedUser";
import styles from "./HeaderMenuActions.module.scss";
import { useEffect, useRef } from "react";
import { useHeaderDispatch } from "./Provider/HeaderContext";

export default function HeaderMenuActions() {
  const divRef = useRef<HTMLDivElement>(null);
  const dispatchHeader = useHeaderDispatch();

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current !== null) {
        dispatchHeader({
          type: "setLeftMenuActions",
          setLeftMenuActions: divRef.current.getBoundingClientRect().left,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={divRef}
      className={styles.headerMenuActionsWrapper}
      data-cy={"HeaderMenuActions"}
    >
      <NotificationBell />
      <WarringAlert />
      {/* <ChatWithSupport /> */}
      <SettingGear />
      <AvatarComponentOfLoggedUser />
    </div>
  );
}
