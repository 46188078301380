import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Modal,
  Slider,
  message,
  Divider,
  Typography,
  Switch,
  Result,
  Button,
} from "antd";
import {
  AiOutlinePhone,
  AiOutlineMobile,
  AiOutlineUserAdd,
  AiOutlineEdit,
  AiOutlineUser,
} from "react-icons/ai";
import EditUserLoader from "./Loader";
import { timezones } from "./timezones";
import {
  createCompanyUser,
  editCompanyUser,
  fetchCompanyInfo,
  fetchSingleCompanyUser,
} from "../../../app/services/company";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import AddTeamModal from "./AddTeamModal";
import { fetchTeams } from "app/services/team/teamClient";

interface ViewUserProps {
  open: boolean;
  userId: number | null;
  viewOnly?: boolean;
  onComplete: () => void;
  setOpen: (isOpen: boolean) => void;
}

const defaultPermission = 1;
const defaultIsGuest = false;
const defaultIsActive = true;
const { Option } = Select;
const { Title, Paragraph, Text } = Typography;

/**
 * Randomly generate a password for guest users
 * @param length
 * @returns
 */
function passwordGen(length: number) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function CreateUser({
  open,
  userId,
  viewOnly = false,
  setOpen,
  onComplete,
}: ViewUserProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataLoading, setDataLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const formType = 0;
  const [dataLoadingError, setDataLoadingError] = useState(false);
  const [userIsGuest, setUserIsGuest] = useState(defaultIsGuest);
  const [permission, setPermission] = useState(defaultPermission);
  const [isAddTeamOpen, setIsAddTeamOpen] = useState<boolean>(false);
  const [teamListLoading, setTeamListLoading] = useState<boolean>(false);
  const [teamList, setTeamList] = useState<any>([]);

  const [companyNameRegex, setCompanyNameRegex] = useState<RegExp>();
  useEffect(() => {
    fetchCompanyInfo().then((companyResp) => {
      if (companyResp.success) {
        const re = new RegExp(`^((?!${companyResp.data.name}).)*$`, "gi");
        setCompanyNameRegex(re);
      }
    });
  }, []);

  const permissionList = [
    {
      name: t("userPermissionData.guest.name"),
      permission: 64,
      info: t("userPermissionData.guest.info"),
      actions: [t("userPermissionData.guest.action.0")],
    },
    {
      name: t("userPermissionData.operator.name"),
      permission: 96,
      info: t("userPermissionData.operator.info"),
      actions: [
        t("userPermissionData.guest.action.0"),
        t("userPermissionData.operator.action.0"),
        t("userPermissionData.operator.action.1"),
      ],
    },
    {
      name: t("userPermissionData.editor.name"),
      permission: 112,
      info: t("userPermissionData.editor.info"),
      actions: [
        t("userPermissionData.guest.action.0"),
        t("userPermissionData.operator.action.0"),
        t("userPermissionData.operator.action.1"),
        t("userPermissionData.editor.action.0"),
      ],
    },
    {
      name: t("userPermissionData.manager.name"),
      permission: 124,
      info: t("userPermissionData.manager.info"),
      actions: [
        t("userPermissionData.guest.action.0"),
        t("userPermissionData.operator.action.0"),
        t("userPermissionData.operator.action.1"),
        t("userPermissionData.editor.action.0"),
        t("userPermissionData.manager.action.0"),
      ],
    },
    {
      name: t("userPermissionData.admin.name"),
      permission: 126,
      info: t("userPermissionData.admin.info"),
      actions: [
        t("userPermissionData.guest.action.0"),
        t("userPermissionData.operator.action.0"),
        t("userPermissionData.operator.action.1"),
        t("userPermissionData.editor.action.0"),
        t("userPermissionData.manager.action.0"),
        t("userPermissionData.admin.action.0"),
      ],
    },
    {
      name: t("userPermissionData.superAdmin.name"),
      permission: 127,
      info: t("userPermissionData.superAdmin.info"),
      actions: [
        t("userPermissionData.guest.action.0"),
        t("userPermissionData.operator.action.0"),
        t("userPermissionData.operator.action.1"),
        t("userPermissionData.editor.action.0"),
        t("userPermissionData.manager.action.0"),
        t("userPermissionData.admin.action.0"),
        t("userPermissionData.superAdmin.action.0"),
      ],
    },
  ];

  //Fetch teams
  const fetchTeamList = async () => {
    setTeamListLoading(true);
    try {
      const teams = await fetchTeams();
      let teamSelectList = teams.map((team) => {
        return { id: team.id, name: team.name, _count: team._count };
      });
      setTeamList(teamSelectList);
      setTeamListLoading(false);
    } catch (error) {
      setTeamListLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("team.teamsNotFetched"),
      });
    }
  };

  const handleAddTeamCancel = () => {
    setIsAddTeamOpen(false);
  };

  const handleAddTeamSave = () => {
    setIsAddTeamOpen(false);
    fetchTeamList();
  };

  // Reset the form on drawer open
  useEffect(() => {
    if (open) {
      form.resetFields();
      (async () => {
        try {
          if (userId) {
            // Edit user form
            setDataLoading(true);
            const responses = await Promise.all([
              fetchSingleCompanyUser(userId),
            ]);
            if (responses[0].success) {
              const userData = responses[0].data;
              const permissionIndex = permissionList.findIndex(
                (perm) => perm.permission === userData.permissions
              );
              const userIsGuestValue =
                userData.permissions ===
                permissionList.find((perm) => perm.permission === 64)
                  ?.permission;
              form.setFieldsValue({
                ...userData,
                isGuest: userIsGuestValue,
                permissions: permissionIndex,
                local_time_zone:
                  userData?.account_configurations?.local_time_zone,
                teams_id: userData.teams || [],
              });
              setPermission(permissionIndex);
              setUserIsGuest(userIsGuestValue);
              fetchTeamList();
            }
          } else {
            // Create user form
            setDataLoading(true);
            await fetchTeamList();
            setUserIsGuest(defaultIsGuest);
            setPermission(defaultPermission);
          }
          setDataLoading(false);
          setDataLoadingError(false);
        } catch (error) {
          console.error(error);
          setDataLoading(false);
          setDataLoadingError(true);
        }
      })();
    }
    // eslint-disable-next-line
  }, [open, userId]);

  // On form submit
  const onFinish = async (values: any) => {
    const { active, isGuest, teams_id, ...otherValues } = values;
    const action = !userId ? "created" : "edited";
    type inputsType = typeof otherValues;
    const validValues: inputsType = Object.entries(otherValues).reduce(
      (acc, [key, value]) => {
        acc[key] = value ? value : "";
        return acc;
      },
      {} as inputsType
    );
    const dataToSubmit = {
      ...validValues,
      active,
      isGuest,
      teams_id,
      permissions: permissionList[values.permissions].permission,
    };
    // delete dataToSubmit.teams;
    let response;
    try {
      setFormLoading(true);
      if (!userId) {
        response = await createCompanyUser(dataToSubmit);
      } else {
        response = await editCompanyUser(userId, dataToSubmit);
      }
      if (response.success) {
        setOpen(false);
        onComplete();
        messageApi.open({
          type: "success",
          duration: 3,
          content:
            t("companyUserInfo.accountHasBeen") +
            " " +
            t(`departments.${action}`) +
            " " +
            t("departments.successfully"),
        });
      }
      setFormLoading(false);
    } catch (error) {
      console.error(error);
      setFormLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content:
          t("companyUserInfo.accountCouldNot") +
          " " +
          t(`departments.${action}`),
      });
    }
  };

  // On guest switch toggled
  const onGuestChange = (value: boolean) => {
    setUserIsGuest(value);
    if (value) setPermission(0);
    form.setFieldsValue({ permissions: 0 });
    if (value && !form.getFieldValue("password"))
      form.setFieldsValue({ password: passwordGen(12) });
  };

  // Dynamic modal Title

  const modalTitle = (
    <Title level={3}>
      {userId ? (
        viewOnly ? (
          <>
            <AiOutlineUser size={32} /> {t("companyUserInfo.infoUser")}: #
            {userId}
          </>
        ) : (
          <>
            <AiOutlineEdit size={32} /> {t("companyUserInfo.editUser")}: #
            {userId}
          </>
        )
      ) : (
        <>
          <AiOutlineUserAdd size={32} /> {t("companyUserInfo.createUser")}
        </>
      )}
    </Title>
  );

  return (
    <div>
      {contextHolder}
      <Modal
        style={{
          top: "0px",
          padding: "15px",
        }}
        width={720}
        open={open}
        closable={true}
        title={modalTitle}
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          loading: formLoading,
          disabled: (!!userId && !!dataLoadingError) || viewOnly,
        }}
        okText={formLoading ? t("button.submitting") : t("button.submit")}
        cancelText={t("button.cancel")}
      >
        {/* Todo: Develop invite a user by email once the Notification service is ready */}
        {/* {!userId && (
          <Segmented
            block
            size="large"
            value={formType}
            className="mb-5"
            onChange={(value) => setFormType(value)}
            options={[
              { label: "Add a user", value: 0 },
              { label: "Invite a user by email", value: 1 },
            ]}
          />
        )} */}
        {!formType ? (
          <div>
            {dataLoading ? (
              <EditUserLoader />
            ) : dataLoadingError ? (
              <Result
                status="error"
                title={t("companyUserInfo.errorLoadingUser")}
              />
            ) : (
              <Form
                name="userForm"
                form={form}
                layout="vertical"
                onFinish={onFinish}
                requiredMark
                disabled={viewOnly}
              >
                <Title level={4}>{t("companyUserInfo.personalInfo")}</Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="first_name"
                      label={t("companyTable.firstName")}
                      rules={[
                        {
                          required: true,
                          message: `${t("companyUserInfo.pleaseEnterA")}${t(
                            "companyTable.firstNameSmall"
                          )}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${t("companyUserInfo.enterUserA")}${t(
                          "companyTable.firstNameSmall"
                        )}`}
                        maxLength={250}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="middle_name"
                      label={t("companyTable.middleName")}
                    >
                      <Input
                        placeholder={`${t("companyUserInfo.enterUserA")}${t(
                          "companyTable.middleNameSmall"
                        )}`}
                        maxLength={250}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="last_name"
                      label={t("companyTable.lastName")}
                      rules={[
                        {
                          required: true,
                          message: `${t("companyUserInfo.pleaseEnterA")}${t(
                            "companyTable.lastNameSmall"
                          )}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${t("companyUserInfo.enterUserA")}${t(
                          "companyTable.lastNameSmall"
                        )}`}
                        maxLength={250}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="mobile"
                      label={t("companyUserInfo.mobileNumber")}
                      rules={[
                        {
                          pattern: /^\+?[0-9]+$/,
                          message: t("companyUserInfo.mobileIncludesNumber"),
                        },
                      ]}
                    >
                      <Input
                        addonBefore={<AiOutlineMobile size={20} />}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Title level={4} className="mb-0">
                  {t("companyUserInfo.authenticationInfo")}
                </Title>
                <Text>{t("companyUserInfo.pleaseCopyPaste")}</Text>
                <Row className="mt-3" gutter={16}>
                  <Col span={userId ? 12 : 24}>
                    <Form.Item
                      name="isGuest"
                      label={t("companyUserInfo.isGuestUser")}
                      valuePropName="checked"
                      initialValue={defaultIsGuest}
                    >
                      <Switch
                        checkedChildren={t("companyUserInfo.guestUser")}
                        unCheckedChildren={t("companyUserInfo.notGuestUser")}
                        onChange={onGuestChange}
                      />
                    </Form.Item>
                  </Col>
                  {userId && (
                    <Col span={12}>
                      <Form.Item
                        name="active"
                        label={t("companyUserInfo.isActiveUser")}
                        valuePropName="checked"
                        initialValue={defaultIsActive}
                      >
                        <Switch
                          checkedChildren={t("companyUserInfo.activeUser")}
                          unCheckedChildren={t("companyUserInfo.notActiveUser")}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label={t("companyTable.email")}
                      rules={[
                        {
                          required: !userIsGuest,
                          message: `${t("companyUserInfo.pleaseEnterA")}${t(
                            "companyTable.emailSmall"
                          )}`,
                        },
                        {
                          type: "email",
                          message: t("companyUserInfo.inputValidEmail"),
                        },
                      ]}
                    >
                      <Input
                        maxLength={250}
                        placeholder={`${t("companyUserInfo.enterUserA")}${t(
                          "companyTable.emailSmall"
                        )}`}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="password"
                      label={t("loginPage.passwordBig")}
                      rules={[
                        {
                          required: !userId ? true : false,
                          message: `${t("companyUserInfo.pleaseEnterA")}${t(
                            "loginPage.password"
                          )}`,
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&'.,;])[A-Za-z\d@$!%*?&'.,;]{8,}$/g,
                          message: t("companyUserInfo.passwordError"),
                        },
                        {
                          pattern: companyNameRegex,
                          message: t(
                            "companyUserInfo.passwordCompanyNameError"
                          ),
                        },
                      ]}
                    >
                      {userIsGuest ? (
                        <Input
                          maxLength={250}
                          placeholder={`${t("companyUserInfo.enterUserA")}${t(
                            "loginPage.password"
                          )}`}
                        />
                      ) : (
                        <Input.Password
                          maxLength={250}
                          placeholder={`${t("companyUserInfo.enterUserA")}${t(
                            "loginPage.password"
                          )}`}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {/* )} */}
                </Row>
                <Divider />
                <Title level={4}>{t("companyUserInfo.professionalInfo")}</Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="title"
                      label={t("companyUserInfo.jobTitle")}
                      rules={[
                        {
                          required: true,
                          message: `${t("companyUserInfo.pleaseEnterThe")}${t(
                            "companyUserInfo.jobTitleSmall"
                          )}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${t("companyUserInfo.enterUserThe")}${t(
                          "companyUserInfo.jobTitleSmall"
                        )}`}
                        maxLength={250}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      label={t("companyUserInfo.phoneNumber")}
                      rules={[
                        {
                          pattern: /^\+?[0-9]+$/,
                          message: t("companyUserInfo.phoneIncludesNumber"),
                        },
                      ]}
                    >
                      <Input
                        addonBefore={<AiOutlinePhone size={20} />}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="profile"
                      label={t("companyEdit.description")}
                    >
                      <Input.TextArea
                        rows={4}
                        placeholder={`${t("companyUserInfo.enterUserAFem")}${t(
                          "companyEdit.descriptionSmall"
                        )}`}
                        maxLength={250}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Title level={4}>{t("companyUserInfo.accountConfig")}</Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="local_time_zone"
                      label={`${t("companyUserInfo.localTimezone")}`}
                      initialValue="Europe/Paris"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={`${t(
                          "companyUserInfo.pleaseSelectTimeZone"
                        )}${t("companyUserInfo.localTimezone")}`}
                      >
                        {timezones.map((timezone) => (
                          <Option value={timezone}>{timezone}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="teams_id"
                      label={t("companyTable.team")}
                      rules={[
                        {
                          required: true,
                          message: `${t("companyUserInfo.pleaseSelectTeam")}${t(
                            "companyTable.teamSmall"
                          )}`,
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        loading={teamListLoading}
                        // options={teamList}
                        showSearch
                        optionFilterProp="children"
                        placeholder={`${t(
                          "companyUserInfo.pleaseSelectTeam"
                        )}${t("companyTable.teamSmall")}`}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={() => setIsAddTeamOpen(true)}
                            >
                              {t("team.addTeam")}
                            </Button>
                          </>
                        )}
                      >
                        {teamList.map((team: any) => (
                          <Option value={+team.id}>
                            <div className="d-flex justify-content-between">
                              <div>{team.name}</div>
                              <div className="d-flex align-items-center">
                                <AiOutlineUser size={16} />
                                {team._count.user}
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="mt-2">
                    <div className="ant-col ant-form-item-label">
                      <label
                        htmlFor="permissions"
                        className="ant-form-item-required"
                        title="User permissions"
                      >
                        {t("companyUserInfo.userPermission")}
                      </label>
                    </div>

                    <Row>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #ddd",
                            paddingTop: "1rem",
                            boxShadow: "2px 2px 8px #aaa",
                            borderRadius: 5,
                          }}
                        >
                          <Form.Item
                            name="permissions"
                            className="m-0"
                            initialValue={defaultPermission}
                          >
                            <Slider
                              min={0}
                              max={5}
                              vertical
                              onChange={(permissionIndex: number) =>
                                setPermission(permissionIndex)
                              }
                              disabled={userIsGuest || viewOnly}
                              style={{
                                display: "inline-block",
                                height: 300,
                              }}
                              marks={{
                                ...(permissionList.map(
                                  (perm) => perm.name
                                ) as any),
                              }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={18} className="p-4">
                        <Title level={4}>
                          {permissionList[permission].name}
                        </Title>
                        <Paragraph>
                          {permissionList[permission].info}
                          <br />
                          <br />
                          <Text strong>
                            {t("companyUserInfo.possibleActions")}
                          </Text>
                          <ul>
                            {permissionList[permission].actions.map(
                              (action, i) => (
                                <li key={i}>{action}</li>
                              )
                            )}
                          </ul>
                        </Paragraph>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        ) : (
          <div className="invite-user-segment"></div>
        )}
      </Modal>
      <AddTeamModal
        isAddTeamOpen={isAddTeamOpen}
        handleAddTeamCancel={handleAddTeamCancel}
        handleAddTeamSave={handleAddTeamSave}
      />
    </div>
  );
}
