import { Layout, LayoutType } from "@armtekfrance/webgate/dist";
import DocumentsModelsBody from "./DocumentsModelsBody";
import { ApiDocumentFamilyEnum } from "@armtekfrance/corenexus-pn-sn";
import DocumentsModelsFilters from "./DocumentsModelsFilters";

export const DocumentsModelsPage = ({
  documentFamily,
}: {
  documentFamily: ApiDocumentFamilyEnum;
}) => {
  return (
    <Layout
      layoutType={LayoutType.NO_SIDE}
      header={
        <DocumentsModelsFilters
          dataCy="DocumentsModelsPage_header"
          documentFamily={documentFamily}
        />
      }
      content={<DocumentsModelsBody />}
    />
  );
};
