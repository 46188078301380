import { useMutation, useQuery } from "@tanstack/react-query";
import Request, {
  SortFormatType,
  filterFormat,
  pageFormat,
  pageSizeFormat,
  queryClient,
  sortFormat,
} from "../../../libs/requests";
import { Comment } from "./types";
import { PaginationType } from "../pagination/types";

const fetchComments = async (
  page: number,
  pageSize: number,
  filters: { document_model_id?: number; parent_id?: number } = {},
  sort: SortFormatType = { updated_at: "desc" }
) => {
  return Request()
    .get("/document-comments/", {
      params: {
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
        ...filterFormat(filters),
        ...sortFormat(sort),
      },
    })
    .then((response) => {
      return {
        comments: response.data.data.comments as Comment[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const fetchCommentsTanstack = (
  page: number,
  pageSize: number,
  filters: { document_model_id?: number; parent_id?: number } = {},
  sort: SortFormatType = { updated_at: "desc" }
) => {
  const {
    status,
    error,
    data: comments,
  } = useQuery({
    queryKey: ["comments", page, pageSize, filters, sort],
    queryFn: () => fetchComments(page, pageSize, filters, sort),
  });
  return { status, error, comments };
};

export const mutationReplyToAComment = () => {
  return useMutation({
    mutationFn: ({
      content,
      document_model_id,
      parent_id,
    }: {
      content: string;
      document_model_id: number;
      parent_id?: number | undefined;
    }) => {
      return createDocumentComment(content, document_model_id, parent_id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["comments"],
      });
    },
  });
};

const createDocumentComment = async (
  content: string,
  document_model_id: number,
  parent_id?: number | undefined
) => {
  return Request()
    .post("/document-comments/", {
      content: content,
      ...(parent_id !== undefined && { parent_id }),
      document_model_id: document_model_id,
    })
    .then((response) => {
      return response.data.data.id as number;
    });
};
