import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./ConfirmActionModal.module.scss";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";

interface ConfirmActionModalProps {
  isVisible: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  confirmInsideText: string;
  confirmHeaderText: string;
}

export default function ConfirmActionModal({
  isVisible,
  handleCancel,
  handleConfirm,
  confirmInsideText,
  confirmHeaderText,
}: ConfirmActionModalProps) {
  const { t } = useTranslation();

  const [isEnabled, setIsEnabled] = useState(false);
  const [confirmText, setConfirmtext] = useState("");

  const handleChange = (event: any) => {
    const tempConfirmText: string = event.target.value;

    setConfirmtext(tempConfirmText);
    if (tempConfirmText.toLowerCase() === t("archived.confirmtext")) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  const onLeave = () => {
    handleCancel();
    setIsEnabled(false);
    setConfirmtext("");
  };

  return (
    <Modal
      title={confirmHeaderText}
      open={isVisible}
      onCancel={onLeave}
      footer={[
        <div className={styles.confirmActionModalFooter}>
          <Button type="primary" onClick={onLeave}>
            <p>{t("button.cancel")}</p>
          </Button>

          <Button
            onClick={() => {
              onLeave();
              handleConfirm();
            }}
            disabled={!isEnabled}
            data-cy="Models-ConfirmActionOKButton"
          >
            <p>OK</p>
          </Button>
        </div>,
      ]}
    >
      <h6>{confirmInsideText}</h6>
      <p>{t("archived.confirmArchiv")}</p>
      <TextArea
        value={confirmText}
        placeholder={t("archived.confirmArchiv")}
        onChange={handleChange}
        autoSize
        data-cy="Models-ConfirmActionTextInput"
      />
    </Modal>
  );
}
