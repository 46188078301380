import { useNavigate } from "react-router-dom";
import {
  ButtonType,
  IconButton,
} from "@armtekfrance/armtek-react-design-system";

export function ModelHeaderInfoButton({ id }: { id: string }) {
  const navigate = useNavigate();

  const navigateToInfoPage = () => {
    navigate(`/account/knowledge/models/info/${id}`);
  };

  return (
    <IconButton
      iconData="InfoCircleOutlined"
      type={ButtonType.underline}
      onClick={navigateToInfoPage}
    />
  );
}
