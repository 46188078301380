import { Button, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AiOutlineMinusCircle } from "react-icons/ai";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { AlertOutlined, AlertFilled } from "@ant-design/icons";

interface CheckboxType {
  removeChoice: (index: number) => void;
  readOnly?: boolean;
  data: any;
  onChangeOptionValue: (index: number, value: any) => void;
  onChangeSendAlert: (index: number) => void;
  checklistData: any;
}

export default function CheckboxLayout({
  removeChoice,
  readOnly,
  data,
  onChangeOptionValue,
  onChangeSendAlert,
  checklistData,
}: CheckboxType) {
  const { t } = useTranslation();
  return (
    <Checkbox.Group disabled={!readOnly} style={{ width: "100%" }}>
      <>
        {data.options ? (
          data.options.map((field: any, index: number) => (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
              className={styles.checkboxLine}
            >
              <div
                key={index}
                style={{
                  marginBottom: "8px",
                  marginLeft: "4px",
                  width: "95%",
                  backgroundColor: "white",
                  paddingLeft: "8px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                <Checkbox value={field.key}></Checkbox>
                <TextArea
                  placeholder={
                    t("doc.models.edit.sections.blocks.choice") +
                    " " +
                    (index + 1)
                  }
                  autoSize
                  bordered={false}
                  style={{ width: "92%", marginRight: "10px" }}
                  value={field.value}
                  onChange={(data) => onChangeOptionValue(index, data)}
                  disabled={readOnly}
                />
                <Button
                  type="link"
                  onClick={() => onChangeSendAlert(index)}
                  block
                  icon={
                    checklistData.options[index].sendAlert ? (
                      <AlertFilled
                        style={{
                          color: "red",
                        }}
                      />
                    ) : (
                      <AlertOutlined />
                    )
                  }
                  style={{
                    width: "21px",
                    height: "21px",
                    marginLeft: "2%",
                    color: "#030852",
                  }}
                ></Button>
              </div>
              {!readOnly && data.options.length > 1 ? (
                <div
                  style={{
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <Button
                    type="link"
                    onClick={() => removeChoice(index)}
                    block
                    icon={<AiOutlineMinusCircle />}
                    style={{
                      width: "21px",
                      height: "21px",
                      color: "#030852",
                    }}
                    className={styles.removeButton}
                  ></Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </>
    </Checkbox.Group>
  );
}
