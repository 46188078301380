import { QueryStatus } from "@tanstack/react-query";
import { Team } from "app/services/team/types";

export type stateTeamLayoutProvider = {
  statusTeams: QueryStatus;
  errorTeams: unknown;
  teams: Team[] | undefined;
  activeId: number | null;
  deleteLoading: boolean;
  newTeamCreated: boolean;
  isArchiveModalVisible: boolean;
  archiveTeamId: number | null;
};

export type TypeTeamLayoutContextReducerAction =
  | {
      type: "setTeamsTanstackApi";
      setTeamsTanstackApi: {
        statusTeams: stateTeamLayoutProvider["statusTeams"];
        errorTeams: stateTeamLayoutProvider["errorTeams"];
      };
    }
  | {
      type: "setTeamsTanstack";
      setTeamsTanstack: {
        teams: stateTeamLayoutProvider["teams"];
      };
    }
  | {
      type: "setActiveId";
      setActiveId: {
        activeId: stateTeamLayoutProvider["activeId"];
      };
    }
  | {
      type: "setDeleteLoading";
      setDeleteLoading: {
        deleteLoading: stateTeamLayoutProvider["deleteLoading"];
      };
    }
  | {
      type: "setNewTeamCreated";
      setNewTeamCreated: {
        newTeamCreated: stateTeamLayoutProvider["newTeamCreated"];
      };
    }
  | {
      type: "setIsArchiveModalVisible";
      setIsArchiveModalVisible: {
        isArchiveModalVisible: stateTeamLayoutProvider["isArchiveModalVisible"];
      };
    }
  | {
      type: "setArchiveTeamId";
      setArchiveTeamId: {
        archiveTeamId: stateTeamLayoutProvider["archiveTeamId"];
      };
    }
  | {
      type: "resetArchiveTeamId";
    };

export function teamLayoutReducer(
  stateTeamLayout: stateTeamLayoutProvider,
  action: TypeTeamLayoutContextReducerAction
): stateTeamLayoutProvider {
  switch (action.type) {
    case "setTeamsTanstackApi": {
      return {
        ...stateTeamLayout,
        statusTeams: action.setTeamsTanstackApi.statusTeams,
        errorTeams: action.setTeamsTanstackApi.errorTeams,
      };
    }
    case "setTeamsTanstack": {
      return {
        ...stateTeamLayout,
        teams: action.setTeamsTanstack.teams,
      };
    }
    case "setActiveId": {
      return {
        ...stateTeamLayout,
        activeId: action.setActiveId.activeId,
      };
    }
    case "setDeleteLoading": {
      return {
        ...stateTeamLayout,
        deleteLoading: action.setDeleteLoading.deleteLoading,
      };
    }
    case "setNewTeamCreated": {
      return {
        ...stateTeamLayout,
        newTeamCreated: action.setNewTeamCreated.newTeamCreated,
      };
    }
    case "setIsArchiveModalVisible": {
      return {
        ...stateTeamLayout,
        isArchiveModalVisible:
          action.setIsArchiveModalVisible.isArchiveModalVisible,
      };
    }
    case "setArchiveTeamId": {
      return {
        ...stateTeamLayout,
        archiveTeamId: action.setArchiveTeamId.archiveTeamId,
      };
    }
    case "resetArchiveTeamId": {
      return {
        ...stateTeamLayout,
        archiveTeamId: null,
      };
    }
    default: {
      return { ...stateTeamLayout };
    }
  }
}
