import { createContext, Dispatch, useContext } from "react";
import {
  stateDocumentsModelsProvider,
  TypeDocumentsModelsContextReducerAction,
} from "./DocumentModelsReducer";

export const DocumentsModelsContext =
  createContext<stateDocumentsModelsProvider>({
    status: "loading",
    slimView: false,
    error: null,
    documentModels: [],
    selectedItems: [],
    nameTextFilter: "",
    typeFilter: [],
    usersFilter: [],
    pagination: { page: 1, pageSize: 10, total: -1 },
  });

export const DocumentsModelsDispatchContext = createContext<
  Dispatch<TypeDocumentsModelsContextReducerAction>
>(() => {
  return { type: "" };
});

export function useDocumentModels() {
  return useContext(DocumentsModelsContext);
}

export function useDocumentModelsDispatch() {
  return useContext(DocumentsModelsDispatchContext);
}
