import { createContext, Dispatch, useContext } from "react";
import {
  stateExecutionFluxProvider,
  TypeExecutionFluxContextReducerAction,
} from "./ExecutionFluxReducer";

export const ExecutionFluxContext = createContext<stateExecutionFluxProvider>({
  statusDocumentsModels: "loading",
  errorDocumentsModels: null,
  documentsModels: [],
  paginationDocumentModels: { page: 1, pageSize: 10, total: -1 },

  statusBaselineExecutions: "loading",
  errorBaselineExecutions: null,
  baselineExecutions: [],
  paginationBaselineExecutions: { page: 1, pageSize: 10, total: -1 },

  queryNameTextFilter: "",
  queryDateSelectedFilter: { gte: "", lte: "" },
  queryModelSelectedFilter: [],
});

export const ExecutionFluxDispatchContext = createContext<
  Dispatch<TypeExecutionFluxContextReducerAction>
>(() => {
  return { type: "" };
});

export function useExecutionFlux() {
  return useContext(ExecutionFluxContext);
}

export function useExecutionFluxDispatch() {
  return useContext(ExecutionFluxDispatchContext);
}
