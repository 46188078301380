import { useEffect, useState } from "react";
import { BlockType_NEW } from "../../../../../../app/stores/blocks/types";
import MagicBlock from "./MagicBlocks/MagicBlock";
import styles from "./MagicSectionItem.module.scss";

export default function MagicSectionItem({
  position,
  name,
  blocks,
  commentHovered,
}: {
  position: number;
  name: string;
  blocks: { type: BlockType_NEW; inError: boolean }[];
  commentHovered: boolean;
}) {
  const [sectionInError, setSectionInError] = useState<boolean>(false);

  useEffect(() => {
    const sectionInError = blocks.length === 0;
    setSectionInError(sectionInError);
    // eslint-disable-next-line
  }, [blocks]);

  return (
    <div
      className={`${styles.magicSectionItemWrapper} ${
        sectionInError && styles.sectionInError
      }
      ${commentHovered && styles.commentHovered}
      `}
    >
      <div className={styles.stepInfos}>
        <h4>{position}</h4>
        <p>{name}</p>
      </div>
      <div className={styles.blocksDetails}>
        {blocks.map((block, blockIndex) => {
          return (
            <MagicBlock
              key={blockIndex}
              blockType={block.type}
              inError={block.inError}
            />
          );
        })}
      </div>
    </div>
  );
}
