import ListView from "../../../ListView";
import AvatarComponent from "../../../AvatarComponent/AvatarComponent";
import { useEffect, useState } from "react";
import { loadingCols } from "../../../ListView/util";

interface TableType {
  AllUsers: any[];
  loading: boolean;
  paginationDetails: any;
  onChangePagination: (page: number, pageSize: number) => void;
  setSelectedTableKeys: (selectedKeys: any[]) => void;
  selectedKeys?: any[];
}

export default function UsersTable({
  AllUsers,
  loading,
  paginationDetails,
  onChangePagination,
  setSelectedTableKeys,
  selectedKeys,
}: TableType) {
  const { page, pageSize, total } = paginationDetails;

  const [numRows, setNumRows] = useState<number>(10);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (id: number) => <span>{id}</span>,
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      render: (avatar: any, record: any) => (
        <AvatarComponent
          src={avatar?.media_content_uid || ""}
          firstName={record.first_name}
          lastName={record.last_name}
        />
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {first_name}
        </span>
      ),
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
      render: (middle_name: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {middle_name}
        </span>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      render: (last_name: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {last_name}
        </span>
      ),
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string) => (
        <span
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {title}
        </span>
      ),
    },
    // {
    //   title: "Department",
    //   dataIndex: "company_bu",
    //   key: "company_bu",
    //   render: (company_bu: { name: string } | null) => {
    //     const departmentName = company_bu ? company_bu.name : "no department";
    //     return (
    //       <Tooltip title={departmentName}>
    //         <div className="shadow-sm border p-2 rounded bg-white text-capitalize department-list-item">
    //           {departmentName}
    //         </div>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  // Skeleton loader small animation
  useEffect(() => {
    if (loading) {
      let increasing = 1;
      const intervalId = setInterval(() => {
        let increment = () => (Math.random() < 0.5 ? 1 : 2);
        setNumRows((prev) => {
          if (prev >= 10) {
            increasing = -increment();
          }
          if (prev <= 3) {
            increasing = +increment();
          }
          return prev + increasing;
        });
      }, 3000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [loading]);

  const loadingRows = ((numRows: number) => {
    let numList = [];
    for (let i = 1; i <= numRows; i++) {
      numList.push(i);
    }
    return numList;
  })(numRows);

  // Loading skeleton for the table
  const loadingColumns = loadingCols(columns);

  return (
    <ListView
      data={loading ? loadingRows : AllUsers}
      cardData={loading ? loadingColumns : columns}
      columns={columns}
      loading={false}
      loadingCardData={loading}
      isListView={true}
      page={page}
      pageSize={pageSize}
      total={total}
      cardLink={""}
      onChangePagination={onChangePagination}
      setSelectedTableKeys={setSelectedTableKeys}
      selectedKeys={selectedKeys}
      showCheckBox={true}
    />
  );
}
