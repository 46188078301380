import { useEffect } from "react";
import { fetchBlobObjectTanstack } from "../../app/stores/content/contentClient";
import { Image, Spin } from "antd";
import styles from "./ImageDisplayComponentPOC.module.scss";

// TODO useless now?
export default function ImageDisplayComponentPOC({
  src,
  alt,
  thumbnail = true,
  style,
  enablePreview = true,
  onSuccess,
  externalLoading = false,
  staleTime,
}: {
  src: string;
  alt: string;
  thumbnail?: boolean;
  style?: any;
  enablePreview?: boolean;
  onSuccess?: () => void;
  externalLoading?: boolean;
  staleTime?: number;
}) {
  const { status, blobObject } = fetchBlobObjectTanstack(
    src,
    true,
    false,
    staleTime
  );

  const { status: statusThumbnail, blobObject: blobObjectThumbnail } =
    fetchBlobObjectTanstack(src, thumbnail ?? true, false, staleTime);

  useEffect(() => {
    if (status === "success" && statusThumbnail === "success") {
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [status, statusThumbnail]);

  return (
    <div className={styles.imageDisplayComponentPOCWrapper}>
      {!externalLoading && (
        <>
          {status === "loading" && (
            <div className={styles.spinnerWrapper}>
              <Spin size="large" />
            </div>
          )}
          {status === "success" && (
            <>
              {thumbnail ? (
                <Image
                  src={`data:${blobObjectThumbnail?.media.ContentType};base64, ${blobObjectThumbnail?.media.file}`}
                  alt={alt}
                  style={style}
                  preview={
                    enablePreview
                      ? {
                          src: `data:${blobObject?.media.ContentType};base64, ${blobObject?.media.file}`,
                        }
                      : false
                  }
                />
              ) : (
                <>
                  <Image
                    src={`data:${blobObject?.media.ContentType};base64, ${blobObject?.media.file}`}
                    alt={alt}
                    style={style}
                    preview={enablePreview}
                  />
                </>
              )}
            </>
          )}
          {status === "error" && <p>Error</p>} {/* // TODO handle error case */}
        </>
      )}
    </div>
  );
}
