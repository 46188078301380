import { Button, Modal } from "antd";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import ValidatorsAvatar from "../ValidatorsAvatar";
import ValidationStatus from "./ValidationStatus";
import { useTranslation } from "react-i18next";
import ListViewNoPagination from "components/ListView/ListViewNoPagination";
import { useEffect, useState } from "react";
import { fetchUserInfoTanStack } from "app/stores/users/userClient";
import TextArea from "antd/lib/input/TextArea";
import ProcedurePreview from "components/ProcedurePreview";
import ValidationStatusActionModal from "pages/System/ValidationStatus/ValidationStatusActionModal";
import { IUser } from "app/stores/users/types";

export default function ValidationTable({
  procedures,
  loading,
}: {
  procedures: any;
  loading: boolean;
}) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewDocumentId, setViewDocumentId] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState<IUser>();

  const showModal = (docId: number) => {
    setViewDocumentId(docId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    status: statusUserInfoData,
    // eslint-disable-next-line
    error: errorUserInfoData,
    userInfoData,
  } = fetchUserInfoTanStack();

  useEffect(() => {
    setUser(userInfoData);
  }, [statusUserInfoData === "success", procedures]);

  const arrayOfValidatorId = (validators: any) => {
    const arrayOfValidator: number[] = [];
    validators.map((user: any) => {
      arrayOfValidator.push(user.user.id);
      return user;
    });
    return arrayOfValidator;
  };

  const columns = [
    // {
    //   title: "QR",
    //   dataIndex: "document_baseline_id",
    //   key: "qrcode",
    //   render: (document_baseline_id: string) => (
    //     <Popover
    //       trigger="click"
    //       content={<QrCodeComponent qrCode={document_baseline_id} />}
    //     >
    //       <Button icon={<AiOutlineQrcode size={24} />}></Button>
    //     </Popover>
    //   ),
    // },
    {
      title: "edit.name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => (
        <p
          style={{
            margin: "0",
            maxWidth: "25vw",
          }}
        >
          {name}
        </p>
      ),
    },
    {
      title: "menu.release",
      dataIndex: "version",
      key: "release",
      render: (version: string) => <p style={{ margin: "0" }}>{version}</p>,
    },
    {
      title: "doc.models.procedure.authors",
      dataIndex: "author",
      key: "Steps",
      render: (authorData: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AvatarComponent
            src={authorData.avatar?.media_content_uid}
            style={{ border: "2px #262626 solid" }}
            firstName={authorData.first_name}
            lastName={authorData.last_name}
          />
        </div>
      ),
    },
    {
      title: "doc.models.procedure.publishedOn",
      dataIndex: "published_at",
      key: "publishedOnDate",
      render: (publishedOn: string) => (
        <p
          style={{
            display: "flex",
            justifyContent: "flex-start",
            color: "#030852",
            margin: "0",
            padding: "0",
            fontWeight: "400",
            textDecoration: "underline",
          }}
        >
          {new Intl.DateTimeFormat("en-GB").format(new Date(publishedOn))}
        </p>
      ),
    },
    {
      title: "doc.models.procedure.validators",
      dataIndex: "validators",
      key: "created_at",
      render: (validators: any[]) => (
        <ValidatorsAvatar validators={validators} />
      ),
    },
    {
      title: "doc.models.procedure.status",
      dataIndex: "status",
      key: "status",
      render: (status: "PENDING" | "VALIDATED" | "REFUSED") => {
        return <ValidationStatus status={status} statusDate="fd" />;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: ["document_baseline_id", "id"],
      render: (id: string, dos: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            type="default"
            onClick={() => {
              showModal(dos.document_baseline_id);
            }}
          >
            {t("doc.models.edit.tabPanel.preview")}
          </Button>
          {user !== undefined &&
            (user.permissions >= 126 ||
              arrayOfValidatorId(dos.validators).includes(user.id)) && (
              <Button
                type="primary"
                onClick={() => {
                  setIsModalOpen(true);
                  setViewDocumentId(dos.id);
                  setComment("");
                }}
              >
                Action
              </Button>
            )}
        </div>
      ),
    },
  ];
  const [comment, setComment] = useState<string>("");
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(e.currentTarget.value);
  };
  return (
    <div>
      <ListViewNoPagination
        data={procedures}
        cardData={procedures}
        columns={columns}
        loading={loading}
        loadingCardData={loading}
        isListView={true}
        page={1}
        pageSize={20}
        total={10}
        cardLink={"/account/knowledge/models/edit"}
        setSelectedTableKeys={() => {
          return;
        }}
        showCheckBox={false}
      />
      <ProcedurePreview
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        page={"baselines"}
        documentId={viewDocumentId}
      />
      <Modal
        centered
        width={1000}
        title={
          <div style={{ padding: "1rem" }}>
            <h2 style={{ fontSize: "16px" }}>
              {t("validationTable.reviewingDocument")}
            </h2>
          </div>
        }
        open={isModalOpen}
        onOk={() => setIsModalOpen(true)}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <ValidationStatusActionModal
            document_baseline_id={viewDocumentId}
            closeModal={setIsModalOpen}
            comment={comment}
          />,
        ]}
      >
        {user !== undefined && (
          <form style={{ display: "flex" }}>
            <AvatarComponent
              src={user?.avatar?.media_content_uid}
              firstName={user?.first_name}
              lastName={user?.last_name}
            ></AvatarComponent>
            <TextArea
              onChange={onChange}
              value={comment}
              style={{ margin: "0 10px" }}
              rows={4}
            />
          </form>
        )}
      </Modal>
    </div>
  );
}
