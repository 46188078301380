import { Row, Col } from "antd";
import styles from "./AuthLayout.module.scss";
import { MatrixRain } from "components/EasterEggs/MatrixRain";
import React, { useState } from "react";
import MatrixTrigger from "components/EasterEggs/MatrixTrigger";
import Login from "../../pages/Auth/Login";

export default function AuthLayout() {
  const [runEasterEgg, setRunEasterEgg] = useState<boolean>(false);
  const [enterDarkness, setEnterDarkness] = useState<boolean>(false);

  return (
    <div className={styles.auth_layout} style={{ minHeight: "100vh" }}>
      <Row>
        <Col lg={7} md={18} xs={24} className={styles.auth_layout_right}>
          <div className={styles.auth_logo}>
            <MatrixTrigger
              runEasterEgg={runEasterEgg}
              setRunEasterEgg={setRunEasterEgg}
              setEnterDarkness={setEnterDarkness}
            >
              <img
                src="/armtek-logo-big.png"
                alt="Armtek-logo"
                draggable="false"
              />
            </MatrixTrigger>
          </div>
          <Login />
        </Col>
        <Col
          md={17}
          className={` ${false && styles.enterDarkness} ${
            styles.auth_layout_left
          }`}
        >
          <div className={`${enterDarkness && styles.enterDarkness}`}>
            {runEasterEgg && <MatrixRain />}
          </div>
        </Col>
      </Row>
    </div>
  );
}
