import {
  Button,
  Dialog,
  SizeType,
} from "@armtekfrance/armtek-react-design-system";
import { ApiObjectUpsert } from "@armtekfrance/corenexus-pn-sn";
import { ObjectCloudActions, useIsOngoing } from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";
import { navigation } from "Router/NavigationService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./SystemModelEditorPage.module.scss";

const AfterPublishModal = ({
  openDialog,
  setOpenDialog,
  baselineIdPublished,
  typeOfBaselineLabel,
}: {
  openDialog: boolean;
  setOpenDialog: (openDialogue: boolean) => void;
  baselineIdPublished: number | null | undefined;
  typeOfBaselineLabel: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeDialog = () => {
    setOpenDialog(false);
    navigation.to("account/system/models/list");
  };

  const onClickOk = () => {
    if (baselineIdPublished) {
      const objectRequest: ApiObjectUpsert = {
        baseline_id: baselineIdPublished,
      };
      Manager.API.emit(ObjectCloudActions.OBJECT_UPSERT, {
        objects: [objectRequest],
        config: {
          extend: true,
          object_constructor: {
            extend: true,
          },
        },
      }).subscribe((data) => {
        if (data.response?.objects?.length > 0) {
          const constructorId =
            data.response.objects[0].data?.object_constructor?.id;
          if (constructorId) {
            navigateToConstructor(constructorId, data.response.objects[0].id);
          }
          setOpenDialog(false);
        }
      });
    } else {
      setOpenDialog(false);
    }
  };

  const isObjectUpsert = useIsOngoing(ObjectCloudActions.OBJECT_UPSERT);

  const navigateToConstructor = (constructorId: number, objectId: number) => {
    // TODO : Replace with navigation service later
    navigate(
      `/account/system/assign/constructor/${baselineIdPublished}/${constructorId}/${objectId}`
    );
  };

  return (
    <Dialog
      title={t("createObject")}
      onHide={closeDialog}
      size={SizeType.small}
      child={<div>{`${t("createObjectBody")} ${typeOfBaselineLabel} ?`}</div>}
      open={openDialog}
      footer={
        <div className={styles.rowGap}>
          {/* No button */}
          <Button
            child={t("button.no")}
            onClick={closeDialog}
            disabled={isObjectUpsert}
          />
          {/* Ok button */}
          <Button
            child={t("button.confirm")}
            onClick={onClickOk}
            disabled={isObjectUpsert}
            loading={isObjectUpsert}
          />
        </div>
      }
      footerStyle={{ display: "flex", justifyContent: "right" }}
    ></Dialog>
  );
};

export default AfterPublishModal;
