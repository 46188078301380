import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Error403() {
  const { t } = useTranslation();
  return (
    <Result
      status="403"
      title="403"
      subTitle={t("dashboard.403Page")}
      extra={
        <Link to={"/account/dashboard"}>
          <Button type="primary">{t("dashboard.backHome")}</Button>
        </Link>
      }
    />
  );
}
