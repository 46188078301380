import { useQuery } from "@tanstack/react-query";
import Request, { filterFormat } from "../../../libs/requests";
import { IUser } from "./types";
export const fetchUserInfo = async () => {
  return Request()
    .get("/users")
    .then((response) => {
      return response.data.data as IUser;
    });
};

export const fetchUserInfoTanStack = () => {
  const {
    status,
    error,
    data: userInfoData,
  } = useQuery({
    queryKey: ["userInfoData"],
    queryFn: () => fetchUserInfo(),
  });
  return { status, error, userInfoData };
};

export const fetchUsersTanStack = (filters = {}) => {
  const {
    status,
    error,
    data: users,
  } = useQuery({
    queryKey: ["users", filters],
    queryFn: () => fetchUsers(filters),
  });
  return { status, error, users };
};

export const fetchUsers = async (filters = {}) => {
  return Request()
    .get("/users/company", {
      params: {
        ...filterFormat(filters),
      },
    })
    .then((response) => response.data.data.users as IUser[]);
};

export const updateUserPassword = async (password: string) => {
  return Request()
    .patch("/users/password-update", { password })
    .then((response) => response.data);
};
