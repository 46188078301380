import { createContext, Dispatch, useContext } from "react";
import {
  stateAddLinkageNodeProvider,
  TypeAddLinkageNodeContextReducerAction,
} from "./AddLinkageNodeReducer";

export const AddLinkageNodeContext = createContext<stateAddLinkageNodeProvider>(
  {
    pagination: { page: 1, pageSize: 10, total: -1 },
    status: "loading",
    error: null,
    documentModels: [],
    selectedItems: [],
    typeFilter: "",
    queryNameTextFilter: "",
  }
);

export const AddLinkageNodeDispatchContext = createContext<
  Dispatch<TypeAddLinkageNodeContextReducerAction>
>(() => {
  return { type: "" };
});

export function useAddLinkageNode() {
  return useContext(AddLinkageNodeContext);
}

export function useAddLinkageNodeDispatch() {
  return useContext(AddLinkageNodeDispatchContext);
}
