import AddLinkageNodeProvider from "./Provider/AddLinkageNodeProvider";
import AddLinkageNode from "./AddLinkageNode";
import { AddNodeProps } from "./AddLinkageNode";

export default function AddLinkageNodeRoot({
  open,
  setOpen,
  onComplete,
  parentNodeId,
}: AddNodeProps) {
  return (
    <AddLinkageNodeProvider>
      <AddLinkageNode
        open={open}
        parentNodeId={parentNodeId}
        onComplete={onComplete}
        setOpen={setOpen}
      />
    </AddLinkageNodeProvider>
  );
}
