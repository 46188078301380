import Cookie from "universal-cookie";

export function readFromCookies(field: string) {
  const cookies = new Cookie();
  return cookies.get(field);
}

export function removeCookies(field: string) {
  const cookies = new Cookie();
  return cookies.remove(field);
}
