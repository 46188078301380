import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import DropdownMultiSelection from "components/ExecutionTable/DropdownMultiSelection";
import { ITeamType, IUserDropdownMultiSelection } from "app/stores/users/types";
import { useEffect, useState } from "react";
import { fetchUsersTanStack } from "app/stores/users/userClient";
import { DropdownButton } from "@armtekfrance/armtek-react-design-system";

export default function UsersTeamsDropDown({
  onSelectedChange,
  hasPopover = true,
  dropDownStyle,
  forceUserList,
  placeholder,
  canSelectAll = true,
}: {
  onSelectedChange: (
    items: IUserDropdownMultiSelection[],
    itemsIds: number[]
  ) => void;
  hasPopover?: boolean;
  dropDownStyle?: React.CSSProperties;
  forceUserList?: {
    selectedUserList: number[];
    setSelectedUserList: (userList: number[]) => void;
  };
  placeholder?: string;
  canSelectAll?: boolean;
}) {
  const { t } = useTranslation();

  const [allUsers, setAllUsers] = useState<IUserDropdownMultiSelection[]>([]);
  const [usersFilter, setUsersFilter] = useState<number[]>([]);

  const { selectedUserList: forcedSelectedUserList, setSelectedUserList } =
    forceUserList ?? {};

  const {
    status: statusUsers,
    error: errorUsers,
    users,
  } = fetchUsersTanStack({ include_team: true });

  const getAllTheTeams = (
    users: IUserDropdownMultiSelection[]
  ): ITeamType[] => {
    if (!users) return [];
    let teams: ITeamType[] = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].team && users[i].team.length > 0) {
        for (let j = 0; j < users[i].team.length; j++) {
          if (
            teams.filter((team) => team.id === users[i].team[j].id).length < 1
          ) {
            teams.push(users[i].team[j]);
          }
        }
      }
    }
    for (let i = 0; i < teams.length; i++) {
      teams[i].label = teams[i].name;
      teams[i].value = teams[i].name;
    }
    return teams;
  };

  useEffect(() => {
    const LocalAllUsers: IUserDropdownMultiSelection[] =
      users as IUserDropdownMultiSelection[];
    if (LocalAllUsers && LocalAllUsers.length > 0) {
      for (let i = 0; i < LocalAllUsers.length; i++) {
        LocalAllUsers[i].label =
          LocalAllUsers[i].first_name + " " + LocalAllUsers[i].last_name;
        LocalAllUsers[i].value =
          LocalAllUsers[i].first_name + " " + LocalAllUsers[i].last_name;
      }
      setAllUsers(LocalAllUsers);
    }
  }, [statusUsers, errorUsers, users]);

  useEffect(() => {
    console.log("forceUserList", forceUserList);
  }, forcedSelectedUserList);

  const selectedUsers = forcedSelectedUserList ?? usersFilter;

  const dropDownContent = (
    <DropdownMultiSelection
      allItems={
        allUsers != undefined
          ? allUsers.map((user) => {
              return {
                label: user.label!,
                value: user.id,
                filterValues: user.team.map((team) => {
                  return team.id;
                }),
              };
            })
          : []
      }
      styleDropdown={dropDownStyle ? dropDownStyle : { width: "334px" }}
      selectedItems={selectedUsers.length > 0 ? selectedUsers : []}
      onSelectAll={(items) => {
        const selectedUserIds = items.map((user) => {
          return user.value as number;
        });

        setUsersFilter(selectedUserIds);
        if (setSelectedUserList) {
          setSelectedUserList(selectedUserIds);
        }
        onSelectedChange([], selectedUserIds);
      }}
      onClear={(items) => {
        const selectedUsersIds = items.map(
          (selectedUserId) => selectedUserId.value as number
        );

        const userList = usersFilter.filter(
          (userFilterId) =>
            !selectedUsersIds?.some((userId) => userFilterId === userId)
        );

        setUsersFilter(userList);
        if (setSelectedUserList) {
          setSelectedUserList(userList);
        }
        onSelectedChange([], userList);
      }}
      onSelectItem={(itemList) => {
        const selectedIds = itemList as number[];

        setUsersFilter(selectedIds);
        if (setSelectedUserList) {
          setSelectedUserList(selectedIds);
        }
        // TODO: items empty[] is not good
        onSelectedChange([], selectedIds);
      }}
      placeholder={t("dropdownMultiSelection.selectUser")}
      isSelectAllButtonVisible={canSelectAll}
      filter={{
        areFilterDropdownsVisible: true,
        firstFilterField: t("team.team"),
        firstFilterOptions:
          allUsers != undefined
            ? getAllTheTeams(allUsers).map((team) => {
                return {
                  label: team.label,
                  value: team.id,
                };
              })
            : [],
      }}
    />
  );

  return hasPopover ? (
    <div>
      <Popover placement="bottom" trigger="click" content={dropDownContent}>
        {/*
         * // TODO add parameter "selectedUsers.length" as counter for dropdown
         */}
        <DropdownButton
          disabled={statusUsers !== "success"}
          name={placeholder ?? t("settings.user")}
          selectedItems={selectedUsers.length}
        />
        {/* <Button dropdownButton filter disabled={statusUsers !== "success"}>
          <div className={styles.usersTeamsDropDownButton}>
            <div>{placeholder ?? t("settings.user")}</div>
            {usersFilter.length > 0 && (
              <div
                className={`
                  ${styles.usersTeamsDropDownButtonBadge}
                  ${selectedUsers.length <= 9 && styles.badgeBaseFontSize}
                  ${
                    selectedUsers.length > 9 &&
                    selectedUsers.length <= 99 &&
                    styles.badgeGeaterThan9
                  }
                  ${selectedUsers.length > 99 && styles.badgeGeaterThan99}
                `}
              >
                <p>
                  {selectedUsers.length <= 99 ? selectedUsers.length : "99+"}
                </p>
              </div>
            )}
          </div>
        </Button> */}
      </Popover>
    </div>
  ) : (
    <>{dropDownContent}</>
  );
}
