import { QueryStatus } from "@tanstack/react-query";
import { DocumentBaseline } from "app/stores/documentsBaseline/types";
import { PaginationType } from "app/stores/pagination/types";
import { IUser } from "app/stores/users/types";

export type stateValidationStatusProvider = {
  statusBaselineValidations: QueryStatus;
  errorBaselineValidations: unknown;
  baselineValidations: (DocumentBaseline & { selected: boolean })[] | undefined;
  queryBaselineValidationsStatus: ("PENDING" | "VALIDATED" | "REFUSED")[];
  paginationBaselineValidations: PaginationType;
  statusUsers: QueryStatus;
  errorUsers: unknown;
  queryNameTextFilter: string;
  users: IUser[] | undefined;
  authors: number[];
  validators: number[];
};

export type TypeValidationStatusContextReducerAction =
  | {
      type: "setPaginationBaselineValidations";
      paginationBaselineValidations: stateValidationStatusProvider["paginationBaselineValidations"];
    }
  | {
      type: "setQueryNameTextFilter";
      queryNameTextFilter: stateValidationStatusProvider["queryNameTextFilter"];
    }
  | {
      // TODO Type is not DocumentBaseline ?
      type: "setBaselineValidations";
      baselineValidations: stateValidationStatusProvider["baselineValidations"];
    }
  | {
      type: "setErrorBaselineValidations";
      errorBaselineValidations: stateValidationStatusProvider["errorBaselineValidations"];
    }
  | {
      type: "setQueryBaselineValidationsStatus";
      queryBaselineValidationsStatus: stateValidationStatusProvider["queryBaselineValidationsStatus"];
    }
  | {
      type: "setStatus";
      statusBaselineValidations: stateValidationStatusProvider["statusBaselineValidations"];
    }
  | {
      type: "setUsersTanstack";
      setUsersTanstack: {
        statusUsers: stateValidationStatusProvider["statusUsers"];
        errorUsers: stateValidationStatusProvider["errorUsers"];
        users: stateValidationStatusProvider["users"];
      };
    }
  | {
      type: "setBaselineValidationsTanstack";
      setBaselineValidationsTanstack: {
        baselineValidations: stateValidationStatusProvider["baselineValidations"];
        paginationBaselineValidations: stateValidationStatusProvider["paginationBaselineValidations"];
      };
    }
  | {
      type: "setAuthors";
      authors: stateValidationStatusProvider["authors"];
    }
  | {
      type: "setValidators";
      validators: stateValidationStatusProvider["validators"];
    }
  | {
      type: "setBaselineValidationsTanstackApi";
      setBaselineValidationsTanstackApi: {
        statusBaselineValidations: stateValidationStatusProvider["statusBaselineValidations"];
        errorBaselineValidations: stateValidationStatusProvider["errorBaselineValidations"];
      };
    };

export function validationStatusReducer(
  stateValidationStatus: stateValidationStatusProvider,
  action: TypeValidationStatusContextReducerAction
): stateValidationStatusProvider {
  switch (action.type) {
    case "setUsersTanstack": {
      if (action.setUsersTanstack.statusUsers === "success") {
        return {
          ...stateValidationStatus,
          statusUsers: action.setUsersTanstack.statusUsers,
          errorUsers: action.setUsersTanstack.errorUsers,
          users: action.setUsersTanstack.users,
          authors:
            action.setUsersTanstack.users!.map((user) => {
              return user.id;
            }) ?? [],
          validators: [],
        };
      }
      return {
        ...stateValidationStatus,
        statusUsers: action.setUsersTanstack.statusUsers,
        errorUsers: action.setUsersTanstack.errorUsers,
      };
    }
    case "setBaselineValidationsTanstackApi": {
      return {
        ...stateValidationStatus,
        statusBaselineValidations:
          action.setBaselineValidationsTanstackApi.statusBaselineValidations,
        errorBaselineValidations:
          action.setBaselineValidationsTanstackApi.errorBaselineValidations,
      };
    }
    case "setBaselineValidationsTanstack": {
      return {
        ...stateValidationStatus,
        baselineValidations:
          action.setBaselineValidationsTanstack.baselineValidations,
        paginationBaselineValidations:
          action.setBaselineValidationsTanstack.paginationBaselineValidations,
      };
    }
    case "setErrorBaselineValidations": {
      return {
        ...stateValidationStatus,
        errorBaselineValidations: action.errorBaselineValidations,
      };
    }
    case "setQueryBaselineValidationsStatus": {
      return {
        ...stateValidationStatus,
        queryBaselineValidationsStatus: action.queryBaselineValidationsStatus,
      };
    }
    case "setStatus": {
      return {
        ...stateValidationStatus,
        statusBaselineValidations: action.statusBaselineValidations,
      };
    }
    case "setAuthors": {
      return {
        ...stateValidationStatus,
        authors: action.authors,
      };
    }
    case "setValidators": {
      return {
        ...stateValidationStatus,
        validators: action.validators,
      };
    }
    case "setPaginationBaselineValidations": {
      return {
        ...stateValidationStatus,
        paginationBaselineValidations: action.paginationBaselineValidations,
      };
    }
    case "setQueryNameTextFilter": {
      return {
        ...stateValidationStatus,
        paginationBaselineValidations: {
          ...stateValidationStatus.paginationBaselineValidations,
          page: 1,
        },
        queryNameTextFilter: action.queryNameTextFilter,
      };
    }
    default: {
      return { ...stateValidationStatus };
    }
  }
}
