import {
  Col,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  InputNumber,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import styles from "./InputCheckboxBlock.module.scss";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface DataBlockType {
  data: any;
  userInput: any;
  alertOccured: boolean;
}

export default function DataBlock({
  data,
  userInput,
  alertOccured,
}: DataBlockType) {
  const { t } = useTranslation();
  const [inputData, setInputData] = useState(
    data
      ? data
      : {
          name: "",
          instruction: "",
          type: "int",
          units: "",
          conditionType: "fixValue",
          conditionValue: "",
          inputConditionValues: [0, 0, 0],
          mandatory: false,
        }
  );
  useEffect(() => {
    data
      ? setInputData(data)
      : setInputData({
          name: "",
          instruction: "",
          type: "int",
          units: "",
          conditionType: "fixValue",
          conditionValue: "",
          inputConditionValues: [0, 0, 0],
          mandatory: false,
        });
  }, [data]);

  const [requestDataHistory, setRequestDataHistory] = useState("");

  return (
    <div
      style={{
        border: alertOccured ? "3px red solid" : "",
        backgroundColor: "#F6F9FB",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}
      {userInput.relatedAlerts && userInput.relatedAlerts.length > 1 && (
        <div
          style={{
            marginRight: "10px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Select
            defaultValue={
              userInput.relatedAlerts[0].targets[0].data.requested_data
            }
            onChange={(value: any) => {
              setRequestDataHistory(value);
            }}
            style={{ width: 120 }}
          >
            {userInput.relatedAlerts.map((alert: any, index: number) => (
              <Select.Option
                key={index}
                value={alert.targets[0].data.requested_data}
              >
                {alert.targets[0].data.requested_data}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
      <div
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <p
              style={{
                color: "#030852",
                fontWeight: "600",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              {t("doc.models.edit.sections.blocks.mandatoryData")}
            </p>
            <Switch defaultChecked={data?.mandatory} disabled={true} />
          </div>
          <Space direction="vertical">
            <Input
              placeholder={t("doc.models.linkage.name")}
              style={{ width: "35vw" }}
              value={inputData.name}
              disabled={true}
            />
            <TextArea
              placeholder={t("dataBlock.instruction")}
              style={{ marginBottom: "8px", width: "35vw" }}
              value={inputData.instruction}
              disabled={true}
            />
          </Space>
          <Row
            style={{
              marginBottom: "8px",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Col span={2}>{t("doc.models.linkage.type")}</Col>
            <Col span={22}>
              <Radio.Group value={inputData.type} disabled={true}>
                <Radio value={"int"}>{t("dataBlock.int")}</Radio>
                <Radio value={"decimal"}>{t("dataBlock.decimal")}</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: "8px",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Col span={2}>{t("dataBlock.units")}</Col>
            <Col span={22}>
              <Select
                value={inputData.units}
                style={{ width: 220 }}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2}>{t("dataBlock.condition")}</Col>
            <Col span={22}>
              <Radio.Group
                style={{ width: "100%" }}
                value={inputData.conditionType}
                disabled={true}
              >
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Space direction="horizontal" style={{ width: "100%" }}>
                    <Radio value={"interval"}>
                      <Space size="large">
                        {t("dataBlock.interval")}
                        <InputNumber
                          placeholder={t("dataBlock.min")}
                          style={{ width: "80px" }}
                          step={inputData.type === "decimal" ? 0.5 : 1}
                          disabled={
                            inputData.conditionType !== "interval" || true
                          }
                          value={
                            inputData.conditionType === "interval"
                              ? inputData.inputConditionValues[0]
                              : ""
                          }
                          precision={3}
                        />
                        <InputNumber
                          placeholder={t("dataBlock.max")}
                          style={{ width: "80px" }}
                          step={inputData.type === "decimal" ? 0.5 : 1}
                          disabled={
                            inputData.conditionType !== "interval" || true
                          }
                          value={
                            inputData.conditionType === "interval"
                              ? inputData.inputConditionValues[1]
                              : ""
                          }
                          precision={3}
                        />
                      </Space>
                    </Radio>
                  </Space>
                  <Radio value={"margin"}>
                    <Space size="large">
                      {t("dataBlock.margin")}
                      <InputNumber
                        placeholder={t("dataBlock.value")}
                        style={{ width: "80px" }}
                        step={inputData.type === "decimal" ? 0.5 : 1}
                        disabled={inputData.conditionType !== "margin" || true}
                        value={
                          inputData.conditionType === "margin"
                            ? inputData.inputConditionValues[0]
                            : ""
                        }
                        precision={3}
                      />
                      <InputNumber
                        addonBefore="+/-"
                        style={{ width: "120px" }}
                        step={inputData.type === "decimal" ? 0.5 : 1}
                        disabled={inputData.conditionType !== "margin" || true}
                        value={
                          inputData.conditionType === "margin"
                            ? inputData.inputConditionValues[1]
                            : ""
                        }
                        precision={3}
                      />
                      <InputNumber
                        addonBefore="%"
                        style={{ width: "120px" }}
                        step={inputData.type === "decimal" ? 0.5 : 1}
                        disabled={inputData.conditionType !== "margin" || true}
                        value={
                          inputData.conditionType === "margin"
                            ? inputData.inputConditionValues[2]
                            : " "
                        }
                        precision={3}
                      />
                    </Space>
                  </Radio>
                  <Radio value={"fixValue"}>
                    <Space size="large">
                      {t("dataBlock.fixValue")}
                      <InputNumber
                        placeholder={t("dataBlock.value")}
                        style={{ width: "80px" }}
                        step={inputData.type === "decimal" ? 0.5 : 1}
                        disabled={
                          inputData.conditionType !== "fixValue" || true
                        }
                        value={
                          inputData.conditionType === "fixValue"
                            ? inputData.inputConditionValues[0]
                            : ""
                        }
                        precision={3}
                      />
                    </Space>
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: "16px" }}>
          <div className={styles.toggled}>
            <h3>{t("dataBlock.operatorInput")}</h3>
            <div className={styles.toggledContent}>
              {requestDataHistory
                ? requestDataHistory
                : userInput.requested_data}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
