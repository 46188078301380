import { useTranslation } from "react-i18next";
import styles from "./DropDownSelectOrUnselectAll.module.scss";

export default function DropDownSelectOrUnselectAll({
  onSelectAll,
  onClearAll,
}: {
  onSelectAll: () => void;
  onClearAll: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.dropDownSelectOrUnselectAllWrapper}>
      <p onClick={onSelectAll}>{t("operate.selectAll")}</p>
      <p onClick={onClearAll}>{t("operate.clear")}</p>
    </div>
  );
}
