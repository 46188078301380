import AddTeamFormProvider from "./Provider/AddTeamFormProvider";
import AddTeamForm from "./AddTeamForm";
import AddTeamFormParameters from "./AddTeamFormParameters";

export default function AddTeamFormRoot({
  activeId,
  createFormRef,
  onOpenClose,
  messageApi,
  onCancel,
  onSave,
  displayUserSelect,
}: AddTeamFormParameters) {
  return (
    <AddTeamFormProvider>
      <AddTeamForm
        activeId={activeId}
        createFormRef={createFormRef}
        onOpenClose={onOpenClose}
        messageApi={messageApi}
        onCancel={onCancel}
        onSave={onSave}
        displayUserSelect={displayUserSelect}
      />
    </AddTeamFormProvider>
  );
}
