import { useTranslation } from "react-i18next";
import { ReactComponent as HeadSet } from "../TempDS/assets/HeadSet.svg";
import { ReactComponent as AudioPlay } from "../TempDS/assets/AudioPlay.svg";
import { ReactComponent as AudioPause } from "../TempDS/assets/AudioPause.svg";
import styles from "./MediaSelectorAudio.module.scss";
import ReactPlayer from "react-player";
import { useRef, useState } from "react";
import { fetchBlobObjectTanstack } from "app/stores/content/contentClient";
import { MediaContent } from "Stores/media-models";
import React from "react";

export default function MediaSelectorAudio({
  mediaItem,
  dropDownComponent,
  convertToBase64 = true,
}: {
  mediaItem: MediaContent;
  dropDownComponent: JSX.Element;
  convertToBase64?: boolean;
}) {
  const { t } = useTranslation();

  const audioSource = fetchBlobObjectTanstack(
    mediaItem.media_content_uid,
    false,
    false,
    1000 * 60 * 60 * 24 * 7
  );

  const [playing, setPlaying] = useState<boolean>(false);

  const reactPlayerRef = useRef(null);
  return (
    <div className={styles.mediaSelectorAudioWrapper}>
      <article>
        <header>
          <HeadSet />
        </header>
        <footer
          onClick={() => {
            setPlaying(!playing);
          }}
        >
          {audioSource.status === "success" && (
            <>
              {playing ? (
                <span className={styles.iconPause}>
                  <AudioPause />
                </span>
              ) : (
                <AudioPlay />
              )}
              <p>{t("doc.models.edit.sections.blocks.audioPlay")}</p>
            </>
          )}
        </footer>
        <ReactPlayer
          ref={reactPlayerRef}
          width={"0%"}
          height={"0%"}
          url={`data:${audioSource.blobObject?.media.ContentType};${
            convertToBase64 ? "base64," : ""
          } ${audioSource.blobObject?.media.file}`}
          playing={playing}
          onEnded={() => setPlaying(false)}
          controls={false}
        />
      </article>
      {dropDownComponent}
    </div>
  );
}
