import { fetchUsersTanStack } from "app/stores/users/userClient";
import ValidationStatusHeroDropDownAuthor from "./ValidationStatusHeroDropDownAuthor/ValidationStatusHeroDropDownAuthor";
import { useEffect } from "react";
import { useValidationStatusDispatch } from "../Provider/ValidationStatusContext";
import ValidationStatusHeroDropDownStatus from "./ValidationStatusHeroDropDownStatus/ValidationStatusHeroDropDownStatus";
import ValidationStatusHeroDropDownValidator from "./ValidationStatusHeroDropDownValidator/ValidationStatusHeroDropDownValidator";
import ValidationStatusHeroSearch from "./ValidationStatusHeroSearch/ValidationStatusHeroSearch";
import styles from "./ValidationStatusHero.module.scss";

export default function ValidationStatusHero() {
  const dispatchValidationStatus = useValidationStatusDispatch();

  const {
    status: statusUsers,
    error: errorUsers,
    users,
  } = fetchUsersTanStack();

  useEffect(() => {
    dispatchValidationStatus({
      type: "setUsersTanstack",
      setUsersTanstack: { statusUsers, errorUsers, users },
    });
  }, [statusUsers, errorUsers, users]);
  return (
    <div className={styles.validationStatusHeroWrapper}>
      <ValidationStatusHeroSearch />
      <ValidationStatusHeroDropDownAuthor />
      <ValidationStatusHeroDropDownStatus />
      <ValidationStatusHeroDropDownValidator />
    </div>
  );
}
