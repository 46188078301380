import { useTranslation } from "react-i18next";
import { useValidationStatusDispatch } from "../../Provider/ValidationStatusContext";
import UsersTeamsDropDown from "components/UsersTeamsDropDown/UsersTeamsDropDown";

export default function ValidationStatusHeroDropDownValidator() {
  const { t } = useTranslation();
  const dispatchValidationStatus = useValidationStatusDispatch();
  return (
    <UsersTeamsDropDown
      placeholder={t("validationTable.validator")}
      onSelectedChange={(_items, itemsIds) => {
        dispatchValidationStatus({
          type: "setValidators",
          validators: itemsIds,
        });
        return;
      }}
    />
  );
}
