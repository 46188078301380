import { message } from "antd";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { fetchBlobObject } from "../../app/stores/content/contentClient";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";

export default function VideoPlayerComponent({
  isVideoPlaying,
  playVideo,
}: any) {
  const { t } = useTranslation();
  const [video, setVideo] = useState<any>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(false);
      fetchBlobObject(playVideo.media_content.media_content_uid).then(
        (response) => {
          if (response.media != null) {
            setVideo(
              `data:${response.media.ContentType};base64, ${response.media.file}`
            );
          } else if (response.fullMedia != null) {
            setVideo(
              `data:${response.fullMedia.ContentType};base64, ${response.fullMedia.file}`
            );
          }
          setLoading(true);
        }
      );
    } catch (error) {
      message.error(t("avatarComponent.problemLoading"));
      setVideo("");
      setLoading(true);
    }
  }, [playVideo]);

  return (
    <>
      {loading ? (
        <div className={style.playerWrapper}>
          <ReactPlayer
            url={video}
            playing={isVideoPlaying ? true : false}
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      ) : (
        <div className={style.centerSpin}>
          <Spin />
        </div>
      )}
    </>
  );
}
