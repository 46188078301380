import { Col, Form, Input } from "antd";
import { useAddTeamFormDispatch } from "components/AddTeamForm/Provider/AddTeamFormContext";
import { useTranslation } from "react-i18next";

interface AddTeamFormtextFieldsTeamNameProps {
  handleDisplay: () => void;
  handleHide: () => void;
}

export default function AddTeamFormTextFieldsTeamName({
  handleDisplay,
  handleHide,
}: AddTeamFormtextFieldsTeamNameProps) {
  const { t } = useTranslation();

  const dispatchAddTeamForm = useAddTeamFormDispatch();

  const checkNickName = (e: any) => {
    const words: string[] = e.target.value.split(" ");
    let suggestedWord = words.reduce(
      (pre: string, next: string, index: number) => {
        if (index === 1) return pre.charAt(0) + next.charAt(0);
        else return pre + next.charAt(0);
      }
    );

    if (words.length > 1 && words[1].length && suggestedWord.length) {
      dispatchAddTeamForm({
        type: "setSuggestedDropdownValues",
        suggestedTeamNickname: suggestedWord,
        isPopOverVisibleNickname: true,
      });
    } else {
      dispatchAddTeamForm({
        type: "resetSuggestedDropdown",
      });
    }
  };

  return (
    <Col span={24}>
      <Form.Item
        name="name"
        label={t("team.teamFullName")}
        rules={[
          {
            required: true,
            message: t("team.teamNameInput"),
          },
        ]}
      >
        <Input
          maxLength={255} // max length accepted by API for team name
          onChange={checkNickName}
          placeholder={t("team.teamNameInput")}
          onFocus={handleDisplay}
          onBlur={handleHide}
        />
      </Form.Item>
    </Col>
  );
}
