import { Outlet } from "react-router-dom";
import styles from "./TopHeaderLayout.module.scss";
import HeaderRoot from "components/Header/HeaderRoot";

export default function TopHeaderLayout() {
  return (
    <div className={styles.topHeaderLayoutWrapper}>
      <HeaderRoot></HeaderRoot>
      <Outlet />
    </div>
  );
}
