import { Col, Row, Skeleton } from "antd";

export default function NotificationPreferencesLoader({
  includePreferenceEditor,
}: {
  includePreferenceEditor: boolean;
}) {
  return (
    <div>
      {[...Array(11)].map(() => (
        <Row gutter={12}>
          <Col span={includePreferenceEditor ? 10 : 14} className="mb-4">
            <div className="full-skeleton">
              <Skeleton.Input active />
            </div>
          </Col>
          {includePreferenceEditor && (
            <Col span={4} className="mb-4">
              <div className="full-skeleton d-flex justify-content-center">
                <Skeleton.Input active />
              </div>
            </Col>
          )}
          <Col span={5} className="mb-4">
            <div className="full-skeleton d-flex justify-content-center">
              <Skeleton.Input active />
            </div>
          </Col>
          <Col span={5} className="mb-4">
            <div className="full-skeleton d-flex justify-content-center">
              <Skeleton.Input active />
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}
