import styles from "./MediaPickerModalHeader.module.scss";
import MediaPickerModalHeaderTabs from "./MediaPickerHeaderTabs";

export default function MediaPickerModalHeader() {
  return (
    <header className={styles.mediaPickerModalHeaderWrapper}>
      <MediaPickerModalHeaderTabs />
    </header>
  );
}
