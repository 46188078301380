import { notificationType } from "app/stores/notifications/notificationsType";
import NotificationListItem from "./NotificationListItem";
import styles from "./NotificationListDayItem.module.scss";

export default function NotificationListDayItem({
  dayItem,
  onClick,
}: {
  dayItem: { createdAt: string; notifications: notificationType[] | undefined };
  onClick: (item: notificationType) => void;
}) {
  return (
    <div className={styles.notificationListDayItemWrapper}>
      <h2>{dayItem.createdAt}</h2>
      {dayItem.notifications?.map((notification) => {
        return (
          <NotificationListItem
            onClick={onClick}
            notificationItem={notification}
          />
        );
      })}
    </div>
  );
}
