import { IUser } from "app/stores/users/types";
import Request, {
  pageFormat,
  filterFormat,
  pageSizeFormat,
} from "../../../libs/requests";
import { PaginationType } from "app/stores/pagination/types";

// Company services
export const fetchCompanyUsers = async (
  page: number,
  pageSize: number,
  filters: {
    first_name?: {
      contains: string;
    };
    middle_name?: {
      contains: string;
    };
    last_name?: {
      contains: string;
    };
    team_id?: number | string;
  }
) => {
  let response = await Request().get("/users/company", {
    params: {
      ...filterFormat(filters),
      ...pageFormat(page),
      ...pageSizeFormat(pageSize),
    },
  });
  return {
    users: response.data.data.users as IUser[],
    pagination: response.data.data.pagination as PaginationType,
  };
};

export const fetchSingleCompanyUser = async (id: number) => {
  let response = await Request().get(`/users/company/${id}`);
  return await response.data;
};

export const editCompanyInfo = async (companyData: any) => {
  let response = await Request().patch(`/companies`, companyData);
  return await response.data;
};

export const createCompanyUser = async (userData: any) => {
  let response = await Request().post(`/users/register`, userData);
  return await response.data;
};

export const editCompanyUser = async (id: number, userData: any) => {
  let response = await Request().patch(`/users/${id}`, userData);
  return await response.data;
};

export const fetchCompanyInfo = async () => {
  let response = await Request().get(`/companies`);
  return await response.data;
};

export const archiveCompanyUser = async (id: number) => {
  let response = await Request().delete(`/users/${id}`);
  return await response.data;
};

// Company departments
export const fetchCompanyDepartments = async () => {
  let response = await Request().get(`/companies/departments`);
  return await response.data;
};

export const fetchSingleCompanyDepartment = async (id: number) => {
  let response = await Request().get(`/companies/departments/${id}`);
  return await response.data;
};

export const createCompanyDepartment = async (departmentData: any) => {
  let response = await Request().post(`/companies/departments`, departmentData);
  return await response.data;
};

export const updateCompanyDepartment = async (
  id: number,
  departmentData: any
) => {
  let response = await Request().put(
    `/companies/departments/${id}`,
    departmentData
  );
  return await response.data;
};

export const deleteCompanyDepartment = (id: number) => {
  return Request()
    .delete(`/companies/departments/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("deleteCompanyDepartment", error);
      return error.response.data;
    });
};
