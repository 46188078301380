import { Input } from "antd";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";

interface ExternalLinkType {
  data: any;
  updateContentInDb: (id: string | number, data: any) => void;
  disableEdit?: boolean;
  contentId: string | number;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function ExternalLink({
  data,
  updateContentInDb,
  disableEdit,
  contentId,
  setIsContentBeingSaved,
}: ExternalLinkType) {
  const { t } = useTranslation();
  const urlRegex = new RegExp(
    // eslint-disable-next-line
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  const [externalLink, setExternalLink] = useState(
    data
      ? data
      : {
          link: "",
          description: "",
          name: "",
        }
  );
  const [isLinkError, setIsLinkError] = useState(false);
  useEffect(() => {
    setExternalLink(
      data
        ? data
        : {
            link: "",
            description: "",
            name: "",
          }
    );
    setIsLinkError(
      data?.link?.length > 0 && !urlRegex.test(data.link) ? true : false
    );
    // eslint-disable-next-line
  }, [data]);

  let timeoutId: any;
  const handleChange = (fieldKey: string, e: any) => {
    setIsContentBeingSaved(true);
    let updateData: any = {};
    updateData[fieldKey] = e.target.value;
    if (fieldKey === "link") updateData["link"].trim();
    const copyOfData = { ...externalLink, ...updateData };
    setExternalLink(copyOfData);

    let dataToUpdateInDB = { ...copyOfData, link: copyOfData.link.trim() };
    if (
      dataToUpdateInDB.link.length > 0 &&
      !urlRegex.test(dataToUpdateInDB.link)
    ) {
      dataToUpdateInDB = { ...dataToUpdateInDB, link: "" };
      setIsLinkError(true);
    } else {
      setIsLinkError(false);
    }

    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate: any = {
        content: {
          content: dataToUpdateInDB,
        },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  return (
    <div style={{ backgroundColor: "#F6F9FB", padding: "16px" }}>
      <Input
        placeholder={t("doc.models.edit.sections.blocks.linkName")}
        disabled={disableEdit}
        onChange={(e) => handleChange("name", e)}
        value={externalLink.name}
        style={{ marginBottom: "8px" }}
      />
      <Input
        placeholder={t("doc.models.edit.sections.blocks.addExternalLink")}
        disabled={disableEdit}
        onChange={(e) => handleChange("link", e)}
        value={externalLink.link}
        // style={{ marginBottom: "8px" }}
        status={isLinkError ? "error" : ""}
      />
      {isLinkError ? (
        <p style={{ color: "#ff4d4f", margin: "0px", fontSize: "12px" }}>
          {t("externalLink.validUrl")}
        </p>
      ) : (
        <p> </p>
      )}
      <TextArea
        autoSize
        bordered={false}
        rows={2}
        placeholder={t("doc.models.edit.sections.blocks.enterDescription")}
        disabled={disableEdit}
        onChange={(e) => handleChange("description", e)}
        value={externalLink.description}
      />
    </div>
  );
}
