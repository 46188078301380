import { useState } from "react";
import ExecutionTable from "components/ExecutionTable";
import styles from "./ExecutionFlux.module.scss";
import {
  ExpandButton,
  ExpandStateType,
  FilterManager,
  FilterMaster,
  GlobalStore,
  SizeType,
  useStoreState,
} from "@armtekfrance/webgate/dist";
import { ApiExecutionMonitorSearchRequest } from "@armtekfrance/corenexus-pn-sn";
import MediaPreviewWithEditor from "../../../components/MediaPreviewWithEditor/MediaPreviewWithEditor";

export default function ExecutionFlux({
  filterManager,
}: {
  filterManager: FilterManager;
}) {
  const [expandAll, setExpandAll] = useState(false);

  const [filterExecution] = useStoreState(
    GlobalStore.filter.storeFilters.executionFilter
  );
  const [filterDocument] = useStoreState(
    GlobalStore.filter.storeFilters.documentBaselineFilter
  );
  const [filterUser] = useStoreState(
    GlobalStore.filter.storeFilters.userFilter
  );

  const filter: ApiExecutionMonitorSearchRequest = {
    execution: filterExecution,
    document: filterDocument,
    operator: filterUser,

    config: {
      extend: true,
      user: {
        extend: true,
      },
      baseline: {
        extend: true,
        qr_code: {
          extend: true,
        },
      },
    },
  };

  return (
    <div className={styles.executionFluxWrapper}>
      <div className={styles.executionFluxHeroContainer}>
        <MediaPreviewWithEditor />
        <FilterMaster
          filterManager={filterManager}
          fetchData={() => {
            console.log("");
          }}
        />
        <div className={styles.executionFluxHeroBtnContainer}>
          <ExpandButton
            onClickButton={() => {
              setExpandAll(!expandAll);
            }}
            buttonSize={SizeType.medium}
            expandStateType={
              expandAll ? ExpandStateType.reduce : ExpandStateType.expand
            }
          />
          {/* <ThreeDotMenu menuOptions={[]} /> */}
        </div>
      </div>

      <ExecutionTable emitFilter={filter} expandAll={expandAll} />
    </div>
  );
}
