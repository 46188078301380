import { ReactElement } from "react";
import ImageDisplayComponent from "../ImageDisplayComponent/ImageDisplayComponent";
import { t } from "i18next";
import { WarningOutlined } from "@ant-design/icons";

interface ISignature {
  media_content_uid: string;
  alertOccured: boolean;
}

export default function SignatureBlock({
  media_content_uid,
  alertOccured,
}: ISignature): ReactElement {
  return (
    <div
      style={{
        border: alertOccured ? "3px red solid" : "",
        borderRadius: "5px",
        width: "25%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}

      <ImageDisplayComponent
        src={`${media_content_uid}`}
        alt={t("operate.signature")}
      ></ImageDisplayComponent>
    </div>
  );
}
