import { Button, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AiOutlineMinusCircle } from "react-icons/ai";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

interface CheckboxType {
  removeChoice: (index: number) => void;
  readOnly?: boolean;
  data: any;
  onChangeOptionValue: (index: number, value: any) => void;
  correctAnswer: (data: any, index: number) => void;
}

export default function CheckboxLayout({
  removeChoice,
  readOnly,
  data,
  onChangeOptionValue,
  correctAnswer,
}: CheckboxType) {
  const { t } = useTranslation();
  return (
    // <Checkbox.Group disabled={false} style={{ width: "100%" }}>
    <>
      {data.options ? (
        data.options.map((field: any, index: number) => (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              width: "100%",
              minWidth: "509px",
            }}
            className={styles.checkboxLine}
          >
            <div
              key={index}
              style={{
                marginBottom: "8px",
                marginLeft: "4px",
                width: "75%",
                backgroundColor: "white",
                paddingLeft: "8px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                paddingTop: "2px",
                paddingBottom: "2px",
                minWidth: "474px",
              }}
            >
              <Checkbox
                type="checkbox"
                onChange={(data) => correctAnswer(data, index)}
                checked={field.correct}
                value={field.key}
                disabled={readOnly}
              ></Checkbox>
              <TextArea
                placeholder={
                  t("doc.models.edit.sections.blocks.choice") +
                  " " +
                  (index + 1)
                }
                autoSize
                bordered={false}
                style={{ width: "90%" }}
                value={field.value}
                onChange={(data) => onChangeOptionValue(index, data)}
                disabled={readOnly}
              />
            </div>
            {!readOnly && data.options.length > 2 ? (
              <Button
                type="link"
                onClick={() => removeChoice(index)}
                block
                icon={<AiOutlineMinusCircle />}
                style={{
                  width: "21px",
                  height: "21px",
                  marginBottom: "20px",
                  marginLeft: "10px",
                  color: "#030852",
                }}
                className={styles.removeButton}
              ></Button>
            ) : (
              <></>
            )}
          </div>
        ))
      ) : (
        <></>
      )}
    </>
    // </Checkbox.Group>
  );
}
