import { Auth, GlobalStore, useStoreState } from "@armtekfrance/webgate/dist";
import Request from "../../../libs/requests";

export const loginUser = async (email: string, password: string) => {
  return Request()
    .post("/auth/login", {
      email,
      password,
    })
    .then((response) => response.data);
};

export function getLoggedInUser(): {
  avatar: {
    url: string;
    media_content_uid: string;
  };
  first_name: string;
  id: number;
  last_name: string;
  permissions: number;
} {
  const [userStore] = useStoreState<Auth>(GlobalStore.auth);
  let user = {
    id: userStore?.user?.id as number,
    last_name: userStore?.user?.last_name as string,
    first_name: userStore?.user?.first_name as string,
    avatar: {
      url: userStore?.user?.avatar?.data?.url as string,
      media_content_uid: userStore?.user?.avatar?.data
        ?.media_content_uid as string,
    },
    permissions: userStore?.user?.permissions as number,
  };
  return user;
}
