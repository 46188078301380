import {
  fetchNotificationsTanStack,
  openNotificationTanStack,
} from "app/stores/notifications/notificationsClient";
import { notificationType } from "app/stores/notifications/notificationsType";
import {
  useHeader,
  useHeaderDispatch,
} from "components/Header/Provider/HeaderContext";
import NotificationModal from "pages/Notifications/Modal/NotificationModal";
import NotificationsFullList from "pages/Notifications/NotificationsFullList";
import {
  checkIfItHasALink,
  extractDynamicLinkIdFromNotALink,
  isProcedureOrExecution,
} from "pages/Notifications/NotificationsUtils";
import { useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import styles from "./NotificationsOrAlertsDropDown.module.scss";
import AlertsHero from "pages/Notifications/Hero/AlertsHero";
import NotificationHero from "pages/Notifications/Hero/NotificationHero";
import { useTranslation } from "react-i18next";
import { reformattedWebRedirection } from "pages/Notifications/NotificationsOrAlerts";

export default function NotificationsOrAlertsDropDown({
  typeOfNotidications,
}: {
  typeOfNotidications: "NOTIFICATIONS" | "ALERTS";
}) {
  const { t } = useTranslation();
  const headerContext = useHeader();
  const dispatchHeader = useHeaderDispatch();
  const mutation = openNotificationTanStack();
  const navigate = useNavigate();

  const {
    status: statusNotifications,
    error: errorNotifications,
    notifications: notifications,
  } = fetchNotificationsTanStack(
    1,
    100,
    {
      alert: typeOfNotidications === "ALERTS",
    },
    { createdAt: "desc" }
  );

  useEffect(() => {
    dispatchHeader({
      type: "setNotificationTanstackApi",
      setNotificationTanstackApi: {
        statusNotifications,
        errorNotifications,
      },
    });
    if (statusNotifications === "success") {
      dispatchHeader({
        type: "setNotificationTanstack",
        setNotificationTanstack: {
          notifications: notifications?.notifications,
          paginationNotifications: notifications?.pagination ?? {
            page: 1,
            pageSize: 10,
            total: -1,
          },
        },
      });
    }
  }, [statusNotifications, errorNotifications, notifications]);

  const handelNotificationOnClick = (notification: notificationType) => {
    mutation.mutate({
      id: notification.id,
      opened: true,
      receiverId: notification.receiver_id,
    });

    if (checkIfItHasALink(notification.web_redirection)) {
      navigate(reformattedWebRedirection(notification!.web_redirection!));
    } else {
      dispatchHeader({
        type: "setPreview",
        previewInfo: {
          isOpen: true,
          idOfPreview:
            notification.targets && notification.targets.length > 0
              ? notification
              : extractDynamicLinkIdFromNotALink(
                  notification!.web_redirection!
                ),
          typeOfPreview: isProcedureOrExecution(notification),
        },
      });
    }
  };
  const location = useLocation();

  if (
    !matchPath(
      location.pathname,
      `/account/${typeOfNotidications.toLocaleLowerCase()}`
    )
  )
    return (
      <div className={styles.notificationsOrAlertsDropDownWrapper}>
        {typeOfNotidications === "ALERTS" ? (
          <AlertsHero />
        ) : (
          <NotificationHero />
        )}
        {headerContext.statusNotifications === "success" && (
          <>
            <div className={styles.notificationsLists}>
              <NotificationsFullList
                notificationsByDay={headerContext.closedNotificationsByDay}
                typeOfNotidications="UNREAD"
                alertOrNotification={typeOfNotidications}
                notificationCount={
                  typeOfNotidications === "ALERTS"
                    ? headerContext.alertsCount
                      ? headerContext.alertsCount
                      : 0
                    : headerContext.notificationsCount
                    ? headerContext.notificationsCount
                    : 0
                }
                onClick={(notification) => {
                  handelNotificationOnClick(notification);
                }}
              />
              <NotificationsFullList
                notificationsByDay={headerContext.openedNotificationsByDay}
                typeOfNotidications="READ"
                alertOrNotification={typeOfNotidications}
                notificationCount={
                  typeOfNotidications === "ALERTS"
                    ? headerContext.alertsCount
                      ? headerContext.alertsCount
                      : 0
                    : headerContext.notificationsCount
                    ? headerContext.notificationsCount
                    : 0
                }
                onClick={(notification) => {
                  handelNotificationOnClick(notification);
                }}
              />
            </div>
            <NotificationModal
              isModalVisible={headerContext.previewInfo.isOpen}
              typeOfPreview={headerContext.previewInfo.typeOfPreview}
              documentId={headerContext.previewInfo.idOfPreview}
              onCancel={() => dispatchHeader({ type: "togglePreview" })}
            />
          </>
        )}
        <p
          onClick={() => {
            navigate(`/account/${typeOfNotidications.toLocaleLowerCase()}`);
          }}
        >
          {t("topHeader.seeMore")}
        </p>
      </div>
    );
  return <></>;
}
