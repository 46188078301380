import Request, {
  filterFormat,
  pageFormat,
  pageSizeFormat,
  queryClient,
  sortFormat,
} from "../../../libs/requests";
import { notificationType } from "./notificationsType";
import { PaginationType } from "../pagination/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const countNotificationsTanStack = (filters: {
  alert?: boolean;
  opened?: boolean;
}) => {
  const {
    status,
    error,
    data: notificationsCount,
  } = useQuery({
    queryKey: ["countNotifications", filters],
    queryFn: () => countNotifications(filters),
  });
  return { status, error, notificationsCount };
};

const countNotifications = async (
  filters: { alert?: boolean; opened?: boolean } = {}
) => {
  return Request()
    .get("/users/notifications/count", {
      params: {
        ...filterFormat(filters),
      },
    })
    .then((response) => {
      return response.data.data as number;
    });
};

export const fetchNotificationsTanStack = (
  page: number,
  pageSize: number,
  filters: { alert: boolean },
  sort: any = {}
) => {
  const {
    status,
    error,
    data: notifications,
  } = useQuery({
    queryKey: ["notifications", page, pageSize, filters, sort],
    queryFn: () => fetchNotifications(page, pageSize, filters, sort),
    staleTime: 1000 * 60,
  });
  return { status, error, notifications };
};

// TODO createdAt is not a valid input for sortFormat
// Sort should be typed and fit the existing SortFormatType
// Backend sould also fit SortFormatType
export const fetchNotifications = async (
  page: number,
  pageSize: number,
  filters: { alert: boolean },
  sort: any = {}
) => {
  return Request()
    .get("/users/notifications/", {
      params: {
        ...sortFormat(sort),
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
        ...filterFormat(filters),
      },
    })
    .then((response) => {
      return {
        notifications: response.data.data.notifications as notificationType[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const openNotificationTanStack = () => {
  return useMutation({
    mutationFn: ({
      id,
      opened,
      receiverId,
    }: {
      id: number;
      opened: boolean;
      receiverId: number;
    }) => {
      return openNotification(id, opened, receiverId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
      queryClient.invalidateQueries({ queryKey: ["countNotifications"] });
    },
  });
};

const openNotification = async (
  id: number,
  opened: boolean,
  receiverId: number
) => {
  return Request()
    .patch("/users/notifications/" + id, {
      opened: opened,
      receiver_id: receiverId,
    })
    .then((response) => response.data);
};

export const openAllNotificationsTanStack = () => {
  return useMutation({
    mutationFn: ({
      opened,
      receiverId,
      alert,
    }: {
      opened: boolean;
      receiverId: number;
      alert: boolean;
    }) => {
      return openAllNotification(opened, receiverId, alert);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
      queryClient.invalidateQueries({ queryKey: ["countNotifications"] });
    },
  });
};

// is this for all notifications and alerts?
const openAllNotification = async (
  opened: boolean,
  receiverId: number,
  alert: boolean
) => {
  return Request()
    .patch("/users/notifications/", {
      opened: opened,
      receiver_id: receiverId,
      alert: alert,
    })
    .then((response) => response.data);
};
