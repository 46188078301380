import { createContext, Dispatch, useContext } from "react";
import {
  stateObsoleteReleasesProvider,
  TypeObsoleteReleasesContextReducerAction,
} from "./ObsoleteReleasesReducer";

export const ObsoleteReleasesContext =
  createContext<stateObsoleteReleasesProvider>({
    statusBaselineValidations: "loading",
    errorBaselineValidations: null,
    baselineValidations: [],
    paginationBaselineValidations: { page: 1, pageSize: 10, total: -1 },
    queryNameTextFilter: "",
    qureyStartDate: "",
    queryEndDate: "",
    statusUsers: "loading",
    errorUsers: null,
    users: [],
    authors: [],
    validators: [],
  });

export const ObsoleteReleasesDispatchContext = createContext<
  Dispatch<TypeObsoleteReleasesContextReducerAction>
>(() => {
  return { type: "" };
});

export function useObsoleteReleases() {
  return useContext(ObsoleteReleasesContext);
}

export function useObsoleteReleasesDispatch() {
  return useContext(ObsoleteReleasesDispatchContext);
}
