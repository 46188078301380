import { Button, Dropdown, DropdownProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

interface FilterDropdownComponentProps {
  menuList: DropdownProps["overlay"];
  buttonName: string;
  buttonStyle?: any;
}

// TODO fix that depecrated overlay

export default function FilterDropdownComponent({
  menuList,
  buttonName,
  buttonStyle = {},
}: FilterDropdownComponentProps) {
  return (
    <Dropdown overlay={menuList} placement="bottomLeft" trigger={["click"]}>
      <Button style={buttonStyle}>
        <Space>
          {buttonName}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
