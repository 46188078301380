import { useTranslation } from "react-i18next";
import { Button } from "@armtekfrance/armtek-react-design-system";
import styles from "./LinkStyles.module.scss";
import { navigation } from "Router/NavigationService";

export default function DocumentsLink({
  docThisWeek,
}: {
  docThisWeek: number;
}) {
  const { t } = useTranslation();

  const displayedCount = docThisWeek > 99 ? "99+" : docThisWeek;

  return (
    <div className={styles.linkWrapper}>
      <Button
        child={
          <div className={styles.buttonWrapper}>
            <div className={styles.buttonStyle}>{displayedCount}</div>
            <span>{t("dashboard.documentThisWeek")}</span>
          </div>
        }
        onClick={() => {
          navigation.to("account/knowledge/models/list");
        }}
      />
    </div>
  );
}
