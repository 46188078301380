import { createContext, Dispatch, useContext } from "react";
import {
  stateAddTeamFormProvider,
  TypeAddTeamFormContextReducerAction,
} from "./AddTeamFormReducer";

export const AddTeamFormContext = createContext<stateAddTeamFormProvider>({
  selectedUsers: [],
  defaultSelectedUsers: [],
  teamLoading: false,
  formLoading: false,
  activeUserId: null,
  viewOnly: false,
  openCreateUserDrawer: false,
  searchText: "",
  tableDataList: [],
  tableDataLoading: false,
  tableDataListPagination: {
    page: 1,
    pageSize: 15,
    total: 0,
  },
  page: 1,
  pageSize: 10,
  isPopOverVisibleNickname: false,
  suggestedTeamNickname: "",
  isAddDeptOpen: false,
  companyDeptList: [],
  deptListLoading: false,
});

export const AddTeamFormDispatchContext = createContext<
  Dispatch<TypeAddTeamFormContextReducerAction>
>(() => {
  return { type: "" };
});

export function useAddTeamForm() {
  return useContext(AddTeamFormContext);
}

export function useAddTeamFormDispatch() {
  return useContext(AddTeamFormDispatchContext);
}
