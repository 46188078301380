import { useEffect, useState } from "react";
import {
  fetchDocumentCategories,
  createDocumentCategory,
  deleteDocCategory,
} from "../../app/stores/documentCategories/documentCategoriesClient";
import {
  createDocument,
  fetchDocumentByID,
  fetchDocuments,
} from "../../app/stores/documents/documentsClient";
import DroppableContainer from "../../pages/PlanLayout/DroppableContainer";
import DocDisplayContainer from "./DocDisplayContainer";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { updateDocument } from "../../app/stores/documents/documentsClient";
import { getLoggedInUser } from "../../app/stores/auth/authClient";
import { currentDateAndTime } from "../../util/currentDateUtil";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";

export default function PlanOutline() {
  const { t } = useTranslation();

  const pageSize = 20;
  const [categoryTree, setCategoryTree] = useState<any>([]);
  const [isCategoryTreePending, setIsCategoryTreePending] = useState(true);
  const userDetails = getLoggedInUser();

  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any | null>(null); // TODO I confirm - try to type it

  useEffect(() => {
    fetchDocumentCategories({ active: true }, 1, "0").then((response) => {
      if (response.success) {
        setCategoryTree(response.data.docCategories);
        setIsCategoryTreePending(false);
      }
    });
  }, [pageSize]);

  const onDeleteCategory = (category: any) => {
    deleteDocCategory(category.id).then((response) => {
      if (response.success) {
        if (category.parent_id) {
          const indexOfParentCategory = getParentCategoryIndex(
            category.parent_id
          );
          setCategoryTree((oldData: any[]) => {
            let dataSource = [...oldData];
            const filteredSubCategories = dataSource[
              indexOfParentCategory
            ].subCategories.filter(
              (item: any) => parseInt(item.id) !== parseInt(category.id)
            );
            dataSource[indexOfParentCategory].subCategories =
              filteredSubCategories;
            dataSource[indexOfParentCategory]._count.sub_categories -= 1;
            return dataSource;
          });
        } else {
          setCategoryTree((oldData: any[]) => {
            let dataSource = [...oldData];
            const filteredCategories = dataSource.filter(
              (item: any) => parseInt(item.id) !== parseInt(category.id)
            );
            return filteredCategories;
          });
        }
      }
    });
  };

  const onClickCollapse = (
    data: any,
    accepts: string,
    parentCategoryId: number | string
  ) => {
    if (data.length > 0) {
      if (accepts === "Subcategory") {
        //fetch the sub categories here
        const indexOfParentCategory = getParentCategoryIndex(data[0]);
        if (
          !categoryTree[indexOfParentCategory].subCategories ||
          categoryTree[indexOfParentCategory].subCategories.length === 0
        ) {
          getDocumentCategories(parseInt(data[0])).then((response) => {
            setCategoryTree((oldData: any[]) => {
              let dataSource = [...oldData];
              dataSource[indexOfParentCategory]["subCategories"] =
                response.docCategories;
              return dataSource;
            });
          });
        }
      } else if (accepts === "document") {
        const indexOfParentCategory = getParentCategoryIndex(parentCategoryId);
        const indexOfSubCategory = getSubCategoryIndex(
          parentCategoryId,
          data[0]
        );
        if (
          !categoryTree[indexOfParentCategory].subCategories[indexOfSubCategory]
            .documents
        ) {
          getDocuments(data[0]).then((response) => {
            setCategoryTree((oldData: any[]) => {
              let dataSource = [...oldData];
              dataSource[indexOfParentCategory]["subCategories"][
                indexOfSubCategory
              ]["documents"] = response.documentsModels;
              return dataSource;
            });
          });
        }
      }
    }
  };

  const getDocumentCategories = async (id: number) => {
    const docCategories = await fetchDocumentCategories(
      {
        parent_id: id,
        active: true,
      },
      1,
      "0"
    );
    return docCategories.data;
  };

  const getDocuments = async (categoryId: number | string) => {
    return await fetchDocuments(1, 100, {
      doc_category_id: categoryId,
    });
  };

  const handleDrop = (
    item: any,
    categoryId?: number | string,
    subCategoryId?: number | string
  ) => {
    let data: any = {};
    switch (item.type) {
      case "Category":
        data["category"] = "New category";
        createFunctionDocCategory(data).then((retId) => {
          setCategoryTree((oldData: any[]) => {
            let dataSource = [...oldData];
            const newCategory = {
              id: retId,
              category_name: "New category",
              parent_id: null,
              _count: {
                document_model: 0,
                sub_categories: 0,
              },
            };
            dataSource.push(newCategory);
            return dataSource;
          });
        });
        break;
      case "Subcategory":
        data = {
          category: "New subcategory",
          parent_id: categoryId,
        };
        createFunctionDocCategory(data).then((retId) => {
          const indexOfParentCategory = getParentCategoryIndex(categoryId);
          setCategoryTree((oldData: any[]) => {
            let dataSource = [...oldData];
            const newSubCategory = {
              id: retId,
              category_name: "New subcategory",
              parent_id: categoryId,
              _count: {
                document_model: 0,
                sub_categories: 0,
              },
            };
            if (dataSource[indexOfParentCategory].subCategories) {
              dataSource[indexOfParentCategory].subCategories.push(
                newSubCategory
              );
            } else {
              dataSource[indexOfParentCategory]["subCategories"] = [];
              dataSource[indexOfParentCategory].subCategories.push(
                newSubCategory
              );
            }
            dataSource[indexOfParentCategory]._count.sub_categories += 1;
            return dataSource;
          });
        });
        break;
      case "document":
        if (
          item.action === "update" &&
          JSON.stringify(subCategoryId) ===
            JSON.stringify(item.currentSubCategory.id)
        ) {
          return;
        } else {
          createDocumentFunction(
            subCategoryId,
            item.itemValue,
            item.action,
            item.docId,
            item?.family
          ).then((createdDocument) => {
            const parentIndex = getParentCategoryIndex(categoryId);
            const subCategoryIndex = getSubCategoryIndex(
              categoryId,
              subCategoryId
            );

            setCategoryTree((oldData: any[]) => {
              let dataSource = [...oldData];
              if (
                dataSource[parentIndex].subCategories[subCategoryIndex]
                  .documents
              ) {
                dataSource[parentIndex].subCategories[
                  subCategoryIndex
                ].documents.push(createdDocument);
              } else {
                dataSource[parentIndex].subCategories[subCategoryIndex][
                  "documents"
                ] = [];
                dataSource[parentIndex].subCategories[
                  subCategoryIndex
                ].documents.push(createdDocument);
              }
              dataSource[parentIndex].subCategories[
                subCategoryIndex
              ]._count.document_model += 1;

              if (item.action === "update") {
                const indexOfParentCategory = getParentCategoryIndex(
                  item.currentSubCategory.parent_id
                );
                const indexOfSubCategory = getSubCategoryIndex(
                  item.currentSubCategory.parent_id,
                  item.currentSubCategory.id
                );
                dataSource[indexOfParentCategory].subCategories[
                  indexOfSubCategory
                ].documents = dataSource[indexOfParentCategory].subCategories[
                  indexOfSubCategory
                ].documents.filter(
                  (elem: any) =>
                    JSON.stringify(elem.id) !== JSON.stringify(item.docId)
                );
                dataSource[indexOfParentCategory].subCategories[
                  indexOfSubCategory
                ]._count.document_model -= 1;
              }
              return dataSource;
            });
          });
        }
        break;
      default:
        break;
    }
  };

  const createFunctionDocCategory = async (data: any) => {
    const createdCategory = await createDocumentCategory(data);
    return createdCategory.data.id;
  };

  const createDocumentFunction = async (
    categoryId: any,
    docType: string,
    action: string,
    docId: string,
    family: "KNOWLEDGE" | "SYSTEM"
  ) => {
    const currDate = currentDateAndTime();
    const docToCreate = {
      name: `New ${docType} ${userDetails?.first_name} ${currDate}`,
      type: docType,
      category_id: parseInt(categoryId),
      family: family,
    };
    let documentToFetch: any;
    if (action === "update") {
      const dataToUpdate = {
        doc_category_id: categoryId,
      };
      documentToFetch = await updateDocument(docId, dataToUpdate);
    } else {
      documentToFetch = await createDocument(docToCreate);
    }
    const newDoc = await fetchDocumentByID(documentToFetch.data.id);
    return newDoc.data;
  };

  const getParentCategoryIndex = (parentId: any) => {
    const indexOfParentCategory = categoryTree
      .map((object: any) => parseInt(object.id))
      .indexOf(parseInt(parentId));
    return indexOfParentCategory;
  };

  const getSubCategoryIndex = (parentId: any, subCategoryId: any) => {
    const parentIndex = getParentCategoryIndex(parentId);
    const indexOfSubCategory = categoryTree[parentIndex].subCategories
      .map((object: any) => parseInt(object.id))
      .indexOf(parseInt(subCategoryId));
    return indexOfSubCategory;
  };

  return (
    <>
      <p className={style.outlineHeader}>
        {t("doc.plan.outline.currentOutline")}
      </p>
      {/* Main container for category */}
      {isCategoryTreePending ? (
        <div></div>
      ) : (
        <div
          className="scrollable-container"
          style={{
            alignContent: "center",
            marginLeft: "4px",
            marginRight: "4px",
          }}
        >
          <div
            style={{
              height: "80vh",
              overflow: "auto",
              textAlign: "justify",
            }}
          >
            {/* Main container for category */}
            <DroppableContainer
              accept={"Category"}
              containerCategory={{}}
              onDrop={(item: any) => handleDrop(item)}
              onClickCollapse={onClickCollapse}
              containerStyle={{
                minHeight: "100vh",
                paddingTop: "8px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
              headingStyle={{}}
              isCollapse={false}
              showConfirm={(category: any) => {
                setIsArchiveModalVisible(true);
                setCategoryToDelete(category);
              }}
              key={"mainContainer"}
            >
              {/* loop here over the categories */}
              {categoryTree.map((parentCategory: any) => (
                <DroppableContainer
                  accept={"Subcategory"}
                  containerCategory={parentCategory}
                  onDrop={(item: any) => handleDrop(item, parentCategory.id)}
                  onClickCollapse={onClickCollapse}
                  containerStyle={{
                    minHeight: "50px",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingTop: "1rem",
                  }}
                  headingStyle={{
                    height: "28px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                  isCollapse={true}
                  showConfirm={(category: any) => {
                    setIsArchiveModalVisible(true);
                    setCategoryToDelete(category);
                  }}
                  key={parentCategory.id + "container"}
                >
                  {/* loop over subcategories */}
                  <div>
                    {parentCategory.subCategories?.map((subCat: any) => (
                      <DroppableContainer
                        accept={"document"}
                        containerCategory={subCat}
                        onDrop={(item: any) =>
                          handleDrop(item, parentCategory.id, subCat.id)
                        }
                        onClickCollapse={onClickCollapse}
                        containerStyle={{
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                        }}
                        headingStyle={{
                          height: "28px",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                        isCollapse={true}
                        showConfirm={(category: any) => {
                          setIsArchiveModalVisible(true);
                          setCategoryToDelete(category);
                        }}
                        key={subCat.id + "container"}
                      >
                        {/* simple display the documents */}
                        <div>
                          {subCat.documents?.map((doc: any) => {
                            return (
                              <DocDisplayContainer
                                doc={doc}
                                setCategoryTree={setCategoryTree}
                                subCategory={subCat}
                              />
                            );
                          })}
                        </div>
                      </DroppableContainer>
                    ))}
                  </div>
                </DroppableContainer>
              ))}
            </DroppableContainer>
          </div>
        </div>
      )}
      <ConfirmActionModal
        isVisible={isArchiveModalVisible}
        handleCancel={() => setIsArchiveModalVisible(false)}
        handleConfirm={() => {
          if (categoryToDelete) {
            onDeleteCategory(categoryToDelete);
          }
          setCategoryToDelete(null);
          setIsArchiveModalVisible(false);
        }}
        confirmInsideText={t("planOutline.deleteCategory")}
        confirmHeaderText={t("archived.title")}
      />
    </>
  );
}
