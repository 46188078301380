import axios, { AxiosInstance } from "axios";
import { QueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ENV, GlobalStore, OpenAPI } from "@armtekfrance/webgate/dist";

// TODO
// Maybe we can be more specific than string ( ex : "desc"  |  "asc"  | etc... ) ?
export interface SortFormatType {
  created_at?: string;
  updated_at?: string;
  published_at?: string;
  position?: string;
  id?: string;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const sortFormat = (sort: SortFormatType) => {
  return Object.entries(sort).reduce((acc: any, [key, value]) => {
    acc[`sort[${key}]`] = value;
    return acc;
  }, {});
};

export const optionFormat = (option: any) => {
  return option;
};

export const filterFormat = (filter: any) => {
  return Object.entries(filter).reduce((acc: any, [key, value]) => {
    if (value !== null && typeof value === "object") {
      const innerValue: any = { ...value };
      for (let innerKey in innerValue) {
        acc[`filters[${key}][${innerKey}]`] = innerValue[innerKey];
      }
    } else {
      acc[`filters[${key}]`] = value;
    }
    return acc;
  }, {});
};

export const pageFormat = (page: number) => {
  return { page };
};

export const pageSizeFormat = (pageSize: number | string) => {
  return { pageSize };
};

export const showToast = (
  title: string,
  message: string,
  type: "success" | "error" | "info" | "warning",
  autoClose = 3000
) => {
  toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: autoClose,
    closeButton: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const createAxiosInstance = (multipartForm?: boolean): AxiosInstance => {
  let accessToken = GlobalStore?.auth?.tokens?.accessToken;
  let reqHeaders: { [key: string]: string } = {
    Authorization: `Bearer ${accessToken}`,
  };

  if (multipartForm) {
    reqHeaders["Content-Type"] = "multipart/form-data";
  }

  const axiosInstance = axios.create({
    // Replace for quick fix the dev url
    baseURL: OpenAPI.BASE.replace("/api/v2", "") + "/api/v1",
    headers: reqHeaders,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      // Replace for quick fix the dev url
      config.baseURL = OpenAPI.BASE.replace("/api/v2", "") + "/api/v1";
      accessToken = GlobalStore.auth.tokens?.accessToken;
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (ENV.ARMTEK_ENV === "staging" || ENV.ARMTEK_ENV === "dev") {
          const responseData = error.response.data;
          if (responseData.message) {
            // Extract the custom error message from the API response
            const errorMessage = responseData.message;
            // Show the custom error message in the toaster notification
            showToast("Error", errorMessage, "error");
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

const Request = (multipartForm?: boolean): AxiosInstance => {
  return createAxiosInstance(multipartForm);
};

export default Request;
