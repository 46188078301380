import { SizeType } from "@armtekfrance/armtek-react-design-system";
import { ApiDashboardSearchRequest } from "@armtekfrance/corenexus-pn-sn";
import {
  FilterMaster,
  FilterManager,
  Layout,
  ExpandableDashboardList,
} from "@armtekfrance/webgate/dist";
import styles from "./AdvancedAnalysisComponent.module.scss";

interface AdvancedAnalysisComponentProps {
  filterManager: FilterManager;
}

export default function AdvancedAnalysisComponent({
  filterManager,
}: AdvancedAnalysisComponentProps) {
  // const [filterExecution] = useStoreState(
  //   GlobalStore.filter.storeFilters.executionFilter
  // );
  // const [filterDashboard] = useStoreState(
  //   GlobalStore.filter.storeFilters.DashboardBaselineFilter
  // );
  // const [filterUser] = useStoreState(
  //   GlobalStore.filter.storeFilters.userFilter
  // );

  const filter: ApiDashboardSearchRequest = {
    filter: "ALL",
    config: {
      extend: true,
    },
  };

  return (
    <Layout
      header={
        <div className={styles.filterMasterContainer}>
          <FilterMaster
            filterManager={filterManager}
            fetchData={() => {
              //console.log("debug");
            }}
          />
        </div>
      }
      content={
        <div className={styles.advancedAnalysisWrapper}>
          <div className={styles.expandableDashboardListContainer}>
            <ExpandableDashboardList
              object="ApiDashboard"
              emitFilter={filter}
              onCheck={() => console.log("onCheck")}
              disabled={false}
              size={SizeType.small}
              slim={true}
            />
          </div>
        </div>
      }
    />
  );
}
