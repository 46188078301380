import { useReducer } from "react";
import { executionFluxReducer } from "./ExecutionFluxReducer";
import {
  ExecutionFluxContext,
  ExecutionFluxDispatchContext,
} from "./ExecutionFluxContext";

export default function ExecutionFluxProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [executionFlux, dispatchExecutionFlux] = useReducer(
    executionFluxReducer,
    {
      statusDocumentsModels: "loading",
      errorDocumentsModels: null,
      documentsModels: [],
      paginationDocumentModels: { page: 1, pageSize: 10, total: -1 },

      statusBaselineExecutions: "loading",
      errorBaselineExecutions: null,
      baselineExecutions: [],
      paginationBaselineExecutions: { page: 1, pageSize: 10, total: -1 },

      queryNameTextFilter: "",
      queryDateSelectedFilter: { gte: "", lte: "" },
      queryModelSelectedFilter: [],
    }
  );

  return (
    <ExecutionFluxContext.Provider value={executionFlux}>
      <ExecutionFluxDispatchContext.Provider value={dispatchExecutionFlux}>
        {children}
      </ExecutionFluxDispatchContext.Provider>
    </ExecutionFluxContext.Provider>
  );
}
