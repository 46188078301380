import React from "react";
import { createRoot } from "react-dom/client";
import "@armtekfrance/armtek-react-design-system/dist/style.css";
import "@armtekfrance/webgate/dist/style.css";
import * as Sentry from "@sentry/react";
import App from "./App";
import store from "./app/stores/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import {
  DEV,
  ENV,
  OpenAPI,
  ToastWrapper,
  webGate,
} from "@armtekfrance/webgate/dist";

// API V2
OpenAPI.AXIOS = axios; /// Set the axios instance for webgate !important
OpenAPI.BASE = DEV.tools.api_url;
console.log("OpenAPI.BASE :", OpenAPI.BASE);
webGate.init(); /// need to init API asap before anyone else uses Manager.API

const container = document.getElementById("root");
const root = createRoot(container!);

if (
  ENV.COLLECT_LOG === "true" &&
  (ENV.ARMTEK_ENV === "staging" || ENV.ARMTEK_ENV === "prod")
) {
  Sentry.init({
    dsn: "https://05f8b69bca73cfbb2a9912aa4bf95296@o4506060871499776.ingest.sentry.io/4506062479294464",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [ENV.FRONT_WEB_URL ?? "undefined"],
      }),
      new Sentry.Replay(),
    ],
    release: ENV.ARMTEK_PROD_VERSION,
    environment: ENV.ARMTEK_ENV,
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(
  <>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
    <ToastWrapper></ToastWrapper>
  </>
);
