import ReactPlayer from "react-player";
import styles from "./MediaAudioItemWrapper.module.scss";
import { useRef, useState } from "react";
import { ReactComponent as AudioPlay } from "../TempDS/assets/AudioPlay.svg";
import { ReactComponent as AudioPause } from "../TempDS/assets/AudioPause.svg";
import { convertSecondsToTime } from "util/duration";
import { Checkbox } from "antd";
export default function MediaAudioItemWrapper({
  source,
  fileName,
  checked,
  onCheckedChange,
}: {
  source: string;
  fileName: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}) {
  const reactPlayerRef = useRef(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  return (
    // TODO Refacto with MediaSelectorAudio
    <div
      className={styles.mediaAudioItemWrapperWrapper}
      aria-selected={checked}
    >
      <p className={styles.fileName}>{fileName}</p>
      <div className={styles.control} onClick={() => setPlaying(!playing)}>
        {playing && <AudioPause />}
        {!playing && <AudioPlay />}
        <p>
          {`${convertSecondsToTime(Math.floor(played))} /
        ${convertSecondsToTime(Math.floor(duration))}`}
        </p>
      </div>
      <Checkbox
        className={styles.checkBox}
        aria-checked={checked}
        checked={checked}
        onChange={(e) => {
          onCheckedChange(e.target.checked);
        }}
      />
      <ReactPlayer
        ref={reactPlayerRef}
        onProgress={(state) => {
          setPlayed(state.playedSeconds);
        }}
        width="0%"
        onDuration={setDuration}
        url={source}
        playing={playing}
        onEnded={() => setPlaying(false)}
        controls={false}
      />
    </div>
  );
}
