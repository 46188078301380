import { fetchUserTeams } from "app/services/team/teamClient";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function DisplayUserTeamsByUserId({ id }: { id: number }) {
  const { t } = useTranslation();
  const [userTeams, setUserTeams] = useState<any[]>([]);

  useEffect(() => {
    fetchUserTeams(id).then((teams) => {
      setUserTeams(teams);
    });
  }, [id]);

  if (userTeams.length > 0) {
    return (
      <ul style={{ paddingLeft: "14px", marginBottom: "0px" }}>
        {userTeams.map((team) => {
          return <li>{team.name}</li>;
        })}
      </ul>
    );
  } else {
    return <p>{t("companyUserInfo.noTeam")}</p>;
  }
}
