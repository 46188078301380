import styles from "./MenuTabItem.module.scss";

export default function MenuTabItem({
  label,
  selected,
  onClick,
}: {
  label: string;
  selected: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={styles.menuTabItemWrapper}
      aria-selected={selected}
      onClick={onClick}
    >
      <p>{label}</p>
    </div>
  );
}
