import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Switch,
} from "antd";
import QrCodeComponent from "../QrCodeComponent/QrCodeComponent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AiOutlineBlock,
  AiOutlineDelete,
  AiOutlineQrcode,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { duplicateDocument } from "../../app/stores/documents/documentsClient";
import { updateManyDocumentsAsync } from "../../app/stores/documents/documentsSlice";
import ProcedurePreview from "../ProcedurePreview";
import { useAppDispatch } from "../../app/hooks";
import PublishModalComponent from "./PublishModalComponent";
import {
  CaretLeftOutlined,
  LoadingOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";
import { DropDownNew } from "components/DropDown/DropDownNew";
import DropDownList, {
  DropDownListItem,
} from "components/DropDown/DropDownList";
import { Button as ArmtekButton } from "@armtekfrance/armtek-react-design-system";
import NotificationPreferences from "components/NotificationParametrization/NotificationParametrization";
import {
  fetchModelNotificationPreferences,
  updateModelNotificationPreferences,
  deleteModelNotificationPreferences,
} from "app/services/modelPreferences";
import { navigation } from "Router/NavigationService";

const { confirm } = Modal;
interface ModelInfoHeaderData {
  page: "info" | "edit";
  id: any;
  currentDocument: any;
  generatePDFDocument: () => void;
  qrData: any;
  assignQRCode?: () => void;
  unassignQRCode?: () => void;
  onChangeDocumentDataProps: (data: any) => any;
  isContentBeingSaved: boolean;
}

export default function ModelInfoHeader({
  page,
  id,
  currentDocument,
  qrData,
  assignQRCode,
  unassignQRCode,
  onChangeDocumentDataProps,
  isContentBeingSaved,
}: ModelInfoHeaderData) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);
  const [showEventModalVisible, setShowEventModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showPreviewModal = () => {
    setIsModalVisible(true);
  };
  const handlePreviewCancel = () => {
    setIsModalVisible(false);
  };

  const [isArchiveModalVisible, setArchiveIsModalVisible] = useState(false);
  const showArchiveModal = () => {
    setArchiveIsModalVisible(true);
  };
  const handleArchiveOk = () => {
    onArchiveDocument();
    setArchiveIsModalVisible(false);
  };
  const handleArchiveCancel = () => {
    setArchiveIsModalVisible(false);
  };
  const onArchiveDocument = () => {
    const ids = [];
    ids.push(id ? parseInt(id) : id);
    const activeFlag = false;
    dispatch(updateManyDocumentsAsync({ ids, activeFlag }));
    navigation.to("account/knowledge/models/list");
  };

  const [eventsIsUserPreferred, setEventsIsUserPreferred] = useState(true);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
  const showDuplicateModal = () => {
    setIsDuplicateModalVisible(true);
  };
  const handleDuplicateOk = () => {
    onClickDuplicateDocument();
    // setIsDuplicateModalVisible(false);
  };
  const handleDuplicateCancel = () => {
    setIsDuplicateModalVisible(false);
  };
  const onClickDuplicateDocument = () => {
    setDuplicateLoading(true);
    duplicateDocument(id).then((response) => {
      if (response.success) {
        message.success(t("sucess.duplicateSucessfully"), 5);
        setIsDuplicateModalVisible(false);
        setDuplicateLoading(false);
        navigate(`/account/knowledge/models/info/${response.data.id}`);
      } else {
        message.error(t("error.problemDuplicate"), 5);
      }
    });
  };
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const executeActionOnDropdownItemClick = (event: DropDownListItem) => {
    if (event) {
      switch (event.key) {
        case "archiveDocument":
          showArchiveModal();
          break;
        case "duplicate":
          showDuplicateModal();
          break;
      }
    }
  };

  const showDeleteQRConfirm = () => {
    confirm({
      title: t("qrCode.confirmDeleteQR"),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        unassignQRCode && unassignQRCode();
      },
      cancelText: t("button.cancel"),
    });
  };

  const getDropdownItemList = () => {
    let tempDropdownElemsList: DropDownListItem[] = [];

    if (currentDocument.active) {
      tempDropdownElemsList.push({
        key: "archiveDocument",
        label: t("doc.models.edit.generalInformation.archive"),
        icon: <AiOutlineDelete />,
      });
    }
    tempDropdownElemsList.push({
      key: "duplicate",
      label: t("doc.models.edit.generalInformation.duplicate"),
      icon: <AiOutlineBlock />,
    });
    return tempDropdownElemsList;
  };

  const onFetchEventPreferenceSwitch = async (id: number) => {
    try {
      setEventsLoading(true);
      const preferenceData = await fetchModelNotificationPreferences(id);
      setEventsIsUserPreferred(
        preferenceData.data.reduce(
          (acc: boolean, eventPreference: { inherited: boolean }) => {
            return acc && eventPreference.inherited;
          },
          true
        )
      );
      return preferenceData;
    } catch (error) {
      console.log(error);
    } finally {
      setEventsLoading(false);
    }
  };

  const onUpdateEventPreferenceSwitch = async (
    id: number,
    preferences: any
  ) => {
    try {
      setEventsLoading(true);
      const preferenceData = await updateModelNotificationPreferences(
        id,
        preferences
      );
      return preferenceData;
    } catch (error) {
      console.log(error);
    } finally {
      setEventsLoading(false);
    }
  };

  // Switch to trigger the reset of model preferences back to user preferences.
  const onEventPreferenceSwitch = async (checked: boolean) => {
    if (checked) {
      try {
        setEventsLoading(true);
        await deleteModelNotificationPreferences(id);
        setEventsIsUserPreferred(checked);
        message.success(t("notificationParametrization.resetSuccessful"));
        setShowEventModalVisible(false);
      } catch (error) {
        console.log(error);
      } finally {
        setEventsLoading(false);
      }
    } else {
      setEventsIsUserPreferred(checked);
    }
  };

  return (
    <>
      <Row style={{ marginTop: "8px" }}>
        <Col flex="auto" style={{ zIndex: "1" }}>
          <Space size="small" align="center">
            <Link to={`/account/knowledge/models/edit/${id}`}>
              <CaretLeftOutlined
                style={{ color: "#030852", cursor: "pointer" }}
              />
            </Link>
            {page === "edit" ? (
              <>
                <Popover
                  content={
                    qrData && qrData.uuid ? (
                      <Space direction="vertical">
                        <QrCodeComponent qrCode={qrData} />
                        <Button onClick={showDeleteQRConfirm}>
                          {t("qrCode.deleteQR")}
                        </Button>
                      </Space>
                    ) : (
                      <>
                        <p>{t("qrCode.noQrAssigned")}</p>
                        {unassignQRCode && (
                          <Button onClick={assignQRCode}>
                            {t("qrCode.assignQR")}
                          </Button>
                        )}
                      </>
                    )
                  }
                  trigger="click"
                >
                  <AiOutlineQrcode size={32} style={{ cursor: "pointer" }} />
                </Popover>
              </>
            ) : (
              <></>
            )}
            <Input
              placeholder={t("modelInfoHeader.documentName")}
              bordered={false}
              style={{
                width: "50vw",
                height: 40,
                // paddingLeft: 0,
                fontSize: "18px",
                fontWeight: 600,
                background: "#F6F9FB",
              }}
              id="docName"
              defaultValue={currentDocument.name}
              maxLength={255}
              onChange={(e: any) =>
                onChangeDocumentDataProps({ name: e.target.value })
              }
              showCount={true}
            />

            <Spin
              spinning={true}
              indicator={
                isContentBeingSaved ? (
                  <LoadingOutlined style={{ fontSize: "24px" }} />
                ) : (
                  <CheckOutlined />
                )
              }
            />
          </Space>
        </Col>
        <Col style={{ paddingTop: "4px", zIndex: "1" }}>
          <Space>
            {page === "info" ? (
              <Link to={`/account/knowledge/models/edit/${id}`}>
                <Button
                  type="primary"
                  data-cy="Model-EditButton"
                  key="editModel"
                  disabled={isContentBeingSaved}
                >
                  {t("doc.models.edit.tabPanel.edit")}
                </Button>
              </Link>
            ) : (
              <Link to={`/account/knowledge/models/info/${id}`}>
                <Button
                  type="primary"
                  key="editModel"
                  disabled={isContentBeingSaved}
                >
                  {t("doc.models.edit.tabPanel.goToModelInfo")}
                </Button>
              </Link>
            )}
            <>
              <Button
                type="primary"
                onClick={showPreviewModal}
                disabled={isContentBeingSaved}
                key="preview"
              >
                {t("doc.models.edit.tabPanel.preview")}
              </Button>
              <ProcedurePreview
                isModalVisible={isModalVisible}
                handleCancel={handlePreviewCancel}
                documentId={id}
              />
            </>
            <>
              <Button
                type="primary"
                // icon={<AiOutlineSend />}
                key="publish"
                disabled={isContentBeingSaved}
                onClick={() => setIsPublishModalVisible(true)}
                data-cy="Models-ButtonPublish"
              >
                {t("doc.models.edit.tabPanel.publish")}
              </Button>
              {
                <Button
                  type="primary"
                  key="events"
                  disabled={isContentBeingSaved}
                  onClick={() => setShowEventModalVisible(true)}
                >
                  {t("notificationParametrization.preference")}
                </Button>
              }
              <PublishModalComponent
                isPublishModalVisible={isPublishModalVisible}
                setIsPublishModalVisible={setIsPublishModalVisible}
                id={id}
              />
            </>
            <div>
              <DropDownNew
                placement="bottomRight"
                dropdownContent={
                  <DropDownList
                    listItems={getDropdownItemList()}
                    onClickItem={executeActionOnDropdownItemClick}
                  />
                }
                definedWidth="auto"
              >
                <ArmtekButton child="..." />
              </DropDownNew>
            </div>
          </Space>
        </Col>
      </Row>
      {currentDocument.active && (
        <ConfirmActionModal
          isVisible={isArchiveModalVisible}
          handleCancel={handleArchiveCancel}
          handleConfirm={handleArchiveOk}
          confirmInsideText={t("archived.text")}
          confirmHeaderText={t("archived.title")}
        />
      )}
      <Modal
        title={t("doc.models.edit.generalInformation.confirmModelDuplicate")}
        open={isDuplicateModalVisible}
        onOk={handleDuplicateOk}
        onCancel={handleDuplicateCancel}
      >
        {!duplicateLoading ? (
          <p>{t("doc.models.edit.generalInformation.sureToDuplicate")}</p>
        ) : (
          <Row>
            <Col span={6}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
              />
            </Col>
            <Col span={18}>
              <p style={{ fontWeight: "600", fontSize: "16", margin: "0px" }}>
                {t("doc.models.edit.generalInformation.modelDuplicating")}{" "}
              </p>
              <p style={{ fontWeight: "600", fontSize: "16" }}>
                {t("doc.models.edit.generalInformation.doNotRefresh")}
              </p>
            </Col>
          </Row>
        )}
      </Modal>
      <Modal
        title={
          <h3 className="m-0">{t("notificationParametrization.preference")}</h3>
        }
        open={showEventModalVisible}
        width={900}
        footer={false}
        onCancel={() => setShowEventModalVisible(false)}
        destroyOnClose
      >
        <div style={{ textAlign: "end" }}>
          <strong>Reset to user preferences?</strong>
          <Switch
            checked={eventsIsUserPreferred}
            onChange={onEventPreferenceSwitch}
            loading={eventsLoading}
          />
        </div>
        <div>
          <NotificationPreferences
            onSave={(data) => onUpdateEventPreferenceSwitch(id, data)}
            fetchData={() => onFetchEventPreferenceSwitch(id)}
            active={!eventsIsUserPreferred}
          />
        </div>
      </Modal>
    </>
  );
}
