import styles from "./MediaPickerMediasArticle.module.scss";
import { fetchMediaContentTanStackInfinite } from "app/stores/content/contentClient";
import MediaPickerMediasGrid from "./MediaPickerMediasGrid";
import MediaPickerModalMediaArticleHeader from "./MediaPickerMediasArticleHeader";
import { Spin } from "antd";
import MediaPickerModalMediasGridFooter from "./MediaPickerModalMediasGridFooter";
import { Manager } from "@felixkletti/managementjs";
import {
  GlobalStore,
  MediaAction,
  useStoreState,
} from "@armtekfrance/webgate/dist";

export default function MediaPickerMediasArticle() {
  useStoreState(GlobalStore.media.getFilters());
  useStoreState(GlobalStore.media.getMediaPickerModalStatus());
  useStoreState(GlobalStore.media.getSelectedMedias());
  // useStoreState(GlobalStore.media.getLinkContentToBlockTanstack());

  const {
    mediaContents,
    status,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = fetchMediaContentTanStackInfinite(16, GlobalStore.media.getFilters(), {
    id: "desc",
  });

  const closeMediaPickerModal = () => {
    Manager.trigger(MediaAction.OPEN_MEDIA_PICKER, false);
  };

  return (
    <article className={styles.mediaPickerMediasArticleWrapper}>
      <MediaPickerModalMediaArticleHeader />
      {status === "success" && (
        <MediaPickerMediasGrid
          mediaContents={mediaContents}
          onFetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
        />
      )}
      {status === "loading" && <Spin />}
      <MediaPickerModalMediasGridFooter
        onAdd={() => {
          console.log(
            "ALEX::DEBUG::mediaContents",
            GlobalStore.media.getSelectedMedias()
          );
          Manager.trigger(MediaAction.MEDIA_SELECTION_CONFIRMED, {
            mediaContents: GlobalStore.media
              .getSelectedMedias()
              .filter((media) => {
                return (
                  media.type ===
                  GlobalStore.media.getMediaPickerModalStatus().block_type
                );
              }),
            block_id: GlobalStore.media.getMediaPickerModalStatus().block_id,
            source: GlobalStore.media.getMediaUploadSource(),
          });
          closeMediaPickerModal();
        }}
        isLoading={
          GlobalStore.media.getLinkContentToBlockTanstack()?.isLoading ?? false
        }
        addDisabled={
          GlobalStore.media.getSelectedMedias().filter((media) => {
            return (
              media.type ===
              GlobalStore.media.getMediaPickerModalStatus().block_type
            );
          }).length === 0
        }
        onCancel={() => {
          closeMediaPickerModal();
        }}
      />
    </article>
  );
}
