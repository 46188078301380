import { Col, FormInstance, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useAddTeamForm } from "../Provider/AddTeamFormContext";
import { Button } from "@armtekfrance/armtek-react-design-system";
import { RefObject } from "react";
import { ButtonType } from "@armtekfrance/armtek-react-design-system";

interface AddTeamFormButtonsProps {
  openTeamForm: (open: boolean, teamId?: number | null) => Promise<void>;
  onCancel?: () => void;
  formRef: RefObject<FormInstance<any>>;
}

export default function AddTeamFormButtons({
  openTeamForm,
  onCancel,
  formRef,
}: AddTeamFormButtonsProps) {
  const { t } = useTranslation();

  const addTeamFormContext = useAddTeamForm();

  const handleFormSubmit = () => {
    const form = formRef.current;

    if (form) {
      form.submit();
    }
  };

  return (
    <Col span={24}>
      <div className="d-flex justify-content-end">
        <Space>
          <Button
            type={ButtonType.primary}
            loading={addTeamFormContext.formLoading}
            onClick={handleFormSubmit}
            child={
              <p style={{ margin: 0 }}>
                {addTeamFormContext.formLoading
                  ? t("button.saving")
                  : t("button.save")}
              </p>
            }
          />
          <Button
            onClick={() => {
              openTeamForm(false);
              if (onCancel) {
                onCancel();
              }
            }}
            child={<p style={{ margin: 0 }}>{t("button.cancel")}</p>}
          />
        </Space>
      </div>
    </Col>
  );
}
