import InputAudioBlock from "./InputAudioBlock";
import InputCheckboxBlock from "./InputCheckboxBlock";
import InputImageBlock from "./InputImageBlock";
import InputQuizBlock from "./InputQuizBlock";
import InputRadioboxBlock from "./InputRadioboxBlock";
import InputTextBlock from "./InputTextBlock";
import InputVideoBlock from "./InputVideoBlock";
import SignatureBlock from "./SignatureBlock";
import DataBlock from "./dataBlock";
import { BlockTypeConst_NEW } from "../../app/stores/blocks/types";
import SelectItemBlock from "./SelectItemBlock";
import { mediasOfRequestedDatas } from "../ExecutionTable/CsvExecution";

export default function ExecutionFluxBlocks(block_baselines: any) {
  return (
    <>
      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.input_checkbox && (
        <InputCheckboxBlock
          title={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content.title
          }
          options={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content
              .options
          }
          userInput={block_baselines.block_baseline.executed[0].requested_data}
          alertOccured={
            block_baselines.block_baseline.executed[0].alert_occured
          }
        ></InputCheckboxBlock>
      )}
      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.quiz && (
        <InputQuizBlock
          title={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content.title
          }
          options={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content
              .options
          }
          userInput={block_baselines.block_baseline.executed[0].requested_data}
          alertOccured={
            block_baselines.block_baseline.executed[0].alert_occured
          }
        ></InputQuizBlock>
      )}
      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.input_radio && (
        <InputRadioboxBlock
          title={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content.title
          }
          options={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content
              .options
          }
          userInput={block_baselines.block_baseline.executed[0].requested_data}
          alertOccured={
            block_baselines.block_baseline.executed[0].alert_occured
          }
        ></InputRadioboxBlock>
      )}
      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.signature &&
        mediasOfRequestedDatas(
          block_baselines?.block_baseline.executed[0]?.requested_data
        )?.map((media, index) => (
          <SignatureBlock
            key={index}
            media_content_uid={media}
            alertOccured={
              block_baselines.block_baseline.executed[0].alert_occured
            }
          ></SignatureBlock>
        ))}
      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.input_text && (
        <InputTextBlock
          title={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content.title
          }
          userInput={block_baselines.block_baseline.executed[0]}
          alertOccured={
            block_baselines.block_baseline.executed[0].alert_occured
          }
        ></InputTextBlock>
      )}
      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.input_image &&
        block_baselines.block_baseline.executed[0].requested_data &&
        mediasOfRequestedDatas(
          block_baselines?.block_baseline.executed[0]?.requested_data
        ).map((media, index) => (
          <InputImageBlock
            key={index}
            media_content_uid={media}
            alertOccured={
              block_baselines.block_baseline.executed[0].alert_occured
            }
          ></InputImageBlock>
        ))}

      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.input_video &&
        mediasOfRequestedDatas(
          block_baselines.block_baseline.executed[0].requested_data
        )?.map((media, index) => (
          <InputVideoBlock
            key={index}
            userInput={media}
            alertOccured={
              block_baselines.block_baseline.executed[0].alert_occured
            }
          ></InputVideoBlock>
        ))}

      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.input_audio &&
        mediasOfRequestedDatas(
          block_baselines?.block_baseline.executed[0]?.requested_data
        )?.map((media, index) => (
          <InputAudioBlock
            key={index}
            userInput={media}
            alertOccured={
              block_baselines.block_baseline.executed[0].alert_occured
            }
          ></InputAudioBlock>
        ))}
      {[BlockTypeConst_NEW.data, BlockTypeConst_NEW.input_data].includes(
        block_baselines.block_baseline.block_baseline.type
      ) && (
        <DataBlock
          data={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content
          }
          userInput={block_baselines.block_baseline.executed[0]}
          alertOccured={
            block_baselines.block_baseline.executed[0].alert_occured
          }
        ></DataBlock>
      )}
      {block_baselines.block_baseline.block_baseline.type ===
        BlockTypeConst_NEW.select_item && (
        <SelectItemBlock
          data={
            block_baselines.block_baseline.block_baseline
              .block_baseline_has_content[0].content.content_json.content
          }
          userInput={block_baselines.block_baseline.executed[0].requested_data}
          alertOccured={
            block_baselines.block_baseline.executed[0].alert_occured
          }
        />
      )}
    </>
  );
}
