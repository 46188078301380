import { createContext, Dispatch, useContext } from "react";
import {
  stateCommentsProvider,
  TypeCommentsContextReducerAction,
} from "./CommentsReducer";

export const CommentsContext = createContext<stateCommentsProvider>({
  statusComments: "loading",
  errorComments: null,
  comments: [],
  commentsWithReplies: null,
  paginationComments: { page: 1, pageSize: 10, total: -1 },
  sectionIdHovered: null,
});

export const CommentsDispatchContext = createContext<
  Dispatch<TypeCommentsContextReducerAction>
>(() => {
  return { type: "" };
});

export function useComments() {
  return useContext(CommentsContext);
}

export function useCommentsDispatch() {
  return useContext(CommentsDispatchContext);
}
