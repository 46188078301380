import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentModelType } from "../../../../../../app/stores/documents/types";

import styles from "./MagicTypeOfDocumentView.module.scss";
import { i18n } from "i18next";
import { GlobalStore, UtilsFunction } from "@armtekfrance/webgate/dist";
import {
  ApiDocumentSpecification,
  ApiLanguageEnum,
} from "@armtekfrance/corenexus-pn-sn";

export default function MagicTypeOfDocumentView({
  type,
}: {
  type: DocumentModelType;
}) {
  const { t, i18n } = useTranslation();
  const [typeInfo, setTypeInfo] = useState<{
    fullText: string;
    shortThreeLetters: string;
  }>();

  const getTraductionLabel = (i18n: i18n, type: DocumentModelType): string => {
    let docType: ApiDocumentSpecification | undefined = undefined;
    if (GlobalStore?.modelEditor?.documentTypes?.length > 0) {
      for (const singleFamily of GlobalStore.modelEditor.documentTypes) {
        const selectedType = singleFamily.types.find(
          (docType) => docType.type === type
        );
        if (selectedType) {
          docType = selectedType;
          break;
        }
      }
    }

    if (docType) {
      return UtilsFunction.interpretLabel(
        i18n.language as ApiLanguageEnum,
        docType.label
      );
    } else {
      return type;
    }
  };

  useEffect(() => {
    setTypeInfo({
      fullText: getTraductionLabel(i18n, type),
      shortThreeLetters: `doc.models.${type}.short`,
    });
    return;
  }, [type]);

  return (
    <div className={styles.magicTypeOfDocumentViewWrapper}>
      <div className={`${styles.container} ${styles[type]}`}>
        <p className={`${styles.logoLetters} ${styles[type]}`}>
          {t(`${typeInfo?.shortThreeLetters}`)}
        </p>
        <p className={styles.fullText}>{t(`${typeInfo?.fullText}`)}</p>
      </div>
    </div>
  );
}
