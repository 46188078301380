import { DocumentTypeConst } from "app/stores/documents/types";
import i18next from "i18next";
import i18n from "translations/i18n";

/**
 * All the types possible to create inside the documentModel **Content** tab
 */
export let documentTypeMenuItems: {
  key: string;
  value: string;
  label: string;
}[] = [];

/**
 * All **Content** type possible + an entry for *"All type"* only used of filtering
 */
export let documentTypeMenuItemsAll: {
  key: string;
  value: string;
  label: string;
}[] = [];

function updateTranslations() {
  documentTypeMenuItems = [
    {
      key: DocumentTypeConst.procedure,
      value: DocumentTypeConst.procedure,
      label: i18n.t("doc.models.procedure.title"),
    },
    {
      key: DocumentTypeConst.report,
      value: DocumentTypeConst.report,
      label: i18n.t("doc.models.report.title"),
    },
    {
      key: DocumentTypeConst.form,
      value: DocumentTypeConst.form,
      label: i18n.t("doc.models.form.title"),
    },
    {
      key: DocumentTypeConst.specification,
      value: DocumentTypeConst.specification,
      label: i18n.t("doc.models.specification.title"),
    },
    {
      key: DocumentTypeConst.communication,
      value: DocumentTypeConst.communication,
      label: i18n.t("doc.models.communication.title"),
    },
    {
      key: DocumentTypeConst.mixed,
      value: DocumentTypeConst.mixed,
      label: i18n.t("doc.models.mixed.title"),
    },
    {
      key: DocumentTypeConst.manual,
      value: DocumentTypeConst.manual,
      label: i18n.t("doc.models.manual.title"),
    },
    {
      key: DocumentTypeConst.requirements,
      value: DocumentTypeConst.requirements,
      label: i18n.t("doc.models.requirements.title"),
    },
    {
      key: DocumentTypeConst.guide,
      value: DocumentTypeConst.guide,
      label: i18n.t("doc.models.guide.title"),
    },
  ];

  documentTypeMenuItemsAll = JSON.parse(JSON.stringify(documentTypeMenuItems));
  documentTypeMenuItemsAll.unshift({
    key: "document",
    value: "document",
    label: i18n.t("doc.models.allType"),
  });
}

if (i18n.isInitialized) {
  updateTranslations();
}

i18next.on("languageChanged", () => {
  updateTranslations();
});
