import { useRef, useEffect } from "react";
import { message, List, Button, Typography, Skeleton, Tooltip } from "antd";
import { AiOutlinePlus, AiOutlineUser } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { getLoggedInUser } from "app/stores/auth/authClient";
import styles from "./AddTeam.module.scss";
import { fetchCompanyUsers } from "app/services/company";
import {
  addUsersToTeam,
  deleteTeam,
  fetchTeamsTanStack,
} from "app/services/team/teamClient";
import {
  useTeamLayout,
  useTeamLayoutDispatch,
} from "./Provider/TeamLayoutContext";
import { queryClient } from "libs/requests";
import AddTeamFormRoot from "components/AddTeamForm/AddTeamFormRoot";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";
import {
  Button as ArmtekButton,
  ButtonType,
} from "@armtekfrance/armtek-react-design-system";

const { Title, Text } = Typography;

export default function AddTeam() {
  const { t } = useTranslation();
  const createFormRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const user = getLoggedInUser();

  const teamLayoutContext = useTeamLayout();
  const dispatchTeamLayout = useTeamLayoutDispatch();

  const {
    status: statusTeams,
    error: errorTeams,
    teams,
  } = fetchTeamsTanStack();

  useEffect(() => {
    dispatchTeamLayout({
      type: "setTeamsTanstackApi",
      setTeamsTanstackApi: {
        statusTeams: statusTeams,
        errorTeams: errorTeams,
      },
    });
    if (statusTeams === "success") {
      dispatchTeamLayout({
        type: "setTeamsTanstack",
        setTeamsTanstack: {
          teams,
        },
      });
    }
  }, [statusTeams, errorTeams, teams]);

  useEffect(() => {
    if (statusTeams === "error") {
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("team.teamsNotFetched"),
      });
    }
  }, [statusTeams === "error"]);

  // On delete team submit
  const onDeleteSubmit = async (id: number) => {
    try {
      dispatchTeamLayout({
        type: "setDeleteLoading",
        setDeleteLoading: {
          deleteLoading: true,
        },
      });
      await fetchCompanyUsers(1, 10, { team_id: id })
        .then(async ({ users }) => {
          await addUsersToTeam(
            id,
            users.map((user) => {
              return user.id;
            }),
            "REMOVE"
          );
        })
        .catch((error) => {
          console.error(error);
          messageApi.open({
            type: "error",
            duration: 5,
            content: t("team.teamsNotFetched"),
          });
        });

      const response = await deleteTeam(id);

      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ["teams"] });
        messageApi.open({
          type: "success",
          duration: 5,
          content: t(`team.deleteSuccess`),
        });
      }
      dispatchTeamLayout({
        type: "setDeleteLoading",
        setDeleteLoading: {
          deleteLoading: false,
        },
      });
      dispatchTeamLayout({
        type: "setNewTeamCreated",
        setNewTeamCreated: {
          newTeamCreated: false,
        },
      });
    } catch (error) {
      console.error(error);
      dispatchTeamLayout({
        type: "setDeleteLoading",
        setDeleteLoading: {
          deleteLoading: false,
        },
      });
      messageApi.open({
        type: "error",
        duration: 5,
        content: t(`team.deleteFailure`),
      });
    }
  };

  const executeScroll = () => {
    if (createFormRef.current) {
      createFormRef.current.scrollIntoView();
    }
  };

  // On Team edit
  const editTeam = (id: number) => {
    openTeamForm(true, id);
    executeScroll();
  };

  // On Team delete
  const deleteATeam = (id: number) => {
    dispatchTeamLayout({
      type: "setActiveId",
      setActiveId: {
        activeId: id,
      },
    });
    onDeleteSubmit(id);
  };

  // On Add Team
  const createTeam = () => {
    dispatchTeamLayout({
      type: "setActiveId",
      setActiveId: {
        activeId: null,
      },
    });
    openTeamForm(!teamLayoutContext.newTeamCreated, null);
  };

  const openTeamForm = async (open: boolean, teamId?: number | null) => {
    if (teamId !== undefined) {
      dispatchTeamLayout({
        type: "setActiveId",
        setActiveId: {
          activeId: teamId,
        },
      });
    }
    dispatchTeamLayout({
      type: "setNewTeamCreated",
      setNewTeamCreated: {
        newTeamCreated: open,
      },
    });
  };

  return (
    <div className={styles.addTeamWrapper}>
      {contextHolder}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <Title level={4} className="mb-0">
            {t("team.teamsProfile")}
          </Title>
          <Text>{t("team.manage")}</Text>
        </div>
        <ArmtekButton
          type={
            !teamLayoutContext.newTeamCreated
              ? ButtonType.primary
              : ButtonType.default
          }
          onClick={() => createTeam()}
          child={
            <>
              {!teamLayoutContext.newTeamCreated && (
                <>
                  <AiOutlinePlus />
                  &nbsp;{" "}
                </>
              )}
              {!teamLayoutContext.newTeamCreated
                ? t("team.addTeam")
                : t("team.hideForm")}
            </>
          }
        />
      </div>
      {teamLayoutContext.newTeamCreated && (
        <AddTeamFormRoot
          activeId={teamLayoutContext.activeId}
          createFormRef={createFormRef}
          onOpenClose={openTeamForm}
          messageApi={messageApi}
          onSave={() => {
            queryClient.invalidateQueries({ queryKey: ["teams"] });
          }}
          displayUserSelect={true}
        />
      )}
      <div className="my-3 mb-5">
        <Title level={5}>{t("team.teamsProfile")}</Title>
        <List
          itemLayout="horizontal"
          className="shadow rounded"
          dataSource={teams}
          bordered
          renderItem={(item) => (
            <List.Item
              actions={
                teamLayoutContext.statusTeams === "success"
                  ? [
                      <>
                        {user &&
                          (user.permissions === 127 ||
                            user.permissions === 126 ||
                            user.permissions === 124) && (
                            <Tooltip title={t("team.editTeam")}>
                              <Button
                                type="ghost"
                                onClick={() => editTeam(item.id)}
                              >
                                {t("button.edit")}
                              </Button>
                            </Tooltip>
                          )}
                      </>,
                      <Tooltip title={t("team.delete")}>
                        <Button
                          loading={
                            teamLayoutContext.deleteLoading &&
                            teamLayoutContext.activeId === item.id
                          }
                          danger
                          onClick={() => {
                            dispatchTeamLayout({
                              type: "setArchiveTeamId",
                              setArchiveTeamId: {
                                archiveTeamId: item.id,
                              },
                            });
                            dispatchTeamLayout({
                              type: "setIsArchiveModalVisible",
                              setIsArchiveModalVisible: {
                                isArchiveModalVisible: true,
                              },
                            });
                          }}
                        >
                          {t("button.delete")}
                        </Button>
                      </Tooltip>,
                    ]
                  : [<Skeleton.Button active />, <Skeleton.Button active />]
              }
            >
              <Skeleton
                loading={teamLayoutContext.statusTeams === "loading"}
                active
              >
                <List.Item.Meta
                  title={
                    <Title level={5}>
                      <div className="rounded-pill px-3 py-1 shadow d-inline-flex justify-content-center">
                        <div className={styles.leftPill}>
                          <AiOutlineUser size={20} />
                          <p>{item._count.team_has_user}</p>
                        </div>
                        <p className={styles.rightPill}>{item.name}</p>
                      </div>
                    </Title>
                  }
                  description={
                    <div style={{ wordBreak: "break-word" }}>
                      {item.description}
                    </div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
      <ConfirmActionModal
        isVisible={teamLayoutContext.isArchiveModalVisible}
        handleCancel={() =>
          dispatchTeamLayout({
            type: "setIsArchiveModalVisible",
            setIsArchiveModalVisible: {
              isArchiveModalVisible: false,
            },
          })
        }
        handleConfirm={() => {
          if (teamLayoutContext.archiveTeamId) {
            deleteATeam(teamLayoutContext.archiveTeamId);
          }
          dispatchTeamLayout({
            type: "resetArchiveTeamId",
          });
          dispatchTeamLayout({
            type: "setIsArchiveModalVisible",
            setIsArchiveModalVisible: {
              isArchiveModalVisible: false,
            },
          });
        }}
        confirmInsideText={t("team.permanentDelete")}
        confirmHeaderText={t("archived.title")}
      />
    </div>
  );
}
