import styles from "./BadgeOnItem.module.scss";

export default function BadgeOnItem({
  children,
  numberValue,
}: {
  children: JSX.Element;
  numberValue: number | undefined;
}) {
  function cropOverNinetyNine() {
    return numberValue! > 99 ? "99+" : numberValue;
  }

  function classStyleLenght() {
    if (numberValue! < 10) return styles.notificationShort;
    if (numberValue! <= 99) return styles.notificationMedium;
    if (numberValue! > 99) return styles.notificationLong;
  }

  return (
    <div className={`${styles.badgeOnItemWrapper} ${classStyleLenght()} `}>
      <div>{children}</div>
      {numberValue !== undefined && <p>{cropOverNinetyNine()}</p>}
    </div>
  );
}
