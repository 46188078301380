import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Manager } from "@felixkletti/managementjs";
import {
  DocumentModelCloudActions,
  DocumentModelManager,
  ModelBuilder,
  useModelStore,
  UtilsFunction,
} from "@armtekfrance/webgate/dist";
import {
  ApiDocumentFamilyEnum,
  ApiDocumentModelExpandable,
  ApiLanguageEnum,
} from "@armtekfrance/corenexus-pn-sn";
import styles from "./SystemModelEditorPage.module.scss";

import MediaPickerModal from "components/MediaPicker/MediaPickerModal";
import { useTranslation } from "react-i18next";
import { navigation } from "Router/NavigationService";
import AfterPublishModal from "./SystemAfterPublishModal";
import i18n from "translations/i18n";
import { paths } from "Router/ROUTES";
import {
  fetchDocumentCategories,
  fetchDocumentCategoryByID,
} from "app/stores/documentCategories/documentCategoriesClient";

export default function SystemModelEditorPage() {
  const { id } = useParams();
  const { t } = useTranslation();

  const documentModel: ApiDocumentModelExpandable | undefined =
    DocumentModelManager.useModelStateFromModelsRetrieveResponse(parseInt(id!));

  const [documentModelMediaId, setDocumentModelMediaId] = useState<
    number | undefined
  >(undefined);

  const retrieveDocumentModel = (id: number) => {
    Manager.API.emitCustom({
      id: "modelHeaderModelRetrieve",
      subject: DocumentModelCloudActions.DOCUMENT_MODEL_RETRIEVES,
      request: {
        documents: [id],
        config: {
          extend: true,
          cover: {
            extend: true,
            media_token: true,
          },
          qr_code: {
            extend: true,
          },
          sections: {
            extend: true,
            // blocks: {
            //   extend: true,
            //   content: {
            //     extend: true,
            //   },
            // },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (id) {
      retrieveDocumentModel(parseInt(id));
    }
  }, [id]);

  const [openDialog, setOpenDialog] = useState(false);

  const [baselineIdPublished, setBaselineIdPublished] = useState<
    number | null
  >();

  Manager.API.on(
    DocumentModelCloudActions.DOCUMENT_MODEL_START_PUBLISH
  ).subscribe((next) => {
    if (next.success && next.response.document_baseline) {
      setBaselineIdPublished(next.response.document_baseline.id);
    }
  });

  useEffect(() => {
    if (baselineIdPublished) {
      setOpenDialog(true);
    }
  }, [baselineIdPublished]);

  interface ItemProps {
    children: React.ReactNode;
    value: string;
    [key: string]: any;
  }

  interface Category {
    active: boolean;
    category_name: string;
    company_id: number;
    created_at: string;
    id: number;
    parent_id: number | null;
    updated_at: string;
    _count: {
      document_model: number;
      sub_categories: number;
    };
  }

  const [categoriesList, setCategoriesList] = useState<ItemProps[]>([]);
  const [subCategoriesList, setSubCategoriesList] = useState<ItemProps[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const fetchSubCategories = async (categoryId: string) => {
    const response = await fetchDocumentCategories(
      { parent_id: parseInt(categoryId), active: true },
      1,
      1000
    );
    const subCategoriesList: ItemProps[] = response.data.docCategories.map(
      (category: Category) => ({
        children: category.category_name,
        value: category.id.toString(),
      })
    );
    setSubCategoriesList(subCategoriesList);
    return subCategoriesList;
  };

  useEffect(() => {
    if (id) {
      fetchDocumentCategories({ active: true }, 1, 1000).then((response) => {
        setCategoriesList(
          response.data.docCategories.map((category: Category) => ({
            children: category.category_name,
            value: category.id.toString(),
          }))
        );
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const fetchCategoriesFromDocId = async (docCategoryId: number) => {
    if (docCategoryId) {
      fetchDocumentCategoryByID(docCategoryId).then(async (response) => {
        const currentDocCategory: Category = response.data;

        if (currentDocCategory?.parent_id) {
          await fetchSubCategories(currentDocCategory?.parent_id.toString());
          setSelectedCategory(currentDocCategory?.parent_id.toString());
          setSelectedSubCategory(currentDocCategory?.id.toString());
        } else {
          await fetchSubCategories(currentDocCategory?.id.toString());
          setSelectedCategory(currentDocCategory?.id.toString());
        }
      });
    }
  };
  const location = useLocation();
  useEffect(() => {
    return () => {
      // Set the store to initial state
      useModelStore.getState().setCurrentModel(null);
    };
  }, [location]);

  return (
    <>
      {id ? (
        <>
          <MediaPickerModal />
          {openDialog && (
            <AfterPublishModal
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              baselineIdPublished={baselineIdPublished}
              typeOfBaselineLabel={
                UtilsFunction.interpretLabel(
                  i18n.language as ApiLanguageEnum,
                  documentModel?.data?.specification.label
                ) ?? ""
              }
            />
          )}
          <ModelBuilder
            modelId={parseInt(id)}
            documentFamily={ApiDocumentFamilyEnum.SYSTEM}
            mediaId={documentModelMediaId}
            onDelete={() => {
              navigation.to("account/system/models/list");
              return;
            }}
            onDiffNavigate={() => {
              navigation.to(("account/system/models/edit/diff/" + id) as paths);
            }}
            docCategories={categoriesList}
            subCategories={subCategoriesList}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            fetchSubCategories={fetchSubCategories}
            fetchDocumentCategory={fetchCategoriesFromDocId}
          />
        </>
      ) : (
        <div className={styles.centerContainer}>
          <h2>{t("systemModelRetrieveError")}</h2>
        </div>
      )}
    </>
  );
}
