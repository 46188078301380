import Select, { CSSObjectWithLabel, components } from "react-select";
import { useTranslation } from "react-i18next";
import { Select as SelectAntd } from "antd";
import { DatePicker, Tooltip } from "antd";
import moment from "moment";
import { Button } from "@armtekfrance/armtek-react-design-system";
import styles from "./DropdownMultiSelection.module.scss";
import { useEffect, useRef, useState } from "react";

const { RangePicker } = DatePicker;

interface DropdownItemType {
  label: string;
  value: number | string;
  filterValues?: (number | string)[];
}

interface FilterType {
  areFilterDropdownsVisible: boolean;
  firstFilterField: string;
  firstFilterOptions: DropdownItemType[];
}

interface DropdownMultiSelectionProps {
  allItems?: DropdownItemType[];
  selectedItems?: (number | string)[];
  onSelectItem?: (value: (number | string)[]) => void;
  onSelectDate?: (value: [string, string]) => void;
  onSelectAll?: (items: DropdownItemType[]) => void;
  onClear?: (items: DropdownItemType[]) => void;
  type?: "datepicker" | "dropdown";
  styleDropdown?: any;
  isSelectAllButtonVisible?: boolean;
  setIsPopUpVisible?: (isPopUpVisible: boolean) => void;
  placeholder?: string;
  filter?: FilterType;
}

const DropdownMultiSelection = ({
  allItems,
  selectedItems,
  onSelectDate,
  onSelectItem,
  onSelectAll,
  onClear,
  type = "dropdown",
  styleDropdown,
  isSelectAllButtonVisible = true,
  // setIsPopUpVisible,
  placeholder,
  filter,
}: DropdownMultiSelectionProps) => {
  const { t } = useTranslation();

  const [AllOptions, setAllOptions] = useState<DropdownItemType[]>([]);
  const newRef = useRef<HTMLInputElement>(null);

  const { areFilterDropdownsVisible, firstFilterField, firstFilterOptions } =
    filter ?? {};

  const handleClickOutside = (event: any) => {
    if (newRef.current && !newRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (selectedFiltering.length === 0 && allItems) {
      setAllOptions(allItems);
    } else {
      filterBySelectedFilter(filterDuplicata(selectedFiltering));
    }
  }, [allItems]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [newRef]);

  const filterDuplicata = (LocalAllItems: DropdownItemType[]) => {
    if (!LocalAllItems) return [];
    const filteredArray: DropdownItemType[] = [];
    for (let i = 0; i < LocalAllItems.length; i++) {
      if (
        !filteredArray.some(
          (filteredItem) => filteredItem.value === LocalAllItems[i].value
        )
      ) {
        filteredArray.push(LocalAllItems[i]);
      }
    }

    return filteredArray;
  };

  function filterBySelectedFilter(selectedFilters: DropdownItemType[]) {
    if (!allItems) return;

    const filterValuesList = selectedFilters.map((filter) => filter.value);

    const localSelectedItems = allItems.reduce((items, currentItem) => {
      if (!currentItem.filterValues) return items;

      const matchingFilters = currentItem.filterValues.filter((filter) => {
        return filterValuesList.includes(filter as string | number);
      });

      if (matchingFilters && matchingFilters.length > 0) {
        const alreadyExist = items.some(
          (item) => item.value === currentItem.value
        );

        if (!alreadyExist) {
          items.push(currentItem);
        }
      }

      return items;
    }, [] as DropdownItemType[]);

    setAllOptions(localSelectedItems);
  }

  const ControlComponent = ({ children, ...props }: any) => {
    return (
      <components.Control {...props}>
        <div
          style={{
            borderRight: "1px solid #d9d9d9",
            paddingRight: "10px",
            paddingLeft: "5px",
          }}
        >
          {firstFilterField ? firstFilterField : ""}
        </div>
        {children}
      </components.Control>
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiltering, setselectedFiltering] = useState([]);
  const [isSubFilterOpened, setIsSubFilterOpened] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
      ref={newRef}
      onClick={(e) => {
        e?.stopPropagation();
      }}
    >
      {type === "dropdown" ? (
        <SelectAntd
          style={{
            ...styleDropdown,
          }}
          filterOption={(input, option: any) =>
            option
              ? option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              : false
          }
          maxTagCount="responsive"
          onClick={(e) => {
            e?.stopPropagation();
            setIsOpen(true);
          }}
          open={isOpen}
          mode="multiple"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          placeholder={
            placeholder ? placeholder : t("dropdownMultiSelection.pleaseSelect")
          }
          options={AllOptions}
          value={selectedItems}
          onChange={(selectedItems) => {
            if (onSelectItem) {
              onSelectItem(selectedItems);
            }
          }}
          // allowClear={true}
          allowClear={false}
          dropdownRender={(menu) => (
            <>
              {areFilterDropdownsVisible && (
                <div>
                  <Select
                    placeholder={t("dropdown.select")}
                    styles={
                      {
                        control: (base: CSSObjectWithLabel) => ({
                          ...base,
                          width: "95%",
                          margin: "10px",
                          // Margin bottom if subfilter is opened
                          marginBottom: isSubFilterOpened ? "100px" : "0.5rem",
                        }),
                        valueContainer: (base: CSSObjectWithLabel) => ({
                          ...base,
                          maxHeight: "4rem",
                          overflowY: "auto",
                        }),
                        menuList: (base: CSSObjectWithLabel) => ({
                          ...base,
                          // Make the dropdown scrollable and calculation of the max height of the dropdown using the allOptions length * 32px (height of an option)
                          maxHeight:
                            AllOptions.length > 8
                              ? "200px"
                              : `${AllOptions.length * 32}px`,
                        }),
                        // Dirty fix to make the styles type work for now. TODO: Fix this
                      } as any
                    }
                    onMenuClose={() => {
                      setIsSubFilterOpened(false);
                    }}
                    isClearable={true}
                    isMulti
                    components={{ Control: ControlComponent }}
                    options={filterDuplicata(firstFilterOptions!)}
                    onChange={(selectedFilterArray: any) => {
                      if (selectedFilterArray.length === 0) {
                        setselectedFiltering(selectedFilterArray);
                        if (allItems) {
                          setAllOptions(allItems);
                        }
                      } else {
                        setselectedFiltering(selectedFilterArray);
                        filterBySelectedFilter(selectedFilterArray);
                      }
                    }}
                  />
                </div>
              )}
              {isSelectAllButtonVisible && onSelectAll && onClear && (
                <div className={styles.selectAllClearButtonWrapper}>
                  <Button
                    // size="small"
                    onClick={() => {
                      onSelectAll(AllOptions);
                    }}
                    child={<>{t("operate.selectAll")}</>}
                  />
                  <Button
                    // size="small"
                    onClick={() => {
                      onClear(AllOptions);
                    }}
                    child={<>{t("operate.clear")}</>}
                  />
                </div>
              )}
              {menu}
            </>
          )}
        />
      ) : (
        <div>
          <>
            <Tooltip
              placement="top"
              title={t("doc.models.procedure.expirationDate")}
            >
              <RangePicker
                disabledDate={(current) => current.isAfter(moment())}
                format={"DD/MM/YYYY"}
                defaultValue={[moment().subtract(1, "months"), moment()]}
                placeholder={[t("operate.startDate"), t("operate.endDate")]}
                style={{
                  borderRadius: "5px",
                  border: "solid 1px #4f7fa3",
                  boxSizing: "border-box",
                  padding: "0.32rem 1rem",
                }}
                onChange={(data, datesString) => {
                  if (onSelectDate) {
                    onSelectDate(datesString);
                  }
                }}
              />
            </Tooltip>
          </>
        </div>
      )}
    </div>
  );
};

export default DropdownMultiSelection;
