import { createService, Manager } from "@felixkletti/managementjs";
import { createBrowserRouter } from "react-router-dom";
import type { Router as RemixRouter } from "@remix-run/router/dist/router";
import { paths, ROUTES } from "./ROUTES";
import { RouterNavigateOptions } from "@remix-run/router/router";
import { filter, tap } from "rxjs";
import { APP_URL } from "@armtekfrance/webgate/dist";
import { GlobalStore } from "@armtekfrance/webgate/dist";
import { AuthAction } from "@armtekfrance/webgate/dist";
import { LOG, LoggerContext } from "@felixkletti/managementjs/dist/lib/logger";
import { AuthCloudActions } from "@armtekfrance/webgate/dist";

const CONTEXT: LoggerContext = { origin: "NavigationService" };

class NavigationService extends createService("NavigationService") {
  private _router: RemixRouter = createBrowserRouter(ROUTES);
  get router() {
    return this._router;
  }

  constructor() {
    super();
    Manager.API.on(AuthCloudActions.AUTH_LOGIN)
      .pipe(
        filter((value) => !value.success),
        tap(() => this.to("login", true))
      )
      .subscribe();
    Manager.API.on(AuthCloudActions.AUTH_REFRESH_TOKEN)
      .pipe(
        filter((value) => !value.success),
        tap(() => this.to("login", true))
      )
      .subscribe();
    Manager.on(AuthAction.ON_LOGOUT)
      .pipe(tap(() => this.to("login", true)))
      .subscribe();
  }

  public toDefault() {
    LOG.debug.log(
      { ...CONTEXT, action: "toDefault" },
      {
        target: APP_URL.state.store.target_url,
        authenticated: GlobalStore.auth.status.authenticated,
      }
    );
    if (APP_URL.state.store.target_url) {
      this.to(APP_URL.state.store.target_url as paths);
    } else {
      if (GlobalStore.auth.status.authenticated) {
        this.to("account/dashboard");
      } else {
        this.to("login");
      }
    }
  }

  public to(path: paths, saveCurrent = false): void {
    if (saveCurrent && path !== "login") {
      APP_URL.state.setState({
        ...APP_URL.state.store,
        target_url: location.pathname,
      });
    }
    const options: RouterNavigateOptions = {
      replace: true,
    };

    switch (path) {
      /// additional logic if needed
      default:
        this.router.navigate("/" + path, options).then(() => {
          // console.log("Routed successfully to ", "/" + path);
          if (path === APP_URL.state.store.target_url) {
            APP_URL.state.setState({ ...APP_URL.state.store, target_url: "" });
          }
        });
        break;
    }
  }
}

export const navigation = new NavigationService();
