import TopHeaderMenuLayout from "./TopHeaderMenuLayout";
import {
  NavigationPath,
  WebGateNavigationMenu,
} from "@armtekfrance/webgate/dist";
import { navigation } from "Router/NavigationService";
import { paths } from "Router/ROUTES";
import styles from "./TopHeaderMenuNavigationLayout.module.scss";
import { useLocation } from "react-router-dom";

export default function TopHeaderMenuNavigationLayout() {
  let location = useLocation();

  return (
    <div className={styles.topHeaderMenuNavigationLayoutWrapper}>
      <WebGateNavigationMenu
        currentPath={location.pathname as NavigationPath}
        onClickNavigationItem={(path) => {
          navigation.to(path as paths);
        }}
        dataCy="WebgateNavigationMenu-Navigate-App"
      ></WebGateNavigationMenu>
      <TopHeaderMenuLayout />
    </div>
  );
}
