import styles from "./MediaPickerLoadMoreFooter.module.scss";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

export default function MediaPickerLoadMoreFooter({
  hasNextPage,
  isFetching,
  isFetchingNextPage,
}: {
  hasNextPage: boolean | undefined;
  isFetching: boolean;
  isFetchingNextPage: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.mediaPickerLoadMoreFooterWrapper}>
      {isFetching || isFetchingNextPage ? (
        <Spin size="large" />
      ) : (
        <>{hasNextPage === false && <p>{t("mediaPicker.noMoreData")}</p>}</>
      )}
    </div>
  );
}
