import { GlobalStore, MediaAction } from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";
import { MediaContent } from "Stores/media-models";
import { fetchBlobObjectTanstack } from "app/stores/content/contentClient";
import MediaImageItemWrapper from "components/TempDS/MediaImageItemWrapper/MediaImageItemWrapper";

export default function MediaPickerModalImageWrapper({
  imageItem,
  checked,
  onCheckedChange,
  enablePreview = false,
  convertToBase64 = false,
}: {
  imageItem: MediaContent;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  enablePreview?: boolean;
  convertToBase64?: boolean;
}) {
  GlobalStore.media.setThumbnailImage(
    fetchBlobObjectTanstack(
      imageItem.media_content_uid,
      true,
      false,
      1000 * 60 * 60 * 24 * 7
    )
  );

  return (
    <MediaImageItemWrapper
      mediaName={imageItem.name}
      showMediaName={true}
      imageDataUri={`data:${
        GlobalStore.media.getThumbnailImage().blobObject?.media.ContentType
      };${convertToBase64 ? "base64," : ""} ${
        GlobalStore.media.getThumbnailImage().blobObject?.media.file
      }`}
      status={GlobalStore.media.getThumbnailImage().status}
      error={GlobalStore.media.getThumbnailImage().error}
      alt={imageItem.name}
      showCheckbox={true}
      checked={checked}
      typeOfMedia="image"
      onCheckedChange={onCheckedChange}
      enablePreview={enablePreview}
      onPreviewClickOpening={() => {
        Manager.trigger(MediaAction.OPEN_FULL_SCREEN_MEDIA_PREVIEW, {
          media_id: imageItem.id.toString(),
          block_type: "image",
          open: true,
          localDataUri: null,
        });

        GlobalStore.media.setFullScreenMediaPreview({
          media_id: imageItem.id.toString(),
          block_type: "image",
          localDataUri: null,
        });
      }}
    />
  );
}
