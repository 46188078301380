import { useTranslation } from "react-i18next";
import { useObsoleteReleasesDispatch } from "../../Provider/ObsoleteReleasesContext";
import InputSearch from "components/InputSearch/InputSearch";

export default function ObsoleteReleasesHeroSearch() {
  const { t } = useTranslation();
  const dispatchObsoleteReleases = useObsoleteReleasesDispatch();

  return (
    <InputSearch
      placeholder={t("menu.search")}
      onSearch={(e) => {
        dispatchObsoleteReleases({
          type: "setQueryNameTextFilter",
          queryNameTextFilter: e,
        });
      }}
    />
  );
}
