import Header from "./Header";
import HeaderProvider from "./Provider/HeaderProvider";

export default function HeaderRoot() {
  return (
    <HeaderProvider>
      <Header />
    </HeaderProvider>
  );
}
