import React from "react";
import { useEffect, useRef, useState } from "react";
import { checkIfWinningBuffer } from "./checkIfWinningBuffer";
import styles from "./MatrixTrigger.module.scss";

export default function MatrixTrigger({
  children,
  runEasterEgg,
  setRunEasterEgg,
  setEnterDarkness,
}: {
  children: JSX.Element;
  runEasterEgg: boolean;
  setRunEasterEgg: Function;
  setEnterDarkness: Function;
}) {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isMousePressed, setIsMousePressed] = useState(false);
  const [runLogoGlitch, setRunLogoGlitch] = useState<boolean>(false);
  const [buffer, setBuffer] = useState<ArrayBuffer | null>(null);
  const [bufferIndex, setBufferIndex] = useState<number>(0);
  const maxPoints = 500;
  const pointSize = Int32Array.BYTES_PER_ELEMENT;
  const bufferSize = maxPoints * pointSize;

  useEffect(() => {
    if (!imageRef.current) return;

    const image = imageRef.current;

    const handleMouseDown = () => {
      setIsMousePressed(true);
      setBufferIndex(0);
      setBuffer(new ArrayBuffer(bufferSize));
    };

    const handleMouseUp = () => {
      setIsMousePressed(false);
      if (buffer) {
        const int32Array = new Int32Array(buffer);
        const hasWon = checkIfWinningBuffer(int32Array, bufferIndex);
        if (hasWon === true) {
          setRunLogoGlitch(true);
          setTimeout(() => setEnterDarkness(true), 2800);
          setTimeout(() => setRunEasterEgg(true), 4000);
        }
        setBufferIndex(0);
        setBuffer(null);
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isMousePressed || !buffer) return;

      const imageRect = image.getBoundingClientRect();
      const mouseX = event.clientX - imageRect.left;
      const mouseY = event.clientY - imageRect.top;

      const int32Array = new Int32Array(buffer);

      if (bufferIndex < maxPoints) {
        int32Array[bufferIndex] = mouseX;
        int32Array[bufferIndex + 1] = mouseY;
      }

      setBufferIndex(bufferIndex + 2);
      setBuffer(int32Array);
    };

    image.addEventListener("mousedown", handleMouseDown);
    image.addEventListener("mouseup", handleMouseUp);
    image.addEventListener("mousemove", handleMouseMove);

    return () => {
      image.removeEventListener("mousedown", handleMouseDown);
      image.removeEventListener("mouseup", handleMouseUp);
      image.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isMousePressed, bufferIndex, buffer]);

  // Inject the ref directly into the img element
  return (
    <>
      <div ref={imageRef} className={`${runLogoGlitch && styles.glitch}`}>
        {React.cloneElement(children, {
          ref: imageRef,
        })}
      </div>
      {runEasterEgg && (
        <div
          onClick={() => {
            setRunEasterEgg(false);
            setEnterDarkness(false);
          }}
          style={{
            position: "fixed",
            inset: 0,
            zIndex: "99",
          }}
        ></div>
      )}
    </>
  );
}
