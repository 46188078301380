import Request from "../../../libs/requests";

export const fetchModelNotificationPreferences = (id: number) => {
  return Request()
    .get(`/documents/events/${id}`)
    .then((response) => response.data);
};

export const updateModelNotificationPreferences = (
  id: number,
  preferenceData: any
) => {
  return Request()
    .put(`/documents/events/${id}`, preferenceData)
    .then((response) => response.data);
};

export const deleteModelNotificationPreferences = (id: number) => {
  return Request()
    .delete(`/documents/events/${id}`)
    .then((response) => response.data);
};
