import Request from "../../../libs/requests";

export const getAccessRightForAResource = async (
  resourceId: number,
  resourceType: string
) => {
  return Request()
    .get(`/documents/access-rights/${resourceType}/${resourceId}`)
    .then((response) => response.data);
};

export const addOrUpdateAccessRightForAResource = async (data: {
  resource_type: string;
  resource_id: number;
  target_type: string;
  target_id: number;
  read_access: boolean;
  execute_access: boolean;
  edit_access: boolean;
  archive_access: boolean;
}) => {
  return Request()
    .post(`/documents/access-rights`, data)
    .then((response) => response.data);
};

export const deleteAccessRightForAResource = async (data: {
  resource_type: string;
  resource_id: number;
  target_id: number;
}) => {
  return Request()
    .delete(`/documents/access-rights`, { data })
    .then((response) => response.data);
};
