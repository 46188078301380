import React from "react";
import DropDownItemTextSelect from "./DropDownItemTextSelect";
import styles from "./DropDownList.module.scss";

export type DropDownListItem = {
  key: string;
  label: string | JSX.Element;
  icon?: JSX.Element;
  selected?: boolean;
};

export default function DropDownList({
  listItems,
  onClickItem,
  selectType,
  dataCy = "DropDownList",
}: {
  listItems: DropDownListItem[];
  selectType?: "radio" | "checkbox";
  onClickItem: (item: DropDownListItem) => void;
  dataCy?: string;
}) {
  return (
    <ol className={styles.dropDownListWrapper} data-cy={dataCy}>
      {listItems.map((item, index) => {
        return (
          <li
            key={index}
            onClick={() => onClickItem(item)}
            data-cy="Button-SelectTypeCreateSystemModels-ModelsList"
          >
            {item.icon !== undefined &&
              React.cloneElement(item.icon, {
                className: styles.icon,
              })}
            <DropDownItemTextSelect
              selectType={selectType}
              item={item}
              dataCy={item.key}
            />
          </li>
        );
      })}
    </ol>
  );
}
