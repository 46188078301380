import styles from "./Header.module.scss";
import HeaderMenuActions from "./HeaderMenuActions";

export default function Header() {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.stakingContext}>
        <header>
          <HeaderMenuActions />
        </header>
      </div>
    </div>
  );
}
