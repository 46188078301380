import { Col, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

export default function AddTeamFormtextFieldsLocation() {
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Form.Item
        name="location"
        label={t("team.location")}
        rules={[
          {
            required: true,
            message: t("team.teamLocationInput"),
          },
        ]}
      >
        <Input
          maxLength={100} // max length accepted by API for team location
          placeholder={t("team.teamLocationInput")}
        />
      </Form.Item>
    </Col>
  );
}
