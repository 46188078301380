import { DropDownNew } from "components/DropDown/DropDownNew";
import NotificationModal from "pages/Notifications/Modal/NotificationModal";
import { useHeader, useHeaderDispatch } from "../Provider/HeaderContext";
import {
  countNotificationsTanStack,
  fetchNotificationsTanStack,
} from "app/stores/notifications/notificationsClient";
import { useEffect } from "react";
import NotificationsOrAlertsDropDown from "./DropDowns/NotificationsOrAlertsDropDown";
import BadgeOnItem from "../../../components/BadgeOnItem/BadgeOnItem";
import { WarningIcon } from "@armtekfrance/armtek-react-design-system/dist/assets";

export default function WarringAlert({
  dataCy = "WarringAlert",
}: {
  dataCy?: string;
}) {
  const headerContext = useHeader();
  const dispatchHeader = useHeaderDispatch();

  const {
    status: statusAlerts,
    error: errorAlerts,
    notifications: alerts,
  } = fetchNotificationsTanStack(
    1,
    100,
    {
      alert: true,
    },
    { createdAt: "desc" }
  );

  useEffect(() => {
    dispatchHeader({
      type: "setAlertsTanstackApi",
      setAlertsTanstackApi: {
        statusAlerts,
        errorAlerts,
      },
    });
    if (statusAlerts === "success") {
      dispatchHeader({
        type: "setAlertsTanstack",
        setAlertsTanstack: {
          alerts: alerts?.notifications,
          paginationAlerts: alerts?.pagination ?? {
            page: 1,
            pageSize: 10,
            total: -1,
          },
        },
      });
    }
  }, [statusAlerts, errorAlerts, alerts]);

  const {
    status: statusAlertsCount,
    error: errorAlertsCount,
    notificationsCount,
  } = countNotificationsTanStack({
    opened: false,
    alert: true,
  });

  useEffect(() => {
    dispatchHeader({
      type: "setAlertCountTanstackApi",
      setAlertCountTanstackApi: {
        statusAlertsCount,
        errorAlertsCount,
      },
    });
    if (statusAlertsCount === "success") {
      dispatchHeader({
        type: "setAlertCountTanstack",
        setAlertCountTanstack: {
          alertsCount: notificationsCount! ?? -1,
        },
      });
    }
  }, [statusAlertsCount, errorAlertsCount, notificationsCount]);

  return (
    <div data-cy={dataCy}>
      <DropDownNew
        placement="bottomRight"
        dropdownContent={
          <NotificationsOrAlertsDropDown typeOfNotidications="ALERTS" />
        }
      >
        {headerContext.alertsCount ? (
          <BadgeOnItem numberValue={headerContext.alertsCount}>
            <WarningIcon />
          </BadgeOnItem>
        ) : (
          <WarningIcon />
        )}
      </DropDownNew>
      <NotificationModal
        isModalVisible={headerContext.previewInfo.isOpen}
        typeOfPreview={headerContext.previewInfo.typeOfPreview}
        documentId={headerContext.previewInfo.idOfPreview}
        onCancel={() => {
          dispatchHeader({ type: "togglePreview" });
        }}
      />
    </div>
  );
}
