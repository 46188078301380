import Request, {
  sortFormat,
  pageFormat,
  pageSizeFormat,
  filterFormat,
} from "../../../libs/requests";
import { PaginationType } from "../pagination/types";
import { assignQrType, QrCodeType } from "./types";

export const fetchQRCodes = async (
  page: number,
  pageSize: number,
  filters = {},
  sort = { id: "asc" }
): Promise<{ qrCodes: QrCodeType[]; pagination: PaginationType }> => {
  const sortParams = sortFormat(sort);
  const pageParams = pageFormat(page);
  const pageSizeParams = pageSizeFormat(pageSize);
  const filterParams = filterFormat(filters);
  return Request()
    .get("/qrcodes", {
      params: {
        ...filterParams,
        ...sortParams,
        ...pageParams,
        ...pageSizeParams,
      },
    })
    .then((response) => {
      return {
        qrCodes: response.data.data.qrCodes as QrCodeType[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export const createQrCode = async (): Promise<QrCodeType> => {
  return Request()
    .post(`/qrcodes/`)
    .then((response) => {
      return response.data.data as QrCodeType;
    });
};

export const assignQrCodeToResource = async (
  values: assignQrType
): Promise<{ id: number }> => {
  return Request()
    .patch(`/qrcodes/`, values)
    .then((response) => {
      return response.data.data as { id: number };
    });
};

export const exportQrcode = async (qr_code_uuid: string): Promise<any> => {
  return Request()
    .get(`/qrcodes/export/${qr_code_uuid}`, { responseType: "blob" })
    .then((response) => {
      return response as any;
    });
};

export const unassignQRCode = async (id: string) => {
  return Request()
    .delete(`/qrcodes/${id}`)
    .then((response) => response.data);
};
