import { Col, Row, Skeleton } from "antd";

export const loadingData = [
  {
    id: 1,
    name: "",
    description: "",
    _count: { user: 0 },
  },
  {
    id: 2,
    name: "",
    description: "",
    _count: { user: 0 },
  },
  {
    id: 3,
    name: "",
    description: "",
    _count: { user: 0 },
  },
  {
    id: 4,
    name: "",
    description: "",
    _count: { user: 0 },
  },
];

export default function EditCompanyDepartmentLoader() {
  return (
    <div>
      <Row gutter={12}>
        <Col span={24} className="mb-1">
          <Skeleton.Input active />
        </Col>
        <Col span={24} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
          </div>
        </Col>
        <Col span={24} className="mb-1">
          <Skeleton.Input active />
        </Col>
        <Col span={24} className="mb-4">
          <div className="full-skeleton">
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
          </div>
        </Col>
      </Row>
    </div>
  );
}
