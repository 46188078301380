import { Form, Input, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AiOutlineDelete } from "react-icons/ai";

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { archiveDocumentRequest } from "../../app/stores/documentRequests/documentRequestsClient";
import { useTranslation } from "react-i18next";
import { DocumentRequestType } from "../../app/stores/documentRequests/types";
import ConfirmActionModal from "components/ConfirmArchiveModal/ConfirmActionModal";

interface EntityDetailType {
  entityData?: DocumentRequestType;
  entityId: any;
  setEntityId: (entityId: string) => void;
}

export default function DocumentRequestDetail({
  entityData,
  entityId,
}: EntityDetailType) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isArchiveModalVisible, setArchiveIsModalVisible] = useState(false);
  const showModal = () => {
    setArchiveIsModalVisible(true);
  };
  const handleCancel = () => {
    setArchiveIsModalVisible(false);
  };

  const handleOk = () => {
    onClickArchive();
    setArchiveIsModalVisible(false);
  };

  const navigate = useNavigate();
  const onClickArchive = () => {
    if (entityId) {
      archiveDocumentRequest(parseInt(entityId)).then((resp: any) => {
        if (resp?.success) navigate(-1);
      });
    }
  };

  return (
    <div>
      {entityData && entityData.active ? (
        <div>
          <Button icon={<AiOutlineDelete />} onClick={showModal}>
            {t("organisation.archive")}
          </Button>
          <ConfirmActionModal
            isVisible={isArchiveModalVisible}
            handleCancel={handleCancel}
            handleConfirm={handleOk}
            confirmInsideText={t("archived.text")}
            confirmHeaderText={t("archived.title")}
          />
        </div>
      ) : (
        <div></div>
      )}
      <div style={{ minHeight: "16px" }}></div>
      <Form
        form={form}
        layout="vertical"
        initialValues={entityData}
        name="documentRequestsViewForm"
      >
        <Form.Item label={"Document Type"} name="document_type">
          <Input placeholder={t("edit.enterName")} disabled />
        </Form.Item>
        <Form.Item label={"Subject"} name="subject">
          <Input placeholder={t("edit.enterIdentification")} disabled />
        </Form.Item>
        <Form.Item label={t("edit.description")} name="description">
          <TextArea
            rows={8}
            placeholder={t("edit.enterDescription")}
            disabled
          />
        </Form.Item>
      </Form>
    </div>
  );
}
