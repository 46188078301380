import { Button, Col, Modal, Row, Image } from "antd";
import { useState } from "react";
import { SelectedPictogramPreview } from ".";
import pictograms from "./pictogram.json";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

interface PictogramModalType {
  showPictogramModal: boolean;
  setShowPictogramModal: (showPictogramModal: boolean) => void;
  data: any;
  savePictogram: (selectedPictograms: string[]) => void;
}

export default function PictogramModal({
  showPictogramModal,
  setShowPictogramModal,
  data,
  savePictogram,
}: PictogramModalType) {
  const { t } = useTranslation();
  const [selectedPictograms, setSelectedPictograms] = useState(
    data.pictograms ? data.pictograms : []
  );

  const checkIsSelected = (file: string) => {
    return selectedPictograms.find((p: any) => p === file);
  };

  const selectPictogram = (picto: string) => {
    let coptSelectedPictograms = [...selectedPictograms];
    if (checkIsSelected(picto)) {
      coptSelectedPictograms = coptSelectedPictograms.filter(
        (e) => e !== picto
      );
    } else {
      coptSelectedPictograms.push(picto);
    }
    setSelectedPictograms(coptSelectedPictograms);
  };

  const onAddPictogram = () => {
    savePictogram(selectedPictograms);
    // setSelectedPictograms([]);
    setShowPictogramModal(false);
  };

  return (
    <Modal
      width="85vw"
      style={{
        top: 30,
      }}
      open={showPictogramModal}
      title={t("pictogramModal.addPictogram")}
      onCancel={() => setShowPictogramModal(false)}
      onOk={onAddPictogram}
      footer={[
        <Button key="back" onClick={() => setShowPictogramModal(false)}>
          {t("button.cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={onAddPictogram}>
          {t("doc.models.linkage.add")}
        </Button>,
      ]}
    >
      <div>
        {selectedPictograms.length > 0 ? (
          <>
            <p>{`${selectedPictograms.length} ${t(
              "pictogramModal.pictogramsSelected"
            )}`}</p>
            <Row gutter={[16, 8]}>
              {selectedPictograms.map((file: string) => {
                return (
                  <Col style={{ height: "60px" }}>
                    <SelectedPictogramPreview file={file} />
                  </Col>
                );
              })}
            </Row>
          </>
        ) : (
          <p>{t("pictogramModal.pictogramsSelected")}</p>
        )}
      </div>
      <div
        className={style.marginTop}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      ></div>
      <div
        style={{
          maxHeight: "50vh",
          overflow: "auto",
          textAlign: "justify",
        }}
      >
        <h6>{t("pictogramModal.equipments")}</h6>
        <Row gutter={[8, 8]}>
          {pictograms.equipment.map((equipmentPicto) => {
            return (
              <Col>
                <PictogramCard
                  pictogramDetails={equipmentPicto}
                  isSelected={checkIsSelected(equipmentPicto.file)}
                  selectPictogram={() => selectPictogram(equipmentPicto.file)}
                />
              </Col>
            );
          })}
        </Row>
        <h6 style={{ paddingTop: "8px" }}>
          {t("pictogramModal.interdiction")}
        </h6>
        <Row gutter={[8, 8]}>
          {pictograms.interdiction.map((interdictionPicto) => {
            return (
              <Col>
                <PictogramCard
                  pictogramDetails={interdictionPicto}
                  isSelected={checkIsSelected(interdictionPicto.file)}
                  selectPictogram={() =>
                    selectPictogram(interdictionPicto.file)
                  }
                />
              </Col>
            );
          })}
        </Row>
        <h6 style={{ paddingTop: "8px" }}>{t("pictogramModal.danger")}</h6>
        <Row gutter={[8, 8]}>
          {pictograms.danger.map((dangerPicto) => {
            return (
              <Col>
                <PictogramCard
                  pictogramDetails={dangerPicto}
                  isSelected={checkIsSelected(dangerPicto.file)}
                  selectPictogram={() => selectPictogram(dangerPicto.file)}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </Modal>
  );
}

function PictogramCard({ pictogramDetails, isSelected, selectPictogram }: any) {
  const selectedStyle = { border: "2px solid #00B0F0" };
  return (
    <div
      className={style.cardStyle}
      style={isSelected ? selectedStyle : {}}
      onClick={selectPictogram}
    >
      <div className={style.cardContentStyle}>
        <Image
          className="logo"
          src={`/pictos/${pictogramDetails.file}`}
          alt="Pictogram"
          style={{
            height: "60px",
            width: "60px",
          }}
          preview={false}
        />
        <div
          className={style.pictogramDescription}
          style={{ textAlign: "center" }}
        >
          {pictogramDetails.description}
        </div>
      </div>
    </div>
  );
}
