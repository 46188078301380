export function getGTEDateOfWeek(date: Date) {
  let DayOfWeek = date.getDay();
  let Difference = date.getDate() - DayOfWeek + (DayOfWeek === 0 ? -6 : 1);
  let NotFormatedDate = new Date(date.setDate(Difference));

  let ReversedDate = new Intl.DateTimeFormat("ko-KR").format(NotFormatedDate);

  return ReversedDate.replace(/\./g, "").replace(/\s+/g, "-");
}

export function getLTEDateOfWeek(date: Date) {
  let DayOfWeek = date.getDay();
  let Difference = date.getDate() - DayOfWeek + (DayOfWeek === 0 ? -6 : 1);
  let NotFormatedDate = new Date(date.setDate(Difference + 6));

  let ReversedDate = new Intl.DateTimeFormat("ko-KR").format(NotFormatedDate);

  return ReversedDate.replace(/\./g, "").replace(/\s+/g, "-");
}
