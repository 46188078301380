import { useState, useEffect, useRef } from "react";
import { Space, Input, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import CreateUser from "./UserForm";
import CompanyTable from "../../components/CompanyUsersTable/CompanyUsersTable";
import {
  archiveCompanyUser,
  fetchCompanyUsers,
} from "../../app/services/company";

const defaultPaginationOptions = {
  page: 1,
  pageSize: 15,
  total: 0,
};
const { Search } = Input;

export default function CompanyUsersLayout() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [tableDataList, setTableDataList] = useState<any>([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  // eslint-disable-next-line
  const [selectedTableKey, setSelectedTableKeys] = useState<any[]>([]);
  const [openCreateUserDrawer, setOpenCreateUserDrawer] = useState(false);
  const [activeUserId, setActiveUserId] = useState<number | null>(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [tableDataListPagination, setTableDataListPagination] = useState(
    defaultPaginationOptions
  );
  const componentRef = useRef<HTMLDivElement>(null);

  // Update the users table on page or search change
  useEffect(() => {
    fetchUsers();
    return () => {
      setTableDataList([]);
      setTableDataListPagination(defaultPaginationOptions);
      setTableDataLoading(false);
    };
    // eslint-disable-next-line
  }, [page, pageSize, searchText]);

  // Fetch company users
  const fetchUsers = async () => {
    setTableDataLoading(true);
    fetchCompanyUsers(
      page,
      pageSize,
      searchText
        ? {
            first_name: { contains: searchText },
            middle_name: { contains: searchText },
            last_name: { contains: searchText },
          }
        : {}
    )
      .then(({ users, pagination }: any) => {
        setTableDataList(users);
        setTableDataListPagination(pagination);
        setTableDataLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setTableDataLoading(false);
        messageApi.open({
          type: "error",
          duration: 5,
          content: t("team.teamsNotFetched"),
        });
      });
  };

  // Fetch company users
  const archiveUser = async (userId: number) => {
    try {
      const archivedUser = await archiveCompanyUser(userId);
      if (archivedUser.success) {
        messageApi.open({
          type: "success",
          duration: 5,
          content: t("companyUserInfo.userArchived"),
        });
      }
    } catch (error) {
      console.error(error);
      setTableDataLoading(false);
      messageApi.open({
        type: "error",
        duration: 5,
        content: t("companyUserInfo.userArchiveError"),
      });
    }
  };

  // Updates pagination state
  const onChangePagination = (changedPage: number, changedPageSize: number) => {
    if (changedPage !== page) {
      setPage(changedPage);
    } else if (changedPageSize !== pageSize) {
      setPageSize(changedPageSize);
    }
  };

  // Open a Create user modal
  const createUser = () => {
    setViewOnly(false);
    setActiveUserId(null);
    setOpenCreateUserDrawer(true);
  };

  // Open a single user's modal (Details)
  const previewUser = (id: number) => {
    setViewOnly(true);
    setActiveUserId(id);
    setOpenCreateUserDrawer(true);
  };

  // Edit a single user modal
  const editUser = (id: number) => {
    setViewOnly(false);
    setActiveUserId(id);
    setOpenCreateUserDrawer(true);
  };

  // Archive a single user modal
  const onArchiveUser = async (id: number) => {
    setActiveUserId(id);
    await archiveUser(id);
    fetchUsers();
  };

  const onSearch = (value: string) => {
    setPage(1);
    setPageSize(10);
    setSearchText(value);
  };

  return (
    <div style={{ margin: "1rem 2rem" }}>
      {contextHolder}
      <div className="pt-4">
        <Space size="middle">
          <Search
            placeholder={t("menu.search")}
            onSearch={onSearch}
            allowClear
            style={{ width: 300 }}
            maxLength={250}
          />
        </Space>
        <div style={{ float: "right" }}>
          <Space size="middle">
            <Button
              key="add"
              type="primary"
              icon={<AiOutlinePlus />}
              onClick={() => createUser()}
            >
              &nbsp; {t("doc.models.create")}
            </Button>
          </Space>
        </div>
        <div ref={componentRef}>
          <CompanyTable
            editUser={editUser}
            archiveUser={onArchiveUser}
            users={tableDataList}
            isListView={true}
            previewUser={previewUser}
            loading={tableDataLoading}
            onChangePagination={onChangePagination}
            paginationDetails={tableDataListPagination}
            setSelectedTableKeys={setSelectedTableKeys}
          />
        </div>
        <CreateUser
          userId={activeUserId}
          onComplete={fetchUsers}
          open={openCreateUserDrawer}
          viewOnly={viewOnly}
          setOpen={(isOpen) => {
            setOpenCreateUserDrawer(isOpen);
            if (!isOpen) setActiveUserId(null);
          }}
        />
      </div>
    </div>
  );
}
