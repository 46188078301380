import { useState } from "react";
import DocumentRequestsTable from "../../components/DocumentRequestsTable";
import { documentRequestsColumns } from "./contents";
import styles from "./DocumentRequestLayout.module.scss";
import InputSearch from "components/InputSearch/InputSearch";
import { fetchDocumentRequestsTanStack } from "app/stores/documentRequests/documentRequestsClient";

export default function DocumentRequestLayout() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState<{ subject: { contains: string } }>({
    subject: { contains: "" },
  });

  const {
    status: statusDocumentRequests,
    error: errorDocumentRequests,
    documentRequests,
  } = fetchDocumentRequestsTanStack(page, pageSize, filters);

  const onChangePagination = (changedPage: number, changedPageSize: number) => {
    if (changedPage !== page) {
      setPage(changedPage);
    }
    if (changedPageSize !== pageSize) {
      setPageSize(changedPageSize);
    }
  };

  const onSearch = (value: string) => {
    setPage(1);
    setPageSize(10);
    setFilters({ subject: { contains: value } });
  };

  return (
    <div className={styles.documentRequestLayoutWrapper}>
      <InputSearch placeholder="Search" onSearch={onSearch} />
      <DocumentRequestsTable
        documentRequests={documentRequests?.documentRequests ?? []}
        loading={statusDocumentRequests === "loading"}
        paginationDetails={
          documentRequests?.pagination ?? { page: 1, pageSize: 10, total: -1 }
        }
        onChangePagination={onChangePagination}
        columns={documentRequestsColumns}
        setSelectedTableKeys={() => {
          return;
        }}
        selectedTableKey={[]}
      />
      {statusDocumentRequests === "error" && (
        <p> Error occurred {JSON.stringify(errorDocumentRequests)} </p>
      )}
    </div>
  );
}
