import * as WebDataRocksReact from "@webdatarocks/react-webdatarocks";
import { useTranslation } from "react-i18next";
import "@webdatarocks/webdatarocks/webdatarocks.css";

export default function WebDataRocksComponent({ csvJSONData }: any) {
  const fields = Object.keys(csvJSONData);
  const { i18n } = useTranslation();

  return (
    <div className="webdatarockwrapper">
      <WebDataRocksReact.Pivot
        toolbar={true}
        width="100%"
        report={{
          dataSource: {
            data: Array.isArray(csvJSONData) ? csvJSONData : [csvJSONData],
          },

          options: {
            grid: {
              type: "flat",
              showTotals: "off",
              showGrandTotals: "off",
              showHeaders: false,
            },
          },
          slice: {
            columns: [
              fields.map((field: any) => {
                return {
                  uniqueName: field,
                };
              }),
            ],
          },
        }}
        global={
          i18n.language === "fr"
            ? {
                localization: "/locales/fr/webDataRocksTranslation.json",
              }
            : undefined
        }
      />
    </div>
  );
}
