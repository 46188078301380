import MediaPickerModalContent from "./MediaPickerModalContent";
import MediaPreviewWithEditor from "../MediaPreviewWithEditor/MediaPreviewWithEditor";
import {
  GlobalStore,
  MediaAction,
  useStoreState,
} from "@armtekfrance/webgate/dist";
import styles from "./MediaPickerModal.module.scss";
import { useEffect, useRef, useState } from "react";
import { Manager } from "@felixkletti/managementjs";

export default function MediaPickerModal() {
  useStoreState(GlobalStore.media.getMediaPickerModalStatus());

  const dialogRef = useRef<HTMLDialogElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const observers: Array<() => void> = [];
    Manager.on(MediaAction.OPEN_MEDIA_PICKER, (value, done) => {
      if (dialogRef?.current) {
        if (value) {
          dialogRef.current.showModal();
          setOpen(true);
        } else {
          dialogRef.current.close();
          setOpen(false);
        }
      }
      observers.push(done);
    });

    return () => observers.forEach((unsubscribe) => unsubscribe());
  }, []);

  return (
    <>
      <MediaPreviewWithEditor />
      <dialog ref={dialogRef} className={styles.mediaPickerModalWrapper}>
        <span
          onClick={() => {
            dialogRef.current?.close();
            setOpen(false);
          }}
        />
        {open && <MediaPickerModalContent />}
      </dialog>
    </>
  );
}
