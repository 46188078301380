import EditCompany from "./EditCompany/EditCompany";
import EditImages from "./EditImages/EditImages";
import styles from "./CompanyLayout.module.scss";

export default function CompanyLayout() {
  return (
    <div className={styles.companyLayoutWrapper}>
      <div className={styles.gridCompanyContent}>
        <EditImages />
        <EditCompany />
      </div>
    </div>
  );
}
