import MagicListItemActionsAside from "./MagicActionsAside/MagicListItemActionsAside";
import styles from "./MagicListItemBody.module.scss";
import { Dispatch, SetStateAction, useState } from "react";
import MagicListItemArticle from "./MagicArticle/MagicListItemArticle";
import { useMagic } from "../MagicContext";
import { DocumentType } from "app/stores/documents/types";
import { ApiMetabaseDashboard } from "app/stores/metabase/types";

export default function MagicDocumentModelListItemBody({
  displayDetails,
  setDisplayDetails,
  setAnimationState,
  documentModel,
  metabaseDashboard,
  isLoading = false,
  lite,
  type = "models",
  isCurrentUserSuperAdmin = true,
  readOnly = false,
  onOpenModal,
  displayActionSide = true,
}: {
  displayDetails: boolean;
  setDisplayDetails: Dispatch<SetStateAction<boolean>>;
  documentModel?: DocumentType;
  metabaseDashboard?: ApiMetabaseDashboard;
  setAnimationState: Dispatch<
    SetStateAction<"Init" | "dispalyView" | "displayHide">
  >;
  isLoading?: boolean;
  lite: boolean;
  type?: "models" | "analysis";
  isCurrentUserSuperAdmin?: boolean;
  readOnly?: boolean;
  onOpenModal?: (
    id: string | number,
    type: "openModal" | "deleteDashboard" | "changeActive" | "deleteModel"
  ) => void;
  displayActionSide?: boolean;
}) {
  const magic = useMagic();
  const [sectionIdHovered, setSectionIdHovered] = useState<number | null>(null);

  if (type === "analysis") {
    magic.isLoading = false;
  }

  return (
    <div
      className={`${styles.magicListItemBodyWrapper} ${
        magic.isInError && styles.inError
      }
      ${displayActionSide && styles.magicListItemBodyWrapperBorderRight}
      ${!lite && magic.isLoading && styles.isLoading}`}
      onClick={() => {
        !lite && !magic.isLoading && setDisplayDetails(!displayDetails);
        if (!lite && !magic.isLoading) {
          displayDetails
            ? setAnimationState("displayHide")
            : setAnimationState("dispalyView");
        }
      }}
    >
      {!isLoading ? (
        <>
          <MagicListItemArticle
            documentMagicData={documentModel}
            metabaseDashboard={metabaseDashboard}
            lite={lite}
            readOnly={readOnly}
            commentSectionIdHovered={sectionIdHovered}
            type={type}
            onOpenModal={onOpenModal}
          />
          {!lite && displayActionSide && (
            <MagicListItemActionsAside
              documentId={
                type === "analysis"
                  ? metabaseDashboard?.dashboard_uuid || ""
                  : documentModel
                  ? documentModel.id
                  : ""
              }
              lite={lite}
              type={type}
              isCurrentUserSuperAdmin={isCurrentUserSuperAdmin}
              sectionIdHovered={(sectionId) => {
                setSectionIdHovered(sectionId);
              }}
              readOnly={readOnly}
              onOpenModal={onOpenModal}
            />
          )}
        </>
      ) : (
        <>
          <MagicListItemArticle
            documentMagicData={documentModel}
            isLoading={true}
            type={type}
            readOnly={readOnly}
            lite={lite}
            onOpenModal={onOpenModal}
            commentSectionIdHovered={sectionIdHovered}
          />
          <MagicListItemActionsAside
            documentId={documentModel ? documentModel.id : ""}
            type={type}
            isLoading={true}
            lite={lite}
            sectionIdHovered={(sectionId) => {
              setSectionIdHovered(sectionId);
            }}
          />
        </>
      )}
    </div>
  );
}
