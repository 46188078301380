import HeaderMenuTabItem from "./HeaderMenuTabItem";
import { useTranslation } from "react-i18next";
import HeaderMenuTabResizeWrapper from "./HeaderMenuTabResizeWrapper";
export default function HeaderMenuTabPlan() {
  const { t } = useTranslation();

  return (
    <HeaderMenuTabResizeWrapper>
      <HeaderMenuTabItem
        label={t("doc.plan.outline.title")}
        to={"/account/plan/outline"}
      />
      <HeaderMenuTabItem
        label={t("doc.plan.docRequest.title")}
        to={"/account/plan/requests"}
      />
      <HeaderMenuTabItem
        label={t("topHeader.model.modelLinkage")}
        to={"/account/plan/linkage"}
      />
    </HeaderMenuTabResizeWrapper>
  );
}
