export const featureListTabEnglish = [
  "New Theme: The app gets a fresh look with a modern and sleek design.",
  "Performance Optimization: Enjoy faster load times and a smoother user experience.",
  "New Model Editor: The model editor now includes new features like drag-and-drop for blocks and sections, a table of contents, and more!",
  "Updated Blocks: The InputInternalLinkKnowledge and InputInternalLinkObject blocks now replace the old SelectItem block.",
  "Stay tuned for more updates as we continue to enhance your experience!",
];

export const featureListTabFrench = [
  "  Nouveau thème : L'application fait peau neuve avec un design moderne et épuré.",
  "Optimisation des performances : Profitez de temps de chargement plus rapides et d'une expérience utilisateur plus fluide.",
  "Nouveau modélisateur : Le modélisateur inclut désormais de nouvelles fonctionnalités, telles que le glisser-déposer de blocs et de sections, un sommaire, et plus encore !",
  "Mise à jour des blocs : Les blocs InputInternalLinkKnowledge et InputInternalLinkObject remplacent désormais l'ancien bloc SelectItem.",
  "Restez à l'écoute pour d'autres mises à jour, nous continuons d'améliorer votre expérience !",
];
