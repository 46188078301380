import ExecutionFluxProvider from "./Provider/ExecutionFluxProvider";
import ExecutionFlux from "./ExecutionFlux";
import {
  DocumentTypeFilter,
  GlobalStore,
  NameFilter,
  StoreFilterType,
  UtilsFunction,
  IntervalFilter,
  DateTarget,
} from "@armtekfrance/webgate/dist";
import {
  ApiDocumentFamilyEnum,
  ApiExecutionType,
} from "@armtekfrance/corenexus-pn-sn";
import { t } from "i18next";

export default function ExecutionFluxRoot() {
  const filterManager = GlobalStore.filter;

  const selectDateFilter = new IntervalFilter(
    filterManager,
    StoreFilterType.EXECUTION,
    t("filters.operate.creationDate"),
    DateTarget.CREATED_AT
  );

  const documentTypeFilter = new DocumentTypeFilter(
    t("filters.operate.modelType"),
    UtilsFunction.getDocumentTypesFromFamily(ApiDocumentFamilyEnum.KNOWLEDGE),
    filterManager,
    StoreFilterType.DOCUMENT_BASELINE
  );

  const userFilter = new NameFilter(
    t("filters.operate.authorName"),
    filterManager,
    StoreFilterType.USER,
    t("filters.operate.authorPlaceholder")
  );

  const nameDocumentModelNameFilter = new NameFilter(
    t("filters.operate.modelName"),
    filterManager,
    StoreFilterType.DOCUMENT_BASELINE,
    t("filters.operate.modelNamePlaceholder")
  );

  filterManager.clearFilters();
  filterManager.addFilter(selectDateFilter);
  filterManager.addFilter(nameDocumentModelNameFilter);
  filterManager.addFilter(documentTypeFilter);
  filterManager.addFilter(userFilter);

  filterManager.storeFilters.executionFilter.types = [
    ApiExecutionType.EXECUTION,
  ];

  return (
    <ExecutionFluxProvider>
      <ExecutionFlux filterManager={filterManager} />
    </ExecutionFluxProvider>
  );
}
