import TeamLayoutProvider from "./Provider/TeamLayoutProvider";
import TeamLayout from "./TeamLayout";

export default function TeamLayoutRoot() {
  return (
    <TeamLayoutProvider>
      <TeamLayout />
    </TeamLayoutProvider>
  );
}
