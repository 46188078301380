import { Card, Switch } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";

interface SelectItemType {
  data: any;
  disableEdit?: boolean;
  contentId: string | number;
  blockType: string;
  updateContentInDb: (id: string | number, data: any) => void;
  deleteContentInDb: (contentId: string | number) => void;
  setIsContentBeingSaved: (data: boolean) => void;
}

export default function SelectItem({
  data,
  disableEdit,
  contentId,
  updateContentInDb,
  setIsContentBeingSaved,
}: SelectItemType) {
  const { t } = useTranslation();
  const [reqData, setReqData] = useState(
    data ? data : { mandatory: false, instruction: "" }
  );
  let timeoutId: any;

  useEffect(() => {
    setReqData(data ? data : { mandatory: false, instruction: "" });
  }, [data]);

  const changeMandatoryField = (mandatoryData: any) => {
    let reqDataCopy = { ...reqData };
    reqDataCopy["mandatory"] = mandatoryData;
    clearTimeout(timeoutId);
    const dataToUpdate = {
      content: {
        content: reqDataCopy,
      },
    };
    updateContentInDb(contentId, dataToUpdate);
    setReqData(reqDataCopy);
  };

  const onDataChange = (e: any) => {
    setIsContentBeingSaved(true);
    let reqDataCopy = { ...reqData };
    reqDataCopy["instruction"] = e.target.value;
    setReqData(reqDataCopy);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      // Runs 2 second (2000 ms) after the last change
      const dataToUpdate = {
        content: { content: reqDataCopy },
      };
      updateContentInDb(contentId, dataToUpdate);
      setIsContentBeingSaved(false);
    }, 2000);
  };

  return (
    <Card
      style={{
        backgroundColor: "#F6F9FB",
      }}
      bodyStyle={{ padding: "8px" }}
    >
      <div style={{ marginBottom: "20px" }}>
        <Switch
          checkedChildren={t("doc.models.edit.sections.blocks.mandatory")}
          unCheckedChildren={t("doc.models.edit.sections.blocks.notMandatory")}
          checked={reqData?.mandatory}
          disabled={disableEdit}
          onChange={changeMandatoryField}
        />
      </div>
      <div>
        <TextArea
          placeholder={t("selectItemBlock.instructionForSelect")}
          bordered={false}
          autoSize
          value={reqData?.instruction}
          onChange={(e) => onDataChange(e)}
          disabled={disableEdit}
          style={{
            marginTop: "4px",
            paddingLeft: "0px",
            fontSize: "14px",
            margin: "0px",
          }}
        />
      </div>
    </Card>
  );
}
