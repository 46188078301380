import { GlobalStore } from "@armtekfrance/webgate/dist";
import AdvancedAnalysisComponent from "./AdvancedAnalysisComponent";

const AdvancedAnalysisPage = () => {
  let filterManger = GlobalStore.filter;

  // let dashboardNameFilter = new NameFilter(
  //   t("filters.dashboard.name"),
  //   filterManger,
  //   StoreFilterType.DASHBOARD,
  //   t("filters.dashboard.namePlaceholder")
  // );

  filterManger.clearFilters();

  return (
    <div>
      <AdvancedAnalysisComponent filterManager={filterManger} />
    </div>
  );
};

export default AdvancedAnalysisPage;
