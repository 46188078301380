import { QueryStatus } from "@tanstack/react-query";
import { PaginationType } from "app/stores/pagination/types";
import { DocumentType } from "app/stores/documents/types";

export type stateExecutionFluxProvider = {
  statusDocumentsModels: QueryStatus;
  errorDocumentsModels: unknown;
  documentsModels:
    | (DocumentType & { selected: boolean; value: string; label: string })[]
    | undefined;
  paginationDocumentModels: PaginationType;

  statusBaselineExecutions: QueryStatus;
  errorBaselineExecutions: unknown;
  baselineExecutions: (any & { selected: boolean })[] | undefined;
  paginationBaselineExecutions: PaginationType;

  queryNameTextFilter: string;
  queryDateSelectedFilter: { gte: string; lte: string };
  queryModelSelectedFilter: number[];
};

export type TypeExecutionFluxContextReducerAction =
  | {
      type: "setDocumentsModelsTanstackApi";
      setDocumentsModelsTanstackApi: {
        statusDocumentsModels: stateExecutionFluxProvider["statusDocumentsModels"];
        errorDocumentsModels: stateExecutionFluxProvider["errorDocumentsModels"];
      };
    }
  | {
      type: "setDocumentsModelsTanstack";
      setDocumentsModelsTanstack: {
        documentsModels: stateExecutionFluxProvider["documentsModels"];
        paginationDocumentModels: stateExecutionFluxProvider["paginationDocumentModels"];
      };
    }
  | {
      type: "setBaselineExecutionsTanstackApi";
      setBaselineExecutionsTanstackApi: {
        statusBaselineExecutions: stateExecutionFluxProvider["statusBaselineExecutions"];
        errorBaselineExecutions: stateExecutionFluxProvider["errorBaselineExecutions"];
      };
    }
  | {
      type: "setBaselineExecutionsTanstack";
      setBaselineExecutionsTanstack: {
        baselineExecutions: stateExecutionFluxProvider["baselineExecutions"];
        paginationBaselineExecutions: stateExecutionFluxProvider["paginationBaselineExecutions"];
      };
    }
  | {
      type: "setQueryNameTextFilter";
      queryNameTextFilter: stateExecutionFluxProvider["queryNameTextFilter"];
    }
  | {
      type: "setQueryDateSelectedFilter";
      queryDateSelectedFilter: stateExecutionFluxProvider["queryDateSelectedFilter"];
    }
  | {
      type: "setQueryModelSelectedFilter";
      queryModelSelectedFilter: stateExecutionFluxProvider["queryModelSelectedFilter"];
    }
  | {
      type: "setPaginationBaselineExecutions";
      paginationBaselineExecutions: stateExecutionFluxProvider["paginationBaselineExecutions"];
    };

export function executionFluxReducer(
  stateExecutionFlux: stateExecutionFluxProvider,
  action: TypeExecutionFluxContextReducerAction
): stateExecutionFluxProvider {
  switch (action.type) {
    case "setDocumentsModelsTanstackApi": {
      return {
        ...stateExecutionFlux,
        statusDocumentsModels:
          action.setDocumentsModelsTanstackApi.statusDocumentsModels,
        errorDocumentsModels:
          action.setDocumentsModelsTanstackApi.errorDocumentsModels,
      };
    }
    case "setDocumentsModelsTanstack": {
      return {
        ...stateExecutionFlux,
        documentsModels: action.setDocumentsModelsTanstack.documentsModels,
        paginationDocumentModels:
          action.setDocumentsModelsTanstack.paginationDocumentModels,
      };
    }

    case "setBaselineExecutionsTanstackApi": {
      return {
        ...stateExecutionFlux,
        statusBaselineExecutions:
          action.setBaselineExecutionsTanstackApi.statusBaselineExecutions,
        errorBaselineExecutions:
          action.setBaselineExecutionsTanstackApi.errorBaselineExecutions,
      };
    }

    case "setBaselineExecutionsTanstack": {
      return {
        ...stateExecutionFlux,
        baselineExecutions:
          action.setBaselineExecutionsTanstack.baselineExecutions,
        paginationBaselineExecutions:
          action.setBaselineExecutionsTanstack.paginationBaselineExecutions,
      };
    }

    case "setQueryNameTextFilter": {
      return {
        ...stateExecutionFlux,
        paginationBaselineExecutions: {
          ...stateExecutionFlux.paginationBaselineExecutions,
          page: 1,
        },
        queryNameTextFilter: action.queryNameTextFilter,
      };
    }
    case "setQueryDateSelectedFilter": {
      return {
        ...stateExecutionFlux,
        paginationBaselineExecutions: {
          ...stateExecutionFlux.paginationBaselineExecutions,
          page: 1,
        },
        queryDateSelectedFilter: action.queryDateSelectedFilter,
      };
    }

    case "setQueryModelSelectedFilter": {
      return {
        ...stateExecutionFlux,
        paginationBaselineExecutions: {
          ...stateExecutionFlux.paginationBaselineExecutions,
          page: 1,
        },
        queryModelSelectedFilter: action.queryModelSelectedFilter,
      };
    }

    case "setPaginationBaselineExecutions": {
      return {
        ...stateExecutionFlux,
        paginationBaselineExecutions: action.paginationBaselineExecutions,
      };
    }
    default: {
      return { ...stateExecutionFlux };
    }
  }
}
