import { ReactElement } from "react";
import ImageDisplayComponent from "../ImageDisplayComponent/ImageDisplayComponent";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface ISignature {
  media_content_uid: string;
  alertOccured: boolean;
}

export default function InputImageBlock({
  media_content_uid,
  alertOccured,
}: ISignature): ReactElement {
  const { t } = useTranslation();
  return (
    <div
      style={{
        border: alertOccured ? "3px red solid" : "",
        borderRadius: "5px",
        width: "25%",
      }}
    >
      {alertOccured && (
        <WarningOutlined
          style={{
            fontSize: "20px",
            color: "red",
            opacity: 1,
            margin: "10px",
          }}
        />
      )}
      <h3>{t("operate.imageSent")}</h3>

      <ImageDisplayComponent
        src={`${media_content_uid}`}
        alt={t("operate.imageSent")}
        thumbnail={true}
      ></ImageDisplayComponent>
    </div>
  );
}
