export function checkIfItHasALink(redirectionLink: string | null) {
  if (redirectionLink === null) {
    return false;
  }
  if (redirectionLink.includes("null")) {
    return false;
  }
  return true;
}

export function extractDynamicLinkIdFromNotALink(notADynamic_link: string) {
  if (notADynamic_link === null) {
    return null;
  }
  const n = notADynamic_link.lastIndexOf("/");
  const dynamic_linkID = notADynamic_link.substring(n + 1);
  return parseInt(dynamic_linkID);
}

export function isProcedureOrExecution(notification: any) {
  if (
    notification!.body.includes("publié") ||
    notification!.body.includes("aimé")
  )
    return "PROCEDURE";
  if (
    notification!.body.includes("executé") ||
    notification!.body.includes("exécuté") ||
    notification!.body.includes("execution")
  )
    return "EXECUTION";
  if (notification.targets && notification.targets.length > 0) {
    return "SINGLE_BLOCK";
  }
  return "EXECUTION";
}
