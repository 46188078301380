import AddLinkageNodeHeroSearch from "./AddLinkageNodeSearch";
import AddLinkageNodeHeroTypeFilter from "./TypeFilter/AddLinkageNodeHeroTypeFilter";

export default function AddLinkageNodeHero() {
  return (
    <div
      style={{ display: "flex", margin: "1rem 0 1rem 1rem", columnGap: "1rem" }}
    >
      <AddLinkageNodeHeroSearch />
      <AddLinkageNodeHeroTypeFilter />
    </div>
  );
}
