import { SizeType } from "@armtekfrance/armtek-react-design-system";
import {
  ApiDocumentBaseline,
  ApiDocumentBaselineSearchRequest,
  ApiDocumentModel,
  ApiExecutionMonitor,
  ApiObject,
} from "@armtekfrance/corenexus-pn-sn";
import {
  FilterMaster,
  ExpandableDocumentList,
  FilterManager,
  useStoreState,
  GlobalStore,
  DeleteButton,
  Layout,
  HistoryButton,
  PreviewButton,
  PdfExportButton,
} from "@armtekfrance/webgate/dist";
import styles from "./ReleaseComponent.module.scss";
import MagicBaselineConstructor from "components/MagicListItem/MagicBody/MagicActionsAside/MagicActionsButtons/MagicBaselineConstructor";
import MediaPreviewWithEditor from "../../components/MediaPreviewWithEditor/MediaPreviewWithEditor";

interface ReleaseComponentProps {
  filterManager: FilterManager;
  type: "SYSTEM" | "KNOWLEDGE";
}

export default function ReleaseComponent({
  filterManager,
  type,
}: ReleaseComponentProps) {
  const [filterDocument] = useStoreState(
    GlobalStore.filter.storeFilters.documentBaselineFilter
  );
  const [filterUser] = useStoreState(
    GlobalStore.filter.storeFilters.userFilter
  );

  const filter: ApiDocumentBaselineSearchRequest = {
    extend: true,
    documents: filterDocument,
    authors: filterUser,
    config: {
      extend: true,
      user: {
        extend: true,
      },
      sections: type === "SYSTEM" ? { extend: true } : undefined,
      qr_code: {
        extend: true,
      },
    },
  };

  return (
    <>
      <MediaPreviewWithEditor />
      <Layout
        header={
          <div className={styles.filterMasterContainer}>
            <FilterMaster
              filterManager={filterManager}
              fetchData={() => {
                // ...
                //console.log("debug");
              }}
            />
          </div>
        }
        content={
          <div className={styles.releaseWrapper}>
            <div className={styles.expandableDocumentListContainer}>
              <ExpandableDocumentList
                object="ApiDocumentBaseline"
                emitFilter={filter}
                onCheck={() => console.log("onCheck")}
                disabled={false}
                size={SizeType.small}
                slim={true}
                actionButtons={(
                  prop:
                    | ApiExecutionMonitor
                    | ApiDocumentModel
                    | ApiDocumentBaseline
                    | ApiObject
                ) => {
                  const typedProp = prop as ApiDocumentBaseline;
                  return (
                    <div className={styles.actionButtonsContainer}>
                      <div
                        onClick={(e) => e.stopPropagation()}
                        style={{ display: "flex" }}
                      >
                        <PreviewButton
                          modelId={typedProp.model!.id}
                          baselineId={typedProp.id}
                        />

                        {typedProp.id !== undefined && (
                          <PdfExportButton
                            exportParameters={{
                              document_baseline_id: typedProp.id,
                            }}
                            iconOrButton="Icon"
                          />
                        )}
                        {type === "KNOWLEDGE" ? (
                          <HistoryButton
                            data={{ model_id: typedProp.model?.id ?? 0 }}
                          />
                        ) : (
                          <MagicBaselineConstructor baselineId={typedProp.id} />
                        )}

                        <DeleteButton
                          object={typedProp}
                          onDelete={() => {
                            return;
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
}
