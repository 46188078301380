import Request, {
  filterFormat,
  pageFormat,
  pageSizeFormat,
} from "../../../libs/requests";

export const fetchDocumentCategories = async (
  filters: any,
  page?: number,
  pageSize?: number | string
) => {
  const filterParams = filterFormat(filters);
  let reqParams = {
    ...filterParams,
  };
  if (page) {
    const pageParams = pageFormat(page);
    reqParams = {
      ...reqParams,
      ...pageParams,
    };
  }
  if (pageSize) {
    const pageSizeParams = pageSizeFormat(pageSize);
    reqParams = {
      ...reqParams,
      ...pageSizeParams,
    };
  }
  return Request()
    .get("/document-categories", {
      params: reqParams,
    })
    .then((response) => response.data);
};

export const fetchDocumentCategoryByID = async (id: number | string) => {
  return Request()
    .get(`/document-categories/${id}`)
    .then((response) => response.data);
};

export const createDocumentCategory = async (data: any) => {
  return Request()
    .post("/document-categories", data)
    .then((response) => response.data);
};

export const updateDocCategory = async (data: any, id: number | string) => {
  return Request()
    .patch(`/document-categories/${id}`, data)
    .then((response) => response.data);
};

export const deleteDocCategory = async (id: number | string) => {
  return Request()
    .delete(`/document-categories/${id}`)
    .then((response) => response.data);
};
