import { Pdf } from "@armtekfrance/webgate/dist";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./PdfParamsHandler.module.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function PdfParamsHandler() {
  const query = useQuery();
  const pdf_setting_uuid = query.get("pdf_setting_uuid");
  const document_baseline_id = query.get("document_baseline_id");
  const execution_monitor_data_id = query.get("execution_monitor_data_id");
  const language = query.get("language");

  const { i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (language) {
      changeLanguage(language);
    }
  }, [language]);

  return (
    <Pdf
      pdf_setting_uuid={pdf_setting_uuid?.toString() ?? ""}
      document_baseline_id={parseInt(document_baseline_id ?? "")}
      execution_monitor_data_id={
        execution_monitor_data_id !== null
          ? parseInt(execution_monitor_data_id, 10)
          : undefined
      }
    />
  );
}
