import Request, {
  SortFormatType,
  filterFormat,
  optionFormat,
  pageFormat,
  pageSizeFormat,
  queryClient,
  sortFormat,
} from "../../../libs/requests";
import { PaginationType } from "../pagination/types";
import { DocumentType } from "./types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MediaAction } from "@armtekfrance/webgate/dist";
import { Manager } from "@felixkletti/managementjs";

type documentsModelsFiltersType = {
  family?: string | string[];
  document_family?: "KNOWLEDGE" | "SYSTEM";
  name?: { contains: string };
  count?: boolean;
  qr_code?: boolean;
  doc_category_id?: string | number;
};

export const fetchDocuments = async (
  page: number,
  pageSize: number,
  filters: documentsModelsFiltersType = {},
  sort: SortFormatType = {
    updated_at: "desc",
  }
) => {
  return Request()
    .get("/documents/", {
      params: {
        ...filterFormat(filters),
        ...sortFormat(sort),
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
      },
    })
    .then((response) => {
      return {
        documentsModels: response.data.data.documentModels as DocumentType[],
        pagination: response.data.data.pagination as PaginationType,
      };
    });
};

export function fetchDocumentsModelsTanStack(
  page: number,
  pageSize: number,
  filters: documentsModelsFiltersType = {},
  sort: SortFormatType = {
    updated_at: "desc",
  }
) {
  const {
    status,
    error,
    data: documentsModels,
  } = useQuery({
    queryKey: ["documentsModels", page, pageSize, filters, sort],
    queryFn: () => fetchDocuments(page, pageSize, filters, sort),
  });
  return { status, error, documentsModels };
}

export function fetchSingleDocumentModelTanstack(id: number, option: any) {
  const {
    status,
    error,
    data: documents,
  } = useQuery({
    enabled: id !== undefined && id !== null,
    queryKey: ["documents", id, option],
    queryFn: () => fetchSingleDocumentMagic(id, option),
  });
  return { status, error, documents };
}

export const fetchDocumentByID = (id: string) => {
  return Request()
    .get(`/documents/${id}`)
    .then((response) => response.data);
};

export const updateManyDocuments = (ids: any[], activeFlag: boolean) => {
  return Request()
    .patch("/documents", {
      ids: ids,
      active: activeFlag,
    })
    .then((response) => response.data);
};

export const updateDocument = (documentId: string, values: any) => {
  return Request()
    .patch(`/documents/${documentId}`, {
      ...values,
    })
    .then((response) => response.data);
};

export const createDocument = (createDocument: any) => {
  return Request()
    .post("/documents", createDocument)
    .then((response) => response.data);
};

export const duplicateDocument = (documentId: number | string) => {
  return Request()
    .post(`/documents/${documentId}/duplicate`)
    .then((response) => response.data);
};

export const publishDocument = (documentId: number | string, values: any) => {
  return Request()
    .post(`/documents/${documentId}`, values)
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data;
    });
};

export const fetchDocumentVersions = async (
  documentId: number | string,
  page: number,
  pageSize: number,
  filters = {},
  sort: any = { id: "desc" }
) => {
  return Request()
    .get(`documents/${documentId}/versions`, {
      params: {
        ...filterFormat(filters),
        ...sortFormat(sort),
        ...pageFormat(page),
        ...pageSizeFormat(pageSize),
      },
    })
    .then((response) => response.data);
};

export function fetchDocumentVersionsPOC(
  documentId: number,
  page: number,
  pageSize: number,
  filters: any
) {
  const {
    status,
    error,
    data: documentsVersions,
  } = useQuery({
    enabled: documentId > 0 && documentId !== undefined && documentId !== null,
    queryKey: ["documentsVersions", documentId, page, pageSize, filters],
    queryFn: () => fetchDocumentVersions(documentId, page, pageSize, filters),
  });
  return { status, error, documentsVersions };
}

const fetchSingleDocumentMagic = async (id: number, option: any = {}) => {
  const optionParams = optionFormat(option);
  return Request()
    .get(`documents/${id}`, {
      params: {
        ...optionParams,
      },
    })
    .then((response) => {
      return response.data.data as any;
    });
};

const setDocumentCover = async (id: number | string, mediaId: number) => {
  return Request()
    .patch(`/documents/${id}/set-cover`, { media_content_id: mediaId })
    .then((response) => response.data);
};

export const setDocumentCoverTanstack = () => {
  return useMutation({
    mutationFn: ({ id, mediaId }: { id: number | string; mediaId: number }) => {
      return setDocumentCover(id, mediaId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["documents"] });
      Manager.trigger(MediaAction.OPEN_MEDIA_PICKER, false);
    },
  });
};

const deleteDocumentCover = async (id: number | string) => {
  return Request()
    .delete(`/documents/${id}/cover`)
    .then((response) => response.data);
};

export const deleteDocumentCoverTanstack = () => {
  return useMutation({
    mutationFn: ({ id }: { id: number | string }) => {
      return deleteDocumentCover(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["documents"] });
    },
  });
};

const archiveBaselineForAModel = async (docId: number | string) => {
  return Request()
    .post(`/documents/${docId}/archive-baselines`)
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data;
    });
};

export const archiveBaselineForAModelsPOC = (documentId: number) => {
  return useMutation({
    mutationFn: () => {
      return archiveBaselineForAModel(documentId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["documentBaselines"] });
    },
  });
};
export const fetchExecutionsOfDocumentAsCsv = async (
  documentId: number | string,
  document_baseline_ids: number[] | string[],
  execution_monitor_data_id: number | string
) => {
  return Request()
    .post(`/documents/execution/csv/${documentId}`, {
      document_id: documentId,
      document_baseline_ids: document_baseline_ids,
      execution_monitor_data_id: execution_monitor_data_id,
    })
    .then((response) => response);
};
